// Lib
import React, { useEffect } from "react";

// Own components
import { Account } from "@components";

// Hooks
import { useAccountTeams, useAccountTeamUsers, useGetRights } from "@hooks";

// Utils
import { isArrayWithContent } from "@utils";

/**
 * Team type
 */
interface Team {
    rights: Array<any>;
    teamId: string;
    teamName: string;
    teamSize: string;
}

/**
 * Props type
 */

interface Props {
    selectedTeam?: Team;
    setTeam?: (selectedTeam: Team) => void;
}
/**
 * Account Overview
 */
const AccountOverview = ({ selectedTeam, setTeam }: Props) => {
    /**
     * API
     */
    // Get rights
    const {
        getRights,
        response: rights,
        loading: fetchingRights,
    } = useGetRights();

    // Fetch team users
    const {
        getAccountTeamUsers,
        response: teamUsers,
        loading: fetchingUsers,
    } = useAccountTeamUsers();

    // Fetch account teams
    const {
        getAccountTeams,
        response: accountTeams,
        loading: fetchingTeams,
    } = useAccountTeams();

    /**
     * Initial fetch
     */
    useEffect(() => {
        getAccountTeams();
        getRights();
    }, []);

    /**
     * Check if a team is already selected
     * if yes fetch it's users otherwise set the first one as a selected
     */
    useEffect(() => {
        if (!selectedTeam && !isArrayWithContent(accountTeams?.data?.records))
            return;

        if (
            !!setTeam &&
            !selectedTeam &&
            isArrayWithContent(accountTeams?.data?.records)
        ) {
            setTeam(accountTeams?.data?.records[0]);
        }
    }, [selectedTeam, accountTeams]);

    useEffect(() => {
        if (!selectedTeam) return;
        getAccountTeamUsers(selectedTeam?.teamId);
    }, [selectedTeam]);

    /**
     * Render
     */
    return (
        <Account
            accountTeams={{
                data: accountTeams?.data?.records,
                loading: fetchingTeams,
            }}
            rights={{ data: rights?.data, loading: fetchingRights }}
            teamUsers={{
                data: teamUsers?.data?.records,
                loading: fetchingUsers,
            }}
            selectedTeam={selectedTeam}
            setTeam={team => (setTeam ? setTeam(team) : undefined)}
        />
    );
};
export default React.memo(AccountOverview);
