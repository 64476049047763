// Lib
import { Grid, Typography } from "@mui/material";
import React, { useState } from "react";

// Own components
import { MaintenanceListAdvanced, Dialog } from "@components";
// Hooks
import { useMaintenance } from "@hooks";
import { Location } from "@types";
import { isSuccessfulCall } from "@utils";

/**
 * Props type
 */
interface Props {
    location?: Location;
    id?: string;
}

/**
 * Maintenance Country
 */
const MaintenanceCountry = ({ id }: Props) => {
    const [itemToDelete, setItemToDelete] = useState<
        | {
              id: string;
              savedLabel: string;
          }
        | undefined
    >();

    /**
     * API
     */
    const {
        list,
        error,
        loading,
        delete: deleteCountry,
        upsert,
    } = useMaintenance("country", "country|countries");

    /**
     * Country fields for MaintenanceList
     */
    const countryFields = [
        {
            id: "country",
            inputLabel: "New country",
            isSavedLabel: true,
            required: true,
            isLargeSize: true,
        },
        {
            id: "countryIsoCode",
            inputLabel: "ISO code",
            required: true,
            notEditable: true,
            isLargeSize: false,
        },
        {
            id: "currency",
            inputLabel: "ISO currency code",
            required: true,
            notEditable: false,
            isLargeSize: false,
        },
    ];

    /**
     * Delete item
     */
    const onDelete = () => {
        if (!itemToDelete?.id) return;
        deleteCountry(itemToDelete.id, itemToDelete.savedLabel).then(res => {
            if (isSuccessfulCall(res?.status)) {
                setItemToDelete(undefined);
            }
        });
    };

    /**
     * Render
     */
    return (
        <Grid container columnSpacing={10}>
            <Dialog
                title={"Delete country"}
                open={!!itemToDelete?.id}
                id={`${id}-delete-countries-dialog`}
                message={
                    <Typography
                        variant="subtitle2"
                        color="black"
                        component="span"
                    >
                        Are you sure you would like to delete{" "}
                        <Typography
                            variant="body2"
                            component="span"
                        >{`(${itemToDelete?.savedLabel})? `}</Typography>
                    </Typography>
                }
                primaryButton={{
                    text: "Confirm",
                    action: onDelete,
                    loading: loading?.deleting,
                }}
                secondaryButton={{
                    text: "Cancel",
                    action: () => setItemToDelete(undefined),
                }}
            />
            <MaintenanceListAdvanced
                title="Countries"
                isLargeSize
                subtitle="countries"
                id={`${id}-countries`}
                list={list.maintenanceCountryList}
                loading={loading}
                fields={countryFields}
                fieldsKey="country"
                valueId="countryIsoCode"
                error={error}
                deleteListItem={(id, savedLabel) =>
                    setItemToDelete({ id, savedLabel })
                }
                addListItem={(value, savedLabel) => upsert(value, savedLabel)}
            />
        </Grid>
    );
};
export default MaintenanceCountry;
