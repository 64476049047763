// Libs
import {
    Box,
    Typography,
    TextField,
    Divider,
    Grid,
    Switch,
} from "@mui/material";

import React, {
    useEffect,
    useState,
    Fragment,
    useMemo,
    useCallback,
} from "react";

// Own components
import { SearchBar, Table, Modal, LoadingWrapper } from "@components";

// Constants
import {
    HEADERS,
    ROWRENDERERCONST,
    ACCOUNT_STATUS,
    MAP_PRODUCT_CONDITIONS,
} from "@constants";

// Hooks
import {
    useAllProducts,
    useViewingOptions,
    useProductConditionType,
} from "@hooks";

// Types
import { SearchFilters } from "@types";

// Utils
import {
    constructQueryString,
    apiResponseCounter,
    isArrayWithContent,
    isObjectWithContent,
    blockInvalidNumber,
    isSuccessfulCall,
} from "@utils";

// Styles
import * as style from "./style.module.scss";

const ItemRenderer = ({ label, value }: any) => (
    <div className={style.list}>
        <Typography
            variant="caption2"
            marginBottom={2}
            className={style.header}
        >
            {label}
        </Typography>

        {typeof value === "string" ? (
            <Typography variant="caption2">{value}</Typography>
        ) : (
            value
        )}
    </div>
);

const AddProductConditionForm = ({
    product,
    setProductConditions,
    productConditionTypes,
    getProductConditionTypes,
    onSubmit,
    submitting,
}: any) => {
    const [conditions, setConditions] = useState({});
    const [touched, setTouched] = useState<any>({});

    /**
     * Reset all
     */
    const resetAll = () => {
        setConditions({});
        setTouched({});
        setProductConditions(undefined);
    };

    /**
     * Fetch condition types
     */
    useEffect(() => {
        // Reset the form when the modal is closed
        if (product) {
            getProductConditionTypes();
        } else {
            resetAll();
        }
    }, [product]);

    /**
     * Initialize conditions state
     */

    useEffect(() => {
        if (
            !!submitting ||
            !product ||
            (!!product && !isArrayWithContent(productConditionTypes?.data))
        )
            return;

        const mapConditionState = {};

        productConditionTypes?.data?.forEach(item => {
            if (
                !item.productConditionTypeId ||
                !MAP_PRODUCT_CONDITIONS[item?.productConditionTypeId]
            )
                return;

            Object.assign(mapConditionState, {
                [MAP_PRODUCT_CONDITIONS[item?.productConditionTypeId]
                    .conditionTypeId]: {
                    conditionId: item?.conditionId,
                    active: false,
                    ...MAP_PRODUCT_CONDITIONS[item?.productConditionTypeId]
                        ?.fields,
                },
            });

            // Store & initialize the product conditions if exists
            if (!!product && product?.productConditions) {
                const contractTypeIds = Object.keys(product?.productConditions);
                if (isArrayWithContent(contractTypeIds)) {
                    contractTypeIds.forEach(key => {
                        const condition = product?.productConditions[key];

                        MAP_PRODUCT_CONDITIONS[key] = mapConditionState[key] = {
                            ...condition,
                        };
                    });
                }
            }

            setConditions(mapConditionState);
        });
    }, [productConditionTypes, product, submitting]);

    /**
     * Key finder
     */
    const conditionKey = useCallback(item => {
        if (
            !item ||
            !MAP_PRODUCT_CONDITIONS ||
            !MAP_PRODUCT_CONDITIONS[item?.productConditionTypeId]
        )
            return;
        return MAP_PRODUCT_CONDITIONS[item?.productConditionTypeId]
            .conditionTypeId;
    }, []);

    /**
     * Blur handler
     */
    const onBlur = (key: string) => {
        if (!touched[key]) {
            const copyTouched = { ...touched };
            Object.assign(copyTouched, {
                [key]: true,
            });
            setTouched(copyTouched);
        }
    };

    /**
     * Value getter
     */
    const getValue = useCallback(
        (item: any, field: string): any => {
            if (!MAP_PRODUCT_CONDITIONS[item?.productConditionTypeId]) return;
            const key = conditionKey(item);

            if (!conditions[key]) return null;
            return conditions[key][field];
        },
        [conditionKey, conditions],
    );

    /**
     * Change handler
     */
    const onChange = (
        item: any,
        field: string,
        value: string | boolean | number,
    ) => {
        const copyConditions = { ...conditions };
        const key = conditionKey(item);

        copyConditions[key][field] = value;
        setConditions(copyConditions);
    };

    /**
     * Map condition key required field
     */
    const mapConditionKeys = useMemo(() => {
        if (!product || !isArrayWithContent(productConditionTypes?.data))
            return {};
        const mappedConditionn = {};
        productConditionTypes.data.forEach(key =>
            Object.assign(mappedConditionn, {
                [MAP_PRODUCT_CONDITIONS[key.productConditionTypeId]
                    ?.conditionTypeId]:
                    MAP_PRODUCT_CONDITIONS[key.productConditionTypeId]?.fields,
            }),
        );
        return mappedConditionn;
    }, [product, productConditionTypes]);

    /**
     * Check form validity
     */
    const isValidForm = useMemo(() => {
        if (!isObjectWithContent(mapConditionKeys)) return;
        return Object.keys(mapConditionKeys).every(key => {
            return mapConditionKeys[key].every(
                field => !!conditions[key] && !!conditions[key][field],
            );
        });
    }, [conditions, mapConditionKeys]);

    return (
        <Modal
            open={!!product}
            onClose={resetAll}
            title="Product conditions"
            id={`products-conditions`}
            primaryButton={{
                action: () => onSubmit(product?.productId, conditions),
                text: "Save",
                disabled: !isValidForm || submitting,
                loading: submitting,
            }}
            secondaryButton={{
                action: resetAll,
                text: "Cancel",
            }}
        >
            <LoadingWrapper
                id={`products-conditions-loading`}
                loading={productConditionTypes?.loading}
            >
                {isArrayWithContent(productConditionTypes?.data) &&
                    productConditionTypes?.data?.map((item, index) => (
                        <Grid container item xs={12} key={index}>
                            <Grid item xs={3}>
                                <ItemRenderer
                                    label="Condition"
                                    value={item?.name}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <ItemRenderer
                                    label="Active/Inactive"
                                    value={
                                        <Box display="flex" alignItems="center">
                                            <Switch
                                                disabled={submitting}
                                                checked={getValue(
                                                    item,
                                                    "active",
                                                )}
                                                onChange={() =>
                                                    onChange(
                                                        item,
                                                        "active",
                                                        !getValue(
                                                            item,
                                                            "active",
                                                        ),
                                                    )
                                                }
                                                inputProps={{
                                                    "aria-label": "controlled",
                                                }}
                                            />
                                            <Typography variant="caption2">
                                                {getValue(item, "active")
                                                    ? "Active"
                                                    : "Inactive"}
                                            </Typography>
                                        </Box>
                                    }
                                />
                            </Grid>

                            {item?.productConditionTypeId ===
                                "AUTO_CLOSE_ORDER" && (
                                <Grid item xs={3}>
                                    <ItemRenderer
                                        label="Input"
                                        value={
                                            <Grid
                                                container
                                                item
                                                xs={5}
                                                spacing={1}
                                            >
                                                <Grid item xs={10}>
                                                    <TextField
                                                        disabled={submitting}
                                                        name="amount"
                                                        id={`product-condition-input-field`}
                                                        autoComplete="off"
                                                        value={getValue(
                                                            item,
                                                            "amount",
                                                        )}
                                                        onBlur={() => {
                                                            onBlur(
                                                                item.productConditionTypeId,
                                                            );
                                                        }}
                                                        onChange={(
                                                            event: React.BaseSyntheticEvent,
                                                        ) =>
                                                            onChange(
                                                                item,
                                                                "amount",
                                                                event.target
                                                                    .value ===
                                                                    ""
                                                                    ? ""
                                                                    : +event
                                                                          .target
                                                                          .value,
                                                            )
                                                        }
                                                        type="number"
                                                        onKeyDown={
                                                            blockInvalidNumber
                                                        }
                                                        inputProps={{ min: 0 }}
                                                        variant="outlined"
                                                        size="small"
                                                        error={
                                                            getValue(
                                                                item,
                                                                "active",
                                                            ) &&
                                                            item?.productConditionTypeId &&
                                                            !!touched[
                                                                item
                                                                    ?.productConditionTypeId
                                                            ] &&
                                                            item?.productConditionTypeId ===
                                                                "AUTO_CLOSE_ORDER" &&
                                                            !getValue(
                                                                item,
                                                                "amount",
                                                            )
                                                        }
                                                    />
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <Typography variant="caption2">
                                                        Months
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        }
                                    />
                                </Grid>
                            )}

                            <Grid item xs={3}>
                                <ItemRenderer
                                    label="Description"
                                    value={item?.description}
                                />
                            </Grid>

                            {product?.productConditions?.length > 1 &&
                                index ===
                                    product?.productConditions?.length - 1 && (
                                    <Grid item xs={12}>
                                        <Divider />
                                    </Grid>
                                )}
                        </Grid>
                    ))}
            </LoadingWrapper>
        </Modal>
    );
};

/**
 * Maintenance Product Conditions
 */
const ProductConditions: React.FC = () => {
    const [product, setProductConditions] = useState<any>();

    /**
     * Hooks
     */
    const { viewingOptions, setViewingOptions } = useViewingOptions(
        ROWRENDERERCONST.PRODUCT_CONDITIONS,
    );

    /**
     * API
     */

    // Products
    const {
        response: products,
        loading: loadingProducts,
        getProducts,
    } = useAllProducts();

    // Product condition types

    const {
        list: productConditionTypes,
        loading: { listLoading, creating },
        reload: getProductConditionTypes,
        upsert,
    } = useProductConditionType();

    /**
     * Update Product
     */

    const onSearch = (searchFilters: SearchFilters) => {
        const hasFilters = Object.values(searchFilters).some(
            (filter: any) => filter.length,
        );

        // if status is present it should be toggler
        // and should be mapped to isActive
        const mappedFilters = {
            ...searchFilters,
            status:
                searchFilters["status"].length == 2
                    ? null
                    : searchFilters["status"],
        };

        const params = constructQueryString(mappedFilters, true);

        const newParams = params.replace(/status/g, "isActive");

        if (hasFilters) getProducts(newParams);
        else getProducts();
    };

    /**
     * Update Product
     */
    const onSubmit = (productId: string, productConditions: any) => {
        upsert({ productId, productConditions }).then(res => {
            if (!!res && isSuccessfulCall(res?.status)) {
                setProductConditions(undefined);
                getProducts();
            }
        });
    };

    /**
     * Render
     */
    return (
        <Fragment>
            <AddProductConditionForm
                product={product}
                setProductConditions={setProductConditions}
                getProductConditionTypes={getProductConditionTypes}
                productConditionTypes={{
                    data: productConditionTypes?.data
                        .maintenanceProductConditionTypeList,
                    loading: listLoading,
                }}
                onSubmit={onSubmit}
                submitting={creating}
            />

            <Box mt={2} mb={4} display="flex" justifyContent="flex-end">
                <SearchBar
                    id="products"
                    filterKey={ROWRENDERERCONST.PRODUCT_CONDITIONS}
                    handleSearch={(filters: SearchFilters) => onSearch(filters)}
                    placeholder="Search by product name, sku number, organisation ..."
                    viewingOptions={viewingOptions}
                    setViewingOptions={setViewingOptions}
                    startDate={false}
                    endDate={false}
                    status={{ data: ACCOUNT_STATUS, loading: false }}
                />
            </Box>
            <Box mt={5}>
                <Box display="flex" alignItems="baseline" mb={4}>
                    <Typography variant="h2">Products</Typography>

                    <Typography ml={1} variant="caption1">
                        {apiResponseCounter(
                            {
                                data: products?.data?.maintenanceProductList,
                                status: products?.status,
                            },
                            loadingProducts,
                            "product|products",
                        )}
                    </Typography>
                </Box>
                <Table
                    id="partners-list"
                    headers={HEADERS.PRODUCT_CONDITIONS}
                    rows={products?.data?.maintenanceProductList}
                    loading={loadingProducts}
                    type={ROWRENDERERCONST.PRODUCT_CONDITIONS}
                    viewingOptions={viewingOptions}
                    callbacks={{
                        onProductConditionsChange: (
                            productId,
                            productConditions,
                        ) =>
                            setProductConditions({
                                productId,
                                productConditions,
                            }),
                    }}
                    emptyMsg="No products found!"
                />
            </Box>
        </Fragment>
    );
};
export default React.memo(ProductConditions);
