import { Box, Typography } from "@mui/material";
import { navigate } from "gatsby";
import React, { Fragment, useEffect, useState } from "react";

// Own components
import { SearchBar, Table, Dialog, Modal } from "@components";

// constants
import { HEADERS, ROWRENDERERCONST, BPF_STATUS } from "@constants";

// hooks
import {
    useViewingOptions,
    useFinance,
    usePermission,
    useProcessBpf,
    useDownloadBpf,
    useGetBpfById,
    useRemoveFinancialTransactions,
    useSelectedCountry,
} from "@hooks";

// Types
import { SearchFilters, Permissions } from "@types";

// utils
import {
    apiResponseCounter,
    isArrayWithContent,
    constructQueryString,
    isSuccessfulCall,
} from "@utils";

/**
 * Finances
 */
const BpfList = () => {
    /**
     * States
     */
    const [itemToProcess, setItem] = useState<any>(undefined);
    const [itemToDownload, setItemToDownload] = useState<any>(undefined);
    const [financialTransaction, setFinancialTransaction] =
        useState<any>(undefined);
    const [transactionsList, setTransactionsList] = useState<string[]>([]);

    /**
     * Hooks
     */
    const { viewingOptions, setViewingOptions } = useViewingOptions(
        ROWRENDERERCONST.BPF,
    );
    const { isGermanyTeam } = useSelectedCountry();

    /**
     * API
     */

    // Get outcomes
    const {
        loading: { fetchingBpfList },
        loadBpfList,
        bpfList,
        searchBpfList,
    } = useFinance("bpf", "outcome|outcomes");

    // Process bpd
    const { loading: loadingProcessBpf, load: processBpf } = useProcessBpf();

    // downLoad Bpf
    const { load: downLoadBpf }: any = useDownloadBpf();

    //load Bpf Details
    const {
        load: loadBpfDetails,
        loading: { loading: fetchingBpfDetails },
        list: bpfDetails,
    }: any = useGetBpfById();

    const { load: removeTransactions, loading: removing } =
        useRemoveFinancialTransactions();
    /**
     * Permissions
     */
    const { hasPermissionToEditFinancialActions }: Permissions =
        usePermission();

    /**
     * Download bpf
     */

    useEffect(() => {
        if (!itemToDownload) return;
        downLoadBpf(itemToDownload.bpfId).then(res => {
            if (res && isSuccessfulCall(res?.status)) {
                setItemToDownload(undefined);
            }
        });
    }, [itemToDownload]);

    /**
     * Load BPF details
     */
    useEffect(() => {
        if (!financialTransaction) return;
        loadBpfDetails(financialTransaction.bpfId);
    }, [financialTransaction]);

    /**
     * handle mark Bpf as processed
     */
    const onProcessBpf = () => {
        if (!itemToProcess) return;
        processBpf(itemToProcess.bpfId).then(res => {
            if (res && isSuccessfulCall(res?.status)) {
                loadBpfList();
                setItem(undefined);
            }
        });
    };

    /**
     * Select transactions to be deleted
     */
    const handleSelect = (id: string) => {
        const copyTransactionsList = [...transactionsList];
        if (
            isArrayWithContent(copyTransactionsList) &&
            copyTransactionsList.includes(id)
        ) {
            const updatedList = copyTransactionsList.filter(
                itemId => itemId !== id,
            );
            setTransactionsList(updatedList);
        } else {
            copyTransactionsList.push(id);
            setTransactionsList(copyTransactionsList);
        }
    };

    /**
     * Delete transactions
     */
    const handleDeleteTransactions = () => {
        if (!financialTransaction || !isArrayWithContent(transactionsList))
            return;
        removeTransactions(
            financialTransaction.bpfId,
            transactionsList,
            financialTransaction?.financeType,
        ).then(res => {
            if (res && isSuccessfulCall(res?.status)) {
                setFinancialTransaction(undefined);
                setTransactionsList([]);
                loadBpfList();
            }
        });
    };

    /**
     * Search handler
     */
    const onSearch = (searchFilters: SearchFilters) => {
        const hasFilters = Object.values(searchFilters).some(
            filter => filter.length,
        );

        const params = constructQueryString(searchFilters, true);

        if (hasFilters) {
            searchBpfList(params);
        } else loadBpfList();
    };

    /**
     * Render
     */
    return (
        <Fragment>
            <Dialog
                id={`dashboard-bpf-process-dialog`}
                title={"Processed batch payment file"}
                open={!!itemToProcess}
                message={
                    <Typography
                        variant="subtitle2"
                        color="black"
                        component="span"
                    >
                        Are you sure you would like to mark
                        <Typography variant="body2" component="span">
                            {` ${itemToProcess?.bpfName} `}{" "}
                        </Typography>
                        as Processed?
                    </Typography>
                }
                primaryButton={{
                    text: "Confirm",
                    action: onProcessBpf,
                    loading: loadingProcessBpf,
                }}
                secondaryButton={{
                    text: "Cancel",
                    action: () => setItem(undefined),
                }}
            />

            <Modal
                id={`bpf-remove-financial-transaction-modal`}
                open={!!financialTransaction}
                onClose={() => {
                    setFinancialTransaction(undefined);
                    setTransactionsList([]);
                }}
                title={`Financial transactions (${financialTransaction?.contractRef})`}
                primaryButton={{
                    action: handleDeleteTransactions,
                    text: "Remove",
                    loading: removing,
                    disabled:
                        fetchingBpfDetails ||
                        !isArrayWithContent(transactionsList),
                }}
                secondaryButton={{
                    action: () => {
                        setFinancialTransaction(undefined);
                        setTransactionsList([]);
                    },
                    text: "Cancel",
                }}
            >
                <Box>
                    <Table
                        id={`remove-transaction`}
                        headers={
                            financialTransaction?.financeType === "CLAIM"
                                ? HEADERS.CLAIM_FINANCIAL_TRANSACTIONS
                                : HEADERS.OUTCOME_FINANCIAL_TRANSACTIONS
                        }
                        rows={
                            financialTransaction?.financeType === "CLAIM"
                                ? bpfDetails?.data?.claims
                                : bpfDetails?.data?.outcomes
                        }
                        loading={fetchingBpfDetails}
                        disabled={removing || fetchingBpfDetails}
                        type={
                            financialTransaction?.financeType === "CLAIM"
                                ? ROWRENDERERCONST.CLAIM_FINANCIAL_TRANSACTIONS
                                : ROWRENDERERCONST.OUTCOME_FINANCIAL_TRANSACTIONS
                        }
                        permissions={{ hasPermissionToEditFinancialActions }}
                        emptyMsg="No data"
                        callbacks={{
                            onSelect: (id: string) => handleSelect(id),
                            selectedItems: transactionsList,
                        }}
                    />
                </Box>
            </Modal>

            <Box mt={2} mb={4} display="flex" justifyContent="space-between">
                <SearchBar
                    id={`dashboard-financial-bpf-list-search-bar`}
                    filterKey={ROWRENDERERCONST.BPF}
                    viewingOptions={viewingOptions}
                    setViewingOptions={setViewingOptions}
                    handleSearch={(filters: SearchFilters) => onSearch(filters)}
                    placeholder="Search by BPF name or customer reference"
                    startDate
                    endDate
                    dateRange
                    financeType={{
                        loading: false,
                        data: [
                            { label: "Claim", value: "CLAIM" },
                            { label: "Outcome", value: "OUTCOME" },
                        ],
                    }}
                    status={{ data: BPF_STATUS, loading: false }}
                    action={{
                        type: "menu",
                        label: "New batch payment file",
                        callback: (_, key) =>
                            navigate(
                                `/finances/generate-bpf/${key}/select-contract/`,
                                {
                                    state: {
                                        isGenerateBpfFlow: true,
                                    },
                                },
                            ),

                        hidden: !hasPermissionToEditFinancialActions,
                        items: [
                            {
                                label: "Performance based",
                                key: "performance-based",
                            },
                            { label: "Volume based", key: "volume-based" },
                            {
                                label: "Apheresis based",
                                key: "apheresis-based",
                                hidden: !isGermanyTeam,
                            },
                        ],
                    }}
                />
            </Box>
            <Box mt={5}>
                <Box display="flex" alignItems="baseline" mb={4}>
                    <Typography variant="h2">Batch payment files</Typography>

                    <Typography ml={1} variant="caption1">
                        {apiResponseCounter(
                            bpfList,
                            fetchingBpfList,
                            "file|files",
                        )}
                    </Typography>
                </Box>
                <Table
                    id={`dashboard-financial-bpf-list`}
                    headers={HEADERS.BPF}
                    rows={bpfList?.data?.records}
                    loading={fetchingBpfList}
                    type={ROWRENDERERCONST.BPF}
                    viewingOptions={viewingOptions}
                    permissions={{ hasPermissionToEditFinancialActions }}
                    emptyMsg="No data"
                    callbacks={{
                        onBpfDownload: (item: string) =>
                            setItemToDownload(item),
                        onProcessedClick: (item: any) => setItem(item),
                        onRemoveTransactionClick: (item: any) =>
                            setFinancialTransaction(item),
                        onBpfDetailsClick: item =>
                            navigate(`/finances/bpf-details/${item.bpfId}/`),

                        closeActionsMenu:
                            !!financialTransaction &&
                            !itemToDownload &&
                            !itemToProcess,
                    }}
                />
            </Box>
        </Fragment>
    );
};
export default BpfList;
