// Lib
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import clonedeep from "lodash.clonedeep";

import React, { useState, useMemo } from "react";

// Own components
import { GrayBox, Select } from "@components";

// Types
import { AdvancedScale, Brand, Brands, Products } from "@types";

// Utils
import { isArrayWithContent } from "@utils";

// Style
import * as style from "./style.module.scss";

// Custom hooks
import { useSelectedCountry } from "@hooks";
import PricingOptionSelector from "./PricingOptionSelector";

type EditProductType = {
    index: number;
    product: {
        productId: string;
        productName: string;
        externalProductCode: string;
        sapSkuNo: string;
        brand: {
            name: string;
            id: string;
        };
    };
    priceCondition: { priceCondition: string };
    priceSource: string;
    amount: string;
    type: string;
    scales: AdvancedScale[];
};

interface Props {
    onSave?: () => void;
    onChange: (
        product: EditProductType & {
            brand: {
                brandName: string;
                brandId: string;
            };
        },
    ) => void;
    onDelete?: () => void;
    onBlur: (event: React.SyntheticEvent) => void;
    errors?: any;
    touched?: Record<string, boolean>;
    products: Products & { disabledProducts?: Array<string> };
    brands?: Brands;
    brand?: Brand;
    setBrand?: (item: Brand) => void;
    onShowPricesClick: () => void;
    id: string;
    product: EditProductType;
    canDelete?: boolean;
    scales?: Array<AdvancedScale>;
    type?: "contractPrice" | "discountPercentage" | "scale";
    amount?: string;
    isVolumeBasedContract?: boolean;
    canSelectPriceCondition?: boolean;
    allowedScaleRange?: { periodFrom: string; periodTo: string };
    handleProductScales?: (scales: Array<AdvancedScale>) => void;
    onDiscard?: () => void;
    canDiscard?: boolean;
    categoryType?: string;
    productExternalCodeLabel?: string;
    currency?: string;
    canAddMultipleProducts?: boolean;
    isInputValuesAllowed?: boolean;
    canAddFixedPrice?: boolean;
    canAddScales?: boolean;
    canAddDiscountPercentage?: boolean;
}

/**
 * Add product
 */
const AddProduct: React.FC<Props> = ({
    onSave,
    onChange,
    onDelete,
    onBlur,
    errors,
    touched,
    product,
    products,
    onShowPricesClick,
    id,
    canDelete = true,
    scales,
    type,
    amount,
    isVolumeBasedContract = false,
    canSelectPriceCondition = true,
    allowedScaleRange,
    handleProductScales,
    onDiscard,
    canDiscard = false,
    categoryType,
    productExternalCodeLabel,
    brands,
    brand,
    setBrand,
    currency,
    canAddMultipleProducts = false,
    canAddFixedPrice = true,
    canAddScales = true,
    canAddDiscountPercentage = true,
}) => {
    const { isUkTeam } = useSelectedCountry();

    // Type error state
    const [typeError, setTypeError]: any = useState({
        type: "",
        error: "",
    });

    /**
     * Validate type
     */
    const onProductSave = () => {
        if (!product?.type) setTypeError("Type is mandatory");

        if (
            product?.type === "discountPercentage" &&
            (+product?.amount < 0.1 || +product?.amount > 100)
        ) {
            setTypeError({
                type: "discountPercentage",
                error: "Value must be between 0 and 100",
            });
            return;
        }

        if (product?.type === "contractPrice" && +product?.amount < 0) {
            setTypeError({
                type: "contractPrice",
                error: "Value must be bigger than 0",
            });
            return;
        }
        setTypeError({ type: "", error: "" });
        if (onSave) onSave();
    };

    /**
     * Change product handler
     */
    const handleChange = (key: string, value: any) => {
        const copyProduct = product ? clonedeep(product) : {};

        if (key === "product" && canSelectPriceCondition) {
            copyProduct["priceCondition"] =
                // Fill in the price condition ZGPR for UK team, if price source is SAP and contract is volume based
                isVolumeBasedContract &&
                isUkTeam &&
                product?.priceSource === "SAP"
                    ? "ZGPR"
                    : undefined;
        }

        if (key === "type") {
            copyProduct["amount"] = "";
            !!typeError?.type && setTypeError({ type: "", error: "" });
        }

        copyProduct[key] = value;
        onChange(copyProduct);
    };

    /**
     * Price condition finder
     * mapper for price condition that we handle on the front-end and sent from the back-end
     */
    const priceConditionFinder = useMemo(
        () =>
            typeof product?.priceCondition === "string"
                ? product.priceCondition
                : product?.priceCondition?.priceCondition || "",
        [product],
    );

    /**
     * Check if product valid
     */
    const canSaveProduct = useMemo(() => {
        const canSaveProductWithoutAmount =
            !!product?.product?.productId &&
            !canAddDiscountPercentage &&
            !canAddFixedPrice;
        const hasProductAndAmount =
            !!product?.product?.productId &&
            !!product.type &&
            ((product.type !== "scale" && !!product?.amount) ||
                (product.type === "scale" &&
                    !!isArrayWithContent(product.scales) &&
                    product?.scales?.every(
                        scale =>
                            !!scale?.periodFrom &&
                            !!scale?.periodTo &&
                            (!!scale?.price || !!scale?.discountPercentage) &&
                            scale?.cumulativePeriods?.every(
                                item =>
                                    !!item?.cumulativePeriodFrom &&
                                    !!item?.cumulativePeriodTo,
                            ),
                    )));

        // Temporary solution for UK, SAP => should priceCondition ZGPR
        const meetTeamConditions =
            isUkTeam && product?.priceSource === "SAP"
                ? priceConditionFinder === "ZGPR"
                : !!priceConditionFinder || !!product?.priceSource;

        return (
            (hasProductAndAmount && meetTeamConditions) ||
            canSaveProductWithoutAmount
        );
    }, [
        product,
        isUkTeam,
        priceConditionFinder,
        canAddDiscountPercentage,
        canAddFixedPrice,
    ]);

    /**
     * Price correction mapper
     */
    const mapPriceCondition = useMemo(() => {
        if (isUkTeam && isVolumeBasedContract) {
            // Volume based contract for UK team
            if (product?.priceSource === "SAP") {
                return priceConditionFinder;
            }
            return product?.priceSource || "";
        }
        return priceConditionFinder || product?.priceSource || "";
    }, [isUkTeam, isVolumeBasedContract, product, priceConditionFinder]);

    return (
        <GrayBox
            padding={"1rem 2rem"}
            header={<Typography variant="h3">Add product</Typography>}
            id={`${id}-add-product`}
        >
            <form
                noValidate
                onBlur={onBlur}
                className={style.form}
                id={`${id}-add-product-form`}
            >
                <Grid
                    item
                    xs={12}
                    container
                    rowSpacing={3}
                    columnSpacing={4}
                    alignItems={"flex-end"}
                >
                    {!!brands && !!setBrand && (
                        <Grid item xs={12} md={6}>
                            <InputLabel
                                error={!!errors?.brand && !!touched?.brand}
                                id={`${id}-select-brand-label`}
                                shrink
                            >
                                {"Brand (*)"}
                            </InputLabel>

                            <Select
                                id={`${id}-select-brand`}
                                value={brand}
                                fullWidth
                                className={style.whiteInput}
                                onChange={(_, index) => {
                                    setBrand(brands?.data[index]);
                                }}
                                disabled={brands?.loading}
                                menuItemLabel={"brandName"}
                                menuItemId="brandId"
                                list={brands.data}
                                loading={brands.loading}
                                name="brand"
                                scrollable
                                error={!!errors?.brand && !!touched?.brand}
                            />
                        </Grid>
                    )}

                    <Grid item xs={12} md={6}>
                        <InputLabel
                            error={!!errors?.product && !!touched?.product}
                            id={`${id}-select-product-label`}
                            shrink
                        >
                            {"SKU name (*)"}
                        </InputLabel>

                        <Select
                            id={`${id}-select-product`}
                            value={product?.product}
                            fullWidth
                            className={
                                !!brands && !brand?.brandId
                                    ? style.disabledField
                                    : style.whiteInput
                            }
                            onChange={(_, index) => {
                                handleChange("product", products?.data[index]);
                            }}
                            disabled={
                                products?.loading ||
                                (!!brands && !brand?.brandId)
                            }
                            menuItemLabel={"productName"}
                            menuItemId="productId"
                            error={!!errors?.product && !!touched?.product}
                            list={products.data}
                            loading={products.loading}
                            name="product"
                            scrollable
                            disabledItems={products?.disabledProducts}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <InputLabel
                            error={!!errors?.products && !!touched?.products}
                            id={`${id}-sku-label`}
                            shrink
                        >
                            {"SKU code"}
                        </InputLabel>
                        <TextField
                            id={`${id}-sku-input`}
                            size="small"
                            autoComplete="off"
                            variant="outlined"
                            disabled={!!brands && !brand?.brandId}
                            className={style.disabledField}
                            inputProps={{ readOnly: true }}
                            fullWidth
                            value={product?.product?.sapSkuNo || ""}
                        />
                    </Grid>

                    <Grid xs={12} md={6} item order={{ xs: 4, md: 3 }}>
                        <Box
                            display="flex"
                            alignItems="flex-start"
                            justifyContent="space-between"
                        >
                            <InputLabel
                                sx={{ paddingTop: "0.5rem" }}
                                error={!!errors?.type && !!touched?.type}
                                id={`${id}-price-condition-label`}
                                shrink
                            >
                                {canSelectPriceCondition
                                    ? "Price condition (*)"
                                    : "Price source"}
                            </InputLabel>

                            {canSelectPriceCondition && (
                                <Button
                                    variant="text"
                                    onClick={onShowPricesClick}
                                    id={`${id}-show-price-conditions-btn`}
                                    disabled={
                                        !product?.product?.sapSkuNo ||
                                        (!!brands && !brand?.brandId)
                                    }
                                >
                                    <Typography
                                        variant="link"
                                        className={style.link}
                                        sx={{
                                            color: !product?.product?.sapSkuNo
                                                ? "text.disabled"
                                                : "",
                                        }}
                                    >
                                        {"Show price table"}
                                    </Typography>
                                </Button>
                            )}
                        </Box>
                        <TextField
                            id={`${id}-price-condition-input`}
                            size="small"
                            variant="outlined"
                            autoComplete="off"
                            className={style.priceCondition}
                            fullWidth
                            inputProps={{ readOnly: true }}
                            value={mapPriceCondition}
                        />
                    </Grid>
                    {!!productExternalCodeLabel &&
                        !!product?.product?.externalProductCode && (
                            <Grid item xs={12} md={6} order={{ xs: 3, md: 4 }}>
                                <InputLabel
                                    error={
                                        !!errors?.products &&
                                        !!touched?.products
                                    }
                                    id={`${id}-externalProductCode-label`}
                                    shrink
                                >
                                    {productExternalCodeLabel}
                                </InputLabel>
                                <TextField
                                    id={`${id}-externalProductCode-input`}
                                    size="small"
                                    autoComplete="off"
                                    variant="outlined"
                                    className={style.disabledField}
                                    inputProps={{ readOnly: true }}
                                    fullWidth
                                    value={
                                        product?.product?.externalProductCode
                                    }
                                />
                            </Grid>
                        )}
                </Grid>

                {isVolumeBasedContract &&
                    (canAddDiscountPercentage ||
                        canAddFixedPrice ||
                        canAddScales) && (
                        <PricingOptionSelector
                            id={id}
                            type={type}
                            amount={amount}
                            handleChange={handleChange}
                            canAddDiscountPercentage={canAddDiscountPercentage}
                            canAddFixedPrice={canAddFixedPrice}
                            canAddScales={canAddScales}
                            errors={errors}
                            touched={touched}
                            brands={brands}
                            brand={brand}
                            typeError={typeError}
                            scales={scales}
                            handleProductScales={handleProductScales}
                            allowedScaleRange={allowedScaleRange}
                            categoryType={categoryType}
                            currency={currency}
                        />
                    )}
            </form>

            {canAddMultipleProducts && (
                <div className={style.actions}>
                    {canDelete && (
                        <Button
                            variant="text"
                            color="primary"
                            onClick={onDelete}
                            size="large"
                        >
                            <Typography variant="caption1" color={"primary"}>
                                {`Delete product`}
                            </Typography>
                        </Button>
                    )}
                    {canDiscard && (
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={onDiscard}
                            size="large"
                        >
                            <Typography variant="caption1" color={"primary"}>
                                {`Discard changes`}
                            </Typography>
                        </Button>
                    )}
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={onProductSave}
                        size="large"
                        disabled={!canSaveProduct}
                    >
                        <Typography variant="button">{`Save product`}</Typography>
                    </Button>
                </div>
            )}
        </GrayBox>
    );
};
export default React.memo(AddProduct);
