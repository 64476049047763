import { atom } from "recoil";

export const createPartnerStepsState = atom({
    key: "createPartnerStepsState",
    default: [
        {
            id: "create-partner",
            title: "Adding a new partner",
            description:
                "Lorem 1 ipsum dolor sit amet, consectetur adipiscing elit. Nunc sed risus ut elit nibh id. Nisl, praesent pulvinar.",
            isPrepared: false,
        },
        {
            id: "partner-overview",
            title: "Partner account was succesfully created",
            description:
                "Lorem 1 ipsum dolor sit amet, consectetur adipiscing elit. Nunc sed risus ut elit nibh id. Nisl, praesent pulvinar.",
            isPrepared: false,
        },
        {
            id: "edit-partner",
            title: "Editing an existing partner",
            description:
                "Lorem 2 ipsum dolor sit amet, consectetur adipiscing elit. Nunc sed risus ut elit nibh id. Nisl, praesent pulvinar.",
            isPrepared: false,
        },
    ],
});
