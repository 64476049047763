//Lib
import {
    Box,
    Typography,
    FormControl,
    FormControlLabel,
    RadioGroup,
    Radio,
    Grid,
} from "@mui/material";
import React, { Fragment } from "react";

// Own components
import { Dialog, FieldRenderer } from "@components";
import { displayDate } from "@utils";

// styles

import * as style from "../style.module.scss";

/**
 * Props
 */
interface Props {
    openDialog: boolean;
    onTaskComplete: () => void;
    loading: boolean;
    onReset: () => void;
    className?: string;
    values: { approved: string; reasonRejection: string };
    onBlur: (event: React.SyntheticEvent) => void;
    onChange: any;
    oldInfusionDate: string;
    newInfusionDate: string;
    cquenceId: string;
}

/**
 * Infusion Date Change
 */
const InfusionDateChange = ({
    openDialog,
    loading,
    onReset,
    onTaskComplete,
    className,
    values,
    onBlur,
    onChange,
    oldInfusionDate,
    newInfusionDate,
    cquenceId,
}: Props) => {
    return (
        <Fragment>
            <Dialog
                id={`tasks-change-infusion-date`}
                title={"Approve infusion date"}
                open={openDialog}
                message={
                    values?.approved === "true" ? (
                        <Typography variant="subtitle2" color="black">
                            You are about to approve an Infusion Date Change and
                            consequently mark task
                            <Typography variant="body2" component="span">
                                {` Approve Infusion Date Change [${cquenceId}] `}
                            </Typography>
                            as complete. Doing so will change the infusion date
                            with the New Infusion Date. This action cannot be
                            undone.
                        </Typography>
                    ) : (
                        <Typography variant="subtitle2" color="black">
                            You are about to reject an Infusion Date Change and
                            consequently mark task
                            <Typography variant="body2" component="span">
                                {` Approve Infusion Date Change [${cquenceId}] `}
                            </Typography>
                            as complete. Doing so will NOT change the Infusion
                            date.
                        </Typography>
                    )
                }
                primaryButton={{
                    text: "Confirm",
                    action: onTaskComplete,
                    loading: loading,
                }}
                secondaryButton={{
                    text: "Cancel",
                    action: onReset,
                }}
            />

            <div className={style.header}>
                <Typography variant="h2">
                    {`Approve infusion date change [${cquenceId}]`}
                </Typography>
            </div>

            <Box mb={3} className={className}>
                <Grid container rowSpacing={3} columnSpacing={4} mb={3}>
                    {!!oldInfusionDate && (
                        <Grid item xs={12} md={6}>
                            <FieldRenderer
                                id={`task-details-reasonRejection`}
                                label="Old infusion date"
                                value={displayDate(oldInfusionDate)}
                            />
                        </Grid>
                    )}
                    {!!newInfusionDate && (
                        <Grid item xs={12} md={6}>
                            <FieldRenderer
                                id={`task-details-reasonRejection`}
                                label="New infusion date"
                                value={displayDate(newInfusionDate)}
                            />
                        </Grid>
                    )}
                </Grid>

                <form noValidate onBlur={onBlur}>
                    <FormControl>
                        <RadioGroup
                            id={`task-details-task-review`}
                            value={values?.approved}
                            onChange={onChange}
                            name="approved"
                        >
                            <FormControlLabel
                                value={"true"}
                                id={`task-details-review-contract-approve`}
                                control={<Radio size="small" />}
                                label={
                                    <Typography
                                        variant="body2"
                                        color="dark.light"
                                    >
                                        I approve this new infusion date
                                    </Typography>
                                }
                            />
                            <FormControlLabel
                                id={`task-details-review-contract-reject`}
                                value={"false"}
                                control={<Radio size="small" />}
                                label={
                                    <Typography
                                        variant="body2"
                                        color="dark.light"
                                    >
                                        I reject this infusion date
                                    </Typography>
                                }
                            />
                        </RadioGroup>
                    </FormControl>
                </form>
            </Box>
        </Fragment>
    );
};

export default React.memo(InfusionDateChange);
