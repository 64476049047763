// extracted by mini-css-extract-plugin
export var content = "style-module--content--6e47e";
export var error = "#ff3131";
export var footer = "style-module--footer--83add";
export var header = "style-module--header--d09e3";
export var largeView = "style-module--largeView--944bf";
export var lessPadding = "style-module--lessPadding--1ffbf";
export var mediumView = "style-module--mediumView--ef54d";
export var modalWrapper = "style-module--modalWrapper--6a618";
export var noScroll = "style-module--noScroll--fba5b";
export var primary = "#036";
export var secondary = "#019cdc";
export var smallView = "style-module--smallView--41a41";
export var textfont = "\"Verdana Pro\"";
export var titlefont = "\"Karbon\"";
export var white = "#fff";
export var withScroll = "style-module--withScroll--411dd";