// Icons
import DownloadIcon from "@mui/icons-material/Download";
import ClearIcon from "@mui/icons-material/Clear";

// Libs
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import Tooltip from "@mui/material/Tooltip";
import React, { Fragment, useEffect, useState } from "react";

// Styles
import * as style from "./style.module.scss";

/**
 * Props type
 */
interface Props {
    label?: string;
    value: string | undefined;
    type?: string;
    multiline?: boolean;
    id: string;
    darkBg?: boolean;
    hasDownloadAction?: boolean;
    hasDeleteAction?: boolean;
    onDownloadClick?: () => void;
    onDeleteClick?: () => void;
    loading?: boolean;
    disabledAction?: boolean;
    showTooltip?: boolean;
    coloredBg?: boolean;
    hyperlink?: boolean;
    sx?: Record<string, unknown>;
    currency?: string;
}

/**
 * Field props
 */
type Field = Props & { disabled: boolean; hyperlink?: boolean };

/**
 * Field component
 */
const Field = ({
    label,
    value,
    hasDownloadAction,
    hasDeleteAction,
    onDownloadClick,
    onDeleteClick,
    loading,
    type,
    multiline,
    id,
    darkBg,
    disabledAction,
    coloredBg,
    disabled,
    hyperlink,
    sx,
    currency,
}: Field) => {
    const [downloadKey, setDownloadKey] = useState<string | null>(null);

    useEffect(() => {
        if (!hasDownloadAction) return;

        if (!loading) {
            setDownloadKey(null);
        }
    }, [hasDownloadAction, loading]);

    const downloading = loading && downloadKey === id;

    return !!value && hyperlink ? (
        <div className={style.link} id={`${id}-input-hyperlink`}>
            <a href={value} target="_blank" rel="noopener noreferrer">
                <Typography variant="caption1">{value}</Typography>
            </a>
        </div>
    ) : (
        <TextField
            id={`${id}-input`}
            fullWidth
            name={label}
            multiline={multiline}
            rows={5}
            value={
                value
                    ? `${value?.toString()} ${currency || ""}`
                    : "Not specified"
            }
            variant="outlined"
            size="small"
            sx={{
                ...sx,
                "& .MuiInputBase-input, .MuiOutlinedInput-root, .MuiOutlinedInput-root:hover, .MuiOutlinedInput-root.Mui-disabled":
                    {
                        cursor: "default",
                        fieldset: {
                            borderColor: "#BFCCDD !important",
                            borderWidth: "1px !important",
                        },
                    },
                "& .MuiInputBase-input.Mui-disabled": {
                    WebkitTextFillColor: !disabled ? "black" : "#A7A7A7",
                },
                "& .Mui-focused": {
                    fieldset: {
                        borderColor: "#BFCCDD !important",
                        borderWidth: "1px !important",
                    },
                },

                width: type === "number" ? "4.8em" : "100%",
                backgroundColor: darkBg
                    ? "#A7A7A7"
                    : typeof coloredBg === "boolean"
                      ? coloredBg === false
                          ? "#FFE6E6"
                          : "#E4F8EE"
                      : "#fff",
                borderRadius: darkBg ? "4px" : "initial",
            }}
            autoComplete="off"
            disabled={disabled}
            InputProps={{
                readOnly: true,
                endAdornment:
                    hasDownloadAction || hasDeleteAction ? (
                        <InputAdornment position="end">
                            {downloading && (
                                <CircularProgress color="inherit" size={20} />
                            )}

                            {!downloading && hasDownloadAction && (
                                <IconButton
                                    id={`${id}-download-button`}
                                    color="primary"
                                    aria-label="download"
                                    component="span"
                                    onClick={() => {
                                        onDownloadClick && onDownloadClick();
                                        setDownloadKey(id);
                                    }}
                                    size="small"
                                    disabled={disabledAction || loading}
                                >
                                    <DownloadIcon
                                        color={
                                            disabledAction || loading
                                                ? "disabled"
                                                : "primary"
                                        }
                                    />
                                </IconButton>
                            )}

                            {!downloading && hasDeleteAction && (
                                <IconButton
                                    id={`${id}-delete-button`}
                                    color="primary"
                                    aria-label="delete"
                                    component="span"
                                    onClick={onDeleteClick}
                                    disabled={disabledAction}
                                    size="small"
                                >
                                    <ClearIcon
                                        color={
                                            disabledAction
                                                ? "disabled"
                                                : "primary"
                                        }
                                    />
                                </IconButton>
                            )}
                        </InputAdornment>
                    ) : undefined,
            }}
        />
    );
};

/**
 * Field Renderer
 */
const FieldRenderer: React.FC<Props> = ({
    label,
    value,
    hasDownloadAction,
    hasDeleteAction,
    onDownloadClick,
    onDeleteClick,
    loading,
    type,
    multiline,
    id,
    darkBg,
    disabledAction,
    showTooltip,
    coloredBg,
    hyperlink,
    sx,
    currency,
}) => {
    const disabled = value === undefined || value === null || value === "";

    return (
        <Fragment>
            {!!label && <InputLabel shrink>{label}</InputLabel>}
            {showTooltip && !!value ? (
                <Tooltip arrow title={value} placement="top">
                    <div>
                        <Field
                            id={id}
                            label={label}
                            multiline={multiline}
                            value={value}
                            disabled={disabled}
                            type={type}
                            darkBg={darkBg}
                            coloredBg={coloredBg}
                            hasDownloadAction={hasDownloadAction}
                            hasDeleteAction={hasDeleteAction}
                            loading={loading}
                            onDownloadClick={onDownloadClick}
                            onDeleteClick={onDeleteClick}
                            disabledAction={disabledAction}
                            hyperlink={hyperlink}
                            sx={sx}
                            currency={currency}
                        />
                    </div>
                </Tooltip>
            ) : (
                <Field
                    id={id}
                    label={label}
                    multiline={multiline}
                    value={value}
                    disabled={disabled}
                    type={type}
                    darkBg={darkBg}
                    coloredBg={coloredBg}
                    hasDownloadAction={hasDownloadAction}
                    hasDeleteAction={hasDeleteAction}
                    loading={loading}
                    onDownloadClick={onDownloadClick}
                    onDeleteClick={onDeleteClick}
                    disabledAction={disabledAction}
                    hyperlink={hyperlink}
                    sx={sx}
                    currency={currency}
                />
            )}
        </Fragment>
    );
};

export default React.memo(FieldRenderer);
