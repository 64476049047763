// Libs
import { Grid, Typography, Box, InputLabel, TextField } from "@mui/material";

import React, { useState } from "react";

// Constants
import { HEADERS, ROWRENDERERCONST } from "@constants";

// Own components
import { Table, Modal } from "@components";

//hooks
import { useMaintenance } from "@hooks";
import { isSuccessfulCall, blockInvalidNumber } from "@utils";

/**
 * Props type
 */
interface Props {
    id?: string;
}

/**
 * Maintenance Users
 */
const MaintenanceUsers = ({ id }: Props) => {
    const [openModal, toggleModal] = useState(false);
    const [user, setUser] = useState({});
    const [touched, setTouched] = useState({});

    /**
     * API
     */
    const {
        list,
        loading: { creating, listLoading },
        upsert,
    } = useMaintenance("user", "user|users");

    const onChange = (field: string, value: string) => {
        const copyUser = { ...user };
        copyUser[field] = value;
        setUser(copyUser);
    };

    /**
     * Reset all states
     */
    const resetAll = () => {
        toggleModal(false);
        setUser({});
        setTouched({});
    };

    const handleSubmit = () => {
        const mappedTitle =
            user["firstName"] && user["lastName"]
                ? `${user["firstName"]} ${user["lastName"]}`
                : user["email"];
        upsert(user, mappedTitle).then(res => {
            if (isSuccessfulCall(res?.status)) {
                resetAll();
            }
        });
    };

    /**
     * Render
     */
    return (
        <Box mt={5}>
            <Modal
                open={openModal}
                id={`${id}-edit-user`}
                title={"Edit User"}
                onClose={resetAll}
                primaryButton={{
                    action: handleSubmit,
                    text: "Save",
                    loading: creating,
                    disabled:
                        creating ||
                        !user["firstName"] ||
                        !user["lastName"] ||
                        !user["wwid"],
                }}
                secondaryButton={{
                    action: resetAll,
                    text: "Cancel",
                }}
            >
                <Grid container spacing={2}>
                    <Grid item xs={4}>
                        <InputLabel
                            error={touched["firstName"] && !user["firstName"]}
                            shrink
                        >
                            {"First Name (*)"}
                        </InputLabel>
                        <TextField
                            value={user["firstName"] || ""}
                            id={`${id}-edit-user-firstName`}
                            fullWidth
                            name={"firstName"}
                            autoComplete="off"
                            error={touched["firstName"] && !user["firstName"]}
                            disabled={creating}
                            variant="outlined"
                            size="small"
                            onBlur={() =>
                                setTouched({ ...touched, firstName: true })
                            }
                            onChange={event =>
                                onChange("firstName", event.target.value)
                            }
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <InputLabel
                            error={touched["lastName"] && !user["lastName"]}
                            shrink
                        >
                            {"Last Name (*)"}
                        </InputLabel>
                        <TextField
                            value={user["lastName"] || ""}
                            fullWidth
                            name={"lastName"}
                            id={`${id}-edit-user-lastName`}
                            autoComplete="off"
                            error={touched["lastName"] && !user["lastName"]}
                            disabled={creating}
                            variant="outlined"
                            onBlur={() =>
                                setTouched({ ...touched, lastName: true })
                            }
                            size="small"
                            onChange={event =>
                                onChange("lastName", event.target.value)
                            }
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <InputLabel
                            shrink
                            error={touched["wwid"] && !user["wwid"]}
                        >
                            {"WWID (*)"}
                        </InputLabel>

                        <TextField
                            id={`${id}-edit-user-wwid`}
                            name={"wwid"}
                            variant="outlined"
                            error={touched["wwid"] && !user["wwid"]}
                            fullWidth
                            value={user["wwid"]}
                            onBlur={() =>
                                setTouched({ ...touched, wwid: true })
                            }
                            autoComplete="off"
                            disabled={creating}
                            onChange={event => {
                                const inputValue = event.target.value;
                                // Remove any dots (.) or commas (,) from the input value
                                const sanitizedValue = inputValue.replace(
                                    /[.,]/g,
                                    "",
                                );

                                onChange(
                                    "wwid",
                                    sanitizedValue === "" ? "" : sanitizedValue,
                                );
                            }}
                            type="number"
                            onKeyDown={e => blockInvalidNumber(e, true)}
                            inputProps={{
                                min: 0,
                                inputMode: "numeric",
                                pattern: "[0-9]*",
                            }}
                            size="small"
                        />
                    </Grid>
                </Grid>
            </Modal>

            <Box mb={4}>
                <Typography variant="h2">Users</Typography>
            </Box>
            <Table
                loading={listLoading}
                headers={HEADERS.MAINTENANCE_USERS}
                rows={list?.maintenanceUserList || []}
                type={ROWRENDERERCONST.MAINTENANCE_USERS}
                id={`${id}-maintenance-users`}
                callbacks={{
                    onUserEdit: (rowIndex: number) => {
                        toggleModal(true);
                        setUser(list?.maintenanceUserList[rowIndex]);
                    },
                }}
                emptyMsg="No data"
            />
        </Box>
    );
};
export default MaintenanceUsers;
