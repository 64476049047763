import { Dialog as _Dialog } from "@mui/material";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import * as React from "react";

//import styles
import * as style from "./style.module.scss";

interface Action {
    text?: string;
    action: () => void;
    disabled?: boolean;
    loading?: boolean;
}

interface Props {
    open: boolean;
    message?: string | React.ReactNode;
    primaryButton: Action;
    secondaryButton: Action;
    handleClose?: () => void;
    title?: string | React.ReactNode;
    inputField?: React.ReactNode;
    fullWidth?: boolean;
    id: string;
}

const Dialog: React.FC<Props> = ({
    open,
    message,
    secondaryButton,
    primaryButton,
    title,
    handleClose,
    inputField,
    fullWidth,
    id,
}) => {
    return (
        <_Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className={style.dialog}
            classes={{ paper: style.paper }}
            fullWidth={fullWidth}
            id={`${id}-dialog`}
        >
            {title && (
                <DialogTitle id={`${id}-dialog-title`}>{title}</DialogTitle>
            )}
            <DialogContent id={`${id}-dialog-content`}>
                {message && (
                    <DialogContentText id="alert-dialog-description">
                        {typeof message === "string" ? (
                            <Typography variant="subtitle2" color="black">
                                {message}
                            </Typography>
                        ) : (
                            message
                        )}
                    </DialogContentText>
                )}
                {inputField}
            </DialogContent>
            <DialogActions>
                <Button
                    id={`${id}-secondary-btn`}
                    onClick={secondaryButton.action}
                    disabled={
                        secondaryButton.disabled || !!primaryButton?.loading
                    }
                    size="large"
                    sx={{ fontSize: "0.75rem" }}
                >
                    <Typography variant="button">
                        {secondaryButton.text}
                    </Typography>
                </Button>
                <Button
                    onClick={primaryButton.action}
                    autoFocus
                    id={`${id}-primary-btn`}
                    disabled={
                        primaryButton.disabled || !!primaryButton?.loading
                    }
                    variant="outlined"
                    size="large"
                    sx={{ fontSize: "0.75rem" }}
                >
                    {primaryButton?.loading ? (
                        <CircularProgress size={25} color="inherit" />
                    ) : (
                        <Typography variant="button">
                            {primaryButton.text}
                        </Typography>
                    )}
                </Button>
            </DialogActions>
        </_Dialog>
    );
};

export default React.memo(Dialog);
