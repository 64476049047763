//Lib
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { navigate } from "gatsby";
import React, { useEffect, useMemo, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";

//Own components
import {
    ContractReview as ContractSummary,
    Modal,
    TeamViewer,
    LoadingWrapper,
} from "@components";

// Custom hooks
import { useUpdateStep, useAccountTeamUsers } from "@hooks";

//Atoms
import {
    contractMilestonesState,
    contractReviewState,
    createPerformanceBasedContractStepsState,
    financialConditionsState,
    generalInformationState,
    productsDetailsState,
    teamState,
    contractPartnersState,
    selectModelState,
    selectCategoriesState,
} from "@atoms";

// Types
import type { Location } from "@types";

// Utils
import { isArrayWithContent } from "@utils";

/**
 * Props
 */
interface Props {
    location?: Location;
    id?: string;
    contractFlowPath?: string;
    contractType?: "VOLUME" | "PERFORMANCE";
}

/**
 * Contract Review
 */
const ContractReview = ({
    location,
    id,
    contractFlowPath,
    contractType,
}: Props) => {
    const [team] = useRecoilState<any>(teamState);

    const [showReviewers, toggleModal] = useState(false);

    // update step hook
    const updateStepValidation = useUpdateStep(
        location,
        createPerformanceBasedContractStepsState,
    );

    // Fetch team users
    const {
        getAccountTeamUsers,
        response: teamUsers,
        loading: fetchingUsers,
    } = useAccountTeamUsers();

    /**
     * Atoms
     */
    const steps = useRecoilValue(createPerformanceBasedContractStepsState);
    const selectedModel = useRecoilValue(selectModelState);
    const selectedCategories = useRecoilValue(selectCategoriesState);
    const generalInformation = useRecoilValue(generalInformationState);
    const productsDetails = useRecoilValue(productsDetailsState);
    const contractMilestones = useRecoilValue(contractMilestonesState);
    const financialConditions = useRecoilValue(financialConditionsState);
    const additionalPartners = useRecoilValue(contractPartnersState);

    const [contractReviewInitialsState, updateContractReviewState] =
        useRecoilState(contractReviewState);

    /**
     * Check if the flow prepared
     */
    const isFlowNotPrepared = useMemo(() => {
        if (!steps) return;
        return steps.some(step => !step.isPrepared);
    }, [steps]);

    useEffect(() => {
        updateStepValidation(!isFlowNotPrepared);
    }, [isFlowNotPrepared]);

    /**
     * Fetch users
     */
    useEffect(() => {
        if (!showReviewers) return;

        getAccountTeamUsers(team.teamId);
    }, [showReviewers]);

    return (
        <>
            <Modal
                title={"Contract reviewers"}
                id={`${id}-contract-reviewers-model`}
                open={showReviewers}
                onClose={() => toggleModal(false)}
                noScroll={true}
                smallView
                lessPadding
            >
                <LoadingWrapper
                    id={`${id}-contract-reviewers-loading`}
                    loading={fetchingUsers}
                    customMsg={
                        isArrayWithContent(teamUsers?.data?.records)
                            ? undefined
                            : "No users found!"
                    }
                >
                    <TeamViewer
                        id={`${id}-contract-team-members`}
                        showFullName
                        team={teamUsers?.data?.records}
                        smallView
                    />
                </LoadingWrapper>
            </Modal>

            <Box mb={5}>
                <Typography variant="h2">Review your contract</Typography>
            </Box>
            <ContractSummary
                id={`${id}-contract-review`}
                contractType={contractType}
                generalInformation={generalInformation}
                model={selectedModel}
                currency={selectedModel?.currency}
                categories={selectedCategories}
                productsDetails={productsDetails?.products}
                contractMilestones={contractMilestones?.milestones}
                additionalPartners={additionalPartners}
                comments={contractReviewInitialsState?.comments}
                financialConditions={financialConditions?.conditions}
                isCreateContract
                callbacks={{
                    onChangeComment: value => {
                        updateContractReviewState({ comments: value });
                    },
                    onEditClick: path => {
                        if (!path || !contractFlowPath) return;
                        navigate(
                            `/create-contract/${contractFlowPath}/${path}/`,
                        );
                    },
                    loadReviewers: () => toggleModal(true),
                }}
            />
        </>
    );
};

export default ContractReview;
