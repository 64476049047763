// extracted by mini-css-extract-plugin
export var alert = "style-module--alert--2501a";
export var error = "style-module--error--1bfa2";
export var info = "style-module--info--ad886";
export var primary = "#036";
export var secondary = "#019cdc";
export var snackbar = "style-module--snackbar--79133";
export var success = "style-module--success--91bed";
export var textfont = "\"Verdana Pro\"";
export var titlefont = "\"Karbon\"";
export var warning = "style-module--warning--97c97";
export var white = "#fff";