//import libs
import { Box, TableCell, TableRow, Typography } from "@mui/material";
import React from "react";

// import own components
import { ActionMenu } from "@components";

interface Row {
    countryIsoCode: string;
    brandId: string;
    salesOrg: string;
    division: string;
    distributionChannel: string;
    orderType: string;
    conditionType: string;
    bpfId: string;
    entryType: string;
    legalEntity: string;
    fullReportingMrc: string;
    organizationalUnit: string;
    transactionType: string;
    debetCredit: string;
    accountType: string;
    paymentTerms: string;
    paymentMethod: string;
    glAccount: string;
    commodityCode: string;
    companyCode: string;
    taxCode: string;
    referenceDocument: string;
    cnReferenceQualifier: string;
    ftvId: string;
    hccGovernmentIndicator: string;
    hccProductRebate: string;
    hccProfessionalPayment: string;
}

interface Props {
    row: Row;
    rowIndex: number;
    id: string;
    isColVisible: (col: string) => boolean;
    callbacks: any;
    mapper: (
        field: string,
        id: string,
    ) => { brandName?: string; country?: string };
}

const TransactionValuesRow = ({
    id,
    row,
    rowIndex,
    callbacks,
    mapper,
}: Props) => {
    return (
        <TableRow>
            <TableCell>
                <Typography variant="caption2">
                    {mapper("countries", row.countryIsoCode)?.country}
                </Typography>
            </TableCell>

            <TableCell>
                <Typography variant="caption2">
                    {mapper("brands", row.brandId)?.brandName}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography variant="caption2">{row.salesOrg}</Typography>
            </TableCell>
            <TableCell>
                <Typography variant="caption2">{row.division}</Typography>
            </TableCell>

            <TableCell>
                <Typography variant="caption2">
                    {row.distributionChannel}
                </Typography>
            </TableCell>

            <TableCell>
                <Typography variant="caption2">{row.orderType}</Typography>
            </TableCell>

            <TableCell>
                <Typography variant="caption2">{row.conditionType}</Typography>
            </TableCell>
            <TableCell>
                <Typography variant="caption2">{row.bpfId}</Typography>
            </TableCell>

            <TableCell>
                <Typography variant="caption2">{row.entryType}</Typography>
            </TableCell>

            <TableCell>
                <Typography variant="caption2">{row.legalEntity}</Typography>
            </TableCell>

            <TableCell>
                <Typography variant="caption2">
                    {row.fullReportingMrc}
                </Typography>
            </TableCell>

            <TableCell>
                <Typography variant="caption2">
                    {row.organizationalUnit}
                </Typography>
            </TableCell>

            <TableCell>
                <Typography variant="caption2">
                    {row.transactionType}
                </Typography>
            </TableCell>

            <TableCell>
                <Typography variant="caption2">{row.debetCredit}</Typography>
            </TableCell>

            <TableCell>
                <Typography variant="caption2">{row.accountType}</Typography>
            </TableCell>

            <TableCell>
                <Typography variant="caption2">{row.paymentTerms}</Typography>
            </TableCell>

            <TableCell>
                <Typography variant="caption2">{row.paymentMethod}</Typography>
            </TableCell>

            <TableCell>
                <Typography variant="caption2">{row.glAccount}</Typography>
            </TableCell>

            <TableCell>
                <Typography variant="caption2">{row.commodityCode}</Typography>
            </TableCell>

            <TableCell>
                <Typography variant="caption2">{row.companyCode}</Typography>
            </TableCell>

            <TableCell>
                <Typography variant="caption2">{row.taxCode}</Typography>
            </TableCell>

            <TableCell>
                <Typography variant="caption2">
                    {row.referenceDocument}
                </Typography>
            </TableCell>

            <TableCell>
                <Typography variant="caption2">
                    {row.cnReferenceQualifier}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography variant="caption2">
                    {row.hccProfessionalPayment}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography variant="caption2">
                    {row.hccProductRebate}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography variant="caption2">
                    {row.hccGovernmentIndicator}
                </Typography>
            </TableCell>

            <TableCell align="right">
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                >
                    <ActionMenu
                        id={`${id}-${rowIndex}-transaction-row-btn"`}
                        autoHide
                        options={[
                            {
                                label: "Edit",
                                action: () =>
                                    callbacks && callbacks?.editItem(rowIndex),
                            },
                        ]}
                        rowIndex={rowIndex}
                    />
                </Box>
            </TableCell>
        </TableRow>
    );
};

export default TransactionValuesRow;
