// extracted by mini-css-extract-plugin
export var block = "style-module--block--039ac";
export var card = "style-module--card--7660c";
export var details = "style-module--details--ddec9";
export var error = "#ff3131";
export var inputSection = "style-module--inputSection--f5cda";
export var link = "style-module--link--23277";
export var linkOrder = "style-module--linkOrder--da399";
export var list = "style-module--list--9caf0";
export var primary = "#036";
export var secondary = "#019cdc";
export var separator = "style-module--separator--e2640";
export var textfont = "\"Verdana Pro\"";
export var titlefont = "\"Karbon\"";
export var white = "#fff";
export var wrapper = "style-module--wrapper--9172d";