// Lib
import { CircularProgress, Box, Typography, Button } from "@mui/material";
import classames from "classnames";
import React, { Fragment } from "react";

// Styles
import * as style from "./style.module.scss";

/**
 * Props type
 */
interface Props {
    id: string;
    children: React.ReactNode;
    loading: boolean | undefined;
    fullHeight?: boolean;
    height?: string;
    sx?: any;
    error?: any;
    customMsg?: string;
    errorMsg?: string;
    callback?: () => void;
    customCallbackBtn?: React.ReactNode;
    NoEmptyPage?: boolean;
}

/**
 * Loading wrapper
 */
const LoadingWrapper: React.FC<Props> = ({
    loading,
    children,
    fullHeight,
    sx,
    customMsg,
    error,
    callback,
    id,
    customCallbackBtn,
    height,
    errorMsg,
    NoEmptyPage = false,
}) => {
    if (!!NoEmptyPage && loading === undefined) return null;

    return (
        <Fragment>
            {loading && (
                <Box
                    className={classames({
                        [style.fullHeight]: !height && fullHeight,
                    })}
                    id={`${id}-loading-box`}
                    height={height ? height : fullHeight ? 1 : undefined}
                    justifyContent="center"
                    display="flex"
                    alignItems={height || fullHeight ? "center" : "flex-start"}
                    sx={sx}
                    width={height || fullHeight ? 1 : undefined}
                >
                    <CircularProgress />
                </Box>
            )}
            {!loading && (errorMsg || !!error || customMsg) && (
                <Box
                    className={classames({
                        [style.fullHeight]: !height && fullHeight,
                    })}
                    display="flex"
                    justifyContent="center"
                    mt={4}
                    flexDirection="column"
                    alignItems="center"
                    width={height || fullHeight ? 1 : undefined}
                >
                    {
                        <Fragment>
                            {(!!error || !!errorMsg) && (
                                <Typography
                                    variant="caption1"
                                    id={`${id}-error-msg`}
                                    mb={1}
                                >
                                    {errorMsg ||
                                        "There was an unexpected error loading the data."}
                                </Typography>
                            )}

                            {!!customMsg && (
                                <Typography
                                    variant="caption1"
                                    id={`${id}-error-msg`}
                                    mb={1}
                                >
                                    {customMsg}
                                </Typography>
                            )}

                            {!!callback && (
                                <Typography
                                    variant="caption1"
                                    id={`${id}-error-msg`}
                                    mb={4}
                                    display="block"
                                >
                                    Please refresh the page.
                                </Typography>
                            )}
                        </Fragment>
                    }
                    {!!callback &&
                        (customCallbackBtn ? (
                            customCallbackBtn
                        ) : (
                            <Button
                                id={`${id}-re-call-btn`}
                                variant="contained"
                                color="primary"
                                component="span"
                                onClick={callback}
                                size={"large"}
                            >
                                <Typography variant="button">
                                    Refresh
                                </Typography>
                            </Button>
                        ))}
                </Box>
            )}

            {!loading && !error && children}
        </Fragment>
    );
};
export default React.memo(LoadingWrapper);
