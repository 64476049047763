// Lib
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import classnames from "classnames";
import React from "react";

// Own components
import { FormFooter, InfoBox, FieldRenderer } from "@components";

// Types
import {
    Category,
    Model,
    Account,
    Contract,
    FinancialCondition,
    Status,
} from "@types";

// Utils
import { isArrayWithContent } from "@utils";

// Style
import * as style from "./style.module.scss";

// Own summary components
import GeneralInformationSummary from "./GeneralInformationSummary";
import ProductsDetailsSummary from "./ProductsDetailsSummary";
import MilestonesSummary from "./MilestonesSummary";
import FinancialConditionsSummary from "./FinancialConditionsSummary";
import EvidencesSummary from "./EvidencesSummary";
import ContractClassification from "./ContractClassification";
import AdditionalPartnersSummary from "./AdditionalPartnersSummary";

/**
 * Props
 */
interface Props {
    currentStep?: any;
    hideEdit?: boolean;
    generalInformation: any;
    productsDetails?: any;
    contractMilestones?: Array<{
        description: string;
        id: string;
        number: number;
    }>;
    comments?: string;
    reasonRejection?: string;
    callbacks?: {
        onEditClick?: (path: string) => void;
        onBlur?: (
            event?: React.SyntheticEvent,
            field?: string | undefined,
        ) => void;
        onFieldChange?: (key: string, value: string) => void;
        onChangeComment?: (value: string) => void;
        onEditContractItem?: (values: any) => void;
        loadReviewers?: () => void;
        onMilestonesChange?: (milestones: Array<any>) => void;
        setDisableSaveButton?: (value: boolean) => void;
    };
    financialConditions?: any;
    fields?: Record<string, string | undefined>;
    isDuplicateContract?: boolean;
    isEditContract?: boolean;
    partners?: {
        loading: boolean;
        data: Array<Account>;
        onSearch: (query: string) => void;
    };
    contractRefValidation?: {
        isNotUniqueValue: boolean;
        isNotUniqueMessage: boolean;
        duplicationValidating?: boolean;
    };
    touched?: Record<string, boolean>;
    id: string;
    evidences?: {
        loading: boolean;
        data: Array<{ evidenceId: string; fileName: string }>;
        downloadEvidence?: (evidenceId: string) => void;
    };
    className?: string;
    shrinkColumns?: boolean;
    additionalPartners: Contract["additionalPartners"];
    financialConditionsList?: {
        load: () => void;
        data: FinancialCondition[];
        loading: boolean;
    };
    categories?: Array<Category>;
    model?: Model & { id?: string };
    contractType?: "VOLUME" | "PERFORMANCE";
    modelCategories?: {
        load: () => Promise<any>;
        loading: boolean;
        list: Array<any>;
    };
    productsList?: {
        load: (brandId: string) => void;
        data: Array<any>;
        loading: boolean;
        error: any;
    };
    productPrices?: {
        load: (sapSkuNo: string, pricesFrom: string, pricesTo: string) => void;
        data: Array<any>;
        loading: boolean;
        error: any;
    };
    disableEdit?: boolean;
    isCreateContract?: boolean;
    status?: Status;
    currency: string;
    isApheresisBasedContract?: boolean;
}

/**
 * Contract Review
 */
const ContractReview: React.FC<Props> = ({
    className,
    generalInformation,
    productsDetails,
    contractMilestones,
    comments,
    id,
    hideEdit = false,
    callbacks,
    financialConditions,
    isDuplicateContract,
    isEditContract,
    fields,
    contractRefValidation,
    touched,
    partners,
    evidences,
    shrinkColumns,
    reasonRejection,
    additionalPartners,
    financialConditionsList,
    model,
    categories,
    contractType,
    modelCategories,
    productsList,
    productPrices,
    disableEdit,
    isCreateContract = false,
    status,
    currency,
    isApheresisBasedContract,
}) => {
    return (
        <Box
            display="flex"
            flexDirection="column"
            id={id}
            className={classnames(className)}
        >
            <div>
                <ContractClassification
                    id={id}
                    hideEdit={hideEdit}
                    disableEdit={disableEdit}
                    callbacks={callbacks}
                    model={model}
                    categories={categories}
                    contractType={contractType}
                    shrinkColumns={shrinkColumns}
                    isEditContract={isEditContract}
                    isCreateContract={isCreateContract}
                    modelCategories={modelCategories}
                    status={status}
                />

                {!!generalInformation && (
                    <GeneralInformationSummary
                        generalInformation={generalInformation}
                        id={id}
                        hideEdit={hideEdit}
                        callbacks={callbacks}
                        isDuplicateContract={isDuplicateContract}
                        fields={fields}
                        disableEdit={disableEdit}
                        contractRefValidation={contractRefValidation}
                        touched={touched}
                        partners={partners}
                        shrinkColumns={shrinkColumns}
                        isEditContract={isEditContract}
                        isCreateContract={isCreateContract}
                    />
                )}

                <Grid item xs={12} container columnSpacing={5}>
                    {!!productsDetails && (
                        <ProductsDetailsSummary
                            productsDetails={productsDetails}
                            id={id}
                            hideEdit={hideEdit}
                            callbacks={callbacks}
                            shrinkColumns={shrinkColumns}
                            disableEdit={disableEdit}
                            brand={generalInformation.brand}
                            isEditContract={isEditContract}
                            productsList={productsList}
                            generalInformation={generalInformation}
                            productPrices={productPrices}
                            model={model}
                            contractType={contractType}
                            isCreateContract={isCreateContract}
                            categories={categories}
                            currency={currency}
                        />
                    )}

                    {!!contractMilestones &&
                        !!isArrayWithContent(contractMilestones) &&
                        !!isArrayWithContent(productsDetails) && (
                            <MilestonesSummary
                                brand={generalInformation.brand}
                                productDetails={productsDetails[0]}
                                contractMilestones={contractMilestones}
                                id={id}
                                disableEdit={disableEdit}
                                hideEdit={hideEdit}
                                callbacks={callbacks}
                                shrinkColumns={shrinkColumns}
                                isEditContract={isEditContract}
                                isCreateContract={isCreateContract}
                                currency={currency}
                            />
                        )}
                </Grid>
                {!!isArrayWithContent(productsDetails) && (
                    <FinancialConditionsSummary
                        brand={generalInformation.brand}
                        productDetails={productsDetails[0]}
                        financialConditions={financialConditions}
                        id={id}
                        hideEdit={hideEdit}
                        disableEdit={disableEdit}
                        callbacks={callbacks}
                        shrinkColumns={shrinkColumns}
                        financialConditionsList={financialConditionsList}
                        isEditContract={isEditContract}
                        isCreateContract={isCreateContract}
                        currency={currency}
                    />
                )}

                <AdditionalPartnersSummary
                    id={id}
                    hideEdit={hideEdit}
                    callbacks={callbacks}
                    shrinkColumns={shrinkColumns}
                    disableEdit={disableEdit}
                    isEditContract={isEditContract}
                    additionalPartners={additionalPartners}
                    partners={partners}
                    isCreateContract={isCreateContract}
                    primaryPartnerId={
                        generalInformation?.primaryContractPartner?.id
                    }
                    hasOnlyOnePartner={isApheresisBasedContract}
                />

                {!isDuplicateContract && !!evidences && (
                    <EvidencesSummary id={id} evidences={evidences} />
                )}

                {!hideEdit && (isCreateContract || isDuplicateContract) && (
                    <Box mt={5}>
                        <InfoBox
                            id="submitting-warning"
                            warning
                            message={
                                <Box>
                                    <Typography
                                        variant="subtitle2"
                                        component="span"
                                    >
                                        {`After submitting this contract, it will be
                                    reviewed by members of your contract team.
                                    You can leave a comment for them below. `}
                                    </Typography>
                                    <Typography
                                        variant="subtitle2"
                                        component="span"
                                        onClick={callbacks?.loadReviewers}
                                        className={style.clickableText}
                                    >
                                        View contract reviewers
                                    </Typography>
                                </Box>
                            }
                        />
                    </Box>
                )}

                {!!comments && !callbacks?.onChangeComment && (
                    <Box my={5}>
                        <FieldRenderer
                            id={`${id}-comments`}
                            label="Comments"
                            multiline
                            value={comments || ""}
                        />
                    </Box>
                )}

                {!!callbacks?.onChangeComment && (
                    <Box my={5}>
                        <Typography variant="caption1" color="text.secondary">
                            Comments
                        </Typography>
                        <TextField
                            fullWidth
                            name={"comments"}
                            multiline
                            rows={5}
                            value={comments}
                            disabled={disableEdit}
                            onChange={event =>
                                !hideEdit &&
                                !!callbacks?.onChangeComment &&
                                callbacks.onChangeComment(event.target.value)
                            }
                            variant="outlined"
                            inputProps={{
                                readOnly: hideEdit,
                            }}
                        />
                    </Box>
                )}

                {!!reasonRejection && (
                    <Box my={4}>
                        <FieldRenderer
                            id={`${id}-reasonRejection`}
                            label="Reason of rejection"
                            multiline
                            value={reasonRejection || ""}
                        />
                    </Box>
                )}
            </div>
            <FormFooter
                id={`${id}-contract-view-footer`}
                showText={false}
                textAlign="right"
                error={false}
            />
        </Box>
    );
};

export default ContractReview;
