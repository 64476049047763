// Lib
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React, { useCallback, useMemo, ReactNode } from "react";

// Types
import type { FlowStep, Location } from "@types";

// Utils
import { isArrayWithContent } from "@utils";

// Own components
import Step from "./Step/index";

// Styles
import * as style from "./style.module.scss";

/**
 * Props type
 */
interface Props {
    steps?: Array<FlowStep>;
    title: string;
    disabled?: boolean;
    subTitle?: string | ReactNode;
    onStepClick?: (path: string) => void;
    location?: Location;
    id: string;
    notPreparedIndex?: number;
}

/**
 * Stepper
 */
const Stepper: React.FC<Props> = ({
    steps,
    title,
    subTitle,
    disabled,
    onStepClick,
    location,
    id,
    notPreparedIndex,
}) => {
    const titleMapper = useMemo(() => {
        if (!title) return;
        return title.charAt(0).toUpperCase() + title.slice(1);
    }, [title]);

    const findStepInProgress = useCallback(
        (index: number) => {
            if (!steps || !steps[index] || !location) return;
            return location?.pathname?.includes(steps[index].id);
        },
        [steps, location],
    );

    /**
     * Render
     */
    return (
        <Box className={style.wrapper}>
            <Box mb={2}>
                <Typography variant="h2" id={`${id}-title`}>
                    {titleMapper}
                </Typography>
            </Box>
            {typeof subTitle === "string" ? (
                <Box mb={1}>
                    <Typography
                        variant="h3"
                        id={`${id}-subtitle`}
                        className={style.subtitle}
                    >
                        {subTitle}
                    </Typography>
                </Box>
            ) : (
                subTitle
            )}

            {steps &&
                !!isArrayWithContent(steps) &&
                steps.map((step, index) => (
                    <Box
                        key={`${step.id}-${index}`}
                        sx={{
                            display: {
                                xs: findStepInProgress(index)
                                    ? "initial"
                                    : "none",
                                md: "initial",
                            },
                        }}
                    >
                        <Step
                            id={`${id}-${step.id}-${index}`}
                            step={step}
                            index={index}
                            hasStatus
                            onStepClick={onStepClick}
                            disabled={
                                disabled ||
                                (notPreparedIndex !== undefined &&
                                    notPreparedIndex > -1 &&
                                    index > notPreparedIndex)
                            }
                            stepInProgress={findStepInProgress(index)}
                        />
                    </Box>
                ))}
        </Box>
    );
};

export default Stepper;
