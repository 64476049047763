// Libs
import { Grid } from "@mui/material";
import React from "react";

// Own component
import { Card, FieldRenderer } from "@components";

/**
 * Props type
 */
export interface Props {
    id: string;
    fileName: string;
    hasDownloadAction?: boolean;
    loading?: boolean;
    className?: string;
    onDownload?: (fileName: string) => void;
    label?: string;
}

/**
 * Task Data Approval Details
 */
const TaskDataApprovalDetails = ({
    id,
    hasDownloadAction = false,
    className,
    loading,
    fileName,
    onDownload,
    label,
}: Props) => {
    /**
     * Render
     */
    return (
        <Card id={`${id}-card`} className={className}>
            <Grid item xs={12} container rowSpacing={3} columnSpacing={4}>
                <Grid item xs={12} md={6} lg={3}>
                    <FieldRenderer
                        id={`${id}-load-data-file`}
                        label={label}
                        showTooltip
                        value={fileName}
                        disabledAction={loading}
                        loading={loading}
                        hasDownloadAction={hasDownloadAction}
                        onDownloadClick={
                            onDownload ? () => onDownload(fileName) : undefined
                        }
                    />
                </Grid>
            </Grid>
        </Card>
    );
};

export default TaskDataApprovalDetails;
