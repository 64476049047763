import { GeneralInformation } from "@types";
import { atom } from "recoil";

// Utils
import { setInitialDate } from "@utils";

/**
 * General information
 */
export const generalInformationState = atom({
    key: "generalInformationState",
    default: {
        reference: "",
        externalReference: "",
        icdNo: "",
        responsible: "",
        indication: {
            indicationId: "",
            indicationName: "",
        },
        therapeuticArea: {
            therapeuticAreaId: "",
            therapeuticAreaName: "",
        },
        country: { isoCode: "", name: "" },
        startDate: setInitialDate(),
        endDate: setInitialDate("plus", { years: 1 }),
        brand: {
            brandId: "",
            brandName: "",
        },
        primaryPartner: null,
        icdUrl: "",
        claimPeriodicity: { id: "MONTHLY", label: "Monthly" },
    } as GeneralInformation,
});
