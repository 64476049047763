// Icons
import CheckIcon from "@mui/icons-material/Check";
// libs
import { Box, Button, Typography } from "@mui/material";
import classnames from "classnames";
import { navigate } from "gatsby";
import React from "react";

// types
import type { FlowStep } from "@types";
import * as style from "./style.module.scss";

/**
 * Props type
 */
interface Props {
    step: FlowStep;
    index: number;
    hasStatus: boolean;
    disabled?: boolean;
    onStepClick?: (path: string) => void;
    stepInProgress?: boolean;
    id: string;
}

/**
 * Step
 */
const Step: React.FC<Props> = ({
    step,
    index,
    hasStatus,
    disabled = false,
    stepInProgress,
    onStepClick,
    id,
}) => {
    const onClickHandler = () => {
        if (!step.isPrepared) return;
        const chopped = location.pathname.substring(
            0,
            location.pathname.length - 1,
        );
        const pathArray = chopped.split("/");

        if (pathArray[pathArray.length - 1] === step.id) return;
        pathArray[pathArray.length - 1] = step.id;
        return onStepClick
            ? onStepClick(pathArray.join("/"))
            : navigate(pathArray.join("/"));
    };

    /**
     * Render
     */
    return (
        <div
            className={classnames(style.item, {
                [style.progress]: stepInProgress || step.isCompleted,
            })}
        >
            {hasStatus && (
                <Box className={style.status}>
                    {step?.isCompleted && step?.isPrepared ? (
                        <CheckIcon color="primary" fontSize="small" />
                    ) : (
                        <Typography variant="h4">{index + 1}</Typography>
                    )}
                </Box>
            )}

            <Button
                id={`${id}-btn`}
                className={style.content}
                onClick={onClickHandler}
                disabled={disabled || !step.isPrepared}
            >
                <Typography variant="h4">{step.title}</Typography>
            </Button>
        </div>
    );
};

export default Step;
