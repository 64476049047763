//import { useApi } from "../api";
import { useEffect, useLayoutEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { notificationsState, systemState, userState } from "@atoms";
import { useAxios } from "./useAxios";
import { isSuccessfulCall, pathname } from "@utils";
import { NOTIFICATIONS } from "@constants";
import type { Api, NotificationSeverity } from "@types";

const BASE_URI = `/v0`;

export const useSystemAvailability = (getList = true) => {
    const [_, setSystem] = useRecoilState(systemState);
    const user = useRecoilValue<any>(userState);

    const isInactive =
        pathname?.includes("logout") || pathname?.includes("inactive");

    const {
        response,
        loading,
        fetch: load,
        error: error,
    }: Api = useAxios(
        {
            method: "GET",
            url: `${BASE_URI}/system`,
        },
        { errorHandler: "Failed to fetch system info" },
    );

    useLayoutEffect(() => {
        if (!getList || !!user || isInactive) return;

        load().then(res => {
            if (isSuccessfulCall(res?.status)) {
                setSystem(res?.data);
            }
        });
    }, []);

    return {
        load,
        error,
        loading,
        response: { ...response?.data },
    };
};

export const useMaintenanceSystemAvailability = () => {
    const url = `${BASE_URI}/maintenance/availability`;

    /**
     * update notifications atom with success messages
     */
    const [_, setNotifications] = useRecoilState(notificationsState);

    const {
        response: sysInfo,
        loading: fetching,
        fetch: load,
        error: error,
    }: Api = useAxios(
        {
            method: "GET",
            url,
        },
        {
            errorHandler: `Failed to fetch system info`,
        },
    );

    const { loading: creating, fetch: create }: Api = useAxios(
        {
            method: "POST",
            url,
        },
        {
            errorHandler: `Failed to update system info`,
        },
    );

    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            load();
        }

        return () => {
            unmounted = true;
        };
    }, []);

    return {
        sysInfo,
        loading: {
            creating,
            fetching,
        },
        error,
        getSystemInfo: () => load(),
        upsert: async (sysInfo: any) =>
            await create({
                data: sysInfo,
            }).then(res => {
                if (isSuccessfulCall(res?.status)) {
                    setNotifications({
                        severity: NOTIFICATIONS.SEVERITY
                            .SUCCESS as NotificationSeverity,
                        message: `System info was successfully updated`,
                        autoHide: true,
                    });

                    load();
                }
                return res;
            }),
    };
};
