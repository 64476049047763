//import libs
import {
    Box,
    TableCell,
    TableRow,
    Typography,
    Radio,
    Checkbox,
} from "@mui/material";
import React from "react";

// import own components
import { ActionMenu } from "@components";

// Import utils
import { constantMapper } from "@utils";
import { Country } from "types/api";

type Model = {
    accountContextId: string;
    dataSource: string;
    id: string;
    name: string;
    priceSource: string;
    productContextId: string;
    regionContextId: string;
};
interface Row {
    categoryValue: string;
    type: string;
    country: Country;
    categoryLabel: string;
    categoryName: string;
    categoryId: string;
    datamodel: Model;
    fixed: boolean;
}

interface Props {
    row: Row;
    rowIndex: number;
    id: string;
    isColVisible: (col: string) => boolean;
    callbacks: any;
    disabled?: boolean;
    selectedRow?: any;
    linkable: boolean;
}

/**
 * CategoryRow
 */
const CategoryRow = ({
    id,
    row,
    rowIndex,
    isColVisible,
    callbacks,
    disabled,
    selectedRow,
    linkable = false,
}: Props) => {
    return (
        <TableRow
            id={`${id}-row-${rowIndex}`}
            selected={selectedRow === row.categoryId}
            sx={{
                "&:last-child td, &:last-child th": {
                    border: 0,
                },
            }}
            onClick={
                callbacks.onCategorySelect
                    ? event => {
                          callbacks.onCategorySelect(
                              row,
                              parseInt(
                                  (event.target as HTMLInputElement).value,
                              ),
                          );
                      }
                    : undefined
            }
        >
            {callbacks?.onCategorySelect && (
                <TableCell width="4.6875rem">
                    {linkable ? (
                        <Checkbox
                            onChange={() => {
                                callbacks.onCategorySelect(row);
                            }}
                            disabled={disabled}
                            checked={
                                !!callbacks.findCategory &&
                                !!callbacks.findCategory(row.categoryId)
                            }
                            size="small"
                        />
                    ) : (
                        <Radio
                            onChange={event => {
                                callbacks.onCategorySelect(
                                    row,
                                    parseInt(event.target.value),
                                );
                            }}
                            checked={selectedRow === row.categoryId}
                            value={rowIndex}
                            inputProps={{
                                "aria-label": `${id}-row-${rowIndex}`,
                            }}
                            required
                            id={`${id}-row-${rowIndex}-select-category`}
                        />
                    )}
                </TableCell>
            )}

            {isColVisible("categoryName") && (
                <TableCell>
                    <Typography variant="caption2">
                        {row.categoryName || "-"}
                    </Typography>
                </TableCell>
            )}

            {!linkable && isColVisible("categoryLabel") && (
                <TableCell>
                    <Typography variant="caption2">
                        {row.categoryLabel || "-"}
                    </Typography>
                </TableCell>
            )}

            {isColVisible("categoryValue") && (
                <TableCell>
                    <Typography variant="caption2">
                        {row.categoryValue || "-"}
                    </Typography>
                </TableCell>
            )}

            {!linkable && isColVisible("country") && (
                <TableCell>
                    <Typography variant="caption2">
                        {constantMapper(row?.country?.name) || "-"}
                    </Typography>
                </TableCell>
            )}

            {!linkable && isColVisible("type") && (
                <TableCell>
                    <Typography variant="caption2">
                        {row.type ? constantMapper(row.type) : "-"}
                    </Typography>
                </TableCell>
            )}
            {!linkable && !callbacks?.onCategorySelect && (
                <TableCell align="right">
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-end"
                    >
                        <ActionMenu
                            id={`${id}-${rowIndex}-task-row"`}
                            autoHide
                            disabled={disabled}
                            options={[
                                {
                                    label: "Edit",
                                    action: () =>
                                        callbacks &&
                                        callbacks?.onCategoryEdit(
                                            row,
                                            rowIndex,
                                        ),
                                    disabled: row.fixed,
                                },
                                {
                                    label: "Delete",
                                    action: () =>
                                        callbacks &&
                                        callbacks?.onCategoryDelete(row),
                                    disabled: row.fixed,
                                },
                            ]}
                            rowIndex={rowIndex}
                        />
                    </Box>
                </TableCell>
            )}
        </TableRow>
    );
};

export default CategoryRow;
