import { useEffect } from "react";
import { useAxios } from "./useAxios";
import type { Api } from "@types";

const BASE_URI = `/v0`;

/**
 * Region
 */
export const useRegion = (getList = true, type?: string) => {
    const url = `${BASE_URI}/region`;

    const {
        response: list,
        loading: fetching,
        fetch: loadList,
        error: listError,
    }: Api = useAxios(
        {
            method: "GET",
            url: `${url}${type ? `?type=${type}` : ""}`,
        },
        {
            errorHandler: `Failed to fetch regions`,
        },
    );

    const {
        response: accountField,
        loading: fetchingAccountField,
        fetch: loadAccountField,
        error: accountFieldError,
    }: Api = useAxios(
        {
            method: "GET",
            url: `${url}/accountField`,
        },
        {
            errorHandler: `Failed to fetch account fields`,
        },
    );

    const {
        loading: deleting,
        fetch: deleteRegion,
        error: deleteError,
    }: Api = useAxios({
        method: "DELETE",
    });

    const {
        loading: creating,
        fetch: createRegion,
        error: createError,
    }: Api = useAxios({
        method: "POST",
        url,
    });

    const {
        loading: updating,
        fetch: updateRegion,
        error: updateError,
    }: Api = useAxios({
        method: "PUT",
    });

    useEffect(() => {
        if (!getList) return;
        let unmounted = false;
        if (!unmounted) {
            loadList();
        }
        return () => {
            unmounted = true;
        };
    }, []);

    return {
        list: { regions: list, accountField },
        loading: {
            creating,
            fetching,
            updating,
            deleting,
            fetchingAccountField,
        },
        error: {
            listError,
            createError,
            updateError,
            deleteError,
            accountFieldError,
        },
        createRegion: listItem =>
            createRegion(
                { data: listItem },
                {
                    errorHandler: `Failed to add "${listItem.regionName}"`,
                    successHandler: `"${listItem.regionName}" was successfully added`,
                },
            ),
        updateRegion: (regionId: string, listItem: any) =>
            updateRegion(
                { url: `${url}/${regionId}`, data: listItem },
                {
                    errorHandler: `Failed to update "${listItem?.regionName}"`,
                    successHandler: `"${listItem?.regionName}" was successfully updated`,
                },
            ),

        deleteRegion: (regionId: string, regionName: string) =>
            deleteRegion(
                { url: `${url}/${regionId}` },
                {
                    errorHandler: `Failed to delete "${regionName}"`,
                    successHandler: `"${regionName}" was successfully deleted`,
                },
            ),
        loadAccountField,

        load: loadList,
    };
};

/**
 * Fetch region details
 */
export const useRegionDetails = () => {
    const {
        response: list,
        error,
        fetch: loadList,
        loading,
    }: Api = useAxios(
        {
            method: "GET",
        },
        { errorHandler: `Failed to fetch region details` },
    );

    return {
        load: (regionId: string) =>
            loadList({ url: `${BASE_URI}/region/${regionId}` }),
        error,
        loading,
        list,
    };
};
