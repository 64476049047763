import { atom } from "recoil";

/**
 * Pagination atom
 */
export const paginationState = atom({
    key: "paginationState",
    default: {
        page: 1,
        type: "" as string,
    },
});
