import { DateTime } from "luxon";
import * as yup from "yup";

/**
 * Upload Claim file schema
 */
export const addClaimDetailsSchema = yup.object().shape({
    isUkTeam: yup.boolean().nullable(),
    periodFrom: yup
        .date()
        .typeError("please enter a valid date")
        .required("This field is mandatory"),
    periodTo: yup
        .date()
        .typeError("please enter a valid date")
        .required("This field is mandatory")
        .when("periodFrom", ([periodFrom], schema) => {
            const limiter = DateTime.fromJSDate(periodFrom).plus({ days: 1 });
            return periodFrom && yup.date().isValidSync(periodFrom)
                ? schema.min(limiter, "End date must be later than Start date")
                : schema;
        }),
    file: yup.mixed().required("A file is required"),
    contractPartner: yup.object().shape({
        accountAddress: yup.string().nullable(),
        accountCity: yup.string().nullable(),
        accountId: yup.string().required("This field is mandatory"),
        accountName: yup.string().required(),
        accountPostalCode: yup.string().nullable(),
        accountTypeId: yup.string().nullable(),
        accountStatus: yup.string().required(),
        countryIsoCode: yup.string().nullable(),
        homeCountry: yup.string().nullable(),
        knowyoursupplierid: yup.string().nullable(),
        sapAccountCode: yup.string().nullable(),
        codsId: yup.string().nullable(),
    }),
    claimCustomerReference: yup.string().when("isUkTeam", value => {
        if (value && !!value[0]) {
            return yup
                .string()
                .required("This field is mandatory")
                .max(
                    30,
                    "Claim customer reference must be less than 30 characters",
                );
        } else {
            return yup.string().nullable();
        }
    }),
    claimCustomerInfo: yup.string().when("isUkTeam", value => {
        if (value && !!value[0]) {
            return yup.string().required("This field is mandatory");
        } else {
            return yup.string().nullable();
        }
    }),

    claimDocumentDate: yup.date().when("isUkTeam", value => {
        if (value && !!value[0]) {
            return yup
                .date()
                .typeError("please enter a valid date")
                .required("This field is mandatory");
        } else {
            return yup.date().typeError("please enter a valid date").nullable();
        }
    }),

    claimDueDate: yup.date().when("isUkTeam", value => {
        if (value && !!value[0]) {
            return yup
                .date()
                .typeError("please enter a valid date")
                .required("This field is mandatory")
                .when("claimDocumentDate", ([claimDocumentDate], schema) => {
                    const limiter = DateTime.fromJSDate(claimDocumentDate).plus(
                        { days: 1 },
                    );
                    return claimDocumentDate &&
                        yup.date().isValidSync(claimDocumentDate)
                        ? schema.min(
                              limiter,
                              "The Claim due date must be after the Claim document date",
                          )
                        : schema;
                });
        } else {
            return yup.date().typeError("please enter a valid date").nullable();
        }
    }),
});

/**
 * Select Claim Contract Schema
 */
export const selectClaimContractSchema = yup.object().shape({
    contract: yup
        .object()
        .shape({
            reference: yup.string().required(),
            id: yup.string().required(),
        })
        .required(),
});
