// libs
import ConstructionIcon from "@mui/icons-material/Construction";
import { navigate } from "gatsby";
import React, { Fragment, useMemo, useRef, useState } from "react";

// own component
import {
    Breadcrumbs,
    StepActions,
    Stepper,
    FlowLayout,
    FlowAside,
    FlowBody,
    FlowContent,
    FlowFooter,
} from "@components";
import { useCreateTeam, useFindStep, useUpdateTeam } from "@hooks";
import { createTeamStepsState } from "@atoms";

//import types
import type { Location } from "@types";

//Own containers
import { TeamOverview } from "@containers";
import { isSuccessfulCall } from "@utils";

/**
 * Props type
 */
interface Props {
    location: Location;
    children: React.ReactNode;
}

/**
 * Create team layout
 */
const CreateTeamLayout = ({ location, children }: Props) => {
    /**
     * States
     */
    // Store team Id to render confimation page
    const [teamId, setTeamId] = useState(undefined);

    //Via this ref we can save the state of each page via stepper (higher order)
    const ref = useRef<any>();

    /**
     * API
     */

    //Create new team
    const { createTeam, loading: createTeamLoading } = useCreateTeam();

    //Update current team
    const { updateTeam, loading: updateTeamLoading } = useUpdateTeam();

    //Steps hooks
    const { currentStep } = useFindStep(location, createTeamStepsState);

    /**
     * Check if the current page is edit page
     */
    const isEditMode = useMemo(() => {
        return location?.pathname.includes("edit-team") && !teamId;
    }, [location, teamId]);

    /**
     * Check if edit is finished
     */
    const isEditingFinish = useMemo(() => {
        return location?.pathname.includes("edit-team");
    }, [location]);

    /**
     * Check if the current page is team overview page
     */
    const isOverview = useMemo(() => {
        return !!teamId;
    }, [teamId]);

    /**
     * Click back handler
     */
    const onClick = () => {
        setTeamId(undefined);
        if (isOverview) {
            navigate(`/contract-team/edit-team/${teamId}/`);
        } else navigate(`/maintenance/teams/`);
    };

    /**
     * OnSubmit
     */
    const onSubmit = () => {
        if (isOverview) {
            setTeamId(undefined);
            navigate(`/maintenance/teams/`);
            return;
        }
        const values = ref?.current?.updateState();

        if (isEditMode) {
            updateTeam(values.teamId, values).then(res => {
                if (!!res && isSuccessfulCall(res?.status)) {
                    setTeamId(res?.data?.teamId);
                }
            });
        } else {
            createTeam(values).then(res => {
                if (!!res && isSuccessfulCall(res?.status)) {
                    setTeamId(res?.data?.teamId);
                }
            });
        }
    };

    /**
     * Render
     */
    return (
        <Fragment>
            <Breadcrumbs
                id={"create-team-breadcrumb"}
                icon={<ConstructionIcon color="primary" />}
                title="Maintenance"
                location={location}
            />

            <FlowLayout>
                <FlowAside>
                    <Stepper
                        id={"create-team-stepper"}
                        title={currentStep?.title}
                    />
                </FlowAside>

                <FlowBody>
                    <Fragment>
                        <FlowContent>
                            {!!teamId && (
                                <TeamOverview
                                    id="create-team-form"
                                    teamId={teamId}
                                    onEdit={onClick}
                                    isEditingFinish={isEditingFinish}
                                />
                            )}

                            {!teamId &&
                                React.Children.map(
                                    children as React.ReactElement,
                                    (child: React.ReactElement) =>
                                        React.cloneElement(child, {
                                            location,
                                            isEditMode,
                                            creatingTeam:
                                                createTeamLoading ||
                                                updateTeamLoading,
                                            ref,
                                            id: "create-team",
                                        }),
                                )}
                        </FlowContent>

                        <FlowFooter>
                            <StepActions
                                id={"create-team-step-actions"}
                                loading={createTeamLoading || updateTeamLoading}
                                primaryButton={{
                                    text: isEditMode
                                        ? "Save changes"
                                        : isOverview
                                          ? "Return to overview"
                                          : "Create team",
                                    action: onSubmit,
                                    disabled:
                                        (!currentStep?.isPrepared ||
                                            createTeamLoading ||
                                            updateTeamLoading) &&
                                        !isOverview,
                                    isSubmitButton: true,
                                }}
                                tertiaryButton={{
                                    text: isEditMode
                                        ? "Discard changes"
                                        : isOverview
                                          ? "Edit Team"
                                          : "Discard team",
                                    action: onClick,
                                    disabled:
                                        createTeamLoading || updateTeamLoading,
                                }}
                            />
                        </FlowFooter>
                    </Fragment>
                </FlowBody>
            </FlowLayout>
        </Fragment>
    );
};

export default CreateTeamLayout;
