import { Box, Typography } from "@mui/material";
import { navigate } from "gatsby";
import React, { useState, Fragment, useMemo, useRef } from "react";
import { useResetRecoilState } from "recoil";

// Own components
import { SearchBar, Table, CancelWithReasons } from "@components";

//constants
import { HEADERS, ROWRENDERERCONST } from "@constants";

// Recoil
import { createOutcomeMapper } from "@selectors";

//hooks
import {
    useOutcomes,
    useOutcomeStatuses,
    useBrands,
    useTeamTherapeuticArea,
    useProducts,
    usePermission,
    useViewingOptions,
} from "@hooks";

// Types
import { SearchFilters, Permissions, Location } from "@types";

// Utils
import {
    apiResponseCounter,
    constructQueryString,
    isSuccessfulCall,
} from "@utils";

/**
 * Props type
 */

type Props = {
    location?: Location;
};

/**
 * Outcomes
 */
const Outcomes = ({ location }: Props) => {
    /**
     * States
     */
    // Reset outcome flow
    const resetCreateOutcome = useResetRecoilState(createOutcomeMapper);

    // Cancel outcome state
    const [outcomeToCancel, setOutcomeToCancel] = useState<
        { salessystemOrderId: string; outcomeId: string } | undefined
    >(undefined);

    /**
     * Search Ref
     */

    const searchRef = useRef<any>();

    /**
     * Check if financial condition page
     */
    const isFinancialConditionsPage = useMemo(() => {
        if (!location) return;
        return location?.pathname.includes("/dashboard/financial-conditions/");
    }, [location]);

    /**
     * Permissions
     */
    const { hasPermissionToEditOutcome }: Permissions = usePermission();

    /**
     * Hooks
     */
    const { viewingOptions, setViewingOptions } = useViewingOptions(
        isFinancialConditionsPage
            ? ROWRENDERERCONST.OUTCOMES_FINANCIAL_CONDITIONS
            : ROWRENDERERCONST.OUTCOMES,
    );

    /**
     * API
     */

    //Outcomes
    const {
        list: outcomes,
        loading: { listLoading, canceling },
        search,
        reload: reloadOutcomes,
        cancel: cancelOutcome,
    }: any = useOutcomes(isFinancialConditionsPage ? undefined : "outcome");

    // outcome statuses
    const { loading: fetchingOutcomeStatuses, list: outcomeStatuses } =
        useOutcomeStatuses();

    // Brands
    const { list: brands, loading: brandsLoading }: any = useBrands();

    // Therapeutic area
    const { list: therapeuticAreas, loading: therapeuticAreasLoading }: any =
        useTeamTherapeuticArea();

    //Products
    const { response: product, loading: productLoading }: any = useProducts();

    /**
     * Search handler
     */
    const onSearch = (filters: SearchFilters) => {
        const hasFilters = Object.values(filters).some(filter => filter.length);
        const params = constructQueryString(filters, isFinancialConditionsPage);
        if (hasFilters || isFinancialConditionsPage) search(`${params}`);
        else reloadOutcomes();
    };

    /**
     * Cancel outcome
     */
    const onOutcomeCancel = (reason: string) => {
        if (!reason || !outcomeToCancel?.outcomeId) return;
        cancelOutcome(
            outcomeToCancel.outcomeId,
            outcomeToCancel?.salessystemOrderId,
            reason,
        ).then(res => {
            if (!!res && isSuccessfulCall(res?.status)) {
                setOutcomeToCancel(undefined);
                searchRef?.current?.onSearchReset();
            }
        });
    };

    /**
     * Status mapper
     */
    const mapOutcomeStatuses = useMemo(() => {
        if (!outcomeStatuses?.data?.records) return [];
        return outcomeStatuses?.data?.records?.map(status => {
            return {
                label: status?.label,
                value: status?.id,
            };
        });
    }, [outcomeStatuses?.data?.records]);

    /**
     * Render
     */
    return (
        <Fragment>
            <CancelWithReasons
                id={`cancel-outcome`}
                title={`${
                    isFinancialConditionsPage
                        ? "Cancel financial Condition"
                        : "Cancel outcome"
                } (${outcomeToCancel?.salessystemOrderId})`}
                open={!!outcomeToCancel?.outcomeId}
                onClose={() => setOutcomeToCancel(undefined)}
                onSubmit={onOutcomeCancel}
                loading={canceling}
            />

            <Box mt={2} mb={4} display="flex" justifyContent="flex-end">
                <SearchBar
                    id={`dashboard-outcomes-overview-search-bar`}
                    filterKey={
                        isFinancialConditionsPage
                            ? ROWRENDERERCONST.OUTCOMES_FINANCIAL_CONDITIONS
                            : ROWRENDERERCONST.OUTCOMES
                    }
                    handleSearch={(filters: SearchFilters) => onSearch(filters)}
                    viewingOptions={viewingOptions}
                    setViewingOptions={setViewingOptions}
                    products={{
                        data: product?.data?.records,
                        loading: productLoading,
                    }}
                    therapeuticAreas={{
                        data: therapeuticAreas?.data?.records,
                        loading: therapeuticAreasLoading,
                    }}
                    brands={{ data: brands?.data, loading: brandsLoading }}
                    placeholder={`Search by cquence id, contract reference`}
                    action={{
                        label: "New outcome",
                        callback: () => {
                            navigate("/create-outcome/select-contract/");
                            resetCreateOutcome();
                        },
                        hidden:
                            isFinancialConditionsPage ||
                            !hasPermissionToEditOutcome,
                    }}
                    status={{
                        data: mapOutcomeStatuses,
                        loading: fetchingOutcomeStatuses,
                    }}
                    showFinancialTriggerTypeFilter={isFinancialConditionsPage}
                    ref={searchRef}
                />
            </Box>
            <Box mt={5}>
                <Box display="flex" alignItems="baseline" mb={4}>
                    <Typography variant="h2">
                        {isFinancialConditionsPage
                            ? "Financial conditions"
                            : "Outcomes"}
                    </Typography>

                    <Typography ml={1} variant="caption1">
                        {apiResponseCounter(
                            outcomes,
                            listLoading,
                            isFinancialConditionsPage
                                ? "financial condition|financial conditions"
                                : "outcome|outcomes",
                        )}
                    </Typography>
                </Box>
                <Table
                    id={`dashboard-${
                        isFinancialConditionsPage
                            ? "financial-conditions"
                            : "outcomes"
                    }-overview-list`}
                    headers={
                        isFinancialConditionsPage
                            ? HEADERS.OUTCOMES_FINANCIAL_CONDITIONS
                            : HEADERS.OUTCOMES
                    }
                    rows={outcomes?.data?.records}
                    loading={listLoading}
                    type={
                        isFinancialConditionsPage
                            ? ROWRENDERERCONST.OUTCOMES_FINANCIAL_CONDITIONS
                            : ROWRENDERERCONST.OUTCOMES
                    }
                    viewingOptions={viewingOptions}
                    permissions={{ hasPermissionToEditOutcome }}
                    callbacks={{
                        cancelOutcome: (
                            outcomeId: string,
                            salessystemOrderId: string,
                        ) =>
                            setOutcomeToCancel({
                                outcomeId,
                                salessystemOrderId,
                            }),
                        onShowDetailsClick: (outcomeId: string) =>
                            isFinancialConditionsPage
                                ? navigate(
                                      `/financial-conditions/financial-conditions-details/${outcomeId}/`,
                                  )
                                : navigate(
                                      `/outcomes/outcome-details/${outcomeId}/`,
                                  ),
                    }}
                    emptyMsg={
                        isFinancialConditionsPage
                            ? "No financial conditions found!"
                            : "No outcomes found!"
                    }
                />
            </Box>
        </Fragment>
    );
};
export default Outcomes;
