import React from "react";
import { useRecoilValue, useResetRecoilState } from "recoil";

import { Notifications as _Notifications } from "@components";
import { notificationsState } from "@atoms";

/**
 * Notifications
 */
const Notifications = () => {
    /**
     * Get notifications atom
     */
    const notifications = useRecoilValue(notificationsState);
    const restNotifications = useResetRecoilState(notificationsState);
    /**
     * Render
     */
    return (
        <_Notifications
            id="notification"
            notifications={notifications}
            dismiss={() => restNotifications()}
        />
    );
};
export default React.memo(Notifications);
