// Libs
import { Box, TableCell, TableRow, Typography, Tooltip } from "@mui/material";
import React from "react";

// Own components
import { ActionMenu } from "@components";
import { displayDate, truncateString } from "@utils";

// Types
import type { Milestone } from "@types";

interface Props {
    row: Milestone;
    rowIndex: number;
    id: string;
    callbacks: any;
    isColVisible: (col: string) => boolean;
}

const MilestoneRow = ({
    id,
    row,
    rowIndex,
    callbacks,
    isColVisible,
}: Props) => {
    return (
        <TableRow>
            {isColVisible("milestoneNo") && (
                <TableCell>
                    <Typography variant="caption2">
                        {row.milestoneNo}
                    </Typography>
                </TableCell>
            )}

            {isColVisible("milestoneDescr") && (
                <TableCell>
                    <Tooltip arrow placement="top" title={row.milestoneDescr}>
                        <Typography variant="caption2">
                            {truncateString(row.milestoneDescr)}
                        </Typography>
                    </Tooltip>
                </TableCell>
            )}

            {isColVisible("contractRef") && (
                <TableCell>
                    <Typography variant="caption2">
                        {row.contractRef}
                    </Typography>
                </TableCell>
            )}

            {isColVisible("validFrom") && (
                <TableCell>
                    <Typography variant="caption2">
                        {displayDate(row.validFrom)}
                    </Typography>
                </TableCell>
            )}

            {isColVisible("validTo") && (
                <TableCell>
                    <Typography variant="caption2">
                        {displayDate(row.validTo)}
                    </Typography>
                </TableCell>
            )}

            {isColVisible("productName") && (
                <TableCell>
                    <Typography variant="caption2">
                        {row.productName}
                    </Typography>
                </TableCell>
            )}

            <TableCell align="right">
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                >
                    <ActionMenu
                        id={`${id}-${rowIndex}-milestone-row"`}
                        autoHide
                        autoWidth
                        options={[
                            {
                                label: "Link billing plan to milestone",
                                action: () =>
                                    callbacks &&
                                    callbacks?.linkBillingPlan(row.milestoneId),
                            },
                        ]}
                        rowIndex={rowIndex}
                    />
                </Box>
            </TableCell>
        </TableRow>
    );
};

export default MilestoneRow;
