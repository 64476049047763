// Lib
import {
    Typography,
    ListItem,
    ListItemButton,
    ListItemAvatar,
    List,
    ListItemText,
} from "@mui/material";
import classnames from "classnames";
import React from "react";

// Own components
import { AvatarRenderer } from "@components";

// Utils
import { isArrayWithContent } from "@utils";

// Types
import type { Team, TeamUser } from "@types";

// styles
import * as style from "./style.module.scss";

/**
 * Props type
 */
interface Props {
    team: Array<Team | TeamUser>;
    id: string;
    showNoTeamMsg?: boolean;
    showFullName?: boolean;
    className?: string;
    smallView?: boolean;
}

const TeamViewer = ({
    team,
    id,
    className,
    showNoTeamMsg = false,
    showFullName = false,
    smallView,
}: Props) => {
    return isArrayWithContent(team) ? (
        <List
            dense
            className={classnames({ [style.smallView]: smallView }, className)}
        >
            {team?.map(({ firstName, lastName, email }: any, index) => {
                return (
                    <ListItem
                        id={`${id}-team-overview-${index}`}
                        key={index}
                        disablePadding
                    >
                        <ListItemButton>
                            <ListItemAvatar>
                                <AvatarRenderer
                                    email={email}
                                    firstName={firstName}
                                    lastName={lastName}
                                    width={31}
                                    height={31}
                                />
                            </ListItemAvatar>
                            <ListItemText
                                primary={
                                    showFullName && !!firstName && !!lastName
                                        ? `${firstName} ${lastName}`
                                        : email
                                }
                            />
                        </ListItemButton>
                    </ListItem>
                );
            })}
        </List>
    ) : showNoTeamMsg ? (
        <Typography variant="caption1">
            {`You are not attached to a team`}
        </Typography>
    ) : null;
};

export default React.memo(TeamViewer);
