// extracted by mini-css-extract-plugin
export var body = "style-module--body--42b8e";
export var card = "style-module--card--1afe0";
export var description = "style-module--description--584f2";
export var error = "#ff3131";
export var footerAction = "style-module--footerAction--9c9c8";
export var headerAction = "style-module--headerAction--94fcf";
export var label = "style-module--label--76b50";
export var list = "style-module--list--5632f";
export var primary = "#036";
export var secondary = "#019cdc";
export var textfont = "\"Verdana Pro\"";
export var titlefont = "\"Karbon\"";
export var white = "#fff";