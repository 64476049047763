import * as yup from "yup";

export const billingPlanSchema = yup.object().shape({
    billingBlock: yup.string().nullable(),
    billingPlanId: yup.string().nullable(),
    counter: yup.string().required(),
    days: yup.number().integer().nullable(),
    division: yup.string().required(),
    documentType: yup.string().required(),
    paymentTerms: yup.string().nullable(),
    percentage: yup.number().nullable(),
    planType: yup.string().required(),
    salesOrg: yup.string().required(),
});
