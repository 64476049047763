// Lib
import EditIcon from "@mui/icons-material/Edit";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React, { Fragment, useEffect, useState, useMemo } from "react";

// Own components
import {
    GrayBox,
    FieldRenderer,
    ProductFinancialConditionsForm,
    Modal,
    Card,
} from "@components";

// Types
import { Brand, FinancialCondition } from "@types";

// Utils
import {
    thousandsSeparator,
    mappedFinancialConditions as financialConditionsMapper,
    isObjectWithContent,
} from "@utils";

/**
 * Brand type
 */
interface BrandType extends Brand {
    name?: string;
}

type InternalFinancialCondition = Record<
    string,
    {
        fixedRebateAmount: number | undefined;
        rebatePercentage: number | undefined;
        name?: string;
        selectedCondition?: string;
        amount: number;
    }
>;

/**
 * Props
 */
interface Props {
    hideEdit?: boolean;
    disableEdit?: boolean;
    productDetails?: any;
    callbacks?: {
        onEditClick?: (path: string) => void;
        onEditContractItem?: (value: Record<string, any>) => void;
        setDisableSaveButton?: (value: boolean) => void;
    };
    financialConditions?: InternalFinancialCondition;
    id: string;
    shrinkColumns?: boolean;
    brand: BrandType;
    isEditContract?: boolean;
    financialConditionsList?: {
        load: () => void;
        data: Array<FinancialCondition>;
        loading: boolean;
    };
    isCreateContract?: boolean;
    currency: string;
}

/**
 * FinancialConditionsSummary
 */
const FinancialConditionsSummary: React.FC<Props> = ({
    productDetails,
    id,
    hideEdit = false,
    callbacks,
    financialConditions,
    shrinkColumns,
    brand,
    isEditContract,
    financialConditionsList,
    disableEdit,
    isCreateContract,
    currency,
}) => {
    const [openEditModal, setEditModal] = useState(false);
    const [updateFinancialConditions, setFinancialConditions] =
        useState<InternalFinancialCondition>({});

    // Touched state
    const [touched, setTouched] = useState({});

    /**
     * Handle blur
     */
    const onBlur = (e: React.BaseSyntheticEvent, key?: string) => {
        const copyTouched = { ...touched };
        const name = key ? key : e.target.name;
        if (copyTouched[name]) return;
        Object.assign(copyTouched, {
            [name]: true,
        });

        setTouched(copyTouched);
    };

    const mappedFinancialConditions = useMemo(() => {
        if (
            !financialConditions ||
            Object.keys(financialConditions).length === 0
        )
            return;

        return financialConditionsMapper(financialConditions);
    }, [financialConditions]);

    useEffect(() => {
        if (!mappedFinancialConditions?.condition) return;

        if (!openEditModal) {
            setFinancialConditions({});
            callbacks?.setDisableSaveButton &&
                callbacks?.setDisableSaveButton(false);
        } else {
            setFinancialConditions(
                financialConditions as InternalFinancialCondition,
            );
            callbacks?.setDisableSaveButton &&
                callbacks?.setDisableSaveButton(true);
        }
    }, [financialConditions, openEditModal]);

    /**
     * Financial conditions error mapper
     */
    const hasFinancialConditionsError = useMemo(() => {
        if (!isEditContract || !isObjectWithContent(updateFinancialConditions))
            return;
        const key = mappedFinancialConditions?.key;
        if (!key) return true;
        const { amount, selectedCondition } = updateFinancialConditions[key];

        return (
            !amount ||
            (selectedCondition === "rebatePercentage" && Number(amount) > 100)
        );
    }, [mappedFinancialConditions, updateFinancialConditions, isEditContract]);

    return mappedFinancialConditions?.condition ? (
        <Grid item xs={12}>
            <Modal
                open={openEditModal}
                id={`edit-financial-conditions`}
                title={"Financial conditions"}
                onClose={() => {
                    setEditModal(false);
                }}
                primaryButton={{
                    action: () => {
                        if (callbacks?.onEditContractItem) {
                            callbacks?.onEditContractItem({
                                financialConditions: updateFinancialConditions,
                            });
                            setEditModal(false);
                        }
                    },
                    text: "Save",
                    disabled: hasFinancialConditionsError,
                }}
                secondaryButton={{
                    action: () => {
                        setFinancialConditions({});
                        setEditModal(false);
                    },
                    text: "Cancel",
                }}
            >
                <ProductFinancialConditionsForm
                    id={`${id}-financial-conditions-form`}
                    financialConditions={updateFinancialConditions}
                    financialConditionsList={financialConditionsList}
                    onChange={conditions => setFinancialConditions(conditions)}
                    touched={touched}
                    onBlur={onBlur}
                    noTitle
                    canDelete={false}
                    productName={
                        productDetails?.product?.productName ||
                        productDetails?.productName
                    }
                    brandName={brand?.brandName || brand?.name || ""}
                    sapSkuNo={
                        productDetails?.product?.sapSkuNo ||
                        productDetails?.sapSkuNo
                    }
                    currency={currency}
                    price={productDetails?.priceCondition?.price}
                />
            </Modal>
            <Card
                id={`${id}-edit-financial-condition`}
                title="Financial conditions"
                action={{
                    icon: <EditIcon fontSize="small" />,
                    hidden:
                        hideEdit ||
                        (!isEditContract && !isCreateContract) ||
                        (isEditContract && !mappedFinancialConditions),
                    disabled: disableEdit,
                    action: () =>
                        isEditContract
                            ? setEditModal(true)
                            : !!callbacks?.onEditClick &&
                              callbacks.onEditClick("financial-conditions"),
                }}
            >
                <GrayBox
                    id={`${id}-product-details`}
                    padding={"1rem"}
                    header={
                        <Typography variant="h3">{`${
                            productDetails?.product?.productName ||
                            productDetails?.productName
                        } (${brand?.brandName || brand?.name})`}</Typography>
                    }
                />

                <Grid
                    item
                    xs={12}
                    container
                    rowSpacing={3}
                    columnSpacing={4}
                    marginTop={2}
                >
                    <Grid item xs={12} md={6}>
                        <FieldRenderer
                            id={`${id}-selected-condition`}
                            label="Selected Condition"
                            value={mappedFinancialConditions?.name}
                        />
                    </Grid>

                    {mappedFinancialConditions?.condition ===
                        "fixedRebateAmount" && (
                        <Fragment>
                            <Grid item xs={12} md={shrinkColumns ? 3 : 6}>
                                <FieldRenderer
                                    id={`${id}-condition-type-fixed-rebate-amount`}
                                    label="Condition type"
                                    value={"Fixed rebate amount"}
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <FieldRenderer
                                    id={`${id}-fixed-rebate-amount`}
                                    label={
                                        mappedFinancialConditions?.amountLabel ||
                                        "Amount"
                                    }
                                    value={`${thousandsSeparator(
                                        mappedFinancialConditions?.amount,
                                    )} ${currency}`}
                                />
                            </Grid>
                        </Fragment>
                    )}

                    {mappedFinancialConditions?.condition ===
                        "rebatePercentage" && (
                        <Fragment>
                            <Grid item xs={12} md={shrinkColumns ? 3 : 6}>
                                <FieldRenderer
                                    id={`${id}-financial-condition-rebate-percentage`}
                                    label="Condition type"
                                    value={"Rebate percentage"}
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <FieldRenderer
                                    id={`${id}-rebate-percentage-amount`}
                                    label="Percentage"
                                    value={`${thousandsSeparator(
                                        mappedFinancialConditions?.amount,
                                    )}%`}
                                />
                            </Grid>
                        </Fragment>
                    )}
                </Grid>
            </Card>
        </Grid>
    ) : null;
};

export default FinancialConditionsSummary;
