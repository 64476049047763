import {
    Typography,
    InputLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
    TextField,
    FormHelperText,
} from "@mui/material";
import React, { useState, Fragment, useMemo, useEffect } from "react";

// Own components
import { Modal } from "@components";

/**
 * Props type
 */

type Props = {
    id: string;
    onSubmit: (reason: string) => void;
    onClose: () => void;
    loading: boolean;
    title: string;
    open: boolean;
    children?: React.ReactNode;
};

const REASON_OF_CANCELLATION = [
    { id: "wrongDataDElivery", label: "Wrong data-delivery supplier/insurer" },
    {
        id: "wrongManualData",
        label: "Wrong manual data-entry by end-user",
    },
    { id: "other", label: "Other" },
];
/**
 * Cancel With Reasons
 */
const CancelWithReasons = ({
    id,
    onSubmit,
    onClose,
    loading,
    open,
    title,
    children,
}: Props) => {
    const [reasonCancellationIndex, setReasonIndex] = useState<
        undefined | number
    >(undefined);
    const [other, setOther] = useState("");
    const [touched, setTouch] = useState(false);

    /**
     * Reset cancel states
     */
    const resetCancelStates = () => {
        setTouch(false);
        setReasonIndex(undefined);
        setOther("");
    };

    useEffect(() => {
        if (!open) {
            resetCancelStates();
        }
    }, [open]);
    /**
     * Cancel outcome
     */
    const handleSubmit = () => {
        if (reasonCancellationIndex === undefined) return;
        onSubmit(
            REASON_OF_CANCELLATION[reasonCancellationIndex]?.id === "other"
                ? other
                : REASON_OF_CANCELLATION[reasonCancellationIndex]?.label,
        );
    };

    /**
     * Validate "Other" reason, it should be => 150 char
     */
    const limitDescription = useMemo(() => {
        if (!other) return;

        return typeof other === "string" && other?.length > 150;
    }, [other]);

    /**
     * Render
     */
    return (
        <Modal
            id={id}
            mediumView
            title={title}
            open={open}
            onClose={onClose}
            primaryButton={{
                text: "Submit",
                action: handleSubmit,
                id: `${id}-submit-button`,
                loading,
                disabled:
                    typeof reasonCancellationIndex !== "number" ||
                    (REASON_OF_CANCELLATION[reasonCancellationIndex].id ===
                        "other" &&
                        (!other || limitDescription)),
            }}
            secondaryButton={{
                action: onClose,
                text: "Cancel",
            }}
        >
            <div>
                {children}
                <InputLabel shrink>{"Reason of cancellation (*)"}</InputLabel>

                <RadioGroup
                    id={`${id}-reason-of-cancellation`}
                    value={reasonCancellationIndex}
                    onChange={event =>
                        setReasonIndex(parseInt(event.target.value))
                    }
                >
                    {REASON_OF_CANCELLATION.map((item, index) => (
                        <FormControlLabel
                            key={item.id}
                            disabled={loading}
                            value={index}
                            control={<Radio size="small" />}
                            id={item.id}
                            label={
                                <Typography variant="caption1">
                                    {item.label}
                                </Typography>
                            }
                        />
                    ))}
                </RadioGroup>
                {typeof reasonCancellationIndex === "number" &&
                    REASON_OF_CANCELLATION[reasonCancellationIndex]?.id ===
                        "other" && (
                        <Fragment>
                            <TextField
                                value={other}
                                fullWidth
                                multiline
                                id={`${id}-other`}
                                disabled={loading}
                                minRows={6}
                                autoComplete="off"
                                error={!other && touched}
                                onBlur={() => setTouch(true)}
                                name="other"
                                variant="outlined"
                                size="small"
                                onChange={event => setOther(event.target.value)}
                            />
                            <FormHelperText error={!!limitDescription}>
                                Reason must be shorter than or equal to 150
                                characters
                            </FormHelperText>
                        </Fragment>
                    )}
            </div>
        </Modal>
    );
};
export default CancelWithReasons;
