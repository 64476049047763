import { Box, Typography, Button } from "@mui/material";
import { navigate } from "gatsby";
import React, { Fragment, useCallback } from "react";

// types
import { Location } from "@types";

/**
 * Props type
 */
interface Props {
    location: Location;
    children: React.ReactElement<
        any,
        string | React.JSXElementConstructor<any>
    >;
}

const SUB_PAGES = [
    {
        path: "/maintenance/teams/",
        label: "Teams",
    },
    {
        path: "/maintenance/roles/",
        label: "Roles",
    },
];

/**
 * Teams Layout
 */
const TeamsLayout = ({ location, children }: Props) => {
    /**
     * Check if the page is active
     */
    const isActive = useCallback(
        page => {
            return location.pathname.includes(page);
        },
        [location],
    );

    /**
     * Render
     */
    return (
        <Fragment>
            <Box mb={5} mt={3} display="flex" alignItems="center">
                {SUB_PAGES.map((page, index) => (
                    <Button
                        id={`dashboard-financial-transaction-type-btn-${index}`}
                        key={index}
                        onClick={() => navigate(page.path)}
                        variant="outlined"
                        size="large"
                        sx={{
                            fontSize: "0.875rem",
                            backgroundColor: isActive(page.path)
                                ? "#e6f5fc"
                                : "none",
                            borderColor: "transparent",
                            padding: "0.3rem 1.3rem",
                            marginRight: "1rem",
                        }}
                    >
                        <Typography variant="subtitle2">
                            {page.label}
                        </Typography>
                    </Button>
                ))}
            </Box>

            <Box mt={5}>{children}</Box>
        </Fragment>
    );
};
export default TeamsLayout;
