// Lib

import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { NumericFormat } from "react-number-format";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";

import React, { useEffect, useState, useMemo } from "react";

// Own components
import { Modal } from "@components";

/**
 * UOM
 */
type UOM = {
    unitOfMeasureFrom: string;
    unitOfMeasureTo: string;
    discountPercentage?: string;
    price?: string;
};

/**
 * Props type
 */
type Props = {
    id: string;
    open: boolean;
    onSubmit: (values: UOM) => void;
    scaleTypeLabel: string;
    scaleType: string;
    currency: string;
    onClose: () => void;
    cumulativeUomRanges: Array<{
        unitOfMeasureFrom: string;
        unitOfMeasureTo: string;
    }>;
};

const isRangeWithinAnother = (existingRanges, newRange) => {
    const [newMin, newMax] = newRange;

    for (const { unitOfMeasureFrom, unitOfMeasureTo } of existingRanges) {
        if (
            (+newMin >= +unitOfMeasureFrom && +newMin <= +unitOfMeasureTo) || // New range's start is within an existing range
            (+newMax >= +unitOfMeasureFrom && +newMax <= +unitOfMeasureTo) || // New range's end is within an existing range
            (+newMin <= +unitOfMeasureFrom && +newMax >= +unitOfMeasureTo) // New range fully covers an existing range
        ) {
            return false; // Overlap detected
        }
    }

    return true; // No overlap
};

/**
 * Cumulative Scale
 */
const CumulativeScale = ({
    id,
    open,
    onSubmit,
    scaleTypeLabel,
    scaleType,
    currency,
    onClose,
    cumulativeUomRanges,
}: Props) => {
    // Scale state
    const [uomData, setUomData] = useState<UOM>({
        unitOfMeasureFrom: "",
        unitOfMeasureTo: "",
        [scaleType]: "",
    });

    // touched
    const [touched, setTouched] = useState({});
    const [error, setError] = useState("");

    useEffect(() => {
        setTouched({});
        setError("");
    }, [open]);

    /**
     * Change scale field handler
     */
    const onScaleFieldChange = (key: string, value: any) => {
        const copyUom = { ...uomData };

        copyUom[key] = value;
        setUomData(copyUom);
    };

    /**
     * Handle blur
     */
    const onBlur = (key: string) => {
        const copyTouched = { ...touched };
        if (copyTouched[key]) return;
        copyTouched[key] = true;
        setTouched(copyTouched);
    };

    /**
     * Check if the scale valid
     */
    const canSaveScale = useMemo(() => {
        if (!uomData) return;

        const {
            unitOfMeasureFrom,
            unitOfMeasureTo,
            discountPercentage,
            price,
        } = uomData;

        return (
            ((!!discountPercentage && +discountPercentage <= 100) || !!price) &&
            !!unitOfMeasureFrom &&
            !!unitOfMeasureTo &&
            +unitOfMeasureFrom < +unitOfMeasureTo
        );
    }, [uomData]);

    /**
     * Handle submit
     */
    const handleSubmit = () => {
        const { unitOfMeasureFrom, unitOfMeasureTo } = uomData;

        if (
            !isRangeWithinAnother(cumulativeUomRanges, [
                +unitOfMeasureFrom,
                +unitOfMeasureTo,
            ])
        ) {
            setError(
                "From measure to measure can not overlap with any already existing From measure to measure for this scale period.",
            );
            return;
        }

        if (+unitOfMeasureFrom >= +unitOfMeasureTo) {
            setError("From measure must be less than to measure.");
            return;
        }

        if (canSaveScale) {
            onSubmit(uomData as UOM);
            setUomData({
                unitOfMeasureFrom: "",
                unitOfMeasureTo: "",
                [scaleType]: "",
            });
        }
    };

    return (
        <Modal
            open={!!open}
            id={`add-new-cumulative-scale-modal`}
            title={"Add cumulative scale"}
            onClose={() => {
                setUomData({
                    unitOfMeasureFrom: "",
                    unitOfMeasureTo: "",
                    [scaleType]: "",
                });
                setError("");
                onClose();
            }}
            primaryButton={{
                action: () => handleSubmit(),
                text: "Save",
                disabled: !canSaveScale,
            }}
            secondaryButton={{
                action: () => {
                    setUomData({
                        unitOfMeasureFrom: "",
                        unitOfMeasureTo: "",
                        [scaleType]: "",
                    });
                    setError("");
                    onClose();
                },
                text: "Cancel",
            }}
        >
            <Grid container item xs={12} px={"24px"} spacing={1}>
                <Grid item xs={4} pb={1}>
                    <InputLabel
                        id={`${id}-${"unitOfMeasureFrom-label"}`}
                        shrink
                        error={
                            !!error ||
                            (touched["unitOfMeasureFrom"] &&
                                !uomData.unitOfMeasureFrom) ||
                            (touched["unitOfMeasureTo"] &&
                                touched["unitOfMeasureFrom"] &&
                                +uomData?.unitOfMeasureFrom >=
                                    +uomData?.unitOfMeasureTo)
                        }
                    >
                        {"From measure(*)"}
                    </InputLabel>
                    <NumericFormat
                        name={"unitOfMeasureFrom"}
                        fullWidth
                        value={uomData?.unitOfMeasureFrom}
                        id={`${id}-${"unitOfMeasureFrom"}`}
                        autoComplete="off"
                        onBlur={() => onBlur("unitOfMeasureFrom")}
                        error={
                            !!error ||
                            (touched["unitOfMeasureFrom"] &&
                                !uomData?.unitOfMeasureFrom) ||
                            (touched["unitOfMeasureTo"] &&
                                touched["unitOfMeasureFrom"] &&
                                +uomData?.unitOfMeasureFrom >=
                                    +uomData?.unitOfMeasureTo)
                        }
                        helperText={
                            touched["unitOfMeasureFrom"] &&
                            !uomData?.unitOfMeasureFrom
                                ? "This field is required"
                                : undefined
                        }
                        thousandSeparator="."
                        decimalSeparator=","
                        decimalScale={0}
                        customInput={TextField}
                        allowNegative={false}
                        size="small"
                        onValueChange={({ floatValue }) => {
                            onScaleFieldChange(
                                "unitOfMeasureFrom",
                                floatValue?.toString() ?? "",
                            );
                        }}
                    />
                    {touched["unitOfMeasureTo"] &&
                        touched["unitOfMeasureFrom"] &&
                        +uomData?.unitOfMeasureFrom >=
                            +uomData?.unitOfMeasureTo && (
                            <FormHelperText error>
                                From measure must be less than to measure.
                            </FormHelperText>
                        )}
                </Grid>
                <Grid item xs={4} pb={1}>
                    <InputLabel
                        id={`${id}-${"unitOfMeasureTo-label"}`}
                        shrink
                        error={
                            !!error ||
                            (touched["unitOfMeasureTo"] &&
                                !uomData?.unitOfMeasureTo) ||
                            (touched["unitOfMeasureTo"] &&
                                touched["unitOfMeasureFrom"] &&
                                +uomData?.unitOfMeasureFrom >=
                                    +uomData?.unitOfMeasureTo)
                        }
                    >
                        {"To measure(*)"}
                    </InputLabel>

                    <NumericFormat
                        id={`${id}-${"unitOfMeasureTo"}`}
                        name={"unitOfMeasureTo"}
                        fullWidth
                        value={uomData?.unitOfMeasureTo}
                        autoComplete="off"
                        onBlur={() => onBlur("unitOfMeasureTo")}
                        error={
                            !!error ||
                            (touched["unitOfMeasureTo"] &&
                                !uomData?.unitOfMeasureTo) ||
                            (touched["unitOfMeasureTo"] &&
                                touched["unitOfMeasureFrom"] &&
                                +uomData?.unitOfMeasureFrom >=
                                    +uomData?.unitOfMeasureTo)
                        }
                        helperText={
                            touched["unitOfMeasureTo"] &&
                            !uomData?.unitOfMeasureTo
                                ? "This field is required"
                                : undefined
                        }
                        thousandSeparator="."
                        decimalSeparator=","
                        decimalScale={0}
                        customInput={TextField}
                        allowNegative={false}
                        size="small"
                        onValueChange={({ floatValue }) => {
                            onScaleFieldChange(
                                "unitOfMeasureTo",
                                floatValue?.toString() ?? "",
                            );
                        }}
                    />
                </Grid>

                <Grid item xs={4} pb={1}>
                    <InputLabel
                        id={`${id}-price-condition-label`}
                        shrink
                        error={
                            !!touched[scaleType] &&
                            ((scaleType === "discountPercentage" &&
                                +(uomData?.[scaleType] ?? 0) > 100) ||
                                (scaleType === "price" && !uomData[scaleType]))
                        }
                    >
                        {`${scaleTypeLabel} (*)`}
                    </InputLabel>

                    <NumericFormat
                        name={"amount"}
                        fullWidth
                        value={uomData ? uomData[scaleType] : ""}
                        id={`${id}-${scaleType}`}
                        autoComplete="off"
                        onBlur={() => onBlur(scaleType)}
                        error={
                            !!touched[scaleType] &&
                            ((scaleType === "discountPercentage" &&
                                !!uomData[scaleType] &&
                                +(uomData?.[scaleType] ?? 0) > 100) ||
                                (scaleType === "price" && !uomData[scaleType]))
                        }
                        thousandSeparator="."
                        decimalSeparator=","
                        customInput={TextField}
                        allowNegative={false}
                        size="small"
                        isAllowed={values => {
                            const { floatValue } = values;
                            return !!floatValue &&
                                !!uomData &&
                                uomData["discountPercentage"] !== undefined
                                ? floatValue <= 100
                                : true;
                        }}
                        onValueChange={({ floatValue }) => {
                            onScaleFieldChange(
                                scaleType,
                                floatValue?.toString() ?? "",
                            );
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Typography variant="caption1">
                                        {scaleType === "discountPercentage"
                                            ? "%"
                                            : currency}
                                    </Typography>
                                </InputAdornment>
                            ),
                        }}
                    />
                </Grid>

                {!!error && (
                    <Grid item xs={12}>
                        <FormHelperText error>{error}</FormHelperText>
                    </Grid>
                )}
            </Grid>
        </Modal>
    );
};

export default React.memo(CumulativeScale);
