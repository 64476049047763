//import { useApi } from "../api";
import { useAxios } from "./useAxios";
import type { Api } from "@types";

const BASE_URI = `/v0`;

/**
 * Fetch reports
 */
export const useGetReports = () => {
    const url = `${BASE_URI}/report`;

    const {
        response: list,
        loading: listLoading,
        fetch: loadList,
        error: listError,
    }: Api = useAxios(
        {
            method: "GET",
            url,
        },
        { errorHandler: `Failed to fetch the report` },
        true,
    );

    return {
        list: list,
        loading: listLoading,
        error: listError,
        load: (path: string) =>
            loadList({
                url: `${url}/${path}`,
            }),
    };
};
