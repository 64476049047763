import { atom } from "recoil";

export const editOrderStepsState = atom({
    key: "editOrderStepsState",
    default: [
        {
            id: "edit-order",
            title: "Edit order",
            description: "",
            isPrepared: false,
        },
    ],
});

export const linkOrderStepsState = atom({
    key: "linkOrderStepsState",
    default: [
        {
            id: "link-order",
            title: "Link order to contract",
            description:
                "Lorem 1 ipsum dolor sit amet, consectetur adipiscing elit. Nunc sed risus ut elit nibh id. Nisl, praesent pulvinar.",
            isPrepared: false,
        },
    ],
});

export const viewOrderStepsState = atom({
    key: "viewOrderStepsState",
    default: [
        {
            id: "view-order",
            title: "View order",
            description:
                "Lorem 1 ipsum dolor sit amet, consectetur adipiscing elit. Nunc sed risus ut elit nibh id. Nisl, praesent pulvinar.",
            isPrepared: true,
        },
    ],
});

export const addOutcomeStepsState = atom({
    key: "addOutcomeStepsState",
    default: [
        {
            id: "add-outcome",
            title: "Add outcome",
            description:
                "Lorem 1 ipsum dolor sit amet, consectetur adipiscing elit. Nunc sed risus ut elit nibh id. Nisl, praesent pulvinar.",
            isPrepared: false,
        },
    ],
});
