import { atom } from "recoil";

export const createPerformanceBasedContractStepsState = atom({
    key: "createPerformanceBasedContractStepsState",
    default: [
        {
            id: "general-information",
            title: "General information",
            description:
                "Lorem 1 ipsum dolor sit amet, consectetur adipiscing elit. Nunc sed risus ut elit nibh id. Nisl, praesent pulvinar.",
            isPrepared: false,
            isCompleted: false,
        },
        {
            id: "product-details",
            title: "Product details",
            description:
                "Lorem 2 ipsum dolor sit amet, consectetur adipiscing elit. Nunc sed risus ut elit nibh id. Nisl, praesent pulvinar.",
            isPrepared: false,
            isCompleted: false,
        },
        {
            id: "financial-conditions",
            title: "Financial conditions",
            description:
                "Lorem 3 ipsum dolor sit amet, consectetur adipiscing elit. Nunc sed risus ut elit nibh id. Nisl, praesent pulvinar.",
            isPrepared: true, // it is an optional so it is initially prepared
            isCompleted: false,
        },
        {
            id: "contract-milestones",
            title: "Pricing & milestones",
            description:
                "Lorem 3 ipsum dolor sit amet, consectetur adipiscing elit. Nunc sed risus ut elit nibh id. Nisl, praesent pulvinar.",
            isPrepared: false,
            isCompleted: false,
        },
        {
            id: "additional-partners",
            title: "Additional partners",
            description:
                "Lorem 2 ipsum dolor sit amet, consectetur adipiscing elit. Nunc sed risus ut elit nibh id. Nisl, praesent pulvinar.",
            isPrepared: false,
            isCompleted: false,
        },
        {
            id: "contract-review",
            title: "Review & submit",
            description:
                "Lorem 5ipsum dolor sit amet, consectetur adipiscing elit. Nunc sed risus ut elit nibh id. Nisl, praesent pulvinar.",
            isPrepared: true,
            isCompleted: false,
        },
    ],
});

export const createVolumeBasedContractStepsState = atom({
    key: "  createVolumeBasedContractStepsState",
    default: [
        {
            id: "model",
            title: "Model",
            description:
                "Lorem 1 ipsum dolor sit amet, consectetur adipiscing elit. Nunc sed risus ut elit nibh id. Nisl, praesent pulvinar.",
            isPrepared: false,
            isCompleted: false,
        },
        {
            id: "category-tags",
            title: "Category tags",
            description:
                "Lorem 1 ipsum dolor sit amet, consectetur adipiscing elit. Nunc sed risus ut elit nibh id. Nisl, praesent pulvinar.",
            isPrepared: false,
            isCompleted: false,
        },
        {
            id: "general-information",
            title: "General information",
            description:
                "Lorem 1 ipsum dolor sit amet, consectetur adipiscing elit. Nunc sed risus ut elit nibh id. Nisl, praesent pulvinar.",
            isPrepared: false,
            isCompleted: false,
        },
        {
            id: "product-details",
            title: "Product details",
            description:
                "Lorem 2 ipsum dolor sit amet, consectetur adipiscing elit. Nunc sed risus ut elit nibh id. Nisl, praesent pulvinar.",
            isPrepared: false,
            isCompleted: false,
        },
        {
            id: "additional-partners",
            title: "Additional partners",
            description:
                "Lorem 2 ipsum dolor sit amet, consectetur adipiscing elit. Nunc sed risus ut elit nibh id. Nisl, praesent pulvinar.",
            isPrepared: false,
            isCompleted: false,
        },
        {
            id: "contract-review",
            title: "Review & submit",
            description:
                "Lorem 5ipsum dolor sit amet, consectetur adipiscing elit. Nunc sed risus ut elit nibh id. Nisl, praesent pulvinar.",
            isPrepared: true,
            isCompleted: false,
        },
    ],
});
