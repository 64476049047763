import { selector } from "recoil";

import {
    contractSelectionState,
    createOutcomeStepsState,
    orderSelectionState,
    outcomeDetailsState,
} from "@atoms";

const mapOutcomeForApi = (states: any) =>
    Object.assign(
        {},
        {
            contractId: states.contractSelection.contractId,
            orderId: states.orderSelection.orderId,
            outcomeVariables: states.outcomeDetails,
        },
    );

/**
 * Submit create outcome
 */
export const createOutcomeMapper = selector({
    key: "createOutcomeMapper",
    get: ({ get }) => {
        const contractSelection = get(contractSelectionState);
        const orderSelection = get(orderSelectionState);
        const outcomeDetails = get(outcomeDetailsState);

        // Currently this mapper is not used, we map the body request on the layout level
        // Check create ourcome submit function
        return mapOutcomeForApi({
            contractSelection,
            orderSelection,
            outcomeDetails,
        });
    },
    set: ({ reset }) => {
        reset(contractSelectionState);
        reset(orderSelectionState);
        reset(outcomeDetailsState);
        reset(createOutcomeStepsState);
    },
});
