import { atom } from "recoil";

export const externalCodesStepsState = atom({
    key: "externalCodesStepsState",
    default: [
        {
            id: "external-codes",
            title: "External codes",
            description: "",
            isPrepared: false,
        },
    ],
});
