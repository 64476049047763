// Icons
import GridOn from "@mui/icons-material/GridOn";

// Libs
import { navigate } from "gatsby";
import { Box, Typography, Grid } from "@mui/material";
import React, { Fragment, useLayoutEffect } from "react";

// Own component
import {
    Breadcrumbs,
    StepActions,
    Stepper,
    FlowLayout,
    FlowAside,
    FlowBody,
    FlowContent,
    FlowFooter,
    LoadingWrapper,
    FieldRenderer,
    Card,
} from "@components";
import { useGetBpfById } from "@hooks";

// Types
import type { Location } from "@types";

// Utils
import {
    constantMapper,
    displayDate,
    isArrayWithContent,
    thousandsSeparator,
} from "@utils";

/**
 * Props type
 */
interface Props {
    location: Location;
    bpfId: string;
    id?: string;
}

/**
 * Finance Details
 */
const FinanceDetails = ({ location, bpfId, id = "bpf-details" }: Props) => {
    const {
        load: loadBpfDetails,
        loading: { loading, downloadingEvidence },
        list: bpfDetails,
        error: { bpfDetailsError },
        downloadEvidence,
    }: any = useGetBpfById();

    /**
     * Load BPF details
     */
    useLayoutEffect(() => {
        if (!bpfId) return;
        loadBpfDetails(bpfId);
    }, [bpfId]);

    /**
     * Render
     */
    return (
        <Fragment>
            <Breadcrumbs
                id={`bpf-details-breadcrumbs`}
                icon={<GridOn color="primary" />}
                title="Dashboard"
                location={location}
            />
            <FlowLayout>
                <LoadingWrapper
                    fullHeight
                    id={`view-contract-loading`}
                    loading={loading}
                    error={bpfDetailsError}
                    NoEmptyPage
                >
                    <Fragment>
                        <FlowAside>
                            <Stepper
                                id={`bpf-details-stepper`}
                                title={`BPF details`}
                                subTitle={bpfDetails?.data?.bpfName}
                            />
                        </FlowAside>

                        <FlowBody>
                            <Fragment>
                                <FlowContent>
                                    <Fragment>
                                        <Card
                                            id={`${id}-additional-partners`}
                                            title="BPF Details"
                                        >
                                            <Grid
                                                item
                                                xs={12}
                                                container
                                                rowSpacing={3}
                                                columnSpacing={4}
                                            >
                                                <Grid item xs={12} md={6}>
                                                    <FieldRenderer
                                                        id={`${id}-outcome-bpfName`}
                                                        label="BPF Name"
                                                        value={
                                                            bpfDetails?.data
                                                                ?.bpfName
                                                        }
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <FieldRenderer
                                                        id={`${id}-outcome-dateProcessed`}
                                                        label="Date Processed"
                                                        value={displayDate(
                                                            bpfDetails?.data
                                                                ?.dateProcessed,
                                                        )}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <FieldRenderer
                                                        id={`${id}-outcome-contractRef`}
                                                        label="Contract reference"
                                                        value={
                                                            bpfDetails?.data
                                                                ?.contractRef
                                                        }
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <FieldRenderer
                                                        id={`${id}-outcome-customerRef`}
                                                        label="Customer reference"
                                                        value={
                                                            bpfDetails?.data
                                                                ?.customerRef
                                                        }
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <FieldRenderer
                                                        id={`${id}-outcome-primaryContractPartner`}
                                                        label="Contract partner"
                                                        value={
                                                            bpfDetails?.data
                                                                ?.primaryContractPartner
                                                        }
                                                    />
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <FieldRenderer
                                                        id={`${id}-outcome-status`}
                                                        label="Status"
                                                        value={constantMapper(
                                                            bpfDetails?.data
                                                                ?.status,
                                                        )}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Card>

                                        <Card
                                            id={`${id}-additional-partners`}
                                            title="Additional partners"
                                        >
                                            {!!bpfDetails?.data
                                                ?.additionalPartners &&
                                            !!isArrayWithContent(
                                                bpfDetails?.data
                                                    ?.additionalPartners,
                                            ) ? (
                                                <Grid
                                                    item
                                                    xs={12}
                                                    container
                                                    rowSpacing={3}
                                                    columnSpacing={4}
                                                >
                                                    {bpfDetails?.data?.additionalPartners?.map(
                                                        (partner, index) => (
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                key={index}
                                                                md={6}
                                                            >
                                                                <FieldRenderer
                                                                    id={`${id}-${index}`}
                                                                    label=""
                                                                    value={
                                                                        partner?.name
                                                                    }
                                                                />
                                                            </Grid>
                                                        ),
                                                    )}
                                                </Grid>
                                            ) : (
                                                <Box
                                                    display="flex"
                                                    justifyContent="center"
                                                >
                                                    <Typography variant="caption1">
                                                        No additional partners
                                                        found!
                                                    </Typography>
                                                </Box>
                                            )}
                                        </Card>
                                        {isArrayWithContent(
                                            bpfDetails?.data?.outcomes,
                                        ) && (
                                            <Fragment>
                                                {bpfDetails?.data?.outcomes?.map(
                                                    outcome => (
                                                        <Card
                                                            id={`${id}-additional-partners`}
                                                            title={
                                                                outcome?.salessystemOrderId
                                                            }
                                                            key={
                                                                outcome?.outcomeId
                                                            }
                                                        >
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                container
                                                                rowSpacing={3}
                                                                columnSpacing={
                                                                    4
                                                                }
                                                            >
                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                    md={6}
                                                                >
                                                                    <FieldRenderer
                                                                        id={`${id}-outcome-amount`}
                                                                        label="Amount"
                                                                        value={thousandsSeparator(
                                                                            outcome?.amount,
                                                                        )}
                                                                    />
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                    md={6}
                                                                >
                                                                    <FieldRenderer
                                                                        id={`${id}-outcome-measure`}
                                                                        label="Measure"
                                                                        value={
                                                                            outcome?.measure
                                                                        }
                                                                    />
                                                                </Grid>

                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                    md={6}
                                                                >
                                                                    <FieldRenderer
                                                                        id={`${id}-outcome-percentage`}
                                                                        label="Percentage"
                                                                        value={
                                                                            outcome?.percentage
                                                                                ? `${outcome?.percentage}%`
                                                                                : undefined
                                                                        }
                                                                    />
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                    md={6}
                                                                >
                                                                    <FieldRenderer
                                                                        id={`${id}-outcome-salessystemOrderId`}
                                                                        label="Cquence Id"
                                                                        value={
                                                                            outcome?.salessystemOrderId
                                                                        }
                                                                    />
                                                                </Grid>

                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                    md={6}
                                                                >
                                                                    <FieldRenderer
                                                                        id={`${id}-outcome-sapOrderNo`}
                                                                        label="SAP order no"
                                                                        value={
                                                                            outcome?.sapOrderNo
                                                                        }
                                                                    />
                                                                </Grid>

                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                    md={6}
                                                                >
                                                                    <FieldRenderer
                                                                        id={`${id}-outcome-status`}
                                                                        label="Status"
                                                                        value={constantMapper(
                                                                            outcome?.status,
                                                                        )}
                                                                    />
                                                                </Grid>

                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                    md={6}
                                                                >
                                                                    <FieldRenderer
                                                                        id={`${id}-outcome-vatAmount`}
                                                                        label="VAT amount"
                                                                        value={thousandsSeparator(
                                                                            outcome?.vatAmount,
                                                                        )}
                                                                    />
                                                                </Grid>

                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                    md={6}
                                                                >
                                                                    <FieldRenderer
                                                                        id={`${id}-outcome-vatPercentage`}
                                                                        label="VAT percentage"
                                                                        value={
                                                                            outcome?.vatPercentage
                                                                        }
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Card>
                                                    ),
                                                )}
                                            </Fragment>
                                        )}

                                        {bpfDetails?.data?.evidence && (
                                            <Card
                                                id={`${id}-additional-partners`}
                                                title="Evidences"
                                            >
                                                <Grid
                                                    item
                                                    xs={12}
                                                    container
                                                    rowSpacing={3}
                                                    columnSpacing={4}
                                                >
                                                    <Grid item xs={12} md={6}>
                                                        <FieldRenderer
                                                            id={`${id}-evidence-fileName`}
                                                            value={
                                                                bpfDetails?.data
                                                                    ?.evidence
                                                                    ?.fileName
                                                            }
                                                            loading={
                                                                downloadingEvidence
                                                            }
                                                            hasDownloadAction
                                                            onDownloadClick={() =>
                                                                downloadEvidence(
                                                                    bpfDetails
                                                                        ?.data
                                                                        ?.bpfId,
                                                                    bpfDetails
                                                                        ?.data
                                                                        ?.evidence
                                                                        ?.fileName,
                                                                )
                                                            }
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Card>
                                        )}
                                        {isArrayWithContent(
                                            bpfDetails?.data?.claims,
                                        ) && (
                                            <Fragment>
                                                {bpfDetails?.data?.claims?.map(
                                                    claim => (
                                                        <Card
                                                            id={`${id}-additional-partners`}
                                                            key={
                                                                claim.claimHeaderId
                                                            }
                                                            title={`Claim details (${claim?.filename})`}
                                                        >
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                container
                                                                rowSpacing={3}
                                                                columnSpacing={
                                                                    4
                                                                }
                                                            >
                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                    md={6}
                                                                >
                                                                    <FieldRenderer
                                                                        id={`${id}-claim-supplier`}
                                                                        label="Supplier"
                                                                        value={
                                                                            claim?.supplier
                                                                        }
                                                                    />
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                    md={6}
                                                                >
                                                                    <FieldRenderer
                                                                        id={`${id}-claim-status`}
                                                                        label="Status"
                                                                        value={constantMapper(
                                                                            claim?.status,
                                                                        )}
                                                                    />
                                                                </Grid>

                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                    md={6}
                                                                >
                                                                    <FieldRenderer
                                                                        id={`${id}-claim-dateFrom`}
                                                                        label="Date from"
                                                                        value={displayDate(
                                                                            claim?.dateFrom,
                                                                        )}
                                                                    />
                                                                </Grid>

                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                    md={6}
                                                                >
                                                                    <FieldRenderer
                                                                        id={`${id}-claim-date-to`}
                                                                        label="Date to"
                                                                        value={displayDate(
                                                                            claim?.dateTo,
                                                                        )}
                                                                    />
                                                                </Grid>

                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                    md={6}
                                                                >
                                                                    <FieldRenderer
                                                                        id={`${id}-claim-file-name`}
                                                                        label="File name"
                                                                        value={
                                                                            claim?.filename
                                                                        }
                                                                    />
                                                                </Grid>

                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                    md={6}
                                                                >
                                                                    <FieldRenderer
                                                                        id={`${id}-claim-file-date`}
                                                                        label="File date"
                                                                        value={displayDate(
                                                                            claim?.filedate,
                                                                        )}
                                                                    />
                                                                </Grid>

                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                    md={6}
                                                                >
                                                                    <FieldRenderer
                                                                        id={`${id}-claim-date-created`}
                                                                        label="Date created"
                                                                        value={displayDate(
                                                                            claim?.createdAt,
                                                                        )}
                                                                    />
                                                                </Grid>

                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                    md={6}
                                                                >
                                                                    <FieldRenderer
                                                                        id={`${id}-claim-total-units`}
                                                                        label="Units"
                                                                        value={thousandsSeparator(
                                                                            claim?.totalUnits,
                                                                        )}
                                                                    />
                                                                </Grid>

                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                    md={6}
                                                                >
                                                                    <FieldRenderer
                                                                        id={`${id}-claim-total-value`}
                                                                        label="Sales"
                                                                        value={thousandsSeparator(
                                                                            claim?.priceCorrectionSum ??
                                                                                claim?.totalValue,
                                                                        )}
                                                                    />
                                                                </Grid>

                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                    md={6}
                                                                >
                                                                    <FieldRenderer
                                                                        id={`${id}-claim-total-rebate`}
                                                                        label="Rebate"
                                                                        value={thousandsSeparator(
                                                                            claim?.priceCorrectionRebateSum ??
                                                                                claim?.totalRebateValue,
                                                                        )}
                                                                    />
                                                                </Grid>

                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                    md={6}
                                                                >
                                                                    <FieldRenderer
                                                                        id={`${id}-claim-vatIncluded`}
                                                                        label="VAT included"
                                                                        value={
                                                                            claim?.vatIncluded
                                                                                ? "Yes"
                                                                                : " No"
                                                                        }
                                                                    />
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                    md={6}
                                                                >
                                                                    <FieldRenderer
                                                                        id={`${id}-claim-vatPercentage`}
                                                                        label="VAT percentage"
                                                                        value={
                                                                            claim.vatIncluded
                                                                                ? claim.vatPercentage
                                                                                    ? `${claim.vatPercentage}%`
                                                                                    : ""
                                                                                : "NA"
                                                                        }
                                                                    />
                                                                </Grid>

                                                                {claim?.priceCorrectionStatus && (
                                                                    <Grid
                                                                        item
                                                                        xs={12}
                                                                        md={6}
                                                                    >
                                                                        <FieldRenderer
                                                                            id={`${id}-claim-price-correction-status`}
                                                                            label="Price correction status"
                                                                            value={constantMapper(
                                                                                claim.priceCorrectionStatus,
                                                                            )}
                                                                        />
                                                                    </Grid>
                                                                )}
                                                            </Grid>
                                                        </Card>
                                                    ),
                                                )}
                                            </Fragment>
                                        )}
                                    </Fragment>
                                </FlowContent>

                                <FlowFooter>
                                    <StepActions
                                        id={`bpf-details-step-actions`}
                                        primaryButton={{
                                            text: "Return to overview",
                                            action: () =>
                                                navigate(
                                                    `/dashboard/finances/bpf/`,
                                                ),
                                            isSubmitButton: true,
                                        }}
                                    />
                                </FlowFooter>
                            </Fragment>
                        </FlowBody>
                    </Fragment>
                </LoadingWrapper>
            </FlowLayout>
        </Fragment>
    );
};

export default FinanceDetails;
