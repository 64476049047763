// Icons
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

//Lib
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputAdornment from "@mui/material/InputAdornment";
import CircularProgress from "@mui/material/CircularProgress";
import React, { Fragment, useMemo, useState } from "react";
import classnames from "classnames";

// Utils
import { isArrayWithContent } from "@utils";

// Style
import * as style from "../style.module.scss";

type ValueItem = {
    label: string;
    value: string;
    filterKey: string;
};

/**
 * Props type
 */
interface Props {
    id: string;
    renderValue: string;
    className: string;
    loading: boolean;
    handleFilterChange: any;
    labelKey: string;
    valueKey: string;
    data: Array<any>;
    filterName: string;
    value: ValueItem[];
    disableItemSelection?: boolean;
}

/**s
 * Select single item
 */
const OneItemFilter: React.FC<Props> = ({
    id,
    renderValue,
    loading,
    handleFilterChange,
    labelKey,
    valueKey,
    data,
    filterName,
    value,
    className,
    disableItemSelection,
}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const mappedValue = useMemo(() => {
        if (value === undefined || !isArrayWithContent(value)) return;
        return value[0];
    }, [value]);

    const setAnchor = ev => {
        if (ev.target.tagName === "BUTTON") return setAnchorEl(ev.target);
        let anchor = ev.target;
        while (anchor.tagName !== "BUTTON") {
            anchor = anchor.parentElement;
        }
        setAnchorEl(anchor);
    };

    return (
        <Fragment>
            <Button
                className={classnames(style.button, className)}
                id={`${id}-price-range-button`}
                color="primary"
                onClick={setAnchor}
                size={"small"}
                disabled={loading}
                variant={"outlined"}
                endIcon={
                    loading ? (
                        <InputAdornment position="start">
                            <CircularProgress color="inherit" size={15} />
                        </InputAdornment>
                    ) : anchorEl ? (
                        <KeyboardArrowUpIcon />
                    ) : (
                        <KeyboardArrowDownIcon />
                    )
                }
            >
                <Typography variant="button">{renderValue}</Typography>
            </Button>

            <Popover
                id={`fade-menu`}
                anchorEl={anchorEl}
                open={open}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
            >
                <Box px={3} py={2} display="flex" flexDirection="column">
                    {isArrayWithContent(data) ? (
                        <FormControl>
                            <RadioGroup
                                id={`task-details-task-review`}
                                value={
                                    mappedValue !== undefined
                                        ? JSON.stringify(mappedValue[valueKey])
                                        : undefined
                                }
                                onChange={(_, radioValue) => {
                                    const val = data?.find(
                                        el =>
                                            el[valueKey] ===
                                            JSON.parse(radioValue),
                                    );

                                    handleFilterChange(
                                        filterName,
                                        val,
                                        valueKey,
                                        true,
                                    );
                                }}
                            >
                                {data.map((item, index) => (
                                    <FormControlLabel
                                        disabled={disableItemSelection}
                                        value={JSON.stringify(item[valueKey])}
                                        key={`${id}-filter-item-key-${index}`}
                                        id={`task-details-review-contract-approve`}
                                        control={<Radio size="small" />}
                                        label={
                                            <Typography variant="caption1">
                                                {item[labelKey]}
                                            </Typography>
                                        }
                                    />
                                ))}
                            </RadioGroup>
                        </FormControl>
                    ) : (
                        <Typography variant="caption1">No data</Typography>
                    )}
                </Box>
            </Popover>
        </Fragment>
    );
};

export default React.memo(OneItemFilter);
