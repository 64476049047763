import { useMemo } from "react";
import { useRecoilValue } from "recoil";

import { Location } from "@types";

/**
 * Find the current step
 * Toggle in progress status
 */
export const useFindStep = (
    location: Location | undefined,
    stepsState: any,
) => {
    /**
     * Step state for the atom (stepsState)
     */
    const steps = useRecoilValue<any>(stepsState);

    /**
     * Current index finder
     */
    const currentIndex = useMemo(() => {
        if (!steps || !location) return false;

        return steps.findIndex(step => {
            return location.pathname.includes(step.id);
        });
    }, [steps, location]);

    return { currentIndex, currentStep: steps[currentIndex] };
};
