import { Notification } from "@types";
import { atom } from "recoil";

/**
 * Notifications State
 */
export const notificationsState = atom({
    key: "notificationsState",
    default: {
        severity: undefined,
        message: "",
        autoHide: true,
    } as Notification,
});
