// Icons
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

// Lib
import { Breadcrumbs as _Breadcrumbs } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { navigate } from "gatsby";
import { Helmet } from "react-helmet";
import React, { useEffect, useState, Fragment } from "react";
import classnames from "classnames";

// Constants
import { BREADCRUMBS_MAP } from "@constants";

// Types
import { Location } from "@types";

// Styles
import * as style from "./style.module.scss";

/**
 * Breadcrumbs Page
 */

type BreadcrumbsPage = {
    label: string;
    id: string;
    homePath?: string;
};

/**
 * Props type
 */
interface Props {
    location: Location;
    icon: React.ReactNode;
    title: string;
    resetAtoms?: () => void;
    id: string;
    onClick?: () => void;
}

/**
 * Breadcrumbs
 */
const Breadcrumbs: React.FC<Props> = ({
    location,
    icon,
    title,
    resetAtoms,
    id,
    onClick,
}) => {
    // State
    const [currentPage, setPage] = useState<BreadcrumbsPage | undefined>(
        undefined,
    );

    useEffect(() => {
        if (!location) return;

        const index = BREADCRUMBS_MAP.findIndex(item =>
            location.pathname.includes(item.id),
        );

        if (index === -1) {
            setPage(undefined);
        } else setPage(BREADCRUMBS_MAP[index]);
    }, [location]);

    /**
     * Render
     */
    return (
        <Fragment>
            {title && (
                <Helmet>
                    <title>
                        {currentPage?.label
                            ? `${title} | ${currentPage.label}`
                            : `${title}`}
                    </title>
                </Helmet>
            )}

            <div className={style.wrapper}>
                <div className={style.title}>
                    <div
                        className={style.item}
                        id={`${id}-home-page-btn`}
                        onKeyDown={() => {
                            if (onClick) {
                                onClick();
                                return;
                            }
                            if (currentPage?.homePath) {
                                navigate(currentPage.homePath);
                                !!resetAtoms && resetAtoms();
                            }
                        }}
                        onClick={() => {
                            if (onClick) {
                                onClick();
                                return;
                            }
                            if (currentPage?.homePath) {
                                navigate(currentPage.homePath);
                                !!resetAtoms && resetAtoms();
                            }
                        }}
                    >
                        {icon}
                    </div>
                    <div
                        id={`${id}-sub-page-btn`}
                        className={classnames(style.item, {
                            [style.hasCursor]: !!currentPage?.homePath,
                        })}
                        onKeyDown={() => {
                            if (onClick) {
                                onClick();
                                return;
                            }
                            if (currentPage?.homePath) {
                                navigate(currentPage.homePath);
                                !!resetAtoms && resetAtoms();
                            }
                        }}
                        onClick={() => {
                            if (onClick) {
                                onClick();
                                return;
                            }
                            if (currentPage?.homePath) {
                                navigate(currentPage.homePath);
                                !!resetAtoms && resetAtoms();
                            }
                        }}
                    >
                        {title}
                    </div>
                    <div className={classnames(style.nav, style.item)}>
                        <_Breadcrumbs
                            separator={<ArrowForwardIosIcon fontSize="small" />}
                            aria-label="breadcrumb"
                            id={`${id}-breadcrumbs`}
                        >
                            {!!currentPage && (
                                <Box display="flex" alignItems="center">
                                    <ArrowForwardIosIcon
                                        sx={{ mx: "1rem", fontSize: "0.75rem" }}
                                        color="primary"
                                    />
                                    <Typography
                                        className={style.currentPage}
                                        variant="caption1"
                                        color="primary"
                                        id={`${id}-nav-${currentPage?.id}`}
                                    >
                                        {currentPage.label}
                                    </Typography>
                                </Box>
                            )}
                        </_Breadcrumbs>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Breadcrumbs;
