import { AccountStatus, CreateAccountForm } from "@types";
import { atom } from "recoil";

export const createPartnerState = atom({
    key: "createPartnerState",
    default: {
        accountId: "",
        accountName: "",
        homeCountry: { countryIsoCode: "", country: "" },
        countryIsoCode: { countryIsoCode: "", country: "" },
        sapAccountCode: "",
        knowyoursupplierid: "",
        accountType: { accountTypeId: "", accountTypeName: "" },
        accountCity: "",
        accountAddress: "",
        accountPostalCode: "",
        accountStatus: { label: "Active", value: AccountStatus.ACTIVE },
        bankType: "",
        codsId: "",
    } as CreateAccountForm,
});
