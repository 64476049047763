import { atom } from "recoil";

// Constants
import { ROWRENDERERCONST } from "@constants";

/**
 * User info
 */
export const userState = atom({
    key: "userState",
    default: undefined,
});

const localStorageEffect =
    () =>
    ({ setSelf, onSet }) => {
        if (typeof window !== "undefined") {
            const storedTeam = localStorage.getItem(
                ROWRENDERERCONST.__PICKED_TEAM,
            );

            if (
                storedTeam &&
                storedTeam !== "undefined" &&
                storedTeam !== "null"
            ) {
                const parsedTeam = JSON.parse(storedTeam);

                setSelf(parsedTeam);
            }
        }

        onSet((newValue, _, isReset) => {
            if (typeof window !== "undefined") {
                isReset
                    ? localStorage.removeItem(ROWRENDERERCONST.__PICKED_TEAM)
                    : localStorage.setItem(
                          ROWRENDERERCONST.__PICKED_TEAM,
                          JSON.stringify(newValue),
                      );
            }
        });
    };

export const teamState = atom({
    key: "teamState",
    default: undefined,
    effects: [localStorageEffect()],
});
