//Lib
import { useRecoilValue } from "recoil";
import { useMemo } from "react";

// Atoms
import { teamState } from "@atoms";

const TEAM_COUNTRIES = {
    TEAM_GERMANY: "DE",
    TEAM_NETHERLAND: "NL",
};

export const useUserTeamCountry = () => {
    /**
     * Get profile data
     */
    const team = useRecoilValue<any>(teamState);

    const mapTeamCountries = useMemo(() => {
        const mappedTeamCountries = {};

        if (!team?.country) return {};

        Object.keys(TEAM_COUNTRIES).forEach(
            key =>
                (mappedTeamCountries[key] =
                    TEAM_COUNTRIES[key] === team?.country?.countryIsoCode),
        );

        return mappedTeamCountries as any;
    }, [team?.country]);

    return mapTeamCountries;
};
