//import libs
import {
    TableCell,
    TableRow,
    Typography,
    Tooltip,
    Checkbox,
} from "@mui/material";
import React, { Fragment, useMemo } from "react";

//utils
import {
    constantMapper,
    displayDate,
    isArrayWithContent,
    thousandsSeparator,
    truncateString,
} from "@utils";

type Row = {
    claimHeaderId: string;
    accountName: string;
    dateProcessed: string;
    dateFrom: string;
    dateTo: string;
    filedate: string;
    filename: string;
    supplier: string;
    createdAt: string;
    totalRebateValue: string;
    vatIncluded: string;
    status: string;
    priceCorrectionStatus?: string;
};
interface Props {
    row: Row;
    rowIndex: number;
    id: string;
    callbacks: any;
    selectedRow: string;
    mapper?: (key: string, value: string) => { brandName: string } | undefined;
    disabled?: boolean;
    isFinancialTransaction?: boolean;
    isPriceCorrectionClaims?: boolean;
}

const BPFClaimsSelectionRow = ({
    row,
    rowIndex,
    id,
    callbacks,
    disabled,
    isFinancialTransaction,
    isPriceCorrectionClaims,
}: Props) => {
    /**
     * Check if the item is selected
     * don't return undefined to avoid warnings
     */
    const isItemSelected = useMemo(() => {
        if (
            !row?.claimHeaderId ||
            !callbacks?.selectedItems ||
            !isArrayWithContent(callbacks?.selectedItems)
        )
            return false;

        return callbacks?.selectedItems?.includes(row?.claimHeaderId);
    }, [callbacks?.selectedItems, row?.claimHeaderId]);

    /**
     * Render
     */
    return (
        <TableRow
            sx={{ cursor: "pointer" }}
            selected={!!isItemSelected}
            id={`${id}-row-${rowIndex}`}
            onClick={event => {
                if (!disabled) {
                    callbacks.onSelect(
                        row.claimHeaderId,
                        parseInt((event.target as HTMLInputElement).value),
                    );
                }
            }}
        >
            <TableCell
                width={!isFinancialTransaction ? "4.6875rem" : "undefined"}
                padding={isFinancialTransaction ? "checkbox" : "normal"}
            >
                <Checkbox
                    onChange={event => {
                        callbacks.onSelect(
                            row.claimHeaderId,
                            parseInt(event.target.value),
                        );
                    }}
                    disabled={disabled}
                    checked={!!isItemSelected}
                    size="small"
                    id={`${id}-row-${rowIndex}-outcome-contract`}
                />
            </TableCell>
            <TableCell>
                <Typography variant="caption2">
                    {row.accountName || row.supplier}
                </Typography>
            </TableCell>
            <TableCell>
                <Tooltip arrow placement="top" title={row.filename}>
                    <Typography variant="caption2">
                        {truncateString(row.filename, 20, true)}
                    </Typography>
                </Tooltip>
            </TableCell>
            {!isFinancialTransaction && (
                <TableCell>
                    <Typography variant="caption2">
                        {displayDate(row.filedate)}
                    </Typography>
                </TableCell>
            )}
            {!isPriceCorrectionClaims && (
                <Fragment>
                    <TableCell>
                        <Typography variant="caption2">
                            {displayDate(row.dateFrom)}
                        </Typography>
                    </TableCell>
                    <TableCell>
                        <Typography variant="caption2">
                            {displayDate(row.dateTo)}
                        </Typography>
                    </TableCell>
                    {!isFinancialTransaction && (
                        <TableCell>
                            <Typography variant="caption2">
                                {displayDate(
                                    row.dateProcessed || row.createdAt,
                                )}
                            </Typography>
                        </TableCell>
                    )}{" "}
                    {isFinancialTransaction && (
                        <TableCell>
                            <Typography variant="caption2">
                                {row.totalRebateValue
                                    ? thousandsSeparator(row.totalRebateValue)
                                    : "-"}
                            </Typography>
                        </TableCell>
                    )}
                    {isFinancialTransaction && (
                        <TableCell>
                            <Typography variant="caption2">
                                {typeof row.vatIncluded === "boolean"
                                    ? row.vatIncluded
                                        ? "Yes"
                                        : " No"
                                    : "-"}
                            </Typography>
                        </TableCell>
                    )}
                    {isFinancialTransaction && (
                        <TableCell>
                            <Typography variant="caption2">
                                {constantMapper(
                                    row?.priceCorrectionStatus
                                        ? row?.priceCorrectionStatus
                                        : row.status,
                                )}
                            </Typography>
                        </TableCell>
                    )}
                </Fragment>
            )}
        </TableRow>
    );
};

export default BPFClaimsSelectionRow;
