import { useAxios } from "./useAxios";
import type { Api } from "@types";

const BASE_URI = `/v0`;

/**
 * Fetch product details
 */
export const useProductDetails = () => {
    const {
        response: list,
        error,
        fetch: loadList,
        loading,
    }: Api = useAxios(
        {
            method: "GET",
        },
        { errorHandler: `Failed to fetch product details` },
    );

    return {
        load: (productId: string) =>
            loadList({ url: `${BASE_URI}/products/${productId}` }),
        error,
        loading,
        list,
    };
};
