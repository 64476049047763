import { useRecoilState } from "recoil";
import { NOTIFICATIONS } from "@constants";
import { notificationsState } from "@atoms";
import { useAxios } from "./useAxios";
import type { Api, NotificationSeverity } from "@types";
import { isSuccessfulCall } from "@utils";

const BASE_URI = `/v0`;

/**
 * fetch admin products list
 */
export const useAllProducts = () => {
    const { response, loading, error, fetch }: Api = useAxios();
    return {
        getProducts: (query?: string) =>
            fetch(
                {
                    method: "GET",
                    url: query
                        ? `${BASE_URI}/maintenance/product${query}`
                        : `${BASE_URI}/maintenance/product`,
                },
                { errorHandler: "Failed to fetch products" },
            ),
        error,
        loading,
        response,
    };
};

/**
 * Outcome
 */
export const useProductConditionType = () => {
    /**
     * update notifications atom with success messages
     */
    const [_, setNotifications] = useRecoilState(notificationsState);

    const url = `${BASE_URI}/maintenance/productConditionType`;

    const {
        response: list,
        loading: listLoading,
        fetch: loadList,
        error: listError,
    }: Api = useAxios(
        {
            method: "GET",
            url,
        },
        { errorHandler: `Failed to fetch product conditions` },
    );

    const {
        loading: creating,
        error: createError,
        fetch: create,
    }: Api = useAxios(
        {
            method: "POST",
            url: `${BASE_URI}/maintenance/productCondition`,
        },
        { errorHandler: `Failed to update product conditions` },
    );

    return {
        list,
        loading: { listLoading, creating },
        error: { listError, createError },
        reload: () => loadList(),
        upsert: (data: any, method?: string) =>
            create({
                data,
                method: method ?? "POST",
            }).then(res => {
                if (isSuccessfulCall(res?.status)) {
                    setNotifications({
                        severity: NOTIFICATIONS.SEVERITY
                            .SUCCESS as NotificationSeverity,
                        message: `Product condition was successfully saved`,
                        autoHide: true,
                    });
                }
                return res;
            }),
    };
};
