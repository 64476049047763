//Lib
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
    Checkbox,
    InputAdornment,
    ListItemText,
    MenuItem,
    Select,
    Typography,
    CircularProgress,
} from "@mui/material";
import React from "react";

// Utils
import { isArrayWithContent } from "@utils";

// Style
import * as style from "../style.module.scss";

/**
 * Props type
 */
interface Props {
    id: string;
    renderValue: string;
    loading: boolean;
    handleFilterChange: any;
    isItemChecked: (
        filter: string,
        value: Record<string, string>,
        key: string,
    ) => Record<string, string>;
    labelKey: string;
    valueKey: string;
    data: Array<any>;
    className: string;
    filterName: string;
    disableItemSelection?: boolean;
}

/**s
 * Select Filter
 */
const SelectFilter: React.FC<Props> = ({
    id,
    renderValue,
    loading,
    handleFilterChange,
    isItemChecked,
    labelKey,
    valueKey,
    data,
    className,
    filterName,
    disableItemSelection,
}) => {
    return (
        <Select
            id={`${id}-filter`}
            value={[]}
            multiple
            className={className}
            size="small"
            displayEmpty
            renderValue={() => renderValue}
            disabled={loading}
            IconComponent={props =>
                loading ? (
                    <InputAdornment position="start">
                        <CircularProgress color="inherit" size={20} />
                    </InputAdornment>
                ) : (
                    <KeyboardArrowDownIcon
                        {...props}
                        color="primary"
                        fontSize="small"
                    />
                )
            }
            onChange={event => {
                handleFilterChange(filterName, event.target.value, valueKey);
            }}
            MenuProps={{
                classes: {
                    paper: style.scrollMenu,
                },
            }}
        >
            {isArrayWithContent(data) ? (
                data?.map((item, index) => (
                    <MenuItem
                        id={`${id}-filter-item-${item[valueKey]}`}
                        key={`${id}-filter-item-key-${index}`}
                        value={item}
                        className={style.listItem}
                        disabled={disableItemSelection}
                    >
                        <Checkbox
                            checked={
                                !!isItemChecked(filterName, item, valueKey)
                            }
                            size="small"
                        />

                        <ListItemText>
                            <Typography
                                variant="caption1"
                                className={style.label}
                            >
                                {item[labelKey]}
                            </Typography>
                        </ListItemText>
                    </MenuItem>
                ))
            ) : (
                <MenuItem value={undefined} className={style.listItem}>
                    <ListItemText>
                        <Typography variant="caption1" className={style.label}>
                            No data
                        </Typography>
                    </ListItemText>
                </MenuItem>
            )}
        </Select>
    );
};

export default React.memo(SelectFilter);
