// lib
import {
    Button,
    CircularProgress,
    Grid,
    InputLabel,
    TextField,
    Typography,
} from "@mui/material";
import { NumericFormat } from "react-number-format";
import { useFormik, FormikProps } from "formik";
import React, { useMemo } from "react";

// Utils
import { isArrayWithContent } from "@utils";

// Own components
import { Modal } from "@components";

// Types
import type { BillingPlanValues } from "@types";

// schema
import { billingPlanSchema } from "@schemas";

/**
 * Viewable Fields Props type
 */

interface ViewableFields {
    id: string;
    inputLabel: string;
    required: boolean;
    type?: string;
}

/**
 * Props type
 */
interface Props {
    fields: Array<ViewableFields>;
    id: string;
    initialValues: any;
    open: boolean;
    loading: boolean;
    onSubmit: (values: BillingPlanValues, isEditMode: boolean) => void;
    onClose: () => void;
}

/**
 *  Billing Plan form
 */
const BillingPlanForm: React.FC<Props> = ({
    id,
    open,
    loading,
    fields,
    onSubmit,
    initialValues,
    onClose,
}) => {
    const formik: FormikProps<BillingPlanValues> = useFormik<BillingPlanValues>(
        {
            initialValues: {
                ...initialValues,
            },
            validationSchema: billingPlanSchema,
            enableReinitialize: true,
            validateOnMount: true,
            onSubmit: () => undefined,
        },
    );

    /**
     * Check if it's edit mode
     */

    const isEditMode = useMemo(() => {
        if (!formik.values) return false;
        return !!formik?.values?.billingPlanId;
    }, [formik?.values]);

    /**
     * Check form validity
     */
    const isValidForm =
        (!!formik &&
            !!formik.isValid &&
            Object.keys(formik.errors).length === 0) ||
        (!isEditMode &&
            Object.keys(formik.errors).length === 0 &&
            Object.keys(formik.touched).length > 0);

    /**
     * Render
     */
    return (
        <Modal
            open={open}
            onClose={onClose}
            id={`${id}-"modal-billing-plan"`}
            title={!isEditMode ? "Add new billing plan" : "Edit billing plan"}
        >
            <form
                noValidate
                onBlur={formik.handleBlur}
                id={`${id}-billing-plan-form`}
            >
                <Grid container spacing={2}>
                    {isArrayWithContent(fields) &&
                        fields?.map((field, index) => (
                            <Grid item xs={4} key={`${field.id}-${index}`}>
                                <InputLabel
                                    error={
                                        formik?.errors[field.id] &&
                                        formik?.touched[field.id]
                                    }
                                    shrink
                                >
                                    {field.inputLabel}{" "}
                                    {!field.required ? "" : " (*)"}
                                </InputLabel>
                                {field?.type &&
                                (field.type === "decimal" ||
                                    field.type === "integer") ? (
                                    <NumericFormat
                                        id={`${index}-${field.type}`}
                                        name={field.id}
                                        disabled={loading}
                                        value={
                                            field.type === "integer"
                                                ? parseInt(
                                                      formik?.values[field.id],
                                                  )
                                                : formik?.values[field.id]
                                        }
                                        autoComplete="off"
                                        fullWidth
                                        error={
                                            formik?.errors[field.id] &&
                                            formik?.touched[field.id]
                                        }
                                        thousandSeparator="."
                                        decimalSeparator=","
                                        onKeyDown={e => {
                                            // Prevent the user from typing . or , in days field
                                            if (field?.type === "integer") {
                                                [".", ","].includes(e.key) &&
                                                    e.preventDefault();
                                            }
                                        }}
                                        customInput={TextField}
                                        allowNegative={false}
                                        size="small"
                                        isAllowed={values => {
                                            const { floatValue } = values;
                                            return !!floatValue &&
                                                field.id === "percentage"
                                                ? floatValue <= 100
                                                : true;
                                        }}
                                        onValueChange={({ floatValue }) => {
                                            formik.setFieldValue(
                                                field.id,
                                                typeof floatValue !==
                                                    "number" && !floatValue
                                                    ? null
                                                    : floatValue,
                                            );
                                        }}
                                    />
                                ) : (
                                    <TextField
                                        value={formik?.values[field.id] || ""}
                                        fullWidth
                                        name={field.id}
                                        autoComplete="off"
                                        error={
                                            formik?.errors[field.id] &&
                                            formik?.touched[field.id]
                                        }
                                        disabled={loading}
                                        variant="outlined"
                                        size="small"
                                        onChange={formik.handleChange}
                                    />
                                )}
                            </Grid>
                        ))}
                    <Grid
                        item
                        xs={12}
                        container
                        justifyContent="flex-end"
                        mt={4}
                    >
                        <Button
                            size="large"
                            variant="outlined"
                            onClick={onClose}
                            sx={{ marginRight: "1rem" }}
                        >
                            <Typography variant="button">Cancel</Typography>
                        </Button>
                        <Button
                            size="large"
                            variant="contained"
                            disabled={loading || !isValidForm}
                            onClick={() => onSubmit(formik.values, isEditMode)}
                        >
                            {loading ? (
                                <CircularProgress size={25} color="inherit" />
                            ) : isEditMode ? (
                                <Typography variant="button">Save</Typography>
                            ) : (
                                <Typography variant="button">Submit</Typography>
                            )}
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </Modal>
    );
};
export default BillingPlanForm;
