// lib
import { Grid } from "@mui/material";
import React from "react";

interface Props {
    children: React.ReactNode;
    className?: string;
}

/**
 * FlowContent
 */
const FlowContent = ({ children, className }: Props) => {
    /**
     * Render
     */
    return (
        <Grid item xs={12} height="100%" className={className}>
            {children}
        </Grid>
    );
};
export default React.memo(FlowContent);
