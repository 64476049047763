import React, { Fragment, useState, useEffect } from "react";
import { useIdleTimer } from "react-idle-timer";
import { navigate } from "gatsby";

import { pathname } from "@utils";

// Own components
import { Modal } from "@components";
/**
 * Props type
 */
interface Props {
    itemRef: React.RefObject<HTMLDivElement>;
}

const SystemTimer: React.FC<Props> = ({ itemRef }) => {
    useEffect(() => {
        if (itemRef.current) {
            itemRef.current.focus();
        }
    }, [itemRef]);

    // 300000 milliseconds = 5 minutes
    const IDLE_WARNING_TIMEOUT_OFFSET = 300000;
    // 900000 milliseconds = 15 minutes
    const IDLE_TIMEOUT = 900000;
    const [remainingTime, setRemainingTime] = useState(IDLE_TIMEOUT);
    const [showIdleWarning, setShowIdleWarning] = useState(false);

    const { getRemainingTime, start } = useIdleTimer({
        onIdle: () => {
            setShowIdleWarning(false);
            navigate("/inactive");
        },
        onAction: () => {
            if (showIdleWarning) {
                setShowIdleWarning(false);
                setTimeout(() => {
                    setRemainingTime(IDLE_TIMEOUT);
                }, 1000);
            }
        },
        timeout: IDLE_TIMEOUT,
        crossTab: true,
        syncTimers: 500,
        eventsThrottle: 1000,
        events: ["mousedown", "touchstart", "MSPointerDown"],
        disabled:
            pathname?.includes("logout") || pathname?.includes("inactive"),
        stopOnIdle: true,
    });

    useEffect(() => {
        if (!pathname?.includes("logout") && !pathname?.includes("inactive")) {
            // eslint-disable-next-line smells/no-setinterval
            const interval = setInterval(() => {
                setRemainingTime(getRemainingTime());
                if (getRemainingTime() === 0) {
                    start();
                }
                setShowIdleWarning(
                    getRemainingTime() <= IDLE_WARNING_TIMEOUT_OFFSET,
                );
            }, 1000);

            return () => {
                clearInterval(interval);
            };
        }
    });

    return (
        <Fragment>
            <Modal
                open={showIdleWarning}
                onClose={() => null}
                id="inactive"
                title="Session Timeout"
                smallView
            >
                You will be logged out due to inactivity
                <br />
                in{" "}
                {remainingTime < 60000
                    ? `${Math.floor(remainingTime / 1000)} seconds`
                    : `less than ${Math.ceil(
                          Math.ceil(remainingTime / 60 / 1000),
                      )} minutes`}
                .
            </Modal>
        </Fragment>
    );
};

export default SystemTimer;
