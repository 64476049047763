// extracted by mini-css-extract-plugin
export var body = "style-module--body--18eb0";
export var card = "style-module--card--83fd3";
export var description = "style-module--description--63952";
export var error = "#ff3131";
export var footerAction = "style-module--footerAction--c401e";
export var headerAction = "style-module--headerAction--096e3";
export var label = "style-module--label--76d73";
export var list = "style-module--list--151ea";
export var primary = "#036";
export var secondary = "#019cdc";
export var textfont = "\"Verdana Pro\"";
export var titlefont = "\"Karbon\"";
export var white = "#fff";