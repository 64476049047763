// Libs
import { Box, TableCell, TableRow, Typography, Tooltip } from "@mui/material";
import React from "react";

// Own components
import { ActionMenu } from "@components";

// Types
type Row = {
    firstName: string;
    lastName: string;
    email: string;
    objectId: string;
    wwid: string;
};

interface Props {
    row: Row;
    rowIndex: number;
    id: string;
    callbacks: any;
    disabled?: boolean;
}

const MaintenanceUsersRow = ({
    id,
    row,
    rowIndex,
    callbacks,

    disabled,
}: Props) => {
    return (
        <TableRow>
            <TableCell>
                <Typography variant="caption2">{row.firstName}</Typography>
            </TableCell>

            <TableCell>
                <Tooltip arrow placement="top" title={row.lastName}>
                    <Typography variant="caption2">{row.lastName}</Typography>
                </Tooltip>
            </TableCell>

            <TableCell>
                <Typography variant="caption2">{row.email}</Typography>
            </TableCell>
            <TableCell>
                <Typography variant="caption2">{row.wwid}</Typography>
            </TableCell>

            <TableCell>
                <Typography variant="caption2">{row.objectId}</Typography>
            </TableCell>

            <TableCell align="right">
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                >
                    <ActionMenu
                        id={`${id}-${rowIndex}-milestone-row"`}
                        autoHide
                        autoWidth
                        options={[
                            {
                                label: "Edit user",
                                action: () =>
                                    callbacks &&
                                    callbacks?.onUserEdit(rowIndex),
                                disabled,
                            },
                        ]}
                        rowIndex={rowIndex}
                    />
                </Box>
            </TableCell>
        </TableRow>
    );
};

export default MaintenanceUsersRow;
