// Icons
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";

// Lib
import { Grid, InputLabel } from "@mui/material";

import React, { useEffect, useState } from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

// Own components
import { Modal, FieldRenderer } from "@components";

// Utils
import { convertDateForAPI, datePlaceholder, displayDate } from "@utils";

type Props = {
    id: string;
    open: boolean;
    onClose: () => void;
    title: string;
    onSubmit: (infusionDate: string) => void;
    loading: boolean;
    disabled: boolean;
    salessystemOrderId: string;
    orderInfusionDate?: string;
};

/**
 * Change infusion date
 */
const ChangeInfusionDate = ({
    id,
    open,
    onClose,
    title,
    onSubmit,
    loading,
    disabled,
    salessystemOrderId,
    orderInfusionDate,
}: Props) => {
    const [infusionDate, setInfusionDate] = useState<string>("");
    const [touched, setTouched] = useState(false);
    const [errors, setErrors] = useState(false);

    /**
     * Reset infusion date states
     */
    const resetInfusionDateStates = () => {
        setErrors(false);
        setTouched(false);
        setInfusionDate("");
    };

    useEffect(() => {
        if (!open) {
            resetInfusionDateStates();
        }
    }, [open]);

    /**
     * Render
     */
    return (
        <Modal
            id={`${id}-modal`}
            open={open}
            smallView
            onClose={onClose}
            title={title}
            primaryButton={{
                action: () => onSubmit(infusionDate),
                text: "Submit",
                loading: loading,
                disabled: disabled || !infusionDate || errors,
                id: `${id}-primary-btn`,
            }}
            secondaryButton={{
                action: () => {
                    onClose();
                },
                text: "Cancel",
                id: `${id}-secondary-btn`,
            }}
        >
            <Grid container item xs={12} spacing={3}>
                <Grid item xs={6}>
                    <FieldRenderer
                        id={`cequence_id`}
                        label="Cquence id"
                        value={salessystemOrderId}
                    />
                </Grid>
                {orderInfusionDate && (
                    <Grid item xs={6}>
                        <FieldRenderer
                            id={`old-infusion-date`}
                            label="Old infusion date"
                            value={displayDate(orderInfusionDate)}
                        />
                    </Grid>
                )}
                <Grid item xs={6}>
                    <InputLabel
                        id={`infusion-date-label`}
                        shrink
                        error={errors && touched}
                    >
                        {"New infusion date (*)"}
                    </InputLabel>

                    <DatePicker
                        value={datePlaceholder(infusionDate)}
                        onChange={value => {
                            if (!value || !value?.isValid) {
                                setInfusionDate("");
                                !errors && setErrors(true);
                            }

                            if (value) {
                                setInfusionDate(convertDateForAPI(value));
                                !touched && setTouched(true);
                            }
                            if (value?.isValid && !!errors) {
                                setErrors(false);
                            }
                        }}
                        format="dd/MM/yyyy"
                        slotProps={{
                            textField: {
                                variant: "outlined",
                                autoComplete: "off",
                                sx: {
                                    svg: {
                                        color: "#036",
                                        opacity: "0.5",
                                    },
                                },
                                size: "small",
                                fullWidth: true,
                                placeholder: "dd/mm/yyyy",
                                required: true,
                                error: errors && touched,
                                id: `infusion-date-input`,
                                name: "infusion-date-input",
                            },
                        }}
                        slots={{
                            openPickerIcon: CalendarTodayIcon,
                        }}
                    />
                </Grid>
            </Grid>
        </Modal>
    );
};

export default ChangeInfusionDate;
