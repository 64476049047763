import { DateTime } from "luxon";
import * as yup from "yup";

/**
 * CONSTANTS
 */

export const PARTNER = {
    accountAddress: yup.string().nullable(),
    accountCity: yup.string().nullable(),
    accountStatus: yup.string().required(),
    accountId: yup.string().required(),
    accountName: yup.string().nullable(),
    accountPostalCode: yup.string().nullable(),
    accountTypeId: yup.string().nullable(),
    countryIsoCode: yup.string().nullable(),
    homeCountry: yup.string().nullable(),
    knowyoursupplierid: yup.string().nullable(),
    sapAccountCode: yup.string().nullable(),
    codsId: yup.string().nullable(),
};

/**
 * SCHEMAS
 */

/**
 * General Information Schema
 */
export const performanceGeneralInformationSchema = yup.object().shape({
    reference: yup.string().required(),
    externalReference: yup.string().nullable(),
    icdNo: yup.string().nullable(),
    icdUrl: yup
        .string()
        .url(
            "Invalid URL: Please enter a valid URL that begins with 'https://'",
        )
        .test("is-https", "URL must start with https", value => {
            // Custom validation function to check if the URL starts with https
            if (value) {
                return value.startsWith("https://");
            }
            return true; // Return true for empty values or non-string values
        })
        .nullable(),
    responsible: yup.string().nullable(),
    country: yup.object().shape({
        name: yup.string().required(),
        isoCode: yup.string().required(),
    }),
    startDate: yup.date().typeError("please enter a valid date").required(),
    endDate: yup
        .date()
        .typeError("please enter a valid date")
        .when("startDate", ([startDate], schema) => {
            return yup.date().isValidSync(startDate) && !!startDate
                ? schema.min(
                      DateTime.fromJSDate(startDate).plus({ days: 1 }),
                      "End date must be later than Start date",
                  )
                : schema;
        }),
    brand: yup.object().shape({
        brandId: yup.string().required(),
        brandName: yup.string().required(),
    }),
    therapeuticArea: yup.object().shape({
        therapeuticAreaId: yup.string().required(),
        therapeuticAreaName: yup.string().required(),
    }),
    indication: yup.object().shape({
        indicationId: yup.string().nullable(),
        indicationName: yup.string().nullable(),
    }),
    primaryPartner: yup.object().shape({
        accountAddress: yup.string().nullable(),
        accountCity: yup.string().nullable(),
        accountId: yup.string().required(),
        accountName: yup.string().required(),
        accountPostalCode: yup.string().nullable(),
        accountTypeId: yup.string().nullable(),
        accountStatus: yup.string().required(),
        countryIsoCode: yup.string().nullable(),
        homeCountry: yup.string().nullable(),
        knowyoursupplierid: yup.string().nullable(),
        sapAccountCode: yup.string().nullable(),
        codsId: yup.string().nullable(),
    }),
});

export const volumeGeneralInformationSchema = yup.object().shape({
    reference: yup.string().required(),
    externalReference: yup.string().nullable(),
    icdNo: yup.string().nullable(),
    icdUrl: yup
        .string()
        .url(
            "Invalid URL: Please enter a valid URL that begins with 'https://'",
        )
        .test("is-https", "URL must start with https", value => {
            // Custom validation function to check if the URL starts with https
            if (value) {
                return value.startsWith("https://");
            }
            return true; // Return true for empty values or non-string values
        })
        .nullable(),
    responsible: yup.string().nullable(),
    country: yup.object().shape({
        name: yup.string().required(),
        isoCode: yup.string().required(),
    }),
    startDate: yup.date().typeError("please enter a valid date").required(),
    endDate: yup
        .date()
        .typeError("please enter a valid date")
        .when("startDate", ([startDate], schema) => {
            return yup.date().isValidSync(startDate) && !!startDate
                ? schema.min(
                      DateTime.fromJSDate(startDate).plus({ days: 1 }),
                      "End date must be later than Start date",
                  )
                : schema;
        }),

    therapeuticArea: yup.object().shape({
        therapeuticAreaId: yup.string().required(),
        therapeuticAreaName: yup.string().required(),
    }),
    primaryPartner: yup.object().shape({
        accountAddress: yup.string().nullable(),
        accountCity: yup.string().nullable(),
        accountId: yup.string().required(),
        accountName: yup.string().required(),
        accountPostalCode: yup.string().nullable(),
        accountTypeId: yup.string().nullable(),
        accountStatus: yup.string().required(),
        countryIsoCode: yup.string().nullable(),
        homeCountry: yup.string().nullable(),
        knowyoursupplierid: yup.string().nullable(),
        sapAccountCode: yup.string().nullable(),
        codsId: yup.string().nullable(),
    }),
    claimPeriodicity: yup.object().shape({
        id: yup.string(),
        label: yup.string(),
    }),
});

/**
 * Product details
 * Volume based  & performance based
 */

export const volumeBasedProductDetailsSchema = yup.object().shape({
    products: yup
        .array()
        .of(
            yup.object().shape(
                {
                    product: yup.object().required().shape({
                        productId: yup.string().required(),
                        productName: yup.string().required(), // we don't need to send it with the request body but we need to show the name on the Milestones page
                        sapSkuNo: yup.string().required(),
                    }),

                    priceCondition: yup
                        .object()
                        .when("priceSource", ([priceSource]) => {
                            return priceSource
                                ? yup
                                      .object()
                                      .shape({
                                          priceCondition: yup.string(),
                                          currency: yup.string(),
                                          price: yup.string(),
                                      })
                                      .nullable()
                                : yup.object().required().shape({
                                      priceCondition: yup.string().required(),
                                      currency: yup.string(),
                                      price: yup.string(),
                                  });
                        }),
                    priceSource: yup
                        .string()
                        .when("priceCondition", ([priceCondition]) => {
                            return !!priceCondition &&
                                priceCondition?.priceCondition
                                ? yup.string()
                                : yup.string().required();
                        }),

                    type: yup
                        .string()
                        .when(["priceSource"], ([priceSource]) => {
                            if (priceSource === "iTENDER") {
                                return yup.string().nullable();
                            }
                            return yup.string().required();
                        }),
                    scale: yup.array().when("type", ([type]) => {
                        return type === "scale"
                            ? yup
                                  .array()
                                  .of(
                                      yup.object().shape({
                                          price: yup.string(),
                                          discountPercentage: yup.string(),
                                          unitOfMeasureFrom: yup
                                              .string()
                                              .required(),
                                          unitOfMeasureTo: yup
                                              .string()
                                              .required(),
                                          unitOfMeasure: yup
                                              .string()
                                              .required(),
                                          periodFrom: yup
                                              .date()
                                              .typeError(
                                                  "please enter a valid date",
                                              )
                                              .required(),
                                          periodTo: yup
                                              .date()
                                              .typeError(
                                                  "please enter a valid date",
                                              )
                                              .required()
                                              .when(
                                                  "periodFrom",
                                                  ([periodFrom], schema) => {
                                                      const limiter =
                                                          DateTime.fromJSDate(
                                                              periodFrom,
                                                          ).plus({ days: 1 });
                                                      return periodFrom &&
                                                          yup
                                                              .date()
                                                              .isValidSync(
                                                                  periodFrom,
                                                              )
                                                          ? schema.min(
                                                                limiter,
                                                                "End date must be later than period from date",
                                                            )
                                                          : schema;
                                                  },
                                              ),
                                          cumulativePeriodLength: yup
                                              .string()
                                              .required(),
                                          cumulativePeriodDuration: yup
                                              .string()
                                              .required(),
                                          cumulativePeriods: yup
                                              .array()
                                              .of(
                                                  yup.object().shape({
                                                      cumulativePeriodFrom: yup
                                                          .string()
                                                          .required(),
                                                      cumulativePeriodTo: yup
                                                          .string()
                                                          .required(),
                                                  }),
                                              )
                                              .required(),
                                      }),
                                  )
                                  .min(1)
                            : yup.array().nullable();
                    }),
                },
                [["priceCondition", "priceSource"]],
            ),
        )
        .min(1)
        .required(),
});

export const performanceBasedProductDetailsSchema = yup.object().shape({
    products: yup
        .array()
        .of(
            yup.object().shape({
                product: yup.object().required().shape({
                    productId: yup.string().required(),
                    productName: yup.string().required(), // we don't need to send it with the request body but we need to show the name on the Milestones page
                    sapSkuNo: yup.string().required(),
                }),
                priceCondition: yup.object().required().shape({
                    priceCondition: yup.string().required(),
                    currency: yup.string(),
                    price: yup.string(),
                }),
            }),
        )
        .min(1)
        .required(),
});

const validateCondition = yup
    .object()
    .shape({
        amount: yup
            .number()
            .when("selectedCondition", ([selectedCondition]: string[]) => {
                if (selectedCondition === "rebatePercentage") {
                    return yup.number().min(0.1).max(100).required();
                } else return yup.number().required();
            }),
        selectedCondition: yup.string().required(),
    })
    .required();

/**
 * Financial Conditions
 */
export const financialConditionsSchema = yup.object().shape({
    conditions: yup.lazy(obj =>
        yup.object(
            Object.keys(obj).reduce((acc, key) => {
                acc[key] = validateCondition;
                return acc;
            }, {}),
        ),
    ),
});

/**
 * Contract Milestones
 */
export const contractMilestonesSchema = yup.object().shape({
    milestones: yup
        .array()
        .of(
            yup.object().shape({
                description: yup.string().required(),
                number: yup.string().required(),
            }),
        )
        .min(1)
        .required(),
});

/**
 * Contract Partners
 */

export const contractPartnersSchema = yup
    .array()
    .required()
    .when(
        "$shouldAddAdditionalPartners",
        ([shouldAddAdditionalPartners], schema) => {
            return shouldAddAdditionalPartners
                ? schema
                      .min(1)
                      .max(1)
                      .of(yup.object().shape(PARTNER).required())
                : schema.of(yup.object().shape(PARTNER).nullable()).nullable();
        },
    );
