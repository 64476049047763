import { selector } from "recoil";

import {
    contractMilestonesState,
    createPerformanceBasedContractStepsState,
    createVolumeBasedContractStepsState,
    generalInformationState,
    productsDetailsState,
    contractReviewState,
    financialConditionsState,
    contractPartnersState,
    selectModelState,
    selectCategoriesState,
} from "@atoms";
import { isArrayWithContent } from "@utils";

type Conditions = {
    [key: string]: {
        amount: string;
        selectedCondition: string;
    };
};

const mapContractForApi = ({
    generalInformation,
    productsDetails,
    contractMilestones,
    contractReview,
    financialConditions,
    contractPartners,
    contractModel,
    contractCategories,
}: any) => {
    const isVolumeBased = !!isArrayWithContent(contractCategories);

    const mappedFinancialConditions = () => {
        if (
            isVolumeBased ||
            Object.keys(financialConditions?.conditions as Conditions)
                .length === 0
        )
            return;

        const selectedCondition = Object.values(
            financialConditions?.conditions as Conditions,
        )[0]?.selectedCondition;
        const amount = Object.values(
            financialConditions?.conditions as Conditions,
        )[0]?.amount;

        return {
            [Object.keys(financialConditions?.conditions as Conditions)[0]]: {
                [selectedCondition]: amount,
            },
        };
    };

    const mapProducts = () => {
        if (!isArrayWithContent(productsDetails?.products)) return [];

        return productsDetails.products.map(item => {
            return {
                datamodelId: contractModel?.datamodelId,
                productId: item?.product?.productId,
                priceSource: item?.priceSource || undefined,
                priceCondition:
                    item?.priceCondition?.priceCondition || undefined,
                milestones: !isVolumeBased
                    ? contractMilestones?.milestones
                    : undefined,
                financialConditions: mappedFinancialConditions(),
                scales: item?.type === "scale" ? item?.scales : undefined,
                discountPercentage:
                    item?.type === "discountPercentage"
                        ? item?.amount
                        : undefined,
                contractPrice:
                    item?.type === "contractPrice" ? item?.amount : undefined,
            };
        });
    };
    const mapPartners = () => {
        if (!isArrayWithContent(contractPartners)) return [];
        return contractPartners.map(partner => partner.id);
    };

    return Object.assign(
        {},
        {
            contractType: isVolumeBased ? "VOLUME" : "PERFORMANCE",
            categories: contractCategories,
            datamodelId: contractModel?.datamodelId,
            country: generalInformation?.country?.isoCode,
            startDate: generalInformation?.startDate,
            endDate: generalInformation?.endDate,
            brandId: isVolumeBased
                ? undefined
                : generalInformation?.brand?.brandId,
            reference: generalInformation?.reference,
            externalReference: generalInformation?.externalReference,
            primaryPartnerId: generalInformation?.primaryPartner?.accountId,
            icdNo: generalInformation?.icdNo,
            responsible: generalInformation?.responsible,
            therapeuticAreaId:
                generalInformation?.therapeuticArea?.therapeuticAreaId,
            indicationId: isVolumeBased
                ? undefined
                : generalInformation?.indication?.indicationId,
            additionalPartners: mapPartners(),
            products: mapProducts(),
            comments: contractReview?.comments,
            icdUrl: generalInformation?.icdUrl,
            claimPeriodicity: isVolumeBased
                ? generalInformation?.claimPeriodicity?.id
                : undefined,
        },
    );
};

/**
 * Submit create contract
 */
export const createContractMapper = selector({
    key: "createContractMapper",
    get: ({ get }) => {
        const contractModel = get(selectModelState);
        const contractCategories = get(selectCategoriesState);
        const generalInformation = get(generalInformationState);
        const productsDetails = get(productsDetailsState);
        const contractMilestones = get(contractMilestonesState);
        const contractReview = get(contractReviewState);
        const financialConditions = get(financialConditionsState);
        const contractPartners = get(contractPartnersState);

        return mapContractForApi({
            generalInformation,
            productsDetails,
            contractMilestones,
            contractReview,
            financialConditions,
            contractPartners,
            contractModel,
            contractCategories,
        });
    },
    set: ({ reset }) => {
        reset(generalInformationState);
        reset(productsDetailsState);
        reset(contractMilestonesState);
        reset(createPerformanceBasedContractStepsState);
        reset(createVolumeBasedContractStepsState);
        reset(contractReviewState);
        reset(financialConditionsState);
        reset(selectModelState);
        reset(selectCategoriesState);
        reset(contractPartnersState);
    },
});
