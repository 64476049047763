//Lib
import { Box, Typography } from "@mui/material";
import React, {
    useImperativeHandle,
    useState,
    useEffect,
    useCallback,
    useMemo,
} from "react";
import { useFormik } from "formik";
import debounce from "lodash.debounce";
import * as yup from "yup";
import { useRecoilState } from "recoil";
// Atoms
import {
    duplicateContractStepsState,
    editContractStepsState,
    teamState,
} from "@atoms";

//Own components
import { ContractReview, Modal, LoadingWrapper, TeamViewer } from "@components";

// Types
import type {
    Category,
    Contract,
    Location,
    Milestone,
    Permissions,
} from "@types";

// Utils
import {
    isArrayWithContent,
    constructQueryString,
    mappedFinancialConditions as financialConditionsMapper,
} from "@utils";

// hooks
import {
    useUpdateStep,
    useValidateDuplication,
    useContractPartners,
    useUploadDocument,
    usePermission,
    useGetFinancialConditions,
    useAccountTeamUsers,
    useGetContractCategory,
    useContractsProducts,
    useContractProductPrices,
} from "@hooks";

/**
 * Props
 */
interface Props {
    contract?: Contract;
    isDuplicateContract?: boolean;
    isEditContract?: boolean;
    location?: Location;
    id?: string;
    setDisableSaveButton?: (value: boolean) => void;
    isEditingContract?: boolean;
}

/**
 * Function that validate url
 */
const URL_SCHEMA = yup.object().shape({
    icdUrl: yup
        .string()
        .url(
            "Invalid URL: Please enter a valid URL that begins with 'https://'",
        )
        .test("is-https", "URL must start with https", value => {
            // Custom validation function to check if the URL starts with https
            if (value) {
                return value.startsWith("https://");
            }
            return true; // Return true for empty values or non-string values
        })
        .nullable(),
});

// Example usage
const validateURL = async url => {
    try {
        await URL_SCHEMA.validate({ icdUrl: url });
        return true;
    } catch (error) {
        return error;
    }
};

/**
 * Contract view
 */
const ContractView = React.forwardRef(
    (
        {
            location,
            contract,
            isDuplicateContract,
            isEditContract,
            id,
            setDisableSaveButton,
            isEditingContract,
        }: Props,
        ref,
    ) => {
        const [validationMessage, validateUrl] = useState("");

        /**
         * Permissions
         */
        const { hasPermissionToEditOutcome }: Permissions = usePermission();
        // Get team
        const [team] = useRecoilState<any>(teamState);

        /**
         * API
         */
        const {
            getContractsProducts,
            response: productsList,
            loading: loadingProducts,
            error: productsErrors,
        } = useContractsProducts();

        const {
            getPrices,
            loading: pricesLoading,
            response: prices,
        } = useContractProductPrices();

        // state holds the fields that we need to duplicate
        const [fields, setFields] = useState<any>({
            reference: undefined,
            icdNo: undefined,
            primaryPartner: undefined,
            externalReference: undefined,
            responsible: undefined,
            icdUrl: undefined,
        });

        /**
         * Formik state
         */
        const formik: any = useFormik({
            initialValues: {
                reference: "" as string,
                icdNo: "" as string,
                primaryPartner: undefined,
                externalReference: "" as string,
                responsible: "" as string,
                categories: [] as Category[],
                milestones: [] as Milestone[],
                financialConditions: undefined as any,
                products: [] as Contract["products"],
                additionalPartners: [] as Array<{ id: string; name: string }>,
            },
            // validationSchema: contractPartnersSchema,
            enableReinitialize: true,
            validateOnMount: true,
            onSubmit: () => undefined,
        });

        const [showReviewers, toggleModal] = useState(false);

        // Touched state
        const [touched, setTouched] = useState({});

        /**
         * API
         */

        // Check the uniqueness of contract ref
        const {
            validate,
            isUniqueValue,
            isNotUniqueValue,
            isNotUniqueMessage,
            loading: duplicationValidating,
        } = useValidateDuplication("contractRef");

        // Fetch partners
        const {
            getPartners,
            response: partners,
            loading: partnersLoading,
        } = useContractPartners();

        // Fetch evidences
        const {
            loading: {
                fetching: fetchingEvidences,
                downloading: downloadingEvidence,
            },
            list: evidences,
            load: loadEvidences,
            download: downloadEvidence,
        } = useUploadDocument();

        // Fetch financial conditions
        const {
            getFinancialConditions,
            loading,
            response: financialConditionsList,
        } = useGetFinancialConditions();

        // Fetch team users
        const {
            getAccountTeamUsers,
            response: teamUsers,
            loading: fetchingUsers,
        } = useAccountTeamUsers();

        // Get data model categories

        const {
            load: loadCategoryList,
            list: categoryList,
            loading: fetchingCategoryList,
        } = useGetContractCategory();

        /**
         * Hooks
         */

        // Update step hook
        const updateStepValidation = useUpdateStep(
            location,
            isEditContract
                ? editContractStepsState
                : duplicateContractStepsState,
        );

        // Check if the ref is unique
        const contractRefValidation = useMemo(() => {
            return {
                isNotUniqueValue,
                isNotUniqueMessage,
                duplicationValidating,
            };
        }, [isNotUniqueValue, isNotUniqueMessage, duplicationValidating]);

        /**
         * pass the state back to the layout / higher order component
         */
        useImperativeHandle(ref, () => ({
            onSubmit() {
                return isEditContract ? formik.values : fields;
            },
        }));

        /**
         * validate icd url
         */
        useEffect(() => {
            if (!isDuplicateContract || !fields) return;

            if (!fields?.icdUrl) {
                validateUrl("");
                return;
            }

            validateURL(fields?.icdUrl).then(result => {
                if (result instanceof Error) {
                    validateUrl(result?.message);
                    return;
                } else {
                    validateUrl("");
                }
            });
        }, [isDuplicateContract, fields?.icdUrl]);

        /**
         * Check if the form has any error
         */
        const hasError =
            isDuplicateContract &&
            (!isUniqueValue ||
                !fields.reference ||
                !fields.primaryPartner ||
                !validationMessage);

        /**
         * Check & map financial conditions
         */
        const productFinancialConditions: any = useMemo(() => {
            if (
                !isEditContract ||
                !contract?.products ||
                !contract?.products[0]?.financialConditions
            )
                return;
            return contract?.products[0]?.financialConditions;
        }, [contract?.products]);

        /**
         * Check & map milestones
         */
        const productMilestones: any = useMemo(() => {
            if (
                !isEditContract ||
                !contract?.products ||
                !contract?.products[0]?.milestones
            )
                return;
            return contract?.products[0]?.milestones;
        }, [contract?.products]);

        /**
         * Check the step validation
         */
        useEffect(() => {
            if (!isDuplicateContract && !isEditContract) return;
            updateStepValidation(!hasError);
        }, [hasError, location, isDuplicateContract, isEditContract]);

        /**
         * Map financial conditions
         */
        const mappedFinancialConditions = useMemo(() => {
            if (
                !productFinancialConditions ||
                Object.keys(productFinancialConditions).length === 0
            )
                return;
            return financialConditionsMapper(productFinancialConditions);
        }, [productFinancialConditions]);

        /**
         * In case of edit contract
         * map the editable fields to a local states
         */
        useEffect(() => {
            if (!isEditContract || !contract) return;

            const generalInformation = {
                externalReference: contract.externalReference,
                icdNo: contract.icdNo,
                responsible: contract.responsible,
                icdUrl: contract.icdUrl,
            };

            const milestones = (productMilestones as Array<any>)
                ? productMilestones
                : [];

            let financialConditions = {};

            if (mappedFinancialConditions) {
                financialConditions = {
                    [mappedFinancialConditions.key]: {
                        amount: mappedFinancialConditions?.amount,
                        selectedCondition: mappedFinancialConditions?.condition,
                        name: mappedFinancialConditions?.name,
                    },
                };
            }

            // Map financial conditions to match the component interface and add
            // amount: undefined
            // selectedCondition: null

            formik.setValues({
                ...generalInformation,
                milestones,
                financialConditions,
                products: contract?.products,
                additionalPartners: contract?.additionalPartners,
                categories: contract?.categories ? contract.categories : [],
            });
        }, [
            isEditContract,
            contract,
            mappedFinancialConditions,
            productMilestones,
        ]);

        /**
         * Handle blur
         */
        const onBlur = (e?: React.BaseSyntheticEvent, field?: string) => {
            const copyTouched = { ...touched };
            const name = field ? field : e?.target?.name;
            if (copyTouched[name]) return;
            Object.assign(copyTouched, {
                [name]: true,
            });
            setTouched(copyTouched);
        };

        /**
         * Validation debouncer
         */
        const debounceLoadData = useCallback(
            debounce(contractRef => validate(undefined, { contractRef }), 600),
            [],
        );

        /**
         * Edit contract section
         */
        const onEditContractItem = (values: any) => {
            const updateFields = { ...formik.values, ...values };
            formik.setValues(updateFields);
        };

        /**
         * Change handler
         */
        const onFieldChange = (key: string, value: string) => {
            const copyFields = { ...fields };
            copyFields[key] = value;
            setFields(copyFields);
        };

        /**
         * Fetch users
         */
        useEffect(() => {
            if (!showReviewers) return;

            getAccountTeamUsers(team.teamId);
        }, [showReviewers]);

        /**
         * Check the uniqueness of reference
         */
        useEffect(() => {
            if (!fields.reference) {
                return;
            }
            debounceLoadData(fields.reference);
        }, [fields.reference]);

        /**
         * Load evidences
         */
        useEffect(() => {
            if (!contract?.id) return;
            loadEvidences(contract.id);
        }, [contract?.id]);

        /**
         * Load product list on edit contract
         */
        const loadProductsList = (brandId: string) => {
            if (!isEditContract || !contract) return;

            // In order to fetch pzn nr. we have to query productContextId
            const queryContextId = {
                contextId: contract?.datamodel?.productContextId,
                datamodelId: contract?.datamodel?.id,
                brandId,
            };

            const query = constructQueryString(
                {
                    countryIsoCode: contract?.country?.code,
                    ...queryContextId,
                },
                true,
            );

            return getContractsProducts(query);
        };

        /**
         * Load product prices
         */
        const loadProductsPrices = (
            sapSkuNo: string,
            pricesFrom: string,
            pricesTo: string,
        ) => {
            if (!isEditContract || !sapSkuNo || !pricesFrom || !pricesTo)
                return;

            const query = constructQueryString(
                {
                    sapSkuNo,
                    pricesFrom,
                    pricesTo,
                },
                true,
            );

            getPrices(query);
        };

        return (
            <>
                <Modal
                    title={"Contract reviewers"}
                    id={`${id}-contract-reviewers`}
                    open={showReviewers}
                    onClose={() => toggleModal(false)}
                    smallView
                >
                    <LoadingWrapper
                        id={`${id}-contract-reviewers-loading`}
                        loading={fetchingUsers}
                        customMsg={
                            isArrayWithContent(teamUsers?.data?.records)
                                ? undefined
                                : "No users found!"
                        }
                    >
                        <TeamViewer
                            id={`${id}-view-team-members`}
                            showFullName
                            team={teamUsers?.data?.records}
                        />
                    </LoadingWrapper>
                </Modal>

                <Box mb={5}>
                    <Typography variant="h2">Contract details</Typography>
                </Box>

                <ContractReview
                    id={`${id}-contract-review`}
                    contractType={contract?.type}
                    fields={fields}
                    touched={touched}
                    partners={{
                        data: partners?.data?.partners,
                        loading: partnersLoading,
                        onSearch: (query: string) => getPartners(query),
                    }}
                    modelCategories={
                        contract?.datamodel?.id
                            ? {
                                  load: () =>
                                      loadCategoryList(
                                          contract.datamodel.id as string,
                                      ),
                                  loading: fetchingCategoryList,
                                  list: categoryList?.data?.categories,
                              }
                            : undefined
                    }
                    model={contract?.datamodel}
                    currency={contract?.datamodel?.currency || ""}
                    categories={
                        isEditContract
                            ? formik?.values?.categories
                            : contract?.categories
                    }
                    isDuplicateContract={isDuplicateContract}
                    isEditContract={isEditContract}
                    contractRefValidation={contractRefValidation}
                    //hideEdit={true}
                    disableEdit={isEditingContract}
                    isApheresisBasedContract={
                        mappedFinancialConditions?.isApheresisBased
                    }
                    generalInformation={{
                        reference: contract?.reference,
                        country: contract?.country,
                        startDate: contract?.startDate,
                        endDate: contract?.endDate,
                        therapeuticArea: contract?.therapeuticArea,
                        indication: contract?.indication,
                        brand: contract?.brand,
                        primaryContractPartner:
                            contract?.primaryContractPartner,
                        externalReference: isEditContract
                            ? formik.values?.externalReference
                            : contract?.externalReference,
                        responsible: isEditContract
                            ? formik.values?.responsible
                            : contract?.responsible,
                        icdNo: isEditContract
                            ? formik.values?.icdNo
                            : contract?.icdNo,
                        icdUrl: isEditContract
                            ? formik.values?.icdUrl
                            : contract?.icdUrl,
                        isValidIcdUrl: validationMessage,
                        claimPeriodicity:
                            contract?.type === "VOLUME"
                                ? contract?.claimPeriodicity
                                : undefined,
                    }}
                    additionalPartners={
                        isEditContract
                            ? formik?.values?.additionalPartners
                            : contract?.additionalPartners
                    }
                    productsDetails={
                        isEditContract
                            ? formik?.values?.products
                            : contract?.products
                    }
                    financialConditions={
                        isEditContract
                            ? formik?.values?.financialConditions
                            : !!contract?.products &&
                              contract?.products[0]?.financialConditions
                    }
                    contractMilestones={
                        isEditContract
                            ? formik?.values?.milestones
                            : !!contract?.products &&
                              contract?.products[0]?.milestones
                    }
                    comments={contract?.comments || ""}
                    reasonRejection={contract?.reasonRejection}
                    callbacks={{
                        onFieldChange,
                        onBlur,
                        loadReviewers: isDuplicateContract
                            ? () => toggleModal(true)
                            : undefined,
                        onEditContractItem,
                        setDisableSaveButton,
                    }}
                    evidences={{
                        downloadEvidence: hasPermissionToEditOutcome
                            ? (evidenceId: string) =>
                                  downloadEvidence(contract?.id, evidenceId)
                            : undefined,
                        loading:
                            fetchingEvidences || downloadingEvidence || false,
                        data: evidences?.data?.records,
                    }}
                    financialConditionsList={{
                        load: getFinancialConditions,
                        loading,
                        data: financialConditionsList?.data?.records,
                    }}
                    productsList={{
                        load: (brandId: string) => loadProductsList(brandId),
                        data: productsList?.data?.records,
                        loading: loadingProducts,
                        error: productsErrors,
                    }}
                    productPrices={{
                        load: (
                            sapSkuNo: string,
                            pricesFrom: string,
                            pricesTo: string,
                        ) => loadProductsPrices(sapSkuNo, pricesFrom, pricesTo),
                        data: prices?.data,
                        loading: pricesLoading,
                        error: productsErrors,
                    }}
                    status={contract?.status}
                />
            </>
        );
    },
);

export default React.memo(ContractView);
