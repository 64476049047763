//lib
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React from "react";

/**
 * Outcome overview
 */
const OutcomeOverview = () => {
    return (
        <Box display="flex" flexDirection="column" height={1}>
            <Typography variant="h2">
                Your outcome is being processed
            </Typography>
        </Box>
    );
};

export default OutcomeOverview;
