// lib
import {
    Button,
    CircularProgress,
    Grid,
    InputLabel,
    TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";

//utils
import { isArrayWithContent } from "@utils";

//Props
type ViewableFields = {
    id: string;
    notEditable?: boolean;
    isSavedLabel?: boolean;
    inputLabel: string;
    required: boolean;
    isLargeSize?: boolean;
};

interface Props {
    fields: Array<ViewableFields>;
    id: string;
    loading: { creating: boolean; listLoading: boolean; deleting: boolean };
    addListItem: (value: object, savedLabel: string) => void;
}

/**
 *   Input form
 */
const InputForm: React.FC<Props> = ({ id, loading, fields, addListItem }) => {
    const [values, setValues] = useState<any>({});
    const [touched, setTouched] = useState({});

    useEffect(() => {
        if (!fields) return;

        let initialTouched = {};
        let initialValues = {};

        fields.forEach(field => {
            initialTouched = { ...initialTouched, [field.id]: false };
            initialValues = { ...initialValues, [field.id]: "" };
        });
        setTouched(initialTouched);
        setValues(initialValues);
    }, [fields]);

    const disabled = Object?.values(values).some(
        (value, index) => fields[index]?.required && !value,
    );

    /**
     * Render
     */
    return (
        <Grid
            container
            mb={5}
            mt={3}
            spacing={2}
            id={id}
            alignItems={"flex-end"}
        >
            {isArrayWithContent(fields) &&
                fields.map((field, index) => (
                    <Grid
                        key={field.id + "-" + index}
                        item
                        xs={12}
                        md={!field?.isLargeSize ? 3 : 4}
                    >
                        <InputLabel
                            error={
                                field.required &&
                                !values[field.id] &&
                                !!touched[field.id]
                            }
                            shrink
                        >
                            {field.inputLabel} {field.required && " (*)"}
                        </InputLabel>

                        <TextField
                            value={values[field.id]}
                            fullWidth
                            autoComplete="off"
                            error={
                                field.required &&
                                !values[field.id] &&
                                !!touched[field.id]
                            }
                            onBlur={() =>
                                setTouched({
                                    ...touched,
                                    [field.id]: true,
                                })
                            }
                            name="name"
                            variant="outlined"
                            size="small"
                            onChange={e => {
                                setValues({
                                    ...values,
                                    [field.id]:
                                        field.id === "countryIsoCode"
                                            ? e.target.value.toUpperCase()
                                            : e.target.value,
                                });
                            }}
                        />
                    </Grid>
                ))}

            <Grid item xs={12} sm={2}>
                <Button
                    size="large"
                    sx={{ fontSize: "0.75rem", height: "34px" }}
                    variant="outlined"
                    disabled={
                        disabled || loading?.creating || loading?.listLoading
                    }
                    onClick={() => {
                        addListItem(values, values["country"]);
                        setValues({});
                        setTouched({});
                    }}
                    fullWidth
                >
                    {loading?.creating ? (
                        <CircularProgress size={25} color="inherit" />
                    ) : (
                        "Add"
                    )}
                </Button>
            </Grid>
        </Grid>
    );
};
export default InputForm;
