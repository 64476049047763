// Lib

import Grid from "@mui/material/Grid";

import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import classnames from "classnames";
import React from "react";

// Own components
import { Card, LoadingWrapper } from "@components";

// Utils
import { isArrayWithContent, truncateString } from "@utils";

// Style
import * as style from "../style.module.scss";

/**
 * Props
 */
interface Props {
    id: string;
    evidences: {
        loading: boolean;
        data: Array<{ evidenceId: string; fileName: string }>;
        downloadEvidence?: (evidenceId: string) => void;
    };
}

/**
 * EvidencesSummary
 */
const EvidencesSummary: React.FC<Props> = ({ id, evidences }) => {
    return (
        <Grid item xs={12}>
            <Card id={`${id}-evidences`} title="Attached files">
                <LoadingWrapper
                    id={`${id}-evidences`}
                    loading={evidences?.loading}
                    customMsg={
                        isArrayWithContent(evidences.data)
                            ? undefined
                            : "No files found!"
                    }
                >
                    {isArrayWithContent(evidences.data) && (
                        <Grid container item xs={12} mt={3} spacing={3}>
                            {evidences.data.map((evidence, index) => (
                                <Grid item xs={5} key={index}>
                                    <Tooltip
                                        arrow
                                        placement="top"
                                        title={evidence.fileName}
                                    >
                                        <Typography
                                            variant="link"
                                            className={classnames(style.link, {
                                                [style.disabledLink]:
                                                    !evidences?.downloadEvidence,
                                            })}
                                            onClick={() =>
                                                evidences?.downloadEvidence
                                                    ? evidences?.downloadEvidence(
                                                          evidence.evidenceId,
                                                      )
                                                    : undefined
                                            }
                                        >
                                            {truncateString(
                                                evidence.fileName,
                                                30,
                                                true,
                                            )}
                                        </Typography>
                                    </Tooltip>
                                </Grid>
                            ))}
                        </Grid>
                    )}
                </LoadingWrapper>
            </Card>
        </Grid>
    );
};

export default React.memo(EvidencesSummary);
