// icon
import AddIcon from "@mui/icons-material/Add";
//lib
import {
    Box,
    Button,
    Divider,
    InputLabel,
    TextField,
    Typography,
} from "@mui/material";

import React, { Fragment, useCallback, useState } from "react";

// Utils
import { isArrayWithContent, thousandsSeparator } from "@utils";

//Own components
import { Dialog, HighlightCard } from "@components";

// Style
import * as style from "./style.module.scss";

/**
 * Milestone interface
 */
interface Milestone {
    name?: string;
    description: string;
    number: number;
    id?: string;
}

/**
 * Props type
 */
interface Props {
    milestones: Milestone[];
    onChange: (field: string, value: Milestone[]) => void;
    errors?: Record<string, any>;
    touched: Record<string, any>;
    setTouched?: any;
    onBlur: (event: React.SyntheticEvent) => void;
    id: string;
    noTitle?: boolean;
    productName: string;
    brandName: string;
    sapSkuNo: string;
    currency: string;
    price: string;
}

/**
 * Product Milestones Form
 */
const ProductMilestonesForm: React.FC<Props> = ({
    milestones,
    onChange,
    errors,
    touched,
    setTouched,
    onBlur,
    id,
    noTitle,
    productName,
    brandName,
    sapSkuNo,
    currency,
    price,
}) => {
    const [milestoneToDelete, setMilestoneToDelete] = useState<any>(undefined);
    /**
     * Change formik state
     */
    const handleChange = (key: string, index: number, value: string) => {
        const copyMilestones: any = [...milestones];
        copyMilestones[index] = { ...copyMilestones[index], [key]: value };
        onChange("milestones", copyMilestones);
    };

    /**
     * Add new milestone
     */
    const addMilestone = () => {
        const copyMilestones: any = [...milestones];
        copyMilestones.push({
            number: milestones.length + 1,
            description: "",
        });
        onChange("milestones", copyMilestones);
    };

    /**
     * Delete milestone
     */
    const deleteMilestone = () => {
        const copyMilestones: any = [...milestones];
        const filteredMilestones: Array<{
            number: number;
            description: string;
        }> = copyMilestones.filter(
            (_, idx) => idx !== milestoneToDelete?.index,
        );

        const mappedMilestones: Milestone[] = [];
        const mappedTouch: boolean[] = [];

        filteredMilestones.forEach((item, index) => {
            mappedMilestones.push({
                number: index + 1,
                description: item.description,
            });
            if (!!touched && isArrayWithContent(touched["description"])) {
                mappedTouch.push(true);
            }
        });

        !!setTouched && setTouched({ ...touched, description: mappedTouch });

        onChange("milestones", mappedMilestones);
        setMilestoneToDelete(undefined);
    };

    /**
     * Check if field has error
     */
    const transformError = useCallback(
        (key: string, index: number) => {
            return (
                (!errors &&
                    !milestones[index].description &&
                    touched[`${key}.${index}`]) ||
                (!!errors?.milestones &&
                    !!Object.keys(touched).length &&
                    !!errors.milestones[index] &&
                    !!errors.milestones[index][key] &&
                    !!touched[key] &&
                    !!touched[key][index])
            );
        },
        [errors, touched, milestones],
    );

    /**
     * Render
     */
    return (
        <Box mt={2}>
            {!noTitle && (
                <Box mb={3} mt={7}>
                    <Typography variant="h3">
                        {"Product milestones & conditions"}
                    </Typography>
                </Box>
            )}

            <form noValidate className={style.card} onBlur={onBlur}>
                <Dialog
                    id={`${id}-delete-milestone`}
                    open={!!milestoneToDelete}
                    message={
                        <Typography
                            variant="subtitle2"
                            color="black"
                            component="span"
                        >
                            Are you sure you would like to delete this milestone
                            for{" "}
                            <Typography
                                variant="body2"
                                component="span"
                            >{`${productName} (${brandName})? `}</Typography>
                            This action cannot be undone.
                        </Typography>
                    }
                    primaryButton={{
                        text: "Delete milestone",
                        action: deleteMilestone,
                    }}
                    secondaryButton={{
                        text: "Cancel",
                        action: () => setMilestoneToDelete(undefined),
                    }}
                />

                <HighlightCard
                    id={`${id}-milestone-form`}
                    title={`${productName} (${brandName})`}
                    items={[
                        sapSkuNo,
                        !!currency && !!price
                            ? `${thousandsSeparator(price)} ${currency}`
                            : undefined,
                    ]}
                    action={
                        !isArrayWithContent(milestones)
                            ? {
                                  label: "Add milestones",
                                  callback: () => addMilestone(),
                              }
                            : undefined
                    }
                />

                {isArrayWithContent(milestones) && (
                    <div className={style.list}>
                        <Typography
                            variant="caption1"
                            className={style.description}
                        >
                            Please describe the details for each milestone
                            below. This can include the period of follow-up
                            (e.g. from month 4 to month 9 after infusion), the
                            performance condition (e.g. OS, TTNT, PFS, etc.),
                            financial action required if a milestone is met or
                            not met (e.g. rebate of 30% on contract price).
                            These descriptions are used by IT Support to create
                            automation rules for your contract. Please be as
                            precise and concise as possible.
                        </Typography>

                        {milestones.map((item, index) => (
                            <Fragment key={index}>
                                <div className={style.headerAction}>
                                    <Typography variant="caption1Dark">
                                        {`Milestone ${item.number}`}
                                    </Typography>
                                    <Button
                                        id={`${id}-delete-milestone-${index}`}
                                        variant="text"
                                        color="primary"
                                        onClick={() =>
                                            setMilestoneToDelete({
                                                item,
                                                index,
                                            })
                                        }
                                    >
                                        Delete milestones
                                    </Button>
                                </div>
                                <div className={style.body}>
                                    <InputLabel
                                        id={`${id}-milestone-description-label-${index}`}
                                        className={style.label}
                                        error={
                                            !!transformError(
                                                "description",
                                                index,
                                            )
                                        }
                                        shrink
                                    >
                                        {"Milestone description (*)"}
                                    </InputLabel>

                                    <TextField
                                        id={`${id}-milestones-description-${index}`}
                                        fullWidth
                                        name={`description.${index}`}
                                        multiline
                                        autoComplete="off"
                                        onBlur={onBlur}
                                        placeholder="e.g. overall survival at one month with 30% rebate"
                                        rows={5}
                                        value={item.description || ""}
                                        onChange={(
                                            ev: React.SyntheticEvent,
                                        ) => {
                                            const target =
                                                ev.target as HTMLInputElement;
                                            handleChange(
                                                "description",
                                                index,
                                                target.value,
                                            );
                                        }}
                                        variant="outlined"
                                        error={
                                            !!transformError(
                                                "description",
                                                index,
                                            )
                                        }
                                    />
                                </div>
                            </Fragment>
                        ))}
                        <Divider />
                        <Button
                            id={`${id}-add-milestone-btn`}
                            variant="text"
                            startIcon={<AddIcon />}
                            color="primary"
                            className={style.footerAction}
                            onClick={addMilestone}
                            disabled={
                                !milestones[milestones.length - 1]?.description
                            }
                        >
                            Add milestones
                        </Button>
                    </div>
                )}
            </form>
        </Box>
    );
};
export default React.memo(ProductMilestonesForm);
