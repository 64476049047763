//Lib
import {
    Box,
    Typography,
    FormControl,
    FormControlLabel,
    RadioGroup,
    Radio,
} from "@mui/material";

import React, { Fragment } from "react";

// Own components
import { Dialog } from "@components";

/**
 * Props
 */
interface Props {
    openDialog: boolean;
    onConfirm: () => void;
    loading: boolean;
    onReset: () => void;
    className?: string;
    values: {
        approved: string;
        rejectionReason: string;
    };
    onBlur: (event: React.SyntheticEvent) => void;
    onChange: any;
    id: string;
    fileName;
}

/**
 * price data approval task
 */
const PriceData = ({
    id,
    openDialog,
    loading,
    onReset,
    className,
    values,
    onBlur,
    onChange,
    onConfirm,
    fileName,
}: Props) => {
    return (
        <Fragment>
            <Dialog
                id={`${id}-tasks-approval-price-data-approval-task`}
                title={"Approve upload Open House product prices task"}
                open={openDialog}
                message={
                    values?.approved === "false" ? (
                        <Typography variant="subtitle2" color="black">
                            You are about to reject the upload of product prices
                            for the Open House contracts
                            <Typography variant="body2" component="span">
                                {` (${fileName})`}
                            </Typography>
                        </Typography>
                    ) : (
                        <Typography variant="subtitle2" color="black">
                            You are about to approve the upload of product
                            prices for the Open House contracts
                            <Typography variant="body2" component="span">
                                {` (${fileName}). `}
                            </Typography>
                            {
                                "Once you click 'Confirm', all the product prices in the file will be updated or added in SCP."
                            }
                        </Typography>
                    )
                }
                primaryButton={{
                    text: "Confirm",
                    action: onConfirm,
                    loading: loading,
                }}
                secondaryButton={{
                    text: "Cancel",
                    action: onReset,
                }}
            />

            <Box mb={3} className={className}>
                <form noValidate onBlur={onBlur}>
                    <FormControl>
                        <RadioGroup
                            id={`${id}-price-data-approval`}
                            value={values?.approved}
                            onChange={onChange}
                            name="approved"
                        >
                            <FormControlLabel
                                value={"true"}
                                id={`${id}-task-details-approve-price-data`}
                                control={<Radio size="small" />}
                                label={
                                    <Typography
                                        variant="body2"
                                        color="dark.light"
                                    >
                                        I approve this decision
                                    </Typography>
                                }
                            />

                            <FormControlLabel
                                id={`${id}-task-details-reject-price-data`}
                                value={"false"}
                                control={<Radio size="small" />}
                                label={
                                    <Typography
                                        variant="body2"
                                        color="dark.light"
                                    >
                                        I reject this decision
                                    </Typography>
                                }
                            />
                        </RadioGroup>
                    </FormControl>
                </form>
            </Box>
        </Fragment>
    );
};

export default React.memo(PriceData);
