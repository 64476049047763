import { useAxios } from "./useAxios";
import type { Api } from "@types";
import { useEffect } from "react";

const BASE_URI = `/v0`;

/**
 *
 * Fetch orders
 */
export const useOrders = (path: string, status?: string) => {
    const url = `${BASE_URI}/${path}`;

    const {
        response: orders,
        loading: fetchingOrders,
        fetch: loadList,
        error: listError,
    }: Api = useAxios(
        {
            method: "GET",
            url: status ? `${url}?status=${status}` : `${url}`,
        },
        { errorHandler: `Failed to fetch orders` },
    );

    const {
        response: order,
        loading: fetchingOrder,
        fetch: fetchOrder,
        error: orderError,
    }: Api = useAxios(
        {
            method: "GET",
        },
        { errorHandler: `Failed to fetch order` },
    );

    const {
        response: updateOrder,
        loading: updatingOrder,
        fetch: update,
        error: updateOrderError,
    }: Api = useAxios(
        {
            method: "POST",
            url: `${url}?status=${status}`,
        },
        {
            errorHandler: `Failed to update order`,
            successHandler: "Order was successfully updated",
        },
    );

    const {
        response: linkOrder,
        loading: linkingOrder,
        fetch: link,
        error: linkOrderError,
    }: Api = useAxios(
        {
            method: "POST",
        },
        {
            errorHandler: `Failed to link order`,
            successHandler: "Order was successfully linked",
        },
    );

    const {
        response: unlinkOrder,
        loading: unlinkingOrder,
        fetch: unlink,
        error: unlinkOrderError,
    }: Api = useAxios(
        {
            method: "POST",
        },
        {
            errorHandler: `Failed to unlink order`,
            successHandler: "Order was successfully unlinked",
        },
    );

    return {
        response: { orders, order, updateOrder, linkOrder, unlinkOrder },
        loading: {
            fetchingOrders,
            fetchingOrder,
            updatingOrder,
            linkingOrder,
            unlinkingOrder,
        },
        error: {
            ordersError: listError,
            orderError,
            updateOrderError,
            linkOrderError,
            unlinkOrderError,
        },

        reload: (searchParams?: string) =>
            loadList({
                url: searchParams ? `${url}${searchParams}` : `${url}`,
            }),
        search: (searchParams: string) =>
            loadList({
                url: `${url}${searchParams}`,
            }),
        fetchOrder: (orderId: string) =>
            fetchOrder({
                url: `${url}/${orderId}`,
            }),
        updateOrder: (orderId: string, data) =>
            update({
                url: `${url}/${orderId}/status`,
                data,
            }),
        linkOrder: (orderId: string, contractId: string) =>
            link({
                url: `${url}/${orderId}/link`,
                data: { contractId },
            }),
        unlinkOrder: (orderId: string) =>
            unlink({
                url: `${url}/${orderId}/unlink`,
            }),
    };
};

/**
 * Edit Infusion Date
 */
export const useEditInfusionDate = () => {
    const { response, loading, fetch, error }: Api = useAxios({
        method: "POST",
    });

    return {
        error,
        loading,
        response,
        editInfusionDate: (
            action: "edit" | "add",
            orderId: string,
            infusionDate: string,
        ) =>
            fetch(
                {
                    url: `${BASE_URI}/orders/${orderId}/infusionDate`,
                    data: { infusionDate },
                },
                {
                    errorHandler: `Failed to ${action} infusion date`,
                    successHandler: `Infusion date was successfully ${action}ed`,
                },
            ),
    };
};

/**
 * Fetch order by id
 */
export const useOrder = () => {
    const { response, loading, error, fetch }: Api = useAxios();
    return {
        getOrder: (orderId: string) =>
            fetch(
                {
                    method: "GET",
                    url: `${BASE_URI}/orders/${orderId}`,
                },
                { errorHandler: "Failed to fetch order" },
            ),

        error,
        loading,
        response,
    };
};

export const useOrderStatuses = () => {
    const {
        response: list,
        loading: listLoading,
        fetch: load,
        error: listError,
    }: Api = useAxios(
        {
            method: "GET",
            url: `${BASE_URI}/orders/statuses`,
        },
        {
            errorHandler: "Faild to fetch order statuses",
        },
    );

    useEffect(() => {
        let unmounted = false;

        if (!unmounted) {
            load();
        }
        return () => {
            unmounted = true;
        };
    }, []);

    return {
        loading: listLoading,
        error: listError,
        list: list,
    };
};
