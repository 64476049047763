//import libs
import { TableCell, TableRow, Typography, Checkbox } from "@mui/material";
import { constantMapper, isArrayWithContent, thousandsSeparator } from "@utils";
import React, { useMemo } from "react";

interface Row {
    id: string;
    bpfName: string;
    salessystemOrderId: string;
    sapOrderNo: string;
    amount: number;
    measure: string;
    percentage: string;
    vatPercentage: string;
    vatAmount: number;
    status: string;
    outcomeId: string;
}

interface Props {
    row: Row;
    rowIndex: number;
    permissions: any;
    id: string;
    callbacks: any;
    disabled?: boolean;
}

const FinancialTransactions = ({
    id,
    row,
    rowIndex,
    callbacks,
    disabled,
}: Props) => {
    /**
     * Check if the item is selected
     * don't return undefined to avoid warnings
     */
    const isItemSelected = useMemo(() => {
        if (
            !row.outcomeId ||
            !callbacks?.selectedItems ||
            !isArrayWithContent(callbacks?.selectedItems)
        )
            return false;
        return callbacks?.selectedItems?.includes(row?.outcomeId);
    }, [callbacks?.selectedItems, row?.outcomeId]);

    return (
        <TableRow
            id={`${id}-row-${rowIndex}`}
            sx={{
                cursor: "pointer",
            }}
            selected={!!isItemSelected}
            onClick={event => {
                if (!disabled) {
                    callbacks.onSelect(
                        row.outcomeId,
                        parseInt((event.target as HTMLInputElement).value),
                    );
                }
            }}
        >
            <TableCell align="left" padding="checkbox">
                <Checkbox
                    onChange={() => callbacks?.onSelect(row.outcomeId)}
                    checked={!!isItemSelected}
                    disabled={disabled}
                />
            </TableCell>

            <TableCell>
                <Typography variant="caption2">
                    {row.salessystemOrderId}
                </Typography>
            </TableCell>

            <TableCell>
                <Typography variant="caption2"> {row.sapOrderNo}</Typography>
            </TableCell>
            <TableCell>
                <Typography variant="caption2">
                    {" "}
                    {thousandsSeparator(row.amount)}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography variant="caption2"> {row.measure}</Typography>
            </TableCell>

            <TableCell>
                <Typography variant="caption2"> {row.percentage}</Typography>
            </TableCell>

            <TableCell>
                <Typography variant="caption2"> {row.vatPercentage}</Typography>
            </TableCell>

            <TableCell>
                <Typography variant="caption2">
                    {" "}
                    {thousandsSeparator(row.vatAmount)}
                </Typography>
            </TableCell>

            <TableCell>
                <Typography variant="caption2">
                    {constantMapper(row.status)}
                </Typography>
            </TableCell>
        </TableRow>
    );
};

export default FinancialTransactions;
