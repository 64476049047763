// Lib
import { Box, Typography } from "@mui/material";
import React, { useEffect, useImperativeHandle } from "react";
import { useRecoilState, useResetRecoilState } from "recoil";

// Own components
import { FormFooter, InfoBox, Table } from "@components";

//Constants
import { HEADERS, ROWRENDERERCONST } from "@constants";

// Custom hooks
import { useGetMatchedContractsWithOrder, useUpdateStep } from "@hooks";

// Atoms
import { contractSelectionState, linkOrderStepsState } from "@atoms";

// Types
import type { FlowStep, Location } from "@types";

// Utils
import { apiResponseCounter } from "@utils";

/**
 * Props
 */
interface Props {
    location?: Location;
    currentStep?: FlowStep;
    order?: { orderId: string };
    id?: string;
}

/**
 * Link order
 */
const LinkOrder = React.forwardRef(
    ({ location, currentStep, order, id }: Props, ref) => {
        /**
         * API
         */
        const {
            response: contractResult,
            loading: contractResultLoading,
            load: loadContracts,
        } = useGetMatchedContractsWithOrder();

        /**
         * Recoil
         */
        const [contractSelectionInitialState, updateContractSelectionState] =
            useRecoilState(contractSelectionState);

        const resetAtoms = useResetRecoilState(contractSelectionState);

        /**
         * Reset contract selection & fetch fresh contract list
         */
        useEffect(() => {
            resetAtoms();
            if (order?.orderId) loadContracts(order.orderId);
        }, [order]);

        /**
         * Update step hook
         */
        const updateStepValidation = useUpdateStep(
            location,
            linkOrderStepsState,
        );

        /**
         * Form validation check
         */
        const canSaveState =
            currentStep?.isPrepared ||
            !!contractSelectionInitialState.reference;

        /**
         * Check if the current step is valid
         * then set isPrepared to true, otherwise set it false
         */
        useEffect(() => {
            updateStepValidation(canSaveState);
        }, [canSaveState, location]);

        /**
         * Save state and go to the next page (controlled by the layout)
         */
        useImperativeHandle(ref, () => ({
            onSubmit() {
                return contractSelectionInitialState;
            },
        }));

        return (
            <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                height={1}
            >
                <div>
                    <Box mb={6}>
                        <Typography variant="h2">Contract selection</Typography>
                    </Box>

                    <Box display="flex" alignItems="center" mt={5}>
                        <Typography variant="h3">Contracts</Typography>

                        <Typography ml={1} variant="caption1">
                            {apiResponseCounter(
                                contractResult,
                                contractResultLoading,
                                "contract|contracts",
                            )}
                        </Typography>
                    </Box>

                    <Table
                        id={`${id}-link-order`}
                        headers={HEADERS.OUTCOME_CONTRACT_SELECTION}
                        rows={contractResult?.data.records}
                        loading={contractResultLoading}
                        type={ROWRENDERERCONST.OUTCOME_CONTRACT_SELECTION}
                        maxHeight="35rem"
                        emptyMsg="No contracts found"
                        callbacks={{
                            onSelect: row => {
                                updateContractSelectionState(row);
                            },
                        }}
                        selectedRow={contractSelectionInitialState?.id}
                    />
                </div>
                <div>
                    <Box mb={6}>
                        <InfoBox
                            id={`${id}-link-order-info-box`}
                            warning
                            message={
                                <Typography variant="subtitle2">
                                    You are about to link this order to a
                                    contract. This action cannot be undone.
                                </Typography>
                            }
                        />
                    </Box>
                    <FormFooter
                        id={`${id}-link-order-footer`}
                        error={false}
                        textAlign="right"
                        showText={false}
                        noMargin
                    />
                </div>
            </Box>
        );
    },
);

export default React.memo(LinkOrder);
