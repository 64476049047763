// Icons
import SquareIcon from "@mui/icons-material/Square";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

// Lib
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import FormControl from "@mui/material/FormControl";
import { Box, Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import React, { Fragment, useMemo } from "react";

// Own components
import { FieldRenderer, Select } from "@components";

// Types
import { TeamRole } from "@types";

// Utils
import { isArrayWithContent, stringTransformer } from "@utils";

// Styles
import * as style from "./style.module.scss";

/**
 * Right type
 */
interface Right {
    category: string;
    rightId: string;
    rightName: string;
}

/**
 * Props type
 */
interface Props {
    id: string;
    onRightsChange?: (rightId: string) => void;
    onRoleChange?: (role: TeamRole) => void;
    disabled?: boolean;
    rights: Array<Right>;
    values?: Array<string>;
    roles?: {
        data: Array<TeamRole>;
        loading: boolean;
    };
    approvalRole?: TeamRole;
    onBlur?: (key: string) => void;
    isEditMode?: boolean;
}
/**
 * User Rights
 */
const UserRights: React.FC<Props> = ({
    id,
    onRightsChange,
    onRoleChange,
    disabled,
    rights,
    values,
    roles,
    approvalRole,
    onBlur,
    isEditMode,
}) => {
    /**
     * Map rights to Category & right label
     */
    const rightsMapper = useMemo(() => {
        if (!isArrayWithContent(rights)) return;

        return rights.reduce((acc: any, curr: Right) => {
            const found = acc.find((right: Right) => {
                return right.category === curr.category;
            });

            if (found) {
                found.rightIds.push({
                    rightId: curr.rightId,
                    rightName: curr.rightName,
                });
            } else {
                acc.push({
                    category: curr.category,
                    rightIds: [
                        {
                            rightId: curr.rightId,
                            rightName: curr.rightName,
                        },
                    ],
                });
            }
            return acc;
        }, []);
    }, [rights]);

    /**
     * Render
     */
    return (
        <FormControl fullWidth>
            <FormGroup>
                <Grid container item xs={12} spacing={2}>
                    {rightsMapper?.map(item => (
                        <Grid
                            container
                            item
                            xs={6}
                            key={`${item.category}`}
                            data-testid={item.category}
                            mb={5}
                            justifyContent={"space-between"}
                            alignItems={"baseline"}
                        >
                            <Grid item xs={3} pt={1}>
                                <Typography
                                    variant="caption1"
                                    color="text.disabled"
                                >
                                    {stringTransformer(item.category, {
                                        capitalize: true,
                                    })}
                                </Typography>
                            </Grid>
                            <Grid item xs={8}>
                                {item.rightIds.map((el, rightsIndex) => (
                                    <Fragment
                                        key={`${id}-${rightsIndex}-${el.rightId}-${item.category}`}
                                    >
                                        <FormControlLabel
                                            id={`${id}-${rightsIndex}-${el.rightId}-${item.category}`}
                                            sx={{
                                                width: "100%",
                                            }}
                                            control={
                                                <Checkbox
                                                    id={`${id}-${rightsIndex}-${el.rightId}-${item.category}-checkbox`}
                                                    onBlur={() =>
                                                        onBlur?.("rightIds")
                                                    }
                                                    checked={
                                                        !!values &&
                                                        values?.indexOf(
                                                            el.rightId,
                                                        ) > -1
                                                    }
                                                    checkedIcon={
                                                        <CheckBoxIcon
                                                            sx={{
                                                                width: "0.85em",
                                                                height: "0.85em",
                                                                outlineOffset:
                                                                    "-3px",
                                                                outline:
                                                                    disabled
                                                                        ? "3px solid #9CA6C6"
                                                                        : "2px solid #036",
                                                                borderRadius:
                                                                    "3px",
                                                            }}
                                                        />
                                                    }
                                                    icon={
                                                        <SquareIcon
                                                            sx={{
                                                                width: "0.85em",
                                                                height: "0.85em",
                                                                border: "0.25px solid #036",
                                                                boxShadow:
                                                                    "inset 0px 0px 0px 0.2px #036",
                                                                outline:
                                                                    "7px solid white",
                                                                outlineOffset:
                                                                    "-8px",
                                                                borderRadius:
                                                                    "2px",
                                                                fill: "white",
                                                            }}
                                                        />
                                                    }
                                                    onChange={() =>
                                                        onRightsChange
                                                            ? onRightsChange(
                                                                  el.rightId,
                                                              )
                                                            : undefined
                                                    }
                                                    name={el.rightId}
                                                />
                                            }
                                            label={
                                                <Typography variant="caption1">
                                                    {stringTransformer(
                                                        el.rightName,
                                                    )}
                                                </Typography>
                                            }
                                            disabled={disabled}
                                        />

                                        {el?.rightId === "RightId15" &&
                                            !!values &&
                                            values?.indexOf("RightId15") >
                                                -1 && (
                                                <Fragment>
                                                    {isEditMode ? (
                                                        <Box mt={1}>
                                                            <label
                                                                className={
                                                                    style.label
                                                                }
                                                            >
                                                                {
                                                                    "Approver role"
                                                                }
                                                            </label>
                                                            <Select
                                                                id={`${id}-team-approver-role`}
                                                                name="approverRole"
                                                                whiteBg
                                                                size="small"
                                                                loading={
                                                                    roles?.loading
                                                                }
                                                                onBlur={() =>
                                                                    onBlur?.(
                                                                        "role",
                                                                    )
                                                                }
                                                                onChange={(
                                                                    val,
                                                                    idx,
                                                                ) =>
                                                                    !!roles &&
                                                                    !!isArrayWithContent(
                                                                        roles?.data,
                                                                    ) &&
                                                                    onRoleChange
                                                                        ? onRoleChange(
                                                                              typeof idx ===
                                                                                  "number"
                                                                                  ? roles
                                                                                        ?.data[
                                                                                        idx
                                                                                    ]
                                                                                  : val ||
                                                                                        "",
                                                                          )
                                                                        : undefined
                                                                }
                                                                menuItemId="roleId"
                                                                menuItemLabel="roleName"
                                                                placeHolder={{
                                                                    title: "None",
                                                                    value: "",
                                                                    selectable:
                                                                        true,
                                                                }}
                                                                list={
                                                                    roles?.data ||
                                                                    []
                                                                }
                                                                value={
                                                                    approvalRole ||
                                                                    ""
                                                                }
                                                                displayEmpty
                                                                disabled={
                                                                    disabled
                                                                }
                                                            />
                                                        </Box>
                                                    ) : values?.indexOf(
                                                          "RightId15",
                                                      ) > -1 ? (
                                                        <Fragment>
                                                            <label
                                                                className={
                                                                    style.label
                                                                }
                                                            >
                                                                {
                                                                    "Approver role"
                                                                }
                                                            </label>
                                                            <FieldRenderer
                                                                id={`${id}-role`}
                                                                value={
                                                                    approvalRole?.roleName ||
                                                                    "None"
                                                                }
                                                            />
                                                        </Fragment>
                                                    ) : null}
                                                </Fragment>
                                            )}
                                    </Fragment>
                                ))}
                            </Grid>
                        </Grid>
                    ))}
                </Grid>
            </FormGroup>
        </FormControl>
    );
};
export default React.memo(UserRights);
