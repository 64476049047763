import { Grid, Typography } from "@mui/material";
import React, { useState } from "react";

// Own components
import { MaintenanceList, Dialog } from "@components";
//hooks
import { useMaintenance } from "@hooks";
import { Location } from "@types";
import { isSuccessfulCall } from "@utils";

/**
 * Props type
 */
interface Props {
    location?: Location;
    id?: string;
}

/**
 * Partner
 */
const MaintenancePartner = ({ id }: Props) => {
    const [itemToDelete, setItemToDelete] = useState<
        | {
              id: string;
              savedLabel: string;
          }
        | undefined
    >();

    /**
     * API
     */

    const {
        list: accountTypes,
        error: accountTypeError,
        loading,
        delete: deleteAccountType,
        upsert: upsertAccountType,
    } = useMaintenance("accountType", "partner type|partner types");

    /**
     * Delete item
     */
    const onDelete = () => {
        if (!itemToDelete?.id) return;
        deleteAccountType(itemToDelete.id, itemToDelete.savedLabel).then(
            res => {
                if (isSuccessfulCall(res?.status)) {
                    setItemToDelete(undefined);
                }
            },
        );
    };

    /**
     * Render
     */
    return (
        <Grid container columnSpacing={10}>
            <Dialog
                id={`${id}-delete-partner`}
                title={"Delete partner"}
                open={!!itemToDelete?.id}
                message={
                    <Typography
                        variant="subtitle2"
                        color="black"
                        component="span"
                    >
                        Are you sure you would like to delete{" "}
                        <Typography
                            variant="body2"
                            component="span"
                        >{`(${itemToDelete?.savedLabel})? `}</Typography>
                    </Typography>
                }
                primaryButton={{
                    text: "Confirm",
                    action: onDelete,
                    loading: loading?.deleting,
                }}
                secondaryButton={{
                    text: "Cancel",
                    action: () => setItemToDelete(undefined),
                }}
            />
            <MaintenanceList
                title="Partner type"
                subtitle="partner types"
                label="New partner type (*)"
                id={`${id}-partner-status`}
                list={accountTypes?.maintenanceAccountTypeList}
                loading={{
                    fetching: loading?.listLoading,
                    creating: loading?.creating,
                    deleting: loading?.deleting,
                }}
                valueLabel="accountTypeName"
                valueId="accountTypeId"
                error={accountTypeError}
                addListItem={(value, savedLabel) =>
                    upsertAccountType(value, savedLabel)
                }
                deleteListItem={(id, savedLabel) =>
                    setItemToDelete({ id, savedLabel })
                }
            />
        </Grid>
    );
};
export default MaintenancePartner;
