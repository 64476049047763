// Lib
import { Typography, Avatar } from "@mui/material";
import React from "react";

interface Props {
    email: string;
    firstName: string;
    lastName: string;
    width?: number;
    height?: number;
    bgColor?: string;
}

/**
 * Avatar Renderer
 */
const AvatarRenderer = ({
    email,
    firstName,
    lastName,
    width = 43,
    height = 43,
    bgColor,
}: Props) => {
    const constructedAvatar =
        !!firstName && !!lastName
            ? `${firstName[0]}${lastName[0]}`
            : !!email && email.substring(0, 2).toUpperCase();

    return (
        <Avatar
            sx={{
                bgcolor: bgColor,
                marginRight: "0.5rem",
                width,
                height,
                display: "flex",
                alignItems: "center",
            }}
        >
            <Typography
                variant="h4"
                color="#fff"
                component="span"
                sx={{
                    fontWeight: "500",
                }}
            >
                {constructedAvatar}
            </Typography>
        </Avatar>
    );
};

export default React.memo(AvatarRenderer);
