import { Partner } from "@types";
import { atom } from "recoil";

/**
 * Contract partner state
 */
export const contractPartnersState = atom({
    key: "contractPartnersState",
    default: [] as Partner[],
});
