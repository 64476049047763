//Lib
import {
    Box,
    Typography,
    Grid,
    InputLabel,
    TextField,
    FormControl,
    FormControlLabel,
    RadioGroup,
    Radio,
} from "@mui/material";
import { NumericFormat } from "react-number-format";
import React, { Fragment } from "react";

// Own component
import { TaskReview, Dialog } from "@components";

// Styles
import * as style from "../style.module.scss";

/**
 * Props
 */
interface Props {
    onBlur: (event: React.SyntheticEvent) => void;
    onChange: any;
    errors: Record<string, string>;
    touched: Record<string, string>;
    values: Record<string, string>;
    id: string;
    task: any;
    openDialog: boolean;
    order: any;
    onApprove: () => void;
    loading: boolean;
    onReset: () => void;
    className?: string;
    hideAction: boolean;
    onFieldChange: (field: string, value: any) => void;
    downloadApheresis?: {
        name: string;
        onDownload: () => void;
        loading: boolean;
    } | null;
}

/**
 * Outcome Approval Task
 */
const OutcomeApprovalTask = ({
    id,
    onBlur,
    onChange,
    errors,
    touched,
    values,
    task,
    openDialog,
    order,
    loading,
    onApprove,
    onReset,
    className,
    hideAction = false,
    onFieldChange,
    downloadApheresis,
}: Props) => {
    /**
     * API
     */

    return (
        <Fragment>
            <Dialog
                id={`tasks-approval-task`}
                title={"Approve calculation"}
                open={openDialog}
                message={
                    values?.approved === "rejected" ? (
                        <Typography variant="subtitle2" color="black">
                            You are about to reject an outcome and consequently
                            mark task
                            <Typography variant="body2" component="span">
                                {` Approve financial calculation [${order?.salessystemOrderId}] `}
                            </Typography>
                            as complete. Doing so will cancel the outcome and a
                            new outcome should be made
                        </Typography>
                    ) : (
                        <Typography variant="subtitle2" color="black">
                            You are about to approve the calculation and
                            consequently mark task
                            <Typography variant="body2" component="span">
                                {` Approve financial calculation [${order?.salessystemOrderId}] `}
                            </Typography>
                            as complete. Doing so will trigger a financial
                            transaction. This action cannot be undone.
                        </Typography>
                    )
                }
                primaryButton={{
                    text: "Confirm",
                    action: onApprove,
                    loading: loading,
                }}
                secondaryButton={{
                    text: "Cancel",
                    action: onReset,
                }}
            />

            <Typography variant="h2" className={style.header}>
                {`Approve financial calculation [${order?.salessystemOrderId}]`}
            </Typography>

            <TaskReview
                id={`${id}-task-review-order`}
                title="Task data"
                sapOrderNo={task?.data?.order?.sapOrderNo}
                treatmentSite={task?.data?.order?.treatmentSite}
                infusionDate={task?.data?.order?.infusionDate}
                creationDate=""
                dueDate=""
            />
            <TaskReview
                id={`${id}-task-review-input`}
                title="Input"
                inputValues={task?.data?.task?.input}
            />
            <TaskReview
                id={`task-details-task-review`}
                title="Outcome"
                outcomeValues={task?.data?.outcome}
            />
            {!!downloadApheresis && !!downloadApheresis?.name && (
                <TaskReview
                    id={`task-details-task-review`}
                    title="Outcome files"
                    downloadApheresis={downloadApheresis}
                />
            )}

            {!hideAction && (
                <Box className={className}>
                    <Box mb={2}>
                        <Typography variant="h3">Financial approval</Typography>
                    </Box>

                    <form noValidate onBlur={onBlur}>
                        <FormControl>
                            <RadioGroup
                                id={`task-details-task-review`}
                                value={values?.approved}
                                onChange={onChange}
                                name="approved"
                            >
                                <FormControlLabel
                                    value={"approved"}
                                    id={`task-details-task-review-approve`}
                                    control={<Radio size="small" />}
                                    label={
                                        <Typography
                                            variant="body2"
                                            color="dark.light"
                                        >
                                            I approve this decision
                                        </Typography>
                                    }
                                />
                                <FormControlLabel
                                    id={`task-details-task-review-approve-with-different-amount`}
                                    value={"approvedWithDifferentAmount"}
                                    control={<Radio size="small" />}
                                    label={
                                        <Typography
                                            variant="body2"
                                            color="dark.light"
                                        >
                                            I approve this decision, with a
                                            different amount:
                                        </Typography>
                                    }
                                />
                                <FormControlLabel
                                    id={`task-details-task-review-approve-with-different-amount`}
                                    value={"rejected"}
                                    control={<Radio size="small" />}
                                    label={
                                        <Typography
                                            variant="body2"
                                            color="dark.light"
                                        >
                                            I reject this decision
                                        </Typography>
                                    }
                                />
                            </RadioGroup>
                        </FormControl>

                        {values?.approved === "rejected" && (
                            <Grid container spacing={2} mt={4}>
                                <Grid xs={12} item>
                                    <InputLabel
                                        error={
                                            !!errors.rejectionReason &&
                                            !!touched.rejectionReason
                                        }
                                        shrink
                                    >
                                        {"Reason of rejection (*)"}
                                    </InputLabel>
                                    <TextField
                                        id={`task-details-task-reason-of-rejection-input`}
                                        fullWidth
                                        name="rejectionReason"
                                        autoComplete="off"
                                        value={values.rejectionReason}
                                        variant="outlined"
                                        size="small"
                                        onChange={onChange}
                                        error={
                                            !!errors.rejectionReason &&
                                            !!touched.rejectionReason
                                        }
                                    />
                                </Grid>
                                <Grid xs={12} item>
                                    <InputLabel
                                        error={
                                            !!errors.decisionInfo &&
                                            !!touched.decisionInfo
                                        }
                                        shrink
                                    >
                                        {"Additional comments (*)"}
                                    </InputLabel>
                                    <TextField
                                        id={`task-details-task-review-decision-info-input`}
                                        fullWidth
                                        name="decisionInfo"
                                        value={values.decisionInfo}
                                        variant="outlined"
                                        autoComplete="off"
                                        size="small"
                                        onChange={onChange}
                                        error={
                                            !!errors.decisionInfo &&
                                            !!touched.decisionInfo
                                        }
                                    />
                                </Grid>
                            </Grid>
                        )}
                        {values?.approved === "approvedWithDifferentAmount" && (
                            <Grid container spacing={2} mt={4}>
                                <Grid xs={12} md={5} item>
                                    <InputLabel
                                        error={
                                            !!errors.newRebateAmount &&
                                            !!touched.newRebateAmount
                                        }
                                        shrink
                                    >
                                        {"New amount (*)"}
                                    </InputLabel>

                                    <NumericFormat
                                        id={`task-details-task-review-new-rebate-input`}
                                        fullWidth
                                        name="newRebateAmount"
                                        autoComplete="off"
                                        value={values.newRebateAmount}
                                        variant="outlined"
                                        error={
                                            !!errors.newRebateAmount &&
                                            !!touched.newRebateAmount
                                        }
                                        thousandSeparator="."
                                        decimalSeparator=","
                                        customInput={TextField}
                                        allowNegative={false}
                                        size="small"
                                        onValueChange={({ floatValue }) => {
                                            onFieldChange(
                                                "newRebateAmount",
                                                floatValue,
                                            );
                                        }}
                                    />
                                </Grid>

                                <Grid xs={12} md={7} item>
                                    <InputLabel
                                        error={
                                            !!errors.overrideReason &&
                                            !!touched.overrideReason
                                        }
                                        shrink
                                    >
                                        {"Reason for change (*)"}
                                    </InputLabel>
                                    <TextField
                                        id={`task-details-task-review-override-reason-input`}
                                        fullWidth
                                        name="overrideReason"
                                        value={values.overrideReason}
                                        variant="outlined"
                                        autoComplete="off"
                                        size="small"
                                        onChange={onChange}
                                        error={
                                            !!errors.overrideReason &&
                                            !!touched.overrideReason
                                        }
                                    />
                                </Grid>
                                <Grid xs={12} item>
                                    <InputLabel
                                        error={
                                            !!errors.decisionInfo &&
                                            !!touched.decisionInfo
                                        }
                                        shrink
                                    >
                                        {"Additional comments (*)"}
                                    </InputLabel>
                                    <TextField
                                        id={`task-details-task-review-decision-info-input`}
                                        fullWidth
                                        name="decisionInfo"
                                        value={values.decisionInfo}
                                        variant="outlined"
                                        autoComplete="off"
                                        size="small"
                                        onChange={onChange}
                                        error={
                                            !!errors.decisionInfo &&
                                            !!touched.decisionInfo
                                        }
                                    />
                                </Grid>
                            </Grid>
                        )}
                    </form>
                </Box>
            )}
        </Fragment>
    );
};

export default React.memo(OutcomeApprovalTask);
