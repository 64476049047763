// lib
import { Box, Typography } from "@mui/material";

import React, { useState } from "react";

// Own components
import { Table, SearchBar } from "@components";

// Hooks
import { useViewingOptions } from "@hooks";

// Constants
import { HEADERS, ROWRENDERERCONST } from "@constants";

// Types
import type { BillingPlanValues } from "@types";

import BillingPlanForm from "./BillingPlanForm";

/**
 * Viewable Fields Props type
 */

interface ViewableFields {
    id: string;
    inputLabel: string;
    required: boolean;
    type?: string;
}

/**
 * Props type
 */
interface Props {
    fields: Array<ViewableFields>;
    id: string;
    error: any;
    list: Array<any>;
    loading: {
        fetchingList: boolean;
        creating: boolean;
    };
    onSubmit: (values: BillingPlanValues, isEdit: boolean) => Promise<any>;
}

const INITIAL_VALUES = {
    salesOrg: "",
    documentType: "",
    division: "",
    planType: "",
    counter: "",
};

/**
 * Maintenance Billing Plan
 */
const MaintenanceBillingPlan: React.FC<Props> = ({
    id,
    list,
    loading,
    fields,
    onSubmit,
}) => {
    /**
     * States
     */
    const [initialValues, setInitialValues] = useState<any>(undefined);

    /**
     * Custom Hooks
     */
    const { viewingOptions, setViewingOptions } = useViewingOptions(
        ROWRENDERERCONST.BILLING_PLAN,
    );

    const handleSubmit = (values: BillingPlanValues, isEditMode: boolean) => {
        onSubmit(values, isEditMode).then(res => {
            !!res && setInitialValues(undefined);
        });
    };
    /**
     * Render
     */
    return (
        <Box mt={5}>
            <BillingPlanForm
                id={`${id}-billing-plan-form`}
                initialValues={initialValues}
                onSubmit={handleSubmit}
                open={!!initialValues}
                onClose={() => setInitialValues(undefined)}
                loading={loading?.creating}
                fields={fields}
            />

            <Box mt={2} mb={4} display="flex" justifyContent="flex-end">
                <SearchBar
                    id="billing-plan-search"
                    filterKey={ROWRENDERERCONST.BILLING_PLAN}
                    setViewingOptions={setViewingOptions}
                    viewingOptions={viewingOptions}
                    action={{
                        label: "Add new billing plan",
                        callback: () => setInitialValues(INITIAL_VALUES),
                        disabled: loading?.fetchingList,
                    }}
                    hasFilters={false}
                />
            </Box>

            <Box mb={4}>
                <Typography variant="h2">Billing plan</Typography>
            </Box>
            <Table
                loading={loading?.fetchingList}
                headers={HEADERS.BILLING_PLAN}
                rows={list}
                type={ROWRENDERERCONST.BILLING_PLAN}
                viewingOptions={viewingOptions}
                id="billing-plan-list"
                callbacks={{
                    editItem: (index: number) => {
                        setInitialValues(list[index]);
                    },
                }}
                emptyMsg="No data"
            />
        </Box>
    );
};
export default MaintenanceBillingPlan;
