import { atom } from "recoil";

export const createTeamStepsState = atom({
    key: "createTeamStepsState",
    default: [
        {
            id: "create-team",
            title: "Creating a new contract team",
            description:
                "Lorem 1 ipsum dolor sit amet, consectetur adipiscing elit. Nunc sed risus ut elit nibh id. Nisl, praesent pulvinar.",
            isPrepared: false,
        },
        {
            id: "team-overview",
            title: "Creating a new contract team",
            description:
                "Lorem 1 ipsum dolor sit amet, consectetur adipiscing elit. Nunc sed risus ut elit nibh id. Nisl, praesent pulvinar.",
            isPrepared: false,
        },
        {
            id: "edit-team",
            title: "Edit contract team",
            description:
                "Lorem 2 ipsum dolor sit amet, consectetur adipiscing elit. Nunc sed risus ut elit nibh id. Nisl, praesent pulvinar.",
            isPrepared: false,
        },
    ],
});
