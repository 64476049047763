// Libs
import { Box, Typography } from "@mui/material";
import { navigate } from "gatsby";
import React from "react";

// Own components
import { SearchBar, Table } from "@components";

// Constants
import {
    ACCOUNT_STATUS,
    filterByCountry,
    HEADERS,
    ROWRENDERERCONST,
} from "@constants";

// Hooks
import {
    useAccountTypes,
    usePartners,
    usePermission,
    useSelectedCountry,
    useViewingOptions,
} from "@hooks";

// Types
import { SearchFilters, Permissions } from "@types";

// Utils
import { apiResponseCounter, constructQueryString } from "@utils";

/**
 * Partners
 */
const Partners = () => {
    /**
     * Selected team country isoCode
     */
    const { countryIsoCode } = useSelectedCountry();
    /**
     * Permissions
     */
    const { hasPermissionToEditDataTables }: Permissions = usePermission();

    /**
     * Hooks
     */
    const { viewingOptions, setViewingOptions } = useViewingOptions(
        ROWRENDERERCONST.PARTNERS,
    );

    /**
     * API
     */
    const {
        list: partnerList,
        loading: { fetching },
        search,
        load: reloadPartners,
    } = usePartners(`partners`, "partner|partners", false);

    const { list: accountTypes, loading: fetchingAccountTypes } =
        useAccountTypes();

    /**
     * Search handler
     */
    const onSearch = (filters: SearchFilters) => {
        const hasFilters = Object.values(filters).some(filter => filter.length);

        const params = constructQueryString(filters);
        if (hasFilters) search(`${params}`);
        else reloadPartners();
    };

    /**
     * Render
     */
    return (
        <>
            <Box mt={2} mb={4} display="flex" justifyContent="flex-end">
                <SearchBar
                    id={`dashboard-partners-overview-search-bar`}
                    filterKey={ROWRENDERERCONST.PARTNERS}
                    handleSearch={(filters: SearchFilters) => onSearch(filters)}
                    action={{
                        label: "New partner",
                        callback: () => navigate("/partner/create-partner/"),
                        hidden: !hasPermissionToEditDataTables,
                    }}
                    startDate={false}
                    endDate={false}
                    status={{ data: ACCOUNT_STATUS, loading: false }}
                    placeholder="Search by partner name"
                    viewingOptions={viewingOptions}
                    setViewingOptions={setViewingOptions}
                    accountTypes={{
                        data: accountTypes?.data,
                        loading: fetchingAccountTypes,
                    }}
                    hasPagination={true}
                />
            </Box>
            <Box mt={5}>
                <Box display="flex" alignItems="baseline" mb={4}>
                    <Typography variant="h2">Partners</Typography>

                    <Typography ml={1} variant="caption1">
                        {apiResponseCounter(
                            partnerList,
                            fetching,
                            "partner|partners",
                        )}
                    </Typography>
                </Box>
                <Table
                    id={`dashboard-partners-overview-list`}
                    headers={filterByCountry(HEADERS.PARTNERS, countryIsoCode)}
                    rows={partnerList?.data?.records || partnerList?.data}
                    loading={fetching}
                    type={ROWRENDERERCONST.PARTNERS}
                    viewingOptions={viewingOptions}
                    permissions={{ hasPermissionToEditDataTables }}
                    callbacks={{
                        onEditPartner: item =>
                            navigate(
                                `/partner/edit-partner/${item.accountId}/`,
                            ),
                        onExternalCodesEdit: item =>
                            navigate(
                                `/partner/external-codes/${item.accountId}/`,
                            ),
                    }}
                    emptyMsg="No partners found!"
                    hasPagination={partnerList?.data?.remainingPages}
                />
            </Box>
        </>
    );
};
export default React.memo(Partners);
