// Icons
import GridOn from "@mui/icons-material/GridOn";

// Libs
import { navigate } from "gatsby";
import React, {
    Fragment,
    useEffect,
    useLayoutEffect,
    useMemo,
    useState,
} from "react";

// Own components
import {
    Breadcrumbs,
    StepActions,
    Stepper,
    LoadingWrapper,
    FlowLayout,
    FlowAside,
    FlowBody,
    FlowContent,
    FlowFooter,
    ClaimDetails as _ClaimDetails,
} from "@components";

//Hooks
import { useGetClaimById } from "@hooks";

//Types
import type { Location } from "@types";
import { isArrayWithContent, isSuccessfulCall } from "@utils";

// Define the structure of a Note
type Note = {
    note: string;
    noteInfo: string;
    claimNoteId?: string;
};

// Notes is an array of Note or an empty array
type Notes = Array<Note> | [];

/**
 * Props type
 */
interface Props {
    location: Location;
    children?: React.ReactNode;
    claimId: string;
    id?: string;
    isEditClaim?: boolean;
}

/**
 * Claim details
 */
const ClaimDetails = ({
    claimId,
    location,
    id = "claim-details",
    isEditClaim,
}: Props) => {
    const [descriptions, setDescriptions] = useState<string[]>([]);
    const [files, uploadFiles] = useState([]);
    const [storedAttachments, setStoredAttachments] = useState<any>([]);
    const [storedNotes, setNotes] = useState<Notes>([]);

    const [editNote, setEditNote] = useState<{
        index: number;
        note: string;
        noteInfo: string;
    }>();

    const {
        download: downloadClaimData,
        load: loadClaimDetails,
        loading: {
            loading: fetchingClaimDetails,
            downloading,
            downloadingClaimDetails,
            downloadingClaimInvoice,
            editingNotes,
            uploadingAttachments,
            downloadingClaimAttachment,
        },
        list: claimDetails,
        error: { error: claimDetailsError },
        downloadClaimDetails,
        downloadClaimInvoice,
        downloadClaimAttachment,
        editNotes,
        uploadAttachments,
    }: any = useGetClaimById();

    useLayoutEffect(() => {
        if (!claimId) return;
        loadClaimDetails(claimId);
    }, [claimId]);

    useEffect(() => {
        // If it's not an edit claim, return

        if (
            !!claimDetails?.data?.notes &&
            isArrayWithContent(claimDetails?.data?.notes)
        ) {
            setNotes(claimDetails?.data?.notes);
        }

        if (
            !!claimDetails?.data?.attachments &&
            isArrayWithContent(claimDetails?.data?.attachments)
        ) {
            setStoredAttachments(claimDetails?.data?.attachments);
        }
    }, [claimDetails?.data?.notes, claimDetails?.data?.attachments]);

    useEffect(() => {
        if (isArrayWithContent(files) && !isArrayWithContent(descriptions)) {
            const arr = Array.from({ length: files.length }, () => "");
            setDescriptions(arr);
        }
    }, [files]);

    /**
     * Memoize data response
     */
    const data = useMemo(() => {
        if (!claimDetails?.data) return {};
        return claimDetails?.data;
    }, [claimDetails?.data]);

    /**
     * On submit
     */
    const onSubmit = () => {
        if (isEditClaim) {
            const mappedNotes = storedNotes.map(note => {
                return {
                    note: note.note,
                    noteInfo: note.noteInfo,
                    claimNoteId: note?.claimNoteId || undefined,
                };
            });

            const mapAttachmentsWithDescriptions = files.map(file => {
                return {
                    file,
                    fileDescription: descriptions[files.indexOf(file)],
                };
            });

            Promise.all([
                editNotes(claimId, mappedNotes),
                uploadAttachments(claimId, [
                    ...mapAttachmentsWithDescriptions,
                    ...storedAttachments,
                ]),
            ]).then(results => {
                if (
                    (!!results && isSuccessfulCall(results[0]?.status)) ||
                    isSuccessfulCall(results[1]?.status)
                ) {
                    if (isSuccessfulCall(results[1]?.status)) {
                        setDescriptions([]);
                        uploadFiles([]);
                    }
                    if (
                        isSuccessfulCall(results[0]?.status) &&
                        isSuccessfulCall(results[1]?.status)
                    ) {
                        navigate(`/claims/claim-details/${claimId}/`);
                    } else loadClaimDetails(claimId);
                }
            });

            return;
        }
        data?.priceCorrection?.priceCorrectionStatus
            ? navigate(`/dashboard/price-correction-claims/`)
            : navigate(`/dashboard/claims`);
    };

    /**
     *
     * @param index number
     * @param value string
     */
    const onDescriptionChange = (
        type: "delete" | "edit",
        index: number,
        value: string,
        attachmentsType?: "stored" | "newAttachments",
    ) => {
        if (attachmentsType === "newAttachments") {
            if (type === "edit") {
                const newDescriptions = [...descriptions];
                newDescriptions[index] = value;
                setDescriptions(newDescriptions);
            } else if (type === "delete") {
                const newDescriptions = [...descriptions];
                newDescriptions.splice(index, 1);
                setDescriptions(newDescriptions);
            }
        } else {
            if (type === "edit") {
                const newDescriptions = [...storedAttachments];
                newDescriptions[index].fileDescription = value;
                setStoredAttachments(newDescriptions);
            } else if (type === "delete") {
                const newDescriptions = [...storedAttachments];
                newDescriptions.splice(index, 1);
                setStoredAttachments(newDescriptions);
            }
        }
    };

    /**
     * On attachment remove
     */

    const onAttachmentRemove = (
        index: number,
        attachmentsType: "stored" | "newAttachments",
    ) => {
        if (attachmentsType === "newAttachments") {
            uploadFiles(prev => prev.filter((_, idx) => idx !== index));
        } else {
            setStoredAttachments(prev =>
                prev.filter((_, idx) => idx !== index),
            );
        }
        onDescriptionChange("delete", index, "", attachmentsType);
    };

    const disabled = useMemo(() => {
        if (!isEditClaim) return false;
        const filesHasNoDescription =
            storedAttachments.some(item => !item.fileDescription) ||
            descriptions.some(item => !item);
        return (
            editingNotes ||
            uploadingAttachments ||
            editNote?.index !== undefined ||
            filesHasNoDescription
        );
    }, [
        editNote?.index,
        editingNotes,
        isEditClaim,
        uploadingAttachments,
        storedAttachments,
        descriptions,
    ]);

    /**
     * Render
     */
    return (
        <Fragment>
            <Breadcrumbs
                id={`claim-details-breadcrumbs`}
                icon={<GridOn color="primary" />}
                title="Dashboard"
                location={location}
            />
            <FlowLayout>
                <LoadingWrapper
                    fullHeight
                    id={`view-contract-loading`}
                    loading={fetchingClaimDetails}
                    error={claimDetailsError}
                    NoEmptyPage
                >
                    <Fragment>
                        <FlowAside>
                            <Stepper
                                id={`claim-details-stepper`}
                                title={
                                    isEditClaim ? "Edit claim" : "Claim details"
                                }
                                subTitle={data?.fileName}
                            />
                        </FlowAside>

                        <FlowBody>
                            <Fragment>
                                <FlowContent>
                                    <_ClaimDetails
                                        id={id}
                                        isEditClaim={isEditClaim}
                                        files={files}
                                        uploadFiles={uploadFiles}
                                        storedNotes={storedNotes}
                                        disabled={
                                            editingNotes || uploadingAttachments
                                        }
                                        setNotes={setNotes}
                                        editNote={editNote}
                                        setEditNote={setEditNote}
                                        claimId={claimId}
                                        downloadClaimData={downloadClaimData}
                                        downloading={
                                            downloading ||
                                            downloadingClaimAttachment
                                        }
                                        downloadingClaimDetails={
                                            downloadingClaimDetails
                                        }
                                        downloadClaimDetails={
                                            downloadClaimDetails
                                        }
                                        downloadClaimInvoice={
                                            downloadClaimInvoice
                                        }
                                        downloadingClaimInvoice={
                                            downloadingClaimInvoice
                                        }
                                        onDescriptionChange={
                                            onDescriptionChange
                                        }
                                        onDownloadAttachment={idx =>
                                            downloadClaimAttachment(
                                                storedAttachments[idx]
                                                    ?.claimAttachmentId,
                                                storedAttachments[idx]
                                                    ?.fileName,
                                            )
                                        }
                                        descriptions={descriptions}
                                        storedAttachments={storedAttachments}
                                        onAttachmentRemove={onAttachmentRemove}
                                        {...data}
                                    />
                                </FlowContent>

                                <FlowFooter>
                                    <StepActions
                                        id={`claim-details-step-actions`}
                                        loading={
                                            editingNotes || uploadingAttachments
                                        }
                                        primaryButton={{
                                            text: isEditClaim
                                                ? "Submit"
                                                : "Return to overview",
                                            action: () => onSubmit(),
                                            isSubmitButton: true,

                                            disabled: disabled,
                                        }}
                                        tertiaryButton={{
                                            text: "Cancel",
                                            action: () => {
                                                navigate("/dashboard/claims");
                                                setEditNote(undefined);
                                                setNotes([]);
                                                uploadFiles([]);
                                            },
                                            hidden:
                                                !isEditClaim ||
                                                !!data?.priceCorrection
                                                    ?.priceCorrectionStatus,
                                        }}
                                    />
                                </FlowFooter>
                            </Fragment>
                        </FlowBody>
                    </Fragment>
                </LoadingWrapper>
            </FlowLayout>
        </Fragment>
    );
};

export default ClaimDetails;
