// Libs
import { CircularProgress, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import React, { Fragment } from "react";

// own components
import { FieldRenderer } from "@components";

//Hooks
import { usePartners } from "@hooks";

//Style
import * as style from "./style.module.scss";
import { splitStringWithDash } from "@utils";

/**
 * Props type
 */
interface Props {
    partnerId: string;
    id: string;
    canAddRegion?: boolean;
}

/**
 * Partner overview result
 */
const PartnerOverview = ({ partnerId, id, canAddRegion }: Props) => {
    /**
     * API
     */

    const {
        list: partner,
        loading: { fetching },
    } = usePartners(`partner/${partnerId}`, "partner|partners");

    /**
     * Render partner result
     */
    return (
        <Fragment>
            {fetching ? (
                <Box display="flex" alignItems="center" justifyContent="center">
                    <CircularProgress />
                </Box>
            ) : (
                <Box
                    height={1}
                    display="flex"
                    flexDirection="column"
                    className={style.wrapper}
                >
                    <Box mb={3}>
                        <Typography variant="h1">
                            Partner account was successfully created
                        </Typography>
                    </Box>
                    <Box className={style.card}>
                        <Grid
                            item
                            container
                            rowSpacing={3}
                            columnSpacing={{ xs: 0, md: 4 }}
                        >
                            <Grid item xs={12}>
                                <Typography variant="h3">
                                    Partner address
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FieldRenderer
                                    id={`${id}-partner-overview-homeCountry`}
                                    label="Country (*)"
                                    value={partner?.data?.homeCountry?.name}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FieldRenderer
                                    id={`${id}-partner-overview-accountAddress`}
                                    label="Street addess (*)"
                                    value={partner?.data?.accountAddress}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FieldRenderer
                                    id={`${id}-partner-overview-accountPostalCode`}
                                    label="Postal code or ZIP code (*)"
                                    value={partner?.data?.accountPostalCode}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FieldRenderer
                                    id={`${id}-partner-overview-accountCity`}
                                    label="City (*)"
                                    value={partner?.data?.accountCity}
                                />
                            </Grid>
                        </Grid>

                        <Grid
                            item
                            container
                            rowSpacing={3}
                            columnSpacing={{ xs: 0, md: 4 }}
                            mt={6}
                        >
                            <Grid item xs={12}>
                                <Typography variant="h3">
                                    Account details
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FieldRenderer
                                    id={`${id}-partner-overview-accountName`}
                                    label="Account name (*)"
                                    value={partner?.data?.accountName}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FieldRenderer
                                    id={`${id}-partner-overview-accountTypeName`}
                                    label="Account type (*)"
                                    value={
                                        partner?.data?.accountType
                                            .accountTypeName
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FieldRenderer
                                    id={`${id}-partner-overview-knowyoursupplierid`}
                                    label="Know Your Supplier ID (*)"
                                    value={partner?.data?.knowyoursupplierid}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FieldRenderer
                                    id={`${id}-partner-overview-active-status`}
                                    label="Status (*)"
                                    value={partner?.data?.accountStatus}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FieldRenderer
                                    id={`${id}-partner-overview-countryOfSale`}
                                    label="Country of sale (*)"
                                    value={partner?.data?.country?.name}
                                />
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <FieldRenderer
                                    id={`${id}-partner-overview-codsId`}
                                    label="Cods ID"
                                    value={partner?.data?.codsId}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <FieldRenderer
                                    id={`${id}-partner-overview-bank-type`}
                                    label="Bank type"
                                    value={partner?.data?.bankType}
                                />
                            </Grid>
                            {canAddRegion && (
                                <Fragment>
                                    <Grid item xs={12} md={6}>
                                        <FieldRenderer
                                            id={`${id}-partner-overview-codsId`}
                                            label="Trust account"
                                            value={
                                                partner?.data?.trustAccountCode
                                                    ?.accountName
                                            }
                                        />
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <FieldRenderer
                                            id={`${id}-partner-overview-cmu-region-code`}
                                            label="CMU region"
                                            showTooltip
                                            value={splitStringWithDash(
                                                partner?.data?.cmuRegionCode,
                                                [
                                                    "regionName",
                                                    "regionCode",
                                                    "type",
                                                ],
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <FieldRenderer
                                            id={`${id}-partner-overview-region-code`}
                                            label="Region"
                                            showTooltip
                                            value={splitStringWithDash(
                                                partner?.data?.regionCode,
                                                [
                                                    "regionName",
                                                    "regionCode",
                                                    "type",
                                                ],
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <FieldRenderer
                                            id={`${id}-partner-overview-region-1-code`}
                                            label="Sub region 1"
                                            showTooltip
                                            value={splitStringWithDash(
                                                partner?.data?.regionCode1,
                                                [
                                                    "regionName",
                                                    "regionCode",
                                                    "type",
                                                ],
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <FieldRenderer
                                            id={`${id}-partner-overview-region-2-code`}
                                            label="Sub region 2"
                                            showTooltip
                                            value={splitStringWithDash(
                                                partner?.data?.regionCode2,
                                                [
                                                    "regionName",
                                                    "regionCode",
                                                    "type",
                                                ],
                                            )}
                                        />
                                    </Grid>
                                </Fragment>
                            )}
                        </Grid>
                    </Box>
                </Box>
            )}
        </Fragment>
    );
};

export default React.memo(PartnerOverview);
