// Lib
import { Box, Typography } from "@mui/material";
import React, { useEffect, useImperativeHandle } from "react";
import { useRecoilState, useResetRecoilState } from "recoil";

// Own components
import { FormFooter, SearchBar, Table } from "@components";

//Constants
import { HEADERS, ROWRENDERERCONST } from "@constants";

// Custom hooks
import {
    useBrands,
    useOutcome,
    useContractPartners,
    useTeamTherapeuticArea,
    useUpdateStep,
    useProducts,
} from "@hooks";

// Atoms
import {
    contractSelectionState,
    orderSelectionState,
    createOutcomeStepsState,
} from "@atoms";

// Types
import type { FlowStep, Location, SearchFilters } from "@types";

/**
 * Utils
 */
import { apiResponseCounter, constructQueryString } from "@utils";

/**
 * Props
 */
interface Props {
    location?: Location;
    currentStep?: FlowStep;
    id?: string;
}

/**
 * Contract Selection
 */
const ContractSelection = React.forwardRef(
    ({ location, currentStep, id }: Props, ref) => {
        const restOrder = useResetRecoilState(orderSelectionState);
        /**
         * API
         */

        //Contracts
        const {
            list: contractResult,
            loading: contractResultLoading,
            search,
            reload: reloadContracts,
        } = useOutcome("contracts", "contract|contracts");

        //Partners
        const {
            getPartners,
            response: partners,
            loading: partnersLoading,
        } = useContractPartners(false);
        // Brands
        const { list: brands, loading: brandsLoading } = useBrands();

        // Therapeutic area
        const { list: therapeuticAreas, loading: therapeuticAreasLoading } =
            useTeamTherapeuticArea();

        //Products
        const {
            getProducts,
            response: product,
            loading: productLoading,
        } = useProducts();

        useEffect(() => {
            getProducts();
        }, []);

        // Contract selection state
        const [contractSelectionInitialState, updateContractSelectionState] =
            useRecoilState(contractSelectionState);

        // update step hook
        const updateStepValidation = useUpdateStep(
            location,
            createOutcomeStepsState,
        );

        /**
         * Form validation check
         */
        const canSaveState =
            currentStep?.isPrepared ||
            !!contractSelectionInitialState.reference;

        /**
         * Check if the current step is valid
         * then set isPrepared to true, otherwise set it false
         */
        useEffect(() => {
            updateStepValidation(canSaveState);
        }, [canSaveState, location]);

        /**
         * Save state and go to the next page (controlled by the layout)
         */
        useImperativeHandle(ref, () => ({
            updateState() {
                // Reset order if the user changes the contract after the selection of the order
                restOrder();
                updateContractSelectionState(contractSelectionInitialState);
            },
        }));

        const onSearch = (filters: SearchFilters) => {
            const hasFilters = Object.values(filters).some(
                filter => filter.length,
            );

            const params = constructQueryString(filters, true);
            if (hasFilters) search(`${params}`);
            else reloadContracts();
        };

        return (
            <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                height="100%"
            >
                <div>
                    <Box mb={7}>
                        <Typography variant="h2">Contract selection</Typography>
                    </Box>

                    <SearchBar
                        id={`${id}-contract-selection-search-bar`}
                        handleSearch={(filters: SearchFilters) =>
                            onSearch(filters)
                        }
                        simpleLayout
                        partners={{
                            data: partners?.data,
                            loading: partnersLoading,
                            onSearch: (query: string) => getPartners(query),
                        }}
                        products={{
                            data: product?.data?.records,
                            loading: productLoading,
                        }}
                        therapeuticAreas={{
                            data: therapeuticAreas?.data?.records,
                            loading: therapeuticAreasLoading,
                        }}
                        brands={{
                            data: brands?.data,
                            loading: brandsLoading,
                        }}
                        placeholder="Search by reference"
                    />
                    <Box display="flex" alignItems="baseline" mt={3}>
                        <Typography variant="h3">Search results</Typography>

                        <Typography ml={1} variant="caption1">
                            {apiResponseCounter(
                                contractResult,
                                contractResultLoading,
                                "contract|contracts",
                            )}
                        </Typography>
                    </Box>

                    <Table
                        id={`${id}-contract-selection-list`}
                        headers={HEADERS.OUTCOME_CONTRACT_SELECTION}
                        rows={contractResult?.data?.records}
                        loading={contractResultLoading}
                        type={ROWRENDERERCONST.OUTCOME_CONTRACT_SELECTION}
                        maxHeight="30rem"
                        callbacks={{
                            onSelect: row => {
                                updateContractSelectionState(row);
                            },
                        }}
                        selectedRow={contractSelectionInitialState?.id}
                        emptyMsg="No contracts found!"
                    />
                </div>

                <FormFooter
                    error={false}
                    textAlign="right"
                    id={`${id}-contract-selection-footer`}
                />
            </Box>
        );
    },
);

export default React.memo(ContractSelection);
