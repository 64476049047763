import { atom } from "recoil";

export const editContractStepsState = atom({
    key: "editContractStepsState",
    default: [
        {
            id: "edit-contract",
            title: "Edit contract",
            description: "",
            isPrepared: false,
        },
    ],
});

export const contractViewStepsState = atom({
    key: "contractViewStepsState",
    default: [
        {
            id: "view-contract",
            title: "View contract",
            description:
                "Lorem 1 ipsum dolor sit amet, consectetur adipiscing elit. Nunc sed risus ut elit nibh id. Nisl, praesent pulvinar.",
            isPrepared: false,
        },
    ],
});

export const duplicateContractStepsState = atom({
    key: "duplicateContractStepsState",
    default: [
        {
            id: "duplicate-contract",
            title: "Duplicate contract",
            description:
                "Lorem 1 ipsum dolor sit amet, consectetur adipiscing elit. Nunc sed risus ut elit nibh id. Nisl, praesent pulvinar.",
            isPrepared: false,
        },
    ],
});

export const uploadDocumentStepsState = atom({
    key: "uploadDocumentStepsState",
    default: [
        {
            id: "upload-file",
            title: "Upload file",
            description:
                "Lorem 1 ipsum dolor sit amet, consectetur adipiscing elit. Nunc sed risus ut elit nibh id. Nisl, praesent pulvinar.",
            isPrepared: false,
        },
    ],
});
