// Libs
import { TableCell, TableRow, Typography, Button } from "@mui/material";
import React from "react";

interface Row {
    productName: string;
    productId: string;
    salesOrg: string;
    sapSkuNo: string;
    brandName: string;
    brandId: string;
    active: boolean;
    country: { isoCode: string; name: string };
    productConditions: Array<{
        conditionTypeId: string;
        conditionName: string;
        conditionDescription: string;
        status: boolean;
        amount: number;
    }>;
}

/**
 * Props type
 */
interface Props {
    row: Row;
    rowIndex: number;
    callbacks: any;
    id: string;
    isColVisible: (col: string) => boolean;
}

/**
 * Products conditions
 */
const ProductsConditions = ({
    id,
    row,
    rowIndex,
    callbacks,
    isColVisible,
}: Props) => {
    return (
        <TableRow id={`${id}-row-${rowIndex}`}>
            {isColVisible("productName") && (
                <TableCell>
                    <Typography variant="caption2">
                        {row.productName}
                    </Typography>
                </TableCell>
            )}

            {isColVisible("salesOrg") && (
                <TableCell>
                    <Typography variant="caption2">{row.salesOrg}</Typography>
                </TableCell>
            )}
            {isColVisible("sapSkuNo") && (
                <TableCell>
                    <Typography variant="caption2">{row.sapSkuNo}</Typography>
                </TableCell>
            )}
            {isColVisible("status") && (
                <TableCell>
                    <Typography variant="caption2">
                        {row.active ? "Active" : "Inactive"}
                    </Typography>
                </TableCell>
            )}

            {isColVisible("country") && (
                <TableCell>
                    <Typography variant="caption2">
                        {row.country?.name}
                    </Typography>
                </TableCell>
            )}

            <TableCell align="right">
                <Button
                    variant="text"
                    color="primary"
                    onClick={() =>
                        callbacks?.onProductConditionsChange
                            ? callbacks.onProductConditionsChange(
                                  row?.productId,
                                  row?.productConditions,
                              )
                            : undefined
                    }
                >
                    <Typography variant="link">
                        {"Edit Product Conditions"}
                    </Typography>
                </Button>
            </TableCell>
        </TableRow>
    );
};

export default ProductsConditions;
