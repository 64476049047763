//Lib
import {
    Box,
    Typography,
    FormControl,
    FormControlLabel,
    RadioGroup,
    Radio,
} from "@mui/material";

import React, { Fragment } from "react";

// Own components
import { Dialog } from "@components";

/**
 * Props
 */
interface Props {
    openDialog: boolean;
    onConfirm: () => void;
    loading: boolean;
    onReset: () => void;
    className?: string;
    values: {
        approved: string;
        rejectionReason: string;
    };
    onBlur: (event: React.SyntheticEvent) => void;
    onChange: any;
    id: string;
    name: string;
    knowyoursupplierid: string;
    address: string;
}

/**
 * Partner Data Approval Task
 */
const PartnerDataApprovalTask = ({
    id,
    openDialog,
    loading,
    onReset,
    className,
    values,
    onBlur,
    onChange,
    onConfirm,
    name,
    address,
    knowyoursupplierid,
}: Props) => {
    return (
        <Fragment>
            <Dialog
                id={`${id}-tasks-approval-partner-data-approval-task`}
                title={"Approve partner data"}
                open={openDialog}
                message={
                    <Fragment>
                        {values?.approved === "true" && (
                            <Typography variant="subtitle2" color="black">
                                You are about to approve the creation/update of
                                a partner with following details . Are you sure
                                you want to continue?
                                <Typography variant="body2" component="span">
                                    {` (${name}, ${address}, ${knowyoursupplierid})`}
                                </Typography>
                                . The data in this file will be added in SCP.
                                Are you sure you want to continue?
                            </Typography>
                        )}

                        {values?.approved === "false" && (
                            <Typography variant="subtitle2" color="black">
                                You are about to reject the creation/update of a
                                partner with following details
                                <Typography variant="body2" component="span">
                                    {` (${name}, ${address}, ${knowyoursupplierid})`}
                                </Typography>
                                . Are you sure you want to continue?
                            </Typography>
                        )}
                    </Fragment>
                }
                primaryButton={{
                    text: "Confirm",
                    action: onConfirm,
                    loading: loading,
                }}
                secondaryButton={{
                    text: "Cancel",
                    action: onReset,
                }}
            />

            <Box mb={3} className={className}>
                <form noValidate onBlur={onBlur}>
                    <FormControl>
                        <RadioGroup
                            id={`${id}-partner-data-approval`}
                            value={values?.approved}
                            onChange={onChange}
                            name="approved"
                        >
                            <FormControlLabel
                                value={"true"}
                                id={`${id}-task-details-approve-partner-data`}
                                control={<Radio size="small" />}
                                label={
                                    <Typography
                                        variant="body2"
                                        color="dark.light"
                                    >
                                        I approve this decision
                                    </Typography>
                                }
                            />

                            <FormControlLabel
                                id={`${id}-task-details-reject-partner-data`}
                                value={"false"}
                                control={<Radio size="small" />}
                                label={
                                    <Typography
                                        variant="body2"
                                        color="dark.light"
                                    >
                                        I reject this decision
                                    </Typography>
                                }
                            />
                        </RadioGroup>
                    </FormControl>
                </form>
            </Box>
        </Fragment>
    );
};

export default React.memo(PartnerDataApprovalTask);
