// extracted by mini-css-extract-plugin
export var boxWrapper = "style-module--boxWrapper--b570e";
export var error = "#ff3131";
export var icon = "style-module--icon--d6d29";
export var infoTitle = "style-module--infoTitle--56dc7";
export var infobox = "style-module--infobox--3f3f2";
export var primary = "#036";
export var secondary = "#019cdc";
export var textfont = "\"Verdana Pro\"";
export var titlefont = "\"Karbon\"";
export var warningBox = "style-module--warningBox--048ad";
export var warningColor = "style-module--warningColor--50e3c";
export var white = "#fff";