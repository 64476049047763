import { atom } from "recoil";

/**
 * Contract Type State
 */
export const financialConditionsState = atom({
    key: "financialConditionsState",
    default: {
        conditions: {},
    },
});
