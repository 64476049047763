// extracted by mini-css-extract-plugin
export var content = "style-module--content--140d8";
export var error = "#ff3131";
export var item = "style-module--item--ed333";
export var primary = "#036";
export var progress = "style-module--progress--1dc4b";
export var secondary = "#019cdc";
export var status = "style-module--status--6ec6e";
export var textfont = "\"Verdana Pro\"";
export var titlefont = "\"Karbon\"";
export var white = "#fff";