import { atom } from "recoil";

/**
 * Contract review
 */
export const contractReviewState = atom({
    key: "contractReviewState",
    default: {
        comments: "",
    },
});
