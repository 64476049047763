import * as yup from "yup";

/**
 * Create team schema
 */
export const createTeamSchema = yup.object().shape({
    countries: yup.array().min(1),
    therapeuticAreaIds: yup.array().min(1),
    teamName: yup.string().required(),
    minimumContractReviewers: yup.number().min(1).required(),
    minimumFinancialApprovers: yup.number().min(1).required(),
    users: yup.array().min(1).required(),
});
