// Libs
import { Box, Typography } from "@mui/material";
import { navigate } from "gatsby";
import React, { Fragment, useMemo } from "react";

// Own components
import { SearchBar, Table } from "@components";

// Constants
import { HEADERS, ROWRENDERERCONST } from "@constants";

// Hooks
import {
    useTasks,
    useViewingOptions,
    useGetTaskDefinitionKeys,
    usePermission,
} from "@hooks";

// Types
import { SearchFilters, Permissions } from "@types";

// Utils
import {
    apiResponseCounter,
    constructQueryString,
    isArrayWithContent,
    stringTransformer,
} from "@utils";

/**
 * Props type
 */
interface Props {
    id: string;
}

/**
 * Ongoing Tasks
 */
const OngoingTasks = ({ id }: Props) => {
    /**
     * API
     */

    const {
        loading: { listLoading },
        response: { list },
        error: { listError },
        search: searchTasks,
    } = useTasks(false);

    const { loading: fetchingTaskDefinitionKeys, list: taskDefinitionKeys } =
        useGetTaskDefinitionKeys();

    /**
     * Hooks
     */
    // Viewing options
    const { viewingOptions, setViewingOptions } = useViewingOptions(
        ROWRENDERERCONST.ONGOING_TASKS,
    );

    // Permissions
    const { hasPermissionToEditDataTables }: Permissions = usePermission();

    /**
     * Map Task definitions key
     */

    const mappedTaskDefinitionKeys = useMemo(() => {
        if (!isArrayWithContent(taskDefinitionKeys?.data?.records)) return [];

        return taskDefinitionKeys?.data?.records?.map(key => {
            return {
                keyName: stringTransformer(key),
                keyId: key,
            };
        });
    }, [taskDefinitionKeys]);

    /**
     * Search handler
     */
    const onSearch = (searchFilters: SearchFilters) => {
        const params = constructQueryString(searchFilters);
        searchTasks(params);
    };

    /**
     * Render
     */
    return (
        <Fragment>
            <Box mt={5} mb={4} display="flex" justifyContent="flex-end">
                <SearchBar
                    id={`${id}-task-overview`}
                    filterKey={ROWRENDERERCONST.ONGOING_TASKS}
                    placeholder="Search by contract reference"
                    action={{ hidden: true }}
                    viewingOptions={viewingOptions}
                    handleSearch={(filters: SearchFilters) => onSearch(filters)}
                    startDate={false}
                    showTasksTypeFilter
                    endDate={false}
                    setViewingOptions={setViewingOptions}
                    taskDefinitionKeys={{
                        loading: fetchingTaskDefinitionKeys,
                        data: mappedTaskDefinitionKeys,
                    }}
                    disableSelectionFilters={listLoading}
                />
            </Box>
            <Box mt={5}>
                <Box display="flex" alignItems="baseline" mb={4}>
                    <Typography variant="h2">Ongoing tasks</Typography>

                    <Typography ml={1} variant="caption1">
                        {apiResponseCounter(list, listLoading, "task|tasks")}
                    </Typography>
                </Box>
                <Table
                    id={`${id}-task-overview`}
                    headers={HEADERS.ONGOING_TASKS}
                    rows={list?.data?.records || []}
                    loading={listLoading}
                    error={listError}
                    type={ROWRENDERERCONST.ONGOING_TASKS}
                    viewingOptions={viewingOptions}
                    callbacks={{
                        viewTaskDetails: (taskId: string) =>
                            navigate(`/tasks/task-details/${taskId}/`),
                    }}
                    permissions={{ hasPermissionToEditDataTables }}
                    emptyMsg="No tasks found"
                />
            </Box>
        </Fragment>
    );
};
export default OngoingTasks;
