// Lib
import EditIcon from "@mui/icons-material/Edit";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import React, { Fragment, useState, useCallback, useEffect } from "react";

// Own components
import { Card, FieldRenderer, Modal, Table } from "@components";

// Types
import { Model, Category, Status } from "@types";

// Utils
import { isArrayWithContent } from "@utils";

// Constants
import { HEADERS, ROWRENDERERCONST } from "@constants";

/**
 * Props
 */
interface Props {
    hideEdit?: boolean;
    disableEdit?: boolean;
    callbacks?: {
        onEditClick?: (path: string) => void;
        onEditContractItem?: (value: Record<string, Category[]>) => void;
        setDisableSaveButton?: (value: boolean) => void;
    };
    id: string;
    model?: Model & { id?: string };
    shrinkColumns?: boolean;
    categories?: Array<Category>;
    contractType?: "VOLUME" | "PERFORMANCE";
    isEditContract?: boolean;
    modelCategories?: {
        load: () => Promise<any>;
        loading: boolean;
        list: Array<any>;
    };
    isCreateContract?: boolean;
    status?: Status;
}

/**
 * Contract classification review
 */
const ContractClassification: React.FC<Props> = ({
    id,
    hideEdit = false,
    callbacks,
    model,
    shrinkColumns,
    categories,
    contractType,
    isEditContract,
    modelCategories,
    disableEdit,
    isCreateContract,
    status,
}) => {
    const [openModal, toggleModal] = useState(false);
    const [categoryList, setCategories] = useState<Category[]>([]);

    /**
     * Update categories state
     */
    const handleUpdateCategories = category => {
        const copyCats: Category[] = [...categoryList];

        const foundCatIndex = isArrayWithContent(copyCats)
            ? copyCats.findIndex(
                  (item: Category) =>
                      category?.categoryLabel === item?.categoryLabel,
              )
            : -1;

        if (foundCatIndex === -1) return {};

        if (foundCatIndex > -1) {
            copyCats[foundCatIndex] = {
                ...category,
            };

            setCategories(copyCats);
        }
    };

    /**
     * Find Selected Category
     */
    const findSelectedCategory = useCallback(
        (categoryName: string) => {
            if (!categoryName || !isArrayWithContent(categoryList))
                return false;

            const foundCat = categoryList.find(
                (item: Category) => item.categoryName === categoryName,
            );

            return foundCat;
        },
        [categoryList],
    );

    useEffect(() => {
        if (!isEditContract || !modelCategories) return;

        if (!openModal) {
            setCategories([]);
            callbacks?.setDisableSaveButton &&
                callbacks?.setDisableSaveButton(false);
        } else {
            modelCategories.load();
            callbacks?.setDisableSaveButton &&
                callbacks?.setDisableSaveButton(true);
        }
    }, [openModal]);

    /**
     * Edit categories handler
     */
    const onEditCategoriesClick = () => {
        if (isCreateContract) {
            !!callbacks?.onEditClick && callbacks.onEditClick("category-tags");
        } else {
            if (!!categories && isArrayWithContent(categories))
                setCategories(categories);
            toggleModal(true);
        }
    };

    /**
     * Disable category (Contract type)
     */
    const disableCategoryByType = useCallback(categories => {
        if (!isArrayWithContent(categories)) return;

        return !!categories.find(
            el =>
                el.categoryId === "DE_CONTRACT_TYPE_PRICE" ||
                el.categoryId === "GB_CONTRACT_TYPE_PRICE",
        );
    }, []);

    /**
     * Render
     */
    return (
        <Fragment>
            <Modal
                open={openModal}
                id={`${id}-classification-cat-tags`}
                title={"Category tags"}
                onClose={() => {
                    toggleModal(false);
                    setCategories([]);
                }}
                primaryButton={{
                    action: () => {
                        if (!callbacks?.onEditContractItem) return;

                        callbacks?.onEditContractItem({
                            categories: categoryList,
                        });
                        toggleModal(false);
                    },
                    text: "Save",
                    disabled:
                        modelCategories?.loading ||
                        !isArrayWithContent(categoryList),
                }}
                secondaryButton={{
                    action: () => {
                        setCategories([]);
                        toggleModal(false);
                    },
                    text: "Cancel",
                }}
            >
                <Table
                    id={`${id}-classification-edit-categories`}
                    headers={HEADERS.CONTRACT_CATEGORY}
                    rows={modelCategories?.list || []}
                    loading={modelCategories?.loading || false}
                    type={ROWRENDERERCONST.CONTRACT_CATEGORY}
                    callbacks={{
                        onCategorySelect: (category: any) => {
                            handleUpdateCategories(category);
                        },
                        findSelectedCategory: (categoryName: string) =>
                            findSelectedCategory(categoryName),
                        disableCategoryByType,
                    }}
                    maxHeight="30rem"
                    // disabled={fetching}
                    emptyMsg="No categories found!"
                />
            </Modal>
            <Card
                id={`${id}-card`}
                title="Contract classification"
                firstItemOnScreen
            >
                {!!contractType && (
                    <Grid
                        item
                        xs={12}
                        container
                        rowSpacing={3}
                        columnSpacing={4}
                    >
                        <Grid item xs={12} md={shrinkColumns ? 3 : 6}>
                            <FieldRenderer
                                id={`${id}-contract-type`}
                                label="Contract type"
                                value={
                                    contractType === "VOLUME"
                                        ? "Volume based"
                                        : "Performance based"
                                }
                            />
                        </Grid>
                        {!!status && (
                            <Grid item xs={12} md={shrinkColumns ? 3 : 6}>
                                <FieldRenderer
                                    id={`${id}-contract-status`}
                                    label="Status"
                                    value={status?.name}
                                />
                            </Grid>
                        )}
                    </Grid>
                )}

                {(!!model?.datamodelId || !!model?.id) && (
                    <Fragment>
                        <Box my={4}>
                            <Divider />
                        </Box>

                        <Box
                            display="flex"
                            justifyContent={"space-between"}
                            alignItems="center"
                            mb={4}
                        >
                            <Typography variant="h3">Model</Typography>

                            {!hideEdit &&
                                !isEditContract &&
                                isCreateContract && (
                                    <IconButton
                                        id={`${id}-edit-category-tags-btn`}
                                        color="primary"
                                        aria-label="edit button"
                                        component="span"
                                        onClick={() =>
                                            !!callbacks?.onEditClick &&
                                            callbacks.onEditClick("model")
                                        }
                                    >
                                        <EditIcon fontSize="small" />
                                    </IconButton>
                                )}
                        </Box>

                        <Grid
                            item
                            xs={12}
                            container
                            rowSpacing={3}
                            columnSpacing={4}
                        >
                            <Grid item xs={12} md={shrinkColumns ? 3 : 6}>
                                <FieldRenderer
                                    id={`${id}-model-name`}
                                    label="Model name"
                                    value={model?.name}
                                />
                            </Grid>

                            <Grid item xs={12} md={shrinkColumns ? 3 : 6}>
                                <FieldRenderer
                                    id={`${id}-model-dataSource`}
                                    label="Data source"
                                    value={model?.dataSource}
                                />
                            </Grid>

                            {model?.priceSource && (
                                <Grid item xs={12} md={shrinkColumns ? 3 : 6}>
                                    <FieldRenderer
                                        id={`${id}-model-priceSource`}
                                        label="Price source"
                                        value={model?.priceSource}
                                    />
                                </Grid>
                            )}
                        </Grid>
                    </Fragment>
                )}

                {!!categories && isArrayWithContent(categories) && (
                    <Fragment>
                        <Box my={4}>
                            <Divider />
                        </Box>

                        <Box
                            display="flex"
                            justifyContent={"space-between"}
                            alignItems="center"
                            mb={4}
                        >
                            <Typography variant="h3">Categories</Typography>

                            {(isEditContract || isCreateContract) && (
                                <IconButton
                                    id={`${id}-edit-model-btn`}
                                    color="primary"
                                    aria-label="edit button"
                                    component="span"
                                    disabled={disableEdit}
                                    onClick={onEditCategoriesClick}
                                >
                                    <EditIcon fontSize="small" />
                                </IconButton>
                            )}
                        </Box>

                        <Grid
                            item
                            xs={12}
                            container
                            rowSpacing={3}
                            columnSpacing={4}
                        >
                            {categories.map((cat, index) => (
                                <Grid
                                    item
                                    xs={12}
                                    key={index}
                                    md={shrinkColumns ? 3 : 6}
                                >
                                    <FieldRenderer
                                        key={index}
                                        id={`${id}-${index}`}
                                        label={cat.categoryLabel}
                                        value={cat.categoryValue}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </Fragment>
                )}
            </Card>
        </Fragment>
    );
};

export default React.memo(ContractClassification);
