// lib
import { Grid } from "@mui/material";
import React from "react";

interface Props {
    children: React.ReactNode;
}

/**
 * FlowFooter
 */
const FlowFooter: React.FC<Props> = ({ children }) => {
    /**
     * Render
     */
    return (
        <Grid item xs={12} pb={7}>
            {children}
        </Grid>
    );
};
export default React.memo(FlowFooter);
