//import libs
import { TableCell, TableRow, Typography } from "@mui/material";
import React from "react";

// Import utils
import { Model, GroupedCategories } from "@types";

// Own  components
import { Select } from "@components";

interface Row extends GroupedCategories {
    categoryLabel: string;
    categoryName: string;
    categoryId: string;
    datamodel: Model;
}

/**
 * Props type
 */
interface Props {
    row: Row;
    rowIndex: number;
    id: string;
    callbacks: any;
}

/**
 * Contract category row
 */
const ContractCategoryRow = ({ id, row, rowIndex, callbacks }: Props) => {
    return (
        <TableRow
            id={`${id}-row-${rowIndex}`}
            sx={{
                "&:last-child td, &:last-child th": {
                    border: 0,
                },
            }}
        >
            <TableCell>
                <Typography variant="caption2">
                    {row.categoryLabel || "-"}
                </Typography>
            </TableCell>

            <TableCell>
                <Select
                    id={`${id}-row-${rowIndex}-select-category`}
                    value={callbacks?.findSelectedCategory(row.categoryName)}
                    name="categoryId"
                    disabled={
                        row.categories?.length === 1 ||
                        (!!callbacks?.disableCategoryByType &&
                            callbacks?.disableCategoryByType(row.categories))
                    }
                    onChange={(_, index) => {
                        callbacks.onCategorySelect(
                            {
                                ...row.categories[index],
                                categoryLabel: row.categoryLabel,
                                categoryName: row.categoryName,
                            },
                            rowIndex,
                        );
                    }}
                    menuItemLabel={"categoryValue"}
                    menuItemId={"categoryId"}
                    list={row.categories}
                />
            </TableCell>
        </TableRow>
    );
};

export default ContractCategoryRow;
