import { Model } from "@types";
import { atom } from "recoil";

/**
 * select model state
 */
export const selectModelState = atom({
    key: "selectModelState",
    default: {
        datamodelId: "",
        name: "",
        priceSource: "",
        dataSource: "",
        allowedTotalUnitsTolerance: undefined,
        productContext: undefined,
        accountContext: undefined,
        regionContext: undefined,
        currency: "",
        type: "",
    } as Model,
});
