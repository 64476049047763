//import libs
import { Radio, TableCell, TableRow, Typography } from "@mui/material";
import React from "react";

//utils
import { displayDate } from "@utils";
//style
import * as style from "./style.module.scss";

interface Row {
    orderId: string;
    productName: string;
    infusionDate: string;
    treatmentSite: string;
    salessystemOrderId: string;
    extContractRef: string;
    orderStatus: string;
}

interface Props {
    row: Row;
    rowIndex: number;
    id: string;
    callbacks: any;
    selectedRow: string[];
}

const OutcomeOrderSelectionRow = ({
    row,
    rowIndex,
    id,
    callbacks,
    selectedRow,
}: Props) => {
    return (
        <TableRow
            className={style.cursor}
            selected={selectedRow[0] === row.orderId}
            id={`${id}-row-${rowIndex}`}
            onClick={event => {
                const target = event.target as HTMLInputElement;
                callbacks.onSelect(row, parseInt(target.value));
            }}
        >
            <TableCell width="4.6875rem">
                <Radio
                    id={`${id}-row-${rowIndex}-outcome-order`}
                    onChange={event => {
                        callbacks.onSelect(row, parseInt(event.target.value));
                    }}
                    checked={selectedRow[0] === row.orderId}
                    value={rowIndex}
                    inputProps={{ "aria-label": `${id}-row-${rowIndex}` }}
                    required
                />
            </TableCell>
            <TableCell>
                <Typography variant="caption2">{row.productName}</Typography>
            </TableCell>
            <TableCell>
                <Typography variant="caption2">
                    {displayDate(row.infusionDate)}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography variant="caption2">{row.treatmentSite}</Typography>
            </TableCell>
            <TableCell>
                <Typography variant="caption2">
                    {row.salessystemOrderId}
                </Typography>
            </TableCell>
        </TableRow>
    );
};

export default OutcomeOrderSelectionRow;
