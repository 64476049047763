// libs
import { Box, Typography, IconButton } from "@mui/material";
import React from "react";

// Styles
import * as style from "./style.module.scss";

// Types
import { Button } from "@types";
import classnames from "classnames";

type Props = {
    title?: string;
    id: string;
    action?: Button;
    children?: React.ReactNode;
    firstItemOnScreen?: boolean;
    className?: string;
};

/**
 * Card
 */
const Card = ({
    title,
    id,
    action,
    children,
    firstItemOnScreen = false,
    className,
}: Props) => (
    <Box
        className={classnames(
            style.card,
            {
                [style.firstItem]: firstItemOnScreen,
            },
            className,
        )}
        id={`${id}-wrapper`}
    >
        {(!!title || !!action) && (
            <Box
                display="flex"
                justifyContent={"space-between"}
                alignItems="center"
                mb={4}
            >
                <Typography variant="h3">{title}</Typography>
                {!action?.hidden && (
                    <IconButton
                        id={`${id}-action-btn`}
                        color="primary"
                        aria-label="action button"
                        component="span"
                        disabled={action?.disabled}
                        onClick={action?.action}
                    >
                        {action?.icon}
                    </IconButton>
                )}
            </Box>
        )}

        {children}
    </Box>
);

export default React.memo(Card);
