// Libs
import { Box, Typography } from "@mui/material";
import { useFormik } from "formik";
import React, {
    Fragment,
    useEffect,
    useImperativeHandle,
    useState,
} from "react";
import { useRecoilValue } from "recoil";

// Own components
import {
    CreatePartnerForm,
    FormFooter,
    InfoBox,
    LoadingWrapper,
} from "@components";

// Constants
import { ACCOUNT_DETAILS_STATUS } from "@constants";

//custom hooks
import {
    useFormValidation,
    useAccountTypes,
    useUpdateStep,
    useTeamCountries,
    useAllCountries,
    useContractPartners,
    useRegion,
} from "@hooks";

// Recoil
import { createPartnerState, createPartnerStepsState } from "@atoms";

// Schemas
import { createPartnerSchema } from "@schemas";

// Types
import { Location } from "@types";

// Utils
import { isArrayWithContent, constructQueryString } from "@utils";

/**
 * Props type
 */
interface Props {
    location: Location;
    isEditMode?: boolean;
    partner?: any;
    id?: string;
    teamCountryIsoCode?: string;
    canAddRegion?: boolean;
}

/**
 * Create Partner
 */
const CreatePartner = React.forwardRef(
    (
        {
            location,
            isEditMode,
            id,
            partner,
            teamCountryIsoCode,
            canAddRegion,
        }: Props,
        ref,
    ) => {
        // Partners
        const {
            getPartners,
            response: partners,
            loading: partnersLoading,
        } = useContractPartners();

        // Region

        // Regions CRUD
        const {
            list: { regions: cmuTypeRegions },
            loading: { fetching: fetchingCMUTypeRegions },
            load: loadCMUTypeRegions,
        } = useRegion(false, "CMU");

        // Regions CRUD
        const {
            list: { regions: regionTypeRegion },
            loading: { fetching: fetchingRegionTypeRegion },
            load: loadRegionTypeRegion,
        } = useRegion(false, "REGION");

        // Regions CRUD
        const {
            list: { regions: subRegion1TypeRegion },
            loading: { fetching: fetchingSubRegion1TypeRegion },
            load: loadSubRegion1TypeRegion,
        } = useRegion(false, "SUB-REG-1");

        // Regions CRUD
        const {
            list: { regions: subRegion2TypeRegion },
            loading: { fetching: fetchingSubRegion2TypeRegion },
            load: loadSubRegion2TypeRegion,
        } = useRegion(false, "SUB-REG-2");

        /**
         * update step
         */
        const updateStepValidation = useUpdateStep(
            location,
            createPartnerStepsState,
        );

        /**
         * Create partner state
         */
        const initialPartner = useRecoilValue(createPartnerState);
        const [partnerValues, setPartnerValues] = useState(initialPartner);

        const { list: accountTypes, loading: fetchingAccountTypes } =
            useAccountTypes();

        const { loading: countryLoading, list: countries } = useTeamCountries();

        const { loading: allCountryLoading, list: allCountries } =
            useAllCountries();

        const accountStatusList = ACCOUNT_DETAILS_STATUS;

        /**
         * Formik state
         */

        const formik = useFormik({
            initialValues: partnerValues,
            validationSchema: createPartnerSchema,
            enableReinitialize: true,
            validateOnMount: true,
            onSubmit: () => undefined,
        });

        useEffect(() => {
            if (!canAddRegion) return;
            loadCMUTypeRegions();
            loadRegionTypeRegion();
            loadSubRegion1TypeRegion();
            loadSubRegion2TypeRegion();
        }, [canAddRegion]);

        useEffect(() => {
            if (
                !isArrayWithContent(countries?.data?.records) ||
                !isArrayWithContent(allCountries?.data?.records)
            )
                return;

            // Clone partner to edit values
            if (partner?.data) {
                const accountStatus = accountStatusList.find(
                    c => c.value === partner?.data?.accountStatus,
                );

                const homeCountry = allCountries?.data?.records?.find(
                    c =>
                        c.countryIsoCode ===
                        partner?.data?.homeCountry?.isoCode,
                );

                const countryIsoCode = countries?.data?.records?.find(
                    c => c.countryIsoCode === partner?.data?.country?.isoCode,
                );

                setPartnerValues({
                    ...partner?.data,
                    homeCountry: {
                        // keep this order because when we stringify it, we need to match the order of all countries response
                        country: homeCountry?.country,
                        countryIsoCode: homeCountry?.countryIsoCode,
                    },
                    countryIsoCode: {
                        // keep this order because when we stringify it, we need to match the order of countries response
                        countryIsoCode: countryIsoCode?.countryIsoCode,
                        country: countryIsoCode?.country,
                    },
                    accountStatus,
                });
            } else {
                const diffCountry = countries?.data?.records[0];
                // Initialize country of sale with the first country in the list
                formik.setFieldValue("countryIsoCode", {
                    // keep this order because when we stringify it, we need to match the order of countries response
                    countryIsoCode: diffCountry?.countryIsoCode,
                    country: diffCountry?.country,
                });
            }
        }, [
            partner?.data,
            countries?.data?.records,
            accountStatusList,
            allCountries?.data?.records,
        ]);

        /**
         * Form validation
         */

        const { isValidForm, hasErrors } = useFormValidation(
            formik.errors,
            formik.touched,
        );

        /**
         * Form validation check
         */
        const canSubmit = isValidForm;

        /**
         * Save state (controlled by the layout)
         */
        useImperativeHandle(ref, () => ({
            updateState() {
                return formik.values;
            },
        }));

        /**
         * Check if the current step is valid => set isPrepared to true, otherwise set it false
         */
        useEffect(() => {
            if (location) {
                updateStepValidation(canSubmit);
            }
        }, [canSubmit]);

        /**
         * Render
         */
        return (
            <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                height={1}
            >
                <LoadingWrapper
                    id={`create-partner-create-form-loading`}
                    loading={
                        fetchingAccountTypes ||
                        countryLoading ||
                        allCountryLoading
                    }
                    fullHeight
                >
                    <div>
                        <Box mb={6}>
                            <Typography variant="h2">
                                Partner details
                            </Typography>
                        </Box>

                        <Fragment>
                            {isEditMode && !!partner?.data?.sapAccountCode && (
                                <Box my={5}>
                                    <InfoBox
                                        id={`${id}-info-box`}
                                        message={
                                            <Typography>
                                                The data for this partner is
                                                stored externally in SAP, with
                                                the exception of some fields.
                                            </Typography>
                                        }
                                        warning
                                    />
                                </Box>
                            )}
                            <CreatePartnerForm
                                id={`${id}-create-new-partner-fox`}
                                values={formik.values}
                                errors={formik.errors}
                                touched={formik.touched}
                                onBlur={formik.handleBlur}
                                setFieldValue={formik.setFieldValue}
                                setTouched={formik.setTouched}
                                handleChange={formik.handleChange}
                                handleBlur={formik.handleBlur}
                                canAddRegion={canAddRegion}
                                disabled={!!partnerValues.sapAccountCode}
                                accountStatusList={accountStatusList}
                                accountTypes={{
                                    data: accountTypes?.data,
                                    loading: fetchingAccountTypes,
                                }}
                                countryOfSaleList={{
                                    data: countries?.data?.records,
                                    loading: countryLoading,
                                }}
                                countries={{
                                    data: allCountries?.data?.records,
                                    loading: allCountryLoading,
                                }}
                                cmuTypeRegions={{
                                    data: cmuTypeRegions?.data?.records || [],
                                    fetching: fetchingCMUTypeRegions,
                                }}
                                regionTypeRegions={{
                                    data: regionTypeRegion?.data?.records || [],
                                    fetching: fetchingRegionTypeRegion,
                                }}
                                subRegion1TypeRegion={{
                                    data:
                                        subRegion1TypeRegion?.data?.records ||
                                        [],
                                    fetching: fetchingSubRegion1TypeRegion,
                                }}
                                subRegion2TypeRegion={{
                                    data:
                                        subRegion2TypeRegion?.data?.records ||
                                        [],
                                    fetching: fetchingSubRegion2TypeRegion,
                                }}
                                partners={{
                                    data: partners?.data?.partners,
                                    loading: partnersLoading,
                                    onSearch: (query: string) => {
                                        getPartners(
                                            `${query}${constructQueryString({
                                                countryIsoCode:
                                                    teamCountryIsoCode,
                                            })}`,
                                        );
                                    },
                                }}
                            />
                        </Fragment>
                    </div>
                    <FormFooter
                        id={`${id}-create-new-partner-footer`}
                        error={hasErrors}
                        textAlign="right"
                    />
                </LoadingWrapper>
            </Box>
        );
    },
);

export default CreatePartner;
