// Lib
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React, { useEffect, useMemo, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";

// Own components
import { Table, Dialog } from "@components";

//custom hooks
import { useGetContractModel, useUpdateStep } from "@hooks";

// Atoms
import { selectCategoriesState, selectModelState } from "@atoms";

// Types
import type { FlowStep, Location, Model as ModelType } from "@types";

// Constants
import { HEADERS, ROWRENDERERCONST } from "@constants";

// Utils
import { isArrayWithContent } from "@utils";

/**
 * Props type
 */
interface Props {
    location?: Location;
    currentStep?: FlowStep;
    flowState?: Array<FlowStep>;
    resetFlow?: () => void;
}

/**
 * Select Model
 */
const Model = ({ location, flowState, resetFlow }: Props) => {
    const [resetFlowWarning, setResetFlowWarning] = useState<
        ModelType | undefined
    >(undefined);

    /**
     * Get data model list
     */
    const { list: dataModelList, loading: fetching } =
        useGetContractModel("VOLUME");

    /**
     * Recoil states
     */
    // Model state
    const [selectedModel, updateModel] = useRecoilState(selectModelState);

    // Categories state
    const categories = useRecoilValue(selectCategoriesState);

    /**
     * Check if the current step is valid
     * then set isPrepared to true, otherwise set it false
     */
    /**
     *  Update step hook
     */
    const updateStepValidation = useUpdateStep(location, flowState);

    /**
     * Save state and go to the next page (controlled by the layout)
     */
    useEffect(() => {
        updateStepValidation(!!selectedModel?.datamodelId);
    }, [selectedModel]);

    /**
     * Adjust model header with Radio button header
     */
    const modelHeaderWithRadioBtn = useMemo(() => {
        const headers = [...HEADERS.MODEL];

        headers.unshift({
            header: "",
            viewingOption: "",
            rowKey: "",
        });

        return headers;
    }, []);

    /**
     * Render
     */
    return (
        <Box display="flex" flexDirection="column">
            <Dialog
                title={"Reinitialize Contract Creation Process"}
                id={"reset-flow"}
                open={!!resetFlowWarning?.datamodelId}
                message={
                    <Typography
                        variant="subtitle2"
                        color="black"
                        component="span"
                    >
                        {`You are about to change the model. Doing so will remove the filled data. This action cannot be undone.`}
                    </Typography>
                }
                primaryButton={{
                    text: "Confirm",
                    action: () => {
                        resetFlow && resetFlow();
                        setResetFlowWarning(undefined);
                        resetFlowWarning !== undefined &&
                            updateModel(resetFlowWarning);
                    },
                }}
                secondaryButton={{
                    text: "Cancel",
                    action: () => setResetFlowWarning(undefined),
                }}
            />
            <Box mb={7}>
                <Typography variant="h2">Select model</Typography>
            </Box>

            <Table
                id="model-list"
                headers={modelHeaderWithRadioBtn}
                rows={dataModelList?.data?.records}
                loading={fetching}
                type={ROWRENDERERCONST.MODEL}
                callbacks={{
                    onModelSelect: item => {
                        if (isArrayWithContent(categories)) {
                            setResetFlowWarning(item);
                        } else updateModel({ ...item });
                    },
                }}
                maxHeight="30rem"
                disabled={fetching}
                emptyMsg="No models found!"
                selectedRow={selectedModel?.datamodelId}
            />
        </Box>
    );
};

export default React.memo(Model);
