// Libs
import AssignmentIcon from "@mui/icons-material/Assignment";
import { Box } from "@mui/material";
import React, { useMemo } from "react";

// Own components
import { Tabs, Breadcrumbs } from "@components";

// Types
import { Location } from "@types";

/**
 * Props type
 */
interface Props {
    location: Location;
    children?: React.ReactNode;
}

/**
 * Tasks
 */
const Tasks = ({ location, children }: Props) => {
    /**
     * Tabs
     */
    const tabs = useMemo(() => {
        return [
            {
                title: "Ongoing",
                path: "/tasks/ongoing-tasks/",
            },
            {
                title: "Completed",
                path: "/tasks/completed-tasks/",
            },
        ];
    }, []);

    /**
     * Render
     */
    return (
        <div>
            <Breadcrumbs
                id={`tasks`}
                icon={<AssignmentIcon color="primary" />}
                title="Tasklist"
                location={location}
            />
            <Box mb={5}>
                <Tabs id={`dashboard-tabs`} location={location} items={tabs} />
            </Box>

            <Box px={7}>{children}</Box>
        </div>
    );
};
export default Tasks;
