import "./src/styles/index.scss";

import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import React from "react";
import { RecoilRoot } from "recoil";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";

// Layout containers
import { Layout } from "./src/containers";

// Theme
import theme from "./src/styles/theme";
import { msalInstance, account } from "./src/api";
import { pathname } from "./src/utils";

export const onClientEntry = async () => {
    await msalInstance.initialize();
    const redirectPromise = await msalInstance.handleRedirectPromise();
    if (
        !account &&
        !redirectPromise?.account &&
        !pathname?.includes("logout") &&
        !pathname?.includes("inactive")
    ) {
        await msalInstance.loginRedirect();
    }
};

export const wrapRootElement = ({ element }) => (
    <ThemeProvider theme={theme}>
        <CssBaseline />
        <LocalizationProvider dateAdapter={AdapterLuxon}>
            <RecoilRoot>
                <Layout>{element}</Layout>
            </RecoilRoot>
        </LocalizationProvider>
    </ThemeProvider>
);
