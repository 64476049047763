// extracted by mini-css-extract-plugin
export var active = "style-module--active--bd365";
export var drawer = "style-module--drawer--86729";
export var error = "#ff3131";
export var folded = "style-module--folded--1fae7";
export var logo = "style-module--logo--4a5bd";
export var menu = "style-module--menu--4f311";
export var navs = "style-module--navs--ae080";
export var paper = "style-module--paper--2acbb";
export var primary = "#036";
export var secondary = "#019cdc";
export var textfont = "\"Verdana Pro\"";
export var titlefont = "\"Karbon\"";
export var white = "#fff";
export var wrapper = "style-module--wrapper--47cc7";