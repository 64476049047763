//Lib
import Typography from "@mui/material/Typography";
import classnames from "classnames";
import React from "react";

//Utils
import { isArrayWithContent } from "@utils";

//Styles
import * as style from "../style.module.scss";

/**
 * List type
 */
type List = {
    key: string;
    parts: Array<{ text: string; highlight: boolean }>;
};

/**
 * Props type
 */
type Props = {
    list: Array<List>;
    keysToMatch: Array<string>;
    id: string;
};

/**
 * Filter List Renderer
 * Search component children
 * specify the class that we need to apply depending on a certain key
 */
const FilterListRenderer: React.FC<Props> = ({ list, keysToMatch, id }) => {
    if (!isArrayWithContent(list)) {
        return (
            <div className={style.filterList}>
                <Typography variant="caption2">No result</Typography>
            </div>
        );
    }

    /**
     * Render
     */
    return (
        <div className={style.filterList}>
            {keysToMatch?.map((key: string) => {
                const hasTwoCaptions: string[] = [];
                return list?.map((item, index) => {
                    if (
                        (item.key === key &&
                            item.key === "knowyoursupplierid") ||
                        item.key === "sapAccountCode"
                    ) {
                        hasTwoCaptions.push(key);
                    }
                    return (
                        item.key === key && (
                            <div
                                className={classnames({
                                    [style.header]: key === "accountName",
                                    [style.subHeader]: key === "accountCity",
                                    [style.firstCaption]:
                                        key === "sapAccountCode",
                                    [style.secondCaption]:
                                        key === "knowyoursupplierid",
                                    [style.separator]:
                                        hasTwoCaptions.length === 2,
                                })}
                            >
                                {item.key === "sapAccountCode"
                                    ? `SAP: `
                                    : item.key === "knowyoursupplierid"
                                      ? `KNOW YOUR SUPPLIER ID: `
                                      : ""}
                                {item.parts.map(part => (
                                    <span
                                        key={`${id}-label-${index}-${part.text}`}
                                        style={{
                                            fontWeight: part.highlight
                                                ? 700
                                                : 400,
                                        }}
                                    >
                                        {part.text}
                                    </span>
                                ))}
                            </div>
                        )
                    );
                });
            })}
        </div>
    );
};

export default React.memo(FilterListRenderer);
