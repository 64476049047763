//Lib
import {
    Box,
    Typography,
    FormControl,
    FormControlLabel,
    RadioGroup,
    Radio,
    InputLabel,
    TextField,
} from "@mui/material";
import React, { Fragment } from "react";

// Own components
import { Dialog } from "@components";

// Styles
import * as style from "../style.module.scss";

/**
 * Props
 */
interface Props {
    openDialog: boolean;
    onTaskComplete: () => void;
    loading: boolean;
    onReset: () => void;
    contractRef: string;
    className?: string;
    values: { approved: string; reasonRejection: string };
    onBlur: (event: React.SyntheticEvent) => void;
    onChange: any;
    errors: any;
    touched: any;
}

/**
 * Review Task
 */
const ReviewTask = ({
    contractRef,
    openDialog,
    loading,
    onReset,
    onTaskComplete,
    className,
    values,
    onBlur,
    onChange,
    errors,
    touched,
}: Props) => {
    return (
        <Fragment>
            <Dialog
                id={`tasks-task-review`}
                title={"Complete review"}
                open={openDialog}
                message={
                    values?.approved === "true" ? (
                        <Typography variant="subtitle2" color="black">
                            You are about to mark task
                            <Typography variant="body2" component="span">
                                {` Review contract [${contractRef}] `}
                            </Typography>
                            as complete. Doing so will set the contract to
                            active and will no longer be editable. This action
                            cannot be undone.
                        </Typography>
                    ) : (
                        <Typography variant="subtitle2" color="black">
                            You are about to reject a contract and consequently
                            mark task
                            <Typography variant="body2" component="span">
                                {` Review contract [${contractRef}] `}
                            </Typography>
                            as complete. Doing so will cancel the contract. This
                            action cannot be undone and a new contract should be
                            made.
                        </Typography>
                    )
                }
                primaryButton={{
                    text: "Confirm",
                    action: onTaskComplete,
                    loading: loading,
                }}
                secondaryButton={{
                    text: "Cancel",
                    action: onReset,
                }}
            />

            <Typography variant="h2" className={style.header}>
                {`Review contract [${contractRef}]`}
            </Typography>

            <Box mb={3} className={className}>
                <Box mb={2}>
                    <Typography variant="h3">
                        Complete contract review
                    </Typography>
                </Box>
                <Typography variant="body2" color="dark.light" mb={3}>
                    By approving this contract, you confirm to all the data
                    registered in this contract as correct. If data is faulty,
                    please use the reject option. Then the contract will be
                    cancelled and must be re-made.
                </Typography>

                <form noValidate onBlur={onBlur}>
                    <FormControl>
                        <RadioGroup
                            id={`task-details-task-review`}
                            value={values?.approved}
                            onChange={onChange}
                            name="approved"
                        >
                            <FormControlLabel
                                value={"true"}
                                id={`task-details-review-contract-approve`}
                                control={<Radio size="small" />}
                                label={
                                    <Typography
                                        variant="body2"
                                        color="dark.light"
                                    >
                                        I approve this contract
                                    </Typography>
                                }
                            />
                            <FormControlLabel
                                id={`task-details-review-contract-reject`}
                                value={"false"}
                                control={<Radio size="small" />}
                                label={
                                    <Typography
                                        variant="body2"
                                        color="dark.light"
                                    >
                                        I reject this contract
                                    </Typography>
                                }
                            />
                        </RadioGroup>
                    </FormControl>

                    {values?.approved === "false" && (
                        <Box mt={2}>
                            <InputLabel
                                error={
                                    !!errors?.reasonRejection &&
                                    !!touched?.reasonRejection
                                }
                                shrink
                            >
                                {"Reason of rejection (*)"}
                            </InputLabel>
                            <TextField
                                id={`task-details-task-contract-review-rejection-reason-input`}
                                fullWidth
                                name="reasonRejection"
                                value={values.reasonRejection}
                                variant="outlined"
                                autoComplete="off"
                                size="small"
                                onChange={onChange}
                                error={
                                    !!errors?.reasonRejection &&
                                    !!touched?.reasonRejection
                                }
                            />
                        </Box>
                    )}
                </form>
            </Box>
        </Fragment>
    );
};

export default React.memo(ReviewTask);
