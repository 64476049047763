// Lib
import { Box, Typography, Avatar, Grid, InputLabel } from "@mui/material";
import NoAccountsIcon from "@mui/icons-material/NoAccounts";
import React, { Fragment, useMemo } from "react";
import { useRecoilValue } from "recoil";

// Own components
import { UserRights, LoadingWrapper, TeamViewer, Select } from "@components";

// Atoms
import { userState } from "@atoms";

// Utils
import { isArrayWithContent } from "@utils";

// Styles
import * as style from "./style.module.scss";

// Types
import type { Right, Team, TeamUser, User as Profile } from "@types";

/**
 * Props type
 */
interface Props {
    accountTeams: { data: Array<Team>; loading: boolean };
    teamUsers?: { data: Array<TeamUser>; loading: boolean };
    rights: { data: Array<Right>; loading: boolean };
    selectedTeam: Team | undefined;
    setTeam?: (team: Team) => void;
    isSelectTeamPage?: boolean;
}

type AvatarType = {
    email?: string;
    firstName: string;
    lastName: string;
    width?: number;
    height?: number;
    bgColor?: string;
};

/**
 * Avatar Renderer
 */
const AvatarRenderer = ({
    email,
    firstName,
    lastName,
    width = 43,
    height = 43,
    bgColor,
}: AvatarType) => {
    const constructedAvatar =
        !!email && !firstName && !lastName
            ? email.substring(0, 2).toUpperCase()
            : `${firstName[0]}${lastName[0]}`;

    return (
        <Avatar
            sx={{
                bgcolor: bgColor,
                marginRight: "0.5rem",
                width,
                height,
            }}
        >
            <Typography
                variant="h4"
                color="#fff"
                component="span"
                sx={{
                    fontWeight: "500",
                    textAlign: "center",
                    position: "absolute",
                    top: "25%",
                }}
            >
                {constructedAvatar}
            </Typography>
        </Avatar>
    );
};

/**
 * Contracts
 */
const Account = ({
    accountTeams,
    rights,
    teamUsers,
    selectedTeam,
    setTeam,
    isSelectTeamPage,
}: Props) => {
    /**
     * Get profile data
     */
    const profile = useRecoilValue<Profile | undefined>(userState);

    /**
     * Map user id's
     */
    const rightIds = useMemo(() => {
        if (!isArrayWithContent(selectedTeam?.rights)) return;
        return selectedTeam?.rights.map(el => el.rightId);
    }, [selectedTeam]);

    /**
     * Render
     */
    return (
        <div className={style.wrapper}>
            <LoadingWrapper
                loading={accountTeams?.loading}
                fullHeight
                id="teams"
                NoEmptyPage
            >
                <Fragment>
                    {!isSelectTeamPage &&
                        profile?.info?.firstName &&
                        profile?.info?.lastName && (
                            <Box
                                mt={4}
                                mb={4}
                                display="flex"
                                alignItems="center"
                            >
                                <AvatarRenderer
                                    firstName={profile?.info?.firstName}
                                    lastName={profile?.info?.lastName}
                                    bgColor="#036"
                                />
                                <Typography variant="h2">
                                    {`${profile?.info?.firstName} ${profile?.info?.lastName}`}
                                </Typography>
                            </Box>
                        )}
                    {isArrayWithContent(accountTeams?.data) ? (
                        <>
                            <Grid container mt={6}>
                                <Grid item xs={12} md={6} xl={4}>
                                    <InputLabel id={`select-team-label`} shrink>
                                        {"Selected team"}
                                    </InputLabel>

                                    <Select
                                        id={`select-team`}
                                        value={selectedTeam}
                                        fullWidth
                                        onChange={
                                            setTeam
                                                ? (_, index) => {
                                                      setTeam(
                                                          accountTeams?.data[
                                                              index
                                                          ],
                                                      );
                                                  }
                                                : undefined
                                        }
                                        menuItemLabel={"teamName"}
                                        menuItemId="teamId"
                                        list={accountTeams?.data}
                                        loading={false}
                                        name="teams"
                                        scrollable
                                    />
                                </Grid>
                            </Grid>

                            <LoadingWrapper
                                loading={teamUsers?.loading || rights?.loading}
                                height="35rem"
                                id="user-rights"
                            >
                                <Fragment>
                                    <Grid container mt={5} spacing={3}>
                                        <Grid item xs={12} md={7} lg={8}>
                                            <Box my={3}>
                                                <Typography variant="h3">
                                                    User rights
                                                </Typography>
                                            </Box>
                                            <UserRights
                                                id="account-overview"
                                                disabled={true}
                                                rights={rights?.data}
                                                values={rightIds}
                                                approvalRole={
                                                    selectedTeam?.approvalRole
                                                }
                                            />
                                        </Grid>

                                        {teamUsers && (
                                            <Grid item xs={12} md={5} lg={4}>
                                                <div
                                                    className={
                                                        style.teamWrapper
                                                    }
                                                >
                                                    <div
                                                        className={
                                                            style.teamTitle
                                                        }
                                                    >
                                                        <Typography variant="h3">
                                                            My team
                                                        </Typography>
                                                        {!!isArrayWithContent(
                                                            accountTeams?.data,
                                                        ) &&
                                                            !teamUsers?.loading && (
                                                                <Typography
                                                                    ml={1}
                                                                    variant="caption1"
                                                                >
                                                                    {`This team contains ${teamUsers?.data?.length} member(s).`}
                                                                </Typography>
                                                            )}
                                                    </div>

                                                    <TeamViewer
                                                        id="team-viewer"
                                                        team={teamUsers?.data}
                                                        showNoTeamMsg
                                                        className={style.list}
                                                        showFullName
                                                    />
                                                </div>
                                            </Grid>
                                        )}
                                    </Grid>
                                </Fragment>
                            </LoadingWrapper>
                        </>
                    ) : (
                        <div className={style.noTeam}>
                            <NoAccountsIcon
                                color="primary"
                                sx={{
                                    maxWidth: "4rem",
                                    maxHeight: "4rem",
                                    fontSize: "4rem",
                                }}
                            />

                            <Typography variant="h3">No team found</Typography>

                            <Typography variant="subtitle2">
                                {
                                    "You are not attached to a team. Please contact an administrator."
                                }
                            </Typography>
                        </div>
                    )}
                </Fragment>
            </LoadingWrapper>
        </div>
    );
};
export default React.memo(Account);
