// Icons
import GridOn from "@mui/icons-material/GridOn";

// Libs
import { navigate } from "gatsby";
import React, { Fragment, useLayoutEffect, useMemo } from "react";

// Own component
import {
    Breadcrumbs,
    StepActions,
    Stepper,
    FlowLayout,
    FlowAside,
    FlowBody,
    FlowContent,
    FlowFooter,
    LoadingWrapper,
    OutcomeDetails as _OutcomeDetails,
} from "@components";
import {
    useOutcomeDetails,
    usePermission,
    useUploadDocument,
    useDownloadBpf,
    useDownloadApheresisPdf,
} from "@hooks";

// Types
import type { Location, Permissions } from "@types";

/**
 * Props type
 */
interface Props {
    location: Location;
    outcomeId: string;
}

/**
 * Outcome details
 */
const OutcomeDetails = ({ location, outcomeId }: Props) => {
    /**
     * Check outcome trigger type
     */
    const isFinancialConditionsPage = useMemo(() => {
        if (!location) return;
        return location?.pathname?.includes(
            "/financial-conditions/financial-conditions-details/",
        );
    }, [location]);

    /**
     * Permissions
     */
    const { hasPermissionToEditOutcome }: Permissions = usePermission();

    //API
    const {
        getOutcomeDetails,
        loading,
        response,
        error: getFinancialDetailsError,
    } = useOutcomeDetails();

    // Fetch evidences
    const {
        loading: { downloading: downloadingEvidence },
        download: downloadEvidence,
    } = useUploadDocument();

    // download apheresis
    const { loading: downloadingApheresis, download: downloadApheresisPdf } =
        useDownloadApheresisPdf();

    // downLoad Bpf
    const { load: downLoadBpf, loading: downloadingBpf }: any =
        useDownloadBpf();

    /**
     * Fetch outcome details
     */
    useLayoutEffect(() => {
        if (!outcomeId) return;
        getOutcomeDetails(outcomeId);
    }, [outcomeId]);

    /**
     * Data mapper
     */
    const data = useMemo(() => {
        if (!loading && !response?.data) return;
        return response?.data;
    }, [response?.data, loading]);

    /**
     * Download bpf
     */
    const handleDownloadBpf = (fileId: string) => downLoadBpf(fileId);

    /**
     * Render
     */
    return (
        <Fragment>
            <Breadcrumbs
                id={`outcome-details-breadcrumbs`}
                icon={<GridOn color="primary" />}
                title="Dashboard"
                location={location}
            />
            <FlowLayout>
                <LoadingWrapper
                    fullHeight
                    id={`view-contract-loading`}
                    loading={loading}
                    error={getFinancialDetailsError}
                    NoEmptyPage
                >
                    <Fragment>
                        <FlowAside>
                            <Stepper
                                id={`outcome-details-stepper`}
                                title={
                                    isFinancialConditionsPage
                                        ? "Financial conditions details"
                                        : "Outcome details"
                                }
                                subTitle={`#${data?.contract?.ref}`}
                            />
                        </FlowAside>

                        <FlowBody>
                            <Fragment>
                                <FlowContent>
                                    <_OutcomeDetails
                                        isFinancialConditions={
                                            isFinancialConditionsPage
                                        }
                                        outcome={data}
                                        id="outcome-details"
                                        downloadBpf={{
                                            onDownload: fileId =>
                                                handleDownloadBpf(fileId),
                                            loading: downloadingBpf,
                                        }}
                                        downloadEvidence={{
                                            canDownload:
                                                hasPermissionToEditOutcome,
                                            onDownload: (
                                                contractId: string,
                                                evidenceId: string,
                                            ) =>
                                                downloadEvidence(
                                                    contractId,
                                                    evidenceId,
                                                ),

                                            loading: downloadingEvidence,
                                        }}
                                        downloadApheresis={{
                                            onDownload: () =>
                                                downloadApheresisPdf(outcomeId),
                                            loading: downloadingApheresis,
                                        }}
                                    />
                                </FlowContent>

                                <FlowFooter>
                                    <StepActions
                                        id={`outcomes-details-step-actions`}
                                        primaryButton={{
                                            text: "Return to overview",
                                            action: () =>
                                                isFinancialConditionsPage
                                                    ? navigate(
                                                          `/dashboard/financial-conditions/`,
                                                      )
                                                    : navigate(
                                                          `/dashboard/outcomes/`,
                                                      ),
                                            isSubmitButton: true,
                                        }}
                                    />
                                </FlowFooter>
                            </Fragment>
                        </FlowBody>
                    </Fragment>
                </LoadingWrapper>
            </FlowLayout>
        </Fragment>
    );
};

export default OutcomeDetails;
