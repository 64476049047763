// lib
import { Typography, Box } from "@mui/material";
import classnames from "classnames";
import React from "react";

//style
import * as style from "./style.module.scss";

interface Props {
    error?: boolean;
    className?: string;
    textAlign?: "end" | "left" | "center" | "right" | "start";
    showText?: boolean;
    noMargin?: boolean;
    id: string;
}

const FormFooter: React.FC<Props> = ({
    error,
    textAlign,
    className,
    showText = true,
    noMargin,
    id,
}) => (
    <Box
        id={`${id}-footer`}
        className={classnames(
            style.wrapper,
            { [style.error]: error, [style.noMargin]: noMargin },
            className,
        )}
        textAlign={textAlign}
    >
        {showText && (
            <Typography variant="caption1">
                {"Fields with (*) are mandatory."}
            </Typography>
        )}
    </Box>
);
export default FormFooter;
