// lib
import { Box, Grid, Typography, Modal, IconButton } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import React, { useEffect, useState } from "react";

// Own components
import { Table, SearchBar } from "@components";

// Hooks
import { useViewingOptions } from "@hooks";

// Style
import * as style from "./style.module.scss";

// Constants
import { HEADERS, ROWRENDERERCONST } from "@constants";

// Types
import type { BillingPlanValues, Milestone } from "@types";

/**
 * Props type
 */
interface Props {
    id: string;
    milestoneList: Array<Milestone>;
    billingPlanList: Array<BillingPlanValues>;
    loading: {
        fetchingMilestones: boolean;
        fetchingBillingPlans: boolean;
        linkingBillingPlan: boolean;
    };

    loadBillingPlanList: (milestoneId: string) => void;
    onLinkBillingPlan: (
        milestoneId: string,
        billingPlanId: string,
    ) => Promise<any>;
}

/**
 * Maintenance Milestone
 */
const MaintenanceMilestone: React.FC<Props> = ({
    id,
    milestoneList,
    billingPlanList,
    loading,
    onLinkBillingPlan,
    loadBillingPlanList,
}) => {
    /**
     * States
     */
    const [milestoneId, setMilestoneId] = useState("");

    /**
     * Custom Hooks
     */
    const { viewingOptions, setViewingOptions } = useViewingOptions(
        ROWRENDERERCONST.MILESTONE,
    );

    useEffect(() => {
        if (!milestoneId) {
            setMilestoneId("");
        } else {
            loadBillingPlanList(milestoneId);
        }
    }, [milestoneId]);

    /**
     *  link handler
     */
    const handleLinkBillingPlan = (billingPlanId: string) => {
        onLinkBillingPlan(milestoneId, billingPlanId).then(success => {
            !!success && setMilestoneId("");
        });
    };

    /**
     * Render
     */
    return (
        <Box mt={5}>
            <Modal
                open={!!milestoneId}
                onClose={() => setMilestoneId("")}
                aria-labelledby="modal-billing-plan"
                aria-describedby="modal-billing-plan"
                id={`${id}-"modal-billing-plan"`}
                sx={{
                    backgroundColor: "rgba(0, 51, 102, 0.2)",
                    zIndex: theme => theme.zIndex.drawer + 1,
                }}
            >
                <div className={style.modalWrapper}>
                    <div className={style.header}>
                        <Typography variant="h3">Billing plans</Typography>
                        <IconButton
                            size="small"
                            onClick={() => setMilestoneId("")}
                        >
                            <ClearIcon color="primary" />
                        </IconButton>
                    </div>
                    <Grid container className={style.content} spacing={2}>
                        <Table
                            loading={loading?.fetchingBillingPlans}
                            headers={HEADERS.BILLING_PLAN}
                            rows={billingPlanList}
                            type={ROWRENDERERCONST.BILLING_PLAN}
                            id="billing-plan-list"
                            callbacks={{
                                linkBillingPlan: (billingPlanId: string) => {
                                    handleLinkBillingPlan(billingPlanId);
                                },
                            }}
                            emptyMsg="No linkable billing plans found!"
                            disabled={loading?.linkingBillingPlan}
                        />
                    </Grid>
                </div>
            </Modal>

            <Box mt={2} mb={4} display="flex" justifyContent="flex-end">
                <SearchBar
                    id="milestone-search"
                    filterKey={ROWRENDERERCONST.MILESTONE}
                    setViewingOptions={setViewingOptions}
                    viewingOptions={viewingOptions}
                    hasFilters={false}
                    action={{ hidden: true }}
                />
            </Box>

            <Box mb={4}>
                <Typography variant="h2">Milestone</Typography>
            </Box>
            <Table
                loading={loading?.fetchingMilestones}
                headers={HEADERS.MILESTONE}
                rows={milestoneList}
                type={ROWRENDERERCONST.MILESTONE}
                viewingOptions={viewingOptions}
                id="billing-plan-list"
                callbacks={{
                    linkBillingPlan: (milestoneId: string) =>
                        setMilestoneId(milestoneId),
                }}
                emptyMsg="No data"
            />
        </Box>
    );
};
export default MaintenanceMilestone;
