//Lib
import {
    Box,
    Typography,
    FormControl,
    FormControlLabel,
    RadioGroup,
    Radio,
} from "@mui/material";
import React, { Fragment } from "react";

// Own component
import { TaskReview, Dialog } from "@components";

/**
 * Props
 */
interface Props {
    onBlur: (event: React.SyntheticEvent) => void;
    onChange: any;
    values: Record<string, string>;
    id: string;
    task: any;
    openDialog: boolean;
    order: any;
    onApprove: () => void;
    loading: boolean;
    onReset: () => void;
    className?: string;
    hideAction: boolean;
}

/**
 * CancelOutcome
 */
const CancelOutcome = ({
    id,
    onBlur,
    onChange,
    values,
    task,
    openDialog,
    order,
    loading,
    onApprove,
    onReset,
    className,
    hideAction = false,
}: Props) => {
    /**
     * API
     */

    return (
        <Fragment>
            <Dialog
                id={`tasks-approval-task`}
                title={"Approve outcome Cancellation"}
                open={openDialog}
                message={
                    values?.approved === "false" ? (
                        <Typography variant="subtitle2" color="black">
                            You are about to reject an outcome cancellation and
                            consequently mark task
                            <Typography variant="body2" component="span">
                                {` Cancel Outcome [${order?.salessystemOrderId}] `}
                            </Typography>
                            as complete. Doing so will NOT cancel the outcome
                            and make it eligible again for financial
                            transactions.
                        </Typography>
                    ) : (
                        <Typography variant="subtitle2" color="black">
                            You are about to approve an outcome cancellation and
                            consequently mark task
                            <Typography variant="body2" component="span">
                                {` Cancel Outcome [${order?.salessystemOrderId}] `}
                            </Typography>
                            as complete. Doing so will trigger a financial
                            transaction. This action cannot be undone.
                        </Typography>
                    )
                }
                primaryButton={{
                    text: "Confirm",
                    action: onApprove,
                    loading: loading,
                }}
                secondaryButton={{
                    text: "Cancel",
                    action: onReset,
                }}
            />

            <TaskReview
                id={`${id}-task-review-order`}
                title="Task data"
                sapOrderNo={task?.data?.order?.sapOrderNo}
                treatmentSite={task?.data?.order?.treatmentSite}
                infusionDate={task?.data?.order?.infusionDate}
                salessystemOrderId={task?.data?.order?.salessystemOrderId}
                creationDate=""
                dueDate=""
            />

            <TaskReview
                id={`task-details-task-review`}
                title="Outcome"
                outcomeValues={task?.data?.outcome}
            />
            {!hideAction && (
                <Box className={className}>
                    <Box mb={2}>
                        <Typography variant="h3">
                            Approve outcome cancellation
                        </Typography>
                    </Box>

                    <form noValidate onBlur={onBlur}>
                        <FormControl>
                            <RadioGroup
                                id={`task-details-task-review`}
                                value={values?.approved}
                                onChange={onChange}
                                name="approved"
                            >
                                <FormControlLabel
                                    value={"true"}
                                    id={`task-details-cancel-outcome-approve`}
                                    control={<Radio size="small" />}
                                    label={
                                        <Typography
                                            variant="body2"
                                            color="dark.light"
                                        >
                                            I approve this decision
                                        </Typography>
                                    }
                                />
                                <FormControlLabel
                                    id={`task-details-cancel-outcome-rejection`}
                                    value={"false"}
                                    control={<Radio size="small" />}
                                    label={
                                        <Typography
                                            variant="body2"
                                            color="dark.light"
                                        >
                                            I reject this decision
                                        </Typography>
                                    }
                                />
                            </RadioGroup>
                        </FormControl>
                    </form>
                </Box>
            )}
        </Fragment>
    );
};

export default React.memo(CancelOutcome);
