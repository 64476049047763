type GenericObject = { [key: string]: any };

export const stringTransformer = (
    text: string,
    options?: { capitalize?: boolean; lowerCase?: boolean },
): string => {
    if (!text) return "";
    if (!options)
        return text
            .split(/(?=[A-Z])/)
            .map((p, i) =>
                i === 0 ? p[0].toUpperCase() + p.slice(1) : p.toLowerCase(),
            )
            .join(" ");

    if (options?.capitalize && !!text) {
        return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
    }

    if (options?.lowerCase && !!text) {
        return text
            .split(/(?=[A-Z])/)
            .map(p => p.toLowerCase())
            .join(" ");
    }
    return "";
};

export const truncateString = (
    str: string,
    limit?: number,
    withTail?: boolean,
    length?: number,
) => {
    if (!str) return "";
    const extension = withTail ? str.split(".").pop() : "";
    const defaultLength = length ? length : 35;
    return str.length > defaultLength
        ? str.slice(0, limit ? limit : 35) + ".." + `.${extension}`
        : str;
};

export const constantMapper = (text: string, noTextTransform?: boolean) => {
    if (!text || typeof text !== "string") return "";
    const str = text.split("_").join(" ");

    if (noTextTransform) return str;
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};
export const camelCaseSplitter = (text: string) => {
    if (!text) return;
    const convertedStr = text.replace(/([A-Z])/g, " $1");

    return (
        convertedStr.charAt(0).toUpperCase() +
        convertedStr.slice(1).toLowerCase()
    );
};

export const trimAndFormat = (str, toUpperCase = false) => {
    if (!str || str === "") return "";

    // Remove all spaces from the string
    const trimmedStr = str.replace(/\s+/g, "");

    return toUpperCase ? trimmedStr.toUpperCase() : trimmedStr;
};

// Convert to base64
export const convertBase64 = file => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);

        fileReader.onload = () => {
            resolve(fileReader.result);
        };

        fileReader.onerror = error => {
            reject(error);
        };
    });
};

export const splitStringWithDash = (items: GenericObject, keys: string[]) => {
    if (!keys || !items) return "";

    return keys
        .map(key => items[key])
        .filter(Boolean)
        .join("-");
};
