// Libs
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import FormHelperText from "@mui/material/FormHelperText";
import React, { Fragment, useMemo, useState } from "react";

// Own components
import {
    SearchBar,
    Table,
    Modal,
    Select,
    LoadingWrapper,
    Dialog,
} from "@components";

// Constants
import {
    HEADERS,
    ROWRENDERERCONST,
    REGION_TYPE,
    filterByCountry,
} from "@constants";

// Hooks
import {
    usePermission,
    useViewingOptions,
    useCountries,
    useRegion,
    useSelectedCountry,
} from "@hooks";

// Types
import { Permissions, BasicRegionType, SubRegionType } from "@types";

// Utils
import {
    apiResponseCounter,
    isArrayWithContent,
    isSuccessfulCall,
} from "@utils";
import { Country } from "types/api";
import { navigate } from "gatsby";

/**
 * Region types
 */
type RegionItem = BasicRegionType & {
    country?: Country;
    tenderRegionCode?: BasicRegionType;
    parentRegionCode?: BasicRegionType;
    regionAccountField?: string;
    index?: number;
};

const INITIAL_REGION = {
    type: { label: "", value: "" },
    regionCode: "",
    regionName: "",
    tenderRegionCode: undefined,
    parentRegionCode: undefined,
    regionAccountField: "",
    country: { isoCode: "", name: "" },
    index: undefined,
};

/**
 * Regions
 */
const Regions = () => {
    const { isUkTeam, countryIsoCode } = useSelectedCountry();

    /**
     * Local states
     */

    // Region values store
    const [regionToUpdate, setRegionToUpdate] =
        useState<RegionItem>(INITIAL_REGION);

    // Toggle modal
    const [openAddModal, setOpenAddModal] = useState<boolean>(false);

    // Touched store
    const [touched, setTouch] = useState({});

    // Delete region
    const [regionToBeDeleted, setRegionToBeDeleted] = useState<
        RegionItem | undefined
    >(undefined);

    // Validation
    const [validationMsg, setValidationMsg] = useState({
        type: "",
        regionCode: "",
    });

    /**
     * API
     */

    //countries
    const {
        list: countries,
        loading: loadingCountries,
        load: loadCountries,
    } = useCountries(false);

    // Regions CRUD
    const {
        list: { regions, accountField },
        loading: {
            creating,
            fetching,
            updating,
            deleting,
            fetchingAccountField,
        },
        createRegion,
        updateRegion,
        deleteRegion,
        load: loadRegions,
        loadAccountField,
    } = useRegion();

    /**
     * Permissions
     */
    const { hasPermissionToEditDataTables }: Permissions = usePermission();

    /**
     * Hooks
     */
    const { viewingOptions, setViewingOptions } = useViewingOptions(
        ROWRENDERERCONST.REGION,
    );

    /**
     * Reset all
     */
    const onReset = () => {
        setOpenAddModal(false);
        setRegionToUpdate(INITIAL_REGION);
        setTouch({});
        setValidationMsg({ type: "", regionCode: "" });
    };

    /**
     * Check if the form is valid
     */
    const checkFormValidity = () => {
        if (!isArrayWithContent(regions?.data?.records)) {
            setValidationMsg({ type: "", regionCode: "" });
            return false;
        }
        let hasDuplicateType = false;
        let hasDuplicateCode = false;

        regions?.data?.records.forEach((item, index) => {
            if (index === regionToUpdate?.index) return;

            if (
                item.type === (regionToUpdate?.type as SubRegionType)?.value &&
                item.regionCode === regionToUpdate?.regionCode
            ) {
                hasDuplicateType = true;
                hasDuplicateCode = true;
            }
        });
        setValidationMsg({
            type: hasDuplicateType
                ? "Type must be unique for each region code"
                : "",
            regionCode: hasDuplicateCode
                ? "Code must be unique for each region type"
                : "",
        });

        return hasDuplicateType || hasDuplicateCode;
    };

    /**
     * Submit/update new region
     */
    const onRegionSubmit = () => {
        const hasDuplication = checkFormValidity();
        if (hasDuplication) return;

        const mappedRegionForApi = {
            regionName: regionToUpdate.regionName,
            regionCode: regionToUpdate.regionCode,
            type: (regionToUpdate?.type as SubRegionType)?.value,
            tenderRegionCode: isUkTeam
                ? regionToUpdate.tenderRegionCode?.regionId || null
                : undefined,
            parentRegionCode: isUkTeam
                ? regionToUpdate.parentRegionCode?.regionId || null
                : undefined,
            regionAccountField: isUkTeam
                ? regionToUpdate.regionAccountField || null
                : undefined,
            countryIsoCode: regionToUpdate?.country?.isoCode,
        };

        //Update region, join region ID on Edit
        if (regionToUpdate.regionId) {
            updateRegion(regionToUpdate.regionId, {
                ...mappedRegionForApi,
                regionId: regionToUpdate.regionId,
            }).then(res => {
                if (isSuccessfulCall(res?.status)) {
                    onReset();
                    loadRegions();
                }
            });
        } else {
            // Add region, join country ISO code on create

            // Not allowed to use the same values when the user adds a new region

            if (!regionToUpdate) return;

            createRegion({
                ...mappedRegionForApi,
                countryIsoCode: regionToUpdate?.country?.isoCode,
            }).then(res => {
                if (isSuccessfulCall(res?.status)) {
                    onReset();
                    loadRegions();
                }
            });
        }
    };

    /**
     * Delete region
     */
    const onRegionDelete = () => {
        if (!regionToBeDeleted) return;

        deleteRegion(
            regionToBeDeleted?.regionId as string,
            regionToBeDeleted?.regionName as string,
        ).then(res => {
            if (isSuccessfulCall(res?.status)) {
                setRegionToBeDeleted(undefined);
                loadRegions();
            }
        });
    };

    /**
     * Change handler
     */
    const onChange = (field: string, value: any) => {
        if (!field) return;
        const copyRegionToUpdate: RegionItem = { ...regionToUpdate };
        copyRegionToUpdate[field] = value;

        setRegionToUpdate(copyRegionToUpdate);
    };

    /**
     * Initialize type
     */

    const regionType = (type?: string) => {
        if (!type) return REGION_TYPE[0];
        const findType = REGION_TYPE.find(el => el.value === type);
        // did it dirty because of ts warning
        return findType ? findType : REGION_TYPE[0];
    };

    /**
     * Prepare region to update stage
     */
    const storeRegionToUpdate = (
        item?: Omit<RegionItem, "type"> & { type: string },
    ) => {
        loadAccountField();
        loadCountries().then(res => {
            if (
                isSuccessfulCall(res?.status) &&
                isArrayWithContent(res?.data?.records)
            ) {
                // Initialize region
                const initialRegion = {
                    type: regionType(item?.type),
                    regionCode: item?.regionCode || "",
                    regionName: item?.regionName || "",
                    country: item?.country || res?.data?.records[0],
                    regionId: item?.regionId || undefined,
                    index: item?.index !== undefined ? item?.index : undefined,
                    tenderRegionCode: isUkTeam
                        ? item?.tenderRegionCode
                        : undefined,
                    parentRegionCode: isUkTeam
                        ? item?.parentRegionCode
                        : undefined,
                    regionAccountField: isUkTeam
                        ? item?.regionAccountField || ""
                        : undefined,
                };

                setRegionToUpdate(initialRegion);
            }
        });
    };

    /**
     * Blur handler
     */
    const handleBlur = (field: string) => {
        if (!field || (!!field && !!touched[field])) return;
        const copyTouched = { ...touched };
        copyTouched[field] = true;
        setTouch(copyTouched);
    };

    /**
     * Error handler
     */
    const hasError = (field: string) => {
        if (!field) return;
        return !!touched[field] && !regionToUpdate[field];
    };

    /**
     * Check if the form is valid
     */
    const canSubmit = useMemo(() => {
        if (!regionToUpdate) return false;

        const { type, country, regionName, regionCode } = regionToUpdate;
        return (
            !!(type as SubRegionType)?.value &&
            !!country?.isoCode &&
            !!regionName &&
            !!regionCode
        );
    }, [regionToUpdate]);

    /**
     * Render
     */
    return (
        <>
            <Dialog
                open={!!regionToBeDeleted}
                id={`delete-modal`}
                message={
                    <Typography
                        variant="subtitle2"
                        color="black"
                        component="span"
                    >
                        Are you sure you would like to delete region
                        <Typography
                            variant="body2"
                            component="span"
                        >{` ${regionToBeDeleted?.regionName}? `}</Typography>
                        This action cannot be undone.
                    </Typography>
                }
                primaryButton={{
                    text: "Delete",
                    disabled: deleting,
                    action: () => onRegionDelete(),
                    loading: deleting,
                }}
                secondaryButton={{
                    text: "Cancel",
                    action: () => setRegionToBeDeleted(undefined),
                }}
            />

            <Modal
                id={`add-new-region-modal`}
                open={openAddModal}
                smallView
                onClose={() => {
                    onReset();
                }}
                title={
                    regionToUpdate?.regionId ? "Edit region" : "Add new region"
                }
                primaryButton={{
                    action: onRegionSubmit,
                    text: regionToUpdate?.regionId ? "Save" : "Add",
                    loading: creating || updating,
                    disabled: !canSubmit || loadingCountries,
                }}
                secondaryButton={{
                    action: () => {
                        onReset();
                    },
                    text: "Cancel",
                }}
            >
                <LoadingWrapper
                    id={`region-wrapper`}
                    loading={loadingCountries || fetchingAccountField}
                >
                    <Grid container item xs={12} spacing={3}>
                        <Grid item xs={12}>
                            <InputLabel
                                shrink
                                id={`country`}
                                error={hasError("country")}
                            >
                                {"Country (*)"}
                            </InputLabel>

                            <Select
                                value={regionToUpdate?.country || ""}
                                id={`region-select-country`}
                                onChange={(_, index) => {
                                    onChange(
                                        "country",
                                        countries?.data?.records[index],
                                    );
                                }}
                                name="country"
                                menuItemLabel={"name"}
                                list={countries?.data?.records}
                                menuItemId="isoCode"
                                onBlur={() => handleBlur("country")}
                                error={hasError("country")}
                            />
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <InputLabel
                                shrink
                                id={`type`}
                                error={
                                    hasError("type") || !!validationMsg?.type
                                }
                            >
                                {"Type (*)"}
                            </InputLabel>
                            <Select
                                value={regionToUpdate?.type}
                                id={`region-type`}
                                onChange={(_, index) =>
                                    onChange("type", REGION_TYPE[index])
                                }
                                list={REGION_TYPE}
                                menuItemId="value"
                                menuItemLabel="label"
                                name="type"
                                onBlur={() => handleBlur("type")}
                                error={
                                    hasError("type") || !!validationMsg?.type
                                }
                            />
                            {!!validationMsg?.type && (
                                <FormHelperText error>
                                    {validationMsg?.type}
                                </FormHelperText>
                            )}
                        </Grid>
                        <Grid item xs={4}>
                            <InputLabel
                                shrink
                                id={`code`}
                                error={
                                    hasError("regionCode") ||
                                    !!validationMsg?.regionCode
                                }
                            >
                                {"Code (*)"}
                            </InputLabel>
                            <TextField
                                disabled={!regionToUpdate?.country?.isoCode}
                                id={`region-code`}
                                fullWidth
                                name={"regionCode"}
                                size="small"
                                value={regionToUpdate?.regionCode}
                                onChange={event =>
                                    onChange("regionCode", event.target.value)
                                }
                                variant="outlined"
                                onBlur={() => handleBlur("regionCode")}
                                error={
                                    hasError("regionCode") ||
                                    !!validationMsg?.regionCode
                                }
                            />
                            {!!validationMsg?.regionCode && (
                                <FormHelperText error>
                                    {validationMsg?.regionCode}
                                </FormHelperText>
                            )}
                        </Grid>
                        <Grid item xs={4}>
                            <InputLabel
                                shrink
                                id={`regionName`}
                                error={hasError("regionName")}
                            >
                                {"Name (*)"}
                            </InputLabel>
                            <TextField
                                disabled={
                                    loadingCountries ||
                                    !regionToUpdate?.country?.isoCode
                                }
                                id={`region-name`}
                                size="small"
                                fullWidth
                                name={"regionName"}
                                value={regionToUpdate?.regionName}
                                onChange={event =>
                                    onChange("regionName", event.target.value)
                                }
                                variant="outlined"
                                onBlur={() => handleBlur("regionName")}
                                error={hasError("regionName")}
                            />
                        </Grid>
                        {isUkTeam && (
                            <Fragment>
                                <Grid item xs={12} md={4}>
                                    <InputLabel
                                        shrink
                                        id={`tenderRegionCode`}
                                        error={!!validationMsg?.type}
                                    >
                                        {"Tender region code"}
                                    </InputLabel>
                                    <Select
                                        value={regionToUpdate?.tenderRegionCode}
                                        id={`region-tender-region-code`}
                                        onChange={(_, index) =>
                                            onChange(
                                                "tenderRegionCode",
                                                regions?.data?.records[index],
                                            )
                                        }
                                        list={regions?.data?.records}
                                        menuItemId="regionId"
                                        menuItemLabel={[
                                            "regionName",
                                            "regionCode",
                                            "type",
                                        ]}
                                        displayEmpty
                                        name="tenderRegionCode"
                                        onBlur={() =>
                                            handleBlur("tenderRegionCode")
                                        }
                                        error={!!validationMsg?.type}
                                        placeHolder={{
                                            title: "Not specified",
                                            value: "",
                                            selectable: true,
                                        }}
                                        scrollable
                                    />
                                    {!!validationMsg?.type && (
                                        <FormHelperText error>
                                            {validationMsg?.type}
                                        </FormHelperText>
                                    )}
                                </Grid>

                                <Grid item xs={12} md={4}>
                                    <InputLabel
                                        shrink
                                        id={`parentRegionCode`}
                                        error={!!validationMsg?.type}
                                    >
                                        {"Parent region code"}
                                    </InputLabel>

                                    <Select
                                        value={regionToUpdate?.parentRegionCode}
                                        id={`region-parent-region-code`}
                                        onChange={(_, index) =>
                                            onChange(
                                                "parentRegionCode",
                                                regions?.data?.records[index],
                                            )
                                        }
                                        list={regions?.data?.records}
                                        menuItemId="regionId"
                                        menuItemLabel={[
                                            "regionName",
                                            "regionCode",
                                            "type",
                                        ]}
                                        name="parentRegionCode"
                                        displayEmpty
                                        onBlur={() =>
                                            handleBlur("parentRegionCode")
                                        }
                                        error={!!validationMsg?.type}
                                        placeHolder={{
                                            title: "Not specified",
                                            value: "",
                                            selectable: true,
                                        }}
                                        scrollable
                                    />

                                    {!!validationMsg?.type && (
                                        <FormHelperText error>
                                            {validationMsg?.type}
                                        </FormHelperText>
                                    )}
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <InputLabel
                                        shrink
                                        id={`regionAccountField`}
                                        error={!!validationMsg?.type}
                                    >
                                        {"Region account field"}
                                    </InputLabel>
                                    <Select
                                        value={
                                            regionToUpdate?.regionAccountField
                                        }
                                        id={`region-account-region-field`}
                                        onChange={value =>
                                            onChange(
                                                "regionAccountField",
                                                value,
                                            )
                                        }
                                        list={accountField?.data}
                                        name="regionAccountField"
                                        displayEmpty
                                        onBlur={() =>
                                            handleBlur("regionAccountField")
                                        }
                                        error={!!validationMsg?.type}
                                        placeHolder={{
                                            title: "Not specified",
                                            value: "",
                                            selectable: true,
                                        }}
                                        noTextTransform
                                    />

                                    {!!validationMsg?.type && (
                                        <FormHelperText error>
                                            {validationMsg?.type}
                                        </FormHelperText>
                                    )}
                                </Grid>
                            </Fragment>
                        )}
                    </Grid>
                </LoadingWrapper>
            </Modal>

            <Box mt={5}>
                <Box mt={2} mb={4} display="flex" justifyContent="flex-end">
                    <SearchBar
                        id="add-new-region"
                        filterKey={ROWRENDERERCONST.REGION}
                        setViewingOptions={setViewingOptions}
                        viewingOptions={viewingOptions}
                        action={{
                            label: "Add new region",
                            callback: () => {
                                storeRegionToUpdate();
                                setOpenAddModal(true);
                            },
                            hidden: !hasPermissionToEditDataTables,
                        }}
                        hasFilters={false}
                    />
                </Box>
                <Box display="flex" alignItems="baseline" mb={4}>
                    <Typography variant="h2">Regions</Typography>

                    <Typography ml={1} variant="caption1">
                        {apiResponseCounter(
                            regions,
                            fetching,
                            "region|regions",
                        )}
                    </Typography>
                </Box>
                <Table
                    id={`dashboard-regions-overview-list`}
                    headers={filterByCountry(HEADERS.REGION, countryIsoCode)}
                    rows={regions?.data?.records}
                    loading={fetching}
                    type={ROWRENDERERCONST.REGION}
                    viewingOptions={viewingOptions}
                    permissions={{ hasPermissionToEditDataTables }}
                    callbacks={{
                        onEdit: (item, index) => {
                            storeRegionToUpdate({ ...item, index });
                            setOpenAddModal(true);
                        },
                        onDelete: (region: RegionItem) =>
                            setRegionToBeDeleted(region),
                        onExternalCodesEdit: item =>
                            navigate(
                                `/region/external-codes/${item.regionId}/`,
                            ),
                    }}
                    emptyMsg="No regions found!"
                />
            </Box>
        </>
    );
};
export default React.memo(Regions);
