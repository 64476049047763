import { MappedProductsForClient } from "@types";
import { atom } from "recoil";

/**
 * Product details
 */
export const productsDetailsState = atom({
    key: "productsDetailsState",
    default: {
        products: [],
    } as MappedProductsForClient,
});
