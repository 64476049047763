//libs
import GridOn from "@mui/icons-material/GridOn";
import { Box } from "@mui/material";
import React, { useMemo } from "react";

//own components
import { Breadcrumbs, Tabs } from "@components";
//hooks
import { useLandingPage } from "@hooks";
//types
import type { Location } from "@types";

/**
 * Props type
 */
interface Props {
    children?: React.ReactNode;
    location: Location;
}

/**
 * Dashboard layout
 */
const DashboardLayout = ({ children, location }: Props) => {
    /**
     * Permissions
     */
    const {
        areContractsVisible,
        areFinancesVisible,
        areOrdersVisible,
        areOutcomesVisible,
        areClaimsVisible,
    } = useLandingPage();

    /**
     * Tabs
     */
    const tabs = useMemo(() => {
        return [
            {
                title: "Contracts",
                path: "/dashboard/contracts/",
                disabled: !areContractsVisible,
            },
            {
                title: "Orders",
                path: "/dashboard/orders/",
                disabled: !areOrdersVisible,
            },
            {
                title: "Outcomes",
                path: "/dashboard/outcomes/",
                disabled: !areOutcomesVisible,
            },
            {
                title: "Financial conditions",
                path: "/dashboard/financial-conditions/",
                disabled: !areOutcomesVisible,
            },
            {
                title: "Claims",
                path: "/dashboard/claims/",
                disabled: !areClaimsVisible,
                subpages: ["/dashboard/price-correction-claims"],
            },
            {
                title: "Finances",
                path: "/dashboard/finances/bpf/",
                disabled: !areFinancesVisible,
                subpages: [
                    "/dashboard/finances/credit-notes",
                    "/dashboard/finances/invoice-release",
                ],
            },
            {
                title: "Products",
                path: "/dashboard/products/",
            },
            {
                title: "Partners",
                path: "/dashboard/partners/",
            },
            {
                title: "Regions",
                path: "/dashboard/regions/",
            },
        ];
    }, [
        areContractsVisible,
        areFinancesVisible,
        areOrdersVisible,
        areOutcomesVisible,
        areClaimsVisible,
    ]);

    return (
        <div>
            <Breadcrumbs
                id={`dashboard-breadcrumb`}
                icon={<GridOn color="primary" />}
                title="Dashboard"
                location={location}
            />

            <Box mb={5}>
                <Tabs id={`dashboard-tabs`} location={location} items={tabs} />
            </Box>

            <Box px={7}>{children}</Box>
        </div>
    );
};
export default React.memo(DashboardLayout);
