//import axios from "axios";
import { useState } from "react";
import { useRecoilState } from "recoil";
import { navigate } from "gatsby";
import client from "../api";

// constants
import { NOTIFICATIONS } from "@constants";
import { notificationsState, teamState } from "@atoms";
import { NotificationSeverity } from "@types";
import { isArrayWithContent } from "@utils";

/**
 * Use Axios hook
 */
export const useAxios = (
    params: any = {},
    defaultOptions: any = {},
    reset?: boolean,
) => {
    const [response, setResponse] = useState(undefined);
    const [error, setError] = useState(undefined);
    const [loading, setLoading] = useState<boolean>(false);

    const forceReset = () => {
        setResponse(undefined);
        setError(undefined);
    };

    const [team] = useRecoilState<any>(teamState);

    /**
     * update notifications atom with errors
     */
    const [_, setNotifications] = useRecoilState(notificationsState);
    const fetchData = async (
        paramsOverride: any = {},
        optionsOverride: any = {},
    ) => {
        // Reset errors
        setError(undefined);
        const options = { ...defaultOptions, ...optionsOverride };

        try {
            setLoading(true);
            const result: any = await client.request({
                headers: { "X-Team-Id": team?.teamId },
                ...params,
                ...paramsOverride,
            });
            setResponse(result);
            if (options?.successHandler) {
                setNotifications({
                    severity: NOTIFICATIONS.SEVERITY
                        .SUCCESS as NotificationSeverity,
                    message: `${options.successHandler}`,
                    autoHide: true,
                });
            }
            if (reset) setError(undefined);
            return result;
        } catch (error: any) {
            // Capture 401 requests, assume unauthorized user. Force a document request so
            // the server can do a silent SSO session refresh. Try to land on the same page
            // as where the user currently is.

            if (error.status === 401) {
                window.location.href = window.location.pathname;
                return; // Nothing after this really matters.
            }

            // Handle API 401 status
            // if the user is not assigned to any team then we need to show 401 page
            if (
                error?.response?.status === 401 &&
                error?.response?.data?.source === "SCP"
            ) {
                navigate("/401");
                return;
            }

            setError(error);

            if (error.response?.data || error.message) {
                // In some api's we handle the error inside an input field
                // for instance: show the uniqueness of a certain value, we need to hide the snackbar error in that case
                if (
                    !options?.noSnackbarStatuses ||
                    !isArrayWithContent(options?.noSnackbarStatuses) ||
                    !error?.response?.status ||
                    !options?.noSnackbarStatuses?.includes(
                        error?.response?.status,
                    )
                ) {
                    setNotifications({
                        severity: NOTIFICATIONS.SEVERITY
                            .ERROR as NotificationSeverity,
                        message: options.errorHandler
                            ? `${options.errorHandler}, ${
                                  error.response?.data?.message ||
                                  error?.message
                              }`
                            : error.response?.data?.message || error?.message,
                        autoHide: true,
                    });
                }
            }
            if (reset) setResponse(undefined);
            return error;
        } finally {
            setLoading(false);
        }
    };

    return {
        response,
        error,
        loading,
        fetch: fetchData,
        forceReset,
    };
};
