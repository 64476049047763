import { isSuccessfulCall } from "@utils";
import { useAxios } from "./useAxios";
import type { Api } from "@types";

const BASE_URI = `/v0`;

export const useValidateDuplication = (path: string) => {
    const url = `${BASE_URI}`;

    const {
        response,
        loading,
        fetch: loadList,
        error,
        forceReset,
    }: Api = useAxios(
        {
            method: "POST",
            url: `${url}`,
        },
        { noSnackbarStatuses: [409] }, // Don't show snackbar error if the error is Invalid or conflict ( only for this api call)
        true,
    );

    return {
        response,
        loading,
        error,
        isUniqueValue: !loading && isSuccessfulCall(response?.status),
        isNotUniqueValue:
            // Check if Invalid or conflict
            !loading && error?.response?.status === 409,
        isNotUniqueMessage:
            !loading && error?.response?.status === 409
                ? error?.response?.data?.message
                : "",
        validate: (id: string | undefined, data: any, query?: string) => {
            const apiSchema = {
                contractRef: {
                    url: query
                        ? `contracts/createContract/validate${query}`
                        : `contracts/createContract/validate`,
                    data,
                },
                bpfName: { url: `bpf/validate`, data },
                paymentReference: { url: `bpf/validate`, data },
                bpfId: {
                    url: `maintenance/financialTransactionValue/validate`,
                    data,
                },
                dmnId: { url: `tasks/${id}/createDMN/validate`, data },
            };

            apiSchema[path]?.data
                ? loadList({
                      url: `${url}/${apiSchema[path].url}`,
                      data: apiSchema[path].data,
                  })
                : loadList({ url: `${url}/${apiSchema[path].url}` });
        },
        forceReset,
    };
};
