// Lib
import { Typography, Box } from "@mui/material";
import { navigate } from "gatsby";
import React, { Fragment, useMemo } from "react";

// Own components
import { SearchBar, Table } from "@components";

// Constants
import { HEADERS, ROWRENDERERCONST } from "@constants";

// Hooks
import { useMaintenance, useGetTeams, useViewingOptions } from "@hooks";

// Utils
import { isArrayWithContent } from "@utils";

/**
 * Props type
 */
interface Props {
    id?: string;
}

/**
 * Teams overview
 */
const ContractTeamsOverview = ({ id }: Props) => {
    /**
     * Hooks
     */
    const { viewingOptions, setViewingOptions } = useViewingOptions(
        ROWRENDERERCONST.CONTRACT_TEAMS,
    );

    /**
     * Get team list
     */
    const { response: teams, loading: loadingTeams } = useGetTeams();

    /**
     * Fetch countries
     */

    const {
        list: countries,
        loading: { listLoading: loadingCountries },
    } = useMaintenance("country", "country|countries");

    /**
     * Therapeutic areas
     */

    const {
        list: therapeuticAreas,
        loading: { listLoading: loadingTherapeuticArea },
    } = useMaintenance("therapeuticArea", "therapeuticArea|therapeuticAreas");

    /**
     * Map TherapeuticArea & countries
     */
    const teamsMapper = (field: string, arr: Array<any>) => {
        if (
            !field ||
            !isArrayWithContent(arr) ||
            !isArrayWithContent(teams?.data?.records) ||
            !isArrayWithContent(countries?.maintenanceCountryList) ||
            !isArrayWithContent(
                therapeuticAreas?.maintenanceTherapeuticAreaList,
            )
        )
            return;

        if (field === "therapeuticAreas") {
            const filteredTherapeuticArea =
                therapeuticAreas.maintenanceTherapeuticAreaList.filter(
                    element => arr.includes(element.therapeuticAreaId),
                );
            return filteredTherapeuticArea
                .map(el => el.therapeuticAreaName)
                .join(", ");
        }
    };

    /**
     * Reverse table data
     */
    const data = useMemo(() => {
        if (!isArrayWithContent(teams?.data?.records)) return [];
        const reversed = teams.data.records.reverse();
        return reversed;
    }, [teams?.data?.records]);

    /**
     * Render
     */
    return (
        <Fragment>
            <Box mt={2} mb={4} display="flex" justifyContent="flex-end">
                <SearchBar
                    id={`${id}-contract-teams-search`}
                    filterKey={ROWRENDERERCONST.CONTRACT_TEAMS}
                    setViewingOptions={setViewingOptions}
                    viewingOptions={viewingOptions}
                    action={{
                        label: "New team",
                        callback: () => navigate("/contract-team/create-team/"),
                    }}
                    hasFilters={false}
                />
            </Box>
            <Box mt={5}>
                <Box display="flex" alignItems="baseline" mb={4}>
                    <Typography variant="h2">Teams</Typography>
                    {isArrayWithContent(teams?.data?.records) && (
                        <Typography ml={1} variant="caption1">
                            {teams?.data?.records.length} teams found
                        </Typography>
                    )}
                </Box>
                <Table
                    id={`${id}-contract-teams-list`}
                    headers={HEADERS.CONTRACT_TEAMS}
                    rows={data || []}
                    loading={
                        loadingTeams ||
                        loadingCountries ||
                        loadingTherapeuticArea
                    }
                    type={ROWRENDERERCONST.CONTRACT_TEAMS}
                    callbacks={{
                        onEditTeam: option =>
                            navigate(
                                `/contract-team/edit-team/${option.teamId}/`,
                            ),
                    }}
                    viewingOptions={viewingOptions}
                    mapper={teamsMapper}
                    emptyMsg
                />
            </Box>
        </Fragment>
    );
};
export default React.memo(ContractTeamsOverview);
