// extracted by mini-css-extract-plugin
export var boxWrapper = "style-module--boxWrapper--166c6";
export var card = "style-module--card--fad45";
export var contentWrapper = "style-module--contentWrapper--97501";
export var error = "#ff3131";
export var header = "style-module--header--42458";
export var primary = "#036";
export var secondary = "#019cdc";
export var textfont = "\"Verdana Pro\"";
export var titlefont = "\"Karbon\"";
export var white = "#fff";