//lib
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React from "react";

/**
 * Props type
 */
interface Props {
    referenceId: string;
    id: string;
}

/**
 * Contract overview
 */
const ContractOverview = ({ referenceId, id }: Props) => {
    return (
        <Box display="flex" flexDirection="column" height={1}>
            <Typography variant="h2">
                Your contract was submitted successfully
            </Typography>

            <Box mt={7} mb={3}>
                <Typography variant="h3">Contract reference</Typography>
            </Box>
            {referenceId && (
                <Typography
                    variant="subtitle2"
                    id={`${id}-reference-id`}
                >{`#${referenceId}`}</Typography>
            )}
        </Box>
    );
};

export default ContractOverview;
