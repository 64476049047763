import { atom } from "recoil";

export const generatePerformanceBasedBpfStepsState = atom({
    key: "generatePerformanceBasedBpfStepsState",
    default: [
        {
            id: "select-contract",
            title: "Select contract",
            description: "",
            isPrepared: false,
            isCompleted: false,
        },
        {
            id: "select-outcomes",
            title: "Select outcomes",
            description: "",
            isPrepared: false,
            isCompleted: false,
        },
    ],
});

export const generateApheresisBasedBpfStepsState = atom({
    key: "generateApheresisBasedBpfStepsState",
    default: [
        {
            id: "select-contract",
            title: "Select contract",
            description: "",
            isPrepared: false,
            isCompleted: false,
        },
        {
            id: "select-outcomes",
            title: "Select outcomes",
            description: "",
            isPrepared: false,
            isCompleted: false,
        },
    ],
});

export const generateVolumeBasedBpfStepsState = atom({
    key: "generateVolumeBasedBpfStepsState",
    default: [
        {
            id: "select-contract",
            title: "Select contract",
            description: "",
            isPrepared: false,
            isCompleted: false,
        },
        {
            id: "select-claims",
            title: "Select claims",
            description: "",
            isPrepared: false,
            isCompleted: false,
        },
    ],
});

export const generateBpfState = atom({
    key: "generateBpfState",
    default: {
        outcomes: [],
        claims: [],
        priceCorrectionClaims: [],
        bpfName: "",
        paymentReference: "",
        contractId: "",
    },
});

export const creditNoteDetailsStepState = atom({
    key: "creditNoteDetailsStepState",
    default: [
        {
            id: "credit-note-details",
            title: "View details",
            description: "",
            isPrepared: false,
        },
    ],
});
