//Lib
import {
    Box,
    Typography,
    Grid,
    InputLabel,
    TextField,
    FormHelperText,
    CircularProgress,
} from "@mui/material";

import React, { Fragment } from "react";

// Own components
import { Dialog, InfoBox } from "@components";

// Styles

import * as style from "../style.module.scss";

/**
 * Props
 */
interface Props {
    onBlur: (event: React.SyntheticEvent) => void;
    onChange: any;
    errors: Record<string, string>;
    touched: Record<string, string>;
    values: Record<string, string>;
    id: string;
    contractRef: string;
    validateDmnId: boolean;
    openDialog: boolean;
    onDmnUpload: () => void;
    loading: boolean;
    onReset: () => void;
    taskId: string;
    validatingDmn?: boolean;
    isNotUniqueValue: boolean;
    className?: string;
}

/**
 * Task Contract View
 */
const UploadDmn = ({
    id,
    onBlur,
    onChange,
    errors,
    touched,
    contractRef,
    values,
    validateDmnId,
    openDialog,
    loading,
    validatingDmn,
    isNotUniqueValue,
    onReset,
    onDmnUpload,
    className,
}: Props) => {
    /**
     * API
     */

    return (
        <Fragment>
            <Dialog
                id={`tasks-submit-dmn`}
                title={`Submit DMN table`}
                message={
                    validatingDmn ? (
                        <Box
                            height={1}
                            justifyContent="center"
                            display="flex"
                            minHeight="30rem"
                            alignItems="center"
                        >
                            <CircularProgress />
                        </Box>
                    ) : (
                        <Fragment>
                            <Typography variant="subtitle2" color="black">
                                You are about to submit the DMN table and
                                consequently mark task
                                <Typography variant="body2" component="span">
                                    {` Setup rules table [${contractRef}] `}
                                </Typography>
                                as complete. Doing so will trigger other actions
                                within SCP. This action cannot be undone.
                            </Typography>

                            {isNotUniqueValue && (
                                <Box mt={5}>
                                    <InfoBox
                                        id="dmn-submitting-warning"
                                        warning
                                        warningColor
                                        message={`WARNING: The DMN ID you entered already exists within SCP. This will cause you to override the existing DMN table and may cause issues with other contracts.`}
                                    />
                                </Box>
                            )}
                        </Fragment>
                    )
                }
                open={openDialog}
                primaryButton={{
                    text: "Submit DMN table",
                    action: onDmnUpload,
                    loading: loading,
                    disabled: loading,
                }}
                secondaryButton={{
                    text: "Cancel",
                    action: onReset,
                    disabled: loading,
                }}
                fullWidth
            />

            <Typography variant="h2" className={style.header}>
                {`Setup rules table [${contractRef}]`}
            </Typography>

            <form noValidate onBlur={onBlur} className={className}>
                <Box mt={3} mb={2}>
                    <Typography variant="h3">
                        {`Upload DMN table [${contractRef}]`}
                    </Typography>
                </Box>
                <Grid container spacing={2} mt={4}>
                    {/*  <Grid xs={12} md={5} item>
                <InputLabel
                error={
                    !!formik.errors?.xml &&
                    !!formik.touched?.xml
                }
                shrink
                >
                {"XML file (*)"}
                </InputLabel>
                <TextField
                fullWidth
                name="xml"
                autoComplete="off"
                value={formik.values?.xml}
                variant="outlined"
                size="small"
                onChange={formik.handleChange}
                error={
                    !!formik.errors?.xml &&
                    !!formik.touched?.xml
                }
                />
                </Grid> */}

                    <Grid xs={12} md={6} item>
                        <InputLabel
                            error={!!errors?.dmnId && !!touched?.dmnId}
                            shrink
                        >
                            {"DMN ID (*)"}
                        </InputLabel>
                        <TextField
                            id={`${id}-task-details-dmn-id`}
                            fullWidth
                            name="dmnId"
                            autoComplete="off"
                            value={values?.dmnId}
                            variant="outlined"
                            size="small"
                            onChange={onChange}
                            error={!!errors?.dmnId && !!touched?.dmnId}
                        />
                        {!!values?.dmnId && !validateDmnId && (
                            <FormHelperText error>
                                DMN ID must not contain special characters
                            </FormHelperText>
                        )}
                    </Grid>
                </Grid>
            </form>
        </Fragment>
    );
};

export default React.memo(UploadDmn);
