//import { useApi } from "../api";
import { useAxios } from "./useAxios";
import { useEffect } from "react";
import { useRecoilState } from "recoil";

// Types
import type { Api } from "@types";

// AToms
import { notificationsState } from "@atoms";

// Constants
import { NOTIFICATIONS } from "@constants";

// Utils
import { isSuccessfulCall, startDownload } from "@utils";

const BASE_URI = `/v0/contracts`;

/**
 * A hook that allows interaction with an already created contract
 */
export const useCreateContract = (type?: string) => {
    const { response, loading, error, fetch }: Api = useAxios();

    return {
        createContract: (data: any) =>
            fetch(
                {
                    method: "POST",
                    url: `${BASE_URI}`,
                    data,
                },
                {
                    errorHandler: type
                        ? "Failed to duplicate contract"
                        : "Failed to create contract",
                    successHandler: type
                        ? "Contract was successfully duplicated"
                        : undefined,
                },
            ),
        error,
        loading,
        response,
    };
};

/**
 * Fetch contracts list
 */
export const useGetContracts = () => {
    const url = `${BASE_URI}`;

    const {
        response: list,
        loading: listLoading,
        fetch: loadList,
        error: listError,
    }: Api = useAxios(
        {
            method: "GET",
            url,
        },
        { errorHandler: `Failed to fetch contracts` },
    );

    return {
        list: list,
        loading: listLoading,
        error: listError,
        reload: () => loadList(),
        search: (searchParams: string) =>
            loadList({
                url: `${url}${searchParams}`,
            }),
    };
};

/**
 * Fetch contract by id
 */
export const useGetContract = () => {
    const { response, loading, error, fetch }: Api = useAxios();
    return {
        getContract: (contractId: string) =>
            fetch(
                {
                    method: "GET",
                    url: `${BASE_URI}/${contractId}`,
                },
                { errorHandler: "Failed to fetch contract" },
            ),

        error,
        loading,
        response,
    };
};

/**
 * Cancel contract
 */
export const useCancelContract = () => {
    const {
        response: list,
        loading: listLoading,
        fetch: cancelContract,
        error: listError,
    }: Api = useAxios(
        {
            method: "POST",
        },
        {
            errorHandler: "Failed to cancel contract",
            successHandler: "Contract was successfully canceled",
        },
    );

    return {
        list: list,
        loading: listLoading,
        error: listError,
        cancel: (contractId: string) =>
            cancelContract({
                url: `${BASE_URI}/${contractId}/cancel`,
            }),
    };
};

/**
 * Update contract milestones
 */
export const useEditContract = () => {
    const { response, loading, error, fetch }: Api = useAxios();
    return {
        updateContract: (contractId: string, data: any) =>
            fetch(
                {
                    method: "PUT",
                    url: `${BASE_URI}/${contractId}`,
                    data,
                },
                {
                    errorHandler: "Failed to update contract",
                    successHandler: "Contract was successfully updated",
                },
            ),

        error,
        loading,
        response,
    };
};

export const useGetFinancialConditions = () => {
    const { response, loading, error, fetch }: Api = useAxios();

    return {
        getFinancialConditions: () =>
            fetch(
                {
                    method: "GET",
                    url: `${BASE_URI}/financialConditions`,
                },
                { errorHandler: "Failed to fetch financial conditions" },
            ),
        error,
        loading,
        response,
    };
};

/**
 * Check the contractRef if unique is or not
 */
export const useCheckContractRef = () => {
    const { response, loading, error, fetch }: Api = useAxios();
    return {
        checkContractRef: (contractRef: string) =>
            fetch(
                {
                    method: "POST",
                    url: `${BASE_URI}/createContract/validate`,
                    data: { contractRef },
                },
                {
                    errorHandler: "Failed to check contract reference",
                    noSnackbarStatuses: [409],
                },
            ),

        error,
        loading,
        response,
    };
};

/**
 * Fetch contract statuses
 */

export const useContractStatuses = () => {
    const {
        response: list,
        loading: listLoading,
        fetch: load,
        error: listError,
    }: Api = useAxios(
        {
            method: "GET",
            url: `${BASE_URI}/statuses`,
        },
        {
            errorHandler: "Failed to fetch contract statuses",
        },
    );

    useEffect(() => {
        load();
    }, []);

    return {
        loading: listLoading,
        error: listError,
        list,
    };
};

/**
 * Upload a document to the contract
 */

export const useUploadDocument = () => {
    /**
     * update dmn file
     */
    const [_, setNotifications] = useRecoilState(notificationsState);

    const {
        response,
        loading: uploading,
        fetch: uploadFile,
        error: uploadError,
    }: any = useAxios(
        {
            method: "POST",
            url: `${BASE_URI}`,
        },
        { errorHandler: `Failed to upload the file` },
    );

    /**
     * Helpers
     */
    // Convert to base64
    const convertBase64 = file => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);

            fileReader.onload = () => {
                resolve(fileReader.result);
            };

            fileReader.onerror = error => {
                reject(error);
            };
        });
    };

    const {
        response: list,
        loading: fetching,
        fetch: load,
        error: fetchError,
    }: any = useAxios(
        {
            method: "GET",
            url: `${BASE_URI}`,
        },
        { errorHandler: `Failed to fetch files` },
    );

    const { loading: downloading, fetch: download }: any = useAxios(
        {
            method: "GET",
            url: `${BASE_URI}`,
        },
        { errorHandler: `Failed to download files` },
    );

    return {
        loading: { uploading, fetching, downloading },
        error: { uploadError, fetchError },
        response,
        list,
        load: (contractId: string) => {
            load({
                url: `${BASE_URI}/${contractId}/evidences`,
            });
        },
        download: (contractId, evidenceId: string) => {
            /**
             * Download handler
             */

            download({
                url: `${BASE_URI}/${contractId}/evidences/${evidenceId}`,
            }).then(res => {
                if (isSuccessfulCall(res?.status) && res?.data?.location) {
                    startDownload(res?.data?.location);
                }
            });
        },
        upload: async (file: any, contractId: string) => {
            const base64File = await convertBase64(file);

            return await uploadFile({
                data: {
                    fileData: base64File,
                    fileName: file?.name,
                },
                url: `${BASE_URI}/${contractId}/evidences`,
            }).then(res => {
                if (isSuccessfulCall(res?.status)) {
                    setNotifications({
                        severity: NOTIFICATIONS.SEVERITY.SUCCESS as any,
                        message: `"${file?.name}" was successfully uploaded`,
                        autoHide: true,
                    });
                }
                return res;
            });
        },
    };
};

/**
 * Fetch contracts model
 */
export const useGetContractModel = (type: string, getList = true) => {
    const url = `${BASE_URI}/datamodels?datamodelType=${type}`;

    const {
        response: list,
        loading: listLoading,
        fetch: loadList,
        error: listError,
    }: Api = useAxios(
        {
            method: "GET",
            url,
        },
        { errorHandler: `Failed to fetch contract data model` },
    );

    useEffect(() => {
        if (!getList) return;
        let unmounted = false;
        if (!unmounted) {
            loadList();
        }
        return () => {
            unmounted = true;
        };
    }, []);

    return {
        list: list,
        loading: listLoading,
        error: listError,
        load: () => loadList(),
    };
};

/**
 * Fetch contract model category
 */
export const useGetContractCategory = () => {
    const url = `${BASE_URI}/categories`;

    const {
        response: list,
        loading: listLoading,
        fetch: loadList,
        error: listError,
    }: Api = useAxios(
        {
            method: "GET",
            url,
        },
        { errorHandler: `Failed to fetch contract categories` },
    );

    return {
        list: list,
        loading: listLoading,
        error: listError,
        load: (datamodelId: string) =>
            loadList({
                url: `${url}?datamodelId=${datamodelId}`,
            }),
    };
};

/**
 * Change contract date
 */
export const useChangeContractDate = () => {
    const {
        response: list,
        loading: listLoading,
        fetch: loadList,
        error: listError,
    }: Api = useAxios(
        {
            method: "POST",
        },
        { errorHandler: `Failed to change contract date` },
    );

    return {
        list: list,
        loading: listLoading,
        error: listError,
        load: ({
            contractId,
            validFrom,
            validTo,
            comment,
        }: {
            contractId: string;
            validFrom: string;
            validTo: string;
            comment: string | null;
        }): Promise<any> =>
            loadList({
                url: `${BASE_URI}/${contractId}/changeDate`,
                data: {
                    validFrom,
                    validTo,
                    comment,
                },
            }),
    };
};

/**
 * Change contract date
 */
export const useChangeContractIcd = () => {
    const {
        response: list,
        loading: listLoading,
        fetch: loadList,
        error: listError,
    }: Api = useAxios(
        {
            method: "POST",
        },
        {
            errorHandler: `Failed to change contract ICD`,
            successHandler: "Contract ICD was successfully updated",
        },
    );

    return {
        list: list,
        loading: listLoading,
        error: listError,
        load: ({
            contractId,
            icdNo,
            icdUrl,
        }: {
            contractId: string;
            icdNo: string | "";
            icdUrl: string | "";
        }): Promise<any> =>
            loadList({
                url: `${BASE_URI}/${contractId}/changeIcd`,
                data: {
                    icdNo,
                    icdUrl,
                },
            }),
    };
};
