import { useEffect } from "react";
import { useRecoilState } from "recoil";

// constants
import { NOTIFICATIONS } from "@constants";
import { notificationsState } from "@atoms";
import { useAxios } from "./useAxios";

import type { Api, NotificationSeverity } from "@types";
import { isSuccessfulCall } from "@utils";

const BASE_URI = `/v0`;

/**
 * Maintenance
 */
export const useMaintenance = (path: string, type: string, getList = true) => {
    const url = `${BASE_URI}/maintenance/${path}`;

    /**
     * update notifications atom with success messages
     */
    const [_, setNotifications] = useRecoilState(notificationsState);

    const {
        response: list,
        loading: listLoading,
        fetch: loadList,
        error: listError,
    }: Api = useAxios(
        {
            method: "GET",
            url,
        },
        { errorHandler: `Failed to fetch ${type.split("|")[1]}` },
    );

    const {
        loading: creating,
        error: createError,
        fetch: create,
    }: Api = useAxios(
        {
            method: "POST",
            url,
        },
        { errorHandler: `Failed to add / update ${type.split("|")[0]}` },
    );

    const {
        error: deleteError,
        fetch: deletion,
        loading: deleting,
    }: Api = useAxios(
        {
            method: "DELETE",
            url,
        },
        { errorHandler: `Failed to delete ${type.split("|")[0]}` },
    );

    useEffect(() => {
        if (!getList) return;

        let unmounted = false;
        if (!unmounted) {
            loadList();
        }

        return () => {
            unmounted = true;
        };
    }, []);

    return {
        list: list?.data || [],
        loading: { creating, listLoading, deleting },
        error: {
            list: listError,
            create: createError,
            delete: deleteError,
        },
        reload: (query?: string) =>
            loadList({
                url: query ? `${url}${query}` : url,
            }),
        /**
         * edit or create a existing list item, requests without id will create a new list item
         */
        upsert: async (listItem: any, savedItem: string, method?: string) =>
            await create({
                data: listItem,
                method: method ? method : "POST",
            }).then(res => {
                if (isSuccessfulCall(res?.status)) {
                    setNotifications({
                        severity: NOTIFICATIONS.SEVERITY
                            .SUCCESS as NotificationSeverity,
                        message: `"${savedItem}" was successfully saved`,
                        autoHide: true,
                    });
                    //await loadList();
                    loadList();
                }
                return res;
            }),

        delete: (id: string, label: string) => {
            return deletion({
                url: `${url}/${id}`,
            }).then(res => {
                if (isSuccessfulCall(res?.status)) {
                    setNotifications({
                        severity: NOTIFICATIONS.SEVERITY
                            .SUCCESS as NotificationSeverity,
                        message: `"${label}" was successfully deleted`,
                        autoHide: true,
                    });
                    loadList();
                    return res;
                }
            });
        },
    };
};

/**
 * Custom API hook
 * Fetch linkable billing plans
 * Link billings plan to a milestone
 */
export const useMaintenanceMilestone = () => {
    const url = `${BASE_URI}/maintenance/milestone`;

    /**
     * update notifications atom with success messages
     */
    const [_, setNotifications] = useRecoilState(notificationsState);

    const {
        response: milestoneList,
        loading: fetchingMilestones,
        fetch: loadMilestonelist,
        error: milestonelistError,
    }: Api = useAxios(
        {
            method: "GET",
            url,
        },
        { errorHandler: `Failed to fetch milestone list` },
    );

    const {
        response: billingPlanList,
        loading: fetchingBillingPlans,
        fetch: loadBillingPlanList,
        error: billingPlanListError,
    }: Api = useAxios(
        {
            method: "GET",
            url,
        },
        { errorHandler: `Failed to fetch billing plans` },
    );

    const {
        loading: linkingBillingPlan,
        error: linkingBillingPlanError,
        fetch: linkBillingPlan,
    }: Api = useAxios(
        {
            method: "POST",
            url,
        },
        { errorHandler: `Failed to link billing plan to milestone` },
    );

    useEffect(() => {
        let unmounted = false;

        if (!unmounted) {
            loadMilestonelist();
        }

        return () => {
            unmounted = true;
        };
    }, []);

    return {
        list: {
            billingPlanList: billingPlanList?.data || [],
            milestoneList: milestoneList?.data || [],
        },
        loading: {
            fetchingBillingPlans,
            fetchingMilestones,
            linkingBillingPlan,
        },
        error: {
            linkingBillingPlanError,
            billingPlanListError,
            milestonelistError,
        },
        reload: () => loadMilestonelist(),
        /**
         * Link billing plan to milestone
         */
        load: {
            loadBillingPlanList: (milestoneId: string) =>
                loadBillingPlanList({
                    url: `${url}/${milestoneId}/linkableBillingPlans`,
                }),
        },
        upsert: {
            linkBillingPlan: async (
                milestoneId: string,
                billingPlanId: string,
            ) =>
                await linkBillingPlan({
                    url: `${url}/${milestoneId}/billingPlan`,
                    data: { billingPlanId },
                }).then(res => {
                    if (isSuccessfulCall(res?.status)) {
                        setNotifications({
                            severity: NOTIFICATIONS.SEVERITY
                                .SUCCESS as NotificationSeverity,
                            message: `Billing plan was successfully linked to milestone`,
                            autoHide: true,
                        });
                        loadMilestonelist();
                    }
                    return res;
                }),
        },
    };
};

/**
 * Link model to categories
 */
export const useLinkCategoriesToModel = () => {
    const url = `${BASE_URI}/maintenance/datamodel`;

    /**
     * update notifications atom with success messages
     */
    const [_, setNotifications] = useRecoilState(notificationsState);

    const {
        loading: fetching,
        error: loadError,
        fetch: loadLinkedCategories,
    }: Api = useAxios(
        {
            method: "GET",
            url,
        },
        { errorHandler: `Failed to fetch linked categories` },
    );

    const {
        loading: creating,
        error: createError,
        fetch: linkCategories,
    }: Api = useAxios(
        {
            method: "POST",
            url,
        },
        { errorHandler: `Failed to update the model` },
    );

    return {
        loading: { fetching, creating },
        error: { loadError, createError },
        load: datamodelId =>
            loadLinkedCategories({ url: `${url}/${datamodelId}/categories` }),
        upsert: (datamodelId: string, categories: string[]) =>
            linkCategories({
                url: `${url}/${datamodelId}/categories`,
                data: { categories },
            }).then(res => {
                if (isSuccessfulCall(res?.status)) {
                    setNotifications({
                        severity: NOTIFICATIONS.SEVERITY
                            .SUCCESS as NotificationSeverity,
                        message: `Model was successfully updated`,
                        autoHide: true,
                    });
                }
                return res;
            }),
    };
};
