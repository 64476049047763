import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import { isArrayWithContent } from "@utils";

type Props = {
    color?:
        | "primary"
        | "inherit"
        | "secondary"
        | "success"
        | "error"
        | "info"
        | "warning";
    variant?: "text" | "outlined" | "contained";
    disableElevation?: boolean;
    disabled?: boolean;
    items: Array<{ key: string; label: string; hidden?: boolean }>;
    id: string;
    label: string;
    onSelect: (event: React.BaseSyntheticEvent, key: string) => void;
    startIcon?: React.ReactNode;
};

/**
 * Basic Buttons menu
 */
const ButtonsMenu = ({
    items,
    id,
    onSelect,
    color = "primary",
    disabled = false,
    variant = "contained",
    disableElevation = true,
    label,
    startIcon,
}: Props) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <Button
                id={`${id}-button`}
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                startIcon={startIcon}
                color={color}
                disabled={disabled}
                disableElevation={disableElevation}
                variant={variant}
            >
                <Typography variant="button">{label}</Typography>
            </Button>
            <Menu
                id={`${id}-buttons-menu`}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    "aria-labelledby": "basic-button",
                }}
            >
                {isArrayWithContent(items) &&
                    items.map(
                        (item, index) =>
                            !item?.hidden && (
                                <MenuItem
                                    key={index}
                                    onClick={e => {
                                        onSelect(e, item.key);
                                        handleClose();
                                    }}
                                >
                                    {item.label}
                                </MenuItem>
                            ),
                    )}
            </Menu>
        </div>
    );
};

export default ButtonsMenu;
