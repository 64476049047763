// extracted by mini-css-extract-plugin
export var error = "#ff3131";
export var header = "style-module--header--954c4";
export var headerItem = "style-module--headerItem--8ce45";
export var loadingOrNoContent = "style-module--loadingOrNoContent--f03a3";
export var primary = "#036";
export var secondary = "#019cdc";
export var tableContainer = "style-module--tableContainer--f3baf";
export var textfont = "\"Verdana Pro\"";
export var titlefont = "\"Karbon\"";
export var white = "#fff";