/**
 * Check API call status
 */
export const isSuccessfulCall = (status: number) => {
    if (!status || typeof status !== "number") return false;
    return status >= 200 && status < 300;
};

/*
 *  Check if API is fully loaded
 */
export const apiResponseCounter = (
    response: any,
    loading: boolean | undefined,
    text: string,
) => {
    if (!loading && isSuccessfulCall(response?.status)) {
        const arrLength = Array.isArray(response?.data)
            ? response?.data?.length
            : response?.data?.records?.length;

        return !!arrLength && arrLength > 0
            ? `${arrLength} ${
                  arrLength === 1 ? text.split("|")[0] : text.split("|")[1]
              } found`
            : `No ${text.split("|")[1]} found`;
    } else return "";
};

// Download files
export const startDownload = (uri: string) => {
    if (!document?.body) throw new Error("Error, missing <body>.");

    const link = document.createElement("a");
    link.href = uri;
    link.style.display = "none";
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};
