// Libs
import { TableCell, TableRow, Typography, Checkbox, Box } from "@mui/material";
import React from "react";

// Utils
import { displayDate, thousandsSeparator } from "@utils";

interface Row {
    outcomeId: string;
    productName: string;
    therapeuticAreaName: string;
    contractRef: string;
    salessystemOrderId: string;
    amount: number;
    country: string;
    endDate: string;
    createdAt: string;
    sapSkuNo: string;
}

/**
 * Props type
 */
interface Props {
    row: Row;
    rowIndex: number;
    callbacks: any;
    id: string;
    disabled?: boolean;
}

/**
 * Finances Outcomes
 */
const FinancesOutcomes = ({
    id,
    row,
    rowIndex,
    callbacks,
    disabled,
}: Props) => {
    return (
        <TableRow
            id={`${id}-row-${rowIndex}`}
            selected={callbacks?.onFindOutcome(row.outcomeId)}
            onClick={() => {
                if (!disabled) {
                    callbacks?.onOutcomeSelect(row.outcomeId);
                }
            }}
            sx={{ cursor: "pointer" }}
        >
            <TableCell>
                <Checkbox
                    checked={callbacks?.onFindOutcome(row.outcomeId)}
                    disabled={disabled}
                />
            </TableCell>

            <TableCell>
                <Typography variant="caption2">{row.contractRef}</Typography>
            </TableCell>

            <TableCell>
                <Typography variant="caption2">
                    {row.therapeuticAreaName}
                </Typography>
            </TableCell>

            <TableCell>
                <Typography variant="caption2">{row.productName}</Typography>
            </TableCell>

            <TableCell>
                <Typography variant="caption2">{row.sapSkuNo}</Typography>
            </TableCell>

            <TableCell>
                <Typography variant="caption2">
                    {row.salessystemOrderId}
                </Typography>
            </TableCell>

            <TableCell>
                <Box display="flex" flexDirection="column">
                    <Typography variant="caption2">
                        {displayDate(row.createdAt)}
                    </Typography>
                    <Typography variant="caption2">
                        {displayDate(row.endDate)}
                    </Typography>
                </Box>
            </TableCell>

            <TableCell>
                <Typography variant="caption2">
                    {thousandsSeparator(row.amount)}
                </Typography>
            </TableCell>
        </TableRow>
    );
};

export default FinancesOutcomes;
