//import { useApi } from "../api";

import { useAxios } from "./useAxios";
import { useRecoilState } from "recoil";
// Atoms
import { teamState } from "@atoms";

// Hooks
import { useAccountTeams } from "@hooks";

// Types
import type { Api } from "@types";
import { isArrayWithContent, isSuccessfulCall } from "@utils";
import { useEffect } from "react";

const BASE_URI = `/v0`;

/**
 * Create Team
 * Fetch team by id
 */
export const useGetTeam = () => {
    const { response, loading, error, fetch }: Api = useAxios();

    return {
        getTeam: (teamId: string) =>
            fetch(
                {
                    method: "GET",
                    url: `${BASE_URI}/teams/${teamId}`,
                },
                { errorHandler: "Failed to fetch the team" },
            ),
        error,
        loading,
        response,
    };
};

/**
 * Create Team
 * update team by id
 */
export const useUpdateTeam = () => {
    const { response, loading, error, fetch }: Api = useAxios();
    const [team, storeTeam] = useRecoilState<any>(teamState);
    const { getAccountTeams } = useAccountTeams();

    return {
        updateTeam: (teamId: string, data: any) =>
            fetch(
                {
                    method: "PUT",
                    url: `${BASE_URI}/teams/${teamId}`,
                    data,
                },
                { errorHandler: "Failed to update the team" },
            ).then(res => {
                // if the team is already stored in the local storage
                // we need to update it with the last user rights
                // fetching /team/profile endpoint and find the team then update the local storage
                if (
                    res &&
                    isSuccessfulCall(res?.status) &&
                    !!team &&
                    team.teamId === teamId
                ) {
                    getAccountTeams().then(res => {
                        if (
                            !!res &&
                            isSuccessfulCall(res?.status) &&
                            isArrayWithContent(res?.data?.records)
                        ) {
                            const updatedTeam = res?.data?.records?.find(
                                team => team.teamId === teamId,
                            );

                            storeTeam(updatedTeam);
                        }
                    });
                }
                // important to return the whole res, to store the ID and show the confirmation page
                return res;
            }),
        error,
        loading,
        response,
    };
};

/**
 * Create Team
 * create new team
 */
export const useCreateTeam = () => {
    const { response, loading, error, fetch }: Api = useAxios();

    return {
        createTeam: (data: any) =>
            fetch(
                {
                    method: "POST",
                    url: `${BASE_URI}/teams`,
                    data,
                },
                { errorHandler: "Failed to create a new contract team" },
            ),
        error,
        loading,
        response,
    };
};

/**
 * Get teams list
 */
export const useGetTeams = (getList = true) => {
    const {
        response,
        loading,
        error,
        fetch: loadList,
    }: Api = useAxios(
        {
            method: "GET",
            url: `${BASE_URI}/teams`,
        },
        { errorHandler: "Failed to fetch contract teams" },
    );

    useEffect(() => {
        if (!getList) return;

        let unmounted = false;
        if (!unmounted) {
            loadList();
        }
        return () => {
            unmounted = true;
        };
    }, []);

    return {
        getTeams: loadList,
        error,
        loading,
        response,
    };
};

/**
 * Fetch user rights
 */
export const useGetRights = () => {
    const { response, loading, error, fetch }: Api = useAxios();

    return {
        getRights: () =>
            fetch(
                {
                    method: "GET",
                    url: `${BASE_URI}/rights`,
                },
                { errorHandler: "Failed to fetch team rights" },
            ),
        error,
        loading,
        response,
    };
};
