import { selector } from "recoil";

import {
    uploadClaimFileStepsState,
    claimContractSelectionState,
    claimDetailsState,
} from "@atoms";

const mapClaimForApi = (states: any) =>
    Object.assign(
        {},
        {
            contractId: states.contractSelection.contractId,
            orderId: states.orderSelection.orderId,
            outcomeVariables: states.outcomeDetails,
        },
    );

/**
 * Submit create outcome
 */
export const uploadClaimProcessMapper = selector({
    key: "uploadClaimProcessMapper",
    get: ({ get }) => {
        const contractSelection = get(claimContractSelectionState);
        const claimDetails = get(claimDetailsState);

        return mapClaimForApi({
            contractSelection,
            claimDetails,
        });
    },
    set: ({ reset }) => {
        reset(claimContractSelectionState);
        reset(claimDetailsState);
        reset(uploadClaimFileStepsState);
    },
});
