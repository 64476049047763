// Icons
import ClearIcon from "@mui/icons-material/Clear";

// Lib
import {
    Modal as _Modal,
    Typography,
    IconButton,
    Button,
    CircularProgress,
} from "@mui/material";
import classnames from "classnames";
import React from "react";

// Types
import type { Button as ButtonType } from "@types";

// Styles
import * as style from "./style.module.scss";

/**
 * Props type
 */
interface Props {
    open: boolean;
    onClose: () => void;
    id: string;
    title: string;
    children: any;
    className?: string;
    primaryButton?: ButtonType;
    secondaryButton?: ButtonType;
    smallView?: boolean;
    mediumView?: boolean;
    largeView?: boolean;
    maxHeight?: string;
    noScroll?: boolean;
    lessPadding?: boolean;
}

/**
 * Modal
 */
const Modal: React.FC<Props> = ({
    id,
    open,
    onClose,
    title,
    children,
    className,
    primaryButton,
    secondaryButton,
    smallView,
    mediumView,
    largeView,
    maxHeight,
    noScroll,
    lessPadding,
}) => {
    return (
        <_Modal
            open={open}
            onClose={onClose}
            aria-labelledby="default-modal"
            aria-describedby="default-modal"
            id={`${id}-modal`}
            sx={{
                backgroundColor: "rgba(0, 51, 102, 0.2)",
                zIndex: theme => theme.zIndex.drawer + 1,
                maxHeight,
            }}
        >
            <div
                className={classnames(
                    style.modalWrapper,
                    { [style.smallView]: smallView },
                    { [style.mediumView]: mediumView },
                    { [style.largeView]: largeView },
                    { [style.noScroll]: noScroll },
                    className,
                )}
            >
                <div className={style.header}>
                    <Typography variant="h3">{title}</Typography>
                    <IconButton size="small" onClick={onClose}>
                        <ClearIcon color="primary" />
                    </IconButton>
                </div>
                <div
                    className={classnames(style.content, {
                        [style.withScroll]: noScroll,
                        [style.lessPadding]: lessPadding,
                    })}
                >
                    {children}
                </div>

                {(!!primaryButton || !!secondaryButton) && (
                    <div className={style.footer}>
                        {!!secondaryButton && !secondaryButton?.hidden && (
                            <Button
                                size="large"
                                variant="outlined"
                                onClick={secondaryButton.action}
                                disabled={secondaryButton.disabled}
                                sx={{ marginRight: "1rem" }}
                                data-testid={secondaryButton.dataTestId}
                                id={secondaryButton?.id}
                            >
                                <Typography variant="button">
                                    {secondaryButton.text}
                                </Typography>
                            </Button>
                        )}
                        {!!primaryButton && !primaryButton?.hidden && (
                            <Button
                                size="large"
                                variant="contained"
                                onClick={primaryButton?.action}
                                disabled={
                                    primaryButton?.disabled ||
                                    primaryButton?.loading
                                }
                                id={primaryButton?.id}
                                data-testid={primaryButton.dataTestId}
                            >
                                {primaryButton?.loading ? (
                                    <CircularProgress
                                        size={18}
                                        color="inherit"
                                    />
                                ) : (
                                    <Typography variant="button">
                                        {primaryButton?.text}
                                    </Typography>
                                )}
                            </Button>
                        )}
                    </div>
                )}
            </div>
        </_Modal>
    );
};
export default React.memo(Modal);
