// Libs
import GridOn from "@mui/icons-material/GridOn";
import { navigate } from "gatsby";
import React, {
    Fragment,
    useEffect,
    useLayoutEffect,
    useMemo,
    useRef,
    useState,
} from "react";

// Own component
import {
    Breadcrumbs,
    StepActions,
    Stepper,
    LoadingWrapper,
    FlowLayout,
    FlowAside,
    FlowBody,
    FlowContent,
    FlowFooter,
} from "@components";

// Custom hooks
import {
    useFindStep,
    useGetContract,
    useEditContract,
    useCreateContract,
    useUploadDocument,
    useApiMapper,
} from "@hooks";

// Recoil
import {
    contractViewStepsState,
    editContractStepsState,
    duplicateContractStepsState,
    uploadDocumentStepsState,
} from "@atoms";

// Types
import type { Location } from "@types";
import { isSuccessfulCall } from "@utils";

/**
 * Props type
 */
interface Props {
    children: React.ReactNode;
    location: Location;
    contractId: string;
}

/**
 * Edit & view contract layout
 */
const ContractLayout = ({ location, contractId, children }: Props) => {
    //Via this ref we can save the state of each page via stepper (higher order)
    const ref = useRef<any>();
    const [isEditingContract, setDisableSaveButton] = useState(false);
    const { editContractMapper, duplicateContractMapper } = useApiMapper();
    /**
     * Find location history
     */
    const prevPath = useMemo(() => {
        if (!location) return;
        if (location.state?.prevPath?.pathname?.includes("/tasks")) {
            return {
                path: "/tasks/",
                label: "Return to tasks overview",
            };
        }
        if (
            location.state?.prevPath?.pathname?.includes("/order/add-outcome")
        ) {
            return {
                path: location.state?.prevPath?.pathname,
                label: "Return to add outcome",
            };
        }
    }, [location]);

    /**
     * API
     */

    // Update contract
    const { loading: updatingContract, updateContract } = useEditContract();

    // Duplicate contract
    const { loading: createContractLoading, createContract } =
        useCreateContract("duplicate");

    // Upload file
    const {
        loading: { uploading },
        upload: uploadDocument,
    } = useUploadDocument();

    // Fetch contract
    const {
        response,
        loading,
        getContract,
        error: getContractError,
    } = useGetContract();

    useEffect(() => {
        if (updatingContract || createContractLoading || uploading) {
            setDisableSaveButton(true);
        } else {
            setDisableSaveButton(false);
        }
    }, [updatingContract, createContractLoading, uploading]);

    /**
     * Check contract action type (Edit, duplicate, upload or view)
     * Find step state
     */
    const getContractActions = useMemo(() => {
        if (!location) return;

        const isContractView = location.pathname.includes(
            "/contract/view-contract",
        );
        const isEditContract = location.pathname.includes(
            "/contract/edit-contract",
        );
        const isDuplicateContract = location.pathname.includes(
            "/contract/duplicate-contract",
        );
        const isUploadFile = location.pathname.includes("upload-file");

        return {
            isEditContract,
            isContractView,
            isDuplicateContract,
            isUploadFile,
            stepState: isDuplicateContract
                ? duplicateContractStepsState
                : isEditContract
                  ? editContractStepsState
                  : isUploadFile
                    ? uploadDocumentStepsState
                    : contractViewStepsState,
        };
    }, [location]);

    /**
     * Steps hooks
     */
    const { currentStep } = useFindStep(
        location,
        getContractActions?.stepState,
    );

    /**
     * Fetch contract
     */
    useLayoutEffect(() => {
        if (!contractId) return;
        getContract(contractId);
    }, [contractId]);

    /**
     * Save handler
     */

    const onNextClick = () => {
        if (
            !getContractActions?.isUploadFile &&
            !getContractActions?.isDuplicateContract &&
            !getContractActions?.isEditContract &&
            !prevPath
        ) {
            navigate("/dashboard/contracts/");
            return;
        }
        if (prevPath) {
            navigate(prevPath.path);
            return;
        }

        const data = ref.current.onSubmit();

        const contract = response.data;

        if (getContractActions?.isEditContract) {
            const mappedData = editContractMapper(contract?.type, data);

            updateContract(contractId, mappedData).then(response => {
                if (isSuccessfulCall(response?.status) && response?.data?.id) {
                    navigate(`/contract/view-contract/${response.data.id}/`);
                }
            });
            return;
        }

        if (getContractActions?.isUploadFile) {
            uploadDocument(data, contractId).then(res => {
                if (!!res && isSuccessfulCall(res?.status)) {
                    ref?.current?.onReset();
                }
            });
            return;
        }

        if (getContractActions?.isDuplicateContract) {
            const mappedData = duplicateContractMapper(contract, data);
            createContract(mappedData).then(response => {
                if (!!response && isSuccessfulCall(response.status)) {
                    navigate(`/contract/view-contract/${response.data.id}/`);
                }
            });
            return;
        }
    };

    /**
     * Render
     */
    return (
        <Fragment>
            <Breadcrumbs
                id="contract-breadcrumb"
                icon={<GridOn color="primary" />}
                title="Dashboard"
                location={location}
            />

            <LoadingWrapper
                fullHeight
                id={`view-contract-loading`}
                loading={loading}
                error={getContractError}
                callback={() => {
                    if (!contractId) return;
                    getContract(contractId);
                }}
            >
                <FlowLayout>
                    <FlowAside>
                        <Stepper
                            id="contract-stepper"
                            title={`${currentStep?.title}`}
                            subTitle={`#${response?.data?.reference}`}
                            location={location}
                        />
                    </FlowAside>

                    <FlowBody>
                        <FlowContent>
                            {React.Children.map(
                                children as React.ReactElement,
                                (child: React.ReactElement) =>
                                    React.cloneElement(child, {
                                        location,
                                        ref,
                                        contract: response?.data,
                                        isDuplicateContract:
                                            getContractActions?.isDuplicateContract,
                                        isEditContract:
                                            getContractActions?.isEditContract,
                                        id: "contract",
                                        setDisableSaveButton,
                                        isEditingContract,
                                    }),
                            )}
                        </FlowContent>

                        <FlowFooter>
                            <StepActions
                                id={"contract-step-actions"}
                                loading={
                                    updatingContract ||
                                    createContractLoading ||
                                    uploading
                                }
                                primaryButton={{
                                    text: getContractActions?.isUploadFile
                                        ? "Upload"
                                        : getContractActions?.isDuplicateContract
                                          ? "Submit"
                                          : getContractActions?.isEditContract
                                            ? "Save changes"
                                            : prevPath
                                              ? prevPath.label
                                              : "Return to overview",
                                    action: onNextClick,
                                    disabled:
                                        isEditingContract ||
                                        ((getContractActions?.isDuplicateContract ||
                                            getContractActions?.isEditContract ||
                                            getContractActions?.isUploadFile) &&
                                            !currentStep?.isPrepared) ||
                                        updatingContract ||
                                        createContractLoading ||
                                        uploading,
                                    isSubmitButton: true,
                                }}
                                tertiaryButton={{
                                    text: "Cancel",
                                    action: () =>
                                        navigate("/dashboard/contracts/"),
                                    hidden:
                                        !getContractActions?.isUploadFile &&
                                        !getContractActions?.isDuplicateContract &&
                                        !getContractActions?.isEditContract,
                                }}
                            />
                        </FlowFooter>
                    </FlowBody>
                </FlowLayout>
            </LoadingWrapper>
        </Fragment>
    );
};

export default ContractLayout;
