import { isArrayWithContent } from "@utils";
import { DateTime } from "luxon";
import * as yup from "yup";

/**
 * Outcome details schema
 */

export const outcomeDetailsSchema = yup.object({
    infusionDate: yup.string(),
    variables: yup
        .array()
        .when("infusionDate", ([infusionDate]: string[], schema) => {
            return schema.of(
                yup
                    .object({
                        inputVariableName: yup.string().required(),
                        label: yup.string().required(),
                        type: yup.string().required(),
                        value: yup.mixed().when("type", ([type]) => {
                            return type === "boolean"
                                ? yup.boolean().required()
                                : type === "integer"
                                  ? yup.number().integer().positive().required()
                                  : type === "date"
                                    ? yup
                                          .date()
                                          .typeError(
                                              "Please enter a valid date",
                                          )
                                          .required()
                                    : yup.string();
                        }),
                    })
                    .test(
                        "date-range-test",
                        "End of treatment date must be later than infusion date",
                        function (value) {
                            if (
                                value.inputVariableName !== "endOfTreatmentDate"
                            )
                                return true;

                            return (
                                !!value.value &&
                                yup.date().isValidSync(infusionDate) &&
                                yup.date().isValidSync(value.value) &&
                                DateTime.fromISO(infusionDate).startOf("day") <
                                    DateTime.fromJSDate(value?.value).startOf(
                                        "day",
                                    )
                            );
                        },
                    ),
            );
        }),
    evidences: yup.array(),
    reason: yup.string().when("evidences", ([evidences]) => {
        return isArrayWithContent(evidences)
            ? yup.string()
            : yup.string().required();
    }),
});
