// extracted by mini-css-extract-plugin
export var error = "#ff3131";
export var list = "style-module--list--f79aa";
export var noTeam = "style-module--noTeam--d0424";
export var primary = "#036";
export var secondary = "#019cdc";
export var teamTitle = "style-module--teamTitle--76a7c";
export var teamWrapper = "style-module--teamWrapper--eedd4";
export var textfont = "\"Verdana Pro\"";
export var titlefont = "\"Karbon\"";
export var white = "#fff";
export var wrapper = "style-module--wrapper--6f40d";