//import libs
import { Box, TableCell, TableRow, Typography, Button } from "@mui/material";
import React from "react";

// import own components
import { ActionMenu } from "@components";

// Types
import type { BillingPlanValues } from "@types";

interface Props {
    row: BillingPlanValues;
    rowIndex: number;
    id: string;
    callbacks: any;
    isColVisible: (col: string) => boolean;
    disabled?: boolean;
}

const BillingPlanRow = ({
    id,
    row,
    rowIndex,
    callbacks,
    isColVisible,
    disabled,
}: Props) => {
    return (
        <TableRow>
            {isColVisible("salesOrg") && (
                <TableCell>
                    <Typography variant="caption2">{row.salesOrg}</Typography>
                </TableCell>
            )}

            {isColVisible("documentType") && (
                <TableCell>
                    <Typography variant="caption2">
                        {row.documentType}
                    </Typography>
                </TableCell>
            )}
            {isColVisible("division") && (
                <TableCell>
                    <Typography variant="caption2">{row.division}</Typography>
                </TableCell>
            )}
            {isColVisible("planType") && (
                <TableCell>
                    <Typography variant="caption2">{row.planType}</Typography>
                </TableCell>
            )}

            {isColVisible("counter") && (
                <TableCell>
                    <Typography variant="caption2">{row.counter}</Typography>
                </TableCell>
            )}

            {isColVisible("days") && (
                <TableCell>
                    <Typography variant="caption2">{row.days}</Typography>
                </TableCell>
            )}

            {isColVisible("percentage") && (
                <TableCell>
                    <Typography variant="caption2">{row.percentage}</Typography>
                </TableCell>
            )}
            {isColVisible("paymentTerms") && (
                <TableCell>
                    <Typography variant="caption2">
                        {row.paymentTerms}
                    </Typography>
                </TableCell>
            )}

            {isColVisible("billingBlock") && (
                <TableCell>
                    <Typography variant="caption2">
                        {row.billingBlock}
                    </Typography>
                </TableCell>
            )}

            <TableCell align="right">
                {callbacks?.linkBillingPlan ? (
                    <Button
                        id={`${id}-${rowIndex}-billing-plan-row-btn"`}
                        variant="text"
                        disabled={disabled}
                        onClick={() =>
                            callbacks?.linkBillingPlan(row.billingPlanId)
                        }
                    >
                        Link to milestone
                    </Button>
                ) : (
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-end"
                    >
                        <ActionMenu
                            id={`${id}-${rowIndex}-billing-plan-row"`}
                            autoHide
                            options={[
                                {
                                    label: "Edit",
                                    action: () =>
                                        callbacks &&
                                        callbacks?.editItem(rowIndex),
                                },
                            ]}
                            rowIndex={rowIndex}
                        />
                    </Box>
                )}
            </TableCell>
        </TableRow>
    );
};

export default BillingPlanRow;
