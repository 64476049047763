//Lib
import {
    Box,
    Typography,
    FormControl,
    FormControlLabel,
    RadioGroup,
    Radio,
    Grid,
} from "@mui/material";
import React, { Fragment } from "react";

// Own components
import { Dialog, FieldRenderer } from "@components";
import { displayDate } from "@utils";

// styles

import * as style from "../style.module.scss";

/**
 * Props
 */
interface Props {
    openDialog: boolean;
    onTaskComplete: () => void;
    loading: boolean;
    onReset: () => void;
    className?: string;
    values: { approved: string; reasonRejection: string };
    onBlur: (event: React.SyntheticEvent) => void;
    onChange: any;
    oldStartDate: string;
    oldEndDate: string;
    newStartDate: string;
    newEndDate: string;
    contractRef: string;
    comment: string | null;
    hideActions?: boolean;
}

/**
 * Contract Date Change
 */
const ContractDateChange = ({
    openDialog,
    loading,
    onReset,
    onTaskComplete,
    className,
    values,
    onBlur,
    onChange,
    oldStartDate,
    oldEndDate,
    newStartDate,
    newEndDate,
    contractRef,
    comment,
    hideActions,
}: Props) => {
    return (
        <Fragment>
            <Dialog
                id={`tasks-contract-date-change`}
                title={"Approve Contract Date Change"}
                open={openDialog}
                message={
                    values?.approved === "true" ? (
                        <Typography variant="subtitle2" color="black">
                            You are about to approve Contract Date Change and
                            consequently mark task
                            <Typography variant="body2" component="span">
                                {` Approve Contract Date Change [${contractRef}] `}
                            </Typography>
                            {
                                "as complete. Doing so will change the Contract dates to the New Contract Dates. If the New Contract End Date is a date in the past, also the contract status will be set to 'Inactive'. This action cannot be undone."
                            }
                        </Typography>
                    ) : (
                        <Typography variant="subtitle2" color="black">
                            You are about to reject Contract Date Change and
                            consequently mark task
                            <Typography variant="body2" component="span">
                                {` Approve Contract Date Change [${contractRef}] `}
                            </Typography>
                            as complete. Doing so will NOT change the Contract
                            dates.
                        </Typography>
                    )
                }
                primaryButton={{
                    text: "Confirm",
                    action: onTaskComplete,
                    loading: loading,
                }}
                secondaryButton={{
                    text: "Cancel",
                    action: onReset,
                }}
            />

            <div className={style.header}>
                <Typography variant="h2">
                    {`Approve Contract Date Change [${contractRef}]`}
                </Typography>
            </div>

            <Box mb={3} className={className}>
                <Grid container rowSpacing={3} columnSpacing={4} mb={3}>
                    {!!oldStartDate && (
                        <Grid item xs={12} md={6}>
                            <FieldRenderer
                                id={`task-details-old-start-date`}
                                label="Old start date"
                                value={displayDate(oldStartDate)}
                            />
                        </Grid>
                    )}
                    {!!oldEndDate && (
                        <Grid item xs={12} md={6}>
                            <FieldRenderer
                                id={`task-details-old-end-date`}
                                label="Old end date"
                                value={displayDate(oldEndDate)}
                            />
                        </Grid>
                    )}
                    {!!newStartDate && (
                        <Grid item xs={12} md={6}>
                            <FieldRenderer
                                id={`task-details-new-start-date`}
                                label="New start date"
                                value={displayDate(newStartDate)}
                            />
                        </Grid>
                    )}
                    {!!newEndDate && (
                        <Grid item xs={12} md={6}>
                            <FieldRenderer
                                id={`task-details-new-end-date`}
                                label="New end date"
                                value={displayDate(newEndDate)}
                            />
                        </Grid>
                    )}
                    {!!comment && (
                        <Grid item xs={12}>
                            <FieldRenderer
                                id={`task-details-comment`}
                                label="Comment"
                                value={comment}
                                multiline
                            />
                        </Grid>
                    )}
                </Grid>

                {!hideActions ? (
                    <form noValidate onBlur={onBlur}>
                        <FormControl>
                            <RadioGroup
                                id={`task-details-task-review`}
                                value={values?.approved}
                                onChange={onChange}
                                name="approved"
                            >
                                <FormControlLabel
                                    value={"true"}
                                    id={`task-details-change-contract-date-approve`}
                                    control={<Radio size="small" />}
                                    label={
                                        <Typography
                                            variant="body2"
                                            color="dark.light"
                                        >
                                            I approve this new contract dates
                                        </Typography>
                                    }
                                />
                                <FormControlLabel
                                    id={`task-details-change-contract-date-reject`}
                                    value={"false"}
                                    control={<Radio size="small" />}
                                    label={
                                        <Typography
                                            variant="body2"
                                            color="dark.light"
                                        >
                                            I reject this new contract dates
                                        </Typography>
                                    }
                                />
                            </RadioGroup>
                        </FormControl>
                    </form>
                ) : null}
            </Box>
        </Fragment>
    );
};

export default React.memo(ContractDateChange);
