import { Box, Typography, Button } from "@mui/material";
import { navigate } from "gatsby";
import React, { Fragment, useCallback } from "react";

// types
import { Location } from "@types";
// hooks
import { useSelectedCountry } from "@hooks";

/**
 * Props type
 */
interface Props {
    location: Location;
    children: React.ReactChild;
}

const SUB_PAGES = [
    {
        path: "/dashboard/claims/",
        label: "Claims",
    },
    {
        path: "/dashboard/price-correction-claims/",
        label: "Price correction claims",
    },
];

/**
 * ClaimsLayout
 */
const ClaimsLayout = ({ location, children }: Props) => {
    const { isGermanyTeam } = useSelectedCountry();

    /**
     * Check if the page is active
     */
    const isActive = useCallback(
        page => {
            return location.pathname.includes(page);
        },
        [location],
    );

    /**
     * Render
     */
    return (
        <Fragment>
            {isGermanyTeam && (
                <Box mb={5} mt={3} display="flex" alignItems="center">
                    <Typography variant="caption2" mr={3}>
                        Claims type:
                    </Typography>
                    {SUB_PAGES.map((page, index) => (
                        <Button
                            id={`dashboard-claims-type-btn-${index}`}
                            key={index}
                            onClick={() => navigate(page.path)}
                            variant="outlined"
                            size="large"
                            sx={{
                                fontSize: "0.875rem",
                                backgroundColor: isActive(page.path)
                                    ? "#e6f5fc"
                                    : "none",
                                borderColor: "transparent",
                                padding: "0.3rem 1.3rem",
                                marginRight: "1rem",
                            }}
                        >
                            <Typography variant="subtitle2">
                                {page.label}
                            </Typography>
                        </Button>
                    ))}
                </Box>
            )}

            <Box mt={5}>{children}</Box>
        </Fragment>
    );
};
export default ClaimsLayout;
