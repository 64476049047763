// extracted by mini-css-extract-plugin
export var content = "style-module--content--c177d";
export var editWrapper = "style-module--editWrapper--9aa72";
export var error = "#ff3131";
export var header = "style-module--header--836dc";
export var modalWrapper = "style-module--modalWrapper--c4623";
export var primary = "#036";
export var secondary = "#019cdc";
export var textfont = "\"Verdana Pro\"";
export var titlefont = "\"Karbon\"";
export var white = "#fff";