import { isArrayWithContent } from "../array";

export const constructQueryString = (
    object: Record<string, any>,
    startFromScratch?: boolean,
) => {
    if (!object) return "";
    return Object.keys(object).reduce((queryString, key, index) => {
        const value = object[key];

        if (
            (typeof value === "string" || typeof value === "number") &&
            !!value
        ) {
            const encodedValue =
                key === "search" ? encodeURIComponent(value) : value;

            return startFromScratch && (index === 0 || queryString === "")
                ? `?${key}=${encodedValue}`
                : queryString + `&${key}=${encodedValue}`;
        } else if (Array.isArray(value) && isArrayWithContent(value)) {
            const queryChain = value
                .map(el => `${key}=${el.filterKey ? el[el.filterKey] : el}`)
                .join("&");

            const queryStart =
                startFromScratch && (index === 0 || queryString === "")
                    ? "?"
                    : "&";

            const listString = queryStart.concat(queryChain);
            return queryString + listString;
        }

        return queryString;
    }, "");
};
