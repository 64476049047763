// Icons
import Box from "@mui/material/Box";
import FormHelperText from "@mui/material/FormHelperText";
// Lib
import clonedeep from "lodash.clonedeep";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import React, { Fragment, useEffect, useMemo, useState } from "react";

// Own components
import { GrayBox, UserRights } from "@components";

// Style
import * as style from "./style.module.scss";
import { isArrayWithContent } from "@utils";
import { TeamRole } from "@types";

/**
 * Right type
 */
type Right = {
    category: string;
    rightId: string;
    rightName: string;
};

type UserInfo = {
    email: string;
    rightIds: Right;
    scpUserId?: string;
};

const EMAIL = /\S+@\S+\.\S+/;
/**
 * Props type
 */
interface Props {
    id: string;
    disabled?: boolean;
    rights: {
        data: Array<Right>;
    };
    userInfo: any;
    userIndex: any;
    deleteUser: () => void;
    onSave: (value: UserInfo) => void;
    editUserIndex: any;
    setEditUserIndex: (index) => void;
    roles?: {
        data: Array<TeamRole>;
        loading: boolean;
    };
}
/**
 * Add user
 */
const AddUser: React.FC<Props> = ({
    id,
    disabled,
    rights,
    userInfo,
    deleteUser,
    onSave,
    setEditUserIndex,
    editUserIndex,
    userIndex,
    roles,
}) => {
    const [user, setUser] = useState<any>({ email: "", rightIds: [] });
    const [touched, setTouched] = useState<any>({});

    useEffect(() => {
        if (!userInfo) return;
        setUser(userInfo);
    }, [userInfo]);

    /**
     * on email change
     */
    const onEmailChange = value => {
        const copyUser = clonedeep(user);

        copyUser.email = value;
        setUser(copyUser);
    };

    /**
     * Toggle rights
     */
    const onRightsChange = (value: string) => {
        const copyUser = clonedeep(user);

        if (copyUser.rightIds.indexOf(value) > -1) {
            const filteredRights = copyUser.rightIds.filter(el => el !== value);
            copyUser.rightIds = filteredRights;
            if (value === "RightId15") {
                // Reset approver role
                copyUser.approvalRole = null;
            }
        } else {
            if (value === "RightId15") {
                // Reset approver role
                copyUser.approvalRole = null;
            }

            // User should have Read right if he has create or edit rights
            // so we should map it dynamically
            // Contract (Create contract : RightId01, editContract: RightId02, read contract: RightId03)
            if (
                (value === "RightId01" || value === "RightId02") &&
                !copyUser.rightIds["RightId03"]
            ) {
                copyUser.rightIds.push("RightId03");
            }

            // Order (CreateOrder : RightId06, EditOrder: RightId07, ReadOrder: RightId08)

            if (
                (value === "RightId06" || value === "RightId07") &&
                !copyUser.rightIds["RightId08"]
            ) {
                copyUser.rightIds.push("RightId08");
            }

            // Claims
            if (value === "RightId19" && !copyUser.rightIds["RightId18"]) {
                copyUser.rightIds.push("RightId18");
            }

            copyUser.rightIds.push(value);
        }

        setUser(copyUser);
    };

    const onRoleChange = (role: TeamRole) => {
        const copyUser = clonedeep(user);
        copyUser.approvalRole = role;
        setUser(copyUser);
    };

    const onBlur = (el: string) => {
        if (touched[el]) return;
        const copyTouched = { ...touched };
        copyTouched[el] = true;
        setTouched(copyTouched);
    };

    const validation = useMemo(() => {
        let emailMsg = "";
        const testEmail = EMAIL.test(user?.email);
        const hasRights = isArrayWithContent(user?.rightIds);

        if (user.email) {
            emailMsg = testEmail ? "" : "Please enter a valid email address.";
        } else emailMsg = "Email is a required field";

        const rightsMsg = !hasRights
            ? "Users must have at least one user right."
            : "";

        return {
            email: emailMsg,
            rightIds: rightsMsg,
            isValidForm: testEmail && hasRights,
        };
    }, [user, editUserIndex, userIndex]);

    return (
        <Fragment>
            <GrayBox
                id={`${id}-team-form`}
                padding={4}
                header={
                    <Fragment>
                        <Box>
                            <InputLabel shrink error={false}>
                                {"User account (*)"}
                            </InputLabel>

                            <TextField
                                id={`${id}-team-email`}
                                className={style.whiteInput}
                                fullWidth
                                name="email"
                                autoComplete="off"
                                onBlur={() => onBlur("email")}
                                value={user.email}
                                onChange={(event: React.SyntheticEvent) => {
                                    const target =
                                        event?.target as HTMLInputElement;
                                    onEmailChange(target.value);
                                }}
                                variant="outlined"
                                size="small"
                                data-testid="email-input"
                                error={
                                    !!validation?.email && !!touched["email"]
                                }
                                disabled={disabled}
                                inputProps={{
                                    readOnly:
                                        editUserIndex !== userIndex &&
                                        !!userInfo.email,
                                }}
                            />
                        </Box>
                    </Fragment>
                }
                helper={
                    !!touched["email"] &&
                    !!validation?.email && (
                        <FormHelperText error>
                            {validation.email}
                        </FormHelperText>
                    )
                }
                collapsible
                collapse={editUserIndex === userIndex}
                primaryAction={{
                    text: "Remove",
                    onClick: deleteUser,
                    disabled,
                    id: `${id}-delete-user`,
                }}
                secondaryAction={{
                    text:
                        editUserIndex !== userIndex && !!userInfo.email
                            ? "Edit"
                            : "Save",

                    onClick: () =>
                        editUserIndex !== userIndex && !!userInfo.email
                            ? setEditUserIndex(userIndex)
                            : onSave(user),
                    disabled: disabled || !validation?.isValidForm,
                    id: `${id}-save-user`,
                }}
            >
                <UserRights
                    id={`${id}-user-rights`}
                    isEditMode={editUserIndex === userIndex}
                    rights={rights?.data}
                    values={user.rightIds}
                    approvalRole={user?.approvalRole}
                    onRightsChange={(rightId: string) => {
                        onRightsChange(rightId);
                    }}
                    onRoleChange={role => {
                        onRoleChange(role);
                    }}
                    onBlur={onBlur}
                    disabled={disabled || editUserIndex !== userIndex}
                    roles={roles}
                />
            </GrayBox>
            {!!touched["email"] && validation?.rightIds && (
                <Box mt={2} display="flex">
                    <Typography variant="caption1" color="error">
                        {validation.rightIds}
                    </Typography>
                </Box>
            )}
        </Fragment>
    );
};
export default React.memo(AddUser);
