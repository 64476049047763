// extracted by mini-css-extract-plugin
export var actions = "style-module--actions--504d5";
export var disabledField = "style-module--disabledField--13f3a";
export var doubleInputField = "style-module--doubleInputField--dbfd2";
export var error = "#ff3131";
export var form = "style-module--form--6faad";
export var link = "style-module--link--58977";
export var priceCondition = "style-module--priceCondition--c3679";
export var primary = "#036";
export var secondary = "#019cdc";
export var textfont = "\"Verdana Pro\"";
export var titlefont = "\"Karbon\"";
export var white = "#fff";
export var whiteInput = "style-module--whiteInput--31df3";