import { Contract } from "@types";
import { atom } from "recoil";

/**
 * Order selection State
 */
export const claimContractSelectionState = atom({
    key: "claimContractSelectionState",
    default: {} as Contract,
});
