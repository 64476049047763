// lib
import { Grid } from "@mui/material";
import React from "react";

interface Props {
    children: React.ReactNode;
}

/**
 * FlowLayout
 */
const FlowLayout: React.FC<Props> = ({ children }) => {
    /**
     * Render
     */
    return (
        <Grid
            container
            item
            xs={12}
            columnSpacing={7}
            pb={7}
            px={7}
            minHeight="calc(100vh - 10rem)"
        >
            {children}
        </Grid>
    );
};
export default React.memo(FlowLayout);
