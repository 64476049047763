// lib
import {
    Backdrop,
    Button,
    CircularProgress,
    Divider,
    Grid,
    InputBase,
    List,
    ListItem,
    ListItemText,
    Paper,
    Typography,
} from "@mui/material";
import React, { Fragment, useMemo, useState } from "react";

//utils
import { isArrayWithContent, sortByField } from "@utils";

//own components
import { ActionMenu } from "@components";

//style
import * as style from "./style.module.scss";
import InputForm from "./InputForm";

//Props
interface ViewableFields {
    id: string;
    notEditable?: boolean;
    isSavedLabel?: boolean;
    inputLabel: string;
    required: boolean;
    isLargeSize: boolean;
}

interface Props {
    isLargeSize: boolean;
    valueId: string;
    fields: Array<ViewableFields>;
    id: string;
    error: any;
    title: string;
    subtitle: string;
    list: Array<any>;
    loading: { creating: boolean; listLoading: boolean; deleting: boolean };
    addListItem: (value: object, label: string) => void;
    deleteListItem?: (id: string, savedLabel: string) => void;
    fieldsKey: string;
    canAddNewRecord?: boolean;
    headers?: Array<{ type: string; label: string }>;
}

/**
 * Maintenance list
 */
const MaintenanceListAdvanced: React.FC<Props> = ({
    error,
    id,
    title,
    subtitle,
    list,
    loading,
    valueId,
    fields,
    isLargeSize,
    addListItem,
    deleteListItem,
    fieldsKey,
    canAddNewRecord = true,
    headers,
}) => {
    //set initial values
    const [editValues, setEditValues] = useState<object>({});
    const [editId, setEditId] = useState<string>("");

    /**
     * Sort list
     */
    const sortedList: Array<any> = useMemo(() => {
        return sortByField(list, fieldsKey);
    }, [list]);

    useMemo(() => {
        const selectedItem = list?.find(x => x[valueId] === editId) || {};
        setEditValues(selectedItem);
    }, [editId]);

    /**
     * Render
     */
    return (
        <Fragment>
            <Grid item xs={12} lg={8} mt={5}>
                <Typography variant="h2">{title}</Typography>{" "}
                {!!canAddNewRecord && (
                    <InputForm
                        fields={fields}
                        id={`${id}-maintenance-input`}
                        loading={loading}
                        addListItem={addListItem}
                    />
                )}
            </Grid>
            <Grid
                item
                xs={12}
                md={isLargeSize ? 12 : 6}
                lg={isLargeSize ? 8 : 5}
                mt={5}
            >
                {/* Background for edit mode */}
                {editId !== "" && (
                    <Backdrop
                        sx={{
                            backgroundColor: "rgba(0, 51, 102, 0.2)",
                            zIndex: theme => theme.zIndex.drawer + 1,
                        }}
                        open
                        onClick={() => setEditId("")}
                    ></Backdrop>
                )}
                {subtitle && (
                    <Typography variant="h3">Saved {subtitle}</Typography>
                )}

                {/* List */}
                <List
                    sx={{
                        overflow: "auto",
                        maxHeight: 375,
                        marginTop: "1rem",
                    }}
                >
                    {!loading.listLoading ? (
                        isArrayWithContent(sortedList) ? (
                            <>
                                {!!headers && isArrayWithContent(headers) && (
                                    <ListItem
                                        style={{
                                            position: "sticky",
                                            top: "-10px",
                                            backgroundColor: "#fff",
                                            zIndex: 1,
                                        }}
                                    >
                                        {headers.map((header, index) => (
                                            <ListItemText
                                                key={index}
                                                sx={{
                                                    width:
                                                        header.type === "text"
                                                            ? "100%"
                                                            : "20%",
                                                }}
                                                disableTypography
                                                primary={
                                                    <Typography
                                                        variant="h6"
                                                        color="dark.main"
                                                    >
                                                        {header.label}
                                                    </Typography>
                                                }
                                            />
                                        ))}
                                    </ListItem>
                                )}

                                <Divider variant="fullWidth" component="li" />
                                {sortedList.map((listItem, index) => (
                                    <Fragment
                                        key={`${listItem[valueId]}-${index}`}
                                    >
                                        <ListItem id={listItem[valueId]}>
                                            {!!fields &&
                                                isArrayWithContent(fields) &&
                                                fields.map(field =>
                                                    editId ===
                                                        listItem[valueId] &&
                                                    !field.notEditable ? (
                                                        // Edit mode
                                                        <Paper
                                                            className={
                                                                style.editWrapper
                                                            }
                                                            key={`edit-item-${field.id}`}
                                                            component="form"
                                                            sx={{
                                                                display: "flex",
                                                                alignItems:
                                                                    "center",
                                                                width:
                                                                    field.id ===
                                                                    "country"
                                                                        ? "100%"
                                                                        : field.id ===
                                                                            "accountRoleName"
                                                                          ? "25%"
                                                                          : "75%",
                                                                mr: 2,
                                                                position:
                                                                    "relative",
                                                                zIndex: theme =>
                                                                    theme.zIndex
                                                                        .drawer +
                                                                    12,
                                                            }}
                                                        >
                                                            <InputBase
                                                                sx={{
                                                                    pl: 1,

                                                                    flex: 1,
                                                                }}
                                                                defaultValue={
                                                                    listItem[
                                                                        field.id
                                                                    ]
                                                                }
                                                                onChange={e => {
                                                                    setEditValues(
                                                                        {
                                                                            ...editValues,
                                                                            [field.id]:
                                                                                e
                                                                                    .target
                                                                                    .value,
                                                                        },
                                                                    );
                                                                }}
                                                                inputComponent="input"
                                                                color="secondary"
                                                                disabled={
                                                                    loading?.creating ||
                                                                    loading?.listLoading
                                                                }
                                                            />

                                                            <Button
                                                                onClick={() => {
                                                                    addListItem(
                                                                        editValues,
                                                                        editValues[
                                                                            fieldsKey
                                                                        ],
                                                                    );
                                                                    setEditId(
                                                                        "",
                                                                    );
                                                                }}
                                                            >
                                                                Save
                                                            </Button>
                                                        </Paper>
                                                    ) : (
                                                        editId !==
                                                            listItem[
                                                                valueId
                                                            ] && (
                                                            <ListItemText
                                                                key={`list-item-${field.id}`}
                                                                sx={{
                                                                    width: "100%",
                                                                }}
                                                                disableTypography
                                                            >
                                                                <Typography variant="caption2">
                                                                    {
                                                                        listItem[
                                                                            field
                                                                                .id
                                                                        ]
                                                                    }
                                                                </Typography>
                                                            </ListItemText>
                                                        )
                                                    ),
                                                )}
                                            {editId !== listItem[valueId] && (
                                                <ActionMenu
                                                    id={id}
                                                    disabled={loading?.deleting}
                                                    autoHide
                                                    options={[
                                                        {
                                                            label: "Edit",
                                                            action: () =>
                                                                setEditId(
                                                                    listItem[
                                                                        valueId
                                                                    ],
                                                                ),
                                                        },
                                                        {
                                                            label: "Delete",
                                                            hidden: !deleteListItem,
                                                            action: deleteListItem
                                                                ? () => {
                                                                      deleteListItem(
                                                                          listItem[
                                                                              valueId
                                                                          ],
                                                                          listItem[
                                                                              fieldsKey
                                                                          ],
                                                                      );
                                                                  }
                                                                : undefined,
                                                        },
                                                    ]}
                                                />
                                            )}
                                        </ListItem>

                                        <Divider
                                            variant="fullWidth"
                                            component="li"
                                        />
                                    </Fragment>
                                ))}
                            </>
                        ) : error.list ? (
                            <Typography variant="caption1" color="error">
                                There was an unexpected error. SCP was unable to
                                load {subtitle.toLowerCase()}.
                            </Typography>
                        ) : (
                            <ListItemText>
                                <Typography variant="caption1" color="primary">
                                    No {subtitle.toLowerCase()} registered yet.
                                </Typography>
                            </ListItemText>
                        )
                    ) : (
                        <ListItem>
                            <CircularProgress />
                        </ListItem>
                    )}
                </List>
            </Grid>
        </Fragment>
    );
};
export default React.memo(MaintenanceListAdvanced);
