// extracted by mini-css-extract-plugin
export var attachment = "style-module--attachment--d3290";
export var disabled = "style-module--disabled--a38a2";
export var error = "#ff3131";
export var primary = "#036";
export var secondary = "#019cdc";
export var spacer = "style-module--spacer--17499";
export var square = "style-module--square--3f0df";
export var textfont = "\"Verdana Pro\"";
export var titlefont = "\"Karbon\"";
export var white = "#fff";