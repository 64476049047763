// Lib
import React from "react";

// Own components
import { MaintenanceMilestone } from "@components";

// Hooks
import { useMaintenanceMilestone } from "@hooks";
import { Location } from "@types";

/**
 * Props type
 */
interface Props {
    location?: Location;
    id?: string;
}

/**
 * Milestone
 */
const Milestone = ({ id }: Props) => {
    /**
     * API
     */

    const {
        list: { billingPlanList, milestoneList },
        loading,
        load: { loadBillingPlanList },
        upsert: { linkBillingPlan },
    } = useMaintenanceMilestone();

    /**
     * Render
     */
    return (
        <MaintenanceMilestone
            id={`${id}-milestone-list`}
            billingPlanList={billingPlanList?.maintenanceLinkableBillingPlans}
            milestoneList={milestoneList?.maintenanceMilestoneList}
            loading={loading}
            loadBillingPlanList={loadBillingPlanList}
            onLinkBillingPlan={linkBillingPlan}
        />
    );
};
export default Milestone;
