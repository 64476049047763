import { useRecoilValue } from "recoil";
import { useMemo } from "react";
import { PERMISSIONS } from "@constants";
import { userState } from "@atoms";
import type { User } from "@types";
import { isArrayWithContent } from "@utils";

/**
 * Receives a permission and will return true or false
 * if a user is permitted to perform an operation
 */

/**
 * user permission hook
 */
export const usePermission = () => {
    const user = useRecoilValue<User | undefined>(userState);

    const mapPermissions = useMemo(() => {
        // User info not loaded => initial permissions
        if (!user) {
            const permissions = {};

            Object.keys(PERMISSIONS).forEach(key => {
                permissions[key] = false;
            });
            return { ...permissions, hasPermissionToEditMaintenance: false };
        }
        const mappedPermissions = {};

        // User info is loaded & the team is not selected => initial permissions + check the role

        if (!isArrayWithContent(user?.rights)) {
            const permissions = {};

            Object.keys(PERMISSIONS).forEach(key => {
                permissions[key] = false;
            });
            return {
                ...permissions,
                hasPermissionToEditMaintenance: user.role === "SCP.Admin",
            };
        } else {
            // User info is loaded & the team is selected => map permissions

            Object.keys(PERMISSIONS).forEach(
                key =>
                    (mappedPermissions[key] = user?.rights?.some(
                        right => right.rightId === PERMISSIONS[key],
                    )),
            );
        }

        return {
            ...mappedPermissions,
            hasPermissionToEditMaintenance: user.role === "SCP.Admin",
        };
    }, [user]);

    return mapPermissions;
};
