// extracted by mini-css-extract-plugin
export var currentPage = "style-module--currentPage--b6998";
export var error = "#ff3131";
export var hasCursor = "style-module--hasCursor--d5ade";
export var item = "style-module--item--81567";
export var nav = "style-module--nav--39c49";
export var primary = "#036";
export var secondary = "#019cdc";
export var textfont = "\"Verdana Pro\"";
export var title = "style-module--title--091c1";
export var titlefont = "\"Karbon\"";
export var white = "#fff";
export var wrapper = "style-module--wrapper--de271";