//Icons
import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import SearchIcon from "@mui/icons-material/Search";
import TuneIcon from "@mui/icons-material/Tune";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import VisibilityIcon from "@mui/icons-material/Visibility";

//Lib
import classnames from "classnames";
import {
    Box,
    Button,
    Checkbox,
    Divider,
    IconButton,
    InputAdornment,
    MenuItem,
    TextField,
    Typography,
    Menu,
} from "@mui/material";
import clonedeep from "lodash.clonedeep";
import debounce from "lodash.debounce";
import React, {
    Fragment,
    useCallback,
    useEffect,
    useRef,
    useState,
    useImperativeHandle,
} from "react";

// Types
import { SearchFilters, ViewingOption, Account } from "@types";

// types
import { AccountType, Brand, Country } from "types/api";

// Utils
import { convertDateForAPI, isArrayWithContent } from "@utils";

// Own components
import { Autocomplete, DateFilter, DataRange, ButtonsMenu } from "@components";
import SelectFilter from "./SelectFilter";

// Style
import * as style from "./style.module.scss";
import OneItemFilter from "./OneItemFilter";
import { useRecoilState } from "recoil";
import { paginationState } from "@atoms";

/**
 * Action type
 */
interface Action {
    label?: string;
    callback?: (event?: React.BaseSyntheticEvent, key?: string) => void;
    hidden?: boolean;
    disabled?: boolean;
    type?: "single" | "menu";
    items?: Array<{ key: string; label: string; hidden?: boolean }>;
}

/**
 * Props type
 */
interface Props {
    id: string;
    action?: Action;
    handleSearch?: (filters: SearchFilters, page?: number) => void;
    accountTypes?: { loading: boolean; data: Array<AccountType> };
    therapeuticAreas?: { loading: boolean; data: Array<any> };
    partners?: {
        loading: boolean;
        data: Array<Account>;
        onSearch: (query: string) => void;
    };
    brands?: { loading: boolean; data: Array<Brand> };
    countries?: { loading: boolean; data: Array<Country> };
    products?: { loading: boolean; data: Array<any> };
    taskDefinitionKeys?: {
        loading: boolean;
        data: Array<string>;
    };
    status?: { loading: boolean; data: Array<any> };
    startDate?: boolean;
    endDate?: boolean;
    placeholder?: string;
    simpleLayout?: boolean;
    viewingOptions?: Array<ViewingOption>;
    setViewingOptions?: any;
    priceRange?: boolean;
    hasFilters?: boolean;
    searchTextOnly?: boolean;
    searchCallback?: () => void;
    dateRange?: boolean;
    contractType?: boolean;
    filterKey?: string;
    financeType?: {
        loading: boolean;
        data: Array<{ value: string; label: string }>;
    };
    showTasksTypeFilter?: boolean;
    disableSelectionFilters?: boolean;
    showFinancialTriggerTypeFilter?: boolean;
    hasPagination?: boolean;
}

/**
 * Search bar
 */
const SearchBar = React.forwardRef(
    (
        {
            id,
            action,
            handleSearch,
            accountTypes,
            therapeuticAreas,
            brands,
            countries,
            products,
            partners,
            status,
            startDate = true,
            endDate = true,
            placeholder,
            simpleLayout,
            viewingOptions,
            setViewingOptions,
            priceRange,
            hasFilters = true,
            searchTextOnly = false,
            searchCallback,
            taskDefinitionKeys,
            dateRange,
            contractType,
            filterKey,
            financeType,
            showTasksTypeFilter,
            disableSelectionFilters,
            showFinancialTriggerTypeFilter,
            hasPagination,
        }: Props,
        ref,
    ) => {
        const initialFilterValues = {
            account: [],
            accountType: [],
            product: [],
            brand: [],
            therapeutic_area: [],
            valid_from: "",
            valid_to: "",
            startDate: "",
            endDate: "",
            status: [],
            countryIsoCode: [],
            priceRangeEnd: "",
            priceRangeStart: "",
            taskDefinitionKey: [],
            type: [],
            financeType: [],
            allTasks: showTasksTypeFilter
                ? [
                      {
                          label: "Show my tasks",
                          value: "false",
                          filterKey: "value",
                      },
                  ]
                : [],
            financialTriggerType: showFinancialTriggerTypeFilter
                ? [
                      {
                          label: "Rebate at infusion",
                          value: "rebateAtInfusion",
                          filterKey: "value",
                      },
                  ]
                : [],
        };

        /**
         * States
         */
        const [showFilters, setShowFilters] = useState(false);
        const [searchValue, setSearchValue] = useState("");
        const [filters, setFilters] = useState(initialFilterValues);
        const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
        const [pagination, setPagination] = useRecoilState(paginationState);

        /**
         * Debounce filter functionality
         */
        const onSearch = useRef(
            debounce(
                (filterQuery: SearchFilters) =>
                    handleSearch && handleSearch(filterQuery),
                350,
            ),
        ).current;

        useEffect(() => {
            if (!hasFilters) return;

            if (!!pagination.page && pagination.page > 1) {
                setPagination(prev => ({ ...prev, page: 1 }));
            }

            let unmounted = false;

            if (!unmounted) {
                onSearch({ ...filters, search: searchValue });
            }

            return () => {
                unmounted = true;
            };
        }, [searchValue, filters]);

        useEffect(() => {
            if (!hasFilters || !pagination.page) return;

            onSearch({ ...filters, search: searchValue });
        }, [pagination.page]);

        useEffect(() => {
            if (!hasPagination || !filterKey) return;

            setPagination({ type: filterKey, page: 1 });
        }, []);

        /**
         * Delete all filters
         */
        const onDeleteAllFilters = () => {
            setFilters(initialFilterValues);
        };

        /**
         * Call search with filters from HOC
         */
        useImperativeHandle(ref, () => ({
            onSearchReset() {
                onDeleteAllFilters();
                setSearchValue("");
                if (hasPagination) {
                    setPagination(prev => ({ ...prev, page: 1 }));
                }
            },
        }));

        /**
         * Change brands, therapeuticAreas and products
         */
        const handleFilterChange = (
            filter: string | Array<string>,
            value: Array<any> | string | Record<string, string>,
            key?: string,
            oneItemSelection = false,
        ) => {
            const filterValue = isArrayWithContent(value) ? value[0] : value;

            const copyFilters = clonedeep(filters);

            if (copyFilters[filter] && key) {
                // Array with radio button
                if (oneItemSelection && typeof value === "object") {
                    copyFilters[filter] = [{ ...value, filterKey: key }];
                } else {
                    const idx = copyFilters[filter].findIndex(
                        item => item[key] === filterValue[key],
                    );

                    idx === -1
                        ? copyFilters[filter].push({
                              ...filterValue,
                              filterKey: key,
                          })
                        : copyFilters[filter].splice(idx, 1);
                }
            } else if (
                typeof filter !== "string" &&
                isArrayWithContent(filter)
            ) {
                filter.forEach(item => {
                    copyFilters[item] = value[item];
                });
            } else {
                copyFilters[filter] = key ? [filterValue] : filterValue;
            }
            if (searchCallback) {
                searchCallback();
            }
            setFilters(copyFilters);
        };

        useEffect(() => {
            if (!filterKey) return;

            if (typeof window !== "undefined") {
                const storedFilters = sessionStorage.getItem(
                    `FILTER_${filterKey}`,
                );

                const storedSearchValue = sessionStorage.getItem(
                    `SEARCH_VALUE_${filterKey}`,
                );

                if (storedFilters) {
                    setFilters(JSON.parse(storedFilters));
                }

                if (storedSearchValue) {
                    setSearchValue(JSON.parse(storedSearchValue));
                }
            }
        }, [filterKey]);

        useEffect(() => {
            if (!filterKey) return;
            if (typeof window !== "undefined") {
                sessionStorage.setItem(
                    `FILTER_${filterKey}`,
                    JSON.stringify(filters),
                );
            }
        }, [filters, filterKey, searchValue]);

        useEffect(() => {
            if (!filterKey) return;
            if (typeof window !== "undefined") {
                sessionStorage.setItem(
                    `SEARCH_VALUE_${filterKey}`,
                    JSON.stringify(searchValue),
                );
            }
        }, [filterKey, searchValue]);

        /**
         * Check if item is selected
         */
        const isItemChecked = useCallback(
            (filter: string, value: Record<string, string>, key: string) => {
                if (!filters[filter]) return;
                return filters[filter].find(item => item[key] === value[key]);
            },
            [filters],
        );

        const handleFreeTextSearch = (value: string) => {
            setSearchValue(value);

            if (searchCallback) {
                searchCallback();
            }
        };
        /**
         * Label renderer
         */
        const labelRenderer = (
            label: string,
            filter: string,
            key?: string,
            disableDeletion?: boolean,
        ) => {
            if (!filter || !filters[filter]) return;

            const filtersMapper =
                !!key && isArrayWithContent(filters[filter])
                    ? filters[filter].map(item => item[key]).join(", ")
                    : filters[filter];

            const onDelete = () => {
                if (disableDeletion) return;
                const copyFilters = clonedeep(filters);
                if (typeof copyFilters[filter] === "string")
                    copyFilters[filter] = "";
                else copyFilters[filter] = [];

                setFilters(copyFilters);
            };

            return (
                <Box className={style.chip}>
                    <Typography variant="chip">{`${label}: `}</Typography>
                    <Typography variant="chip">{filtersMapper}</Typography>
                    {!disableDeletion && (
                        <IconButton size="small" onClick={onDelete}>
                            <ClearIcon
                                fontSize="small"
                                sx={{ width: "0.7rem", height: "0.7rem" }}
                            />
                        </IconButton>
                    )}
                </Box>
            );
        };

        /**
         * Render
         */
        return (
            <div
                className={classnames(style.wrapper, {
                    [style.elevatedSearch]: !simpleLayout,
                })}
            >
                <Box>
                    <div
                        className={classnames(style.searchBox, {
                            [style.withoutSearch]:
                                !hasFilters && !action?.hidden,
                        })}
                    >
                        {!!action &&
                            !action.hidden &&
                            (action.type === "menu" &&
                            !!action.items &&
                            isArrayWithContent(action.items) ? (
                                <ButtonsMenu
                                    id={`${id}-search-btn-menu`}
                                    startIcon={<AddIcon />}
                                    disabled={action.disabled}
                                    items={action.items}
                                    label={action?.label || ""}
                                    onSelect={(
                                        event: React.BaseSyntheticEvent,
                                        key: string,
                                    ) =>
                                        action?.callback
                                            ? action?.callback(event, key)
                                            : undefined
                                    }
                                />
                            ) : (
                                <Button
                                    variant="contained"
                                    disableElevation
                                    startIcon={<AddIcon />}
                                    color="primary"
                                    disabled={action.disabled}
                                    onClick={action.callback}
                                    id={`${id}-search-btn`}
                                >
                                    <Typography variant="button">
                                        {action.label}
                                    </Typography>
                                </Button>
                            ))}

                        {hasFilters && (
                            <TextField
                                size="small"
                                className={style.searchField}
                                id={`${id}-search-field`}
                                autoComplete="off"
                                placeholder={placeholder}
                                value={searchValue}
                                onChange={event =>
                                    handleFreeTextSearch(event.target.value)
                                }
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon color="primary" />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        )}

                        {!!setViewingOptions && (
                            <Fragment>
                                <Button
                                    id={`${id}-viewing-options-list`}
                                    aria-controls={
                                        anchorEl
                                            ? "table-view-options"
                                            : undefined
                                    }
                                    aria-haspopup="true"
                                    aria-expanded={
                                        anchorEl ? "true" : undefined
                                    }
                                    variant="outlined"
                                    color="primary"
                                    onClick={(
                                        event: React.MouseEvent<HTMLElement>,
                                    ) => setAnchorEl(event.currentTarget)}
                                    endIcon={
                                        anchorEl ? (
                                            <KeyboardArrowUpIcon />
                                        ) : (
                                            <KeyboardArrowDownIcon />
                                        )
                                    }
                                    startIcon={<VisibilityIcon />}
                                >
                                    <Typography variant="button">
                                        Viewing options
                                    </Typography>
                                </Button>

                                <Menu
                                    id="table-view-options"
                                    anchorEl={anchorEl}
                                    open={!!anchorEl}
                                    onClose={() => {
                                        setAnchorEl(null);
                                    }}
                                    MenuListProps={{
                                        "aria-labelledby": "basic-button",
                                    }}
                                >
                                    {isArrayWithContent(viewingOptions) &&
                                        viewingOptions!.map(
                                            (option, index) =>
                                                !!option.rowKey && (
                                                    <MenuItem
                                                        key={`${option?.colIdx}-column`}
                                                        value={option.label}
                                                        onClick={() =>
                                                            setViewingOptions(
                                                                option,
                                                                index,
                                                            )
                                                        }
                                                    >
                                                        <Checkbox
                                                            checked={
                                                                option.checked
                                                            }
                                                            size="small"
                                                        />
                                                        <Typography
                                                            variant="caption1"
                                                            sx={{
                                                                color: "text.secondary",
                                                            }}
                                                        >
                                                            {option?.label}
                                                        </Typography>
                                                    </MenuItem>
                                                ),
                                        )}
                                </Menu>
                            </Fragment>
                        )}
                        {hasFilters && !searchTextOnly && (
                            <Button
                                id={`${id}-show-filters-toggler`}
                                variant="outlined"
                                startIcon={<TuneIcon />}
                                color="primary"
                                onClick={() => setShowFilters(!showFilters)}
                            >
                                <Typography variant="button">
                                    Filters
                                </Typography>
                            </Button>
                        )}
                    </div>

                    <div>
                        {showFilters && (
                            <Fragment>
                                <div
                                    className={style.filters}
                                    id={`${id}-filters-wrapper`}
                                >
                                    {startDate && (
                                        <DateFilter
                                            id={`${id}-start-date-filter`}
                                            label="Start date"
                                            value={filters.valid_from}
                                            onChange={value => {
                                                handleFilterChange(
                                                    dateRange
                                                        ? "startDate"
                                                        : "valid_from",
                                                    convertDateForAPI(value),
                                                );
                                            }}
                                            className={style.spacer}
                                        />
                                    )}

                                    {endDate && (
                                        <DateFilter
                                            id={`${id}-end-date-filter`}
                                            label="End date"
                                            value={filters.valid_to}
                                            onChange={value => {
                                                handleFilterChange(
                                                    dateRange
                                                        ? "endDate"
                                                        : "valid_to",
                                                    convertDateForAPI(value),
                                                );
                                            }}
                                            className={style.spacer}
                                        />
                                    )}

                                    {countries && (
                                        <SelectFilter
                                            id={`${id}-filter-countries`}
                                            className={style.spacer}
                                            renderValue={"Country"}
                                            loading={countries?.loading}
                                            handleFilterChange={
                                                handleFilterChange
                                            }
                                            filterName="countryIsoCode"
                                            labelKey="country"
                                            valueKey="countryIsoCode"
                                            data={countries?.data}
                                            isItemChecked={isItemChecked}
                                        />
                                    )}

                                    {products && (
                                        <SelectFilter
                                            id={`${id}-filter-product`}
                                            className={style.spacer}
                                            renderValue={"Product"}
                                            loading={products?.loading}
                                            handleFilterChange={
                                                handleFilterChange
                                            }
                                            filterName="product"
                                            labelKey="productName"
                                            valueKey="productId"
                                            data={products?.data}
                                            isItemChecked={isItemChecked}
                                        />
                                    )}

                                    {taskDefinitionKeys && (
                                        <SelectFilter
                                            id={`${id}-filter-taskDefinitionKeys`}
                                            className={style.spacer}
                                            renderValue={"Task definition keys"}
                                            loading={
                                                taskDefinitionKeys?.loading
                                            }
                                            disableItemSelection={
                                                disableSelectionFilters
                                            }
                                            handleFilterChange={
                                                handleFilterChange
                                            }
                                            filterName="taskDefinitionKey"
                                            labelKey="keyName"
                                            valueKey="keyId"
                                            data={taskDefinitionKeys?.data}
                                            isItemChecked={isItemChecked}
                                        />
                                    )}

                                    {accountTypes && (
                                        <SelectFilter
                                            id={`${id}-filter-accountType`}
                                            className={style.spacer}
                                            renderValue={"Account type"}
                                            loading={accountTypes?.loading}
                                            handleFilterChange={
                                                handleFilterChange
                                            }
                                            data={accountTypes?.data}
                                            filterName="accountType"
                                            labelKey="accountTypeName"
                                            valueKey="accountTypeId"
                                            isItemChecked={isItemChecked}
                                        />
                                    )}

                                    {contractType && (
                                        <OneItemFilter
                                            id={`${id}-filter-contractType`}
                                            className={style.spacer}
                                            renderValue={"Contract type"}
                                            loading={false}
                                            handleFilterChange={
                                                handleFilterChange
                                            }
                                            data={[
                                                {
                                                    label: "Performance based",
                                                    value: "PERFORMANCE",
                                                },
                                                {
                                                    label: "Volume based",
                                                    value: "VOLUME",
                                                },
                                            ]}
                                            filterName="type"
                                            labelKey="label"
                                            valueKey="value"
                                            value={filters["type"]}
                                        />
                                    )}

                                    {status &&
                                        (isArrayWithContent(status?.data) &&
                                        status?.data?.length === 2 ? (
                                            <OneItemFilter
                                                id={`${id}-filter-status`}
                                                className={style.spacer}
                                                renderValue={"Status"}
                                                loading={status?.loading}
                                                handleFilterChange={
                                                    handleFilterChange
                                                }
                                                data={status?.data}
                                                filterName="status"
                                                labelKey="label"
                                                valueKey="value"
                                                value={filters["status"]}
                                            />
                                        ) : (
                                            <SelectFilter
                                                id={`${id}-filter-status`}
                                                className={style.spacer}
                                                renderValue={"Status"}
                                                loading={status?.loading}
                                                handleFilterChange={
                                                    handleFilterChange
                                                }
                                                data={status?.data}
                                                filterName="status"
                                                labelKey="label"
                                                valueKey="value"
                                                isItemChecked={isItemChecked}
                                            />
                                        ))}

                                    {financeType && (
                                        <OneItemFilter
                                            id={`${id}-filter-finance-type`}
                                            className={style.spacer}
                                            renderValue={"Finance type"}
                                            loading={financeType?.loading}
                                            handleFilterChange={
                                                handleFilterChange
                                            }
                                            data={financeType?.data}
                                            filterName="financeType"
                                            labelKey="label"
                                            valueKey="value"
                                            value={filters["financeType"]}
                                        />
                                    )}

                                    {brands && (
                                        <SelectFilter
                                            id={`${id}-filter-brand`}
                                            className={style.spacer}
                                            renderValue={"Brand"}
                                            loading={brands?.loading}
                                            handleFilterChange={
                                                handleFilterChange
                                            }
                                            data={brands?.data}
                                            filterName="brand"
                                            labelKey="brandName"
                                            valueKey="brandId"
                                            isItemChecked={isItemChecked}
                                        />
                                    )}

                                    {therapeuticAreas && (
                                        <SelectFilter
                                            id={`${id}-filter-therapeuticAreas`}
                                            className={style.spacer}
                                            renderValue={"Therapeutic area"}
                                            loading={therapeuticAreas?.loading}
                                            handleFilterChange={
                                                handleFilterChange
                                            }
                                            data={therapeuticAreas?.data}
                                            filterName="therapeutic_area"
                                            labelKey="therapeuticAreaName"
                                            valueKey="therapeuticAreaId"
                                            isItemChecked={isItemChecked}
                                        />
                                    )}

                                    {!!partners && (
                                        <Box
                                            className={style.autocompleteSpacer}
                                        >
                                            <Autocomplete
                                                data={partners?.data || []}
                                                id={`${id}-partners-filter`}
                                                loading={partners.loading}
                                                name="account"
                                                size="small"
                                                label="Partner"
                                                displayEmpty
                                                onChange={value => {
                                                    handleFilterChange(
                                                        "account",
                                                        value,
                                                        "accountId",
                                                    );
                                                }}
                                                keysToMatch={[
                                                    "accountName",
                                                    "accountCity",
                                                    "sapAccountCode",
                                                    "knowyoursupplierid",
                                                ]}
                                                value={""}
                                                onSearch={(query: string) =>
                                                    partners.onSearch(query)
                                                }
                                                variant="outlined"
                                            />
                                        </Box>
                                    )}

                                    {priceRange && (
                                        <DataRange
                                            id={`${id}-priceRange-filter`}
                                            value={{
                                                priceRangeEnd:
                                                    filters?.priceRangeEnd,
                                                priceRangeStart:
                                                    filters?.priceRangeStart,
                                            }}
                                            handleFilterChange={(
                                                price: Record<
                                                    | "priceRangeStart"
                                                    | "priceRangeEnd",
                                                    string
                                                >,
                                            ) =>
                                                handleFilterChange(
                                                    [
                                                        "priceRangeStart",
                                                        "priceRangeEnd",
                                                    ],
                                                    price,
                                                )
                                            }
                                        />
                                    )}

                                    {showTasksTypeFilter && (
                                        <OneItemFilter
                                            id={`${id}-filter-all-tasks`}
                                            className={style.spacer}
                                            renderValue={"Task view"}
                                            loading={false}
                                            disableItemSelection={
                                                disableSelectionFilters
                                            }
                                            handleFilterChange={
                                                handleFilterChange
                                            }
                                            data={[
                                                {
                                                    label: "Show my tasks",
                                                    value: "false",
                                                },
                                                {
                                                    label: "Show all tasks",
                                                    value: "true",
                                                },
                                            ]}
                                            filterName="allTasks"
                                            labelKey="label"
                                            valueKey="value"
                                            value={filters["allTasks"]}
                                        />
                                    )}
                                    {showFinancialTriggerTypeFilter && (
                                        <OneItemFilter
                                            id={`${id}-filter-show-financial-trigger-type-filter`}
                                            className={style.spacer}
                                            renderValue={
                                                "Financial trigger type"
                                            }
                                            loading={false}
                                            disableItemSelection={
                                                disableSelectionFilters
                                            }
                                            handleFilterChange={
                                                handleFilterChange
                                            }
                                            data={[
                                                {
                                                    label: "Rebate at infusion",
                                                    value: "rebateAtInfusion",
                                                },
                                                {
                                                    label: "Rebate at apheresis",
                                                    value: "rebateAtApheresis",
                                                },
                                            ]}
                                            filterName="financialTriggerType"
                                            labelKey="label"
                                            valueKey="value"
                                            value={
                                                filters["financialTriggerType"]
                                            }
                                        />
                                    )}
                                </div>
                            </Fragment>
                        )}
                    </div>
                </Box>

                {simpleLayout && (
                    <Divider variant="fullWidth" className={style.divider} />
                )}
                <div className={style.chipWrapper}>
                    {isArrayWithContent(filters.accountType) &&
                        labelRenderer(
                            "Account type",
                            "accountType",
                            "accountTypeName",
                        )}
                    {isArrayWithContent(filters.status) &&
                        labelRenderer("Status", "status", "label")}
                    {isArrayWithContent(filters.financeType) &&
                        labelRenderer("Finance type", "financeType", "label")}
                    {isArrayWithContent(filters.type) &&
                        labelRenderer("Contract type", "type", "label")}
                    {isArrayWithContent(filters.countryIsoCode) &&
                        labelRenderer("Country", "countryIsoCode", "country")}
                    {isArrayWithContent(filters.account) &&
                        labelRenderer("Partner", "account", "accountName")}
                    {isArrayWithContent(filters.brand) &&
                        labelRenderer("Brand", "brand", "brandName")}
                    {isArrayWithContent(filters.product) &&
                        labelRenderer("Product", "product", "productName")}
                    {isArrayWithContent(filters.taskDefinitionKey) &&
                        labelRenderer(
                            "Task definition keys",
                            "taskDefinitionKey",
                            "keyName",
                        )}
                    {isArrayWithContent(filters.therapeutic_area) &&
                        labelRenderer(
                            "Therapeutic Area",
                            "therapeutic_area",
                            "therapeuticAreaName",
                        )}
                    {filters.priceRangeStart &&
                        labelRenderer("Price start", "priceRangeStart")}
                    {filters.priceRangeEnd &&
                        labelRenderer("Price end", "priceRangeEnd")}

                    {(filters.valid_from || filters.startDate) &&
                        labelRenderer(
                            "Start date",
                            filters.startDate ? "startDate" : "valid_from",
                        )}
                    {(filters.valid_to || filters.endDate) &&
                        labelRenderer(
                            "End date",
                            filters.endDate ? "endDate" : "valid_to",
                        )}

                    {isArrayWithContent(filters.allTasks) &&
                        labelRenderer("Task view", "allTasks", "label", true)}

                    {isArrayWithContent(filters.financialTriggerType) &&
                        labelRenderer(
                            "Financial trigger type",
                            "financialTriggerType",
                            "label",
                            true,
                        )}

                    {!isArrayWithContent(filters.allTasks) &&
                        !isArrayWithContent(filters?.financialTriggerType) &&
                        Object.values(filters).some(
                            filter => filter.length,
                        ) && (
                            <Button
                                id={`${id}-remove-all-filters-btn`}
                                variant="text"
                                className={style.removeFilters}
                                onClick={() => onDeleteAllFilters()}
                            >
                                Remove all filters
                            </Button>
                        )}
                </div>
            </div>
        );
    },
);

export default SearchBar;
