// Libs
import GridOn from "@mui/icons-material/GridOn";
import { navigate } from "gatsby";
import clonedeep from "lodash.clonedeep";
import React, { useEffect, useMemo, useRef, Fragment } from "react";
import { useRecoilState, useRecoilValue, useResetRecoilState } from "recoil";

// Own component
import {
    Breadcrumbs,
    StepActions,
    Stepper,
    FlowLayout,
    FlowContent,
    FlowBody,
    FlowAside,
    FlowFooter,
} from "@components";
// Hooks
import { useFindStep, useCreateOutcome } from "@hooks";
// Recoil
import {
    createOutcomeStepsState,
    contractSelectionState,
    orderSelectionState,
} from "@atoms";
import { createOutcomeMapper } from "@selectors";

// Types
import type { Location } from "@types";
import { OutcomeOverview } from "@containers";
import { isSuccessfulCall } from "@utils";

/**
 * Props type
 */
interface Props {
    children: React.ReactNode;
    location: Location;
}

/**
 * Create outcome layout
 */
const CreateOutcomeLayout = ({ location, children }: Props) => {
    /**
     * States
     */
    // via this ref we can save the state of each page via stepper (higher order)
    const stepRef = useRef<any>();

    //API
    const { loading: createOutcomeLoading, createOutcome } = useCreateOutcome();

    //Steps hooks
    const { currentIndex: currentStepIndex, currentStep } = useFindStep(
        location,
        createOutcomeStepsState,
    );
    const [steps, setSteps] = useRecoilState(createOutcomeStepsState);
    const resetAtoms = useResetRecoilState(createOutcomeMapper);

    /**
     * States
     */

    // Contract state
    const contractSelection = useRecoilValue(contractSelectionState);
    // Order state
    const orderSelection = useRecoilValue(orderSelectionState);

    /**
     * Reset stored atom
     */
    const reset = () => {
        resetAtoms();
    };

    const isFinished = useMemo(() => {
        return !!steps[steps.length - 1]?.isCompleted;
    }, [steps]);

    /**
     * Reset recoil state on refresh
     */
    useEffect(() => {
        if (
            currentStepIndex > 0 &&
            steps[currentStepIndex - 1].isPrepared === false
        ) {
            reset();
            navigate(`/create-outcome/${steps[0].id}/`);
            return;
        }
    }, [currentStepIndex, location]);

    /**
     * Check if the request can be submitted
     */
    const canSubmit = useMemo(() => {
        if (currentStepIndex !== steps.length - 1) return false; //is not submit

        return !steps.find(
            (step, index) => index < currentStepIndex && !step.isCompleted,
        );
    }, [currentStepIndex, steps]);

    /**
     *  Reset on refresh the page
     */
    useEffect(() => {
        const handleUnload = (e: any) => {
            e.preventDefault();
            const dialogText =
                "You are about to leave the page. Your data will not be saved and thus will be lost."; // old browsers.
            e.returnValue = dialogText;
            return dialogText;
        };
        if (typeof window !== "undefined") {
            window.addEventListener("beforeunload", handleUnload);
            return function cleanup() {
                window.removeEventListener("beforeunload", handleUnload);
            };
        }
    }, []);

    /**
     * Next Click handler
     */
    const onNextClick = () => {
        // reset and navigate to dashboard
        if (isFinished) {
            navigate("/dashboard/outcomes/");
            reset();
            return;
        }

        if (!canSubmit && !isFinished && steps[currentStepIndex].isPrepared) {
            stepRef.current.updateState(); // store the state in an atom
            const copySteps = clonedeep(steps);
            copySteps[currentStepIndex].isCompleted = true;
            setSteps(copySteps);

            return navigate(
                `/create-outcome/${steps[currentStepIndex + 1].id}`,
            );
        }

        // Submit
        if (canSubmit) {
            const data = stepRef.current.getStateData(); // get the data of the state
            const mapper = {
                contractId: contractSelection.id,
                orderId: orderSelection.orderId,
                outcomeVariables: data.mappedVariables,
                evidences: data.evidences,
                reason: data.reason,
            };

            createOutcome(mapper).then(response => {
                if (!!response && isSuccessfulCall(response.status)) {
                    const copySteps = clonedeep(steps);
                    copySteps[currentStepIndex].isCompleted = true;
                    setSteps(copySteps);
                }
            });
            return;
        }
    };

    /**
     * Click back handler
     */
    const onBackClick = () => {
        navigate(`/create-outcome/${steps[currentStepIndex - 1].id}/`);
    };

    /**
     * Render
     */
    return (
        <Fragment>
            <Breadcrumbs
                id={`create-outcome-breadcrumb`}
                icon={<GridOn color="primary" />}
                title="Dashboard"
                location={location}
                resetAtoms={() => reset()}
            />

            <FlowLayout>
                <FlowAside>
                    <Stepper
                        id={`create-outcome-stepper`}
                        steps={steps}
                        title="Register a new outcome"
                        location={location}
                        disabled={createOutcomeLoading || !!isFinished}
                    />
                </FlowAside>

                <FlowBody>
                    <FlowContent>
                        {!!isFinished && <OutcomeOverview />}
                        {!isFinished &&
                            React.Children.map(
                                children as React.ReactElement,
                                (child: React.ReactElement) =>
                                    React.cloneElement(child, {
                                        location,
                                        currentStep,
                                        ref: stepRef,
                                        isFinished,
                                        disabled: createOutcomeLoading,
                                        id: "create-outcome",
                                    }),
                            )}
                    </FlowContent>

                    <FlowFooter>
                        <StepActions
                            id={`create-outcome-step-actions`}
                            loading={createOutcomeLoading}
                            primaryButton={{
                                text: isFinished
                                    ? "Return to overview"
                                    : canSubmit
                                      ? "Process outcome"
                                      : "Next step",
                                action: onNextClick,
                                disabled:
                                    createOutcomeLoading ||
                                    (currentStepIndex !== undefined &&
                                        !steps[currentStepIndex]?.isPrepared),
                                isSubmitButton:
                                    currentStepIndex === steps.length ||
                                    isFinished,
                            }}
                            secondaryButton={{
                                text: "Back",
                                action: onBackClick,
                                disabled: createOutcomeLoading,
                                hidden:
                                    isFinished ||
                                    (currentStepIndex !== undefined &&
                                        currentStepIndex === 0),
                            }}
                            tertiaryButton={{
                                text: "Register another outcome",
                                action: () => {
                                    reset();
                                    navigate(`/create-outcome/${steps[0].id}/`);
                                },
                                hidden: !isFinished,
                            }}
                        />
                    </FlowFooter>
                </FlowBody>
            </FlowLayout>
        </Fragment>
    );
};

export default CreateOutcomeLayout;
