// Icons
import DownloadIcon from "@mui/icons-material/Download";

// Libs
import AssignmentReturnedIcon from "@mui/icons-material/AssignmentReturned";
import LockIcon from "@mui/icons-material/Lock";
import {
    Grid,
    Card,
    CardContent,
    Typography,
    CardActions,
    Button,
    CircularProgress,
} from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";

// Own components
import { Breadcrumbs } from "@components";

// Hooks
import {
    useLandingPage,
    useGetReports,
    usePermission,
    useUserTeamCountry,
} from "@hooks";

// Types
import type { Location, Permissions } from "@types";

// Utils
import { isSuccessfulCall, startDownload } from "@utils";

/**
 * Props type
 */
interface Props {
    children?: React.ReactNode;
    location: Location;
}

/**
 * Reports Overview
 */
const ReportsOverview: React.FC<Props> = ({ location }) => {
    const {
        hasPermissionToApproveFinancialAction,
        hasPermissionToEditDataTables,
    }: Permissions = usePermission();

    const { TEAM_GERMANY } = useUserTeamCountry();

    // Store the index of the report
    const [selectedReport, setSelectedReport] = useState<undefined | number>(
        undefined,
    );

    /**
     * Permissions
     */
    const { areFinancesVisible, areOrdersVisible, areOutcomesVisible } =
        useLandingPage();

    /**
     * API
     */
    const { loading, load } = useGetReports();

    /**
     * Tabs
     */
    const tabs = useMemo(() => {
        return [
            {
                title: "Contracts",
                path: "contracts",
                disabled: false,
            },
            {
                title: "Contract approval",
                path: "contractApprovals",
                disabled: false,
            },
            {
                title: "Contract product price",
                path: "productprices",
                disabled:
                    !areFinancesVisible &&
                    !areOrdersVisible &&
                    !areOutcomesVisible &&
                    !hasPermissionToApproveFinancialAction,
            },
            {
                title: "SCP orders",
                path: "orders",
                disabled:
                    !areFinancesVisible &&
                    !areOrdersVisible &&
                    !areOutcomesVisible &&
                    !hasPermissionToApproveFinancialAction,
            },
            {
                title: "Outcomes flow",
                path: "outcomes",
                disabled:
                    !areFinancesVisible &&
                    !areOutcomesVisible &&
                    !hasPermissionToApproveFinancialAction,
            },
            {
                title: "Finance documents",
                path: "finances",
                disabled:
                    !areFinancesVisible &&
                    !hasPermissionToApproveFinancialAction,
            },
            {
                title: "Finance approval",
                path: "financialApprovals",
                disabled:
                    !areFinancesVisible &&
                    !hasPermissionToApproveFinancialAction,
            },
            {
                title: "Exceptions",
                path: "exceptions",
                disabled: false,
            },
            {
                title: "Teams",
                path: "teams",
                disabled: false,
            },
            {
                title: "Accrual",
                path: "accrual",
                disabled:
                    !areFinancesVisible &&
                    !hasPermissionToApproveFinancialAction,
            },
            {
                title: "Outcomes - automatic processing validation",
                path: "outcomesAutoProcessValidation",
                disabled:
                    !areFinancesVisible &&
                    !areOutcomesVisible &&
                    !hasPermissionToApproveFinancialAction,
                hidden: !TEAM_GERMANY,
            },
            {
                title: "IQVIA Padds - Lauer Taxe",
                path: "datasets",
                disabled:
                    !areFinancesVisible &&
                    !areOutcomesVisible &&
                    !hasPermissionToApproveFinancialAction,
                hidden: !TEAM_GERMANY,
            },
            {
                title: "Mappings",
                path: "mappings",
                disabled: !hasPermissionToEditDataTables,
            },
            {
                title: "Claim approval",
                path: "audit",
                disabled:
                    !areFinancesVisible &&
                    !hasPermissionToApproveFinancialAction,
            },
            {
                title: "Accrual Adjustment",
                path: "accrualAdjustment",
                disabled: !hasPermissionToEditDataTables,
                hidden: !TEAM_GERMANY,
            },
        ];
    }, [
        areOrdersVisible,
        areFinancesVisible,
        areOutcomesVisible,
        hasPermissionToApproveFinancialAction,
        hasPermissionToEditDataTables,
        TEAM_GERMANY,
    ]);

    /**
     * Download handler
     */
    const handleDownload = (report: any) => {
        const path = report?.path;

        load(path).then(res => {
            if (isSuccessfulCall(res?.status) && res?.data?.location) {
                startDownload(res?.data?.location);
            }
            setSelectedReport(undefined);
        });
    };

    useEffect(() => {
        if (selectedReport === undefined) return;

        handleDownload(tabs[selectedReport]);
    }, [selectedReport]);

    /**
     * Render
     */
    return (
        <div>
            <Breadcrumbs
                id="reports-breadcrumbs"
                icon={<AssignmentReturnedIcon color="primary" />}
                title="Reports"
                location={location}
            />

            <Grid container item xs={12} px={6} py={7} spacing={3}>
                {tabs.map(
                    (tab, index) =>
                        !tab?.hidden && (
                            <Grid item xs={12} sm={6} md={4} key={index}>
                                <Card
                                    variant="outlined"
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                    }}
                                >
                                    <CardContent>
                                        <Typography
                                            variant="h4"
                                            textAlign="center"
                                        >
                                            {tab.title}
                                        </Typography>
                                    </CardContent>
                                    <CardActions>
                                        {loading &&
                                            selectedReport === index && (
                                                <CircularProgress
                                                    color="inherit"
                                                    size={20}
                                                />
                                            )}
                                        {(!loading ||
                                            (loading &&
                                                selectedReport !== index)) && (
                                            <Button
                                                id={`reports-${tab.path}-download-btn`}
                                                size="small"
                                                variant="text"
                                                disabled={
                                                    tab.disabled || loading
                                                }
                                                onClick={() => {
                                                    setSelectedReport(index);
                                                }}
                                            >
                                                {tab?.disabled && (
                                                    <LockIcon
                                                        fontSize="small"
                                                        sx={{
                                                            marginRight:
                                                                "0.3rem",
                                                        }}
                                                    />
                                                )}
                                                {!tab?.disabled && (
                                                    <DownloadIcon
                                                        fontSize="small"
                                                        sx={{
                                                            marginRight:
                                                                "0.3rem",
                                                            color: loading
                                                                ? "text.disabled"
                                                                : `#019cdc`,
                                                        }}
                                                    />
                                                )}

                                                <Typography
                                                    variant="link"
                                                    sx={{
                                                        color:
                                                            loading ||
                                                            tab.disabled
                                                                ? "text.disabled"
                                                                : "",
                                                    }}
                                                >
                                                    {"Download"}
                                                </Typography>
                                            </Button>
                                        )}
                                    </CardActions>
                                </Card>
                            </Grid>
                        ),
                )}
            </Grid>
        </div>
    );
};
export default ReportsOverview;
