//Lib
import { Box, Button, Typography } from "@mui/material";
import classNames from "classnames";
import { navigate } from "gatsby";
import React, { useMemo } from "react";

//Own components
import { FormFooter, GrayBox, InfoBox, OrderDetails } from "@components";

// Utils
import { displayDate } from "@utils";

// Style
import * as style from "./style.module.scss";

/**
 * Props
 */
interface Props {
    currentStep?: any;
    order?: any;
    id?: string;
}

/**
 * Order view
 */
const ViewOrder = React.forwardRef(({ order, id }: Props) => {
    /**
     * Check if the user can link the order
     */
    const canLinkOrder = useMemo(() => {
        return (
            order?.infusionDate &&
            order?.sapSkuNo &&
            order?.orderStatus === "NON_LINKED"
        );
    }, [order]);

    return (
        <Box
            display="flex"
            flexDirection="column"
            id={order?.orderId}
            className={style.wrapper}
        >
            <div>
                <Typography variant="h2">Order details</Typography>

                <OrderDetails {...order} id={id} />

                {!!order?.contract?.id && (
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        mt={5}
                        mb={3}
                    >
                        <Typography variant="h3">Contract details</Typography>

                        <Button
                            id={`${id}-view-contract-btn`}
                            variant="text"
                            onClick={() =>
                                navigate(
                                    `/contract/view-contract/${order?.contract?.id}`,
                                )
                            }
                        >
                            <Typography variant="link" className={style.link}>
                                {"See full contract detail"}
                            </Typography>
                        </Button>
                    </Box>
                )}

                {order?.contract?.id ? (
                    <GrayBox
                        id={`${id}-gray-box`}
                        padding={"1rem"}
                        header={
                            <Box>
                                <Typography variant="h3">
                                    {order?.contract?.reference}
                                </Typography>
                                <Box
                                    display="flex"
                                    className={classNames(
                                        style.details,
                                        style.separator,
                                    )}
                                >
                                    {!!order?.treatmentSite && (
                                        <Typography variant="subtitle2">
                                            {order?.treatmentSite}
                                        </Typography>
                                    )}
                                    {!!order?.contract?.brandName && (
                                        <Typography variant="subtitle2">
                                            {order?.contract?.brandName}
                                        </Typography>
                                    )}
                                    {!!order?.contract?.indicationName && (
                                        <Typography variant="subtitle2">
                                            {order?.contract?.indicationName}
                                        </Typography>
                                    )}
                                    {!!order?.contract?.startDate && (
                                        <Typography variant="subtitle2">
                                            {`Valid from ${displayDate(
                                                order?.contract?.startDate,
                                            )} to ${displayDate(
                                                order?.contract?.endDate,
                                            )}`}
                                        </Typography>
                                    )}
                                </Box>
                            </Box>
                        }
                    />
                ) : (
                    canLinkOrder && (
                        <div className={style.infoBox}>
                            <InfoBox
                                id={`${id}-order-view`}
                                warning
                                message={
                                    <Box display="flex" alignItems="baseline">
                                        <Typography variant="subtitle2">
                                            This order is not yet linked to a
                                            contract.
                                        </Typography>
                                        <Button
                                            variant="text"
                                            onClick={() =>
                                                navigate(
                                                    `/order/link-order/${order?.orderId}`,
                                                )
                                            }
                                        >
                                            <Typography
                                                variant="link"
                                                className={style.linkOrder}
                                            >
                                                Link order
                                            </Typography>
                                        </Button>
                                    </Box>
                                }
                            />
                        </div>
                    )
                )}
            </div>
            <FormFooter
                showText={false}
                textAlign="right"
                id={`${id}-view=order-footer`}
            />
        </Box>
    );
});

export default React.memo(ViewOrder);
