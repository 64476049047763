//import icons
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

// import libs
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import classnames from "classnames";
import React, { Fragment } from "react";

// Types
import type { Button as ButtonType } from "@types";

//import styles
import * as style from "./style.module.scss";

/**
 * Props type
 */
interface Props {
    loading?: boolean;
    primaryButton: ButtonType;
    secondaryButton?: ButtonType;
    tertiaryButton?: ButtonType;
    hidden?: boolean;
    id: string;
}

/**
 * Stepper actions
 */
const StepsActions: React.FC<Props> = ({
    loading,
    primaryButton,
    secondaryButton,
    tertiaryButton,
    hidden,
    id,
}) => {
    /**
     * Render
     */
    return !hidden ? (
        <Fragment>
            <Divider />
            <Box
                display="flex"
                justifyContent={
                    !secondaryButton || secondaryButton.hidden
                        ? "flex-end"
                        : "space-between"
                }
                mt={3}
                className={style.actionsWrapper}
            >
                {!!secondaryButton && !secondaryButton.hidden && (
                    <Button
                        className={style.action}
                        variant="text"
                        color="primary"
                        onClick={secondaryButton.action}
                        size="large"
                        disabled={secondaryButton.disabled}
                        id={`${id}-secondary-btn`}
                    >
                        <Typography
                            variant="button"
                            sx={{ fontSize: "0.8rem" }}
                        >
                            {secondaryButton.text}
                        </Typography>
                    </Button>
                )}

                <Stack direction="row" spacing={3}>
                    {!!tertiaryButton && !tertiaryButton.hidden && (
                        <Button
                            id={`${id}-tertiary-btn`}
                            variant="outlined"
                            color="primary"
                            className={style.action}
                            size="large"
                            onClick={tertiaryButton.action}
                            disabled={tertiaryButton.disabled}
                        >
                            <Typography variant="button">
                                {tertiaryButton.text}
                            </Typography>
                        </Button>
                    )}

                    {!!primaryButton && !primaryButton?.hidden && (
                        <Button
                            id={`${id}-primary-btn`}
                            variant="contained"
                            size="large"
                            disableElevation
                            className={classnames(style.action, {
                                [style.actionWithoutIcon]:
                                    primaryButton.isSubmitButton,
                            })}
                            endIcon={
                                primaryButton.isSubmitButton ||
                                loading ? undefined : (
                                    <ArrowForwardIcon fontSize="small" />
                                )
                            }
                            color="primary"
                            onClick={primaryButton.action}
                            disabled={primaryButton.disabled}
                        >
                            {loading ? (
                                <CircularProgress size={20} color="inherit" />
                            ) : (
                                <Typography variant="button">
                                    {primaryButton.text}
                                </Typography>
                            )}
                        </Button>
                    )}
                </Stack>
            </Box>
        </Fragment>
    ) : null;
};

export default React.memo(StepsActions);
