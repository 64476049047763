/*
 *  Check if an array exists and has elements
 */
export const isArrayWithContent = (array: any): boolean =>
    !!array && Array.isArray(array) && array.length > 0;

/*
 *  Trim certain number of elements
 */

export const truncateArray = (
    data: Array<any>,
    limit?: number,
    field?: string,
) => {
    if (!isArrayWithContent(data)) return "";
    if (limit && data.length > limit) data = data.slice(0, limit).concat("...");
    return data
        .map((el: any) => (!!field && el[field] ? el[field] : el))
        .join(", ");
};

export const isObjectWithContent = (obj: any): boolean =>
    typeof obj === "object" &&
    obj !== null &&
    !Array.isArray(obj) &&
    Object.keys(obj).length > 0;

export const sortByField = (
    arr: Array<any>,
    field: string,
    subField?: string,
) => {
    if (!isArrayWithContent(arr)) return [];

    if (subField) {
        return arr.sort((a, b) =>
            a[field][subField]?.localeCompare(b[field][subField]),
        );
    }
    return arr.sort((a, b) => a[field]?.localeCompare(b[field]));
};
