// icon
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Button from "@mui/material/Button";
import EditIcon from "@mui/icons-material/Edit";

// lib
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import classnames from "classnames";
import React, { useEffect, useState } from "react";

//style
import * as style from "./style.module.scss";
import { isArrayWithContent } from "@utils";

/**
 * Action type
 */
interface Action {
    icon?: React.ReactNode;
    text?: string | React.ReactNode;
    onClick?: () => void;
    disabled?: boolean;
    sx?: any;
    id?: string;
    hidden?: boolean;
}

/**
 * Props type
 */
interface Props {
    header: React.ReactNode;
    subheaderSplitter?: Array<string | undefined>;
    subheaderSplitterType?: "caption" | "subtitle";
    primaryAction?: Action;
    secondaryAction?: Action;
    children?: React.ReactNode;
    collapsible?: boolean;
    className?: string;
    helper?: string | React.ReactNode;
    padding?: number | string;
    marginY?: number | string;
    id: string;
    collapse?: boolean;
    wrapperPaddingX?: number;
    wrapperPaddingY?: number;
}

/**
 * Gray Box
 */
const GrayBox: React.FC<Props> = ({
    header,
    subheaderSplitter,
    primaryAction,
    secondaryAction,
    children,
    collapsible,
    className,
    helper,
    padding,
    id,
    collapse,
    marginY,
    subheaderSplitterType,
    wrapperPaddingX,
    wrapperPaddingY,
}) => {
    const [toggleView, setView] = useState(false);

    useEffect(() => {
        if (collapse === undefined) return;
        setView(collapse);
    }, [collapse]);

    return (
        <Grid item xs={12} className={classnames(style.boxWrapper, className)}>
            <Grid
                item
                container
                xs={12}
                p={padding || 5}
                marginY={marginY}
                justifyContent="space-between"
                alignItems={!subheaderSplitter ? "flex-end" : "unset"}
            >
                {header && (
                    <Grid
                        item
                        xs={!primaryAction && !secondaryAction ? 12 : 7}
                        lg={!primaryAction && !secondaryAction ? 12 : 8}
                        //xl={!primaryAction && !secondaryAction ? 12 : 9}
                    >
                        {header}
                    </Grid>
                )}

                {((!!primaryAction && !!primaryAction.onClick) ||
                    (!!secondaryAction && !!secondaryAction?.onClick)) && (
                    <Grid
                        item
                        xs={5}
                        lg={4}
                        //xl={3}
                        container
                        justifyContent="flex-end"
                        className={style.actions}
                    >
                        <Box display="flex">
                            {!!primaryAction &&
                                !primaryAction?.hidden &&
                                (!primaryAction?.text ? (
                                    <IconButton
                                        id={`${id}-primary-icon-btn`}
                                        color="primary"
                                        component="span"
                                        onClick={primaryAction?.onClick}
                                        disabled={primaryAction?.disabled}
                                        sx={primaryAction?.sx}
                                    >
                                        {primaryAction?.icon || (
                                            <EditIcon fontSize="small" />
                                        )}
                                    </IconButton>
                                ) : (
                                    <Button
                                        variant="text"
                                        startIcon={primaryAction?.icon}
                                        color="primary"
                                        onClick={primaryAction?.onClick}
                                        disabled={primaryAction?.disabled}
                                        sx={primaryAction?.sx}
                                        id={`${id}-primary-btn`}
                                    >
                                        {primaryAction?.text}
                                    </Button>
                                ))}

                            {!!secondaryAction &&
                                (!secondaryAction?.text ? (
                                    <IconButton
                                        id={`${id}-primary-icon-btn`}
                                        color="primary"
                                        component="span"
                                        onClick={secondaryAction?.onClick}
                                        disabled={secondaryAction?.disabled}
                                        sx={secondaryAction?.sx}
                                    >
                                        {secondaryAction?.icon}
                                    </IconButton>
                                ) : (
                                    <Button
                                        variant="text"
                                        startIcon={secondaryAction?.icon}
                                        color="primary"
                                        onClick={secondaryAction?.onClick}
                                        disabled={secondaryAction?.disabled}
                                        sx={{
                                            marginLeft: primaryAction
                                                ? "0.1rem"
                                                : "unset",
                                            ...secondaryAction?.sx,
                                        }}
                                        id={`${id}-secondary-btn`}
                                    >
                                        {secondaryAction?.text}
                                    </Button>
                                ))}
                        </Box>
                    </Grid>
                )}
                {!!subheaderSplitter &&
                    isArrayWithContent(subheaderSplitter) && (
                        <Grid item xs={12} className={style.splitter}>
                            {subheaderSplitter.map((str, index) => {
                                return (
                                    str && (
                                        <Typography
                                            variant={
                                                subheaderSplitterType ===
                                                "caption"
                                                    ? "captionSmall"
                                                    : "subtitle2"
                                            }
                                            key={index}
                                            color={"black"}
                                        >
                                            {str}
                                        </Typography>
                                    )
                                );
                            })}
                        </Grid>
                    )}

                {!!helper && (
                    <Grid item xs={12}>
                        {helper}
                    </Grid>
                )}
            </Grid>

            {!!children && (
                <Grid item xs={12} className={style.divider}>
                    <Divider />
                    {collapsible && (
                        <IconButton
                            component="span"
                            color="primary"
                            onClick={() => setView(!toggleView)}
                            className={style.arrow}
                            id={`${id}-collapse-btn`}
                        >
                            {toggleView ? (
                                <KeyboardArrowUpIcon />
                            ) : (
                                <KeyboardArrowDownIcon />
                            )}
                        </IconButton>
                    )}
                </Grid>
            )}

            {((!!children && !collapsible) || toggleView) && (
                <Grid
                    item
                    xs={12}
                    px={wrapperPaddingX || 5}
                    py={wrapperPaddingY || 3}
                >
                    {children}
                </Grid>
            )}
        </Grid>
    );
};
export default React.memo(GrayBox);
