// Icons
import AddIcon from "@mui/icons-material/Add";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

// Lib
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import clonedeep from "lodash.clonedeep";
import React, { useEffect, useMemo, useState } from "react";

// Types
import {
    Country,
    MaintenanceCountries as CountryListType,
    TeamRole,
    TherapeuticAreas as TherapeuticAreasType,
} from "@types";

// Own components
import { GrayBox, MultiSelect, Select } from "@components";
import AddUser from "../AddUser";

// Style
import * as style from "./style.module.scss";
import { isArrayWithContent } from "@utils";

/**
 * Right type
 */
type Right = {
    category: string;
    rightId: string;
    rightName: string;
};

type UserInfo = {
    email: string;
    rightIds: Right;
    scpUserId?: string;
};

/**
 * User type
 */
interface User {
    email: string;
    rightIds: Array<string>;
}

/**
 * Form values state
 */
interface FormState {
    therapeuticAreaIds: Array<string>;
    countries: Array<Country>;
    teamName: string;
    minimumContractReviewers: string | number;
    minimumFinancialApprovers: string | number;
    users: Array<User>;
}

/**
 * Props type
 */
interface Props {
    id: string;
    values: FormState;
    countryList: CountryListType;
    therapeuticAreaList: TherapeuticAreasType;
    errors?: any;
    touched?: any;
    setFieldValue: (type: string, value: any) => void;
    handleChange: (e: React.ChangeEvent<any>) => void;
    onBlur: (e: any) => void;
    disabled?: boolean;
    rights: {
        data: Array<Right>;
    };
    setEditingUser: (value: boolean) => void;
    roles?: {
        data: Array<TeamRole>;
        loading: boolean;
    };
    setResetUsersWarning?: (value: Country) => void;
}
/**
 * Create team
 */
const CreateTeamForm: React.FC<Props> = ({
    id,
    values,
    errors,
    touched,
    countryList,
    therapeuticAreaList,
    setFieldValue,
    handleChange,
    onBlur,
    disabled,
    rights,
    setEditingUser,
    roles,
    setResetUsersWarning,
}) => {
    const [editUserIndex, setEditUserIndex] = useState<any>(undefined);

    useEffect(() => {
        if (editUserIndex === undefined) {
            setEditingUser(false);
        } else setEditingUser(true);
    }, [editUserIndex]);

    /**
     * Countries mapper
     */
    const mappedCountries = useMemo(() => {
        if (!isArrayWithContent(countryList?.data)) return [];
        return countryList?.data.map(country => {
            return {
                isoCode: country.countryIsoCode,
                name: country.country,
            };
        });
    }, []);

    /**
     * Add new user
     */
    const addUser = (index?: number, value?: UserInfo) => {
        const copyUsers = clonedeep(values?.users);
        if (index !== undefined && !!value) {
            copyUsers[index] = value;

            setEditUserIndex(undefined);
        } else {
            copyUsers.push({ email: "", rightIds: [] });

            setEditUserIndex(copyUsers.length - 1);
        }

        setFieldValue("users", copyUsers);
    };

    /**
     * Delete user
     */
    const deleteUsers = (index: number) => {
        const copyUsers = clonedeep(values?.users);
        const filteredUsers = copyUsers.filter((_, idx) => index !== idx);
        setFieldValue("users", filteredUsers);
        setEditUserIndex(undefined);
    };

    /**
     * Select therapeuticArea handler
     */
    const onSelectTherapeuticArea = (event: React.SyntheticEvent) => {
        const target = event.target as HTMLInputElement;
        //values is array of therapeuticAreaIds
        const valueSize = target.value.length;

        const value = JSON.parse(
            target.value[valueSize - 1],
        )?.therapeuticAreaId;
        const copyTherapeuticAreas = clonedeep(values?.therapeuticAreaIds);

        const idx = copyTherapeuticAreas.findIndex(id => id === value);
        if (idx === -1) {
            copyTherapeuticAreas.push(value);
        } else {
            copyTherapeuticAreas.splice(idx, 1);
        }
        setFieldValue("therapeuticAreaIds", copyTherapeuticAreas);
    };

    /**
     * On field type number change
     */

    const onNumberChange = (value: string, field: string) => {
        if (value === "") {
            setFieldValue(field, 0);
        }
        if (!isNaN(parseInt(value)) && parseInt(value) > -1) {
            setFieldValue(field, parseInt(value));
        }
    };

    /**
     * Render
     */
    return (
        <form
            noValidate
            onBlur={onBlur}
            className={style.form}
            id={`${id}-contract-team-form`}
        >
            <Grid container spacing={3}>
                <Grid
                    item
                    container
                    rowSpacing={3}
                    columnSpacing={{ xs: 0, md: 4 }}
                >
                    <Grid item xs={12}>
                        <Typography variant="h3">
                            Contract team details
                        </Typography>
                    </Grid>{" "}
                    <Grid item xs={12} md={6}>
                        <InputLabel
                            id={`${id}-contract-team-label`}
                            shrink
                            error={!!errors.teamName && touched.teamName}
                        >
                            {"Contract team name (*)"}
                        </InputLabel>
                        <TextField
                            id={`${id}-team-name`}
                            fullWidth
                            name="teamName"
                            autoComplete="off"
                            value={values?.teamName}
                            onChange={handleChange}
                            variant="outlined"
                            size="small"
                            error={!!errors.teamName && !!touched.teamName}
                            disabled={disabled}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <InputLabel
                            id={`${id}-team-country`}
                            error={!!errors.countries && !!touched.countries}
                            shrink
                        >
                            {"Select country (*)"}
                        </InputLabel>
                        <Select
                            id={`${id}-select-country-list`}
                            name="countries"
                            onChange={(_, idx) => {
                                if (
                                    !!setResetUsersWarning &&
                                    !!values?.countries[0] &&
                                    mappedCountries[idx]?.isoCode !==
                                        values?.countries[0]?.isoCode &&
                                    isArrayWithContent(values?.users)
                                ) {
                                    setResetUsersWarning(mappedCountries[idx]);
                                    setEditUserIndex(undefined);
                                    return;
                                }

                                setFieldValue("countries", [
                                    mappedCountries[idx],
                                ]);
                            }}
                            error={!!errors.countries && !!touched.countries}
                            menuItemId="isoCode"
                            menuItemLabel="name"
                            list={mappedCountries}
                            loading={countryList?.loading}
                            value={values?.countries[0] || []}
                            disabled={disabled}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <InputLabel
                            error={
                                !!errors.therapeuticAreaIds &&
                                !!touched.therapeuticAreaIds
                            }
                            shrink
                        >
                            {"Select one or more therapeutic areas "}
                        </InputLabel>

                        <MultiSelect
                            id={`${id}-select-therapeutic-area`}
                            name="therapeuticAreaIds"
                            value={values?.therapeuticAreaIds || []}
                            onChange={onSelectTherapeuticArea}
                            multipleSelectKey="therapeuticAreaId"
                            menuItemLabel={"therapeuticAreaName"}
                            error={
                                !!errors.therapeuticAreaIds &&
                                !!touched.therapeuticAreaIds
                            }
                            list={therapeuticAreaList?.data}
                            loading={therapeuticAreaList?.loading}
                            disabled={disabled}
                        />
                    </Grid>
                </Grid>
                <Grid
                    item
                    container
                    rowSpacing={3}
                    columnSpacing={{ xs: 0, md: 4 }}
                >
                    <Grid item xs={12} md={6}>
                        <InputLabel
                            shrink
                            error={
                                !!errors.minimumContractReviewers &&
                                !!touched.minimumContractReviewers
                            }
                        >
                            {"Minimum number of contract reviewers (*)"}
                        </InputLabel>

                        <TextField
                            id={`${id}-minimum-contract-reviewers`}
                            name="minimumContractReviewers"
                            autoComplete="off"
                            value={values?.minimumContractReviewers}
                            onChange={e =>
                                onNumberChange(
                                    e.target.value,
                                    "minimumContractReviewers",
                                )
                            }
                            variant="outlined"
                            size="small"
                            sx={{ width: "4.8em" }}
                            error={
                                !!errors.minimumContractReviewers &&
                                !!touched.minimumContractReviewers
                            }
                            disabled={disabled}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <InputLabel
                            shrink
                            error={
                                !!errors.minimumFinancialApprovers &&
                                !!touched.minimumFinancialApprovers
                            }
                        >
                            {"Minimum number of financial approvers (*)"}
                        </InputLabel>

                        <TextField
                            id={`${id}-minimum-financial-approvers`}
                            name="minimumFinancialApprovers"
                            autoComplete="off"
                            value={values?.minimumFinancialApprovers}
                            onChange={e =>
                                onNumberChange(
                                    e.target.value,
                                    "minimumFinancialApprovers",
                                )
                            }
                            variant="outlined"
                            size="small"
                            sx={{ width: "4.8em" }}
                            error={
                                !!errors.minimumFinancialApprovers &&
                                !!touched.minimumFinancialApprovers
                            }
                            disabled={disabled}
                        />
                    </Grid>
                </Grid>

                <Grid item xs={12} mt={7}>
                    <Box display="flex" alignItems="center">
                        <Typography variant="h3">
                            Add users to this contract team
                        </Typography>
                        <Typography ml={2} variant="caption1">
                            This team contains {values?.users?.length} user
                            {values?.users?.length > 1 ? "s" : ""}.
                        </Typography>
                    </Box>

                    {values?.users?.map((user, userIndex) => (
                        <Box key={userIndex} mt={4}>
                            <AddUser
                                id={`${id}-${userIndex}-add-user`}
                                disabled={
                                    disabled ||
                                    (editUserIndex !== undefined &&
                                        editUserIndex !== userIndex)
                                }
                                rights={rights}
                                userInfo={user}
                                deleteUser={() => deleteUsers(userIndex)}
                                onSave={value => {
                                    addUser(userIndex, value);
                                }}
                                editUserIndex={editUserIndex}
                                setEditUserIndex={setEditUserIndex}
                                userIndex={userIndex}
                                roles={roles}
                            />
                        </Box>
                    ))}

                    <Box mt={4}>
                        <GrayBox
                            padding={2}
                            id={`${id}-add-user`}
                            header={
                                <Button
                                    id={`${id}-add-user-btn`}
                                    variant="text"
                                    startIcon={<AddIcon />}
                                    color="primary"
                                    onClick={() => addUser()}
                                    disabled={
                                        disabled || editUserIndex !== undefined
                                    }
                                >
                                    Add user
                                </Button>
                            }
                        />
                    </Box>
                </Grid>
            </Grid>
        </form>
    );
};
export default React.memo(CreateTeamForm);
