import axios, { AxiosRequestConfig } from "axios";
import { PublicClientApplication, Configuration } from "@azure/msal-browser";

const API_BASE = "/";
const UNAUTHORIZED = 401;
const FORBIDDEN = 403;

const msalConfig: Configuration = {
    auth: {
        clientId: process.env.GATSBY_CLIENT_ID || "",
        authority: `https://login.microsoftonline.com/${process.env.GATSBY_TENANT_ID}`,
        redirectUri: "/",
        postLogoutRedirectUri: "/logout",
    },
    system: {
        allowNativeBroker: false, // Disables WAM Broker
    },
};

export const msalInstance = new PublicClientApplication(msalConfig);
export let account = msalInstance.getAllAccounts()[0];

/**
 * Create an axios instance
 */
const create = () => {
    let token = "";
    let refreshAttempts = 0;
    let isRefreshing = false;
    const refreshAndRetryQueue: {
        resolve: (value?: any) => void;
        reject: (error?: any) => void;
        config: AxiosRequestConfig;
    }[] = [];

    const instance = axios.create({
        baseURL: API_BASE,
        headers: {
            "Content-Type": "application/json",
        },
        validateStatus: (status: number) => status >= 200 && status < 400,
    });

    const acquireToken = async (initial = false) => {
        const redirectPromise = await msalInstance.handleRedirectPromise();
        if (redirectPromise) {
            if (redirectPromise.account) {
                account = redirectPromise.account;
            }
            return redirectPromise.idToken;
        }

        // on initial call we can get the token that is in session storage
        // on refresh we need to get a new id token which acquireTokenSilent does not do (only access token)
        const acquiredToken = initial
            ? await msalInstance.acquireTokenSilent({
                  scopes: ["openid", "profile", "user.read"],
                  account,
              })
            : await msalInstance.ssoSilent({
                  scopes: ["openid", "profile", "user.read"],
                  account,
              });
        return acquiredToken.idToken;
    };

    instance.interceptors.request.use(async config => {
        if (!token.length) {
            token = await acquireToken(true);
        }

        config.headers["Authorization"] = `Bearer ${token}`;
        return config;
    });

    instance.interceptors.response.use(
        response => {
            if (refreshAttempts > 0) {
                refreshAttempts = 0;
            }
            return response;
        },
        async error => {
            const originalRequest = error.config;

            if (!originalRequest) {
                throw error;
            }

            if (
                [UNAUTHORIZED, FORBIDDEN].includes(error.response?.status) &&
                refreshAttempts < 2
            ) {
                if (!isRefreshing) {
                    isRefreshing = true;
                    try {
                        // Refresh the access token
                        token = await acquireToken();

                        // Update the request headers with the new access token
                        originalRequest.headers["Authorization"] =
                            `Bearer ${token}`;

                        // Retry all requests in the queue with the new token
                        refreshAndRetryQueue.forEach(
                            ({ config, resolve, reject }) => {
                                instance
                                    .request(config)
                                    .then(resolve)
                                    .catch(reject);
                            },
                        );

                        // Clear the queue
                        refreshAndRetryQueue.length = 0;

                        // Retry the original request
                        return instance(originalRequest);
                    } catch {
                        msalInstance.logoutRedirect({ account });
                    } finally {
                        isRefreshing = false;
                        refreshAttempts++;
                    }
                }

                // Add the original request to the queue
                return new Promise<void>((resolve, reject) => {
                    refreshAndRetryQueue.push({
                        config: originalRequest,
                        resolve,
                        reject,
                    });
                });
            }
            throw error;
        },
    );

    return instance;
};

const client = create();
export default client;
