//Lib
import {
    Box,
    Typography,
    FormControl,
    FormControlLabel,
    RadioGroup,
    Radio,
    InputLabel,
    TextField,
} from "@mui/material";

import React, { Fragment } from "react";

// Own components
import { Dialog } from "@components";

// Styles
import * as style from "../style.module.scss";

/**
 * Props
 */
interface Props {
    openDialog: boolean;
    onConfirm: () => void;
    loading: boolean;
    onReset: () => void;
    contractRef: string;
    className?: string;
    values: {
        approved: string;
        rejectionReason: string;
    };
    onBlur: (event: React.SyntheticEvent) => void;
    onChange: any;
    errors: any;
    touched: any;
    id: string;
}

/**
 * Claim approval task
 */
const PriceCorrectionApprovalTask = ({
    id,
    openDialog,
    loading,
    onReset,
    className,
    values,
    onBlur,
    onChange,
    errors,
    touched,
    onConfirm,
}: Props) => {
    return (
        <Fragment>
            <Dialog
                id={`${id}-tasks-task-review`}
                title={"Complete claim task"}
                open={openDialog}
                message={
                    values?.approved === "approved" ? (
                        <Typography variant="subtitle2" color="black">
                            You are about to mark this claim task complete.
                            Doing so will approve the claim which can lead to a
                            financial transaction. This action cannot be undone.
                        </Typography>
                    ) : values?.approved === "rejected" ? (
                        <Typography variant="subtitle2" color="black">
                            You are about to mark this claim task as rejected.
                            Doing so will set the claim to rejected without
                            financial impact and the claim will need to be
                            re-made after corrections. This action cannot be
                            undone.
                        </Typography>
                    ) : (
                        <Typography variant="subtitle2" color="black">
                            You are about to reset the price correction
                            calculation for this claim. Doing so will enable the
                            recalculation of the price correction when new Lauer
                            prices are uploaded that impact this claim.
                        </Typography>
                    )
                }
                primaryButton={{
                    text: "Confirm",
                    action: onConfirm,
                    loading: loading,
                }}
                secondaryButton={{
                    text: "Cancel",
                    action: onReset,
                }}
            />

            <Typography variant="h2" className={style.header}>
                {`Financial approval`}
            </Typography>

            <Box mb={3} className={className}>
                <form noValidate onBlur={onBlur}>
                    <FormControl>
                        <RadioGroup
                            id={`${id}-task-details-task-review`}
                            value={values?.approved}
                            onChange={onChange}
                            name="approved"
                        >
                            <FormControlLabel
                                value={"approved"}
                                id={`${id}-task-details-review-contract-approve`}
                                control={<Radio size="small" />}
                                label={
                                    <Typography
                                        variant="body2"
                                        color="dark.light"
                                    >
                                        I approve this decision
                                    </Typography>
                                }
                            />

                            <FormControlLabel
                                id={`${id}-task-details-review-contract-reject`}
                                value={"rejected"}
                                control={<Radio size="small" />}
                                label={
                                    <Typography
                                        variant="body2"
                                        color="dark.light"
                                    >
                                        I reject this decision
                                    </Typography>
                                }
                            />

                            <FormControlLabel
                                id={`${id}-task-details-review-contract-reject`}
                                value={"reset"}
                                control={<Radio size="small" />}
                                label={
                                    <Typography
                                        variant="body2"
                                        color="dark.light"
                                    >
                                        I want to reset the price correction
                                        calculation for this claim
                                    </Typography>
                                }
                            />
                        </RadioGroup>
                    </FormControl>

                    {values?.approved === "rejected" && (
                        <Box mt={2}>
                            <InputLabel
                                error={
                                    !!errors?.rejectionReason &&
                                    !!touched?.rejectionReason
                                }
                                shrink
                            >
                                {"Reason of rejection (*)"}
                            </InputLabel>
                            <TextField
                                id={`${id}-task-details-task-contract-review-rejection-reason-input`}
                                fullWidth
                                name="rejectionReason"
                                value={values.rejectionReason}
                                variant="outlined"
                                autoComplete="off"
                                size="small"
                                onChange={onChange}
                                error={
                                    !!errors?.rejectionReason &&
                                    !!touched?.rejectionReason
                                }
                            />
                        </Box>
                    )}
                </form>
            </Box>
        </Fragment>
    );
};

export default React.memo(PriceCorrectionApprovalTask);
