import { Grid, Typography } from "@mui/material";
import React, { useState } from "react";

// Own components
import { MaintenanceList, Dialog } from "@components";
//hooks
import { useMaintenance } from "@hooks";
import { isSuccessfulCall } from "@utils";

/**
 * Props type
 */
interface Props {
    id?: string;
}

/**
 * Maintenance Product
 */
const MaintenanceProduct = ({ id }: Props) => {
    const [itemToDelete, setItemToDelete] = useState<
        | {
              type: string;
              id: string;
              savedLabel: string;
          }
        | undefined
    >();
    /**
     * API
     */

    //Indications
    const {
        list: indications,
        error: indicationsError,
        loading: {
            listLoading: indicationsLoading,
            creating: indicationCreating,
            deleting: indicationDeleting,
        },
        delete: indicationDelete,
        upsert: indicationUpsert,
    } = useMaintenance("indication", "indication|indications");

    //Brands
    const {
        list: brands,
        error: brandError,
        loading: {
            listLoading: brandLoading,
            creating: brandCreating,
            deleting: brandDeleting,
        },
        delete: brandDelete,
        upsert: brandUpsert,
    } = useMaintenance("brand", "brand|brands");

    //Therapeutic areas
    const {
        list: therapeuticAreas,
        error: therapeuticError,
        loading: {
            listLoading: therapeuticLoading,
            creating: therapeuticCreating,
            deleting: therapeuticDeleting,
        },
        delete: therapeuticDelete,
        upsert: therapeuticUpsert,
    } = useMaintenance("therapeuticArea", "therapeutic area|therapeutic areas");

    /**
     * Delete item
     */
    const onDelete = () => {
        if (!itemToDelete || !itemToDelete?.type) return;

        if (itemToDelete.type === "therapeuticArea") {
            therapeuticDelete(itemToDelete.id, itemToDelete.savedLabel).then(
                res => {
                    if (isSuccessfulCall(res?.status)) {
                        setItemToDelete(undefined);
                    }
                },
            );
        }

        if (itemToDelete.type === "brand") {
            brandDelete(itemToDelete.id, itemToDelete.savedLabel).then(res => {
                if (isSuccessfulCall(res?.status)) {
                    setItemToDelete(undefined);
                }
            });
        }

        if (itemToDelete.type === "indication") {
            indicationDelete(itemToDelete.id, itemToDelete.savedLabel).then(
                res => {
                    if (isSuccessfulCall(res?.status)) {
                        setItemToDelete(undefined);
                    }
                },
            );
        }
    };

    /**
     * Render
     */
    return (
        <Grid container columnSpacing={10}>
            <Dialog
                id={`${id}-delete-confirmation-dialog`}
                title={`Delete ${
                    itemToDelete?.type === "brand"
                        ? "brand"
                        : itemToDelete?.type === "indication"
                          ? "indication"
                          : "therapeutic area"
                }`}
                open={!!itemToDelete?.id}
                message={
                    <Typography
                        variant="subtitle2"
                        color="black"
                        component="span"
                    >
                        Are you sure you would like to delete{" "}
                        <Typography
                            variant="body2"
                            component="span"
                        >{`(${itemToDelete?.savedLabel})? `}</Typography>
                    </Typography>
                }
                primaryButton={{
                    text: "Confirm",
                    action: onDelete,
                    loading:
                        indicationDeleting ||
                        brandDeleting ||
                        therapeuticDeleting,
                }}
                secondaryButton={{
                    text: "Cancel",
                    action: () => setItemToDelete(undefined),
                }}
            />

            <MaintenanceList
                title={"Therapeutic area"}
                subtitle="therapeutic areas"
                label="New therapeutic area (*)"
                id={`${id}-add-therapeutic-area`}
                list={therapeuticAreas.maintenanceTherapeuticAreaList}
                loading={{
                    fetching: therapeuticLoading,
                    creating: therapeuticCreating,
                    deleting: therapeuticDeleting,
                }}
                valueLabel="therapeuticAreaName"
                valueId="therapeuticAreaId"
                error={therapeuticError}
                addListItem={(value, savedLabel) =>
                    therapeuticUpsert(value, savedLabel)
                }
                deleteListItem={
                    (id, savedLabel) =>
                        setItemToDelete({
                            id,
                            savedLabel,
                            type: "therapeuticArea",
                        })
                    // therapeuticDelete(id, savedLabel)
                }
            />

            <MaintenanceList
                label="New brand (*)"
                id={`${id}-add-brand`}
                list={brands.maintenanceBrandList}
                loading={{
                    fetching: brandLoading,
                    creating: brandCreating,
                    deleting: brandDeleting,
                }}
                error={brandError}
                valueLabel="brandName"
                valueId="brandId"
                subtitle="brands"
                title={"Brand"}
                addListItem={(value, savedLabel) =>
                    brandUpsert(value, savedLabel)
                }
                deleteListItem={(id, savedLabel) =>
                    setItemToDelete({ id, savedLabel, type: "brand" })
                }
            />

            <MaintenanceList
                title={"Indication"}
                subtitle="indications"
                list={indications.maintenanceIndicationList}
                valueLabel="indicationName"
                valueId="indicationId"
                loading={{
                    fetching: indicationsLoading,
                    creating: indicationCreating,
                    deleting: indicationDeleting,
                }}
                label="New indication (*)"
                id={`${id}-add-indication`}
                error={indicationsError}
                addListItem={(value, savedLabel) =>
                    indicationUpsert(value, savedLabel)
                }
                deleteListItem={(id, savedLabel) => {
                    setItemToDelete({
                        id,
                        savedLabel,
                        type: "indication",
                    });
                }}
            />
        </Grid>
    );
};
export default MaintenanceProduct;
