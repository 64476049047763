import React from "react";

export const blockInvalidNumber = (
    e: React.KeyboardEvent,
    noFloat?: boolean,
) => {
    if (noFloat) {
        ["e", "E", "+", "-", ".", ","].includes(e.key) && e.preventDefault();
    } else ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();
};

export const thousandsSeparator = (
    number: string | number | null | undefined,
) => {
    if (
        number === undefined ||
        number === null ||
        (typeof number !== "number" && typeof number !== "string")
    )
        return;
    const parsedNumber = typeof number == "number" ? number.toString() : number;
    const [wholePart, decimalPart = ""] = parsedNumber.split(".");
    const reversedWholePart = wholePart.split("").reverse().join("");
    let formattedWholePart = "";
    for (let i = 0; i < reversedWholePart.length; i++) {
        if (i > 0 && i % 3 === 0) {
            formattedWholePart = "." + formattedWholePart;
        }
        formattedWholePart = reversedWholePart[i] + formattedWholePart;
    }
    const formattedNumber =
        decimalPart.length > 0
            ? `${formattedWholePart},${decimalPart}`
            : formattedWholePart;
    return formattedNumber;
};
