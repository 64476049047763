// Lib
import React, { useState, useImperativeHandle, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { Dropzone } from "@components";

import { uploadDocumentStepsState } from "@atoms";

// Hooks
import { useUpdateStep } from "@hooks";

// Types
import type { Location } from "@types";

/**
 * Props type
 */
interface Props {
    contract?: any;
    location?: Location;
    id?: string;
}

/**
 * Upload file
 */
const UploadFile = React.forwardRef(({ location }: Props, ref) => {
    /**
     * Upload dmn state
     */
    const [uploadFile, setFileToUpload] = useState(undefined);

    // Update step hook
    const updateStepValidation = useUpdateStep(
        location,
        uploadDocumentStepsState,
    );

    /**
     * Save and go to the next page
     */
    useImperativeHandle(ref, () => ({
        onSubmit() {
            return uploadFile;
        },
        onReset() {
            setFileToUpload(undefined);
        },
    }));

    /**
     * check step validation
     */
    useEffect(() => {
        updateStepValidation(!!uploadFile);
    }, [uploadFile]);

    /**
     * Render
     */
    return (
        <Box>
            <Box mb={5}>
                <Typography variant="h2">Upload new file</Typography>
            </Box>

            <Dropzone
                disabled={!!uploadFile}
                variant="big"
                id={"upload-contract-document"}
                fileTypes={{
                    "application/pdf": [".pdf"],
                    "image/png": [".png"],
                    "image/jpeg": [".jpeg", ".jpg"],
                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                        [".docx"],
                    "application/msword": [".doc"],
                    "application/vnd.ms-excel": [".xls"],
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                        [".xlsx"],
                    "application/vnd.ms-outlook": [".msg"],
                }}
                files={[uploadFile]}
                onUpload={uploadFiles => {
                    setFileToUpload(uploadFiles);
                }}
                onAttachmentRemove={() => setFileToUpload(undefined)}
            />
        </Box>
    );
});

export default React.memo(UploadFile);
