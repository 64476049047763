// Libs
import { Box, TableCell, TableRow, Typography, Tooltip } from "@mui/material";
import React from "react";

// Own components
import { ActionMenu } from "@components";

// Type
import { Account, AccountStatus, AccountType, BasicRegionType } from "@types";
import { constantMapper, truncateString, splitStringWithDash } from "@utils";

interface Row {
    accountId: string;
    accountName: string;
    homeCountry: { isoCode: string; name: string };
    country: { isoCode: string; name: string };
    sapAccountCode: string;
    knowyoursupplierid: string;
    accountType: AccountType;
    accountCity: string;
    accountAddress: string;
    accountPostalCode: string;
    bankType: string;
    accountStatus: AccountStatus;
    codsId?: string;
    trustAccountCode?: Account;
    cmuRegionCode?: BasicRegionType;
    regionCode?: BasicRegionType;
    regionCode1?: BasicRegionType;
    regionCode2?: BasicRegionType;
}

/**
 * Props type
 */
interface Props {
    row: Row;
    rowIndex: number;
    callbacks: any;
    permissions: any;
    id: string;
    isColVisible: (col: string) => boolean;
}

/**
 * Partners Row
 */
const PartnersRow = ({
    id,
    row,
    rowIndex,
    callbacks,
    isColVisible,
    permissions,
}: Props) => {
    return (
        <TableRow
            id={`${id}-row-${rowIndex}`}
            sx={{
                "&:last-child td, &:last-child th": {
                    border: 0,
                },
            }}
        >
            {isColVisible("name") && (
                <TableCell>
                    <Typography variant="caption2">
                        {row.accountName}
                    </Typography>
                </TableCell>
            )}
            {isColVisible("type") && (
                <TableCell>
                    <Typography variant="caption2">
                        {row.accountType?.accountTypeName}
                    </Typography>
                </TableCell>
            )}
            {isColVisible("address") && (
                <TableCell>
                    <Box display="flex" flexDirection="column">
                        <Typography variant="caption2">{`${row.accountAddress}, ${row.accountPostalCode} ${row.accountCity}`}</Typography>
                        <Typography variant="caption2">
                            {row.homeCountry?.name}
                        </Typography>
                    </Box>
                </TableCell>
            )}
            {isColVisible("sapAccountCode") && (
                <TableCell>
                    <Typography variant="caption2">
                        {row.sapAccountCode || "-"}
                    </Typography>
                </TableCell>
            )}
            {isColVisible("knowyoursupplierid") && (
                <TableCell>
                    <Typography variant="caption2">
                        {row.knowyoursupplierid || "-"}
                    </Typography>
                </TableCell>
            )}
            {isColVisible("bankType") && (
                <TableCell>
                    <Typography variant="caption2">
                        {row.bankType || "-"}
                    </Typography>
                </TableCell>
            )}
            {isColVisible("countryOfSale") && (
                <TableCell>
                    <Typography variant="caption2">
                        {row.country?.name}
                    </Typography>
                </TableCell>
            )}
            {isColVisible("codsId") && (
                <TableCell>
                    <Typography variant="caption2">
                        {row.codsId || "-"}
                    </Typography>
                </TableCell>
            )}
            {isColVisible("trustAccountCode") && (
                <TableCell>
                    <Typography variant="caption2">
                        {row.trustAccountCode?.accountName || "-"}
                    </Typography>
                </TableCell>
            )}
            {isColVisible("regionCode") && (
                <TableCell>
                    {row.regionCode?.regionId ? (
                        <Tooltip
                            arrow
                            placement="top"
                            title={splitStringWithDash(row.regionCode, [
                                "regionName",
                                "regionCode",
                                "type",
                            ])}
                        >
                            <Typography variant="caption2">
                                {truncateString(
                                    splitStringWithDash(row.regionCode, [
                                        "regionName",
                                        "regionCode",
                                        "type",
                                    ]),
                                )}
                            </Typography>
                        </Tooltip>
                    ) : (
                        "-"
                    )}
                </TableCell>
            )}

            {isColVisible("cmuRegionCode") && (
                <TableCell>
                    {row.cmuRegionCode?.regionId ? (
                        <Tooltip
                            arrow
                            placement="top"
                            title={splitStringWithDash(row.cmuRegionCode, [
                                "regionName",
                                "regionCode",
                                "type",
                            ])}
                        >
                            <Typography variant="caption2">
                                {truncateString(
                                    splitStringWithDash(row.cmuRegionCode, [
                                        "regionName",
                                        "regionCode",
                                        "type",
                                    ]),
                                )}
                            </Typography>
                        </Tooltip>
                    ) : (
                        "-"
                    )}
                </TableCell>
            )}

            {isColVisible("regionCode1") && (
                <TableCell>
                    {row.regionCode1?.regionId ? (
                        <Tooltip
                            arrow
                            placement="top"
                            title={splitStringWithDash(row.regionCode1, [
                                "regionName",
                                "regionCode",
                                "type",
                            ])}
                        >
                            <Typography variant="caption2">
                                {truncateString(
                                    splitStringWithDash(row.regionCode1, [
                                        "regionName",
                                        "regionCode",
                                        "type",
                                    ]),
                                )}
                            </Typography>
                        </Tooltip>
                    ) : (
                        "-"
                    )}
                </TableCell>
            )}
            {isColVisible("regionCode2") && (
                <TableCell>
                    {row.regionCode2?.regionId ? (
                        <Tooltip
                            arrow
                            placement="top"
                            title={splitStringWithDash(row.regionCode2, [
                                "regionName",
                                "regionCode",
                                "type",
                            ])}
                        >
                            <Typography variant="caption2">
                                {truncateString(
                                    splitStringWithDash(row.regionCode2, [
                                        "regionName",
                                        "regionCode",
                                        "type",
                                    ]),
                                )}
                            </Typography>
                        </Tooltip>
                    ) : (
                        "-"
                    )}
                </TableCell>
            )}
            {isColVisible("status") && (
                <TableCell>
                    <Typography variant="caption2">
                        {constantMapper(row?.accountStatus)}
                    </Typography>
                </TableCell>
            )}

            <TableCell align="right">
                {permissions && permissions.hasPermissionToEditDataTables && (
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-end"
                    >
                        <ActionMenu
                            id={`${id}-row-${rowIndex}-partner`}
                            options={[
                                {
                                    label: "Edit partner",
                                    action: () =>
                                        callbacks &&
                                        callbacks.onEditPartner(row, rowIndex),
                                    disabled:
                                        row.accountStatus ===
                                        AccountStatus.PENDING,
                                },
                                {
                                    label: "External codes",
                                    disabled: !callbacks?.onExternalCodesEdit,
                                    action: () =>
                                        callbacks &&
                                        callbacks.onExternalCodesEdit(
                                            row,
                                            rowIndex,
                                        ),
                                },
                            ]}
                            rowIndex={rowIndex}
                        />
                    </Box>
                )}
            </TableCell>
        </TableRow>
    );
};

export default PartnersRow;
