import { useMemo } from "react";
import type { FlowStep } from "@types";
import { isArrayWithContent, isObjectWithContent } from "@utils";

export const useFormValidation = (
    errors: any,
    touched: any,
    currentStep?: FlowStep,
    exceptions?: Array<string>,
) => {
    const errorHasContent = Array.isArray(errors)
        ? isArrayWithContent(errors)
        : isObjectWithContent(errors);

    /**
     * Form validity
     */
    const isValidForm = useMemo(
        () =>
            (currentStep?.isPrepared && !errorHasContent) ||
            (!errorHasContent && isObjectWithContent(touched)),
        [errors, errorHasContent, touched],
    );

    /**
     * Form errors
     */

    const hasErrors = useMemo(() => {
        return (
            (!!isObjectWithContent(errors) || !!isArrayWithContent(errors)) &&
            !!isObjectWithContent(touched) &&
            Object.keys(touched).some(field => {
                if (
                    !!exceptions &&
                    isArrayWithContent(exceptions) &&
                    exceptions.includes(field)
                )
                    return;

                const [key, idx] = field.split(".");
                return isArrayWithContent(errors[key]) && !!idx
                    ? parseInt(idx) && errors[idx][key]
                    : errors[key];
            })
        );
    }, [errors, touched]);

    return { isValidForm, hasErrors };
};
