// import libs
import GridOn from "@mui/icons-material/GridOn";
import { navigate } from "gatsby";
import clonedeep from "lodash.clonedeep";
import React, {
    Fragment,
    useEffect,
    useMemo,
    useRef,
    useState,
    useLayoutEffect,
} from "react";
import { useRecoilState, useRecoilValue, useResetRecoilState } from "recoil";

// Containers
import { ContractOverview } from "@containers";

// import own component
import {
    Breadcrumbs,
    StepActions,
    Stepper,
    FlowLayout,
    FlowAside,
    FlowBody,
    FlowContent,
    FlowFooter,
} from "@components";

// Custom Hooks
import { useCreateContract, useFindStep, useGetContractModel } from "@hooks";

// Atoms
import {
    createPerformanceBasedContractStepsState,
    createVolumeBasedContractStepsState,
    selectModelState,
} from "@atoms";

// Selectors
import { createContractMapper } from "@selectors";

// Import types
import type { Location } from "@types";

// Constants
import { CONTRACT_TYPES } from "@constants";
import { isSuccessfulCall, isArrayWithContent } from "@utils";

/**
 * Props type
 */
interface Props {
    children: any;
    location: Location;
    contractType: string;
}

/**
 * Create contract layout
 */
const CreateContractLayout = ({ location, children, contractType }: Props) => {
    /**
     * Check if the contract is performance based
     */
    const flowState = useMemo(() => {
        return contractType === CONTRACT_TYPES.PERFORMANCE
            ? createPerformanceBasedContractStepsState
            : createVolumeBasedContractStepsState;
    }, [contractType]);

    /**
     * Find path id
     */
    const contractFlowPath = useMemo(() => {
        if (!contractType) return;

        return contractType === CONTRACT_TYPES.PERFORMANCE
            ? "performance-based"
            : "volume-based";
    }, [contractType]);

    /**
     * States
     */
    const [contractIds, storeNewContractIds] = useState<{
        referenceId: string | undefined;
        contractId: string | undefined;
    }>({ referenceId: undefined, contractId: undefined });

    // Model state
    const [selectedModel, updateModel] = useRecoilState(selectModelState);

    // via this ref we can save the state of each page via stepper (higher order)
    const stepRef = useRef<any>();

    //API
    const { loading: createContractLoading, createContract } =
        useCreateContract();

    const mappedData = useRecoilValue(createContractMapper);

    /**
     * Get data model list
     */
    const { loading: fetchingDatamodelList, load: loadModelList } =
        useGetContractModel("PERFORMANCE", false);

    //Steps hooks
    const { currentIndex: currentStepIndex, currentStep } = useFindStep(
        location,
        flowState,
    );
    const [steps, setSteps] = useRecoilState(flowState);

    // Reset all atoms
    const resetFlowState = useResetRecoilState(createContractMapper);

    /**
     * Reset stored reference id & atom
     */
    const resetAll = (showFirstStep?: boolean) => {
        resetFlowState();
        storeNewContractIds({ referenceId: undefined, contractId: undefined });

        showFirstStep &&
            navigate(`/create-contract/${contractFlowPath}/${steps[0].id}/`);
    };

    /**
     * Reset recoil state on refresh
     */
    useEffect(() => {
        if (currentStepIndex > 0 && !steps[currentStepIndex - 1].isCompleted) {
            resetAll();
            navigate(`/create-contract/${contractFlowPath}/${steps[0].id}/`);
        }
    }, [currentStepIndex, location]);

    /**
     * Fetch data model in general info if needed (currently only for Performance based contracts)
     */
    useLayoutEffect(() => {
        if (
            contractType !== "PERFORMANCE" ||
            (contractType === "PERFORMANCE" && !!selectedModel?.datamodelId)
        )
            return;
        loadModelList().then(response => {
            if (isArrayWithContent(response?.data?.records)) {
                updateModel(response?.data?.records[0]);
            }
        });
    }, [contractType]);

    /**
     * Check if the request can be submitted
     */
    const canSubmit = useMemo(() => {
        if (currentStepIndex !== steps.length - 1) return false; //is not submit & review page

        return !steps.find(
            (step, index) => index < currentStepIndex && !step.isCompleted,
        );
    }, [currentStepIndex, steps]);

    /**
     *  Reset on refresh the page
     */
    useEffect(() => {
        const handleUnload = (e: any) => {
            e.preventDefault();
            const dialogText =
                "You are about to leave the page. Your data will not be saved and thus will be lost."; // old browsers.
            e.returnValue = dialogText;
            return dialogText;
        };
        if (typeof window !== "undefined") {
            window.addEventListener("beforeunload", handleUnload);
            return function cleanup() {
                window.removeEventListener("beforeunload", handleUnload);
            };
        }
    }, []);

    /**
     * Check if the the contract was successfully submitted
     */
    const isFinished = useMemo(() => {
        return !!contractIds?.referenceId;
    }, [contractIds]);

    /**
     * Reset and navigate to overview
     */
    const goToOverview = () => {
        resetAll();
        navigate("/dashboard/contracts/");
    };

    /**
     * Save state and update steps
     */
    const saveCurrentState = () => {
        if (stepRef?.current?.updateState) {
            stepRef?.current?.updateState();
        }
        const copySteps = clonedeep(steps);
        copySteps[currentStepIndex].isCompleted = true;
        setSteps(copySteps);
    };

    /**
     * Next Click handler
     */
    const onNextClick = () => {
        // reset and navigate to dashboard
        if (isFinished) {
            goToOverview();
            return;
        }

        if (!canSubmit && !isFinished && steps[currentStepIndex].isPrepared) {
            saveCurrentState();
            navigate(
                `/create-contract/${contractFlowPath}/${
                    steps[currentStepIndex + 1].id
                }/`,
            );
        }

        // Submit
        if (canSubmit) {
            createContract(mappedData).then(response => {
                if (!!response && isSuccessfulCall(response.status)) {
                    const copySteps = clonedeep(steps);
                    copySteps[currentStepIndex].isCompleted = true;
                    setSteps(copySteps);
                    storeNewContractIds({
                        referenceId: response?.data?.reference,
                        contractId: response?.data?.id,
                    });
                }
            });
        }
    };

    /**
     * Click back handler
     */
    const onBackClick = () => {
        navigate(
            `/create-contract/${contractFlowPath}/${
                steps[currentStepIndex - 1].id
            }/`,
        );
    };

    /**
     * Disable the rest of steps from the index
     * of a step if it has changed & being not prepared
     */
    const notPreparedIndex = useMemo(() => {
        const foundIndex = steps.findIndex(item => !item.isPrepared);
        return foundIndex;
    }, [steps]);

    /**
     * Render
     */
    return (
        <Fragment>
            <Breadcrumbs
                id={`create-contract-breadcrumb`}
                icon={<GridOn color="primary" />}
                title="Dashboard"
                location={location}
                resetAtoms={() => resetAll()}
            />

            <FlowLayout>
                <FlowAside>
                    <Stepper
                        id={`create-contract-stepper`}
                        steps={steps}
                        location={location}
                        title={`Create a new ${
                            contractType === CONTRACT_TYPES.PERFORMANCE
                                ? "performance based"
                                : "volume based"
                        } contract`}
                        notPreparedIndex={notPreparedIndex}
                        disabled={
                            isFinished ||
                            (currentStepIndex !== undefined &&
                                !steps[currentStepIndex]?.isPrepared)
                        }
                        onStepClick={path => {
                            // if not review page & is prepared => save the state & navigate
                            !canSubmit && saveCurrentState();
                            navigate(path);
                        }}
                    />
                </FlowAside>

                <FlowBody>
                    <Fragment>
                        <FlowContent>
                            {!!isFinished && !!contractIds?.referenceId && (
                                <ContractOverview
                                    id="create-contract"
                                    referenceId={contractIds?.referenceId}
                                />
                            )}

                            {!isFinished &&
                                React.Children.map(
                                    children as React.ReactElement,
                                    (child: React.ReactElement) =>
                                        React.cloneElement(child, {
                                            location,
                                            currentStep,
                                            ref: stepRef,
                                            resetFlow: resetAll,
                                            id: `create-contract`,
                                            contractFlowPath,
                                            contractType,
                                            flowState,
                                        }),
                                )}
                        </FlowContent>

                        <FlowFooter>
                            <StepActions
                                id={`create-contract`}
                                loading={createContractLoading}
                                primaryButton={{
                                    text: isFinished
                                        ? "Return to overview"
                                        : canSubmit
                                          ? "Submit for review"
                                          : "Next step",
                                    action: onNextClick,
                                    disabled:
                                        fetchingDatamodelList ||
                                        createContractLoading ||
                                        (canSubmit
                                            ? false
                                            : currentStepIndex !== undefined &&
                                              !steps[currentStepIndex]
                                                  ?.isPrepared),
                                    isSubmitButton: canSubmit,
                                }}
                                tertiaryButton={{
                                    text: "View contract",
                                    action: () => {
                                        resetFlowState();
                                        navigate(
                                            `/contract/view-contract/${contractIds.contractId}/`,
                                        );
                                    },
                                    hidden: !isFinished,
                                }}
                                secondaryButton={{
                                    text: "Back",
                                    action: onBackClick,
                                    hidden:
                                        isFinished ||
                                        (currentStepIndex !== undefined &&
                                            currentStepIndex === 0),
                                }}
                            />
                        </FlowFooter>
                    </Fragment>
                </FlowBody>
            </FlowLayout>
        </Fragment>
    );
};

export default CreateContractLayout;
