// Libs
import { Box, TableCell, TableRow, Typography } from "@mui/material";
import React from "react";

// Own components
import { ActionMenu } from "@components";

// Utils
import { displayDate, constantMapper } from "@utils";

interface Row {
    outcomeId: string;
    productName: string;
    therapeuticAreaName: string;
    accountName: string;
    milestoneNo: number | string;
    milestoneMet: boolean;
    billingBlock: string;
    sapOrderNo: string;
    scpRefNo: string;
    contractRef: string;
    salessystemOrderId: string;
    amount: number | string;
    percentage: number | string;
    country: string;
    createdAt: string;
    processedAt: string;
    endDate: string;
    infusionDate: string;
    sapSkuNo: string;
    status: string;
}

interface Props {
    row: Row;
    rowIndex: number;
    permissions: any;
    id: string;
    isColVisible: (col: string) => boolean;
    callbacks: any;
}

const InvoiceRelease = ({
    id,
    row,
    rowIndex,
    callbacks,
    permissions,
    isColVisible,
}: Props) => {
    return (
        <TableRow>
            {isColVisible("contractRef") && (
                <TableCell>
                    <Typography variant="caption2">
                        {row.contractRef}
                    </Typography>
                </TableCell>
            )}

            {isColVisible("accountName") && (
                <TableCell>
                    <Typography variant="caption2">
                        {row.accountName}
                    </Typography>
                </TableCell>
            )}

            {isColVisible("infusionDate") && (
                <TableCell>
                    <Typography variant="caption2">
                        {displayDate(row.infusionDate)}
                    </Typography>
                </TableCell>
            )}
            {isColVisible("salessystemOrderId") && (
                <TableCell>
                    <Typography variant="caption2">
                        {row.salessystemOrderId}
                    </Typography>
                </TableCell>
            )}

            {isColVisible("sapOrderNo") && (
                <TableCell>
                    <Typography variant="caption2">{row.sapOrderNo}</Typography>
                </TableCell>
            )}

            {isColVisible("scpRefNo") && (
                <TableCell>
                    <Typography variant="caption2">{row.scpRefNo}</Typography>
                </TableCell>
            )}

            {isColVisible("milestoneNo") && (
                <TableCell>
                    <Typography variant="caption2">
                        {row.milestoneNo}
                    </Typography>
                </TableCell>
            )}

            {isColVisible("milestoneMet") && (
                <TableCell>
                    {row?.milestoneMet && (
                        <Typography variant="caption2">
                            {row?.milestoneMet === true ? "Yes" : "No"}
                        </Typography>
                    )}
                </TableCell>
            )}

            {isColVisible("billingBlock") && (
                <TableCell>
                    <Typography variant="caption2">
                        {row.billingBlock}
                    </Typography>
                </TableCell>
            )}

            {isColVisible("processedAt") && (
                <TableCell>
                    <Typography variant="caption2">
                        {displayDate(row.processedAt)}
                    </Typography>
                </TableCell>
            )}
            {isColVisible("status") && (
                <TableCell>
                    <Typography variant="caption2">
                        {constantMapper(row.status)}
                    </Typography>
                </TableCell>
            )}

            <TableCell align="right">
                {permissions &&
                    (permissions.hasPermissionToReadFinancialActions ||
                        permissions?.hasPermissionToEditFinancialActions) && (
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="flex-end"
                        >
                            <ActionMenu
                                id={`${id}-${rowIndex}-invoice-release-row"`}
                                autoHide
                                autoWidth
                                options={[
                                    {
                                        action: () =>
                                            callbacks?.reprocessInvoice(
                                                row.outcomeId,
                                            ),
                                        label: "Reprocess invoice release",
                                        disabled:
                                            row?.status ===
                                                "PROCESSED_BY_SAP" ||
                                            row?.status === "PROCESSED" ||
                                            !permissions?.hasPermissionToEditFinancialActions,
                                    },
                                    {
                                        action: () =>
                                            callbacks?.viewOrderDetails(
                                                row.outcomeId,
                                            ),
                                        label: "View invoice release details",
                                        disabled:
                                            !permissions.hasPermissionToReadFinancialActions &&
                                            permissions?.hasPermissionToEditFinancialActions,
                                    },
                                ]}
                                rowIndex={rowIndex}
                            />
                        </Box>
                    )}
            </TableCell>
        </TableRow>
    );
};

export default InvoiceRelease;
