import { useAxios } from "./useAxios";
import type { Api } from "@types";
import { useEffect } from "react";
import { useTeamCountries } from "./useMisc";
import { isArrayWithContent, isSuccessfulCall } from "@utils";

const BASE_URI = `/v0`;

/**
 * Context
 */
export const useContext = (getList = true, contextType: string | undefined) => {
    const url = `${BASE_URI}/context`;

    const {
        response: list,
        loading: listLoading,
        fetch: loadList,
        error: listError,
        forceReset,
    }: Api = useAxios(
        {
            method: "GET",
        },
        {
            errorHandler: `Failed to fetch context list`,
        },
        true,
    );

    const { load: reloadCountryList } = useTeamCountries(false);

    /**
     * Load context on demand
     */
    const loadContextList = () => {
        return reloadCountryList().then(res => {
            if (
                isSuccessfulCall(res?.status) &&
                isArrayWithContent(res?.data?.records)
            ) {
                const isoCode = res?.data?.records[0]?.countryIsoCode;
                return loadList({
                    url: `${url}?countryIsoCode=${isoCode}&type=${contextType}`,
                });
            }
        });
    };

    useEffect(() => {
        if (!getList) return;
        let unmounted = false;
        if (!unmounted) {
            loadContextList();
        }
        return () => {
            unmounted = true;
        };
    }, []);

    return {
        list: list,
        loading: { listLoading },
        error: { listError },
        load: loadContextList,
        forceReset,
    };
};
