// extracted by mini-css-extract-plugin
export var disableField = "style-module--disableField--e541f";
export var error = "#ff3131";
export var filterList = "style-module--filterList--561fb";
export var firstCaption = "style-module--firstCaption--ed5da";
export var header = "style-module--header--82277";
export var lastCaption = "style-module--lastCaption--4085f";
export var primary = "#036";
export var roleSection = "style-module--roleSection--863e4";
export var secondary = "#019cdc";
export var subHeader = "style-module--subHeader--add20";
export var textfont = "\"Verdana Pro\"";
export var titlefont = "\"Karbon\"";
export var white = "#fff";
export var wrapper = "style-module--wrapper--6d502";