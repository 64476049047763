// Lib
import React, { Fragment, useEffect, useState } from "react";
import Popover from "@mui/material/Popover";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import * as style from "../style.module.scss";

interface Price {
    priceRangeStart: string;
    priceRangeEnd: string;
}

interface Props {
    handleFilterChange: (price: Price) => void;
    id: string;
    value: Price;
}

/**
 * Data Range
 */
const DataRange: React.FC<Props> = ({ handleFilterChange, id, value }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [priceRange, setPriceRange] = useState({
        priceRangeStart: "",
        priceRangeEnd: "",
    });

    useEffect(() => {
        if (!value.priceRangeEnd && !value?.priceRangeStart) return;

        setPriceRange(value);
    }, [value]);

    const cancel = () => {
        setAnchorEl(null);
        setPriceRange({ priceRangeStart: "", priceRangeEnd: "" });
    };

    const setAnchor = ev => {
        if (ev.target.tagName === "BUTTON") return setAnchorEl(ev.target);
        let anchor = ev.target;
        while (anchor.tagName !== "BUTTON") {
            anchor = anchor.parentElement;
        }
        setAnchorEl(anchor);
    };

    const handleRangeChange = (key: string, value: string) => {
        const copyPriceRange = { ...priceRange };
        copyPriceRange[key] = value;
        setPriceRange(copyPriceRange);
    };

    const onPriceRangeFilter = () => {
        handleFilterChange(priceRange);
        setAnchorEl(null);
    };

    return (
        <Fragment>
            <Button
                id={`${id}-price-range-button`}
                color="primary"
                onClick={setAnchor}
                size={"small"}
                variant={"outlined"}
                className={style.spacer}
            >
                <Typography variant="button">Price</Typography>
            </Button>

            <Popover
                id={`fade-menu`}
                anchorEl={anchorEl}
                open={open}
                onClose={() => cancel()}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                }}
            >
                <Box p={1} display="flex" flexDirection="column">
                    <InputLabel shrink>{"Between"}</InputLabel>
                    <TextField
                        fullWidth
                        id={`${id}-between-input-textfield`}
                        name="priceRangeStart"
                        autoComplete="off"
                        variant="outlined"
                        value={priceRange.priceRangeStart}
                        onChange={ev =>
                            handleRangeChange(
                                "priceRangeStart",
                                ev.target.value,
                            )
                        }
                        size="small"
                    />
                    <Box my={2}>
                        <InputLabel shrink>{"And"}</InputLabel>

                        <TextField
                            fullWidth
                            id={`${id}-end-input-textfield`}
                            name="priceRangeEnd"
                            autoComplete="off"
                            variant="outlined"
                            value={priceRange.priceRangeEnd}
                            onChange={ev =>
                                handleRangeChange(
                                    "priceRangeEnd",
                                    ev.target.value,
                                )
                            }
                            size="small"
                        />
                    </Box>

                    <Button
                        id={`${id}-search-price-range-button`}
                        color="primary"
                        variant="contained"
                        onClick={onPriceRangeFilter}
                        size={"large"}
                        //className={style.spacer}
                    >
                        Apply
                    </Button>
                </Box>
            </Popover>
        </Fragment>
    );
};

export default React.memo(DataRange);
