import { useMemo } from "react";
import { useRecoilValue } from "recoil";
import { userState } from "@atoms";

import { usePermission } from "./usePermission";
// Types
import type { User, Permissions } from "@types";
import { isArrayWithContent } from "@utils";

export const useLandingPage = () => {
    const user: any = useRecoilValue<User | undefined>(userState);

    /**
     * Dashboard
     */

    const {
        // -- contracts
        hasPermissionToReadContract,
        hasPermissionToEditContract,
        hasPermissionToCreateContract,
        // -- Products & partners
        hasPermissionToEditDataTables,
        //-- Orders
        hasPermissionToEditOrder,
        hasPermissionToReadOrder,
        //-- Outcomes
        hasPermissionToEditOutcome,
        // -- Finance
        hasPermissionToReadFinancialActions,
        hasPermissionToEditFinancialActions,
        //-- Maintenance
        hasPermissionToEditMaintenance,
        //-- Tasks
        hasPermissionToReviewContract,
        hasPermissionToApproveFinancialAction,
        hasPermissionToEditCamundaConfiguration,
        //Claims
        hasPermissionToEditClaim,
        hasPermissionToReadClaim,
    }: Permissions = usePermission();

    const areContractsVisible = useMemo(() => {
        return (
            hasPermissionToReadContract ||
            hasPermissionToEditContract ||
            hasPermissionToCreateContract
        );
    }, [
        hasPermissionToReadContract,
        hasPermissionToEditContract,
        hasPermissionToCreateContract,
    ]);

    const areOrdersVisible = useMemo(() => {
        return hasPermissionToReadOrder;
    }, [hasPermissionToReadOrder]);

    const areOutcomesVisible = useMemo(() => {
        return hasPermissionToEditOutcome;
    }, [hasPermissionToEditOutcome]);

    const areFinancesVisible = useMemo(() => {
        return (
            hasPermissionToReadFinancialActions ||
            hasPermissionToEditFinancialActions
        );
    }, [
        hasPermissionToReadFinancialActions,
        hasPermissionToEditFinancialActions,
    ]);

    const areTasksVisible = useMemo(() => {
        return (
            hasPermissionToEditDataTables ||
            hasPermissionToReviewContract ||
            hasPermissionToApproveFinancialAction ||
            hasPermissionToEditCamundaConfiguration ||
            hasPermissionToEditOrder
        );
    }, [
        hasPermissionToReviewContract,
        hasPermissionToApproveFinancialAction,
        hasPermissionToEditCamundaConfiguration,
        hasPermissionToEditOrder,
        hasPermissionToEditDataTables,
    ]);

    const areClaimsVisible = useMemo(() => {
        return hasPermissionToReadClaim || hasPermissionToEditClaim;
    }, [hasPermissionToReadClaim, hasPermissionToEditClaim]);

    const isUserWithRights = useMemo(() => {
        if (!user) return false;
        return isArrayWithContent(user?.rights);
    }, [user]);

    return {
        areContractsVisible,
        areOrdersVisible,
        areOutcomesVisible,
        areFinancesVisible,
        hasPermissionToEditDataTables,
        areTasksVisible,
        isUserWithRights,
        hasPermissionToEditMaintenance,
        areClaimsVisible,
        hasPermissionToEditClaim,
    };
};
