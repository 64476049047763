// Libs
import { Box, TableCell, TableRow, Typography } from "@mui/material";
import React from "react";

// Own components
import { ActionMenu } from "@components";

// Utils
import { constantMapper } from "@utils";

interface Row {
    claimHeaderId: string;
    claimNo: string;
    accountName: string;
    contractRef: string;
    claimStatus: string;
    claimCustomerReference: string;
}

interface Props {
    row: Row;
    rowIndex: number;
    permissions: any;
    id: string;
    isColVisible: (col: string) => boolean;
    callbacks: any;
}

const CreditNotesClaimRow = ({
    id,
    row,
    rowIndex,
    permissions,
    isColVisible,
    callbacks,
}: Props) => {
    return (
        <TableRow
            id={`${id}-row-${rowIndex}`}
            sx={{
                "&:last-child td, &:last-child th": {
                    border: 0,
                },
            }}
        >
            {isColVisible("claimNo") && (
                <TableCell>
                    <Typography variant="caption2">{row.claimNo}</Typography>
                </TableCell>
            )}

            {isColVisible("accountName") && (
                <TableCell>
                    <Typography variant="caption2">
                        {row.accountName}
                    </Typography>
                </TableCell>
            )}

            {isColVisible("contractRef") && (
                <TableCell>
                    <Typography variant="caption2">
                        {row.contractRef}
                    </Typography>
                </TableCell>
            )}

            {isColVisible("claimCustomerReference") && (
                <TableCell>
                    <Typography variant="caption2">
                        {row.claimCustomerReference}
                    </Typography>
                </TableCell>
            )}

            {isColVisible("claimStatus") && (
                <TableCell>
                    <Typography variant="caption2">
                        {constantMapper(row.claimStatus)}
                    </Typography>
                </TableCell>
            )}

            <TableCell align="right">
                {permissions &&
                    (permissions.hasPermissionToReadFinancialActions ||
                        permissions?.hasPermissionToEditFinancialActions) && (
                        <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="flex-end"
                        >
                            <ActionMenu
                                id={`${id}-${rowIndex}-credit-note-row"`}
                                autoHide
                                autoWidth
                                options={[
                                    {
                                        action: () =>
                                            callbacks?.reprocessCreditNote(
                                                row.claimHeaderId,
                                            ),
                                        label: "Reprocess claim",
                                        disabled:
                                            row.claimStatus !== "OPEN" ||
                                            !permissions?.hasPermissionToEditFinancialActions,
                                    },
                                ]}
                                rowIndex={rowIndex}
                            />
                        </Box>
                    )}
            </TableCell>
        </TableRow>
    );
};

export default CreditNotesClaimRow;
