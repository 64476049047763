import { atom } from "recoil";

export const createOutcomeStepsState = atom({
    key: "createOutcomeStepsState",
    default: [
        {
            id: "select-contract",
            title: "Contract selection",
            description:
                "Lorem 1 ipsum dolor sit amet, consectetur adipiscing elit. Nunc sed risus ut elit nibh id. Nisl, praesent pulvinar.",
            isPrepared: false,
            isCompleted: false,
        },
        {
            id: "select-order",
            title: "Order selection",
            description:
                "Lorem 2 ipsum dolor sit amet, consectetur adipiscing elit. Nunc sed risus ut elit nibh id. Nisl, praesent pulvinar.",
            isPrepared: false,
            isCompleted: false,
        },
        {
            id: "outcome-details",
            title: "Outcome details",
            description:
                "Lorem 3 ipsum dolor sit amet, consectetur adipiscing elit. Nunc sed risus ut elit nibh id. Nisl, praesent pulvinar.",
            isPrepared: false,
            isCompleted: false,
        },
    ],
});
