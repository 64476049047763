import { Box, Typography, Grid } from "@mui/material";
import React, { Fragment, useState, useRef, useMemo, useCallback } from "react";
import { navigate } from "gatsby";

// Own components
import {
    SearchBar,
    Table,
    FieldRenderer,
    CancelWithReasons,
    Dialog,
} from "@components";

// Constants
import { filterByCountry, HEADERS, ROWRENDERERCONST } from "@constants";

// Hooks
import {
    useGetClaim,
    useGetClaimById,
    useViewingOptions,
    usePermission,
    useSelectedCountry,
} from "@hooks";

// Types
import { SearchFilters, Permissions } from "@types";

// Utils
import {
    apiResponseCounter,
    constructQueryString,
    displayDate,
    isSuccessfulCall,
} from "@utils";

const SEARCH_BY = {
    GB: ["supplier", "claim reference number", "filename"],
    DE: ["supplier", "filename"],
    DEFAULT: ["supplier", "filename"],
};

const searchByRenderer = (
    countryIsoCode: string,
    isPriceCorrection: boolean,
) => {
    const arr = [...(SEARCH_BY[countryIsoCode] || SEARCH_BY.DEFAULT)];
    const prefix = "Search by";

    if (isPriceCorrection) {
        return `${prefix} supplier or filename...`;
    }

    if (arr.length === 1) {
        return `${prefix} ${arr[0]}...`;
    } else {
        const lastItem = arr.pop();
        return `${prefix} ${arr.join(", ")} or ${lastItem}...`;
    }
};

const MemoizedTable = React.memo(
    ({
        isPriceCorrectionClaims,
        claimList,
        listLoading,
        countryIsoCode,
        viewingOptions,
        callbacks,
        hasPermissionToEditClaim,
    }: {
        isPriceCorrectionClaims: boolean;
        claimList: any;
        listLoading: boolean;
        countryIsoCode: string;
        viewingOptions: any;
        callbacks: any;
        hasPermissionToEditClaim: boolean;
    }) => (
        <Box mt={5}>
            <Box display="flex" alignItems="baseline" mb={4}>
                <Typography variant="h2">
                    {isPriceCorrectionClaims
                        ? "Price correction claims"
                        : "Claims"}
                </Typography>
                <Typography ml={1} variant="caption1">
                    {apiResponseCounter(claimList, listLoading, "claim|claims")}
                </Typography>
            </Box>
            <Table
                id="dashboard-claim-list"
                headers={
                    isPriceCorrectionClaims
                        ? HEADERS.PRICE_CORRECTION_CLAIMS
                        : filterByCountry(HEADERS.CLAIM, countryIsoCode)
                }
                rows={claimList?.data?.records}
                loading={listLoading}
                type={
                    isPriceCorrectionClaims
                        ? ROWRENDERERCONST.PRICE_CORRECTION_CLAIMS
                        : ROWRENDERERCONST.CLAIM
                }
                viewingOptions={viewingOptions}
                emptyMsg="No claims found!"
                callbacks={callbacks}
                permissions={{ hasPermissionToEditClaim }}
            />
        </Box>
    ),
);

// Custom hook for managing claim actions
type ClaimActionItem = {
    claimHeaderId: string;
    filename: string;
    accountName: string;
    dateFrom: string;
    dateTo: string;
};

const useClaimActions = () => {
    const [actions, setActions] = useState<{
        itemToCancel?: ClaimActionItem;
        itemToDelete?: ClaimActionItem;
        itemToApprove?: ClaimActionItem;
        itemToRecall?: ClaimActionItem;
    }>({
        itemToCancel: undefined,
        itemToDelete: undefined,
        itemToApprove: undefined,
        itemToRecall: undefined,
    });

    const setClaimAction = useCallback((actionType, item) => {
        setActions(prev => ({
            ...prev,
            [actionType]: item,
        }));
    }, []);

    return {
        actions,
        setClaimAction,
    };
};

interface MemoizedDialogProps {
    item: ClaimActionItem | undefined;
    onAction: () => void;
    loading: boolean;
    setItem: (item: any) => void;
    dialogId: string;
    message: React.ReactNode;
    primaryButtonText: string;
    secondaryButtonText: string;
}

const MemoizedDialog = React.memo(
    ({
        item,
        onAction,
        loading,
        setItem,
        dialogId,
        message,
        primaryButtonText,
        secondaryButtonText,
    }: MemoizedDialogProps) => (
        <Dialog
            open={!!item?.claimHeaderId}
            id={dialogId}
            message={message}
            primaryButton={{
                text: primaryButtonText,
                action: onAction,
                loading: loading,
            }}
            secondaryButton={{
                text: secondaryButtonText,
                action: () => setItem(undefined),
            }}
        />
    ),
);

const ClaimsOverview = () => {
    const searchRef = useRef<{ onSearchReset: () => void }>(null);
    const { actions, setClaimAction } = useClaimActions();

    const isPriceCorrectionClaims = useMemo(
        () => location.pathname.includes("/price-correction-claims"),
        [location],
    );

    const { viewingOptions, setViewingOptions } = useViewingOptions(
        isPriceCorrectionClaims
            ? ROWRENDERERCONST.PRICE_CORRECTION_CLAIMS
            : ROWRENDERERCONST.CLAIM,
    );

    const { hasPermissionToEditClaim }: Permissions = usePermission();
    const { countryIsoCode } = useSelectedCountry();

    const {
        list: claimList,
        loading: { recalling, approving, deleting, canceling, fetching },
        reload: getClaim,
        search,
        cancel: cancelClaim,
        delete: deleteClaim,
        approve: approveClaim,
        recall: recallClaim,
    } = useGetClaim(isPriceCorrectionClaims);

    const { download: downloadClaimData } = useGetClaimById();

    const handleActionComplete = useCallback(() => {
        searchRef.current?.onSearchReset();
        getClaim();
    }, [getClaim]);

    const onClaimCancel = useCallback(
        (reason: string) => {
            if (!reason || !actions.itemToCancel?.claimHeaderId) return;

            cancelClaim(
                actions.itemToCancel.claimHeaderId,
                actions.itemToCancel.filename,
                reason,
            ).then(res => {
                if (!!res && isSuccessfulCall(res?.status)) {
                    setClaimAction("itemToCancel", undefined);
                    handleActionComplete();
                }
            });
        },
        [
            actions.itemToCancel,
            cancelClaim,
            handleActionComplete,
            setClaimAction,
        ],
    );

    const onClaimDelete = useCallback(() => {
        if (!actions.itemToDelete?.claimHeaderId) return;

        deleteClaim(
            actions.itemToDelete.claimHeaderId,
            actions.itemToDelete.filename,
        ).then(res => {
            if (!!res && isSuccessfulCall(res?.status)) {
                setClaimAction("itemToDelete", undefined);
                handleActionComplete();
            }
        });
    }, [
        actions.itemToDelete,
        deleteClaim,
        handleActionComplete,
        setClaimAction,
    ]);

    const onClaimRecall = useCallback(() => {
        if (!actions.itemToRecall?.claimHeaderId) return;

        recallClaim(
            actions.itemToRecall.claimHeaderId,
            actions.itemToRecall.filename,
        ).then(res => {
            if (!!res && isSuccessfulCall(res?.status)) {
                setClaimAction("itemToRecall", undefined);
                handleActionComplete();
            }
        });
    }, [
        actions.itemToRecall,
        recallClaim,
        handleActionComplete,
        setClaimAction,
    ]);

    const onClaimApprove = useCallback(() => {
        if (!actions.itemToApprove?.claimHeaderId) return;

        approveClaim(
            actions.itemToApprove.claimHeaderId,
            actions.itemToApprove.filename,
        ).then(res => {
            if (!!res && isSuccessfulCall(res?.status)) {
                setClaimAction("itemToApprove", undefined);
                handleActionComplete();
            }
        });
    }, [
        actions.itemToApprove,
        approveClaim,
        handleActionComplete,
        setClaimAction,
    ]);

    const tableCallbacks = useMemo(
        () => ({
            onClaimDetailsClick: (claimHeaderId: string) =>
                navigate(`/claims/claim-details/${claimHeaderId}/`),
            cancelClaim: item => setClaimAction("itemToCancel", item),
            downloadClaimValidationReport: (
                claimHeaderId: string,
                fileName: string,
            ) => downloadClaimData(claimHeaderId, "CLAIM_VALIDATION", fileName),
            onClaimRecall: item => setClaimAction("itemToRecall", item),
            onClaimApprove: item => setClaimAction("itemToApprove", item),
            onClaimDelete: item => setClaimAction("itemToDelete", item),
            onClaimEdit: ({ claimHeaderId }) =>
                navigate(`/claims/edit-claim/${claimHeaderId}`),
        }),
        [setClaimAction, downloadClaimData],
    );

    const onSearch = useCallback(
        (filters: SearchFilters) => {
            const hasFilters = Object.values(filters).some(
                filter => filter.length,
            );
            if (hasFilters) {
                search(constructQueryString(filters));
            } else {
                getClaim();
            }
        },
        [search, getClaim],
    );

    return (
        <Fragment>
            <CancelWithReasons
                id="cancel-claim"
                title="Cancel claim"
                open={!!actions.itemToCancel?.claimHeaderId}
                onClose={() => setClaimAction("itemToCancel", undefined)}
                onSubmit={onClaimCancel}
                loading={canceling}
            >
                <Grid container item xs={12} spacing={3} mb={5}>
                    <Grid item xs={12} md={6}>
                        <FieldRenderer
                            id="claim-details-file-name"
                            label="File name"
                            value={actions.itemToCancel?.filename}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FieldRenderer
                            id="claim-details-account-name"
                            label="Primary contract partner"
                            value={actions.itemToCancel?.accountName}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FieldRenderer
                            id="claim-details-date-from"
                            label="Date from"
                            value={displayDate(actions.itemToCancel?.dateFrom)}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FieldRenderer
                            id="claim-details-date-to"
                            label="Date to"
                            value={displayDate(actions.itemToCancel?.dateTo)}
                        />
                    </Grid>
                </Grid>
            </CancelWithReasons>

            <MemoizedDialog
                item={actions.itemToRecall}
                onAction={onClaimRecall}
                loading={recalling}
                setItem={item => setClaimAction("itemToRecall", item)}
                dialogId="dashboard-claims-recall-claim"
                message={
                    <Typography
                        variant="subtitle2"
                        color="black"
                        component="span"
                    >
                        Recalling the claim will change the claim status to
                        &apos;Draft&apos; again, please confirm this is what you
                        want to do?
                    </Typography>
                }
                primaryButtonText="Confirm"
                secondaryButtonText="Cancel"
            />

            <MemoizedDialog
                item={actions?.itemToDelete}
                onAction={onClaimDelete}
                loading={deleting}
                setItem={item => setClaimAction("itemToDelete", item)}
                dialogId="dashboard-claims-delete-claim"
                message={
                    <Typography
                        variant="subtitle2"
                        color="black"
                        component="span"
                    >
                        Are you sure you want to delete
                        <Typography variant="body2" component="span">
                            {` (${actions?.itemToDelete?.filename})`}
                        </Typography>
                        ? This action cannot be undone.
                    </Typography>
                }
                primaryButtonText="Delete claim"
                secondaryButtonText="Cancel"
            />

            <MemoizedDialog
                item={actions?.itemToApprove}
                onAction={onClaimApprove}
                loading={approving}
                setItem={item => setClaimAction("itemToApprove", item)}
                dialogId="dashboard-claims-approve-claim"
                message={
                    <Typography
                        variant="subtitle2"
                        color="black"
                        component="span"
                    >
                        Are you sure you want to send
                        <Typography variant="body2" component="span">
                            {` (${actions?.itemToApprove?.filename}) `}
                        </Typography>
                        for approval?
                    </Typography>
                }
                primaryButtonText="Send for approval"
                secondaryButtonText="Cancel"
            />
            <Box mt={2} mb={4} display="flex" justifyContent="flex-end">
                <SearchBar
                    id="dashboard-claims"
                    filterKey={
                        isPriceCorrectionClaims
                            ? ROWRENDERERCONST.PRICE_CORRECTION_CLAIMS
                            : ROWRENDERERCONST.CLAIM
                    }
                    handleSearch={onSearch}
                    placeholder={searchByRenderer(
                        countryIsoCode,
                        isPriceCorrectionClaims,
                    )}
                    viewingOptions={viewingOptions}
                    ref={searchRef}
                    startDate={false}
                    endDate={false}
                    setViewingOptions={setViewingOptions}
                    status={{
                        loading: false,
                        data: [
                            {
                                value: "NEW",
                                label: "New",
                            },
                            {
                                value: "DRAFT",
                                label: "Draft",
                            },
                            {
                                value: "OPEN",
                                label: "Open",
                            },
                            {
                                value: "CLOSED",
                                label: "Closed",
                            },
                            {
                                value: "CANCELLED_WITH_FINANCIAL_IMPACT",
                                label: "Cancelled with financial impact",
                            },
                            {
                                value: "CANCELLED_WITHOUT_FINANCIAL_IMPACT",
                                label: "Cancelled without financial impact",
                            },
                            {
                                value: "PENDING_CANCELLATION",
                                label: "Pending cancellation",
                            },
                        ],
                    }}
                />
            </Box>
            <MemoizedTable
                isPriceCorrectionClaims={isPriceCorrectionClaims}
                claimList={claimList}
                listLoading={fetching}
                countryIsoCode={countryIsoCode}
                viewingOptions={viewingOptions}
                callbacks={tableCallbacks}
                hasPermissionToEditClaim={!!hasPermissionToEditClaim}
            />
        </Fragment>
    );
};

export default React.memo(ClaimsOverview);
