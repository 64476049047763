// Libs
import { Typography, Box } from "@mui/material";
import React, { Fragment } from "react";

// Own components
import { TaskReview, FieldRenderer } from "@components";

// Styles
import * as style from "../style.module.scss";
import { Contract } from "@types";

/**
 * Sub task props Type
 */
type TaskType = {
    completed: string;
    approved: boolean;
    assigneeFirstName: string;
    assigneeLastName: string;
    category: string;
    newInfusionDate: string;
    oldInfusionDate: string;
    reasonRejection: string;
    amount: string;
    oldAmount: string;
    override: string;
    reasonOverride: string;
    decisionInfo: string;
    recalled: boolean | null;
    taskDefinitionKey: string;
};

type ContractInfo = {
    id: string;
    brandName: string;
    contractRef: string;
    contractResponsible: string;
    countryName: string;
    newStartDate: string;
    newEndDate: string;
    startDate: string;
    endDate: string;
    indicationName: string;
    primaryContractPartnerName: string;
    therapeuticAreaName: string;
    externalReference: string;
    icdNumber: string;
    icdUrl: string;
    comment: string | null;
};

/**
 * Order props Type
 */
type Order = {
    newInfusionDate: string;
    oldInfusionDate: string;
};

/**
 * Outcome props Type
 */
type Outcome = {
    reasonRejection: string;
    amount: number;
    oldAmount: number;
    override: boolean;
    reasonOverride: string;
    decisionInfo: string;
};

/**
 * Task props Type
 */

type Task = {
    task: TaskType;
    order: Order;
    outcome: Outcome;
    contract: ContractInfo;
};

/**
 *  Props Type
 */
interface Props {
    contract: Contract;
    task: Task;
    taskType: any;
}

const CompletedTasksSection = ({ contract, task, taskType }: Props) => {
    return (
        <Fragment>
            <Box mt={3}>
                <div className={style.header}>
                    <Typography variant="h2">Task details</Typography>
                </div>
            </Box>
            <TaskReview
                id={`completed-tasks`}
                completedTask={{
                    taskDefinitionKey: task?.task?.taskDefinitionKey,
                    completed: task.task?.completed,
                    approved: task.task?.approved,
                    assigneeFirstName: task.task?.assigneeFirstName,
                    assigneeLastName: task.task?.assigneeLastName,
                    category: task.task?.category,
                    newInfusionDate:
                        taskType?.isInfusionDateChangeApprovalTask &&
                        task.order?.newInfusionDate,
                    oldInfusionDate:
                        taskType?.isInfusionDateChangeApprovalTask &&
                        task.order?.oldInfusionDate,
                    newStartDate:
                        taskType?.isChangeContractDateApprovalTask &&
                        task.contract?.newStartDate,
                    comment:
                        taskType?.isChangeContractDateApprovalTask &&
                        task.contract?.comment,
                    newEndDate:
                        taskType?.isChangeContractDateApprovalTask &&
                        task.contract?.newEndDate,
                    oldStartDate:
                        taskType?.isChangeContractDateApprovalTask &&
                        task.contract?.startDate,
                    oldEndDate:
                        taskType?.isChangeContractDateApprovalTask &&
                        task.contract?.endDate,
                    reasonRejection: task.outcome?.reasonRejection,
                    amount: task.outcome?.amount,
                    oldAmount: task.outcome?.oldAmount,
                    override: task.outcome?.override,
                    reasonOverride: task.outcome?.reasonOverride,
                    decisionInfo: task.outcome?.decisionInfo,
                    recalled: task.task?.recalled,
                }}
            />
            {!!contract?.reasonRejection && (
                <Box>
                    <FieldRenderer
                        id={`task-details-reasonRejection`}
                        label="Reason of rejection"
                        multiline
                        value={contract?.reasonRejection || ""}
                    />
                </Box>
            )}
        </Fragment>
    );
};

export default CompletedTasksSection;
