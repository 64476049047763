// Lib
import ConstructionIcon from "@mui/icons-material/Construction";
import Box from "@mui/material/Box";
import React, { useMemo } from "react";

// Own components
import { Breadcrumbs, Tabs } from "@components";

// Types
import type { Location } from "@types";

/**
 * Props type
 */
interface Props {
    children: React.ReactNode;
    location: Location;
}

/**
 * Maintenance layout
 */
const MaintenanceLayout = ({ children, location }: Props) => {
    /**
     * Tabs
     */
    const tabs = useMemo(() => {
        return [
            {
                title: "Product",
                path: "/maintenance/product/",
            },
            {
                title: "Financial conditions",
                path: "/maintenance/financial-conditions/",
            },
            {
                title: "Product conditions",
                path: "/maintenance/product-conditions/",
            },
            {
                title: "Partner",
                path: "/maintenance/partner/",
            },
            {
                title: "Country info",
                path: "/maintenance/country/",
            },
            {
                title: "Teams",
                path: "/maintenance/teams/",
            },
            {
                title: "Users",
                path: "/maintenance/users/",
            },
            {
                title: "Context",
                path: "/maintenance/context",
            },
            {
                title: "Model",
                path: "/maintenance/model",
            },
            {
                title: "Category",
                path: "/maintenance/category",
            },
            {
                title: "Interface",
                path: "/maintenance/interface/",
            },
            {
                title: "Billing plan",
                path: "/maintenance/billing-plan/",
            },
            {
                title: "Milestone",
                path: "/maintenance/milestone/",
            },
            {
                title: "System availability",
                path: "/maintenance/system-maintenance/",
            },
        ];
    }, []);

    return (
        <div>
            <Breadcrumbs
                id={`maintenance-breadcrumb`}
                icon={<ConstructionIcon color="primary" />}
                title="Maintenance"
                location={location}
            />

            <Box mb={5}>
                <Tabs
                    id={`maintenance-tabs`}
                    location={location}
                    items={tabs}
                />
            </Box>

            <Box px={7}>
                {React.Children.map(
                    children as React.ReactElement,
                    (child: React.ReactElement) =>
                        React.cloneElement(child, {
                            id: "maintenance",
                        }),
                )}
            </Box>
        </div>
    );
};
export default MaintenanceLayout;
