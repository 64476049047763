import { atom } from "recoil";

/**
 * Type categories
 */
type Categories = {
    categoryName?: string;
    categoryId: string;
    categoryLabel: string;
    categoryValue: string;
};

/**
 * select category state
 */
export const selectCategoriesState = atom({
    key: "selectCategoriesState",
    default: [] as Categories[],
});
