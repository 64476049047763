// Icons
import { KeyboardArrowDown } from "@mui/icons-material";

// Libs
import { Grid, InputLabel, MenuItem, Select } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React, {
    useEffect,
    useImperativeHandle,
    useMemo,
    useState,
} from "react";

// Own components
import { InfoBox } from "@components";

// Hooks
import { useUpdateStep } from "@hooks";

// Atoms
import { editOrderStepsState } from "@atoms";

// Types
import type { Location } from "@types";

// Constants
import { ORDER_STATUSES } from "@constants";

interface Props {
    location?: Location;
    order?: any;
    id?: string;
}

const EditOrder = React.forwardRef(({ location, order, id }: Props, ref) => {
    const [isInfoboxVisible, setIsInfoboxVisible] = useState<boolean>(false);
    const [orderStatus, updateOrderStatus] = useState<string>("");
    const [touched, setTouched] = useState<boolean>(false);

    // update step hook
    const updateStepValidation = useUpdateStep(location, editOrderStepsState);

    const hasError = useMemo(() => {
        return !touched || (!orderStatus && touched);
    }, [orderStatus, touched]);

    /**
     * Save and go to the next page
     */
    useImperativeHandle(ref, () => ({
        onSave() {
            return { status: orderStatus };
        },
    }));

    const handleChange = target => {
        updateOrderStatus(target.value);

        if (target.value === "CANCELLED") {
            setIsInfoboxVisible(true);
            return;
        }
        setIsInfoboxVisible(false);
    };

    useEffect(() => {
        updateStepValidation(!hasError);
    }, [hasError, location]);

    /**
     * Map order status and show the possible statuses
     */
    const statusMapper = useMemo(() => {
        if (!order?.orderStatus) return [];
        return ORDER_STATUSES[order?.orderStatus];
    }, [order]);

    return (
        <Box
            mb={5}
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            height={1}
        >
            <div>
                <Box mb={7}>
                    <Typography variant="h2">Edit order status</Typography>
                </Box>

                <Grid item xs={12} sm={6}>
                    <InputLabel shrink error={touched && !orderStatus}>
                        {"Status (*)"}
                    </InputLabel>
                    <Select
                        id={`${id}-edit-order-select-order-status`}
                        onBlur={() => setTouched(true)}
                        variant="outlined"
                        fullWidth
                        name={"orderStatus"}
                        size="small"
                        displayEmpty
                        value={orderStatus}
                        onChange={event => {
                            const target = event.target as any;
                            handleChange(target);
                        }}
                        error={touched && !orderStatus}
                        renderValue={item => {
                            return (
                                <span> {item ? item : order?.orderStatus}</span>
                            );
                        }}
                        IconComponent={props => (
                            <KeyboardArrowDown {...props} />
                        )}
                    >
                        <MenuItem value="" disabled>
                            {order?.orderStatus}
                        </MenuItem>
                        {statusMapper?.map((status: string) => (
                            <MenuItem key={status} value={status}>
                                {status}
                            </MenuItem>
                        ))}
                    </Select>
                </Grid>
            </div>
            {isInfoboxVisible && (
                <Box pb={1}>
                    <InfoBox
                        warning
                        id={`${id}-edit-order-order-status`}
                        message={
                            <Typography variant="subtitle2">
                                You are about to change the order status to
                                <strong> {` Cancelled`}</strong>. You cannot
                                undo this action.
                            </Typography>
                        }
                    />
                </Box>
            )}
        </Box>
    );
});

export default React.memo(EditOrder);
