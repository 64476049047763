// Lib
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import {
    FormHelperText,
    Grid,
    InputLabel,
    TextField,
    Select,
    MenuItem,
    Box,
    Typography,
} from "@mui/material";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import React, { Fragment, useCallback } from "react";

// Utils
import { convertDateForAPI, datePlaceholder, isArrayWithContent } from "@utils";

/**
 * Props type
 */
interface Props {
    errors: Record<string, any>;
    values: any;
    onBlur: (field: string, touched?: boolean | undefined) => void;
    onChange: (elementIndex: number, value: any) => void;
    loading?: boolean;
    touched: Record<string, any>;
    disabled?: boolean;
    id: string;
}

/**
 * Outcome variables renderer
 */
const OutcomeVariables: React.FC<Props> = ({
    errors,
    values,
    onBlur,
    onChange,
    loading,
    touched,
    disabled,
    id,
}) => {
    /**
     * Transform error
     */
    const errorTransformer = useCallback(
        (index: number) => {
            if (!errors || !errors.variables) return;

            const foundError = errors.variables[index];

            return !!foundError && !!foundError["value"]
                ? !!foundError["value"]
                : foundError;
        },
        [errors],
    );

    /**
     * Render
     */
    return (
        <Box>
            {!loading && isArrayWithContent(values) ? (
                <Grid container rowSpacing={3} columnSpacing={4}>
                    {values.map(
                        ({ inputVariableName, label, value, type }, index) => (
                            <Fragment key={index}>
                                {type === "date" && (
                                    <Grid item xs={12} md={6}>
                                        <InputLabel
                                            error={
                                                !!errorTransformer(index) &&
                                                touched[inputVariableName]
                                            }
                                            shrink
                                        >
                                            {`${label} (*)`}
                                        </InputLabel>

                                        <DatePicker
                                            value={datePlaceholder(value)}
                                            onChange={value => {
                                                onBlur(inputVariableName, true);
                                                if (!value || !value?.isValid)
                                                    onChange(index, "");

                                                if (value) {
                                                    onChange(
                                                        index,
                                                        convertDateForAPI(
                                                            value,
                                                        ),
                                                    );
                                                }
                                            }}
                                            disabled={disabled}
                                            format="dd/MM/yyyy"
                                            slotProps={{
                                                textField: {
                                                    onBlur: () =>
                                                        onBlur(
                                                            inputVariableName,
                                                            true,
                                                        ),
                                                    variant: "outlined",
                                                    autoComplete: "off",
                                                    sx: {
                                                        svg: {
                                                            color: "#036",
                                                            opacity: "0.5",
                                                        },
                                                    },
                                                    size: "small",
                                                    fullWidth: true,
                                                    placeholder: "dd/mm/yyyy",
                                                    required: true,
                                                    error:
                                                        !!errorTransformer(
                                                            index,
                                                        ) &&
                                                        touched[
                                                            inputVariableName
                                                        ],
                                                    id: `${id}-date-${inputVariableName}`,
                                                    name: inputVariableName,
                                                },
                                            }}
                                            //  minDate={defaultEndMinDate()}
                                            slots={{
                                                // Override default <ActionBar /> with a custom one
                                                openPickerIcon:
                                                    CalendarTodayIcon,
                                            }}
                                        />
                                        {!!errorTransformer(index) &&
                                            touched[inputVariableName] && (
                                                <FormHelperText error>
                                                    {errorTransformer(index)}
                                                </FormHelperText>
                                            )}
                                    </Grid>
                                )}

                                {type === "integer" && (
                                    <Grid item xs={12} md={6}>
                                        <InputLabel
                                            error={
                                                !!errorTransformer(index) &&
                                                touched[inputVariableName]
                                            }
                                            shrink
                                        >
                                            {`${label} (*)`}
                                        </InputLabel>

                                        <TextField
                                            id={`${id}-integer-${inputVariableName}`}
                                            fullWidth
                                            variant="outlined"
                                            disabled={disabled}
                                            autoComplete="off"
                                            sx={{
                                                svg: {
                                                    color: "#036",
                                                    opacity: "0.5",
                                                },
                                            }}
                                            onBlur={() =>
                                                onBlur(inputVariableName, true)
                                            }
                                            value={value || ""}
                                            onChange={event => {
                                                if (
                                                    (!isNaN(
                                                        parseInt(
                                                            event.target.value,
                                                        ),
                                                    ) &&
                                                        parseInt(
                                                            event.target.value,
                                                        ) > -1) ||
                                                    event.target.value == ""
                                                ) {
                                                    onChange(
                                                        index,
                                                        parseInt(
                                                            event.target.value,
                                                        ),
                                                    );
                                                }
                                            }}
                                            name={inputVariableName}
                                            size="small"
                                            error={
                                                !!errorTransformer(index) &&
                                                touched[inputVariableName]
                                            }
                                        />
                                    </Grid>
                                )}

                                {type === "boolean" && (
                                    <Grid item xs={12} md={6}>
                                        <InputLabel
                                            error={
                                                !!errorTransformer(index) &&
                                                touched[inputVariableName]
                                            }
                                            shrink
                                        >
                                            {`${label} (*)`}
                                        </InputLabel>

                                        <Select
                                            id={`${id}-boolean-${inputVariableName}`}
                                            variant="outlined"
                                            fullWidth
                                            disabled={disabled}
                                            name={inputVariableName}
                                            value={JSON.stringify(value)}
                                            size="small"
                                            onChange={event => {
                                                onChange(
                                                    index,
                                                    JSON.parse(
                                                        event.target.value,
                                                    ),
                                                );
                                            }}
                                            onBlur={() =>
                                                onBlur(inputVariableName, true)
                                            }
                                            error={
                                                !!errorTransformer(index) &&
                                                touched[inputVariableName]
                                            }
                                            IconComponent={props => (
                                                <KeyboardArrowDown {...props} />
                                            )}
                                        >
                                            <MenuItem value="true">
                                                Yes
                                            </MenuItem>
                                            <MenuItem value="false">
                                                No
                                            </MenuItem>
                                        </Select>
                                    </Grid>
                                )}
                            </Fragment>
                        ),
                    )}
                </Grid>
            ) : (
                <Box display="flex" justifyContent="center" mt={4}>
                    <Typography variant="caption1">
                        No variables found!
                    </Typography>
                </Box>
            )}
        </Box>
    );
};

export default React.memo(OutcomeVariables);
