//libs
import React, { useCallback, Fragment, useMemo } from "react";

import {
    LinearProgress,
    InputLabel,
    TextField,
    FormHelperText,
    Typography,
    Box,
    Button,
    Grid,
    Divider,
} from "@mui/material";
import { useDropzone } from "react-dropzone";
import classNames from "classnames";

import CloudUploadIcon from "@mui/icons-material/CloudUpload";

// Styles
import * as style from "./style.module.scss";

// Utils
import { isArrayWithContent, truncateArray, truncateString } from "@utils";
import FieldRenderer from "../FieldRenderer";

/**
 * File format
 */
type FileFormat =
    | ".pdf"
    | ".png"
    | ".jpeg"
    | ".jpg"
    | ".docx"
    | ".doc"
    | ".xls"
    | ".xlsx"
    | ".msg"
    | ".geojson"
    | ".txt";

/**
 * Props type
 */

interface Props {
    id: string;
    onUpload: (files: any) => void;
    files: Array<any>;
    onAttachmentRemove?: (
        index: number,
        attachmentsType: "newAttachments" | "stored",
    ) => void;
    loading?: boolean;
    fileTypes: Record<string, Array<FileFormat>>;
    variant: "big" | "small";
    disabled?: boolean;
    canDeleteAttachments?: boolean;
    maxNameLength?: string;
    multiple?: boolean;
    descriptions?: string[];
    onDescriptionChange?: (
        type: "delete" | "edit",
        index: number,
        value: string,
        attachmentsType?: "stored" | "newAttachments",
    ) => void;
    canDownloadAttachments?: boolean;
    onDownloadAttachment?: (index: number) => void;
    storedAttachments?: any;
    hasFileDescription?: boolean;
    downloading?: boolean;
}

/**
 * Dropzone
 */
const Dropzone = ({
    onUpload,
    files,
    loading,
    onAttachmentRemove,
    fileTypes,
    variant = "big",
    disabled,
    canDeleteAttachments = true,
    canDownloadAttachments,
    id,
    maxNameLength,
    multiple = false,
    descriptions,
    onDescriptionChange,
    onDownloadAttachment,
    storedAttachments,
    hasFileDescription,
    downloading,
}: Props) => {
    /**
     * Touched
     */
    const [touched, setTouched] = React.useState<Record<string, boolean>>({});

    /**
     * Blur handler
     */
    const onBlur = (
        action: "add" | "remove" | "reset",
        attachmentsType: "newAttachments" | "stored",
        idx?: number,
    ) => {
        if (!descriptions) return;

        if (action === "add") {
            setTouched({
                ...touched,
                [`${attachmentsType}-attachment-description-${idx}`]: true,
            });
        }

        if (action === "remove") {
            setTouched({});
        }

        if (action === "reset") {
            const updatedTouched = { ...touched };

            if (attachmentsType === "newAttachments") {
                Object.keys(updatedTouched).forEach(key => {
                    if (key.includes("newAttachments-")) {
                        delete updatedTouched[key];
                    }
                });
            } else {
                Object.keys(updatedTouched).forEach(key => {
                    if (key.includes("stored-")) {
                        delete updatedTouched[key];
                    }
                });
            }

            setTouched(updatedTouched);
        }
    };

    /**
     * On drop
     */
    const onDrop = useCallback(
        acceptedFile => {
            onUpload(multiple ? [...files, ...acceptedFile] : acceptedFile[0]);
            onBlur("reset", "newAttachments");
        },
        [files, onUpload],
    );

    /**
     * uploaded files
     */
    const uploadedFiles = useMemo(() => {
        if (!files) return;
        return files;
    }, [files]);

    const mapTypes = useMemo(() => {
        if (!fileTypes) return [];

        const types = Object.values(fileTypes).map(value =>
            value.map(
                item => item.substring(1), // remove "."
            ),
        );
        return types;
    }, [fileTypes]);

    /**
     * Name length validator
     */
    const nameLengthValidator = file => {
        if (!maxNameLength) return null;
        if (file.name.length > maxNameLength) {
            return {
                code: "name-too-large",
                message: `The file name length can not exceed ${maxNameLength} characters. Please shorten the file name and upload again.`,
            };
        }

        return null;
    };

    /**
     * Dropzone hook
     */
    const { getRootProps, getInputProps, fileRejections } = useDropzone({
        onDrop,
        noClick: false,
        noKeyboard: true,
        disabled: disabled,
        multiple: multiple,
        maxFiles: multiple ? undefined : 1,
        maxSize: 1048576 * 6, //6MB
        accept: fileTypes,
        validator: maxNameLength ? nameLengthValidator : undefined,
    });

    /**
     * Rejected file
     */
    const rejectedFile = useMemo(() => {
        if (
            !isArrayWithContent(fileRejections) ||
            !isArrayWithContent(mapTypes)
        )
            return;

        const types = truncateArray(mapTypes);

        return fileRejections.map(item =>
            item.errors.map(err => {
                const messages: string[] = [];

                if (err.code === "file-invalid-type")
                    messages.push(`* The file must be in ${types} format`);
                if (err.code === "file-too-large" && !!maxNameLength)
                    messages.push(
                        "* The file is too large, it must not exceed 6 MB",
                    );
                if (err.code === "name-too-large")
                    messages.push(
                        `* The file name length can not exceed ${maxNameLength} characters. Please shorten the file name and upload again.`,
                    );

                return messages;
            }),
        );
    }, [fileRejections, mapTypes]);

    /**
     * Render
     */
    return (
        <Fragment>
            <div
                {...getRootProps({ className: "dropzone" })}
                id={`${id}-dropzone-wrapper`}
            >
                {variant === "big" ? (
                    <div
                        className={classNames(style.square, {
                            [style.disabled]: disabled,
                        })}
                    >
                        <CloudUploadIcon fontSize="large" />
                        <Typography
                            component="span"
                            variant="caption1"
                            className={style.spacer}
                        >
                            Drag and drop files here
                        </Typography>
                        <Typography
                            component="span"
                            variant="caption1"
                            className={style.spacer}
                        >
                            or
                        </Typography>

                        <Button variant="outlined" disabled={disabled}>
                            Choose file
                        </Button>
                    </div>
                ) : (
                    <Fragment>
                        <InputLabel error={false} shrink>
                            {"DMN file (*)"}
                        </InputLabel>
                        <TextField
                            size="small"
                            disabled={!!disabled}
                            variant="outlined"
                            fullWidth
                            value={"Select DMN file..."}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Fragment>
                )}

                <input
                    data-testid="fileInput"
                    {...getInputProps()}
                    id="fileInput"
                />
                {loading && <LinearProgress />}
            </div>
            {!!uploadedFiles && !!isArrayWithContent(uploadedFiles) && (
                <Grid
                    item
                    container
                    xs={12}
                    alignItems={"flex-end"}
                    justifyContent={"space-between"}
                    columnSpacing={1}
                    mb={4}
                >
                    {isArrayWithContent(storedAttachments) && (
                        <Grid item xs={12} mt={4}>
                            <Typography variant="h4" className={style.spacer}>
                                {"New Attachments"}
                            </Typography>
                        </Grid>
                    )}
                    {uploadedFiles.map(
                        (file, idx) =>
                            !!file && (
                                <Fragment key={idx}>
                                    <Grid
                                        item
                                        xs={
                                            descriptions &&
                                            isArrayWithContent(descriptions)
                                                ? 4
                                                : 6
                                        }
                                        mt={
                                            !!hasFileDescription || idx < 1
                                                ? 2
                                                : 4
                                        }
                                    >
                                        <FieldRenderer
                                            id={`${id}-attachment-${idx}`}
                                            showTooltip
                                            value={truncateString(
                                                file.name,
                                                38,
                                                true,
                                            )}
                                            hasDeleteAction={
                                                canDeleteAttachments
                                            }
                                            onDeleteClick={() => {
                                                onAttachmentRemove &&
                                                    onAttachmentRemove(
                                                        idx,
                                                        "newAttachments",
                                                    );
                                                onBlur(
                                                    "remove",
                                                    "newAttachments",
                                                    idx,
                                                );
                                            }}
                                        />
                                    </Grid>
                                    {hasFileDescription &&
                                        !!descriptions &&
                                        isArrayWithContent(descriptions) &&
                                        !!onDescriptionChange && (
                                            <Grid item xs={8} mt={1}>
                                                <InputLabel
                                                    shrink
                                                    id={`${id}-attachment-${idx}-label`}
                                                    error={
                                                        touched[
                                                            `newAttachments-attachment-description-${idx}`
                                                        ] &&
                                                        descriptions[idx] === ""
                                                    }
                                                >
                                                    {"Description (*)"}
                                                </InputLabel>
                                                <TextField
                                                    id={`${id}-attachment-${idx}-input`}
                                                    size="small"
                                                    autoComplete="off"
                                                    variant="outlined"
                                                    name={`newAttachments-attachment-description-${idx}`}
                                                    fullWidth
                                                    value={
                                                        descriptions[idx] || ""
                                                    }
                                                    error={
                                                        touched[
                                                            `newAttachments-attachment-description-${idx}`
                                                        ] &&
                                                        descriptions[idx] === ""
                                                    }
                                                    onChange={e =>
                                                        onDescriptionChange(
                                                            "edit",
                                                            idx,
                                                            e.target.value?.trimStart(),
                                                            "newAttachments",
                                                        )
                                                    }
                                                    onBlur={() =>
                                                        onBlur(
                                                            "add",
                                                            "newAttachments",
                                                            idx,
                                                        )
                                                    }
                                                />
                                            </Grid>
                                        )}
                                </Fragment>
                            ),
                    )}
                </Grid>
            )}

            {rejectedFile && (
                <FormHelperText error data-testid="rejectedFiles">
                    {`Can't upload the file${multiple ? "s" : ""}:`}
                    {rejectedFile.map((el, index) =>
                        el.map(msg => (
                            <Box display="block" key={index}>
                                {msg}
                            </Box>
                        )),
                    )}
                </FormHelperText>
            )}

            {!!storedAttachments && !!isArrayWithContent(storedAttachments) && (
                <Grid
                    item
                    container
                    xs={12}
                    alignItems={"flex-end"}
                    justifyContent={"space-between"}
                    columnSpacing={1}
                >
                    {isArrayWithContent(uploadedFiles) && (
                        <Grid item xs={12} my={4}>
                            <Divider />
                        </Grid>
                    )}
                    <Grid item xs={12} mt={4}>
                        <Typography variant="h4" className={style.spacer}>
                            {"Stored attachments"}
                        </Typography>
                    </Grid>
                    {storedAttachments.map(
                        (file, idx) =>
                            !!file && (
                                <Fragment key={idx}>
                                    <Grid
                                        item
                                        xs={hasFileDescription ? 4 : 6}
                                        mt={
                                            hasFileDescription || idx < 1
                                                ? 2
                                                : 4
                                        }
                                    >
                                        <FieldRenderer
                                            id={`${id}-attachment-${idx}`}
                                            showTooltip
                                            label={
                                                file?.descriptions
                                                    ? `File ${idx + 1}`
                                                    : undefined
                                            }
                                            disabledAction={downloading}
                                            loading={downloading}
                                            value={truncateString(
                                                file.fileName,
                                                38,
                                                true,
                                            )}
                                            hasDeleteAction={
                                                canDeleteAttachments
                                            }
                                            onDeleteClick={() => {
                                                onAttachmentRemove &&
                                                    onAttachmentRemove(
                                                        idx,
                                                        "stored",
                                                    );
                                                onBlur("remove", "stored", idx);
                                            }}
                                            hasDownloadAction={
                                                canDownloadAttachments
                                            }
                                            onDownloadClick={
                                                onDownloadAttachment
                                                    ? () => {
                                                          onDownloadAttachment(
                                                              idx,
                                                          );
                                                          // setDownloadIdx(idx);
                                                      }
                                                    : undefined
                                            }
                                        />
                                    </Grid>

                                    <Grid item xs={8} mt={1}>
                                        <InputLabel
                                            shrink
                                            id={`${id}-attachment-${idx}-label`}
                                            error={
                                                touched[
                                                    `stored-attachment-description-${idx}`
                                                ] && file?.descriptions === ""
                                            }
                                        >
                                            {"Description (*)"}
                                        </InputLabel>
                                        <TextField
                                            id={`${id}-attachment-${idx}-input`}
                                            size="small"
                                            autoComplete="off"
                                            variant="outlined"
                                            name={`attachment-description-${idx}`}
                                            fullWidth
                                            value={file?.fileDescription || ""}
                                            error={
                                                touched[
                                                    `stored-attachment-description-${idx}`
                                                ] &&
                                                file?.fileDescription === ""
                                            }
                                            onChange={
                                                onDescriptionChange
                                                    ? e =>
                                                          onDescriptionChange(
                                                              "edit",
                                                              idx,
                                                              e.target.value?.trimStart(),
                                                              "stored",
                                                          )
                                                    : undefined
                                            }
                                            onBlur={() =>
                                                onBlur("add", "stored", idx)
                                            }
                                        />
                                    </Grid>
                                </Fragment>
                            ),
                    )}
                </Grid>
            )}
        </Fragment>
    );
};

export default Dropzone;
