// Libs
import { Grid } from "@mui/material";
import React from "react";

// Own component
import { Card, FieldRenderer } from "@components";

/**
 * Props type
 */
export interface Props {
    id: string;
    className?: string;
    name: string;
    knowyoursupplierid: string;
    address: string;
    newKnowyoursupplierid?: string;
}

/**
 * Task partner Approval Details
 */
const TaskPartnerApprovalDetails = ({
    id,
    className,
    name,
    knowyoursupplierid,
    address,
    newKnowyoursupplierid,
}: Props) => {
    /**
     * Render
     */
    return (
        <Card id={`${id}-card`} className={className}>
            <Grid item xs={12} container rowSpacing={3} columnSpacing={4}>
                <Grid item xs={12} md={6} lg={3}>
                    <FieldRenderer
                        id={`${id}-partner-data-name`}
                        label={"Partner name"}
                        showTooltip
                        value={name}
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <FieldRenderer
                        id={`${id}-partner-data-address`}
                        label={"Partner address"}
                        showTooltip
                        value={address}
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <FieldRenderer
                        id={`${id}-partner-knowyoursupplierid`}
                        label={"Know your supplier ID"}
                        showTooltip
                        value={knowyoursupplierid}
                    />
                </Grid>

                {newKnowyoursupplierid && (
                    <Grid item xs={12} md={6} lg={3}>
                        <FieldRenderer
                            id={`${id}-partner-newKnowyoursupplierid`}
                            label={"New Know your supplier ID"}
                            showTooltip
                            value={newKnowyoursupplierid}
                        />
                    </Grid>
                )}
            </Grid>
        </Card>
    );
};

export default TaskPartnerApprovalDetails;
