//Lib
import {
    Box,
    Typography,
    FormControl,
    FormControlLabel,
    RadioGroup,
    Radio,
    Grid,
    InputLabel,
    TextField,
} from "@mui/material";
import React, { Fragment } from "react";

// Own components
import { Dialog, FieldRenderer } from "@components";
import { thousandsSeparator } from "@utils";

/**
 * Props
 */
interface Props {
    openDialog: boolean;
    onTaskComplete: () => void;
    loading: boolean;
    onReset: () => void;
    className?: string;
    values: { approved: string; rejectionReason: string };
    onBlur: (event: React.SyntheticEvent) => void;
    errors: any;
    touched: any;
    onChange: any;
    oldValue: string;
    newValue: string;
    id: string;
}

/**
 * OverrideApprovalTask
 */
const OverrideApprovalTask = ({
    openDialog,
    loading,
    onReset,
    onTaskComplete,
    className,
    values,
    onBlur,
    onChange,
    oldValue,
    newValue,
    errors,
    touched,
    id,
}: Props) => {
    return (
        <Fragment>
            <Dialog
                id={`tasks-approve-claim-override-task`}
                title={"Approve claim override task"}
                open={openDialog}
                message={
                    values?.approved === "recalled" ? (
                        <Typography variant="subtitle2" color="black">
                            You are about to recall this Claim Override task.
                            Doing so will set the claim to draft status. This
                            action cannot be undone.
                        </Typography>
                    ) : values?.approved === "approved" ? (
                        <Typography variant="subtitle2" color="black">
                            You are about to mark this Claim Override task as
                            approved for the Overridden Claim Rebate Value.
                            Doing so will set the Claim to Closed and will
                            create financial transactions. This action cannot be
                            undone.
                        </Typography>
                    ) : (
                        <Typography variant="subtitle2" color="black">
                            You are about to mark this Claim Override task as
                            rejected. Doing so will put the Claim as cancelled
                            and no financial transaction will be done. A new
                            Claim will have to be made if necessary. This action
                            cannot be undone.
                        </Typography>
                    )
                }
                primaryButton={{
                    text: "Confirm",
                    action: onTaskComplete,
                    loading: loading,
                }}
                secondaryButton={{
                    text: "Cancel",
                    action: onReset,
                }}
            />

            <Box mb={3} className={className}>
                <Grid container rowSpacing={3} columnSpacing={4} mb={3}>
                    {!!oldValue && (
                        <Grid item xs={12} md={6}>
                            <FieldRenderer
                                id={`task-details-rejectionReason`}
                                label="Total Rebate value"
                                value={thousandsSeparator(oldValue)}
                            />
                        </Grid>
                    )}
                    {!!newValue && (
                        <Grid item xs={12} md={6}>
                            <FieldRenderer
                                id={`task-details-rejectionReason`}
                                label="Overridden total rebate value"
                                value={thousandsSeparator(newValue)}
                            />
                        </Grid>
                    )}
                </Grid>

                <form noValidate onBlur={onBlur}>
                    <FormControl>
                        <RadioGroup
                            id={`task-details-task-review`}
                            value={values?.approved}
                            onChange={onChange}
                            name="approved"
                        >
                            <FormControlLabel
                                value={"approved"}
                                id={`task-details-override-value-approve`}
                                control={<Radio size="small" />}
                                label={
                                    <Typography
                                        variant="body2"
                                        color="dark.light"
                                    >
                                        I approve this amount
                                    </Typography>
                                }
                            />
                            <FormControlLabel
                                id={`task-details-override-value-reject`}
                                value={"rejected"}
                                control={<Radio size="small" />}
                                label={
                                    <Typography
                                        variant="body2"
                                        color="dark.light"
                                    >
                                        I reject this amount
                                    </Typography>
                                }
                            />
                            <FormControlLabel
                                id={`task-details-override-value-reject`}
                                value={"recalled"}
                                control={<Radio size="small" />}
                                label={
                                    <Typography
                                        variant="body2"
                                        color="dark.light"
                                    >
                                        Recall claim (resets claim to draft
                                        status)
                                    </Typography>
                                }
                            />
                        </RadioGroup>
                    </FormControl>
                    {values?.approved === "rejected" && (
                        <Box mt={2}>
                            <InputLabel
                                error={
                                    !!errors?.rejectionReason &&
                                    !!touched?.rejectionReason
                                }
                                shrink
                            >
                                {"Reason of rejection (*)"}
                            </InputLabel>
                            <TextField
                                id={`${id}-task-details-task-contract-review-rejection-reason-input`}
                                fullWidth
                                name="rejectionReason"
                                value={values.rejectionReason}
                                variant="outlined"
                                autoComplete="off"
                                size="small"
                                onChange={onChange}
                                error={
                                    !!errors?.rejectionReason &&
                                    !!touched?.rejectionReason
                                }
                            />
                        </Box>
                    )}
                </form>
            </Box>
        </Fragment>
    );
};

export default React.memo(OverrideApprovalTask);
