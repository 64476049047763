// Libs
import { TableCell, TableRow, Typography, Button } from "@mui/material";
import React from "react";

interface Row {
    condition: string;
    name: string;
    timingTypeId: string;
    description: string;
}

interface Props {
    row: Row;
    rowIndex: number;
    id: string;
    callbacks: any;
}

const FinancialConditions = ({ rowIndex, row, id, callbacks }: Props) => {
    return (
        <TableRow id={`${id}-row-${rowIndex}`}>
            <TableCell>
                <Typography variant="caption2">{row.name}</Typography>
            </TableCell>
            <TableCell>
                <Typography variant="caption2">{row.timingTypeId}</Typography>
            </TableCell>

            <TableCell>
                <Typography variant="caption2">{row.description}</Typography>
            </TableCell>
            <TableCell align="right">
                {callbacks && callbacks?.selectCondition && (
                    <Button
                        id={`${id}-${rowIndex}-financial-conditions-btn`}
                        variant="text"
                        onClick={() => callbacks?.selectCondition(row)}
                    >
                        Select condition
                    </Button>
                )}
            </TableCell>
        </TableRow>
    );
};

export default FinancialConditions;
