// Lib
import {
    Backdrop,
    Button,
    CircularProgress,
    Divider,
    Grid,
    InputBase,
    InputLabel,
    List,
    ListItem,
    ListItemText,
    Paper,
    TextField,
    Typography,
} from "@mui/material";
import React, { Fragment, useState, useMemo } from "react";

// Utils
import { isArrayWithContent, sortByField } from "@utils";

// Own components
import { ActionMenu } from "@components";

//Props
interface Props {
    valueLabel: string;
    valueId: string;
    id: string;
    error: any;
    title: string;
    subtitle: string;
    label: string;
    list: Array<any>;
    loading: { fetching: boolean; creating: boolean; deleting: boolean };
    disableCreate?: boolean;
    addListItem: (value: any, savedLabel: string) => void;
    deleteListItem: (id: string, savedLabel: string) => void;
}

/**
 * Maintenance list
 */
const MaintenanceList: React.FC<Props> = ({
    error,
    id,
    title,
    subtitle,
    label,
    list,
    loading,
    valueLabel,
    valueId,
    addListItem,
    deleteListItem,
    disableCreate = false,
}) => {
    const [createValue, setCreateValue] = useState<string>("");
    const [editValue, setEditValue] = useState<string>("");
    const [editId, setEditId] = useState<string>("");
    const [touched, setTouched] = useState<boolean>(false);

    /**
     * Sort list
     */
    const sortedList: Array<any> = useMemo(() => {
        return sortByField(list, valueLabel);
    }, [list, valueLabel]);

    /**
     * Render
     */
    return (
        <Grid item xs={12} md={6} lg={4} mt={5}>
            {/* Background for edit mode */}
            {editId !== "" && (
                <Backdrop
                    sx={{
                        backgroundColor: "rgba(0, 51, 102, 0.2)",
                        zIndex: theme => theme.zIndex.drawer + 1,
                    }}
                    open
                    onClick={() => {
                        setEditId("");
                        setEditValue("");
                    }}
                ></Backdrop>
            )}

            {/* Add form */}

            <Fragment>
                <Typography variant="h2">{title}</Typography>
                {!disableCreate ? (
                    <Grid container mb={5} mt={3} spacing={2} id={id}>
                        <Grid item xs={8}>
                            <InputLabel shrink error={!createValue && touched}>
                                {label}
                            </InputLabel>

                            <TextField
                                error={!createValue && touched}
                                fullWidth
                                name="name"
                                autoComplete="off"
                                value={createValue}
                                variant="outlined"
                                size="small"
                                onBlur={() => setTouched(true)}
                                onChange={e => {
                                    setCreateValue(e.target.value);
                                }}
                                disabled={
                                    loading?.creating || loading?.fetching
                                }
                            />
                        </Grid>
                        <Grid item xs={4} display="flex" alignItems="flex-end">
                            <Button
                                size="large"
                                sx={{ fontSize: "0.75rem", height: "34px" }}
                                variant="outlined"
                                onClick={() => {
                                    addListItem(
                                        { [valueLabel]: createValue },
                                        createValue,
                                    );
                                    setCreateValue("");
                                    setTouched(false);
                                }}
                                fullWidth
                                disabled={createValue === ""}
                            >
                                {loading?.creating ? (
                                    <CircularProgress
                                        size={25}
                                        color="inherit"
                                    />
                                ) : (
                                    "Add"
                                )}
                            </Button>
                        </Grid>
                    </Grid>
                ) : (
                    <Grid
                        container
                        mb={5}
                        mt={3}
                        minHeight={70}
                        display="flex"
                        alignItems="center"
                    >
                        {/* //TODO copy from Jentl */}
                        <Typography variant="caption1">
                            Creating {subtitle} not possible
                        </Typography>
                    </Grid>
                )}

                <Typography variant="h3">Saved {subtitle}</Typography>
            </Fragment>

            {/* List */}
            <List
                sx={{
                    overflow: "auto",
                    maxHeight: 375,
                }}
            >
                {!loading?.fetching &&
                    (isArrayWithContent(sortedList) ? (
                        <>
                            <Divider variant="fullWidth" component="li" />
                            {sortedList.map((listItem, index) => (
                                <Fragment key={listItem[valueId] + "-" + index}>
                                    <ListItem id={listItem[valueId]}>
                                        <ListItemText disableTypography>
                                            {editId === listItem[valueId] ? (
                                                // Edit mode
                                                <Paper
                                                    component="form"
                                                    sx={{
                                                        p: "2px 4px",
                                                        display: "flex",
                                                        alignItems: "center",
                                                        width: "100%",
                                                        position: "relative",

                                                        zIndex: theme =>
                                                            theme.zIndex
                                                                .drawer + 12,
                                                    }}
                                                >
                                                    <InputBase
                                                        sx={{
                                                            ml: 1,
                                                            flex: 1,
                                                        }}
                                                        fullWidth
                                                        defaultValue={
                                                            listItem[valueLabel]
                                                        }
                                                        onChange={e =>
                                                            setEditValue(
                                                                e.target.value,
                                                            )
                                                        }
                                                        inputComponent="input"
                                                        color="secondary"
                                                        disabled={
                                                            loading?.creating ||
                                                            loading?.fetching
                                                        }
                                                    />

                                                    <Button
                                                        disabled={
                                                            editValue === ""
                                                        }
                                                        onClick={() => {
                                                            if (
                                                                editValue !== ""
                                                            ) {
                                                                addListItem(
                                                                    {
                                                                        [valueLabel]:
                                                                            editValue,
                                                                        [valueId]:
                                                                            listItem[
                                                                                valueId
                                                                            ],
                                                                    },
                                                                    editValue,
                                                                );
                                                                setEditId("");
                                                                setEditValue(
                                                                    "",
                                                                );
                                                            }
                                                        }}
                                                    >
                                                        Save
                                                    </Button>
                                                </Paper>
                                            ) : (
                                                <Typography variant="caption2">
                                                    {listItem[valueLabel]}
                                                </Typography>
                                            )}
                                        </ListItemText>
                                        {editId !== listItem[valueId] && (
                                            <ActionMenu
                                                id={id}
                                                disabled={loading?.deleting}
                                                autoHide
                                                options={[
                                                    {
                                                        label: "Edit",
                                                        action: () => {
                                                            setEditId(
                                                                listItem[
                                                                    valueId
                                                                ],
                                                            );
                                                            setEditValue(
                                                                listItem[
                                                                    valueLabel
                                                                ],
                                                            );
                                                        },
                                                    },
                                                    {
                                                        label: "Delete",
                                                        action: () =>
                                                            deleteListItem(
                                                                listItem[
                                                                    valueId
                                                                ],
                                                                listItem[
                                                                    valueLabel
                                                                ],
                                                            ),
                                                    },
                                                ]}
                                            />
                                        )}
                                    </ListItem>
                                    <Divider
                                        variant="fullWidth"
                                        component="li"
                                    />
                                </Fragment>
                            ))}
                        </>
                    ) : (
                        !error.list && (
                            <ListItemText>
                                <Typography variant="caption1" color="primary">
                                    No {subtitle.toLowerCase()} registered yet.
                                </Typography>
                            </ListItemText>
                        )
                    ))}

                {!loading?.fetching && error.list && (
                    <Typography variant="caption1" color="error">
                        There was an unexpected error. SCP was unable to load{" "}
                        {subtitle.toLowerCase()}.
                    </Typography>
                )}
                {loading?.fetching && (
                    <ListItem>
                        <CircularProgress />
                    </ListItem>
                )}
            </List>
        </Grid>
    );
};
export default React.memo(MaintenanceList);
