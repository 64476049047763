// icon
import { KeyboardArrowDown } from "@mui/icons-material";

// Lib
import {
    Box,
    Typography,
    Grid,
    InputLabel,
    TextField,
    MenuItem,
    Select,
    FormHelperText,
} from "@mui/material";

import React, { useState, Fragment, useEffect } from "react";

// Own components
import { SearchBar, Table, Modal, LoadingWrapper } from "@components";

// custom hooks
import { useViewingOptions, useMaintenance } from "@hooks";

// Constants
import { HEADERS, ROWRENDERERCONST, CONTEXT_TYPES } from "@constants";

// Utils

import {
    apiResponseCounter,
    isArrayWithContent,
    isSuccessfulCall,
    trimAndFormat,
} from "@utils";

/**
 * Props type
 */
interface Props {
    id?: string;
}

const INITIAL_CONTEXT = {
    type: "",
    countryIsoCode: "",
    contextName: "",
};

type Context = {
    index?: number;
    type: string;
    contextId?: string;
    countryIsoCode: string;
    contextName: string;
};

/**
 * Maintenance Context
 */
const MaintenanceContext = ({ id }: Props) => {
    const [context, setContext] = useState<Context>(INITIAL_CONTEXT);
    const [openModal, toggleModal] = useState(false);
    const [touched, setTouched] = useState<any>({});
    const [validationMsg, setValidationMsg] = useState("");

    const {
        list: contextData,
        loading: { listLoading: fetching, creating, deleting },
        upsert,
        delete: deleteContext,
    } = useMaintenance("context", "context|contexts");

    const {
        list: countries,
        loading: { listLoading: fetchingCountries },
        reload: loadCountries,
    } = useMaintenance("country", "country|countries", false);

    /**
     * Custom Hooks
     */
    const { viewingOptions, setViewingOptions } = useViewingOptions(
        ROWRENDERERCONST.CONTEXT,
    );

    useEffect(() => {
        if (!openModal) return;
        loadCountries();
    }, [openModal]);

    /**
     * Reset all
     */
    const resetAll = () => {
        toggleModal(false);
        setContext(INITIAL_CONTEXT);
        setTouched({});
        setValidationMsg("");
    };

    /**
     * Change handler
     */
    const handleContextChange = (type: string, value: string) => {
        if (!type) return;
        const copyContext = { ...context };
        copyContext[type] = value;
        setContext(copyContext);
    };

    /**
     * Check if the form is valid
     */
    const checkFormValidity = () => {
        if (!isArrayWithContent(contextData?.maintenanceContextList)) {
            setValidationMsg("");
            return false; //is valid
        }

        let hasDuplicateValue = false;

        contextData?.maintenanceContextList.forEach((item, index) => {
            if (index === context?.index) return;

            if (
                item?.contextName?.toUpperCase() ===
                    context?.contextName.toUpperCase() &&
                item?.type === context?.type &&
                item?.country?.isoCode === context?.countryIsoCode
            ) {
                hasDuplicateValue = true;
            }
        });

        setValidationMsg(
            hasDuplicateValue
                ? "Name, type and country must be unique for each context"
                : "",
        );

        return hasDuplicateValue;
    };

    /**
     * Submit handler
     */

    const onSubmit = () => {
        const hasDuplication = checkFormValidity();

        if (hasDuplication) return;

        upsert(context, context.contextName).then(res => {
            if (isSuccessfulCall(res?.status)) {
                resetAll();
            }
        });
    };

    /**
     * validation handler
     */
    const hasError = (field: string) => {
        if (!context || !touched[field]) return;
        return !context?.[field] && touched?.[field];
    };

    /**
     * Render
     */
    return (
        <Fragment>
            <Modal
                id={`context-modal`}
                open={openModal}
                mediumView
                onClose={resetAll}
                title={context?.contextId ? "Edit context" : "Add new context"}
                primaryButton={{
                    action: () => onSubmit(),
                    text: context?.contextId ? "Save" : "Add",
                    loading: creating,
                    disabled:
                        !context?.contextName ||
                        !context?.countryIsoCode ||
                        !context.type ||
                        fetchingCountries,
                }}
                secondaryButton={{
                    action: () => resetAll(),
                    text: "Cancel",
                }}
            >
                <LoadingWrapper
                    id={`${id}-context-loading`}
                    loading={fetchingCountries}
                >
                    <Grid container item xs={12} spacing={3}>
                        <Grid item xs={4}>
                            <InputLabel
                                shrink
                                id={`context-name`}
                                error={
                                    !!hasError("contextName") || !!validationMsg
                                }
                            >
                                {"Name (*)"}
                            </InputLabel>
                            <TextField
                                id={`context-name`}
                                fullWidth
                                name={"code"}
                                onBlur={() => {
                                    if (touched?.contextName) return;
                                    setTouched(prevState => {
                                        return {
                                            ...prevState,
                                            contextName: true,
                                        };
                                    });
                                }}
                                error={
                                    !!hasError("contextName") || !!validationMsg
                                }
                                size="small"
                                value={context?.contextName}
                                disabled={creating}
                                onChange={event =>
                                    handleContextChange(
                                        "contextName",
                                        trimAndFormat(event.target.value, true),
                                    )
                                }
                                variant="outlined"
                            />
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <InputLabel
                                shrink
                                id={`type`}
                                error={!!hasError("type") || !!validationMsg}
                            >
                                {"Type (*)"}
                            </InputLabel>
                            <Select
                                id={`context-type`}
                                variant="outlined"
                                fullWidth
                                onBlur={() => {
                                    if (touched?.type) return;
                                    setTouched(prevState => {
                                        return {
                                            ...prevState,
                                            type: true,
                                        };
                                    });
                                }}
                                value={context?.type}
                                disabled={creating}
                                size="small"
                                error={!!hasError("type") || !!validationMsg}
                                onChange={event => {
                                    handleContextChange(
                                        "type",
                                        event.target.value,
                                    );
                                }}
                                IconComponent={props => (
                                    <KeyboardArrowDown {...props} />
                                )}
                            >
                                {CONTEXT_TYPES.map((type: any) => {
                                    return (
                                        <MenuItem key={type.id} value={type.id}>
                                            {type.label}
                                        </MenuItem>
                                    );
                                })}
                            </Select>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <InputLabel
                                shrink
                                id={`context-country-label`}
                                error={
                                    !!hasError("countryIsoCode") ||
                                    !!validationMsg
                                }
                            >
                                {"Country (*)"}
                            </InputLabel>
                            <Select
                                id={`context-country-select`}
                                variant="outlined"
                                fullWidth
                                onBlur={() => {
                                    if (touched?.countryIsoCode) return;
                                    setTouched(prevState => {
                                        return {
                                            ...prevState,
                                            countryIsoCode: true,
                                        };
                                    });
                                }}
                                value={context?.countryIsoCode}
                                disabled={creating}
                                error={
                                    !!hasError("countryIsoCode") ||
                                    !!validationMsg
                                }
                                size="small"
                                onChange={event => {
                                    handleContextChange(
                                        "countryIsoCode",
                                        event.target.value,
                                    );
                                }}
                                IconComponent={props => (
                                    <KeyboardArrowDown {...props} />
                                )}
                            >
                                {countries?.maintenanceCountryList?.map(
                                    (item: any) => {
                                        return (
                                            <MenuItem
                                                key={item.countryIsoCode}
                                                value={item.countryIsoCode}
                                            >
                                                {item.country}
                                            </MenuItem>
                                        );
                                    },
                                )}
                            </Select>
                        </Grid>
                        <Grid item xs={12}>
                            {!!validationMsg && (
                                <FormHelperText error>
                                    {validationMsg}
                                </FormHelperText>
                            )}
                        </Grid>
                    </Grid>
                </LoadingWrapper>
            </Modal>
            <Box mt={2} mb={4} display="flex" justifyContent="flex-end">
                <SearchBar
                    id="context-action"
                    filterKey={ROWRENDERERCONST.CONTEXT}
                    setViewingOptions={setViewingOptions}
                    viewingOptions={viewingOptions}
                    action={{
                        label: "Add new context",
                        callback: () => {
                            toggleModal(true);
                            setContext(INITIAL_CONTEXT);
                        },
                        disabled: false,
                    }}
                    hasFilters={false}
                />
            </Box>
            <Box mt={5}>
                <Box display="flex" alignItems="baseline" mb={4}>
                    <Typography variant="h2">Context</Typography>

                    <Typography ml={1} variant="caption1">
                        {apiResponseCounter(
                            {
                                data: contextData?.maintenanceContextList,
                                status: 200,
                            },
                            fetching,
                            "context|contexts",
                        )}
                    </Typography>
                </Box>
                <Table
                    id="context-list"
                    headers={HEADERS.CONTEXT}
                    rows={contextData?.maintenanceContextList}
                    loading={fetching}
                    type={ROWRENDERERCONST.CONTEXT}
                    viewingOptions={viewingOptions}
                    callbacks={{
                        onContextDelete: item => {
                            deleteContext(item?.contextId, item?.contextName);
                        },
                        onContextEdit: (item, index) => {
                            toggleModal(true);
                            const selectedContext = { ...item, index };
                            selectedContext.countryIsoCode =
                                selectedContext?.country?.isoCode;
                            delete selectedContext.country;
                            setContext(selectedContext);
                        },
                    }}
                    disabled={deleting}
                    emptyMsg="No contexts found!"
                />
            </Box>
        </Fragment>
    );
};
export default MaintenanceContext;
