//import libs
import { Radio, TableCell, TableRow, Typography, Tooltip } from "@mui/material";
import React, { useMemo } from "react";

//utils
import { displayDate, isArrayWithContent, truncateArray } from "@utils";

//style
import * as style from "./style.module.scss";
import { Contract } from "@types";

interface Props {
    row: Contract & {
        brands: Array<{ name: string; id: string }>;
        categories: Array<{ categoryLabel: string; categoryValue: string }>;
    };
    rowIndex: number;
    id: string;
    callbacks: any;
    selectedRow: string;
    disabled?: boolean;
}

const ClaimContractSelectionRow = ({
    row,
    rowIndex,
    id,
    callbacks,
    selectedRow,
    disabled,
}: Props) => {
    /**
     * Render Categories col
     */
    const mappedCategories = useMemo(() => {
        if (!isArrayWithContent(row?.categories)) return [];

        return row?.categories?.map(el => {
            return `${el.categoryLabel}: ${el.categoryValue}`;
        });
    }, [row?.categories]);

    /**
     * Render
     */
    return (
        <TableRow
            className={style.cursor}
            selected={selectedRow === row.id}
            id={`${id}-row-${rowIndex}`}
            onClick={event => {
                if (!disabled) {
                    callbacks.onSelect(
                        row,
                        parseInt((event.target as HTMLInputElement).value),
                    );
                }
            }}
        >
            <TableCell width="4.6875rem">
                <Radio
                    onChange={event => {
                        callbacks.onSelect(row, parseInt(event.target.value));
                    }}
                    checked={selectedRow === row.id}
                    value={rowIndex}
                    inputProps={{ "aria-label": `${id}-row-${rowIndex}` }}
                    required
                    id={`${id}-row-${rowIndex}-outcome-contract`}
                    disabled={disabled}
                />
            </TableCell>
            <TableCell>
                <Typography variant="caption2">{row.reference}</Typography>
            </TableCell>

            <TableCell>
                <Typography variant="caption2">
                    {displayDate(row.startDate)}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography variant="caption2">
                    {displayDate(row.endDate)}
                </Typography>
            </TableCell>
            <TableCell>
                {isArrayWithContent(row.brands) ? (
                    <Tooltip
                        arrow
                        placement="top"
                        title={truncateArray(row.brands, undefined, "name")}
                    >
                        <Typography variant="caption2">
                            {truncateArray(row.brands, 2, "name")}
                        </Typography>
                    </Tooltip>
                ) : (
                    "-"
                )}
            </TableCell>
            <TableCell>
                <Typography variant="caption2">
                    {row?.primaryContractPartner?.name}
                </Typography>
            </TableCell>
            <TableCell>
                {isArrayWithContent(row.categories) ? (
                    <Tooltip
                        arrow
                        placement="top"
                        title={truncateArray(mappedCategories, undefined)}
                    >
                        <Typography variant="caption2">
                            {truncateArray(mappedCategories, 2)}
                        </Typography>
                    </Tooltip>
                ) : (
                    "-"
                )}
            </TableCell>
        </TableRow>
    );
};

export default ClaimContractSelectionRow;
