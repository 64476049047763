// Libs
import { TableCell, TableRow, Typography, Box } from "@mui/material";
import React from "react";

// Types
import { Brand } from "@types";

// Utils
import { isArrayWithContent } from "@utils";

// Own  components
import { Select, ActionMenu } from "@components";

interface Row {
    productName: string;
    productId: string;
    salesOrg: string;
    sapSkuNo: string;
    brandName: string;
    brandId: string;
    active: boolean;
    country: { isoCode: string; name: string };
}

/**
 * Props type
 */
interface Props {
    row: Row;
    rowIndex: number;
    callbacks: any;
    id: string;
    isColVisible: (col: string) => boolean;
    brands?: Array<Brand>;
    permissions?: any;
}

/**
 * Products
 */
const Products = ({
    id,
    row,
    rowIndex,
    callbacks,
    isColVisible,
    brands,
    permissions,
}: Props) => {
    return (
        <TableRow id={`${id}-row-${rowIndex}`}>
            {isColVisible("productName") && (
                <TableCell>
                    <Typography variant="caption2">
                        {row.productName}
                    </Typography>
                </TableCell>
            )}

            {isColVisible("salesOrg") && (
                <TableCell>
                    <Typography variant="caption2">{row.salesOrg}</Typography>
                </TableCell>
            )}
            {isColVisible("sapSkuNo") && (
                <TableCell>
                    <Typography variant="caption2">{row.sapSkuNo}</Typography>
                </TableCell>
            )}
            {isColVisible("brandName") && (
                <TableCell>
                    {!callbacks.onEditProduct ||
                    !permissions?.hasPermissionToEditDataTables ? (
                        <Typography variant="caption2" pl={2}>
                            {row.brandName}
                        </Typography>
                    ) : (
                        <Select
                            id={`${id}-row-${rowIndex}-product-brand`}
                            value={
                                row.brandId && row.brandName
                                    ? {
                                          brandId: row.brandId,
                                          brandName: row.brandName,
                                      }
                                    : undefined
                            }
                            onChange={(_, index) => {
                                callbacks.onEditProduct &&
                                    callbacks.onEditProduct(
                                        row.productId,
                                        !!brands && isArrayWithContent(brands)
                                            ? brands[index]
                                            : undefined,
                                    );
                            }}
                            menuItemLabel={"brandName"}
                            menuItemId={"brandId"}
                            name="brandId"
                            list={brands as Brand[]}
                        />
                    )}
                </TableCell>
            )}

            {isColVisible("status") && (
                <TableCell>
                    <Typography variant="caption2">
                        {row.active ? "Active" : "Inactive"}
                    </Typography>
                </TableCell>
            )}

            {isColVisible("country") && (
                <TableCell>
                    <Typography variant="caption2">
                        {row.country?.name}
                    </Typography>
                </TableCell>
            )}

            <TableCell align="right">
                {permissions && permissions.hasPermissionToEditDataTables && (
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-end"
                    >
                        <ActionMenu
                            id={`${id}-row-${rowIndex}-product`}
                            autoHide
                            options={[
                                {
                                    label: "Show price table",
                                    action: () =>
                                        callbacks?.onPriceTableClick
                                            ? callbacks.onPriceTableClick(
                                                  row,
                                                  rowIndex,
                                              )
                                            : undefined,
                                },
                                {
                                    label: "External codes",
                                    disabled: !callbacks?.onExternalCodesEdit,
                                    action: () =>
                                        callbacks &&
                                        callbacks.onExternalCodesEdit(
                                            row,
                                            rowIndex,
                                        ),
                                },
                            ]}
                            rowIndex={rowIndex}
                        />
                    </Box>
                )}
            </TableCell>
        </TableRow>
    );
};

export default Products;
