import clonedeep from "lodash.clonedeep";
import { useRecoilState } from "recoil";

import type { Location } from "@types";
import { useFindStep } from "./useFindStep";
import { isArrayWithContent } from "@utils";

export const useUpdateStep = (
    location: Location | undefined,
    stepState: any,
) => {
    const [steps, setSteps] = useRecoilState(stepState);
    const { currentIndex, currentStep } = useFindStep(location, stepState);

    const updateStepValidation = (canSaveState: boolean) => {
        const updatedSteps = clonedeep(steps);
        if (!isArrayWithContent(updatedSteps) || currentIndex < 0) return;

        if (!currentStep?.isPrepared && !canSaveState) return;

        // isPrepared should be false => not valid
        if (currentStep?.isPrepared && !canSaveState) {
            updatedSteps[currentIndex].isPrepared = false;
            //updatedSteps[currentIndex].isCompleted = false;
            setSteps(updatedSteps);
        }

        //isPrepared should be true
        if (canSaveState) {
            updatedSteps[currentIndex].isPrepared = true;
            setSteps(updatedSteps);
        }
    };

    return updateStepValidation;
};
