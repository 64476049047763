// lib
import { Grid } from "@mui/material";
import React from "react";

interface Props {
    children: React.ReactNode;
    fullWidth?: boolean;
}

const Component = ({ children, fullWidth }: any) =>
    fullWidth ? (
        <Grid container item xs={12}>
            {children}
        </Grid>
    ) : (
        <Grid container item xs={12} md={8} mt={6}>
            {children}
        </Grid>
    );

/**
 * FlowBody
 */
const FlowBody: React.FC<Props> = ({ children, fullWidth }) => {
    /**
     * Render
     */
    return <Component fullWidth={fullWidth}>{children}</Component>;
};
export default React.memo(FlowBody);
