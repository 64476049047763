// Icons
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import AddIcon from "@mui/icons-material/Add";

// Lib
import clonedeep from "lodash.clonedeep";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import { DateTime } from "luxon";
import Button from "@mui/material/Button";
import React, { Fragment, useEffect, useMemo, useState } from "react";

// Utils
import {
    calculateAllowedDateRanges,
    generateQuarters,
    isArrayWithContent,
} from "@utils";

// Own components
import { Table, Select as _Select, Dialog } from "@components";

// Constants
import { ROWRENDERERCONST, HEADERS } from "@constants";

// Style
import * as style from "../style.module.scss";

// Types
import { AdvancedScale } from "@types";
import ScalePeriodSelector from "./ScalePeriodSelector";
import CumulativeScale from "./CumulativeScale";

/**
 * Props type
 */
type Props = {
    id: string;
    scales: AdvancedScale[];
    handleScalesChange?: (value: AdvancedScale[]) => void;
    allowedScaleRange?: { periodFrom: string; periodTo: string };
    categoryType: string;
    currency: string;
};

const CUMULATIVE_PERIOD_DURATION = [
    /*  { label: "Contract", value: "CONTRACT" },
    { label: "Month", value: "MONTH" }, */
    { label: "Quarter", value: "QUARTER" },
    /* { label: "Claim", value: "CLAIM" }, */
];

const UNIT_OF_MEASURE = [
    { label: "Volume", value: "VOLUME" },
    { label: "Value", value: "VALUE" },
];

/**
 * Advanced Product scales
 */
const AdvancedProductScales = ({
    id,
    scales,
    handleScalesChange,
    allowedScaleRange,
    categoryType,
    currency,
}: Props) => {
    const [scaleToRemove, setScaleToRemove] = useState<string[] | undefined>(
        undefined,
    );
    /**
     * Check the type of the contract
     */
    const { scaleType, scaleTypeLabel } = useMemo(() => {
        return {
            scaleTypeLabel:
                categoryType === "discountPercentage" ? "Discount" : "Price",
            scaleType:
                categoryType === "discountPercentage"
                    ? "discountPercentage"
                    : "price",
        };
    }, [categoryType]);

    // Cumulative info
    const [cumulativeInfo, setCumulativeInfo] = useState({
        unitOfMeasure: "VOLUME",
        cumulativePeriodLength: "1",
        cumulativePeriodDuration: "QUARTER",
    });

    // Scale period state
    const [showScalePeriod, setScalePeriod] = useState<boolean>(false);

    // Cumulative scale state
    const [scalePeriodRange, setCumulativeScale] = useState<
        string[] | undefined
    >(undefined);

    useEffect(() => {
        if (scales) {
            setCumulativeInfo({
                unitOfMeasure: scales[0]?.unitOfMeasure,
                cumulativePeriodLength: scales[0]?.cumulativePeriodLength,
                cumulativePeriodDuration: scales[0]?.cumulativePeriodDuration,
            });
        }
    }, [scales]);

    /**
     * Update scale handler
     */
    const addScale = (updates: { [key: string]: any }) => {
        const copyScale = clonedeep(scales);
        copyScale.push({
            ...cumulativeInfo,
            ...updates,
            unitOfMeasureFrom: "",
            unitOfMeasureTo: "",
            cumulativePeriods: [
                {
                    cumulativePeriodFrom: "",
                    cumulativePeriodTo: "",
                },
            ],

            [scaleType]: "",
        });

        !!handleScalesChange && handleScalesChange(copyScale);
        setScalePeriod(false);
    };

    /**
     * Cumulative period info getter
     */
    const cumulativePeriodInfoGetter = useMemo(() => {
        if (!cumulativeInfo) return;

        const cumulativePeriodDuration = CUMULATIVE_PERIOD_DURATION.find(
            item => item.value === cumulativeInfo?.cumulativePeriodDuration,
        );

        const cumulativePeriodLength = cumulativeInfo?.cumulativePeriodLength;
        const unitOfMeasure = UNIT_OF_MEASURE.find(
            item => item.value === cumulativeInfo?.unitOfMeasure,
        );

        return {
            cumulativePeriodDuration,
            cumulativePeriodLength,
            unitOfMeasure,
        };
    }, [cumulativeInfo]);

    /**
     * Handle generate quarters
     */
    const handleGenerateQuarters = unitsOfMeasureData => {
        if (!scalePeriodRange) return;
        const [periodFrom, periodTo] = scalePeriodRange || [];

        const quarters = generateQuarters(
            periodFrom,
            periodTo,
            "cumulativePeriodFrom",
            "cumulativePeriodTo",
            +cumulativeInfo?.cumulativePeriodLength,
        );

        if (!isArrayWithContent(quarters)) return;

        const copyScale = clonedeep(scales);
        if (
            !copyScale[copyScale.length - 1] ||
            (!!copyScale[copyScale.length - 1] &&
                copyScale[copyScale.length - 1].cumulativePeriods.some(
                    period =>
                        !period.cumulativePeriodFrom ||
                        !period.cumulativePeriodTo,
                ))
        ) {
            copyScale[copyScale.length - 1] = {
                ...copyScale[copyScale.length - 1],
                ...unitsOfMeasureData,
                cumulativePeriods: quarters,
            };
        } else {
            copyScale.push({
                ...copyScale[copyScale.length - 1],
                periodFrom: scalePeriodRange[0],
                periodTo: scalePeriodRange[1],
                ...unitsOfMeasureData,
                cumulativePeriods: quarters,
            });
        }

        !!handleScalesChange && handleScalesChange(copyScale);
        setCumulativeScale(undefined);
    };

    /**
     * Map cumulative scale row data
     */
    const mapCumulativeScaleRowData = useMemo(() => {
        if (!isArrayWithContent(scales)) return [];

        const groupedArray = Object.values(
            scales.reduce(
                (acc, scale) => {
                    const key = `${scale.periodFrom}|${scale.periodTo}`;

                    if (!acc[key]) {
                        acc[key] = {
                            periodFrom: scale.periodFrom,
                            periodTo: scale.periodTo,
                            data: [],
                        };
                    }

                    (scale as AdvancedScale).cumulativePeriods.forEach(
                        period => {
                            acc[key].data.push({
                                cumulativePeriodFrom:
                                    period.cumulativePeriodFrom,
                                cumulativePeriodTo: period.cumulativePeriodTo,
                                unitOfMeasureFrom: scale.unitOfMeasureFrom,
                                unitOfMeasureTo: scale.unitOfMeasureTo,
                                discountPercentage:
                                    scale.discountPercentage as string,
                                price: scale.price as string,
                                currency: currency,
                            });
                        },
                    );

                    return acc;
                },
                {} as Record<
                    string,
                    {
                        periodFrom: string;
                        periodTo: string;
                        data: {
                            cumulativePeriodFrom: string;
                            cumulativePeriodTo: string;
                            unitOfMeasureFrom: string;
                            unitOfMeasureTo: string;
                            discountPercentage: string;
                            price: string;
                            currency: string;
                        }[];
                    }
                >,
            ),
        );

        // Sort the outer array by periodFrom and periodTo
        groupedArray.sort((a, b) => {
            const dateAFrom = DateTime.fromISO(a.periodFrom);
            const dateBFrom = DateTime.fromISO(b.periodFrom);
            const dateATo = DateTime.fromISO(a.periodTo);
            const dateBTo = DateTime.fromISO(b.periodTo);

            // Sort by periodFrom first
            if (dateAFrom.valueOf() !== dateBFrom.valueOf()) {
                return dateAFrom.valueOf() - dateBFrom.valueOf();
            }

            // If periodFrom is the same, sort by periodTo
            return dateATo.valueOf() - dateBTo.valueOf();
        });

        // Sort data within each group by cumulativePeriodFrom and cumulativePeriodTo
        groupedArray.forEach(group => {
            group.data.sort((a, b) => {
                const dateAFrom = DateTime.fromISO(a.cumulativePeriodFrom);
                const dateBFrom = DateTime.fromISO(b.cumulativePeriodFrom);
                const dateATo = DateTime.fromISO(a.cumulativePeriodTo);
                const dateBTo = DateTime.fromISO(b.cumulativePeriodTo);

                // Sort by cumulativePeriodFrom first
                if (dateAFrom.valueOf() !== dateBFrom.valueOf()) {
                    return dateAFrom.valueOf() - dateBFrom.valueOf();
                }

                // If cumulativePeriodFrom is the same, sort by cumulativePeriodTo
                return dateATo.valueOf() - dateBTo.valueOf();
            });
        });

        return groupedArray;
    }, [scales]);

    const validateAllowedScaleRange = useMemo(() => {
        if (!allowedScaleRange) return { periodFrom: "", periodTo: "" };

        if (!scales[scales.length - 1]) return allowedScaleRange;

        const allowedDateRange = calculateAllowedDateRanges(
            allowedScaleRange,
            scales,
        );

        return allowedDateRange;
    }, [allowedScaleRange, scales]);

    /**
     * Disable add scale period
     */
    const disableAddScalePeriod = useMemo(() => {
        if (
            !scales[scales.length - 1] ||
            !isArrayWithContent(scales[scales.length - 1]?.cumulativePeriods)
        )
            return false;

        return scales[scales.length - 1]?.cumulativePeriods.some(
            period =>
                period.cumulativePeriodFrom === "" ||
                period.cumulativePeriodTo === "",
        );
    }, [scales]);

    /**
     * Find cumulative uom ranges per scale period
     */
    const cumulativeUomRanges = useMemo(() => {
        if (!scalePeriodRange) return [];

        const scalesCopy = clonedeep(scales);
        return scalesCopy.filter(
            ({ periodFrom, periodTo }) =>
                periodFrom === scalePeriodRange[0] &&
                periodTo === scalePeriodRange[1],
        );
    }, [scalePeriodRange, scales]);

    /**
     * Delete Scale
     */
    const onScaleDelete = () => {
        if (!scaleToRemove || !isArrayWithContent(scaleToRemove)) return;

        const copyScale = clonedeep(scales);

        const filteredScales = copyScale.filter(
            scale =>
                scale.periodFrom !== scaleToRemove[0] &&
                scale.periodTo !== scaleToRemove[1],
        );

        !!handleScalesChange && handleScalesChange(filteredScales);
        setScaleToRemove(undefined);
    };

    return (
        <Fragment>
            <Dialog
                id={`${id}-delete-scale`}
                open={!!scaleToRemove}
                message={
                    <Typography
                        variant="subtitle2"
                        color="black"
                        component="span"
                    >
                        This will remove the cumulative scales for this scale
                        period, are you sure you want to do this?
                    </Typography>
                }
                primaryButton={{
                    text: "Remove scales",
                    action: () => onScaleDelete(),
                }}
                secondaryButton={{
                    text: "Cancel",
                    action: () => setScaleToRemove(undefined),
                }}
            />
            <Grid
                item
                container
                xs={12}
                justifyContent={"space-between"}
                spacing={3}
            >
                <Grid item xs={6}>
                    <InputLabel
                        id={`${id}-${"cumulativePeriodDuration-label"}`}
                        shrink
                    >
                        {"Cumulative period duration(*)"}
                    </InputLabel>
                    <_Select
                        id={`${id}-${"cumulativePeriodDuration"}`}
                        value={
                            cumulativePeriodInfoGetter?.cumulativePeriodDuration
                        }
                        fullWidth
                        onChange={(_, index) =>
                            setCumulativeInfo(prev => ({
                                ...prev,
                                cumulativePeriodDuration:
                                    CUMULATIVE_PERIOD_DURATION[index]?.value,
                            }))
                        }
                        menuItemLabel={"label"}
                        menuItemId="value"
                        list={CUMULATIVE_PERIOD_DURATION}
                        loading={false}
                        name="cumulativePeriodDuration"
                        size="small"
                        className={style.whiteInput}
                        disabled={isArrayWithContent(scales)}
                    />
                </Grid>
                <Grid item xs={6}>
                    <InputLabel
                        id={`${id}-${"cumulativePeriodLength-label"}`}
                        shrink
                    >
                        {"Cumulative period length(*)"}
                    </InputLabel>
                    <Select
                        variant="outlined"
                        name={"cumulativePeriodLength"}
                        id={`${id}-${"cumulativePeriodLength"}`}
                        className={style.whiteInput}
                        disabled={isArrayWithContent(scales)}
                        fullWidth
                        size="small"
                        value={
                            cumulativePeriodInfoGetter?.cumulativePeriodLength
                        }
                        onChange={e =>
                            setCumulativeInfo(prev => ({
                                ...prev,
                                cumulativePeriodLength: e.target.value,
                            }))
                        }
                        IconComponent={props => (
                            <KeyboardArrowDownIcon {...props} />
                        )}
                    >
                        {["1", "2", "3", "4"].map((item, index) => (
                            <MenuItem key={index} value={item}>
                                {item}
                            </MenuItem>
                        ))}
                    </Select>
                </Grid>

                <Grid item xs={6}>
                    <InputLabel id={`${id}-${"unitOfMeasure-label"}`} shrink>
                        {"Unit of measure (*)"}
                    </InputLabel>

                    <_Select
                        id={`${id}-${"unitOfMeasure"}`}
                        disabled={isArrayWithContent(scales)}
                        value={cumulativePeriodInfoGetter?.unitOfMeasure}
                        fullWidth
                        onChange={(_, index) =>
                            setCumulativeInfo(prev => ({
                                ...prev,
                                unitOfMeasure: UNIT_OF_MEASURE[index]?.value,
                            }))
                        }
                        menuItemLabel={"label"}
                        menuItemId="value"
                        list={UNIT_OF_MEASURE}
                        loading={false}
                        name="unitOfMeasure"
                        size="small"
                        className={style.whiteInput}
                    />
                </Grid>
                <Grid item container justifyContent={"flex-end"} xs={6}>
                    <Button
                        id={`add-new scale`}
                        variant="text"
                        startIcon={<AddIcon />}
                        color="primary"
                        onClick={() => setScalePeriod(true)}
                        disabled={
                            disableAddScalePeriod ||
                            !cumulativePeriodInfoGetter?.cumulativePeriodLength ||
                            !cumulativePeriodInfoGetter?.cumulativePeriodDuration ||
                            !cumulativePeriodInfoGetter?.unitOfMeasure
                        }
                    >
                        Add scale period
                    </Button>
                </Grid>
                <Box mt={3} overflow={"scroll"} mx={3}>
                    <Table
                        headers={HEADERS.ADVANCED_SCALES_WITH_ACTION.filter(
                            item => {
                                if (
                                    item.rowKey !== "discountPercentage" &&
                                    item.rowKey !== "price"
                                ) {
                                    return true;
                                }

                                return item.rowKey === scaleType;
                            },
                        )}
                        rows={mapCumulativeScaleRowData}
                        loading={false}
                        maxHeight="30rem"
                        type={ROWRENDERERCONST.ADVANCED_SCALES_WITH_ACTION}
                        id={`product-scales-table`}
                        emptyMsg={"No scales found"}
                        minWidth="unset"
                        callbacks={{
                            onAddCumulativeScale: (periodRange: string[]) =>
                                setCumulativeScale(periodRange),
                            onScaleDelete: periodRange =>
                                setScaleToRemove(periodRange),
                        }}
                        size="small"
                    />
                </Box>
            </Grid>

            <ScalePeriodSelector
                open={showScalePeriod}
                id={`product-scale-period-modal`}
                onClose={() => {
                    setScalePeriod(false);
                }}
                onSubmit={(periodFrom, periodTo) => {
                    addScale({
                        periodFrom,
                        periodTo,
                    });
                }}
                scaleDateRange={allowedScaleRange}
                validateAllowedScaleRange={validateAllowedScaleRange}
            />

            <CumulativeScale
                id={`product-scale-cumulative-modal`}
                open={!!scalePeriodRange}
                onClose={() => {
                    setCumulativeScale(undefined);
                }}
                onSubmit={unitsOfMeasureData => {
                    handleGenerateQuarters(unitsOfMeasureData);
                }}
                scaleType={scaleType}
                currency={currency}
                scaleTypeLabel={scaleTypeLabel}
                cumulativeUomRanges={cumulativeUomRanges}
            />
        </Fragment>
    );
};

export default React.memo(AdvancedProductScales);
