import { createTheme } from "@mui/material";
import { TypographyOptions } from "@mui/material/styles/createTypography";
import React from "react";

const theme = createTheme({
    typography: {
        fontFamily: ["Karbon", "Verdana Pro"].join(","),
        h1: {
            fontSize: "2rem",
            lineHeight: "38px",
            color: "#036",
            fontFamily: "Karbon",
            fontStyle: "normal",
            fontWeight: 700,
        },
        h2: {
            fontSize: "1.75rem",
            lineHeight: "34px",
            color: "#036",
            fontFamily: "Karbon",
            fontStyle: "normal",
            fontWeight: 700,
        },
        h3: {
            fontSize: "1.25rem",
            lineHeight: "24px",
            color: "#036",
            fontFamily: "Karbon",
            fontStyle: "normal",
            fontWeight: 700,
        },
        h4: {
            fontSize: "1.125rem",
            lineHeight: "22px",
            color: "#036",
            fontFamily: "Karbon",
            fontStyle: "normal",
            fontWeight: 700,
        },
        h5: {
            fontSize: "1.0625rem",
            color: "#036",
            fontFamily: "Karbon",
            fontStyle: "normal",
            fontWeight: 700,
        },
        h6: {
            fontSize: "1.0625rem",
            fontFamily: "Karbon",
            fontStyle: "normal",
            fontWeight: 500,
            color: "#036",
        },
        subtitle1: {
            fontSize: "0.8rem",
            lineHeight: 1.5,
            fontWeight: 400,
            color: "#036",
        },
        mediumSubtitle1: {
            fontSize: "0.8rem",
            lineHeight: 1.5,
            fontWeight: 500,
            color: "#036",
        },
        subtitle2: {
            fontSize: "0.875rem",
            lineHeight: 1.5,
            fontFamily: "Verdana Pro",
            fontWeight: 400,
        },
        body1: {
            fontSize: "1rem",
            lineHeight: 1.5,
            fontWeight: "normal",
            color: "#036",
            fontFamily: "Verdana Pro",
        },
        bodyDark: {
            fontSize: "1rem",
            lineHeight: 1.5,
            fontWeight: 700,
            color: "#036",
            fontFamily: "Verdana Pro",
        },
        body2: {
            fontSize: "0.8rem",
            lineHeight: 1.5,
            fontWeight: "normal",
            color: "#036",
            fontFamily: "Verdana Pro",
        },
        body2Dark: {
            fontSize: "0.8rem",
            lineHeight: 1.5,
            color: "#036",
            fontWeight: 600,
            fontFamily: "Verdana Pro",
        },
        button: {
            fontSize: "0.75rem",
            fontWeight: 400,
            lineHeight: 1.2,
            fontFamily: "Verdana Pro",
            textTransform: "initial",
        },
        captionSmall: {
            fontSize: "0.625rem",
            lineHeight: "12px",
            fontWeight: 400,
            color: "#000",
            fontFamily: "Verdana Pro",
        },
        caption1: {
            fontSize: "0.75rem",
            lineHeight: 1.2,
            fontWeight: 400,
            color: "#000",
            fontFamily: "Verdana Pro",
        },
        caption1Dark: {
            fontSize: "0.75rem",
            lineHeight: 1.2,
            fontWeight: 600,
            color: "#000",
            fontFamily: "Verdana Pro",
        },
        caption2: {
            fontSize: "0.75rem",
            lineHeight: 1.2,
            fontWeight: 300,
            color: "#000",
            fontFamily: "Verdana Pro",
        },
        link: {
            cursor: "pointer",
            color: "#019cdc",
            textDecoration: "underline",
        },
        chip: {
            fontSize: "0.625rem",
            lineHeight: 1.2,
            fontWeight: 400,
            color: "#333333",
            fontFamily: "Verdana Pro",
        },
    } as ExtendedTypographyOptions,
    palette: {
        primary: {
            main: "#036",
            light: "#BFCCDD",
        },
        secondary: {
            main: "#019cdc",
        },
        action: {
            disabled: "#9CA6C6",
        },
        white: {
            main: "#fff",
        },
        light: {
            main: "#e4e4e4",
            dark: "#c8c9cb",
        },
        dark: {
            main: "#000",
            light: "#222",
        },
        success: {
            main: "#29eb8e",
        },
        error: {
            main: "#ff3131",
        },
        info: {
            main: "#127fa4",
        },
        warning: {
            main: "#f37820",
        },
        text: {
            primary: "#036",
            disabled: "#a0a9ba",
            secondary: "#858585;",
        },
    },
    components: {
        MuiTypography: {
            defaultProps: {
                variantMapping: {
                    h1: "h1",
                    h2: "h2",
                    h3: "h3",
                    h4: "h4",
                    h5: "h5",
                    h6: "h6",
                    subtitle1: "h6",
                    subtitle2: "h6",
                    body1: "p",
                    body2: "p",
                    inherit: "p",
                    mediumSubtitle1: "p",
                    bodyDark: "p",
                    body2Dark: "p",
                    button: "span",
                    caption1: "span",
                    caption1Dark: "span",
                    caption2: "span",
                    link: "span",
                },
            },
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    fontWeight: 700,
                    fontSize: "1.125rem",
                    lineHeight: "1.37rem",
                },
            },
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    padding: "15px",
                    fontSize: "0.75rem",
                    color: "#222222",
                    backgroundColor: "#fff",
                    border: "0.5px solid #036",
                    boxShadow: "0px 4px 14px  rgba(0, 0, 0, 0.07)",
                    borderRadius: "4px",
                    fontWeight: "400",
                    fontFamily: "Verdana Pro",
                },
                arrow: {
                    "&:before": {
                        border: "0.5px solid #036",
                        backgroundColor: "#fff",
                    },
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: "initial",
                    fontFamily: "Verdana Pro",
                    "&.MuiButton-containedPrimary": {
                        "&.Mui-disabled": {
                            backgroundColor: "#9CA6C6",
                            color: "#ffffff",
                        },
                    },
                },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    fieldset: {
                        borderColor: "#BFCCDD",
                        fontSize: "12px",
                        color: "#222222",
                    },
                    "&.Mui-error": {
                        fieldset: {
                            borderLeftWidth: "thick",
                            borderTopLeftRadius: "7px",
                            borderBottomLeftRadius: "7px",
                        },
                    },
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    fontSize: "0.75rem",
                    lineHeight: 1.2,
                    fontWeight: 400,
                    color: "#000",
                },
            },
        },
        MuiSelect: {
            styleOverrides: {
                root: {
                    "&.MuiInputBase": {
                        color: "#222222",
                        fontSize: "12px",
                        lineHeight: "15px",
                    },
                    "&.Mui-disabled": {
                        textFillColor: "#222222",
                        color: "#222222",
                    },
                },
            },
        },
        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    "&.MuiSelect-iconOutlined": {
                        fill: "#003366",
                        opacity: "0.5",
                    },
                },
            },
        },

        MuiInputLabel: {
            styleOverrides: {
                root: {
                    color: "#858585",
                },
            },
        },
        MuiAutocomplete: {
            styleOverrides: {
                root: {
                    "& input::placeholder": {
                        color: "#858585",
                        opacity: "1",
                    },
                },
            },
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    borderBottom: "0.25px solid #9a9a9a",
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    color: "#222222",
                    fontSize: "12px",
                    lineHeight: "15px",
                },
            },
        },
        MuiAlert: {
            styleOverrides: {
                root: {
                    color: "#222222",
                },
            },
        },
        /*  multilineColor: {
            color: "red",
        }, */
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    fontSize: "0.9rem",
                },
            },
        },
    },
    //        0, 1, 2,   3,  4,  5,  6,  7, 8
    spacing: [4, 8, 12, 16, 24, 32, 40, 64, 80, 96, 112, 128], // spacing 0 = 4, 1 = 8 etc...
});

declare module "@mui/material/styles/createPalette" {
    interface PaletteOptions {
        white?: PaletteColorOptions;
        light?: PaletteColorOptions;
        dark?: PaletteColorOptions;
    }
}
declare module "@mui/material/styles" {
    interface TypographyVariantsOptions {
        mediumSubtitle1: React.CSSProperties;
        bodyDark: React.CSSProperties;
        body2Dark: React.CSSProperties;
        button: React.CSSProperties;
        caption1: React.CSSProperties;
        caption1Dark: React.CSSProperties;
        caption2: React.CSSProperties;
        link: React.CSSProperties;
        chip: React.CSSProperties;
        captionSmall: React.CSSProperties;
    }
}
declare module "@mui/material/Typography" {
    interface TypographyPropsVariantOverrides {
        mediumSubtitle1: true;
        bodyDark: true;
        body2Dark: true;
        button: true;
        caption1: true;
        caption1Dark: true;
        caption2: true;
        link: true;
        chip: true;
        captionSmall: true;
    }
}

interface ExtendedTypographyOptions extends TypographyOptions {
    mediumSubtitle1: React.CSSProperties;
    bodyDark: React.CSSProperties;
    body2Dark: React.CSSProperties;
    button: React.CSSProperties;
    caption1: React.CSSProperties;
    caption1Dark: React.CSSProperties;
    caption2: React.CSSProperties;
    link: React.CSSProperties;
    chip: React.CSSProperties;
    captionSmall: React.CSSProperties;
}
/*  */
export default theme;
