//import libs
import { Box, TableCell, TableRow, Typography } from "@mui/material";
import React from "react";

// import own components
import { ActionMenu } from "@components";

interface Row {
    financialConditionTypeId?: string;
    name?: string;
    description?: string;
    camundaDecisionTableId?: string;
}

interface Props {
    row: Row;
    rowIndex: number;
    id: string;
    isColVisible: (col: string) => boolean;
    callbacks: any;
}

const MaintenanceFinancialConditions = ({
    id,
    row,
    rowIndex,
    callbacks,
}: Props) => {
    return (
        <TableRow>
            <TableCell>
                <Typography variant="caption2">{row.name}</Typography>
            </TableCell>

            <TableCell>
                <Typography variant="caption2">{row.description}</Typography>
            </TableCell>
            <TableCell>
                <Typography variant="caption2">
                    {row.camundaDecisionTableId}
                </Typography>
            </TableCell>

            <TableCell align="right">
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                >
                    <ActionMenu
                        id={`${id}-${rowIndex}-maintenance-financial-conditions"`}
                        autoHide
                        options={[
                            {
                                label: "Edit",
                                action: () =>
                                    callbacks && callbacks?.onEdit(rowIndex),
                            },
                        ]}
                        rowIndex={rowIndex}
                    />
                </Box>
            </TableCell>
        </TableRow>
    );
};

export default MaintenanceFinancialConditions;
