import { atom } from "recoil";

import { setInitialDate } from "@utils";
import type { Partner } from "@types";
/**
 * Select contract
 */
export const contractSelectionState = atom({
    key: "contractSelectionState",
    default: {
        id: "",
        name: "",
        country: "",
        status: "",
        startDate: setInitialDate(),
        endDate: setInitialDate("plus", { years: 1 }),
        brandId: "",
        reference: "",
        externalReference: "",
        responsible: "",
        therapeuticArea: "",
        indication: "",
        comments: "",
        products: [
            {
                name: "",
            },
        ],
        additionalPartners: [
            {
                name: "",
            },
        ],
        primaryContractPartner: {} as Partner,
    },
});
