import { useRecoilValue } from "recoil";
import { useMemo } from "react";
import { teamState } from "@atoms";

const MAP_COUNTRIES_ISO_CODE = {
    isUkTeam: "GB",
    isGermanyTeam: "DE",
    isFranceTeam: "FR",
    isItalyTeam: "IT",
    isSpainTeam: "ES",
    isNetherlandsTeam: "NL",
    isBelgiumTeam: "BE",
    isSwitzerlandTeam: "CH",
};

type SelectedCountry = {
    countryIsoCode: string;
} & Record<keyof typeof MAP_COUNTRIES_ISO_CODE, boolean>;

/**
 * Check the stored team and return the selected country iso code
 */
export const useSelectedCountry = (): SelectedCountry => {
    const selectedTeam = useRecoilValue<any>(teamState);

    const mapCountries = useMemo(() => {
        if (!selectedTeam?.country?.countryIsoCode)
            return {} as SelectedCountry;
        const isoCode = selectedTeam?.country?.countryIsoCode;
        const mapIsoCodes = {} as SelectedCountry;

        Object.keys(MAP_COUNTRIES_ISO_CODE).forEach(
            key => (mapIsoCodes[key] = isoCode === MAP_COUNTRIES_ISO_CODE[key]),
        );

        mapIsoCodes.countryIsoCode = isoCode;

        return mapIsoCodes;
    }, [selectedTeam]);

    return mapCountries;
};
