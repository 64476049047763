import { AccountStatus } from "@types";

export const ACCOUNT_STATUS = [
    { label: "Pending", value: AccountStatus.PENDING },
    { label: "Active", value: AccountStatus.ACTIVE },
    { label: "Inactive", value: AccountStatus.INACTIVE },
];

export const ACCOUNT_DETAILS_STATUS = [
    { label: "Active", value: AccountStatus.ACTIVE },
    { label: "Inactive", value: AccountStatus.INACTIVE },
];
