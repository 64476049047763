// lib
import {
    Box,
    Grid,
    InputLabel,
    TextField,
    Typography,
    FormHelperText,
} from "@mui/material";

import clonedeep from "lodash.clonedeep";

import React, { Fragment, useEffect, useState, useMemo } from "react";

// Hooks
import { useMaintenance } from "@hooks";

// Own components
import { Table, Modal } from "@components";

// Style
import * as style from "./style.module.scss";
import { HEADERS, ROWRENDERERCONST } from "@constants";
import { isSuccessfulCall } from "@utils";

/**
 * Props type
 */

type FinancialConditionValues = {
    financialConditionTypeId: string;
    name: string;
    description: string;
    camundaDecisionTableId: string;
};

interface Props {
    id?: string;
}
/**
 * Maintenance Financial Conditions
 */
const FinancialConditions: React.FC<Props> = ({ id }) => {
    /**
     * States
     */
    const [openModal, toggleModal] = useState(false);
    const [values, setValues] = useState<
        FinancialConditionValues | Record<string, string>
    >({});
    const [touched, setTouched] = useState({});

    const {
        list: financialConditions,
        loading: { creating, listLoading },
        upsert: upsertFinancialConditions,
    } = useMaintenance(
        "financialCondition",
        "financialCondition|financialConditions",
    );

    const resetAll = () => {
        setValues({});
        setTouched({});
    };

    useEffect(() => {
        if (!openModal) {
            resetAll();
        }
    }, [openModal]);

    /**
     * Change handler
     */
    const handleChange = (field: string, value: any) => {
        const copyValues = clonedeep(values);
        setValues({
            ...copyValues,
            [field]: value,
        });
    };

    const handleSubmit = () => {
        upsertFinancialConditions(
            {
                financialConditionTypeId: values?.financialConditionTypeId,
                name: values?.name,
                description: values?.description,
                camundaDecisionTableId: values?.camundaDecisionTableId,
            },
            values.name,
            "PUT",
        ).then(res => {
            if (isSuccessfulCall(res?.status)) {
                toggleModal(false);
            }
        });
    };

    const errorTransformer = (field: string) =>
        !!touched[field] && !!values && !values[field];

    const limitDescription = useMemo(() => {
        if (!values["description"]) return;

        return (
            typeof values["description"] === "string" &&
            values["description"]?.length > 150
        );
    }, [values]);

    /**
     * Render
     */
    return (
        <Box mt={5}>
            <Modal
                id={`${id}-edit-financial-conditions-modal`}
                open={openModal}
                onClose={() => toggleModal(false)}
                title="Edit financial condition"
                primaryButton={{
                    action: handleSubmit,
                    text: "Save",
                    disabled:
                        (typeof values?.description === "string" &&
                            !values?.description?.trim()) ||
                        !values?.camundaDecisionTableId ||
                        limitDescription,
                    loading: creating,
                }}
                secondaryButton={{
                    action: () => toggleModal(false),
                    text: "Cancel",
                }}
            >
                <Grid
                    container
                    item
                    flexDirection="column"
                    xs={12}
                    className={style.content}
                    spacing={2}
                >
                    <Grid item xs={12} lg={4}>
                        <InputLabel
                            error={errorTransformer("camundaDecisionTableId")}
                            shrink
                        >
                            {"DMN ID (*)"}
                        </InputLabel>

                        <TextField
                            id={`${id}-edit-financial-camundaDecisionTableId`}
                            value={values["camundaDecisionTableId"]}
                            fullWidth
                            autoComplete="off"
                            error={errorTransformer("camundaDecisionTableId")}
                            onBlur={() =>
                                setTouched({
                                    ...touched,
                                    ["camundaDecisionTableId"]: true,
                                })
                            }
                            name="camundaDecisionTableId"
                            variant="outlined"
                            size="small"
                            onChange={event =>
                                handleChange(
                                    "camundaDecisionTableId",
                                    event.target.value,
                                )
                            }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <InputLabel
                            error={errorTransformer("description")}
                            shrink
                        >
                            {"Description (*)"}
                        </InputLabel>

                        <TextField
                            value={values["description"] || ""}
                            fullWidth
                            multiline
                            id={`${id}-edit-financial-description`}
                            minRows={6}
                            autoComplete="off"
                            error={errorTransformer("description")}
                            onBlur={() =>
                                setTouched({
                                    ...touched,
                                    ["description"]: true,
                                })
                            }
                            name="description"
                            variant="outlined"
                            size="small"
                            onChange={event =>
                                handleChange("description", event.target.value)
                            }
                        />
                        <FormHelperText error={!!limitDescription}>
                            Description must be shorter than or equal to 150
                            characters
                        </FormHelperText>
                    </Grid>
                </Grid>
            </Modal>

            <Fragment>
                <Box mb={4}>
                    <Typography variant="h2">
                        {"Financial conditions"}
                    </Typography>
                </Box>
                <Table
                    id={`${id}-financial-conditions-list`}
                    loading={listLoading}
                    headers={HEADERS.MAINTENANCE_FINANCIAL_CONDITIONS}
                    rows={
                        financialConditions?.maintenanceFinancialConditionList
                    }
                    type={ROWRENDERERCONST.MAINTENANCE_FINANCIAL_CONDITIONS}
                    callbacks={{
                        onEdit: (index: number) => {
                            setValues(
                                financialConditions
                                    ?.maintenanceFinancialConditionList[index],
                            );
                            toggleModal(true);
                        },
                    }}
                    emptyMsg="No data"
                />
            </Fragment>
        </Box>
    );
};
export default React.memo(FinancialConditions);
