//import libs
import { Box, TableCell, TableRow, Typography, Tooltip } from "@mui/material";
import React from "react";

// import own components
import { ActionMenu } from "@components";

// Utils
import { constantMapper, displayDate, truncateString } from "@utils";

interface Row {
    accountName: string;
    filename: string;
    filedate: string;
    status: string;
    dateFrom: string;
    dateTo: string;
    claimHeaderId: string;
    dateProcessed: string;
    contractRef: string;
    claimReferenceNumber: string;
    totalRebateValue: string;
    claimCustomerReference: string;
    claimDueDate: string;
    claimDocumentDate: string;
    datamodelName: string;
}

interface Props {
    row: Row;
    rowIndex: number;
    id: string;
    isColVisible: (col: string) => boolean;
    callbacks: any;
    permissions: any;
    isPriceCorrection?: boolean;
}

const ClaimRow = ({
    id,
    row,
    rowIndex,
    isColVisible,
    callbacks,
    permissions,
    isPriceCorrection,
}: Props) => {
    return (
        <TableRow
            id={`${id}-row-${rowIndex}`}
            sx={{
                "&:last-child td, &:last-child th": {
                    border: 0,
                },
            }}
        >
            {isColVisible("supplierName") && (
                <TableCell>
                    <Typography variant="caption2">
                        {row.accountName}
                    </Typography>
                </TableCell>
            )}

            {isColVisible("contractRef") && (
                <TableCell>
                    <Typography variant="caption2">
                        {row.contractRef || "-"}
                    </Typography>
                </TableCell>
            )}

            {isColVisible("claimReferenceNumber") && !isPriceCorrection && (
                <TableCell>
                    <Typography variant="caption2">
                        {row.claimReferenceNumber || "-"}
                    </Typography>
                </TableCell>
            )}

            {isColVisible("accountName") && (
                <TableCell>
                    <Typography variant="caption2">
                        {row.accountName}
                    </Typography>
                </TableCell>
            )}

            {isColVisible("datamodelName") && !isPriceCorrection && (
                <TableCell>
                    <Typography variant="caption2">
                        {row.datamodelName || "-"}
                    </Typography>
                </TableCell>
            )}

            {isColVisible("fileName") && (
                <TableCell>
                    <Tooltip arrow placement="top" title={row.filename}>
                        <Typography variant="caption2">
                            {truncateString(row.filename, 20, true)}
                        </Typography>
                    </Tooltip>
                </TableCell>
            )}

            {isColVisible("fileDate") && (
                <TableCell>
                    <Typography variant="caption2">
                        {displayDate(row.filedate)}
                    </Typography>
                </TableCell>
            )}

            {isColVisible("dateFrom") && (
                <TableCell>
                    <Typography variant="caption2">
                        {displayDate(row.dateFrom)}
                    </Typography>
                </TableCell>
            )}
            {isColVisible("dateTo") && (
                <TableCell>
                    <Typography variant="caption2">
                        {displayDate(row.dateTo)}
                    </Typography>
                </TableCell>
            )}

            {isColVisible("dateProcessed") && (
                <TableCell>
                    <Typography variant="caption2">
                        {displayDate(row.dateProcessed)}
                    </Typography>
                </TableCell>
            )}
            {isColVisible("claimDocumentDate") && (
                <TableCell>
                    <Typography variant="caption2">
                        {displayDate(row.claimDocumentDate)}
                    </Typography>
                </TableCell>
            )}

            {isColVisible("claimDueDate") && (
                <TableCell>
                    <Typography variant="caption2">
                        {displayDate(row.claimDueDate)}
                    </Typography>
                </TableCell>
            )}

            {isColVisible("claimCustomerReference") && (
                <TableCell>
                    <Typography variant="caption2">
                        {row.claimCustomerReference || "-"}
                    </Typography>
                </TableCell>
            )}
            {isColVisible("totalRebateValue") && (
                <TableCell>
                    <Typography variant="caption2">
                        {row.totalRebateValue || "-"}
                    </Typography>
                </TableCell>
            )}

            {isColVisible("status") && (
                <TableCell>
                    <Typography variant="caption2">
                        {constantMapper(row.status)}
                    </Typography>
                </TableCell>
            )}

            <TableCell align="right">
                <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                >
                    <ActionMenu
                        id={`${id}-row-${rowIndex}-contract-row`}
                        autoWidth
                        autoHide
                        options={[
                            {
                                label: "Claim details ",
                                action: () =>
                                    callbacks &&
                                    callbacks?.onClaimDetailsClick(
                                        row?.claimHeaderId,
                                    ),
                            },
                            {
                                label: "Edit claim",
                                action: () =>
                                    callbacks && callbacks?.onClaimEdit(row),
                                disabled:
                                    row.status !== "DRAFT" ||
                                    !permissions?.hasPermissionToEditClaim,
                                hidden: isPriceCorrection,
                            },
                            {
                                label: "Recall claim",
                                action: () =>
                                    callbacks && callbacks?.onClaimRecall(row),
                                disabled:
                                    row.status !== "NEW" ||
                                    !permissions?.hasPermissionToEditClaim,
                                hidden: isPriceCorrection,
                            },
                            {
                                label: "Delete claim",
                                action: () =>
                                    callbacks && callbacks?.onClaimDelete(row),
                                disabled:
                                    row.status !== "DRAFT" ||
                                    !permissions?.hasPermissionToEditClaim,
                                hidden: isPriceCorrection,
                            },
                            {
                                label: "Send claim for approval",
                                action: () =>
                                    callbacks && callbacks?.onClaimApprove(row),
                                disabled:
                                    row.status !== "DRAFT" ||
                                    !permissions?.hasPermissionToEditClaim,
                                hidden: isPriceCorrection,
                            },
                            {
                                label: isPriceCorrection
                                    ? "Cancel price correction claim"
                                    : "Cancel claim",
                                action: () =>
                                    callbacks && callbacks?.cancelClaim(row),
                                disabled:
                                    (row.status !== "OPEN" &&
                                        row.status !== "CLOSED") ||
                                    !permissions?.hasPermissionToEditClaim,
                            },
                            {
                                label: "Download claim validation report",
                                action: () =>
                                    callbacks &&
                                    callbacks?.downloadClaimValidationReport(
                                        row?.claimHeaderId,
                                        row?.filename,
                                    ),
                                disabled:
                                    !callbacks?.downloadClaimValidationReport,
                            },
                        ]}
                        rowIndex={rowIndex}
                    />
                </Box>
            </TableCell>
        </TableRow>
    );
};

export default React.memo(ClaimRow);
