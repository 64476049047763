//import { useApi } from "../api";
import { useAxios } from "./useAxios";
import type { Api } from "@types";
import { useRecoilState, useResetRecoilState } from "recoil";
import { teamState, userState } from "@atoms";

// Constants
import { ROWRENDERERCONST } from "@constants";

// hooks
import { useLayoutEffect } from "react";
import { isArrayWithContent, isSuccessfulCall } from "@utils";

const BASE_URI = `/v0`;

export const useAccountTeams = () => {
    const { response, loading, error, fetch }: Api = useAxios();

    return {
        getAccountTeams: () =>
            fetch(
                {
                    method: "GET",
                    url: `${BASE_URI}/profile/teams`,
                },
                { errorHandler: "Failed to fetch user teams" },
            ),
        error,
        loading,
        response,
    };
};

/**
 * Get user profile
 */
export const useUser = (getList = true) => {
    const [_, setUser] = useRecoilState(userState);
    const { getAccountTeams, loading: fetchingAccountTeams } =
        useAccountTeams();
    const [team, storeTeam] = useRecoilState<any>(teamState);
    const resetPickedTeam = useResetRecoilState(teamState);

    const { response, loading, error, fetch }: Api = useAxios(
        {
            method: "GET",
            url: `${BASE_URI}/auth/profile`,
        },
        { errorHandler: "Failed to fetch user data" },
    );

    /**
     * Find Selected Team
     */
    const findSelectedTeam = userTeams => {
        if (!userTeams || !isArrayWithContent(userTeams)) return undefined;

        if (team?.teamId) {
            return userTeams.find(userTeam => userTeam.teamId === team.teamId);
        } else if (!team?.teamId && userTeams.length === 1) {
            return userTeams[0];
        }
        return undefined;
    };

    const handleAccounts = () =>
        getAccountTeams().then(res => {
            if (isSuccessfulCall(res?.status)) {
                const selectedTeam = findSelectedTeam(res?.data?.records);

                if (!selectedTeam?.teamId) {
                    resetPickedTeam();
                    Object.keys(ROWRENDERERCONST).forEach((key: string) => {
                        localStorage.removeItem(key);
                    });

                    return fetch().then(profileRes => {
                        if (isSuccessfulCall(profileRes?.status)) {
                            setUser(profileRes.data);
                        }
                    });
                }

                storeTeam(selectedTeam);
                return fetch({
                    headers: {
                        "X-Team-Id": selectedTeam?.teamId,
                    },
                }).then(profileRes => {
                    if (isSuccessfulCall(profileRes?.status)) {
                        setUser(profileRes.data);
                    }
                });
            }
        });

    useLayoutEffect(() => {
        if (!getList) return;
        handleAccounts();
    }, [getList]);

    return {
        error,
        loading: loading || fetchingAccountTeams,
        response,
        getUser: () => handleAccounts(),
    };
};

export const useAccountTeamUsers = () => {
    const { response, loading, error, fetch }: Api = useAxios();

    return {
        getAccountTeamUsers: (teamId: string, query?: string) =>
            fetch(
                {
                    method: "GET",
                    url: query
                        ? `${BASE_URI}/profile/teams/${teamId}/users${query}`
                        : `${BASE_URI}/profile/teams/${teamId}/users`,
                },
                { errorHandler: "Failed to fetch team users" },
            ),
        error,
        loading,
        response,
    };
};
