// Lib
import React, { Fragment, useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { Box, Typography } from "@mui/material";

// Custom hooks
import { useGetContracts, useUpdateStep } from "@hooks";

// Constants
import { HEADERS, ROWRENDERERCONST } from "@constants";

// Types
import type { Location } from "@types";

//Atoms
import {
    claimDetailsState,
    claimContractSelectionState,
    uploadClaimFileStepsState,
} from "@atoms";

//Utils
import { constructQueryString, apiResponseCounter } from "@utils";

// Own components
import { Table } from "@components";
/**
 * Props type
 */
interface Props {
    id?: string;
    location?: Location;
    disabled?: boolean;
}

/**
 * Process
 */
const Process: React.FC<Props> = ({ location, id, disabled }) => {
    const { contractPartner } = useRecoilValue(claimDetailsState);

    const [contract, updateContractSelection] = useRecoilState(
        claimContractSelectionState,
    );

    // update step hook

    const updateStepValidation = useUpdateStep(
        location,
        uploadClaimFileStepsState,
    );

    /**
     * API
     */

    // Get contracts
    const {
        list: contractsList,
        loading: fetching,
        search,
    } = useGetContracts();

    useEffect(() => {
        if (!contractPartner) return;
        const params = constructQueryString(
            {
                account: contractPartner?.accountId,
                type: "VOLUME",
                status: ["INACTIVE", "ACTIVE"],
            },
            true,
        );

        search(`${params}`);
    }, []);

    /**
     * Check if the current step is valid
     * then set isPrepared to true, otherwise set it false
     */
    useEffect(() => {
        updateStepValidation(!!contract?.id);
    }, [contract?.id]);

    /**
     * Render
     */
    return (
        <Fragment>
            <Box display="flex" alignItems="baseline" mb={5}>
                <Typography variant="h2">Contract selection</Typography>

                <Typography ml={1} variant="caption1">
                    {apiResponseCounter(
                        contractsList,
                        fetching,
                        "contract|contracts",
                    )}
                </Typography>
            </Box>

            <Table
                id={`${id}-contract-selection-list`}
                headers={HEADERS.CLAIM_CONTRACT}
                rows={contractsList?.data?.records}
                loading={fetching}
                type={ROWRENDERERCONST.CLAIM_CONTRACT}
                maxHeight="30rem"
                callbacks={{
                    onSelect: row => {
                        updateContractSelection(row);
                    },
                }}
                selectedRow={contract?.id}
                disabled={disabled}
                emptyMsg="No contracts found!"
            />
        </Fragment>
    );
};
export default Process;
