// Icons

import Add from "@mui/icons-material/Add";

// Libs

import {
    Grid,
    Typography,
    Button,
    TextField,
    Box,
    InputLabel,
} from "@mui/material";
import React, { Fragment } from "react";

// Own components
import { FieldRenderer, GrayBox } from "@components";

// Utils
import { isArrayWithContent } from "@utils";

// Styles
import * as style from "./style.module.scss";

// Define the structure of a Note
type Note = {
    note: string;
    noteInfo: string;
};

// Notes is an array of Note or an empty array
type Notes = Array<Note> | [];

/**
 * Props type
 */
interface Props {
    disabled?: boolean;
    id: string;
    storedNotes: { note: string; noteInfo: string }[];
    setNotes: (notes: any) => void;
    editNote: { index: number; note: string; noteInfo: string } | undefined;
    setEditNote: (
        note:
            | {
                  index: number;
                  note: string;
                  noteInfo: string;
              }
            | undefined,
    ) => void;
}

/**
 * Edit claim notes
 */
const EditClaimNotes = ({
    id,
    storedNotes,
    setNotes,
    editNote,
    setEditNote,
    disabled,
}: Props) => {
    /**
     * Touched
     */
    const [touched, setTouched] = React.useState<Record<string, boolean>>({});

    /**
     * Remove Note
     */
    const onNoteRemove = (index: number) =>
        setNotes((prev: Notes) => prev.filter((_, idx) => idx !== index));

    /**
     * Note initializer
     */
    const onInitializeNote = () =>
        setEditNote({
            index: storedNotes.length,
            note: "",
            noteInfo: "",
        });

    /**
     * Render
     */
    return (
        <Fragment>
            {isArrayWithContent(storedNotes) &&
                storedNotes?.map((item, index) => (
                    <Grid key={index} item xs={12} mt={5}>
                        <GrayBox
                            id={`${id}-note-form`}
                            padding={4}
                            wrapperPaddingX={4}
                            header={
                                <Fragment>
                                    <Box>
                                        <InputLabel shrink error={false}>
                                            {"Note (*)"}
                                        </InputLabel>

                                        {editNote?.index !== undefined &&
                                        editNote?.index === index ? (
                                            <TextField
                                                id={`${id}-note-email`}
                                                fullWidth
                                                name="note"
                                                autoComplete="off"
                                                value={editNote?.note || ""}
                                                onBlur={() =>
                                                    setTouched({
                                                        ...touched,
                                                        note: true,
                                                    })
                                                }
                                                className={style.whiteInput}
                                                onChange={(
                                                    event: React.SyntheticEvent,
                                                ) => {
                                                    const target =
                                                        event?.target as HTMLInputElement;

                                                    setEditNote({
                                                        ...editNote,
                                                        note: target.value,
                                                    });
                                                }}
                                                variant="outlined"
                                                size="small"
                                                data-testid="email-input"
                                                error={
                                                    !editNote?.note &&
                                                    touched &&
                                                    touched["note"]
                                                }
                                                disabled={disabled}
                                                inputProps={{
                                                    readOnly:
                                                        editNote?.index !==
                                                        index,
                                                }}
                                            />
                                        ) : (
                                            <FieldRenderer
                                                id={`${id}-claim-details-note-${index}`}
                                                value={item?.note}
                                            />
                                        )}
                                    </Box>
                                </Fragment>
                            }
                            collapsible
                            collapse={editNote?.index === index}
                            primaryAction={{
                                text:
                                    !editNote || editNote?.index !== index
                                        ? "Delete"
                                        : "Discard changes",
                                onClick:
                                    !editNote || editNote?.index !== index
                                        ? () => onNoteRemove(index)
                                        : () => {
                                              setEditNote(undefined);
                                              setEditNote(undefined);
                                              setTouched({});
                                          },
                                disabled:
                                    disabled ||
                                    (!!editNote && editNote?.index !== index),
                                id: `${id}-discard-changes`,
                            }}
                            secondaryAction={{
                                text:
                                    !editNote || editNote?.index !== index
                                        ? "Edit"
                                        : "Save",

                                onClick: () => {
                                    if (
                                        !editNote ||
                                        editNote?.index !== index
                                    ) {
                                        setEditNote({ ...item, index });
                                    } else {
                                        const updatedNotes = [...storedNotes];
                                        updatedNotes[editNote?.index] =
                                            editNote;
                                        setNotes(updatedNotes);
                                        setEditNote(undefined);
                                        setTouched({});
                                    }
                                },
                                disabled:
                                    disabled ||
                                    (!!editNote && editNote?.index !== index) ||
                                    (editNote !== undefined &&
                                        (!editNote?.note ||
                                            !editNote?.noteInfo)),
                                id: `${id}-save-note`,
                            }}
                        >
                            <Fragment>
                                <InputLabel shrink error={false}>
                                    {"Info (*)"}
                                </InputLabel>
                                {editNote?.index === index ? (
                                    <TextField
                                        value={editNote?.noteInfo || ""}
                                        fullWidth
                                        multiline
                                        id={`${id}-edit-note`}
                                        className={style.whiteInput}
                                        minRows={6}
                                        autoComplete="off"
                                        onBlur={() =>
                                            setTouched({
                                                ...touched,
                                                noteInfo: true,
                                            })
                                        }
                                        name="note"
                                        variant="outlined"
                                        size="small"
                                        onChange={event =>
                                            setEditNote({
                                                ...editNote,
                                                noteInfo: event.target.value,
                                            })
                                        }
                                        disabled={disabled}
                                    />
                                ) : (
                                    <FieldRenderer
                                        id={`${id}-claim-details-note-${index}`}
                                        value={item?.noteInfo}
                                        multiline
                                    />
                                )}
                            </Fragment>
                        </GrayBox>
                    </Grid>
                ))}

            {editNote?.index === storedNotes.length && (
                <Grid item xs={12} mt={storedNotes.length > 0 ? 5 : 0}>
                    <GrayBox
                        id={`${id}-note-form`}
                        padding={4}
                        wrapperPaddingX={4}
                        header={
                            <Fragment>
                                <InputLabel
                                    shrink
                                    error={!editNote?.note && touched?.note}
                                >
                                    {"Note (*)"}
                                </InputLabel>

                                <TextField
                                    id={`${id}-note-email`}
                                    className={style.whiteInput}
                                    fullWidth
                                    name="email"
                                    autoComplete="off"
                                    onBlur={() =>
                                        setTouched({
                                            ...touched,
                                            note: true,
                                        })
                                    }
                                    value={editNote?.note || ""}
                                    onChange={(event: React.SyntheticEvent) => {
                                        const target =
                                            event?.target as HTMLInputElement;

                                        setEditNote({
                                            ...editNote,
                                            note: target.value,
                                        });
                                    }}
                                    variant="outlined"
                                    size="small"
                                    data-testid="email-input"
                                    error={
                                        !editNote?.note &&
                                        touched &&
                                        touched["note"]
                                    }
                                    disabled={disabled}
                                />
                            </Fragment>
                        }
                        collapsible
                        collapse={editNote?.index !== undefined}
                        primaryAction={{
                            text: "Delete",
                            onClick: () => {
                                setEditNote(undefined);
                                setEditNote(undefined);
                                setTouched({});
                            },
                            disabled,
                            id: `${id}-discard-changes`,
                        }}
                        secondaryAction={{
                            text: "Save",
                            onClick: () => {
                                const updatedNotes = [...storedNotes];
                                updatedNotes[editNote?.index] = {
                                    noteInfo: editNote?.noteInfo,
                                    note: editNote?.note,
                                };

                                setNotes(updatedNotes);
                                setEditNote(undefined);
                                setTouched({});
                            },
                            disabled:
                                disabled ||
                                !editNote?.note ||
                                !editNote?.noteInfo,
                            id: `${id}-save-note`,
                        }}
                    >
                        <Fragment>
                            <InputLabel
                                shrink
                                error={!editNote?.noteInfo && touched?.noteInfo}
                            >
                                {"Info (*)"}
                            </InputLabel>

                            <TextField
                                value={editNote?.noteInfo || ""}
                                fullWidth
                                multiline
                                id={`${id}-edit-note-info`}
                                onBlur={() =>
                                    setTouched({
                                        ...touched,
                                        noteInfo: true,
                                    })
                                }
                                minRows={6}
                                autoComplete="off"
                                className={style.whiteInput}
                                error={!editNote?.noteInfo && touched?.noteInfo}
                                name="noteInfo"
                                variant="outlined"
                                size="small"
                                onChange={event =>
                                    setEditNote({
                                        ...editNote,
                                        noteInfo: event.target.value,
                                    })
                                }
                                disabled={disabled}
                            />
                        </Fragment>
                    </GrayBox>
                </Grid>
            )}

            <Grid item xs={12} mt={storedNotes.length > 0 ? 1 : undefined}>
                <GrayBox
                    padding={2}
                    marginY={1}
                    id={`${id}-add-note-btn`}
                    header={
                        <Button
                            variant="text"
                            color="primary"
                            onClick={onInitializeNote}
                            startIcon={<Add />}
                            size="large"
                            disabled={disabled || editNote?.index !== undefined}
                        >
                            <Typography
                                variant="caption1"
                                color={
                                    disabled || editNote?.index !== undefined
                                        ? "action.disabled"
                                        : "primary"
                                }
                            >
                                {`Add note`}
                            </Typography>
                        </Button>
                    }
                />
            </Grid>
        </Fragment>
    );
};

export default EditClaimNotes;
