export const ORDER_STATUSES = {
    ACTIVE: ["CANCELLED"],
    NEW: ["CANCELLED"],
    NON_LINKED: ["CANCELLED"],
};

export const REGION_TYPE = [
    { label: "Canton", value: "CANTON" },
    { label: "Country", value: "COUNTRY" },
    { label: "Region", value: "REGION" },
];

export const CONTEXT_TYPES = [
    { label: "Account", id: "ACCOUNT" },
    { label: "Region", id: "REGION" },
    { label: "Product", id: "PRODUCT" },
    { label: "Contract", id: "CONTRACT" },
];

export const MODEL_DATA_SOURCE = [
    { label: "IQVIA PADDS", id: "IQVIA PADDS" },
    { label: "SAP", id: "SAP" },
];

export const MODEL_PRICE_SOURCE = [
    { label: "LAUER", id: "LAUER" },
    { label: "SAP", id: "SAP" },
    { label: "iTender", id: "iTENDER" },
];

export const CONTRACT_TYPES = {
    PERFORMANCE: "PERFORMANCE",
    VOLUME: "VOLUME",
};
