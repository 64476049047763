import React from "react";
import TextField, { TextFieldProps } from "@mui/material/TextField";

interface NumberInputProps extends Omit<TextFieldProps, "onChange"> {
    name: string;
    value: string | null;
    onChange: (e: React.ChangeEvent<any>) => void;
    error?: boolean;
    touched?: boolean;
}

const NumberInput = React.memo(
    ({
        value,
        onChange,
        name,
        id,
        error,
        touched,
        ...props
    }: NumberInputProps) => {
        const handleChange = React.useCallback(
            (event: React.ChangeEvent<HTMLInputElement>) => {
                const newValue = event.target.value.replace(/[^\d]/g, "");
                const syntheticEvent = {
                    ...event,
                    target: {
                        ...event.target,
                        name,
                        value: newValue,
                    },
                };
                onChange(syntheticEvent);
            },
            [name, onChange],
        );

        return (
            <TextField
                name={name}
                id={id}
                value={value}
                onChange={handleChange}
                error={!!error && touched}
                autoComplete="off"
                inputProps={{
                    inputMode: "numeric",
                }}
                size="small"
                {...props}
            />
        );
    },
);

export default NumberInput;
