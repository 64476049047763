//Lib
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import React, { Fragment } from "react";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";

import { datePlaceholder } from "@utils";
/**
 * Props type
 */
interface Props {
    onChange: (date: any) => void;
    className?: string;
    label: string;
    value: string;
    id: string;
}

/**
 * Date Filter
 */
const DateFilter: React.FC<Props> = ({ label, onChange, className, value }) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
        null,
    );

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? "date-filter-popover" : undefined;

    return (
        <Fragment>
            <Button
                id={`${id}-date-filter-btn`}
                onClick={handleClick}
                className={className}
                variant="outlined"
                endIcon={
                    open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />
                }
            >
                {label}
            </Button>

            <Popover
                id={`${id}-date-filter-popover`}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
            >
                <DateCalendar
                    value={datePlaceholder(value)}
                    onChange={date => {
                        onChange(date);
                        handleClose();
                    }}
                />
            </Popover>
        </Fragment>
    );
};

export default React.memo(DateFilter);
