export const CATEGORY_TYPE_MAPPER = {
    CONTRACT_TYPE_PRICE: "contractPrice",
    CONTRACT_TYPE_PERCENTAGE: "discountPercentage",
    CONTRACT_TYPE_TENDER: "tender",
};

export const PERIODICITY = [
    { id: "MONTHLY", label: "Monthly" },
    { id: "QUARTERLY", label: "Quarterly" },
    { id: "YEARLY", label: "Yearly" },
];
