// Libs
import { TableCell, TableRow, Typography, Button } from "@mui/material";
import React from "react";

// utils
import { displayDate, thousandsSeparator } from "@utils";

interface Row {
    condition: string;
    price: number;
    currency: string;
    validFrom: string;
    validTo: string;
    sapSkuNo: string;
}

interface Props {
    row: Row;
    rowIndex: number;
    id: string;
    callbacks: any;
}

const ProductPrices = ({ rowIndex, row, id, callbacks }: Props) => {
    return (
        <TableRow id={`${id}-row-${rowIndex}`}>
            <TableCell>
                <Typography variant="caption2">{row.condition}</Typography>
            </TableCell>
            <TableCell>
                <Typography variant="caption2">
                    {displayDate(row.validFrom)}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography variant="caption2">
                    {displayDate(row.validTo)}
                </Typography>
            </TableCell>

            <TableCell>
                <Typography variant="caption2">
                    {thousandsSeparator(row.price)}
                </Typography>
            </TableCell>

            <TableCell>
                <Typography variant="caption2">{row.currency}</Typography>
            </TableCell>
            <TableCell align="right">
                {callbacks && callbacks?.selectCondition && (
                    <Button
                        id={`${id}-${rowIndex}-product-price-btn`}
                        variant="text"
                        onClick={() => callbacks?.selectCondition(row)}
                    >
                        Select condition
                    </Button>
                )}
            </TableCell>
        </TableRow>
    );
};

export default ProductPrices;
