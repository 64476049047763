import { atom } from "recoil";

/**
 * Contract Type State
 */
export const contractMilestonesState = atom({
    key: "contractMilestonesState",
    default: {
        product: {},
        brand: {},
        milestones: [],
    },
});
