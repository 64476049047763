// Icons
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";

// lib
import { useFormik, type FormikErrors } from "formik";
import {
    FormHelperText,
    Grid,
    InputLabel,
    Box,
    Typography,
    TextField,
} from "@mui/material";
import { useRecoilState, useResetRecoilState } from "recoil";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import React, { Fragment, useEffect, useImperativeHandle } from "react";

// Custom hooks
import { useContractPartners, useUpdateStep, useSelectedCountry } from "@hooks";

// Components
import { Autocomplete, Dropzone } from "@components";

// Utils
import { convertDateForAPI, datePlaceholder } from "@utils";

// Types
import type { FlowStep, Location } from "@types";

// Schemas
import { addClaimDetailsSchema } from "@schemas";

// Atoms
import {
    uploadClaimFileStepsState,
    claimDetailsState,
    claimContractSelectionState,
} from "@atoms";

/**
 * Props type
 */
interface Props {
    children?: React.ReactNode;
    location?: Location;
    currentStep?: FlowStep;
    disabled?: boolean;
}

/**
 * ClaimDetails
 */
const ClaimDetails: React.FC<Props> = React.forwardRef(
    ({ location, disabled }: Props, ref) => {
        const [claimDetails, updateClaimDetails] =
            useRecoilState(claimDetailsState);

        const resetSelectedContract = useResetRecoilState(
            claimContractSelectionState,
        );

        const { isUkTeam } = useSelectedCountry();

        /**
         * API
         */

        // Get partner
        const {
            getPartners,
            response: partners,
            loading: partnersLoading,
        } = useContractPartners();

        /**
         * Formik state
         */
        const formik = useFormik<any>({
            initialValues: claimDetails,
            validationSchema: addClaimDetailsSchema,
            enableReinitialize: true,
            validateOnMount: true,
            onSubmit: () => undefined,
        });

        // update step hook
        const updateStepValidation = useUpdateStep(
            location,
            uploadClaimFileStepsState,
        );

        /**
         * Form validation check
         */
        const canSaveState =
            formik.isValid &&
            formik.values?.periodFrom &&
            formik.values.periodTo &&
            formik.values.contractPartner &&
            formik.values.file &&
            (formik.values?.isUkTeam
                ? !!formik.values?.claimCustomerReference &&
                  !!formik.values?.claimCustomerInfo
                : true);

        useEffect(() => {
            if (canSaveState || !!formik?.values?.contractPartner) return;

            resetSelectedContract();
        }, [canSaveState, formik.values.contractPartner]);

        /**
         * Check if the current step is valid
         * then set isPrepared to true, otherwise set it false
         */
        useEffect(() => {
            updateStepValidation(canSaveState);
        }, [canSaveState, location]);

        useImperativeHandle(ref, () => ({
            updateState() {
                updateClaimDetails(formik.values);
            },
        }));

        useEffect(() => {
            if (isUkTeam === undefined) return;

            if (isUkTeam) {
                formik.setFieldValue("isUkTeam", isUkTeam);
            }
        }, [isUkTeam]);

        /**
         * Render
         */
        return (
            <form noValidate onBlur={formik.handleBlur}>
                <Box mb={5}>
                    <Typography variant="h2">Claim details</Typography>
                </Box>
                <Grid
                    container
                    item
                    xs={12}
                    spacing={3}
                    justifyContent={"space-between"}
                >
                    <Grid item xs={12} md={6}>
                        <InputLabel shrink id={` period-from-picker`}>
                            {"Period from (*)"}
                        </InputLabel>

                        <DatePicker
                            value={datePlaceholder(formik.values.periodFrom)}
                            onChange={value => {
                                formik.setFieldTouched("periodFrom", true);
                                if (!value || !value?.isValid)
                                    formik.setFieldValue("periodFrom", "");

                                if (value) {
                                    formik.setFieldValue(
                                        "periodFrom",
                                        convertDateForAPI(value),
                                    );
                                }
                            }}
                            format="dd/MM/yyyy"
                            disabled={disabled}
                            slotProps={{
                                textField: {
                                    variant: "outlined",
                                    autoComplete: "off",
                                    sx: {
                                        svg: {
                                            color: "#036",
                                            opacity: "0.5",
                                        },
                                    },
                                    size: "small",
                                    fullWidth: true,
                                    placeholder: "dd/mm/yyyy",
                                    required: true,
                                    error:
                                        !!formik.errors.periodFrom &&
                                        !!formik.touched.periodFrom,
                                    id: `periodFrom`,
                                    name: "periodFrom",
                                },
                            }}
                            //  minDate={defaultEndMinDate()}
                            slots={{
                                // Override default <ActionBar /> with a custom one
                                openPickerIcon: CalendarTodayIcon,
                            }}
                        />
                        {!!formik.errors.periodFrom &&
                            !!formik.touched.periodFrom && (
                                <FormHelperText error>
                                    {formik.errors.periodFrom as string}
                                </FormHelperText>
                            )}
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <InputLabel
                            error={
                                !!formik.errors.periodTo &&
                                !!formik.touched.periodTo
                            }
                            shrink
                            id={`period-to-label`}
                        >
                            {"Period to (*)"}
                        </InputLabel>

                        <DatePicker
                            value={datePlaceholder(formik.values.periodTo)}
                            onChange={value => {
                                formik.setFieldTouched("periodTo", true);
                                if (!value || !value.isValid)
                                    formik.setFieldValue("periodTo", "");

                                if (value) {
                                    formik.setFieldValue(
                                        "periodTo",
                                        convertDateForAPI(value),
                                    );
                                }
                            }}
                            format="dd/MM/yyyy"
                            slotProps={{
                                textField: {
                                    variant: "outlined",
                                    autoComplete: "off",
                                    sx: {
                                        svg: {
                                            color: "#036",
                                            opacity: "0.5",
                                        },
                                    },
                                    size: "small",
                                    fullWidth: true,
                                    placeholder: "dd/mm/yyyy",
                                    required: true,
                                    error:
                                        !!formik.errors.periodTo &&
                                        !!formik.touched.periodTo,
                                    id: `period-to-input`,
                                    name: "periodTo",
                                },
                            }}
                            //  minDate={defaultEndMinDate()}
                            slots={{
                                // Override default <ActionBar /> with a custom one
                                openPickerIcon: CalendarTodayIcon,
                            }}
                            minDate={datePlaceholder(
                                formik.values.periodFrom,
                                1,
                            )}
                            disabled={disabled}
                        />
                        {!!formik.errors.periodTo &&
                            !!formik.touched.periodTo && (
                                <FormHelperText error>
                                    {formik.errors.periodTo as string}
                                </FormHelperText>
                            )}
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <InputLabel
                            id={`partner-label`}
                            error={
                                !!formik.errors.contractPartner &&
                                !!formik.touched.contractPartner
                            }
                            shrink
                        >
                            {"Primary contract partner (*)"}
                        </InputLabel>

                        <Autocomplete
                            id={`partner-input`}
                            data={partners?.data?.partners}
                            loading={partnersLoading}
                            name="contractPartner"
                            onBlur={event => {
                                formik.handleBlur(event);
                            }}
                            value={
                                formik.values.contractPartner?.accountId
                                    ? formik.values.contractPartner
                                    : null
                            }
                            size="small"
                            keysToMatch={[
                                "accountName",
                                "accountCity",
                                "sapAccountCode",
                                "knowyoursupplierid",
                            ]}
                            onChange={value => {
                                if (!formik.touched["contractPartner"]) {
                                    formik.setTouched({
                                        ...formik.touched,
                                        contractPartner: true,
                                    });
                                }
                                formik.setFieldValue("contractPartner", value);
                            }}
                            onSearch={(query: string) => {
                                getPartners(query);
                            }}
                            disabled={disabled}
                            variant="outlined"
                            error={
                                !!formik.errors.contractPartner &&
                                !!formik.touched.contractPartner
                            }
                        />
                        {!!(
                            formik?.errors?.contractPartner as FormikErrors<any>
                        )?.accountId &&
                            !!formik?.touched?.contractPartner && (
                                <FormHelperText error>
                                    {
                                        (
                                            formik.errors
                                                .contractPartner as FormikErrors<any>
                                        )?.accountId as string
                                    }
                                </FormHelperText>
                            )}
                    </Grid>
                    {isUkTeam && (
                        <Fragment>
                            <Grid item xs={12}>
                                <InputLabel
                                    shrink
                                    id={`claim-customer-reference`}
                                    error={
                                        !!formik.errors
                                            .claimCustomerReference &&
                                        !!formik.touched.claimCustomerReference
                                    }
                                >
                                    {"Partner claim reference (*)"}
                                </InputLabel>

                                <TextField
                                    id={`claim-customer-reference`}
                                    fullWidth
                                    size="small"
                                    autoComplete="off"
                                    onBlur={event => {
                                        formik.handleBlur(event);
                                    }}
                                    error={
                                        !!formik.errors
                                            .claimCustomerReference &&
                                        !!formik.touched.claimCustomerReference
                                    }
                                    name="claimCustomerReference"
                                    value={
                                        formik.values?.claimCustomerReference
                                    }
                                    onChange={(
                                        event: React.BaseSyntheticEvent,
                                    ) => {
                                        formik.setFieldValue(
                                            "claimCustomerReference",
                                            event.target.value,
                                        );
                                    }}
                                    variant="outlined"
                                />
                                {!!formik.errors.claimCustomerReference &&
                                    !!formik.touched.claimCustomerReference && (
                                        <FormHelperText error>
                                            {
                                                formik.errors
                                                    .claimCustomerReference as string
                                            }
                                        </FormHelperText>
                                    )}
                            </Grid>

                            <Grid item xs={12}>
                                <InputLabel
                                    shrink
                                    id={`claim-customer-info`}
                                    error={
                                        !!formik.errors.claimCustomerInfo &&
                                        !!formik.touched.claimCustomerInfo
                                    }
                                >
                                    {"Partner claim info (*)"}
                                </InputLabel>

                                <TextField
                                    id={`claim-customer-info`}
                                    fullWidth
                                    name={"claimCustomerInfo"}
                                    multiline
                                    autoComplete="off"
                                    onBlur={event => {
                                        formik.handleBlur(event);
                                    }}
                                    rows={5}
                                    value={formik.values?.claimCustomerInfo}
                                    onChange={(
                                        event: React.BaseSyntheticEvent,
                                    ) => {
                                        formik.setFieldValue(
                                            "claimCustomerInfo",
                                            event.target.value,
                                        );
                                    }}
                                    variant="outlined"
                                    error={
                                        !!formik.errors.claimCustomerInfo &&
                                        !!formik.touched.claimCustomerInfo
                                    }
                                />
                                {!!formik.errors.claimCustomerInfo &&
                                    !!formik.touched.claimCustomerInfo && (
                                        <FormHelperText error>
                                            {
                                                formik.errors
                                                    .claimCustomerInfo as string
                                            }
                                        </FormHelperText>
                                    )}
                            </Grid>
                        </Fragment>
                    )}
                    {isUkTeam && (
                        <Fragment>
                            <Grid item xs={12} md={6}>
                                <InputLabel
                                    shrink
                                    id={`claim-document-date`}
                                    error={
                                        !!formik.errors.claimDocumentDate &&
                                        !!formik.touched.claimDocumentDate
                                    }
                                >
                                    {"Claim document date (*)"}
                                </InputLabel>

                                <DatePicker
                                    value={datePlaceholder(
                                        formik.values.claimDocumentDate,
                                    )}
                                    onChange={value => {
                                        formik.setFieldTouched(
                                            "claimDocumentDate",
                                            true,
                                        );
                                        if (!value || !value?.isValid)
                                            formik.setFieldValue(
                                                "claimDocumentDate",
                                                "",
                                            );

                                        if (value) {
                                            formik.setFieldValue(
                                                "claimDocumentDate",
                                                convertDateForAPI(value),
                                            );
                                        }
                                    }}
                                    format="dd/MM/yyyy"
                                    disabled={disabled}
                                    slotProps={{
                                        textField: {
                                            variant: "outlined",
                                            autoComplete: "off",
                                            sx: {
                                                svg: {
                                                    color: "#036",
                                                    opacity: "0.5",
                                                },
                                            },
                                            size: "small",
                                            fullWidth: true,
                                            placeholder: "dd/mm/yyyy",
                                            required: true,
                                            error:
                                                !!formik.errors
                                                    .claimDocumentDate &&
                                                !!formik.touched
                                                    .claimDocumentDate,
                                            id: `claimDocumentDate`,
                                            name: "claimDocumentDate",
                                        },
                                    }}
                                    //  minDate={defaultEndMinDate()}
                                    slots={{
                                        // Override default <ActionBar /> with a custom one
                                        openPickerIcon: CalendarTodayIcon,
                                    }}
                                />
                                {!!formik.errors.claimDocumentDate &&
                                    !!formik.touched.claimDocumentDate && (
                                        <FormHelperText error>
                                            {
                                                formik.errors
                                                    .claimDocumentDate as string
                                            }
                                        </FormHelperText>
                                    )}
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <InputLabel
                                    error={
                                        !!formik.errors.claimDueDate &&
                                        !!formik.touched.claimDueDate
                                    }
                                    shrink
                                    id={`claim-due-date-to-label`}
                                >
                                    {"Claim due date (*)"}
                                </InputLabel>

                                <DatePicker
                                    value={datePlaceholder(
                                        formik.values.claimDueDate,
                                    )}
                                    onChange={value => {
                                        formik.setFieldTouched(
                                            "claimDueDate",
                                            true,
                                        );
                                        if (!value || !value.isValid)
                                            formik.setFieldValue(
                                                "claimDueDate",
                                                "",
                                            );

                                        if (value) {
                                            formik.setFieldValue(
                                                "claimDueDate",
                                                convertDateForAPI(value),
                                            );
                                        }
                                    }}
                                    format="dd/MM/yyyy"
                                    slotProps={{
                                        textField: {
                                            variant: "outlined",
                                            autoComplete: "off",
                                            sx: {
                                                svg: {
                                                    color: "#036",
                                                    opacity: "0.5",
                                                },
                                            },
                                            size: "small",
                                            fullWidth: true,
                                            placeholder: "dd/mm/yyyy",
                                            required: true,
                                            error:
                                                !!formik.errors.claimDueDate &&
                                                !!formik.touched.claimDueDate,
                                            id: `invoice-du-date`,
                                            name: "claimDueDate",
                                        },
                                    }}
                                    slots={{
                                        // Override default <ActionBar /> with a custom one
                                        openPickerIcon: CalendarTodayIcon,
                                    }}
                                    disabled={disabled}
                                />
                                {!!formik.errors.claimDueDate &&
                                    !!formik.touched.claimDueDate && (
                                        <FormHelperText error>
                                            {
                                                formik.errors
                                                    .claimDueDate as string
                                            }
                                        </FormHelperText>
                                    )}
                            </Grid>
                        </Fragment>
                    )}
                    <Grid item xs={12} mt={8}>
                        <Dropzone
                            disabled={!!formik.values?.file || disabled}
                            variant="big"
                            id={"upload-claim-file"}
                            fileTypes={{
                                "application/vnd.ms-excel": [".xls"],
                                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                                    [".xlsx"],
                            }}
                            files={[formik.values?.file]}
                            onUpload={uploadFiles => {
                                formik.setFieldValue("file", uploadFiles);
                            }}
                            canDeleteAttachments={!disabled}
                            onAttachmentRemove={() =>
                                formik.setFieldValue("file", undefined)
                            }
                            maxNameLength="114"
                        />
                    </Grid>
                </Grid>
            </form>
        );
    },
);
export default ClaimDetails;
