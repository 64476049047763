// Lib
import {
    Typography,
    Box,
    Grid,
    InputLabel,
    TextField,
    FormHelperText,
} from "@mui/material";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { NumericFormat } from "react-number-format";

// Own components
import { Dialog, Table, Modal, SearchBar, Select } from "@components";

// Constants
import { HEADERS, ROWRENDERERCONST } from "@constants";

// Hooks
import { useMaintenance } from "@hooks";

// Utils
import {
    isArrayWithContent,
    isSuccessfulCall,
    sortByField,
    trimAndFormat,
} from "@utils";

// Types
import type { Country, Role as RoleType } from "@types";

/**
 * Props type
 */
interface Props {
    id?: string;
}

const INITIAL_VALUES = {
    roleName: "",
    roleCode: "",
    approvalLevel: "",
    country: {
        isoCode: "",
        name: "",
    },
};

/**
 * Maintenance Roles
 */
const Roles = ({ id = "maintenance-roles" }: Props) => {
    const [openEditModal, toggleEditModal] = useState<boolean>(false);
    const [roleEditMode, setRoleEditMode] = useState<RoleType | undefined>();
    const [roleDeleteMode, setRoleDeleteMode] = useState<
        RoleType | undefined
    >();
    const [touched, setTouched] = useState<Record<string, boolean>>({});

    /**
     * Fetch countries
     */

    const {
        list: roles,
        loading: { listLoading: loadingRoles },
        upsert,
        delete: deleteRole,
    } = useMaintenance("role", "role|roles");

    /**
     * Fetch countries
     */

    const {
        list: countries,
        loading: { listLoading: loadingCountries },
        reload: loadCountries,
    } = useMaintenance("country", "country|countries", false);

    /**
     * Handle change
     */
    const onChange = (key: string, value: string | Country) => {
        setRoleEditMode(prev => {
            if (!prev) return prev;
            return {
                ...prev,
                [key]: value,
            };
        });
    };

    /**
     * Countries mapper
     */
    const mappedCountries: Array<Country> = useMemo(() => {
        if (
            !openEditModal ||
            !isArrayWithContent(countries?.maintenanceCountryList)
        )
            return [];
        return countries?.maintenanceCountryList.map(country => {
            return {
                isoCode: country.countryIsoCode,
                name: country.country,
            };
        });
    }, [countries?.maintenanceCountryList, openEditModal]);

    useEffect(() => {
        if (!openEditModal) return;
        loadCountries();
    }, [openEditModal]);

    /**
     * Handle Blur
     */
    const onBlur = (key: string) => {
        const copyTouched = { ...touched };
        if (copyTouched[key]) return;
        copyTouched[key] = true;
        setTouched(copyTouched);
    };

    /**
     * Rest all
     */
    const resetAll = () => {
        setTouched({});
        setRoleEditMode(undefined);
        setRoleDeleteMode(undefined);
        toggleEditModal(false);
    };

    /**
     * Validate role per country
     * Check if the role already exists for the selected country
     * Check if the role is not the same (Add new role) and not editing the same role
     */
    const isValidRole = useMemo(() => {
        if (
            !isArrayWithContent(roles?.maintenanceRoleList) ||
            !roleEditMode?.country?.isoCode ||
            !roleEditMode?.roleCode
        )
            return true;

        const foundRole = roles?.maintenanceRoleList?.find(
            role =>
                role?.roleId !== roleEditMode?.roleId && // if the role is not the same (Add new role)
                role?.roleCode === roleEditMode?.roleCode &&
                role?.country.isoCode === roleEditMode?.country?.isoCode,
        );
        return !foundRole;
    }, [
        roleEditMode?.country?.isoCode,
        roleEditMode?.roleCode,
        roleEditMode?.roleId,
        roles?.maintenanceRoleList,
    ]);

    /**
     * Disabled
     */
    const disabled =
        loadingCountries ||
        !isValidRole ||
        !roleEditMode?.roleName ||
        !roleEditMode?.roleCode ||
        !roleEditMode?.approvalLevel ||
        (!!roleEditMode?.approvalLevel &&
            (parseInt(roleEditMode?.approvalLevel) < 1 ||
                parseInt(roleEditMode?.approvalLevel) > 9)) ||
        !roleEditMode?.country?.isoCode;

    /**
     * Submit handler
     */
    const onSubmit = () => {
        if (disabled) return;
        const mapApiData = {
            roleId: roleEditMode?.roleId ? roleEditMode?.roleId : undefined,
            roleName: roleEditMode?.roleName,
            roleCode: roleEditMode?.roleCode,
            approvalLevel: roleEditMode?.approvalLevel,
            countryIsoCode: roleEditMode?.country?.isoCode,
        };

        upsert(mapApiData, roleEditMode.roleName).then(res => {
            if (isSuccessfulCall(res?.status)) {
                resetAll();
            }
        });
    };

    const onDelete = () => {
        if (roleDeleteMode?.roleId) {
            deleteRole(roleDeleteMode.roleId, roleDeleteMode?.roleName).then(
                res => {
                    if (isSuccessfulCall(res?.status)) {
                        resetAll();
                    }
                },
            );
        }
    };

    /**
     * Sort roles by country iso code
     */
    const sortedRoles = useMemo(() => {
        if (!isArrayWithContent(roles?.maintenanceRoleList)) return [];
        return sortByField(roles?.maintenanceRoleList, "country", "isoCode");
    }, [roles?.maintenanceRoleList]);

    /**
     * Render
     */
    return (
        <Fragment>
            <Dialog
                open={!!roleDeleteMode}
                id={`${id}-delete-role`}
                title="Delete Role"
                message={
                    <Typography
                        variant="subtitle2"
                        color="black"
                        component="span"
                    >
                        Are you sure you want to delete this approval role
                        <Typography
                            variant="body2"
                            component="span"
                        >{` ${roleDeleteMode?.roleName}`}</Typography>
                        ?
                    </Typography>
                }
                primaryButton={{
                    text: "Delete",
                    action: () => onDelete(),
                }}
                secondaryButton={{
                    text: "Cancel",
                    action: () => resetAll(),
                }}
            />

            <Box mt={5}>
                <Modal
                    open={openEditModal}
                    id={`edit-role`}
                    title={roleEditMode?.roleId ? "Edit Role" : "Add Role"}
                    onClose={() => {
                        resetAll();
                    }}
                    primaryButton={{
                        action: () => {
                            onSubmit();
                        },
                        text: "Submit",
                        disabled,
                    }}
                    secondaryButton={{
                        action: () => {
                            resetAll();
                        },
                        text: "Cancel",
                    }}
                >
                    <Grid
                        item
                        xs={12}
                        container
                        rowSpacing={3}
                        columnSpacing={4}
                    >
                        <Grid item xs={12} md={6}>
                            <InputLabel
                                shrink
                                id={`${id}-role-name`}
                                error={
                                    touched?.roleName && !roleEditMode?.roleName
                                }
                            >
                                {"Role (*)"}
                            </InputLabel>

                            <TextField
                                id={`${id}-role-name`}
                                fullWidth
                                size="small"
                                autoComplete="off"
                                error={
                                    touched?.roleName && !roleEditMode?.roleName
                                }
                                onBlur={() => onBlur("roleName")}
                                name="roleName"
                                value={roleEditMode?.roleName}
                                onChange={(event: React.BaseSyntheticEvent) => {
                                    onChange("roleName", event?.target.value);
                                }}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputLabel
                                shrink
                                id={`${id}-role-id`}
                                error={
                                    !isValidRole ||
                                    (touched?.roleCode &&
                                        !roleEditMode?.roleCode)
                                }
                            >
                                {"Role ID (*)"}
                            </InputLabel>

                            <TextField
                                id={`${id}-role-id`}
                                fullWidth
                                size="small"
                                autoComplete="off"
                                name="roleCode"
                                error={
                                    !isValidRole ||
                                    (touched?.roleCode &&
                                        !roleEditMode?.roleCode)
                                }
                                onBlur={() => onBlur("roleCode")}
                                value={roleEditMode?.roleCode}
                                onChange={(event: React.BaseSyntheticEvent) => {
                                    onChange(
                                        "roleCode",
                                        trimAndFormat(event.target.value, true),
                                    );
                                }}
                                variant="outlined"
                            />
                            {!isValidRole && (
                                <FormHelperText error>
                                    The role ID already exists for the selected
                                    country, please enter a different role ID
                                </FormHelperText>
                            )}
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputLabel
                                shrink
                                id={`${id}-approval-level`}
                                error={
                                    (touched?.approvalLevel &&
                                        !roleEditMode?.approvalLevel) ||
                                    (!!roleEditMode?.approvalLevel &&
                                        (parseInt(roleEditMode?.approvalLevel) <
                                            1 ||
                                            parseInt(
                                                roleEditMode?.approvalLevel,
                                            ) > 9))
                                }
                            >
                                {"Role approval level (*)"}
                            </InputLabel>

                            <NumericFormat
                                id={`${id}-role-approval-level`}
                                fullWidth
                                size="small"
                                autoComplete="off"
                                name="approvalLevel"
                                value={roleEditMode?.approvalLevel}
                                variant="outlined"
                                thousandSeparator="."
                                decimalSeparator=","
                                error={
                                    (touched?.approvalLevel &&
                                        !roleEditMode?.approvalLevel) ||
                                    (!!roleEditMode?.approvalLevel &&
                                        (parseInt(roleEditMode?.approvalLevel) <
                                            1 ||
                                            parseInt(
                                                roleEditMode?.approvalLevel,
                                            ) > 9))
                                }
                                onBlur={() => onBlur("approvalLevel")}
                                customInput={TextField}
                                decimalScale={0}
                                isAllowed={values => {
                                    const { floatValue } = values;
                                    if (
                                        typeof floatValue !== "number" && // initialize it with empty string
                                        !floatValue
                                    )
                                        return true;
                                    return (
                                        !!floatValue &&
                                        floatValue >= 1 &&
                                        floatValue <= 9
                                    );
                                }}
                                allowNegative={false}
                                onValueChange={({ floatValue }) => {
                                    onChange(
                                        "approvalLevel",
                                        floatValue?.toString() ?? "",
                                    );
                                }}
                            />
                            <FormHelperText
                                error={
                                    (touched?.approvalLevel &&
                                        !roleEditMode?.approvalLevel) ||
                                    (!!roleEditMode?.approvalLevel &&
                                        (parseInt(roleEditMode?.approvalLevel) <
                                            1 ||
                                            parseInt(
                                                roleEditMode?.approvalLevel,
                                            ) > 9))
                                }
                            >
                                The approval level must be a number between 1
                                and 9
                            </FormHelperText>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <InputLabel
                                shrink
                                id={`${id}-country`}
                                error={
                                    touched?.country &&
                                    !roleEditMode?.country?.isoCode
                                }
                            >
                                {"Country code (*)"}
                            </InputLabel>
                            <Select
                                id={`${id}-select-country-list`}
                                name="countries"
                                onBlur={() => onBlur("country")}
                                onChange={(_, idx) =>
                                    onChange("country", mappedCountries[idx])
                                }
                                menuItemId="isoCode"
                                menuItemLabel="name"
                                error={
                                    touched?.country &&
                                    !roleEditMode?.country?.isoCode
                                }
                                list={mappedCountries}
                                loading={loadingCountries}
                                value={roleEditMode?.country}
                                disabled={loadingCountries}
                            />
                        </Grid>
                    </Grid>
                </Modal>

                <Box mt={2} mb={4} display="flex" justifyContent="flex-end">
                    <SearchBar
                        id={`${id}-add-new-role`}
                        action={{
                            label: "New Role",
                            callback: () => {
                                toggleEditModal(true);
                                setRoleEditMode(INITIAL_VALUES);
                            },
                            disabled: undefined,
                        }}
                        hasFilters={false}
                    />
                </Box>
                <Box display="flex" alignItems="baseline" mb={4}>
                    <Typography variant="h2">Roles</Typography>
                    {isArrayWithContent(roles?.maintenanceRoleList) && (
                        <Typography ml={1} variant="caption1">
                            {` ${roles?.maintenanceRoleList?.length} ${
                                roles?.maintenanceRoleList?.length === 1
                                    ? "role"
                                    : "roles"
                            } found`}
                        </Typography>
                    )}
                </Box>
                <Table
                    id={`${id}-roles-list`}
                    headers={HEADERS.MAINTENANCE_ROLES}
                    rows={sortedRoles}
                    loading={loadingRoles}
                    type={ROWRENDERERCONST.MAINTENANCE_ROLES}
                    callbacks={{
                        onEdit: (_, itemIndex) => {
                            toggleEditModal(true);
                            setRoleEditMode(
                                roles?.maintenanceRoleList[itemIndex],
                            );
                        },
                        onDelete: (_, itemIndex) =>
                            setRoleDeleteMode(
                                roles?.maintenanceRoleList[itemIndex],
                            ),
                    }}
                    emptyMsg="No roles found"
                />
            </Box>
        </Fragment>
    );
};
export default React.memo(Roles);
