import { ErrorOutline } from "@mui/icons-material";
import Info from "@mui/icons-material/Info";
import { Alert, Box, Typography } from "@mui/material";
import classnames from "classnames";

import * as React from "react";

//style
import * as style from "./style.module.scss";
/**
 * Props type
 */
interface Props {
    message: string | React.ReactNode;
    title?: string;
    id: string;
    warning?: boolean;
    warningColor?: boolean;
}

/**
 * Info box
 */
const InfoBox: React.FC<Props> = ({
    message,
    title,
    id,
    warning,
    warningColor,
}) => {
    return (
        <Alert
            id={`${id}-infobox`}
            variant="outlined"
            severity="info"
            icon={false}
            className={classnames(style.boxWrapper, {
                [style.infobox]: !warning,
                [style.warningBox]: warning,
                [style.warningColor]: warningColor,
            })}
        >
            <Box display="flex" alignItems="center" className={style.infoTitle}>
                {warning ? (
                    <ErrorOutline className={style.icon} />
                ) : (
                    <Info className={style.icon} />
                )}
                <Box>
                    {!!title && (
                        <Typography
                            id={`${id}-infobox-title`}
                            variant={warning ? "h3" : "caption1"}
                            className={classnames({
                                [style.infoTitle]: !warning,
                            })}
                        >
                            {title}
                        </Typography>
                    )}
                    {warning &&
                        (typeof message === "string" ? (
                            <Typography
                                id={`${id}-infobox-sub-title-warning`}
                                variant="subtitle2"
                            >
                                {message}
                            </Typography>
                        ) : (
                            message
                        ))}
                </Box>
            </Box>
            {!warning &&
                (typeof message === "string" ? (
                    <Typography
                        id={`${id}-infobox-sub-title`}
                        variant="subtitle2"
                    >
                        {message}
                    </Typography>
                ) : (
                    message
                ))}
        </Alert>
    );
};

export default InfoBox;
