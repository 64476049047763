// extracted by mini-css-extract-plugin
export var actions = "style-module--actions--41bca";
export var arrow = "style-module--arrow--1fad2";
export var boxWrapper = "style-module--boxWrapper--be702";
export var divider = "style-module--divider--3afc6";
export var error = "#ff3131";
export var primary = "#036";
export var secondary = "#019cdc";
export var splitter = "style-module--splitter--4a914";
export var textfont = "\"Verdana Pro\"";
export var titlefont = "\"Karbon\"";
export var white = "#fff";