// Import libs
import { Box, Typography } from "@mui/material";
import { useFormik } from "formik";
import React, {
    useEffect,
    useImperativeHandle,
    useMemo,
    useState,
} from "react";
import clonedeep from "lodash.clonedeep";
import { useRecoilValue } from "recoil";

//Own components
import {
    CreateTeamForm,
    FormFooter,
    LoadingWrapper,
    Dialog,
} from "@components";

// Custom hooks
import {
    useGetTeam,
    useUpdateStep,
    useMaintenance,
    useGetRights,
} from "@hooks";

// Recoil
import { createContractTeamState, createTeamStepsState } from "@atoms";

// Schemas
import { createTeamSchema } from "@schemas";

// Types
import { Country, Location, TeamRole } from "@types";
import { isArrayWithContent } from "@utils";

/**
 * Props type
 */
interface Props {
    location: Location;
    isEditMode?: boolean;
    creatingTeam?: boolean;
    teamId?: string;
    id?: string;
}

type User = {
    email: string;
    objectId: string;
    rightIds: string[];
    approvalRole?: TeamRole;
    scpUserId: string;
};

/**
 * Create Team
 */
const CreateTeam = React.forwardRef(
    ({ location, isEditMode, teamId, creatingTeam, id }: Props, ref) => {
        const [newCountry, setNewCountryWarning] = useState<
            Country | undefined
        >();
        const [isEditingUser, setEditingUser] = useState(false);
        /**
         * update step
         */
        const updateStepValidation = useUpdateStep(
            location,
            createTeamStepsState,
        );

        /**
         * Create team state
         */
        const initialTeam = useRecoilValue(createContractTeamState);

        /**
         * Fetch therapeutic areas
         */

        const {
            list: therapeuticAreas,
            loading: { listLoading: therapeuticAreasLoading },
        } = useMaintenance(
            "therapeuticArea",
            "therapeutic area|therapeutic areas",
        );

        const {
            list: roles,
            loading: { listLoading: loadingRoles },
            reload: loadRoles,
        } = useMaintenance("role", "role|roles", false);

        /**
         * Fetch countries
         */

        const {
            list: countries,
            loading: { listLoading: loadingCountries },
        } = useMaintenance("country", "country|countries");

        /**
         *  Get team by ID if the mode is editMode
         */
        const { getTeam, response: team, loading: teamLoading } = useGetTeam();

        /**
         * Get rights
         */
        const {
            getRights,
            response: rights,
            loading: rightsLoading,
        } = useGetRights();

        /**
         * Formik state
         */
        const formik = useFormik({
            initialValues: initialTeam,
            validationSchema: createTeamSchema,
            enableReinitialize: true,
            validateOnMount: true,
            onSubmit: () => undefined,
        });

        useEffect(() => {
            if (!isEditMode || !team?.data) return;

            formik.setValues(team.data);
        }, [isEditMode, team?.data]);

        useEffect(() => {
            if (formik.values?.countries?.length === 0) return;

            // Fetch roles based on the selected country
            if (formik.values?.countries?.length === 1) {
                const country = formik.values?.countries[0] as Country;
                loadRoles(`?countryIsoCode=${country.isoCode}`);
            }
        }, [formik.values.countries]);

        /**
         * Check if the form has at least one error
         */
        const hasErrors = Object.keys(formik.errors).some(
            key => formik.touched[key],
        );

        useEffect(() => {
            if (isEditMode && !!teamId) {
                getTeam(teamId);
            }
        }, [isEditMode, teamId]);

        /**
         * Load Therapeutic Areas
         */
        useEffect(() => {
            getRights();
        }, []);

        /**
         * Save state (controlled by the layout)
         */
        useImperativeHandle(ref, () => ({
            updateState() {
                const mappedCountriesForApi = formik.values.countries.map(
                    (el: Country) => el.isoCode,
                );
                const mapUsers: User[] = [];

                if (isArrayWithContent(formik.values.users)) {
                    formik.values.users.forEach((user: User) => {
                        const copyUser: User = clonedeep(user);

                        copyUser["approvalRoleId"] = user.approvalRole?.roleId
                            ? user.approvalRole?.roleId
                            : undefined;
                        delete copyUser?.approvalRole;

                        mapUsers.push(copyUser);
                    });
                }

                return {
                    ...formik.values,
                    users: mapUsers,
                    countries: mappedCountriesForApi,
                };
            },
        }));

        /**
         * Form validation check
         */
        const canSubmit =
            !isEditingUser &&
            ((isEditMode && Object.keys(formik.errors).length === 0) ||
                (!!formik &&
                    !!formik.isValid &&
                    Object.keys(formik.errors).length === 0 &&
                    Object.keys(formik.touched).length > 0));

        /**
         * Check if the current step is valid => set isPrepared to true, otherwise set it false
         */
        useEffect(() => {
            if (location) {
                updateStepValidation(canSubmit);
            }
        }, [canSubmit]);

        /**
         * Group loading status
         */
        const isLoading = useMemo(() => {
            return (
                loadingCountries ||
                therapeuticAreasLoading ||
                rightsLoading ||
                teamLoading
            );
        }, [
            loadingCountries,
            therapeuticAreasLoading,
            rightsLoading,
            teamLoading,
        ]);

        /**
         * Render
         */
        return (
            <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                height={1}
            >
                <Dialog
                    title={"Reset users list"}
                    id={`reset-flow-dialog`}
                    open={!!newCountry}
                    message={
                        <Typography
                            variant="subtitle2"
                            color="black"
                            component="span"
                        >
                            {`By changing the country, you'll reset the list of users. Are you certain you want to proceed with this action?`}
                        </Typography>
                    }
                    primaryButton={{
                        text: "Confirm",
                        action: () => {
                            setNewCountryWarning(undefined);
                            formik.setFieldValue("users", []);
                            formik.setFieldValue("countries", [newCountry]);
                        },
                    }}
                    secondaryButton={{
                        text: "Cancel",
                        action: () => setNewCountryWarning(undefined),
                    }}
                />

                <LoadingWrapper loading={isLoading} fullHeight id="teams">
                    <div>
                        <Box mb={7}>
                            {isEditMode ? (
                                <Typography variant="h2">
                                    Edit contract team
                                </Typography>
                            ) : (
                                <Typography variant="h2">
                                    New contract team
                                </Typography>
                            )}
                        </Box>

                        {((isEditMode && !!team?.data) || !isEditMode) && (
                            <CreateTeamForm
                                values={formik.values}
                                errors={formik.errors}
                                touched={formik.touched}
                                onBlur={formik.handleBlur}
                                setFieldValue={formik.setFieldValue}
                                countryList={{
                                    data: countries?.maintenanceCountryList,
                                    loading: loadingCountries,
                                }}
                                therapeuticAreaList={{
                                    data: therapeuticAreas?.maintenanceTherapeuticAreaList,
                                    loading: therapeuticAreasLoading,
                                }}
                                id={`${id}-create-new-team`}
                                handleChange={formik.handleChange}
                                disabled={creatingTeam}
                                rights={{
                                    data: rights?.data,
                                }}
                                setEditingUser={setEditingUser}
                                roles={{
                                    data: roles?.maintenanceRoleList,
                                    loading: loadingRoles,
                                }}
                                setResetUsersWarning={setNewCountryWarning}
                            />
                        )}
                    </div>
                    <FormFooter
                        error={hasErrors}
                        textAlign="right"
                        id={`${id}-create-new-team-footer`}
                    />
                </LoadingWrapper>
            </Box>
        );
    },
);

export default CreateTeam;
