// extracted by mini-css-extract-plugin
export var card = "style-module--card--a4234";
export var details = "style-module--details--80a18";
export var error = "#ff3131";
export var partner = "style-module--partner--98913";
export var primary = "#036";
export var secondary = "#019cdc";
export var separator = "style-module--separator--9751b";
export var text = "style-module--text--c49af";
export var textfont = "\"Verdana Pro\"";
export var titlefont = "\"Karbon\"";
export var white = "#fff";
export var wrapper = "style-module--wrapper--91340";