// Lib
import AssignmentIcon from "@mui/icons-material/Assignment";
import { Box, Typography, Grid } from "@mui/material";
import React, { useEffect, useMemo, Fragment, useLayoutEffect } from "react";
import { useFormik } from "formik";
import xnv from "xml-name-validator";

import { navigate } from "gatsby";

// Own components
import {
    Breadcrumbs,
    StepActions,
    LoadingWrapper,
    FormFooter,
    FlowLayout,
    FlowBody,
    FlowContent,
    FlowFooter,
    FieldRenderer,
    OrderDetails,
    ClaimDetails,
    Card,
    TaskDataApprovalDetails,
    TaskPartnerApprovalDetails,
} from "@components";

// Types
import type { Location, Permissions } from "@types";

// Schemas
import {
    taskDetailsUploadDmnSchema,
    taskDetailsApprovalSchema,
    taskDetailsReviewContractSchema,
    taskDetailsChangeInfusionDateSchema,
    taskDetailsCancelOutcomeSchema,
    taskDetailsClaimApprovalSchema,
    taskDetailsOverrideApprovalSchema,
    taskDetailsCancelClaimSchema,
    taskDetailsPriceDataSchema,
    taskDetailsLoadDataSchema,
    taskDetailsPriceCorrectionClaimApprovalTask,
    taskDetailsPartnerDataSchema,
    taskDetailsChangeContractDateSchema,
} from "@schemas";

// Hooks
import {
    useTasks,
    useValidateDuplication,
    useGetContract,
    useFormValidation,
    usePermission,
    useUploadDocument,
    useGetClaimById,
    usePriceDataFile,
    useLoadDataFile,
    useTaskMapper,
    useDownloadApheresisPdf,
    useSelectedCountry,
} from "@hooks";

// style
import * as style from "./style.module.scss";

// Own containers
import OutcomeApprovalTask from "./OutcomeApprovalTask";
import ReviewTask from "./ReviewTask";
import UploadDmn from "./UploadDmn";
import InfusionDateChange from "./InfusionDateChange";
import CancelOutcome from "./CancelOutcome";
import OverrideApprovalTask from "./OverrideApprovalTask";
import PriceData from "./PriceData";
import LoadData from "./LoadData";

// Utils
import { isArrayWithContent, isSuccessfulCall } from "@utils";

// Task sections
import ClaimApprovalTask from "./ClaimApprovalTask";
import CancelClaim from "./CancelClaim";
import ContractReviewSection from "./ContractReviewSection";
import CompletedTasksSection from "./CompletedTasksSection";
import PriceCorrectionApprovalTask from "./PriceCorrectionApprovalTask";
import PartnerDataApprovalTask from "./PartnerDataApprovalTask";
import ContractDateChange from "./ContractDateChange";

/**
 * Props type
 */
interface Props {
    location: Location;
    taskId: string;
}

const INITIAL_STATES = {
    outcomeApprovalTask: {
        approved: "approved",
        contractRef: "",
        orderId: "",
        overrideReason: "",
        decisionInfo: "",
        newRebateAmount: "",
        rejectionReason: "",
        newClaimAmount: "",
    },
    createDmnTask: {
        xml: "",
        dmnId: "",
    },
    contractReviewTask: {},
};

/**
 * Schema mapper
 */

const MAP_SCHEMA = {
    outcomeApprovalTask: taskDetailsApprovalSchema,
    createDmnTask: taskDetailsUploadDmnSchema,
    infusionDateChangeApprovalTask: taskDetailsChangeInfusionDateSchema,
    contractDateChangeApprovalTask: taskDetailsChangeContractDateSchema,
    cancelOutcomeApprovalTask: taskDetailsCancelOutcomeSchema,
    claimApprovalTask: taskDetailsClaimApprovalSchema,
    contractReviewTask: taskDetailsReviewContractSchema,
    claimOverrideApprovalTask: taskDetailsOverrideApprovalSchema,
    cancelClaimApprovalTask: taskDetailsCancelClaimSchema,
    priceDataApprovalTask: taskDetailsPriceDataSchema,
    priceCorrectionClaimApprovalTask:
        taskDetailsPriceCorrectionClaimApprovalTask,
    evidenceDataApproval: taskDetailsLoadDataSchema,
    iqviaDataApprovalTask: taskDetailsLoadDataSchema,
    lauerDataApprovalTask: taskDetailsLoadDataSchema,
    accrualAdjustmentDataApprovalTask: taskDetailsLoadDataSchema,
    partnerDataApprovalTask: taskDetailsPartnerDataSchema,
    localAgreementDataApprovalTask: taskDetailsLoadDataSchema,
};

/**
 * Task details
 */
const TaskDetails = ({ location, taskId }: Props) => {
    /**
     * Permissions
     */
    const { hasPermissionToEditOutcome }: Permissions = usePermission();

    /**
     * Selected country
     */
    const { isUkTeam } = useSelectedCountry();

    /**
     * Hooks
     */
    const { mappedValues, setMappedValues, resetMappedValues } =
        useTaskMapper();

    // Check dmn uniqueness
    const {
        validate: validateDmn,
        isNotUniqueValue,
        loading: validatingDmn,
    } = useValidateDuplication("dmnId");

    /**
     * API
     */
    const {
        loading: {
            taskLoading,
            uploadDmnLoading,
            approveTaskLoading,
            reviewContractLoading,
            changeInfusionDateLoading,
            cancelOutcomeLoading,
            approveClaimLoading,
            cancelClaimLoading,
            priceDataLoading,
            loadDataLoading,
            approveClaimPriceCorrectionLoading,
            partnerDataLoading,
            changeContractDateLoading,
        },
        response: {
            task,
            uploadDmnResponse,
            approveTaskResponse,
            reviewContractResponse,
            changeInfusionDateResponse,
            cancelOutcomeResponse,
            approveClaimResponse,
            cancelClaimResponse,
            priceDataResponse,
            loadDataResponse,
            approveClaimPriceCorrectionResponse,
            partnerDataResponse,
            changeContractDateResponse,
        },
        error: { taskError },
        loadTask,
        uploadDmn,
        approveTask,
        reviewContract,
        changeInfusionDate,
        cancelOutcome,
        approveClaim,
        cancelClaim,
        approvePriceData,
        approveLoadData,
        approveClaimPriceCorrection,
        approvePartnerData,
        changeContractDate,
    } = useTasks();

    // Download price data file
    const {
        download: downloadPriceDataFile,
        loading: downloadingPriceDataFile,
    } = usePriceDataFile(taskId);

    // Download load data file
    const { download: downloadLoadDataFile, loading: downloadingLoadDataFile } =
        useLoadDataFile(taskId);

    // download apheresis
    const { loading: downloadingApheresis, download: downloadApheresisPdf } =
        useDownloadApheresisPdf();

    // Download Claim file(s)
    const {
        download: downloadClaimData,
        loading: { downloading, downloadingClaimAttachment },
        downloadClaimAttachment,
    } = useGetClaimById();

    // Fetch contract
    const {
        response: contract,
        loading: contractLoading,
        getContract,
        error: contractError,
    } = useGetContract();

    // Fetch evidences
    const {
        loading: {
            fetching: fetchingEvidences,
            downloading: downloadingEvidence,
        },
        list: evidences,
        load: loadEvidences,
        download: downloadEvidence,
    } = useUploadDocument();

    /**
     * Get Task by ID
     */
    useLayoutEffect(() => {
        if (!taskId) return;
        loadTask(taskId).then(res => {
            if (!res || !isSuccessfulCall(res?.status)) return;

            if (
                !res ||
                !res?.data?.contract?.id ||
                res?.data.task?.taskDefinitionKey ===
                    "infusionDateChangeApprovalTask" ||
                res?.data.task?.taskDefinitionKey === "claimApprovalTask" ||
                res?.data.task?.taskDefinitionKey === "cancelClaimApprovalTask"
            )
                return;
            getContract(res?.data?.contract?.id);
            loadEvidences(res?.data?.contract?.id);
        });
    }, [taskId]);

    /**
     * Check if the task is completed
     */
    const isTaskCompleted = useMemo(() => {
        return !!task?.data?.task?.completed;
    }, [task]);

    /**
     * Map contract
     */
    const contractTask = useMemo(() => {
        if (!task?.data?.contract) return;
        return task?.data?.contract;
    }, [task]);

    /**
     * Map  order
     */
    const orderTask = useMemo(() => {
        if (!task?.data?.order) return;
        return task?.data?.order;
    }, [task]);

    /**
     * Map Claim
     */
    const claimTask = useMemo(() => {
        if (!task?.data?.claim) return;
        return task?.data?.claim;
    }, [task]);

    /**
     * Find task type
     */
    const taskType = useMemo(() => {
        if (!task || !task?.data?.task?.taskDefinitionKey) return;

        const taskDefinitionKey = task?.data?.task?.taskDefinitionKey;

        const mappedClaimsTaskDefinitionKey =
            taskDefinitionKey === "claimApprovalTask"
                ? claimTask?.priceCorrection
                    ? "priceCorrectionClaimApprovalTask"
                    : "claimApprovalTask"
                : taskDefinitionKey;

        return {
            isOutcomeApprovalTask: taskDefinitionKey === "outcomeApprovalTask",
            isUploadDmn: taskDefinitionKey === "createDmnTask",
            isReviewTask: taskDefinitionKey === "contractReviewTask",
            isInfusionDateChangeApprovalTask:
                taskDefinitionKey === "infusionDateChangeApprovalTask",
            isChangeContractDateApprovalTask:
                taskDefinitionKey === "contractDateChangeApprovalTask",
            isCancelOutcomeTask:
                taskDefinitionKey === "cancelOutcomeApprovalTask",
            isClaimApprovalTask:
                taskDefinitionKey === "claimApprovalTask" &&
                !claimTask?.priceCorrection,
            isClaimOverrideApprovalTask:
                taskDefinitionKey === "claimOverrideApprovalTask",
            isCancelClaimApprovalTask:
                taskDefinitionKey === "cancelClaimApprovalTask",
            isPriceDataApprovalTask:
                taskDefinitionKey === "priceDataApprovalTask",
            isEvidenceDataApprovalTask:
                taskDefinitionKey === "evidenceDataApprovalTask",
            isIqviaDataApprovalTask:
                taskDefinitionKey === "iqviaDataApprovalTask",
            isAccrualAdjustmentDataApprovalTask:
                taskDefinitionKey === "accrualAdjustmentDataApprovalTask",
            isLauerDataApprovalTask:
                taskDefinitionKey === "lauerDataApprovalTask",
            isPriceCorrectionClaimApprovalTask:
                taskDefinitionKey === "claimApprovalTask" &&
                !!claimTask?.priceCorrection,
            isPartnerDataApprovalTask:
                taskDefinitionKey === "partnerDataApprovalTask",
            isLocalAgreementDataApprovalTask:
                taskDefinitionKey === "localAgreementDataApprovalTask",
            isApheresisDataLoadApprovalTask:
                taskDefinitionKey === "apheresisDataApprovalTask",
            schema: MAP_SCHEMA[mappedClaimsTaskDefinitionKey],
        };
    }, [task, claimTask?.priceCorrection]);

    /**
     * Find task schema
     */
    const taskSchema = useMemo(() => {
        if (!taskType || !taskType.schema) return;

        return taskType.schema;
    }, [taskType]);

    /**
     * Formik state
     */
    const formik: any = useFormik<any>({
        enableReinitialize: true,
        validateOnMount: true,
        initialValues: {},
        validationSchema: taskSchema,
        onSubmit: () => undefined,
    });

    /**
     * Initialize formik with the right state
     */
    useEffect(() => {
        if (!task) return;

        const initialValue =
            INITIAL_STATES[task?.data?.task?.taskDefinitionKey] || {};

        formik.setValues(initialValue);
    }, [task]);

    /**
     * Reset if submit is ok
     */
    useEffect(() => {
        const isSuccessResponse = response =>
            response && isSuccessfulCall(response.status);

        const apiResponses = [
            { loading: uploadDmnLoading, response: uploadDmnResponse },
            { loading: approveTaskLoading, response: approveTaskResponse },
            {
                loading: reviewContractLoading,
                response: reviewContractResponse,
            },
            {
                loading: changeInfusionDateLoading,
                response: changeInfusionDateResponse,
            },
            { loading: cancelOutcomeLoading, response: cancelOutcomeResponse },
            { loading: approveClaimLoading, response: approveClaimResponse },
            { loading: cancelClaimLoading, response: cancelClaimResponse },
            { loading: priceDataLoading, response: priceDataResponse },
            { loading: loadDataLoading, response: loadDataResponse },
            {
                loading: approveClaimPriceCorrectionLoading,
                response: approveClaimPriceCorrectionResponse,
            },
            {
                loading: partnerDataLoading,
                response: partnerDataResponse,
            },
            {
                loading: changeContractDateLoading,
                response: changeContractDateResponse,
            },
        ];

        const shouldResetForm = () => {
            for (const { loading, response } of apiResponses) {
                if (!loading && isSuccessResponse(response)) {
                    return true;
                }
            }
            return false;
        };

        if (taskId && shouldResetForm()) {
            formik.resetForm();
            navigate("/tasks/ongoing-tasks/");
        }
    }, [
        uploadDmnLoading,
        approveTaskLoading,
        reviewContractLoading,
        changeInfusionDateLoading,
        cancelOutcomeLoading,
        approveClaimLoading,
        cancelClaimLoading,
        priceDataLoading,
        loadDataLoading,
        approveClaimPriceCorrectionLoading,
        changeContractDateLoading,
        uploadDmnResponse,
        approveTaskResponse,
        reviewContractResponse,
        changeInfusionDateResponse,
        cancelOutcomeResponse,
        approveClaimResponse,
        cancelClaimResponse,
        priceDataResponse,
        loadDataResponse,
        approveClaimPriceCorrectionResponse,
        partnerDataLoading,
        partnerDataResponse,
        changeContractDateResponse,
        taskId,
    ]);

    /**
     * Form validation
     */

    const { hasErrors } = useFormValidation(formik.errors, formik.touched);

    /**
     * Check if dmn id is valid q name
     */
    const validateDmnId = useMemo(() => {
        if (!formik?.values?.dmnId) return;
        return xnv.qname(formik.values.dmnId);
    }, [formik?.values?.dmnId]);

    /**
     * Form validation check
     */
    const canSaveState =
        (taskType?.isUploadDmn && validateDmnId) ||
        Object.keys(formik.errors).length === 0 ||
        (!!formik &&
            !!formik.isValid &&
            Object.keys(formik.errors).length === 0 &&
            Object.keys(formik.touched).length > 0);

    /**
     * Disable submit
     */
    const disableSubmit = useMemo(
        () =>
            !canSaveState ||
            taskError ||
            taskLoading ||
            uploadDmnLoading ||
            reviewContractLoading ||
            approveTaskLoading ||
            cancelOutcomeLoading ||
            approveClaimLoading ||
            cancelClaimLoading ||
            priceDataLoading ||
            approveClaimPriceCorrectionLoading ||
            loadDataLoading ||
            partnerDataLoading,
        [
            canSaveState,
            taskError,
            taskLoading,
            uploadDmnLoading,
            reviewContractLoading,
            approveTaskLoading,
            cancelOutcomeLoading,
            approveClaimLoading,
            priceDataLoading,
            loadDataLoading,
            cancelClaimLoading,
            approveClaimPriceCorrectionLoading,
            partnerDataLoading,
        ],
    );

    const canApproveTasks = !isTaskCompleted && !!task?.data?.task?.actionable;

    const hasContractDetails = useMemo(() => {
        if (!taskType) return false;
        return (
            taskType?.isOutcomeApprovalTask ||
            taskType?.isUploadDmn ||
            taskType?.isReviewTask ||
            taskType?.isCancelOutcomeTask ||
            taskType?.isChangeContractDateApprovalTask
        );
    }, [taskType]);

    /**
     * Render
     */
    return (
        <div>
            <Breadcrumbs
                id={`tasks-breadcrumbs`}
                icon={<AssignmentIcon color="primary" />}
                title="Tasklist"
                location={location}
                onClick={() =>
                    isTaskCompleted
                        ? navigate("/tasks/completed-tasks/")
                        : navigate("/tasks/ongoing-tasks/")
                }
            />

            <LoadingWrapper
                fullHeight
                id={`task-details-loading`}
                loading={taskLoading || contractLoading}
                error={taskError || contractError}
                callback={() => loadTask(taskId)}
            >
                <FlowLayout>
                    <FlowBody fullWidth>
                        <FlowContent className={style.contentWrapper}>
                            {hasContractDetails && (
                                <ContractReviewSection
                                    contract={contract?.data}
                                    hasPermissionToEditOutcome={
                                        hasPermissionToEditOutcome
                                    }
                                    downloadEvidence={downloadEvidence}
                                    fetchingEvidences={fetchingEvidences}
                                    downloadingEvidence={downloadingEvidence}
                                    evidences={evidences}
                                />
                            )}

                            {taskType?.isInfusionDateChangeApprovalTask && (
                                <Fragment>
                                    <div className={style.boxWrapper}>
                                        <Typography
                                            variant="h2"
                                            className={style.header}
                                        >
                                            Order details
                                        </Typography>

                                        <OrderDetails
                                            treatmentSite={
                                                orderTask?.treatmentSite
                                            }
                                            infusionDate={
                                                orderTask?.oldInfusionDate
                                            }
                                            salessystemOrderId={
                                                orderTask?.salessystemOrderId
                                            }
                                            orderDate={orderTask?.orderDate}
                                            orderStatus={orderTask?.orderStatus}
                                            id={"task-order"}
                                            hideEmpty
                                            className={style.card}
                                        />
                                    </div>
                                </Fragment>
                            )}

                            {taskType?.isPriceDataApprovalTask && (
                                <div className={style.boxWrapper}>
                                    <Typography
                                        variant="h2"
                                        className={style.header}
                                    >
                                        Open House product prices
                                    </Typography>

                                    <TaskDataApprovalDetails
                                        id={`task-price-data-file-details`}
                                        hasDownloadAction={true}
                                        loading={downloadingPriceDataFile}
                                        fileName={
                                            task?.data?.task?.priceData
                                                ?.fileName
                                        }
                                        onDownload={downloadPriceDataFile}
                                        label={"Price data file"}
                                        className={style.card}
                                    />
                                </div>
                            )}

                            {(taskType?.isEvidenceDataApprovalTask ||
                                taskType?.isIqviaDataApprovalTask ||
                                taskType?.isLauerDataApprovalTask ||
                                taskType?.isAccrualAdjustmentDataApprovalTask ||
                                taskType?.isLocalAgreementDataApprovalTask ||
                                taskType?.isApheresisDataLoadApprovalTask) && (
                                <div className={style.boxWrapper}>
                                    <Typography
                                        variant="h2"
                                        className={style.header}
                                    >
                                        {taskType?.isApheresisDataLoadApprovalTask
                                            ? "Apheresis data load file"
                                            : taskType?.isLocalAgreementDataApprovalTask
                                              ? "Local agreement data load file"
                                              : taskType?.isEvidenceDataApprovalTask
                                                ? "Evidence data load file"
                                                : taskType?.isIqviaDataApprovalTask
                                                  ? "IQVIA data load file"
                                                  : taskType?.isLauerDataApprovalTask
                                                    ? "Lauer data load file"
                                                    : "Accrual adjustment data load file"}
                                    </Typography>

                                    <TaskDataApprovalDetails
                                        id={`task-load-data-file-details`}
                                        hasDownloadAction={true}
                                        loading={downloadingLoadDataFile}
                                        fileName={
                                            task?.data?.task?.loadData?.fileName
                                        }
                                        onDownload={downloadLoadDataFile}
                                        className={style.card}
                                    />
                                </div>
                            )}

                            {taskType?.isPartnerDataApprovalTask && (
                                <div className={style.boxWrapper}>
                                    <Typography
                                        variant="h2"
                                        className={style.header}
                                    >
                                        Partner details
                                    </Typography>

                                    <TaskPartnerApprovalDetails
                                        id={`task-partner-data-details`}
                                        className={style.card}
                                        name={task?.data?.task?.partner?.name}
                                        address={
                                            task?.data?.task?.partner?.address
                                        }
                                        knowyoursupplierid={
                                            task?.data?.task?.partner
                                                ?.knowyoursupplierid
                                        }
                                        newKnowyoursupplierid={
                                            task?.data?.task?.partner
                                                ?.newKnowyoursupplierid
                                        }
                                    />
                                </div>
                            )}

                            {(taskType?.isClaimApprovalTask ||
                                taskType?.isClaimOverrideApprovalTask ||
                                taskType?.isCancelClaimApprovalTask ||
                                taskType?.isPriceCorrectionClaimApprovalTask) && (
                                <Fragment>
                                    <div className={style.boxWrapper}>
                                        <Typography
                                            variant="h2"
                                            className={style.header}
                                        >
                                            {`Claim details [${contractTask?.contractRef}${isUkTeam ? " - " + claimTask?.claimNo : ""}]`}
                                        </Typography>

                                        <ClaimDetails
                                            id={"task-details"}
                                            isClaimDetailsTask={
                                                !isTaskCompleted &&
                                                taskType?.isPriceCorrectionClaimApprovalTask
                                            }
                                            hasTitle={false}
                                            shrinkColumns
                                            isTaskOverview
                                            contractRef={
                                                contractTask?.contractRef
                                            }
                                            claimId={claimTask?.claimHeaderId}
                                            downloadClaimData={
                                                downloadClaimData
                                            }
                                            downloading={
                                                downloading ||
                                                downloadingClaimAttachment
                                            }
                                            isApproved={
                                                task?.data?.task?.approved
                                            }
                                            downloadingClaimAttachment={
                                                downloadingClaimAttachment
                                            }
                                            onDownloadAttachment={idx => {
                                                if (
                                                    !isArrayWithContent(
                                                        claimTask?.attachments,
                                                    )
                                                )
                                                    return;

                                                downloadClaimAttachment(
                                                    claimTask?.attachments[idx]
                                                        ?.claimAttachmentId,
                                                    claimTask?.attachments[idx]
                                                        ?.fileName,
                                                );
                                            }}
                                            {...claimTask}
                                        />
                                    </div>
                                </Fragment>
                            )}

                            {!task?.data?.task?.actionable &&
                                task?.data?.task?.approvers &&
                                isArrayWithContent(
                                    task?.data?.task.approvers,
                                ) &&
                                !isTaskCompleted && (
                                    <div className={style.boxWrapper}>
                                        <Card
                                            id={`price-data-card`}
                                            title="Approvers"
                                        >
                                            <Grid
                                                item
                                                xs={12}
                                                container
                                                rowSpacing={3}
                                                columnSpacing={4}
                                            >
                                                {task?.data?.task?.approvers?.map(
                                                    (approver, index) => (
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            md={4}
                                                            lg={3}
                                                            key={`approver-${index}`}
                                                        >
                                                            <FieldRenderer
                                                                id={`approver-${index}`}
                                                                key={index}
                                                                showTooltip
                                                                value={approver}
                                                            />
                                                        </Grid>
                                                    ),
                                                )}
                                            </Grid>
                                        </Card>
                                    </div>
                                )}

                            <Box my={3}>
                                <div className={style.boxWrapper}>
                                    {canApproveTasks &&
                                        taskType?.isInfusionDateChangeApprovalTask && (
                                            <InfusionDateChange
                                                oldInfusionDate={
                                                    task?.data?.order
                                                        ?.oldInfusionDate
                                                }
                                                newInfusionDate={
                                                    task?.data?.order
                                                        ?.newInfusionDate
                                                }
                                                openDialog={
                                                    mappedValues.type ===
                                                    "isInfusionDateChangeApprovalTask"
                                                }
                                                cquenceId={
                                                    task?.data?.order
                                                        ?.salessystemOrderId
                                                }
                                                onTaskComplete={() =>
                                                    changeInfusionDate(
                                                        taskId,
                                                        mappedValues.data,
                                                    )
                                                }
                                                loading={
                                                    changeInfusionDateLoading
                                                }
                                                onReset={resetMappedValues}
                                                className={style.card}
                                                values={formik.values}
                                                onBlur={formik.handleBlur}
                                                onChange={formik.handleChange}
                                            />
                                        )}

                                    {taskType?.isChangeContractDateApprovalTask && (
                                        <ContractDateChange
                                            oldStartDate={
                                                task?.data?.contract?.startDate
                                            }
                                            oldEndDate={
                                                task?.data?.contract?.endDate
                                            }
                                            newStartDate={
                                                task?.data?.contract
                                                    ?.newStartDate
                                            }
                                            newEndDate={
                                                task?.data?.contract?.newEndDate
                                            }
                                            comment={
                                                task?.data?.contract?.comment
                                            }
                                            openDialog={
                                                mappedValues.type ===
                                                "isChangeContractDateApprovalTask"
                                            }
                                            contractRef={
                                                contractTask?.contractRef
                                            }
                                            onTaskComplete={() =>
                                                changeContractDate(
                                                    taskId,
                                                    mappedValues.data,
                                                )
                                            }
                                            loading={changeContractDateLoading}
                                            onReset={resetMappedValues}
                                            className={style.card}
                                            values={formik.values}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            hideActions={!canApproveTasks}
                                        />
                                    )}

                                    {canApproveTasks &&
                                        taskType?.isReviewTask && (
                                            <ReviewTask
                                                openDialog={
                                                    mappedValues.type ===
                                                    "isReviewTask"
                                                }
                                                contractRef={
                                                    contractTask?.contractRef
                                                }
                                                onTaskComplete={() =>
                                                    reviewContract(
                                                        taskId,
                                                        mappedValues.data,
                                                    )
                                                }
                                                loading={reviewContractLoading}
                                                onReset={resetMappedValues}
                                                className={style.card}
                                                values={formik.values}
                                                onBlur={formik.handleBlur}
                                                onChange={formik.handleChange}
                                                errors={formik.errors}
                                                touched={formik.touched}
                                            />
                                        )}

                                    {canApproveTasks &&
                                        taskType?.isUploadDmn && (
                                            <UploadDmn
                                                id={`task-details-upload-dmn`}
                                                onBlur={formik.handleBlur}
                                                onChange={formik.handleChange}
                                                errors={formik.errors}
                                                touched={formik.touched}
                                                contractRef={
                                                    task?.data?.contract
                                                        ?.contractRef
                                                }
                                                values={formik.values}
                                                validateDmnId={validateDmnId}
                                                openDialog={
                                                    mappedValues.type ===
                                                    "isUploadDmn"
                                                }
                                                validatingDmn={validatingDmn}
                                                isNotUniqueValue={
                                                    isNotUniqueValue
                                                }
                                                loading={uploadDmnLoading}
                                                onReset={resetMappedValues}
                                                onDmnUpload={() =>
                                                    uploadDmn(
                                                        taskId,
                                                        mappedValues.data,
                                                    )
                                                }
                                                taskId={taskId}
                                                className={style.card}
                                            />
                                        )}

                                    {taskType?.isOutcomeApprovalTask && (
                                        <OutcomeApprovalTask
                                            id={"task-details-approval-task"}
                                            task={task}
                                            values={formik.values}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            onFieldChange={formik.setFieldValue}
                                            errors={formik.errors}
                                            touched={formik.touched}
                                            openDialog={
                                                mappedValues.type ===
                                                "isOutcomeApprovalTask"
                                            }
                                            order={orderTask}
                                            onApprove={() =>
                                                approveTask(
                                                    taskId,
                                                    mappedValues.data,
                                                )
                                            }
                                            loading={approveTaskLoading}
                                            onReset={() => resetMappedValues()}
                                            className={style.card}
                                            hideAction={!canApproveTasks}
                                            downloadApheresis={
                                                task?.data?.apheresisPdf?.name
                                                    ? {
                                                          name: task?.data
                                                              ?.apheresisPdf
                                                              ?.name,
                                                          onDownload: () =>
                                                              downloadApheresisPdf(
                                                                  task?.data
                                                                      ?.outcome
                                                                      ?.outcomeId,
                                                              ),
                                                          loading:
                                                              downloadingApheresis,
                                                      }
                                                    : null
                                            }
                                        />
                                    )}

                                    {taskType?.isCancelOutcomeTask && (
                                        <CancelOutcome
                                            id={
                                                "task-details-cancel-outcome-task"
                                            }
                                            task={task}
                                            values={formik.values}
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                            openDialog={
                                                mappedValues.type ===
                                                "isCancelOutcomeTask"
                                            }
                                            order={orderTask}
                                            onApprove={() =>
                                                cancelOutcome(
                                                    taskId,
                                                    mappedValues.data,
                                                )
                                            }
                                            loading={cancelOutcomeLoading}
                                            onReset={() => resetMappedValues()}
                                            className={style.card}
                                            hideAction={!canApproveTasks}
                                        />
                                    )}

                                    {canApproveTasks &&
                                        taskType?.isCancelClaimApprovalTask && (
                                            <CancelClaim
                                                id={
                                                    "claim-details-approval-task"
                                                }
                                                openDialog={
                                                    mappedValues.type ===
                                                    "isCancelClaimApprovalTask"
                                                }
                                                contractRef={
                                                    contractTask?.contractRef
                                                }
                                                onConfirm={() =>
                                                    cancelClaim(
                                                        taskId,
                                                        mappedValues.data,
                                                    )
                                                }
                                                loading={cancelClaimLoading}
                                                onReset={resetMappedValues}
                                                className={style.card}
                                                values={formik.values}
                                                onBlur={formik.handleBlur}
                                                onChange={formik.handleChange}
                                            />
                                        )}

                                    {canApproveTasks &&
                                        taskType?.isClaimApprovalTask && (
                                            <ClaimApprovalTask
                                                id={
                                                    "claim-details-approval-task"
                                                }
                                                openDialog={
                                                    mappedValues.type ===
                                                    "isClaimApprovalTask"
                                                }
                                                contractRef={
                                                    contractTask?.contractRef
                                                }
                                                onConfirm={() =>
                                                    approveClaim(
                                                        taskId,
                                                        mappedValues.data,
                                                    )
                                                }
                                                loading={approveClaimLoading}
                                                onReset={resetMappedValues}
                                                className={style.card}
                                                values={formik.values}
                                                onBlur={formik.handleBlur}
                                                onChange={formik.handleChange}
                                                onFieldChange={
                                                    formik.setFieldValue
                                                }
                                                errors={formik.errors}
                                                touched={formik.touched}
                                                isApprovalMatrix={
                                                    task?.data?.task?.variables
                                                        ?.approvalMatrix
                                                }
                                            />
                                        )}

                                    {canApproveTasks &&
                                        taskType?.isPriceCorrectionClaimApprovalTask && (
                                            <PriceCorrectionApprovalTask
                                                id={
                                                    "claim-details-price-correction-approval-task"
                                                }
                                                openDialog={
                                                    mappedValues.type ===
                                                    "isPriceCorrectionClaimApprovalTask"
                                                }
                                                contractRef={
                                                    contractTask?.contractRef
                                                }
                                                onConfirm={() =>
                                                    approveClaimPriceCorrection(
                                                        taskId,
                                                        mappedValues.data,
                                                    )
                                                }
                                                loading={
                                                    approveClaimPriceCorrectionLoading
                                                }
                                                onReset={resetMappedValues}
                                                className={style.card}
                                                values={formik.values}
                                                onBlur={formik.handleBlur}
                                                onChange={formik.handleChange}
                                                errors={formik.errors}
                                                touched={formik.touched}
                                            />
                                        )}

                                    {canApproveTasks &&
                                        taskType?.isClaimOverrideApprovalTask && (
                                            <div>
                                                <div className={style.header}>
                                                    <Typography variant="h2">
                                                        {`Financial override approval`}
                                                    </Typography>
                                                </div>
                                                <OverrideApprovalTask
                                                    id={
                                                        "override-approval-task"
                                                    }
                                                    oldValue={
                                                        task?.data?.claim
                                                            ?.totalRebateValue
                                                    }
                                                    newValue={
                                                        task?.data?.claim
                                                            ?.overriddenTotalRebateValue
                                                    }
                                                    openDialog={
                                                        mappedValues.type ===
                                                        "isClaimOverrideApprovalTask"
                                                    }
                                                    onTaskComplete={() =>
                                                        approveClaim(
                                                            taskId,
                                                            mappedValues.data,
                                                        )
                                                    }
                                                    loading={
                                                        approveClaimLoading
                                                    }
                                                    onReset={resetMappedValues}
                                                    className={style.card}
                                                    values={formik.values}
                                                    onBlur={formik.handleBlur}
                                                    onChange={
                                                        formik.handleChange
                                                    }
                                                    errors={formik.errors}
                                                    touched={formik.touched}
                                                />
                                            </div>
                                        )}

                                    {canApproveTasks &&
                                        taskType?.isPriceDataApprovalTask && (
                                            <div>
                                                <div className={style.header}>
                                                    <Typography variant="h2">
                                                        {`Price data approval`}
                                                    </Typography>
                                                </div>

                                                <PriceData
                                                    id={
                                                        "price-data-approval-task"
                                                    }
                                                    openDialog={
                                                        mappedValues.type ===
                                                        "isPriceDataApprovalTask"
                                                    }
                                                    onConfirm={() =>
                                                        approvePriceData(
                                                            taskId,
                                                            mappedValues.data,
                                                        )
                                                    }
                                                    loading={priceDataLoading}
                                                    onReset={resetMappedValues}
                                                    className={style.card}
                                                    values={formik.values}
                                                    onBlur={formik.handleBlur}
                                                    onChange={
                                                        formik.handleChange
                                                    }
                                                    fileName={
                                                        task?.data?.task
                                                            ?.priceData
                                                            ?.fileName
                                                    }
                                                />
                                            </div>
                                        )}

                                    {canApproveTasks &&
                                        (taskType?.isEvidenceDataApprovalTask ||
                                            taskType?.isLauerDataApprovalTask ||
                                            taskType?.isIqviaDataApprovalTask ||
                                            taskType?.isAccrualAdjustmentDataApprovalTask ||
                                            taskType?.isLocalAgreementDataApprovalTask ||
                                            taskType?.isApheresisDataLoadApprovalTask) && (
                                            <div>
                                                <div className={style.header}>
                                                    <Typography variant="h2">
                                                        {taskType?.isApheresisDataLoadApprovalTask
                                                            ? "Apheresis approval task"
                                                            : taskType?.isLocalAgreementDataApprovalTask
                                                              ? `Local agreement data load approval`
                                                              : taskType?.isEvidenceDataApprovalTask
                                                                ? `Evidence data load approval`
                                                                : taskType?.isLauerDataApprovalTask
                                                                  ? `Lauer data load approval`
                                                                  : taskType?.isAccrualAdjustmentDataApprovalTask
                                                                    ? `Accrual Adjustment data load approval`
                                                                    : `IQVIA data load approval`}
                                                    </Typography>
                                                </div>

                                                <LoadData
                                                    id={
                                                        "load-data-approval-task"
                                                    }
                                                    openDialog={[
                                                        "isEvidenceDataApprovalTask",
                                                        "isLauerDataApprovalTask",
                                                        "isIqviaDataApprovalTask",
                                                        "isAccrualAdjustmentDataApprovalTask",
                                                        "isLocalAgreementDataApprovalTask",
                                                        "isApheresisDataLoadApprovalTask",
                                                    ].includes(
                                                        mappedValues?.type,
                                                    )}
                                                    onConfirm={() =>
                                                        approveLoadData(
                                                            taskId,
                                                            mappedValues.data,
                                                        )
                                                    }
                                                    evidenceDataApprovalTask={
                                                        taskType?.isEvidenceDataApprovalTask
                                                    }
                                                    lauerDataApprovalTask={
                                                        taskType?.isLauerDataApprovalTask
                                                    }
                                                    iqviaDataApprovalTask={
                                                        taskType?.isIqviaDataApprovalTask
                                                    }
                                                    accrualAdjustmentDataApprovalTask={
                                                        taskType?.isAccrualAdjustmentDataApprovalTask
                                                    }
                                                    localAgreementDataApprovalTask={
                                                        taskType?.isLocalAgreementDataApprovalTask
                                                    }
                                                    apheresisDataLoadApprovalTask={
                                                        taskType?.isApheresisDataLoadApprovalTask
                                                    }
                                                    loading={loadDataLoading}
                                                    onReset={resetMappedValues}
                                                    className={style.card}
                                                    values={formik.values}
                                                    onBlur={formik.handleBlur}
                                                    onChange={
                                                        formik.handleChange
                                                    }
                                                    fileName={
                                                        task?.data?.task
                                                            ?.loadData?.fileName
                                                    }
                                                />
                                            </div>
                                        )}

                                    {canApproveTasks &&
                                        taskType?.isPartnerDataApprovalTask && (
                                            <div>
                                                <div className={style.header}>
                                                    <Typography variant="h2">
                                                        Partner approval task
                                                    </Typography>
                                                </div>

                                                <PartnerDataApprovalTask
                                                    id={
                                                        "partner-data-approval-task"
                                                    }
                                                    openDialog={
                                                        mappedValues.type ===
                                                        "isPartnerDataApprovalTask"
                                                    }
                                                    onConfirm={() =>
                                                        approvePartnerData(
                                                            taskId,
                                                            mappedValues.data,
                                                        )
                                                    }
                                                    name={
                                                        task?.data?.task
                                                            ?.partner?.name
                                                    }
                                                    address={
                                                        task?.data?.task
                                                            ?.partner?.address
                                                    }
                                                    knowyoursupplierid={
                                                        task?.data?.task
                                                            ?.partner
                                                            ?.knowyoursupplierid
                                                    }
                                                    loading={loadDataLoading}
                                                    onReset={resetMappedValues}
                                                    className={style.card}
                                                    values={formik.values}
                                                    onBlur={formik.handleBlur}
                                                    onChange={
                                                        formik.handleChange
                                                    }
                                                />
                                            </div>
                                        )}

                                    {isTaskCompleted && (
                                        <CompletedTasksSection
                                            task={task?.data}
                                            contract={contract?.data}
                                            taskType={taskType}
                                        />
                                    )}
                                </div>
                            </Box>
                        </FlowContent>

                        <FlowFooter>
                            <Fragment>
                                {canApproveTasks && (
                                    <Box>
                                        {(taskType?.isUploadDmn ||
                                            (taskType?.isOutcomeApprovalTask &&
                                                formik.values?.approved ===
                                                    "false")) && (
                                            <FormFooter
                                                id={`task-details-footer`}
                                                error={hasErrors}
                                                textAlign="right"
                                            />
                                        )}
                                    </Box>
                                )}

                                <StepActions
                                    id={`tasks-step-actions`}
                                    primaryButton={{
                                        text: "Submit",
                                        action: () =>
                                            setMappedValues({
                                                taskType,
                                                taskId,
                                                contractTask,
                                                orderTask,
                                                validateDmn,
                                                values: formik.values,
                                            }),
                                        disabled:
                                            !canApproveTasks || disableSubmit,
                                        hidden: !canApproveTasks,
                                        isSubmitButton: true,
                                    }}
                                    tertiaryButton={{
                                        text: "Return to overview",
                                        action: () =>
                                            isTaskCompleted
                                                ? navigate(
                                                      "/tasks/completed-tasks/",
                                                  )
                                                : navigate(
                                                      "/tasks/ongoing-tasks/",
                                                  ),
                                    }}
                                />
                            </Fragment>
                        </FlowFooter>
                    </FlowBody>
                </FlowLayout>
            </LoadingWrapper>
        </div>
    );
};
export default TaskDetails;
