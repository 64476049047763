import { atom } from "recoil";

export const createContractTeamState = atom({
    key: "createContractTeamState",
    default: {
        teamName: "",
        countries: [],
        therapeuticAreaIds: [],
        minimumContractReviewers: 0,
        minimumFinancialApprovers: 0,
        users: [],
    },
});
