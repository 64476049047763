// extracted by mini-css-extract-plugin
export var details = "style-module--details--4d0e3";
export var error = "#ff3131";
export var infoBox = "style-module--infoBox--1cd10";
export var link = "style-module--link--151e8";
export var linkOrder = "style-module--linkOrder--5c997";
export var primary = "#036";
export var secondary = "#019cdc";
export var separator = "style-module--separator--14e09";
export var textfont = "\"Verdana Pro\"";
export var titlefont = "\"Karbon\"";
export var white = "#fff";
export var wrapper = "style-module--wrapper--bff96";