//Lib
import {
    Box,
    Typography,
    FormControl,
    FormControlLabel,
    RadioGroup,
    Radio,
} from "@mui/material";

import React, { Fragment } from "react";

// Own components
import { Dialog } from "@components";

/**
 * Props
 */
interface Props {
    openDialog: boolean;
    onConfirm: () => void;
    loading: boolean;
    onReset: () => void;
    className?: string;
    values: {
        approved: string;
        rejectionReason: string;
    };
    onBlur: (event: React.SyntheticEvent) => void;
    onChange: any;
    id: string;
    contractRef: string;
}

/**
 * Cancel claim approval task
 */
const CancelClaim = ({
    id,
    openDialog,
    loading,
    onReset,
    className,
    values,
    onBlur,
    onChange,
    contractRef,
    onConfirm,
}: Props) => {
    return (
        <Fragment>
            <Dialog
                id={`${id}-tasks-approval-claim-cancellation-task`}
                title={"Approve claim Cancellation"}
                open={openDialog}
                message={
                    values?.approved === "false" ? (
                        <Typography variant="subtitle2" color="black">
                            You are about to reject a claim cancellation and
                            consequently mark task
                            <Typography variant="body2" component="span">
                                {` Cancel claim [${contractRef}] `}
                            </Typography>
                            as complete. Doing so will NOT cancel the claim and
                            make it eligible again for financial transactions.
                        </Typography>
                    ) : (
                        <Typography variant="subtitle2" color="black">
                            You are about to approve a claim cancellation and
                            consequently mark task
                            <Typography variant="body2" component="span">
                                {` Cancel claim [${contractRef}] `}
                            </Typography>
                            as complete. Doing so will trigger a financial
                            transaction. This action cannot be undone.
                        </Typography>
                    )
                }
                primaryButton={{
                    text: "Confirm",
                    action: onConfirm,
                    loading: loading,
                }}
                secondaryButton={{
                    text: "Cancel",
                    action: onReset,
                }}
            />

            <Box mb={3} className={className}>
                <form noValidate onBlur={onBlur}>
                    <FormControl>
                        <RadioGroup
                            id={`${id}-cancel-claim-approval`}
                            value={values?.approved}
                            onChange={onChange}
                            name="approved"
                        >
                            <FormControlLabel
                                value={"true"}
                                id={`${id}-task-details-approve-cancel-claim`}
                                control={<Radio size="small" />}
                                label={
                                    <Typography
                                        variant="body2"
                                        color="dark.light"
                                    >
                                        I approve this decision
                                    </Typography>
                                }
                            />

                            <FormControlLabel
                                id={`${id}-task-details-reject-cancel-claim`}
                                value={"false"}
                                control={<Radio size="small" />}
                                label={
                                    <Typography
                                        variant="body2"
                                        color="dark.light"
                                    >
                                        I reject this decision
                                    </Typography>
                                }
                            />
                        </RadioGroup>
                    </FormControl>
                </form>
            </Box>
        </Fragment>
    );
};

export default React.memo(CancelClaim);
