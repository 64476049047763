// Icons
// Libs
import GridOn from "@mui/icons-material/GridOn";
import { navigate } from "gatsby";
import { useResetRecoilState } from "recoil";
import React, {
    Fragment,
    useEffect,
    useLayoutEffect,
    useMemo,
    useRef,
    useState,
} from "react";

// Own component
import {
    Breadcrumbs,
    StepActions,
    Stepper,
    FlowLayout,
    FlowAside,
    FlowBody,
    FlowContent,
    FlowFooter,
    LoadingWrapper,
} from "@components";
import { OutcomeOverview } from "@containers";

// Hooks
import {
    useFindStep,
    useOrders,
    useGetVariables,
    useCreateOutcome,
} from "@hooks";

// Types
import type { Location } from "@types";

// Atoms
import {
    editOrderStepsState,
    linkOrderStepsState,
    viewOrderStepsState,
    addOutcomeStepsState,
    outcomeDetailsState,
    contractSelectionState,
} from "@atoms";

// Utils
import { constructQueryString, isSuccessfulCall } from "@utils";

/**
 * Props type
 */
interface Props {
    children: React.ReactNode;
    location: Location;
    orderId: string;
}

/**
 * Create contract layout
 */
const OrderLayout = ({ location, children, orderId }: Props) => {
    //Via this ref we can save the state of each page via stepper (higher order)
    const ref = useRef<any>();
    const [isOutcomeCreated, setOutcomeStatus] = useState(false);

    /**
     * Flow check
     */
    const isEditOrder = useMemo(() => {
        if (!location) return;
        return location.pathname.includes("edit-order");
    }, [location]);

    const isAddOutcome = useMemo(() => {
        if (!location) return;
        return location.pathname.includes("add-outcome");
    }, [location]);

    const isViewOrder = useMemo(() => {
        if (!location && isEditOrder) return;
        return location.pathname.includes("view-order");
    }, [location]);

    const isLinkOrder = useMemo(() => {
        if (!location && isEditOrder) return;
        return location.pathname.includes("link-order");
    }, [location]);

    /**
     * API
     */

    // Fetch order by ID
    const { fetchOrder, updateOrder, linkOrder, response, loading } =
        useOrders("orders");

    // Get variables
    const {
        getVariables,
        loading: loadingVariables,
        response: variables,
    } = useGetVariables();

    // Create outcome
    const { loading: createOutcomeLoading, createOutcome } = useCreateOutcome();

    /**
     * Reset
     */
    const resetOutcomeDetailsAtom = useResetRecoilState(outcomeDetailsState);
    const resetContractSelectionAtom = useResetRecoilState(
        contractSelectionState,
    );

    const reset = () => {
        setOutcomeStatus(false);
        resetOutcomeDetailsAtom();
        resetContractSelectionAtom();
    };

    useLayoutEffect(() => {
        if (!orderId) return;
        fetchOrder(orderId);
        reset();
    }, [orderId]);

    useEffect(() => {
        if (
            !!isAddOutcome &&
            response?.order?.data.orderId &&
            response?.order?.data?.contract?.id
        ) {
            const order = response?.order?.data;
            const params = constructQueryString(
                {
                    contractId: order?.contract?.id,
                    orderId: order?.orderId,
                },
                true,
            );
            getVariables(params);
        }
    }, [response?.order?.data]);

    //Steps hooks
    const { currentStep } = useFindStep(
        location,
        isEditOrder
            ? editOrderStepsState
            : isViewOrder
              ? viewOrderStepsState
              : isAddOutcome
                ? addOutcomeStepsState
                : linkOrderStepsState,
    );

    /**
     * Save handler
     */
    const onNextClick = () => {
        if (isViewOrder || isOutcomeCreated)
            return navigate("/dashboard/orders/");
        if (isEditOrder) {
            const data = ref.current.onSave();
            updateOrder(orderId, data).then(res => {
                if (
                    !!res &&
                    isSuccessfulCall(res?.status) &&
                    res?.data?.orderId
                ) {
                    navigate(`/order/view-order/${res.data.orderId}/`);
                }
            });
        }

        if (isAddOutcome) {
            const data = ref.current.getStateData();
            const mapper = {
                contractId: response?.order?.data?.contract?.id,
                orderId: orderId,
                outcomeVariables: data?.outcomeVariables,
                reason: data?.reason,
                evidences: data?.evidences,
            };

            createOutcome(mapper).then(response => {
                if (!!response && isSuccessfulCall(response?.status)) {
                    setOutcomeStatus(true);
                }
            });
            return;
        }
        if (isLinkOrder) {
            const contract = ref.current.onSubmit();

            linkOrder(orderId, contract.id).then(response => {
                if (!!response && isSuccessfulCall(response.status)) {
                    reset();
                    navigate("/dashboard/orders/");
                }
            });
            return;
        }
    };

    const isLoading = useMemo(() => {
        if (!isAddOutcome) {
            return loading?.fetchingOrder;
        } else {
            return loading?.fetchingOrder || loadingVariables;
        }
    }, [isAddOutcome, loading, loadingVariables]);

    /**
     * Render
     */
    return (
        <Fragment>
            <Breadcrumbs
                id={`orders-breadcrumb`}
                icon={<GridOn color="primary" />}
                title="Dashboard"
                location={location}
            />

            <FlowLayout>
                <FlowAside>
                    <Stepper
                        id={`orders-stepper`}
                        title={`${currentStep?.title}`}
                        subTitle={`${
                            (isEditOrder || isViewOrder) && !isLoading
                                ? response?.order?.data?.salessystemOrderId
                                : ""
                        }`}
                    />
                </FlowAside>

                <FlowBody>
                    <Fragment>
                        <FlowContent>
                            <LoadingWrapper
                                loading={isLoading}
                                fullHeight
                                id="orders"
                                NoEmptyPage
                            >
                                {isOutcomeCreated ? (
                                    <OutcomeOverview />
                                ) : (
                                    React.Children.map(
                                        children as React.ReactElement,
                                        (child: React.ReactElement) =>
                                            React.cloneElement(child, {
                                                location,
                                                ref,
                                                order: response?.order?.data,
                                                variables:
                                                    variables?.data?.records,
                                                disabled:
                                                    loading?.updatingOrder ||
                                                    createOutcomeLoading,
                                                id: "orders",
                                                loading: loadingVariables,
                                            }),
                                    )
                                )}
                            </LoadingWrapper>
                        </FlowContent>

                        <FlowFooter>
                            <StepActions
                                id={`orders-step-actions`}
                                hidden={isLoading}
                                loading={
                                    createOutcomeLoading ||
                                    loading?.updatingOrder ||
                                    loading?.linkingOrder
                                }
                                tertiaryButton={{
                                    text: "Cancel",
                                    action: () =>
                                        navigate("/dashboard/orders/"),

                                    hidden: isOutcomeCreated || isViewOrder,
                                }}
                                primaryButton={{
                                    text: isEditOrder
                                        ? "Save status"
                                        : isViewOrder
                                          ? "Return to overview"
                                          : isAddOutcome && !isOutcomeCreated
                                            ? "Process outcome"
                                            : isAddOutcome && isOutcomeCreated
                                              ? "Return to overview"
                                              : "Link order",
                                    action: onNextClick,
                                    disabled:
                                        !currentStep.isPrepared ||
                                        loading?.updatingOrder ||
                                        loading.fetchingOrder ||
                                        loading.linkingOrder ||
                                        createOutcomeLoading,
                                    isSubmitButton: true,
                                }}
                            />
                        </FlowFooter>
                    </Fragment>
                </FlowBody>
            </FlowLayout>
        </Fragment>
    );
};

export default OrderLayout;
