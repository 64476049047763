// icon
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Select as _Select } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
// lib
import InputAdornment from "@mui/material/InputAdornment";
import MenuItem from "@mui/material/MenuItem";
import classNames from "classnames";
import React, { Fragment, useMemo } from "react";

// Utils
import { constantMapper, isArrayWithContent } from "@utils";

//import style
import * as style from "./style.module.scss";

interface Props {
    value: any;
    list: Array<any>;
    onChange: any;
    loading?: boolean;
    name: string;
    size?: "medium" | "small";
    error?: boolean;
    menuItemLabel?: string | Array<string>;
    menuItemId?: string;
    disabled?: boolean;
    onBlur?: (event: React.SyntheticEvent) => void;
    id: string;
    className?: string;
    scrollable?: boolean;
    disabledItems?: Array<string | number>;
    fullWidth?: boolean;
    defaultOpen?: boolean;
    whiteBg?: boolean;
    maxWidth?: number | string;
    placeHolder?: {
        title: string;
        value: any;
        selectable?: boolean;
    };
    displayEmpty?: boolean;
    noTextTransform?: boolean;
}

const Select: React.FC<Props> = ({
    value,
    onChange,
    loading = false,
    name,
    size = "small",
    error,
    menuItemLabel,
    menuItemId,
    list,
    disabled,
    onBlur,
    id,
    className,
    scrollable,
    disabledItems,
    fullWidth = true,
    defaultOpen,
    whiteBg,
    placeHolder,
    displayEmpty,
    noTextTransform,
}) => {
    const prefixedValue = useMemo(() => {
        if (!value || loading || !isArrayWithContent(list)) return "";
        if (menuItemId) {
            const indexOfValue = list.findIndex(item => {
                // important to check if the value exists or not otherwise it will take the first item in the array because
                // both are undefined
                return (
                    value[menuItemId] !== undefined &&
                    item[menuItemId] === value[menuItemId]
                );
            });

            return indexOfValue > -1
                ? `${value[menuItemId]}.${indexOfValue}`
                : "";
        } else {
            const indexOfValue = list.findIndex(item => item === value);
            return indexOfValue > -1 ? `${value}.${indexOfValue}` : "";
        }
    }, [value, loading, list, menuItemId]);

    const handleChange = (value: string | null | undefined) => {
        // Store the empty menu item
        if (value === null || value === undefined) {
            return onChange(value, undefined);
        }

        const [val, index] = value.split(".");

        return onChange(
            val,
            !!index && parseInt(index) > -1 ? parseInt(index) : undefined,
        );
    };

    const isMarkedAsDisabled = (item: any) => {
        if (!isArrayWithContent(disabledItems) || !item || !menuItemId) return;
        return disabledItems?.includes(item[menuItemId]);
    };

    return (
        <Fragment>
            <_Select
                fullWidth={fullWidth}
                name={name}
                role="button"
                id={`${id}-${name}`}
                displayEmpty={displayEmpty}
                size={size}
                defaultValue={prefixedValue}
                defaultOpen={defaultOpen}
                disabled={loading || disabled}
                className={classNames(
                    {
                        [style.disableField]: disabled,
                        [style.whiteBg]: whiteBg,
                    },
                    className,
                )}
                MenuProps={{
                    classes: {
                        paper: scrollable ? style.scrollMenu : undefined, // Apply custom styles to the <ul> element
                    },
                }}
                value={prefixedValue}
                data-testid={`${id}-${name}-testId`}
                onChange={event => handleChange(event.target.value)}
                variant="outlined"
                error={error}
                onBlur={onBlur}
                IconComponent={props =>
                    loading ? (
                        <InputAdornment position="start">
                            <CircularProgress color="inherit" size={20} />
                        </InputAdornment>
                    ) : (
                        <KeyboardArrowDownIcon {...props} color="primary" />
                    )
                }
            >
                {placeHolder && (
                    <MenuItem
                        value={placeHolder?.value}
                        disabled={!placeHolder?.selectable}
                        id={`${id}-placeholder`}
                    >
                        {placeHolder?.title}
                    </MenuItem>
                )}
                {!loading && isArrayWithContent(list) ? (
                    list.map((item, index) => (
                        <MenuItem
                            disabled={
                                isArrayWithContent(disabledItems) &&
                                isMarkedAsDisabled(item)
                            }
                            id={
                                menuItemId
                                    ? `${id}-${item[menuItemId]}.${index}`
                                    : `${id}-${item}.${index}`
                            }
                            value={
                                menuItemId
                                    ? `${item[menuItemId]}.${index}`
                                    : `${item}.${index}`
                            }
                            key={`${item}-${name}-${index}`}
                        >
                            {typeof item === "string"
                                ? constantMapper(item, noTextTransform)
                                : typeof menuItemLabel === "string"
                                  ? item[menuItemLabel!]
                                  : menuItemLabel
                                        ?.map(label => item[label!])
                                        .join("-")}
                        </MenuItem>
                    ))
                ) : (
                    <MenuItem disabled>No Data</MenuItem>
                )}
            </_Select>
        </Fragment>
    );
};
export default React.memo(Select);
