// Icons
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";

// Lib
import { FormHelperText, Grid, InputLabel, TextField } from "@mui/material";

import React, { useEffect, useState } from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

// Own components
import { Modal, FieldRenderer } from "@components";

// Utils
import {
    convertDateForAPI,
    datePlaceholder,
    displayDate,
    isValidDateRange,
} from "@utils";

type Props = {
    id: string;
    open: boolean;
    onClose: () => void;
    title: string;
    onSubmit: (
        dateRange: {
            validFrom: string;
            validTo: string;
        },
        comment: string | null,
    ) => void;
    loading: boolean;
    disabled: boolean;
    oldStartDate: string;
    oldEndDate?: string;
};

/**
 * Change contract date
 */
const ChangeContractDate = ({
    id,
    open,
    onClose,
    title,
    onSubmit,
    loading,
    disabled,
    oldStartDate,
    oldEndDate,
}: Props) => {
    const [dateRange, setNewDateRange] = useState<{
        validFrom: string;
        validTo: string;
    }>({
        validFrom: "",
        validTo: "",
    });
    const [comment, setComment] = useState<string | null>(null);
    const [touched, setTouched] = useState<{
        validFrom: boolean;
        validTo: boolean;
    }>({
        validFrom: false,
        validTo: false,
    });
    const [errors, setErrors] = useState<{
        validFrom: string;
        validTo: string;
    }>({ validFrom: "", validTo: "" });

    /**
     * Reset states
     */
    const resetStates = () => {
        setErrors({ validFrom: "", validTo: "" });
        setTouched({
            validFrom: false,
            validTo: false,
        });
        setNewDateRange({
            validFrom: "",
            validTo: "",
        });
        setComment(null);
    };

    useEffect(() => {
        if (!open) {
            resetStates();
        }
    }, [open]);

    const onDateChange = (key: string, value: string) => {
        setNewDateRange({
            ...dateRange,
            [key]: value,
        });

        if (!touched[key]) {
            setTouched({ ...touched, [key]: true });
        }
    };

    useEffect(() => {
        if (!touched["validFrom"] || !touched["validTo"]) return;

        setErrors({
            validFrom:
                touched["validFrom"] && !!dateRange?.validFrom
                    ? ""
                    : errors?.validFrom,
            validTo:
                touched["validTo"] && !!dateRange?.validTo
                    ? ""
                    : errors?.validTo,
        });

        if (dateRange?.validFrom && dateRange?.validTo) {
            if (isValidDateRange(dateRange.validFrom, dateRange.validTo)) {
                setErrors({ validFrom: "", validTo: "" });
            } else {
                setErrors({ validFrom: "", validTo: "Invalid date range" });
            }
        }
    }, [
        dateRange?.validFrom,
        dateRange?.validTo,
        touched["validFrom"],
        touched["validTo"],
    ]);

    /**
     * Render
     */
    return (
        <Modal
            id={`${id}-modal`}
            open={open}
            mediumView
            onClose={onClose}
            title={title}
            primaryButton={{
                action: () => onSubmit(dateRange, comment),
                text: "Submit",
                loading: loading,
                disabled:
                    disabled ||
                    !dateRange?.validFrom ||
                    !dateRange.validTo ||
                    !!errors["validFrom"] ||
                    !!errors["validTo"],
                id: `${id}-primary-btn`,
            }}
            secondaryButton={{
                action: () => {
                    onClose();
                },
                text: "Cancel",
                id: `${id}-secondary-btn`,
            }}
        >
            <Grid container item xs={12} spacing={3}>
                {oldStartDate && (
                    <Grid item xs={6}>
                        <FieldRenderer
                            id={`old-start-date`}
                            label="Start date"
                            value={displayDate(oldStartDate)}
                        />
                    </Grid>
                )}
                {oldEndDate && (
                    <Grid item xs={6}>
                        <FieldRenderer
                            id={`old-end-date`}
                            label="End date"
                            value={displayDate(oldEndDate)}
                        />
                    </Grid>
                )}

                <Grid item xs={6}>
                    <InputLabel
                        id={`start-date-label`}
                        shrink
                        error={
                            (!!errors["validFrom"] || !dateRange?.validFrom) &&
                            touched["validFrom"]
                        }
                    >
                        {"New start date (*)"}
                    </InputLabel>

                    <DatePicker
                        value={datePlaceholder(dateRange?.validFrom)}
                        onChange={value => {
                            onDateChange(
                                "validFrom",
                                !value || !value?.isValid
                                    ? ""
                                    : convertDateForAPI(value),
                            );
                        }}
                        format="dd/MM/yyyy"
                        slotProps={{
                            textField: {
                                variant: "outlined",
                                autoComplete: "off",
                                sx: {
                                    svg: {
                                        color: "#036",
                                        opacity: "0.5",
                                    },
                                },
                                size: "small",
                                fullWidth: true,
                                placeholder: "dd/mm/yyyy",
                                required: true,
                                error:
                                    (!!errors["validFrom"] ||
                                        !dateRange?.validFrom) &&
                                    touched["validFrom"],
                                id: `valid-from-input`,
                                name: "valid-from-input",
                                onBlur: () => {
                                    setTouched({ ...touched, validFrom: true });

                                    if (!dateRange?.validFrom) {
                                        setErrors({
                                            ...errors,
                                            [`validFrom`]:
                                                "This field is required",
                                        });
                                    }
                                },
                            },
                        }}
                        slots={{
                            openPickerIcon: CalendarTodayIcon,
                        }}
                    />
                    {!!errors["validFrom"] && (
                        <FormHelperText error>
                            {errors["validFrom"]}
                        </FormHelperText>
                    )}
                </Grid>
                <Grid item xs={6}>
                    <InputLabel
                        id={`end-date-label`}
                        shrink
                        error={
                            (!!errors["validTo"] || !dateRange?.validTo) &&
                            touched["validTo"]
                        }
                    >
                        {"New end date (*)"}
                    </InputLabel>

                    <DatePicker
                        value={datePlaceholder(dateRange?.validTo)}
                        onChange={value => {
                            onDateChange(
                                "validTo",
                                !value || !value?.isValid
                                    ? ""
                                    : convertDateForAPI(value),
                            );
                        }}
                        format="dd/MM/yyyy"
                        slotProps={{
                            textField: {
                                variant: "outlined",
                                autoComplete: "off",
                                sx: {
                                    svg: {
                                        color: "#036",
                                        opacity: "0.5",
                                    },
                                },
                                size: "small",
                                fullWidth: true,
                                placeholder: "dd/mm/yyyy",
                                required: true,
                                error:
                                    (!!errors["validTo"] ||
                                        !dateRange?.validTo) &&
                                    touched["validTo"],
                                id: `valid-to-input`,
                                name: "valid-to-input",
                                onBlur: () => {
                                    setTouched({ ...touched, validTo: true });

                                    if (!dateRange?.validTo) {
                                        setErrors({
                                            ...errors,
                                            [`validTo`]:
                                                "This field is required",
                                        });
                                    }
                                },
                            },
                        }}
                        slots={{
                            openPickerIcon: CalendarTodayIcon,
                        }}
                    />
                    {!!errors["validTo"] && (
                        <FormHelperText error>
                            {errors["validTo"]}
                        </FormHelperText>
                    )}
                </Grid>
                <Grid item xs={12}>
                    <InputLabel id="comment-label" shrink>
                        {"Comment"}
                    </InputLabel>
                    <TextField
                        value={comment}
                        fullWidth
                        multiline
                        id={`comment`}
                        minRows={6}
                        autoComplete="off"
                        name="comment"
                        variant="outlined"
                        size="small"
                        onChange={event => setComment(event.target.value)}
                    />
                </Grid>
            </Grid>
        </Modal>
    );
};

export default ChangeContractDate;
