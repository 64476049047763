// Lib
import React from "react";

// Own components
import { MaintenanceBillingPlan } from "@components";

// Hooks
import { useMaintenance } from "@hooks";
import { Location } from "@types";

// Types
import type { BillingPlanValues } from "@types";
import { isSuccessfulCall } from "@utils";

/**
 * Props type
 */
interface Props {
    location?: Location;
    id?: string;
}

const FIELDS = [
    {
        id: "salesOrg",
        inputLabel: "Organisation",
        type: "string",
        required: true,
    },
    {
        id: "documentType",
        inputLabel: "Document Type",
        type: "string",
        required: true,
    },
    {
        id: "division",
        inputLabel: "Division",
        type: "string",
        required: true,
    },
    {
        id: "planType",
        inputLabel: "Plan Type",
        type: "string",
        required: true,
    },
    {
        id: "counter",
        inputLabel: "Counter",
        type: "string",
        required: true,
    },
    {
        id: "days",
        inputLabel: "Days",
        type: "integer",
        required: false,
    },
    {
        id: "percentage",
        inputLabel: "Percentage",
        type: "decimal",
        required: false,
    },

    {
        id: "paymentTerms",
        inputLabel: "Payment Terms",
        type: "string",
        required: false,
    },
    {
        id: "billingBlock",
        inputLabel: "Billing Block",
        type: "string",
        required: false,
    },
];

/**
 * Billing Plan
 */
const BillingPlan = ({ id }: Props) => {
    /**
     * API
     */
    const {
        list: billingPlanList,
        error: billingPlanError,
        loading: { listLoading: fetchingList, creating },
        upsert,
    } = useMaintenance("billingPlan", "Billing plan|Billing plan");

    /**
     * Submit handler
     */
    const handleSubmit = (values: BillingPlanValues, isEdit: boolean) => {
        return upsert(
            values,
            isEdit ? "Billing plan" : "New billing plan",
        ).then(res => isSuccessfulCall(res?.status));
    };

    /**
     * Render
     */
    return (
        <MaintenanceBillingPlan
            id={`${id}-billing-plan-list`}
            list={billingPlanList?.maintenanceBillingPlanList}
            loading={{
                fetchingList,
                creating,
            }}
            fields={FIELDS}
            error={{ billingPlanError }}
            onSubmit={handleSubmit}
        />
    );
};
export default BillingPlan;
