// extracted by mini-css-extract-plugin
export var block = "style-module--block--91a39";
export var divider = "style-module--divider--11a1a";
export var error = "#ff3131";
export var inputSection = "style-module--inputSection--d9519";
export var link = "style-module--link--7c6ad";
export var list = "style-module--list--ffed5";
export var primary = "#036";
export var secondary = "#019cdc";
export var selectedContract = "style-module--selectedContract--f594c";
export var selectedOrder = "style-module--selectedOrder--ba0a0";
export var textfont = "\"Verdana Pro\"";
export var titlefont = "\"Karbon\"";
export var white = "#fff";