// extracted by mini-css-extract-plugin
export var content = "style-module--content--31c34";
export var editWrapper = "style-module--editWrapper--d35ab";
export var error = "#ff3131";
export var header = "style-module--header--45236";
export var modalWrapper = "style-module--modalWrapper--0f708";
export var primary = "#036";
export var secondary = "#019cdc";
export var textfont = "\"Verdana Pro\"";
export var titlefont = "\"Karbon\"";
export var white = "#fff";