import { atom } from "recoil";

export const uploadClaimFileStepsState = atom({
    key: "uploadClaimFileStepsState",
    default: [
        {
            id: "process-details",
            title: "Claim details",
            description:
                "Lorem 1 ipsum dolor sit amet, consectetur adipiscing elit. Nunc sed risus ut elit nibh id. Nisl, praesent pulvinar.",
            isPrepared: false,
            isCompleted: false,
        },
        {
            id: "select-contract",
            title: "Contract selection",
            description:
                "Lorem 2 ipsum dolor sit amet, consectetur adipiscing elit. Nunc sed risus ut elit nibh id. Nisl, praesent pulvinar.",
            isPrepared: false,
            isCompleted: false,
        },
    ],
});
