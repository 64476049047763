// Lib

import {
    Box,
    Typography,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    Checkbox,
    TextField,
    InputLabel,
} from "@mui/material";
import { useFormik } from "formik";
import React, {
    Fragment,
    useCallback,
    useEffect,
    useImperativeHandle,
    useMemo,
} from "react";
import { useRecoilValue } from "recoil";
import clonedeep from "lodash.clonedeep";

// Own components
import {
    FormFooter,
    GrayBox,
    LoadingWrapper,
    OutcomeVariables,
} from "@components";

// Custom hooks
import { useUpdateStep, useGetVariables, useUploadDocument } from "@hooks";

// Atoms
import {
    contractSelectionState,
    createOutcomeStepsState,
    orderSelectionState,
    outcomeDetailsState,
} from "@atoms";

// Types
import type { FlowStep, Location } from "@types";

// Schemas
import { outcomeDetailsSchema } from "@schemas";

// Utils
import { displayDate, constructQueryString, isArrayWithContent } from "@utils";

// Style

import * as style from "./style.module.scss";

/**
 * Props type
 */
interface Props {
    location?: Location;
    currentStep?: FlowStep;
    disabled?: boolean;
    id?: string;
}

/**
 * Outcome details
 */
const OutcomeDetails = React.forwardRef(
    ({ location, currentStep, disabled, id }: Props, ref) => {
        // Outcome details state
        const outcomeDetailsInitialState =
            useRecoilValue<any>(outcomeDetailsState);

        // Get contract info from state
        const contractInfo = useRecoilValue(contractSelectionState);

        // Get contract info from state
        const orderInfo = useRecoilValue<any>(orderSelectionState);

        // Update step hook
        const updateStepValidation = useUpdateStep(
            location,
            createOutcomeStepsState,
        );

        /**
         * API
         */
        // Get variables
        const {
            getVariables,
            loading: loadingVariables,
            response: variables,
            error: variablesErrors,
        } = useGetVariables();

        // Get evidences
        const {
            list: evidences,
            loading: { fetching: fetchingEvidences },
            load: loadEvidences,
            download: downloadEvidence,
        } = useUploadDocument();

        //Formik state
        const formik = useFormik({
            enableReinitialize: true,
            isInitialValid: currentStep?.isPrepared,
            initialValues: {
                ...outcomeDetailsInitialState,
            },
            validationSchema: outcomeDetailsSchema,
            onSubmit: () => undefined,
        });

        /**
         * Form validation check
         */
        const canSaveState =
            (currentStep?.isPrepared &&
                Object.keys(formik.errors).length === 0) ||
            (!!formik &&
                !!formik.isValid &&
                Object.keys(formik.errors).length === 0 &&
                Object.keys(formik.touched).length > 0);

        /**
         * Check if the form has at least one error
         */
        const hasErrors = Object.keys(formik.errors).some(
            key => formik.touched[key],
        );

        /**
         * Check if the current step is valid
         * then set isPrepared to true, otherwise set it false
         */
        useEffect(() => {
            updateStepValidation(canSaveState);
        }, [canSaveState, location]);

        /**
         * Save state and go to the next page (controlled by the layout)
         */
        useImperativeHandle(ref, () => ({
            getStateData() {
                // Avoid BE whitelist validation
                const mappedVariables = formik.values.variables.map(el => {
                    const { inputVariableName, type, value } = el;
                    return {
                        inputVariableName,
                        type,
                        value,
                    };
                });
                return {
                    mappedVariables,
                    evidences: formik.values.evidences,
                    reason: formik.values.reason,
                };
            },
        }));

        useEffect(() => {
            if (!!orderInfo?.orderId && !!contractInfo?.id) {
                // Store the infusionDate to be able to validate endOfTeatmentDate
                formik.setFieldValue("infusionDate", orderInfo?.infusionDate);
                const params = constructQueryString(
                    {
                        contractId: contractInfo?.id,
                        orderId: orderInfo?.orderId,
                    },
                    true,
                );
                getVariables(params);
            }
        }, [contractInfo, orderInfo]);

        useEffect(() => {
            if (!contractInfo?.id) return;
            loadEvidences(contractInfo?.id);
        }, [contractInfo?.id]);

        useEffect(() => {
            if (
                !loadingVariables &&
                isArrayWithContent(variables?.data?.records)
            ) {
                const mappedVariables = variables?.data?.records?.map(el => {
                    return {
                        ...el,
                        value: undefined,
                    };
                });

                formik.setFieldValue("variables", mappedVariables);
            }
        }, [loadingVariables, orderInfo]);

        /**
         * Handle form change
         */
        const onDataChange = (elementIndex, value) => {
            const values = clonedeep(formik.values.variables);
            values[elementIndex].value = value;
            formik.setFieldValue("variables", values);
        };

        /**
         * Partner Mapper
         */
        const partnerMapper = useMemo(() => {
            if (!isArrayWithContent(contractInfo?.additionalPartners))
                return "";
            return contractInfo?.additionalPartners
                ?.map(item => item.name)
                .join(", ");
        }, [contractInfo?.additionalPartners]);

        /**
         * Product Mapper
         */
        const productMapper = useMemo(() => {
            return Object.keys(contractInfo.products)
                .map(key => `${contractInfo.products[key].name}`)
                .join(", ");
        }, [contractInfo?.products]);

        /**
         * Evidence handler
         */
        const addEvidence = (evidenceId: string) => {
            const ids: Array<string> = [...formik.values.evidences];
            const idExists = ids.includes(evidenceId);
            if (idExists) {
                const filtered = ids.filter(id => id !== evidenceId);
                formik.setFieldValue("evidences", filtered);
            } else {
                ids.push(evidenceId);
                formik.setFieldValue("evidences", ids);
            }
        };

        const isEvidenceChecked = useCallback(
            (evidenceId: string) => {
                if (!evidenceId) return;
                return formik?.values?.evidences?.includes(evidenceId);
            },
            [formik?.values?.evidences],
        );

        /**
         * Render
         */
        return (
            <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                height={1}
            >
                <div>
                    <Box mb={7}>
                        <Typography variant="h2">Outcome details</Typography>
                    </Box>
                    <GrayBox
                        id={`${id}-outcome-details-gray-box-selected-contract`}
                        className={style.selectedContract}
                        header={
                            <Typography variant="h3">
                                {contractInfo.reference}
                            </Typography>
                        }
                        subheaderSplitter={[
                            partnerMapper,
                            productMapper,
                            contractInfo?.indication,
                            `Valid from ${displayDate(
                                contractInfo.startDate,
                            )} to ${displayDate(contractInfo.endDate)}`,
                        ]}
                        subheaderSplitterType="caption"
                    />
                    <Typography variant="h3" mt={7}>
                        Fill in outcome details
                    </Typography>
                    <Box mt={3}>
                        <GrayBox
                            id={`${id}-outcome-details-selected-order-gray-box`}
                            className={style.selectedOrder}
                            header={
                                <Typography variant="h3">
                                    {`Infusion date ${displayDate(
                                        orderInfo.infusionDate,
                                    )}`}
                                </Typography>
                            }
                            helper={
                                <Box display="flex" alignItems="center">
                                    <Typography variant="captionSmall">
                                        {orderInfo.treatmentSite}
                                    </Typography>
                                    {orderInfo.treatmentSite &&
                                        orderInfo.cquenceId && (
                                            <span className={style.divider}>
                                                |
                                            </span>
                                        )}
                                    <Typography variant="captionSmall">
                                        {orderInfo.cquenceId}
                                    </Typography>
                                </Box>
                            }
                        />
                        <div className={style.inputSection}>
                            <LoadingWrapper
                                id={`${id}-outcome-variables-c`}
                                loading={loadingVariables}
                                error={variablesErrors}
                            >
                                <OutcomeVariables
                                    id={`${id}-outcome-details`}
                                    errors={formik.errors}
                                    touched={formik.touched}
                                    onBlur={formik.setFieldTouched}
                                    onChange={onDataChange}
                                    loading={loadingVariables}
                                    values={formik.values.variables}
                                    disabled={disabled}
                                />
                            </LoadingWrapper>

                            <div className={style.block}>
                                <InputLabel
                                    error={
                                        !!formik.errors["reason"] &&
                                        !!formik.touched["reason"]
                                    }
                                    shrink
                                >
                                    {`Reason of outcome ${
                                        isArrayWithContent(
                                            formik.values.evidences,
                                        )
                                            ? ""
                                            : "(*)"
                                    }`}
                                </InputLabel>

                                <TextField
                                    id={`${id}-reason`}
                                    fullWidth
                                    name={"reason"}
                                    // multiline
                                    //rows={5}
                                    onBlur={formik.handleBlur}
                                    size="small"
                                    autoComplete="off"
                                    variant="outlined"
                                    value={formik.values.reason}
                                    onChange={formik.handleChange}
                                    error={
                                        !!formik.errors["reason"] &&
                                        !!formik.touched["reason"]
                                    }
                                />

                                <InputLabel
                                    shrink
                                >{`or attach file(s)`}</InputLabel>
                                <LoadingWrapper
                                    id={`${id}-outcome-evidences-loading`}
                                    loading={fetchingEvidences}
                                    customMsg={
                                        isArrayWithContent(
                                            evidences?.data?.records,
                                        )
                                            ? undefined
                                            : "No files found!"
                                    }
                                >
                                    <div className={style.list}>
                                        {isArrayWithContent(
                                            evidences?.data?.records,
                                        ) && (
                                            <List dense>
                                                {evidences?.data?.records?.map(
                                                    item => {
                                                        return (
                                                            <Fragment
                                                                key={`evidence-item-${item.evidenceId}`}
                                                            >
                                                                <ListItem
                                                                    id={`evidence-item-${item.evidenceId}`}
                                                                    disablePadding
                                                                >
                                                                    <ListItemIcon
                                                                        sx={{
                                                                            minWidth:
                                                                                "2rem",
                                                                        }}
                                                                    >
                                                                        <Checkbox
                                                                            disableRipple
                                                                            size="small"
                                                                            edge="start"
                                                                            checked={isEvidenceChecked(
                                                                                item.evidenceId,
                                                                            )}
                                                                            onChange={() =>
                                                                                addEvidence(
                                                                                    item.evidenceId,
                                                                                )
                                                                            }
                                                                            tabIndex={
                                                                                -1
                                                                            }
                                                                            inputProps={{
                                                                                "aria-labelledby":
                                                                                    item.evidenceId,
                                                                            }}
                                                                        />
                                                                    </ListItemIcon>
                                                                    <ListItemText
                                                                        primary={
                                                                            <Typography
                                                                                variant="link"
                                                                                className={
                                                                                    style.link
                                                                                }
                                                                                onClick={() =>
                                                                                    downloadEvidence(
                                                                                        contractInfo.id,
                                                                                        item.evidenceId,
                                                                                    )
                                                                                }
                                                                            >
                                                                                {
                                                                                    item.fileName
                                                                                }
                                                                            </Typography>
                                                                        }
                                                                    />
                                                                </ListItem>
                                                            </Fragment>
                                                        );
                                                    },
                                                )}
                                            </List>
                                        )}
                                    </div>
                                </LoadingWrapper>
                            </div>
                        </div>
                    </Box>
                </div>
                <FormFooter
                    error={hasErrors}
                    textAlign="right"
                    id={`${id}-outcome-details-footer`}
                />
            </Box>
        );
    },
);

export default React.memo(OutcomeDetails);
