//lib
import CheckIcon from "@mui/icons-material/Check";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import InfoIcon from "@mui/icons-material/Info";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import Box from "@mui/material/Box";
import classNames from "classnames";
import React, { useEffect, useCallback } from "react";

// Constants
import { NOTIFICATIONS } from "@constants";

// Types
import type { Notification as NotificationType } from "@types";

// Styles
import * as style from "./style.module.scss";
import { isArrayWithContent } from "@utils";

/**
 * Alert renderer
 */
const Alert = React.forwardRef<HTMLDivElement, AlertProps>(
    function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    },
);

/**
 * Props type
 */
type Props = {
    id: string;
    notifications: NotificationType;
    dismiss: () => void;
};

/**
 * Notification Snackbar
 */
const Notifications = ({ id, notifications, dismiss }: Props) => {
    const [msgs, setMsgs] = React.useState<NotificationType[]>([]);

    useEffect(() => {
        if (!notifications?.message) {
            setMsgs([]);
            return;
        }

        if (
            !isArrayWithContent(msgs) ||
            msgs.some(item => item.message !== notifications?.message)
        ) {
            setMsgs([...msgs, notifications]);
        }
    }, [notifications?.message]);

    const getIcon = useCallback((severity = "info") => {
        switch (severity) {
            case NOTIFICATIONS.SEVERITY.WARNING:
                return <ErrorOutlineIcon fontSize="small" />;
            case NOTIFICATIONS.SEVERITY.ERROR:
                return <ErrorOutlineIcon fontSize="small" />;
            case NOTIFICATIONS.SEVERITY.SUCCESS:
                return <CheckIcon fontSize="small" />;
        }
        return <InfoIcon fontSize="small" />;
    }, []);

    return isArrayWithContent(msgs) ? (
        <Snackbar
            id={`${id}-snackbar`}
            onClose={() => {
                dismiss();
                setMsgs([]);
            }}
            autoHideDuration={6000}
            open
            className={style.snackbar}
        >
            <Box display="flex" flexDirection={"column"}>
                {msgs?.map((item, idx) => (
                    <Alert
                        key={idx}
                        sx={{ mb: 1 }}
                        id={`${id}-${idx}-snackbar-alert`}
                        variant="filled"
                        onClose={() => {
                            dismiss();
                            setMsgs([]);
                        }}
                        severity={item.severity}
                        icon={getIcon(item.severity)}
                        className={classNames(style.alert, {
                            [style.error]:
                                item.severity === NOTIFICATIONS.SEVERITY.ERROR,

                            [style.info]:
                                item.severity === NOTIFICATIONS.SEVERITY.INFO,
                            [style.warning]:
                                item.severity ===
                                NOTIFICATIONS.SEVERITY.WARNING,
                            [style.success]:
                                item.severity ===
                                NOTIFICATIONS.SEVERITY.SUCCESS,
                        })}
                    >
                        {item.message}
                    </Alert>
                ))}
            </Box>
        </Snackbar>
    ) : null;
};

export default React?.memo(Notifications);
