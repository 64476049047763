// Lib
import EditIcon from "@mui/icons-material/Edit";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React, { Fragment, useEffect, useState, useMemo } from "react";

// Own components
import { GrayBox, ProductMilestonesForm, Modal, Card } from "@components";

// Types
import { Brand } from "@types";

// Utils
import { isArrayWithContent } from "@utils";

/**
 * Brand type
 */

// Edit milestones type
type EditMilestones = Array<{
    description: string;
    id?: string;
    number: number;
}>;

/**
 * Props
 */
interface Props {
    hideEdit?: boolean;
    disableEdit?: boolean;
    productDetails?: any;
    contractMilestones: EditMilestones;
    callbacks?: {
        onEditClick?: (path: string) => void;
        onEditContractItem?: (value: Record<string, EditMilestones>) => void;
        setDisableSaveButton?: (value: boolean) => void;
    };

    id: string;
    brand: Brand & { name?: string };
    shrinkColumns?: boolean;
    isEditContract?: boolean;
    isCreateContract?: boolean;
    currency: string;
}

/**
 * MilestonesSummary
 */
const MilestonesSummary: React.FC<Props> = ({
    brand,
    productDetails,
    contractMilestones,
    id,
    hideEdit = false,
    callbacks,
    shrinkColumns,
    isEditContract,
    disableEdit,
    isCreateContract,
    currency,
}) => {
    const [openEditModal, setEditModal] = useState(false);
    const [updateMilestones, setMilestones] = useState<EditMilestones>([]);
    // Touched state
    const [touched, setTouched] = useState({});

    useEffect(() => {
        if (!isEditContract || !isArrayWithContent(contractMilestones)) return;

        if (!openEditModal) {
            setMilestones([]);
            callbacks?.setDisableSaveButton &&
                callbacks?.setDisableSaveButton(false);
        } else {
            setMilestones(contractMilestones);
            callbacks?.setDisableSaveButton &&
                callbacks?.setDisableSaveButton(true);
        }
    }, [contractMilestones, isEditContract, openEditModal]);

    /**
     * Handle blur
     */
    const onBlur = (e: React.BaseSyntheticEvent, key?: string) => {
        const copyTouched = { ...touched };
        const name = key ? key : e.target.name;
        if (copyTouched[name]) return;
        Object.assign(copyTouched, {
            [name]: true,
        });

        setTouched(copyTouched);
    };

    /**
     * Milestones error mapper
     */

    const hasMilestoneError = useMemo(() => {
        if (!isEditContract) return;
        return (
            !isArrayWithContent(updateMilestones) ||
            (isArrayWithContent(updateMilestones) &&
                updateMilestones.some(ms => !ms?.description))
        );
    }, [updateMilestones, isEditContract]);

    return (
        <Fragment>
            <Modal
                open={openEditModal}
                id={`edit-categories`}
                title={"Contract milestones"}
                onClose={() => {
                    setEditModal(false);
                }}
                primaryButton={{
                    action: () => {
                        if (callbacks?.onEditContractItem) {
                            callbacks?.onEditContractItem({
                                milestones: updateMilestones,
                            });
                            setEditModal(false);
                        }
                    },
                    text: "Save",
                    disabled: hasMilestoneError,
                }}
                secondaryButton={{
                    action: () => {
                        setMilestones([]);
                        setEditModal(false);
                    },
                    text: "Cancel",
                }}
            >
                <ProductMilestonesForm
                    id={`${id}-edit-milestone`}
                    onChange={(_, updatedMilestones) =>
                        setMilestones(updatedMilestones)
                    }
                    milestones={updateMilestones}
                    noTitle
                    onBlur={onBlur}
                    touched={touched}
                    productName={
                        productDetails?.product?.productName ||
                        productDetails?.productName
                    }
                    brandName={
                        brand?.brandName ||
                        brand?.name ||
                        productDetails?.brand?.name ||
                        ""
                    }
                    sapSkuNo={productDetails?.product?.sapSkuNo}
                    currency={currency}
                    price={productDetails?.priceCondition?.price}
                />
            </Modal>

            <Grid item xs={12} md={shrinkColumns ? 6 : 12}>
                <Card
                    id={`${id}-edit-milestones`}
                    title="Contract milestones"
                    action={{
                        icon: <EditIcon fontSize="small" />,
                        hidden:
                            hideEdit || (!isEditContract && !isCreateContract),
                        disabled: disableEdit,
                        action: () =>
                            isEditContract
                                ? setEditModal(true)
                                : !!callbacks?.onEditClick &&
                                  callbacks.onEditClick("contract-milestones"),
                    }}
                >
                    <GrayBox
                        padding={"1rem"}
                        id={`${id}-product-details`}
                        header={
                            <Typography variant="h3">{`${
                                productDetails?.product?.productName ||
                                productDetails?.productName
                            } (${
                                brand?.brandName || brand?.name
                            })`}</Typography>
                        }
                    />

                    {isArrayWithContent(contractMilestones) &&
                        contractMilestones.map((item, index) => (
                            <Box p={3} key={index}>
                                <Typography variant="caption1Dark">
                                    {`Milestone ${item.number}`}
                                </Typography>

                                <Box mb={2}>
                                    <Typography variant="caption1">
                                        {item.description}
                                    </Typography>
                                </Box>
                            </Box>
                        ))}
                </Card>
            </Grid>
        </Fragment>
    );
};

export default React.memo(MilestonesSummary);
