// Lib
import EditIcon from "@mui/icons-material/Edit";
import InfoIcon from "@mui/icons-material/Info";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import CircularProgress from "@mui/material/CircularProgress";
import Tooltip from "@mui/material/Tooltip";
import FormHelperText from "@mui/material/FormHelperText";
import React, { Fragment, useEffect, useState } from "react";
import * as yup from "yup";

// Own components
import { FieldRenderer, Autocomplete, Modal, Card } from "@components";

// Types
import { Account, FlowStep } from "@types";

// Utils
import { constantMapper, displayDate } from "@utils";

/**
 * Edit contract fields type
 */
type EditContractFields = {
    externalReference: string | undefined;
    icdNo: string | undefined;
    responsible: string | undefined;
    icdUrl: string | undefined;
};

const URL_SCHEMA = yup.object().shape({
    icdUrl: yup
        .string()
        .url(
            "Invalid URL: Please enter a valid URL that begins with 'https://'",
        )
        .test("is-https", "URL must start with https", value => {
            // Custom validation function to check if the URL starts with https
            if (value) {
                return value.startsWith("https://");
            }
            return true; // Return true for empty values or non-string values
        })
        .nullable(),
});

// Example usage
const validateURL = async url => {
    try {
        await URL_SCHEMA.validate({ icdUrl: url });
        return true;
    } catch (error) {
        return error;
    }
};

/**
 * Props
 */
interface Props {
    currentStep?: FlowStep;
    hideEdit?: boolean;
    disableEdit?: boolean;
    generalInformation: any;
    callbacks?: {
        onEditClick?: (path: string) => void;
        onBlur?: (
            event?: React.SyntheticEvent,
            field?: string | undefined,
        ) => void;
        onFieldChange?: (key: string, value: string) => void;
        onEditContractItem?: (values: EditContractFields) => void;
        setDisableSaveButton?: (value: boolean) => void;
    };
    fields?: Record<string, string | undefined>;
    isDuplicateContract?: boolean;
    partners?: {
        loading: boolean;
        data: Array<Account>;
        onSearch: (query: string) => void;
    };
    contractRefValidation?: {
        isNotUniqueValue: boolean;
        isNotUniqueMessage: boolean;
        duplicationValidating?: boolean;
    };
    touched?: Record<string, boolean>;
    id: string;
    isEditContract?: boolean;
    shrinkColumns?: boolean;
    isCreateContract?: boolean;
}

/**
 * General Information Review
 */
const GeneralInformationSummary: React.FC<Props> = ({
    generalInformation,
    id,
    hideEdit = false,
    callbacks,
    isDuplicateContract,
    contractRefValidation,
    touched,
    partners,
    shrinkColumns,
    isEditContract,
    fields,
    disableEdit,
    isCreateContract,
}) => {
    const [generalInformationFields, setGeneralInformation] =
        useState<EditContractFields>({
            externalReference: undefined,
            icdNo: undefined,
            responsible: undefined,
            icdUrl: undefined,
        });

    const [openEditModal, setEditModal] = useState(false);

    // Error State
    const [urlError, setUrlError] = useState<string>("");

    /**
     * Reset general information fields
     */

    const onResetGeneralInformationFields = () => {
        setGeneralInformation({
            externalReference: undefined,
            icdNo: undefined,
            responsible: undefined,
            icdUrl: undefined,
        });
    };

    /**
     * Change handler
     */
    const onChange = (field: string, value: string) => {
        const copyFields = { ...generalInformationFields };
        copyFields[field] = value;
        setGeneralInformation(copyFields);
    };

    useEffect(() => {
        if (!isEditContract) return;

        if (openEditModal) {
            setGeneralInformation({
                externalReference: generalInformation?.externalReference,
                icdNo: generalInformation?.icdNo,
                responsible: generalInformation?.responsible,
                icdUrl: generalInformation?.icdUrl,
            });
            callbacks?.setDisableSaveButton &&
                callbacks?.setDisableSaveButton(true);
        } else {
            onResetGeneralInformationFields();
            callbacks?.setDisableSaveButton &&
                callbacks?.setDisableSaveButton(false);
        }
    }, [isEditContract, openEditModal]);

    /**
     * On edit contract submit
     */

    const submitEditContract = () => {
        if (!callbacks?.onEditContractItem) return;

        validateURL(generalInformationFields?.icdUrl).then(result => {
            if (result instanceof Error) {
                setUrlError(result?.message);
                return;
            } else {
                setUrlError("");
                callbacks?.onEditContractItem &&
                    callbacks?.onEditContractItem(generalInformationFields);
                setEditModal(false);
            }
        });
    };

    return (
        <Fragment>
            <Modal
                open={openEditModal}
                id={`edit-categories`}
                title={"General information"}
                onClose={() => {
                    setEditModal(false);
                }}
                primaryButton={{
                    action: () => {
                        submitEditContract();
                    },
                    text: "Save",
                    disabled: false,
                }}
                secondaryButton={{
                    action: () => {
                        onResetGeneralInformationFields();
                        setEditModal(false);
                    },
                    text: "Cancel",
                }}
            >
                <Grid item xs={12} container rowSpacing={3} columnSpacing={4}>
                    <Grid item xs={12} md={6}>
                        <InputLabel shrink id={`${id}-external-reference`}>
                            {"External reference"}
                        </InputLabel>

                        <TextField
                            id={`${id}-icd-number-input`}
                            fullWidth
                            size="small"
                            autoComplete="off"
                            name="externalReference"
                            value={generalInformationFields?.externalReference}
                            onChange={(event: React.BaseSyntheticEvent) => {
                                onChange(
                                    "externalReference",
                                    event?.target.value,
                                );
                            }}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <InputLabel shrink id={`${id}-responsible`}>
                            {"Contract responsible"}
                        </InputLabel>

                        <TextField
                            id={`${id}-icd-number-input`}
                            fullWidth
                            size="small"
                            autoComplete="off"
                            name="responsible"
                            value={generalInformationFields?.responsible}
                            onChange={(event: React.BaseSyntheticEvent) => {
                                onChange("responsible", event?.target.value);
                            }}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <InputLabel shrink id={`${id}-icd-number-input-label`}>
                            {"ICD number"}
                        </InputLabel>

                        <TextField
                            id={`${id}-icd-number-input`}
                            fullWidth
                            size="small"
                            autoComplete="off"
                            name="icdNo"
                            value={generalInformationFields?.icdNo}
                            onChange={(event: React.BaseSyntheticEvent) => {
                                onChange("icdNo", event?.target.value);
                            }}
                            variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <InputLabel
                            shrink
                            id={`${id}-icd-url-input-label`}
                            error={!!urlError}
                        >
                            {"ICD URL"}
                        </InputLabel>

                        <TextField
                            id={`${id}-icd-url-input`}
                            fullWidth
                            size="small"
                            autoComplete="off"
                            name="icdUrl"
                            value={generalInformationFields?.icdUrl}
                            onChange={(event: React.BaseSyntheticEvent) => {
                                onChange("icdUrl", event?.target.value);
                            }}
                            variant="outlined"
                            error={!!urlError}
                        />
                        {!!urlError && (
                            <FormHelperText error>{urlError}</FormHelperText>
                        )}
                    </Grid>
                </Grid>
            </Modal>

            <Card
                title="General information"
                id={`${id}-card`}
                action={{
                    hidden: hideEdit || (!isEditContract && !isCreateContract),
                    disabled: disableEdit,
                    icon: <EditIcon fontSize="small" />,
                    action: () =>
                        isEditContract
                            ? setEditModal(true)
                            : !!callbacks?.onEditClick &&
                              callbacks.onEditClick("general-information"),
                }}
            >
                <Grid item xs={12} container rowSpacing={3} columnSpacing={4}>
                    {!isDuplicateContract && generalInformation?.reference && (
                        <Grid item xs={12} md={shrinkColumns ? 3 : 6}>
                            <FieldRenderer
                                id={`${id}-ref-number`}
                                label="Contract reference number"
                                value={generalInformation.reference}
                            />
                        </Grid>
                    )}

                    {isDuplicateContract && !!fields && (
                        <Grid item xs={12} md={shrinkColumns ? 3 : 6}>
                            <InputLabel
                                id={`${id}-reference-label`}
                                error={
                                    !fields?.reference &&
                                    !!touched &&
                                    !!touched["reference"]
                                }
                                shrink
                            >
                                {"Contract reference number (*)"}
                            </InputLabel>

                            <TextField
                                id={`${id}-reference-input`}
                                fullWidth
                                name="reference"
                                size="small"
                                autoComplete="off"
                                value={fields?.reference}
                                onBlur={callbacks?.onBlur ?? undefined}
                                onChange={(event: React.BaseSyntheticEvent) => {
                                    !!callbacks?.onFieldChange &&
                                        callbacks.onFieldChange(
                                            "reference",
                                            event?.target.value,
                                        );
                                }}
                                variant="outlined"
                                error={
                                    (!fields?.reference &&
                                        !!touched &&
                                        !!touched["reference"]) ||
                                    contractRefValidation?.isNotUniqueValue
                                }
                                InputProps={
                                    contractRefValidation?.duplicationValidating
                                        ? {
                                              endAdornment: (
                                                  <InputAdornment position="start">
                                                      <CircularProgress
                                                          color="inherit"
                                                          size={20}
                                                      />
                                                  </InputAdornment>
                                              ),
                                          }
                                        : contractRefValidation?.isNotUniqueValue
                                          ? {
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <Tooltip
                                                            open={true}
                                                            arrow
                                                            id={`${id}-reference-validation-tooltip`}
                                                            title={
                                                                contractRefValidation?.isNotUniqueMessage
                                                            }
                                                            placement="top"
                                                        >
                                                            <InfoIcon color="error" />
                                                        </Tooltip>
                                                    </InputAdornment>
                                                ),
                                            }
                                          : undefined
                                }
                            />
                        </Grid>
                    )}

                    {(generalInformation?.country?.isoCode ||
                        generalInformation?.country?.name) && (
                        <Grid item xs={12} md={shrinkColumns ? 3 : 6}>
                            <FieldRenderer
                                id={`${id}-country`}
                                label="Country"
                                value={generalInformation.country?.name}
                            />
                        </Grid>
                    )}
                    {generalInformation?.startDate && (
                        <Grid item xs={12} md={shrinkColumns ? 3 : 6}>
                            <FieldRenderer
                                id={`${id}-start-date`}
                                label="Start date"
                                value={displayDate(
                                    generalInformation.startDate,
                                )}
                            />
                        </Grid>
                    )}
                    {generalInformation?.endDate && (
                        <Grid item xs={12} md={shrinkColumns ? 3 : 6}>
                            <FieldRenderer
                                id={`${id}-end-date`}
                                label="End date"
                                value={displayDate(generalInformation.endDate)}
                            />
                        </Grid>
                    )}
                    {(generalInformation?.brand?.brandId ||
                        generalInformation?.brand?.id ||
                        generalInformation.brand?.brandName ||
                        generalInformation.brand?.name) && (
                        <Grid item xs={12} md={shrinkColumns ? 3 : 6}>
                            <FieldRenderer
                                id={`${id}-brand`}
                                label="Brand"
                                value={
                                    generalInformation.brand?.brandName ||
                                    generalInformation.brand?.name
                                }
                            />
                        </Grid>
                    )}
                    {(generalInformation?.therapeuticArea
                        ?.therapeuticAreaName ||
                        generalInformation?.therapeuticArea?.name) && (
                        <Grid item xs={12} md={shrinkColumns ? 3 : 6}>
                            <FieldRenderer
                                id={`${id}-therapeutic-area`}
                                label="Therapeutic area"
                                value={
                                    generalInformation.therapeuticArea
                                        ?.therapeuticAreaName ||
                                    generalInformation.therapeuticArea?.name
                                }
                            />
                        </Grid>
                    )}
                    {(generalInformation?.indication?.indicationName ||
                        generalInformation?.indication?.name) && (
                        <Grid item xs={12} md={shrinkColumns ? 3 : 6}>
                            <FieldRenderer
                                id={`${id}-indication`}
                                label="Indication"
                                value={
                                    generalInformation.indication
                                        .indicationName ||
                                    generalInformation.indication.name
                                }
                            />
                        </Grid>
                    )}
                    {!isDuplicateContract &&
                        (!!generalInformation?.primaryContractPartner ||
                            !!generalInformation?.primaryPartner
                                ?.accountName) && (
                            <Grid item xs={12} md={shrinkColumns ? 3 : 6}>
                                <FieldRenderer
                                    id={`${id}-primary-partner`}
                                    label="Primary contract partner"
                                    value={
                                        generalInformation
                                            ?.primaryContractPartner?.name ||
                                        generalInformation?.primaryPartner
                                            ?.accountName
                                    }
                                />
                            </Grid>
                        )}
                    {!!isDuplicateContract && !!fields && (
                        <Grid item xs={12} md={shrinkColumns ? 3 : 6}>
                            <InputLabel
                                id={`${id}-primary-partner-input-label`}
                                error={
                                    !fields.primaryPartner &&
                                    !!touched?.primaryPartner
                                }
                                shrink
                            >
                                {"Primary contract partner (*)"}
                            </InputLabel>

                            <Autocomplete
                                id={`${id}-primary-partner-input`}
                                data={partners?.data || []}
                                loading={partners?.loading || false}
                                name="primaryPartner"
                                onBlur={callbacks?.onBlur ?? undefined}
                                value={fields.primaryPartner || undefined}
                                size="small"
                                keysToMatch={[
                                    "accountName",
                                    "accountCity",
                                    "sapAccountCode",
                                    "knowyoursupplierid",
                                ]}
                                onChange={value => {
                                    if (
                                        !!touched &&
                                        !touched["primaryPartner"] &&
                                        !!callbacks?.onBlur
                                    ) {
                                        callbacks.onBlur(
                                            undefined,
                                            "primaryPartner",
                                        );
                                    }
                                    callbacks?.onFieldChange &&
                                        callbacks.onFieldChange(
                                            "primaryPartner",
                                            value,
                                        );
                                }}
                                onSearch={(query: string) => {
                                    partners?.onSearch(query);
                                }}
                                variant="outlined"
                                error={
                                    !fields?.primaryPartner &&
                                    !!touched?.primaryPartner
                                }
                                disabled={isEditContract}
                            />
                        </Grid>
                    )}

                    {(!!generalInformation?.claimPeriodicity ||
                        generalInformation?.claimPeriodicity?.label) && (
                        <Grid item xs={12} md={shrinkColumns ? 3 : 6}>
                            <FieldRenderer
                                id={`${id}-claimPeriodicity`}
                                label="Claim periodicity"
                                value={
                                    generalInformation?.claimPeriodicity
                                        ?.label ||
                                    constantMapper(
                                        generalInformation?.claimPeriodicity,
                                    )
                                }
                            />
                        </Grid>
                    )}

                    {!isDuplicateContract && (
                        <Grid item xs={12} md={shrinkColumns ? 3 : 6}>
                            <FieldRenderer
                                id={`${id}-external-reference`}
                                label="External reference"
                                value={generalInformation?.externalReference}
                            />
                        </Grid>
                    )}
                    {!!isDuplicateContract && !!fields && (
                        <Grid item xs={12} md={shrinkColumns ? 3 : 6}>
                            <InputLabel shrink id={`${id}-external-reference`}>
                                {"External reference"}
                            </InputLabel>

                            <TextField
                                id={`${id}-icd-number-input`}
                                fullWidth
                                size="small"
                                autoComplete="off"
                                name="externalReference"
                                value={fields.externalReference}
                                onChange={(event: React.BaseSyntheticEvent) => {
                                    !!callbacks?.onFieldChange &&
                                        callbacks.onFieldChange(
                                            "externalReference",
                                            event?.target.value,
                                        );
                                }}
                                variant="outlined"
                            />
                        </Grid>
                    )}

                    {!isDuplicateContract && (
                        <Grid item xs={12} md={shrinkColumns ? 3 : 6}>
                            <FieldRenderer
                                id={`${id}-responsible`}
                                label="Contract responsible"
                                value={generalInformation?.responsible}
                            />
                        </Grid>
                    )}

                    {!!isDuplicateContract && !!fields && (
                        <Grid item xs={12} md={shrinkColumns ? 3 : 6}>
                            <InputLabel shrink id={`${id}-responsible`}>
                                {"Contract responsible"}
                            </InputLabel>

                            <TextField
                                id={`${id}-icd-number-input`}
                                fullWidth
                                size="small"
                                autoComplete="off"
                                name="responsible"
                                value={fields.responsible}
                                onChange={(event: React.BaseSyntheticEvent) => {
                                    !!callbacks?.onFieldChange &&
                                        callbacks.onFieldChange(
                                            "responsible",
                                            event?.target.value,
                                        );
                                }}
                                variant="outlined"
                            />
                        </Grid>
                    )}

                    {!isDuplicateContract && (
                        <Grid item xs={12} md={shrinkColumns ? 3 : 6}>
                            <FieldRenderer
                                id={`${id}-icd-number`}
                                label="ICD number"
                                value={generalInformation?.icdNo}
                            />
                        </Grid>
                    )}

                    {!isDuplicateContract && (
                        <Grid item xs={12}>
                            <FieldRenderer
                                id={`${id}-icd-url`}
                                label="ICD URL"
                                value={generalInformation?.icdUrl}
                                hyperlink
                            />
                        </Grid>
                    )}

                    {!!isDuplicateContract && !!fields && (
                        <Grid item xs={12} md={shrinkColumns ? 3 : 6}>
                            <InputLabel
                                shrink
                                id={`${id}-icd-number-input-label`}
                            >
                                {"ICD number"}
                            </InputLabel>

                            <TextField
                                id={`${id}-icd-number-input`}
                                fullWidth
                                size="small"
                                autoComplete="off"
                                name="icdNo"
                                value={fields.icdNo}
                                onChange={(event: React.BaseSyntheticEvent) => {
                                    !!callbacks?.onFieldChange &&
                                        callbacks.onFieldChange(
                                            "icdNo",
                                            event?.target.value,
                                        );
                                }}
                                variant="outlined"
                            />
                        </Grid>
                    )}

                    {!!isDuplicateContract && !!fields && (
                        <Grid item xs={12} md={shrinkColumns ? 3 : 6}>
                            <InputLabel
                                shrink
                                id={`${id}-icd-url-input-label`}
                                error={!!generalInformation?.isValidIcdUrl}
                            >
                                {"ICD URL"}
                            </InputLabel>

                            <TextField
                                id={`${id}-icd-url-input`}
                                fullWidth
                                size="small"
                                autoComplete="off"
                                name="icdUrl"
                                value={fields.icdUrl}
                                onChange={(event: React.BaseSyntheticEvent) => {
                                    !!callbacks?.onFieldChange &&
                                        callbacks.onFieldChange(
                                            "icdUrl",
                                            event?.target.value,
                                        );
                                }}
                                variant="outlined"
                                error={!!generalInformation?.isValidIcdUrl}
                            />
                            {generalInformation?.isValidIcdUrl && (
                                <FormHelperText error>
                                    {generalInformation?.isValidIcdUrl}
                                </FormHelperText>
                            )}
                        </Grid>
                    )}
                </Grid>
            </Card>
        </Fragment>
    );
};

export default React.memo(GeneralInformationSummary);
