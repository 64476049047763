// extracted by mini-css-extract-plugin
export var card = "style-module--card--13c68";
export var description = "style-module--description--b0696";
export var details = "style-module--details--87c9d";
export var error = "#ff3131";
export var primary = "#036";
export var secondary = "#019cdc";
export var text = "style-module--text--152a5";
export var textfont = "\"Verdana Pro\"";
export var titlefont = "\"Karbon\"";
export var white = "#fff";
export var wrapper = "style-module--wrapper--d0676";