import { atom } from "recoil";

import { setInitialDate } from "@utils";

/**
 * Order selection State
 */
export const orderSelectionState = atom({
    key: "orderSelectionState",
    default: {
        orderId: "",
        productName: "",
        salessystemOrderId: "",
        treatmentSite: "",
        infusionDate: setInitialDate(),
        linked: true,
        extContractRef: "",
        orderStatus: "",
    },
});
