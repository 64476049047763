//Lib
import {
    Box,
    Typography,
    FormControl,
    FormControlLabel,
    RadioGroup,
    Radio,
    InputLabel,
    TextField,
    Grid,
} from "@mui/material";
import { NumericFormat } from "react-number-format";
import React, { Fragment } from "react";

// Own components
import { Dialog } from "@components";

// Styles
import * as style from "../style.module.scss";

/**
 * Props
 */
interface Props {
    openDialog: boolean;
    onConfirm: () => void;
    loading: boolean;
    onReset: () => void;
    contractRef: string;
    className?: string;
    values: {
        approved: string;
        rejectionReason: string;
        newClaimAmount: number;
        overrideReason: string;
    };
    onBlur: (event: React.SyntheticEvent) => void;
    onChange: any;
    errors: any;
    touched: any;
    id: string;
    onFieldChange: (field: string, value: any) => void;
    isApprovalMatrix: boolean;
}

/**
 * Claim approval task
 */
const ClaimApprovalTask = ({
    id,
    openDialog,
    loading,
    onReset,
    className,
    values,
    onBlur,
    onChange,
    errors,
    touched,
    onConfirm,
    onFieldChange,
    isApprovalMatrix,
}: Props) => {
    return (
        <Fragment>
            <Dialog
                id={`${id}-tasks-task-review`}
                title={"Complete claim task"}
                open={openDialog}
                message={
                    values?.approved === "recalled" ? (
                        <Typography variant="subtitle2" color="black">
                            You are about to recall this claim task. Doing so
                            will set the claim to draft status. This action
                            cannot be undone.
                        </Typography>
                    ) : values?.approved !== "rejected" ? (
                        <Typography variant="subtitle2" color="black">
                            You are about to mark this claim task complete.
                            Doing so will approve the claim which can lead to a
                            financial transaction. This action cannot be undone.
                        </Typography>
                    ) : (
                        <Typography variant="subtitle2" color="black">
                            You are about to mark this claim task as rejected.
                            Doing so will set the claim to rejected without
                            financial impact and the claim will need to be
                            re-made after corrections. This action cannot be
                            undone.
                        </Typography>
                    )
                }
                primaryButton={{
                    text: "Confirm",
                    action: onConfirm,
                    loading: loading,
                }}
                secondaryButton={{
                    text: "Cancel",
                    action: onReset,
                }}
            />

            <Typography variant="h2" className={style.header}>
                {`Financial approval`}
            </Typography>

            <Box mb={3} className={className}>
                <form noValidate onBlur={onBlur}>
                    <FormControl>
                        <RadioGroup
                            id={`${id}-task-details-task-review`}
                            value={values?.approved}
                            onChange={onChange}
                            name="approved"
                        >
                            <FormControlLabel
                                value={"approved"}
                                id={`${id}-task-details-review-contract-approve`}
                                control={<Radio size="small" />}
                                label={
                                    <Typography
                                        variant="body2"
                                        color="dark.light"
                                    >
                                        I approve this decision
                                    </Typography>
                                }
                            />
                            {!isApprovalMatrix && (
                                <FormControlLabel
                                    id={`${id}-task-details-review-contract-approve-with-diff-amount`}
                                    value={"approvedWithDifferentAmount"}
                                    control={<Radio size="small" />}
                                    label={
                                        <Typography
                                            variant="body2"
                                            color="dark.light"
                                        >
                                            I approve this decision, with a
                                            different amount:
                                        </Typography>
                                    }
                                />
                            )}
                            <FormControlLabel
                                id={`${id}-task-details-review-contract-reject`}
                                value={"rejected"}
                                control={<Radio size="small" />}
                                label={
                                    <Typography
                                        variant="body2"
                                        color="dark.light"
                                    >
                                        I reject this decision
                                    </Typography>
                                }
                            />

                            <FormControlLabel
                                value={"recalled"}
                                id={`${id}-task-details-review-contract-approve`}
                                control={<Radio size="small" />}
                                label={
                                    <Typography
                                        variant="body2"
                                        color="dark.light"
                                    >
                                        Recall claim (resets claim to draft
                                        status)
                                    </Typography>
                                }
                            />
                        </RadioGroup>
                    </FormControl>

                    {values?.approved === "rejected" && (
                        <Box mt={2}>
                            <InputLabel
                                error={
                                    !!errors?.rejectionReason &&
                                    !!touched?.rejectionReason
                                }
                                shrink
                            >
                                {"Reason of rejection (*)"}
                            </InputLabel>
                            <TextField
                                id={`${id}-task-details-task-contract-review-rejection-reason-input`}
                                fullWidth
                                name="rejectionReason"
                                value={values.rejectionReason}
                                variant="outlined"
                                autoComplete="off"
                                size="small"
                                onChange={onChange}
                                error={
                                    !!errors?.rejectionReason &&
                                    !!touched?.rejectionReason
                                }
                            />
                        </Box>
                    )}

                    {values?.approved === "approvedWithDifferentAmount" && (
                        <Grid container spacing={2} mt={4}>
                            <Grid xs={12} md={5} item>
                                <InputLabel
                                    error={
                                        !!errors.newClaimAmount &&
                                        !!touched.newClaimAmount
                                    }
                                    shrink
                                >
                                    {"New amount (*)"}
                                </InputLabel>

                                <NumericFormat
                                    id={`task-details-task-review-new-rebate-input`}
                                    fullWidth
                                    name="newClaimAmount"
                                    autoComplete="off"
                                    value={values.newClaimAmount}
                                    variant="outlined"
                                    error={
                                        !!errors.newClaimAmount &&
                                        !!touched.newClaimAmount
                                    }
                                    thousandSeparator="."
                                    decimalSeparator=","
                                    customInput={TextField}
                                    allowNegative={false}
                                    size="small"
                                    onValueChange={({ floatValue }) => {
                                        onFieldChange(
                                            "newClaimAmount",
                                            floatValue?.toString() ?? "",
                                        );
                                    }}
                                />
                            </Grid>

                            <Grid xs={12} md={7} item>
                                <InputLabel
                                    error={
                                        !!errors.overrideReason &&
                                        !!touched.overrideReason
                                    }
                                    shrink
                                >
                                    {"Reason for change (*)"}
                                </InputLabel>
                                <TextField
                                    id={`task-details-task-review-override-reason-input`}
                                    fullWidth
                                    name="overrideReason"
                                    value={values.overrideReason}
                                    variant="outlined"
                                    autoComplete="off"
                                    size="small"
                                    onChange={onChange}
                                    error={
                                        !!errors.overrideReason &&
                                        !!touched.overrideReason
                                    }
                                />
                            </Grid>
                        </Grid>
                    )}
                </form>
            </Box>
        </Fragment>
    );
};

export default React.memo(ClaimApprovalTask);
