import LockIcon from "@mui/icons-material/Lock";
import { Tabs as _Tabs } from "@mui/material";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import classnames from "classnames";
import { navigate } from "gatsby";
import React, { useMemo } from "react";

import { Location } from "@types";
import * as style from "./style.module.scss";

interface TabItem {
    path: string;
    disabled?: boolean;
    title: string;
    subpages?: Array<string>;
    state?: any;
}

/**
 * Props type
 */
interface Props {
    onClick?: (item) => void;
    items: Array<TabItem>;
    location: Location;
    id?: string;
}

/**
 * Tabs
 */
const Tabs = ({ items, onClick, location, id }: Props) => {
    /**
     * Find active tab
     */
    const activeTab = useMemo(() => {
        if (!location) return;
        return items.findIndex(item => {
            return (
                item.path.includes(location.pathname.replace(/\/$/, "")) ||
                item?.subpages?.includes(location.pathname.replace(/\/$/, ""))
            );
        });
    }, [items, location]);

    /**
     * Click handler
     */
    const handleClick = (event: React.SyntheticEvent, item: TabItem) => {
        event.preventDefault();
        if (onClick && !location) {
            onClick(item);
            // setActiveTab(index);
        } else navigate(item.path, { state: item.state });

        return;
    };

    const a11yProps = (index: number) => {
        return {
            id: `${id}-tab-${index}`,
            "aria-controls": `simple-tabpanel-${index}`,
            tabIndex: 0,
        };
    };

    return (
        <Box className={style.wrapper}>
            <_Tabs
                value={activeTab}
                aria-label="tabs"
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
                id={`${id}-tabs`}
            >
                {items.map((item, index) => (
                    <Tab
                        onClick={event => {
                            if (!item.disabled) {
                                handleClick(event, item);
                            }
                        }}
                        className={style.item}
                        disabled={item.disabled}
                        label={
                            <Box display="flex">
                                {item.disabled && (
                                    <LockIcon
                                        fontSize="small"
                                        sx={{ marginRight: "0.6rem" }}
                                    />
                                )}
                                <Typography
                                    variant="h4"
                                    className={classnames({
                                        [style.disabled]: item.disabled,
                                    })}
                                    sx={{ fontWeight: "500" }}
                                >
                                    {item.title}
                                </Typography>
                            </Box>
                        }
                        {...a11yProps(index)}
                        key={`tab-${index}`}
                    />
                ))}
            </_Tabs>
        </Box>
    );
};

export default React.memo(Tabs);
