// Libs
import { TableCell, TableRow, Typography, Box } from "@mui/material";
import { DeleteForever } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import React from "react";

// Own components
import { ActionMenu } from "@components";

// Utils
import { displayDate, thousandsSeparator } from "@utils";

interface Row {
    price: string;
    currency: string;
    discountPercentage: string;
    unitOfMeasureFrom: string;
    unitOfMeasureTo: string;
    unitOfMeasure: string;
    periodFrom: string;
    periodTo: string;
}

interface Props {
    row: Row;
    rowIndex: number;
    id: string;
    callbacks: any;
    specificKeys?: Record<string, unknown>;
}

const ScalesRow = ({ rowIndex, row, id, callbacks, specificKeys }: Props) => {
    return (
        <TableRow id={`${id}-row-${rowIndex}`}>
            <TableCell>
                <Typography variant="caption2">
                    {thousandsSeparator(row?.price) || "-"}
                </Typography>
            </TableCell>

            {!callbacks?.onEditScale && (
                <TableCell>
                    <Typography variant="caption2">
                        {row?.price !== undefined &&
                        row?.price !== null &&
                        specificKeys
                            ? (specificKeys["currency"] as string)
                            : ""}
                    </Typography>
                </TableCell>
            )}

            <TableCell>
                <Typography variant="caption2">
                    {row.discountPercentage
                        ? `${thousandsSeparator(row?.discountPercentage)}%`
                        : "-"}
                </Typography>
            </TableCell>

            <TableCell>
                <Typography variant="caption2">
                    {thousandsSeparator(row?.unitOfMeasureFrom)}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography variant="caption2">
                    {thousandsSeparator(row?.unitOfMeasureTo)}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography variant="caption2">{row?.unitOfMeasure}</Typography>
            </TableCell>
            <TableCell>
                <Typography variant="caption2">
                    {displayDate(row?.periodFrom)}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography variant="caption2">
                    {displayDate(row?.periodTo)}
                </Typography>
            </TableCell>

            {!!callbacks && !!callbacks?.onEditScale && (
                <TableCell padding="checkbox">
                    <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-end"
                    >
                        <ActionMenu
                            id={`${id}-row-${rowIndex}-contract-row`}
                            autoHide
                            options={[
                                {
                                    label: "Edit scale",
                                    icon: <EditIcon fontSize="small" />,
                                    action: () =>
                                        callbacks?.onEditScale(
                                            "edit",
                                            rowIndex,
                                        ),
                                },

                                {
                                    label: "Delete scale",
                                    icon: <DeleteForever fontSize="small" />,
                                    action: () =>
                                        callbacks?.onEditScale(
                                            "delete",
                                            rowIndex,
                                        ),
                                },
                            ]}
                        />
                    </Box>
                </TableCell>
            )}
        </TableRow>
    );
};

export default ScalesRow;
