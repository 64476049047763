// extracted by mini-css-extract-plugin
export var darkDisabled = "style-module--darkDisabled--d60d8";
export var error = "#ff3131";
export var filterList = "style-module--filterList--90526";
export var firstCaption = "style-module--firstCaption--ece56";
export var header = "style-module--header--41716";
export var listWrapper = "style-module--listWrapper--9fc43";
export var primary = "#036";
export var secondCaption = "style-module--secondCaption--bb8b3";
export var secondary = "#019cdc";
export var separator = "style-module--separator--12434";
export var subHeader = "style-module--subHeader--0c19f";
export var textfont = "\"Verdana Pro\"";
export var titlefont = "\"Karbon\"";
export var white = "#fff";
export var whiteInput = "style-module--whiteInput--a848d";